import React from 'react'
import ReactDOM from 'react-dom'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import '../../scss/style.scss';
import { withStyles } from '@material-ui/core/styles'
import Cropper from 'react-easy-crop'
import ImgDialog from './ImgDialog.js'
import FileBase64 from 'react-file-base64'
import getCroppedImg from './cropImage.js'
// import img from '../public/img.jpeg'
import ImageEditorRc from 'react-cropper-image-editor'
// import './imageEditor.css'
import 'cropperjs/dist/cropper.css'

export default class imageEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: 'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000',
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 4 / 4,
      croppedAreaPixels: null,
      croppedImage: null,
      rotate: 0,
      done:false,
    }
}  

  getFiles = file => {
    console.log(file)
    this.setState({
      imageSrc: file.base64,
    })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels })
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  setSomeThing = base64data => {
    this.setState({ croppedImage: base64data })
  }
  showCroppedImage = () => {
    if (this.cropper) {
      this.setState({
        croppedImage: this.cropper.getCroppedCanvas().toDataURL(),
        done:true
      })
      console.log(this.cropper.getCroppedCanvas().toDataURL());
    }
  }
  resetCroppedImage = () => {
      this.setState({
        croppedImage: "",
        done:false
      })
  }

  doneCroppedImage = () => {
    console.log(typeof this.props.getImgFromCrop);
      if (typeof this.props.getImgFromCrop === 'function') {
          this.props.getImgFromCrop(this.state.croppedImage);
      }
  }  
  closeEditor = () => {
    if (typeof this.props.closeEditor === 'function') {
      this.props.closeEditor();
    }
  }
  testfunction = () => {
    this.props.testfunction(9);
  }
  handleClose = () => {
    this.setState({ croppedImage: null })
  }

  rotateImage(deg) {
    if (this.cropper) {
      this.cropper.rotate(deg)
    }
  }

  render() {
    const { done } = this.state;
    const { classes } = this.props;
    console.log("llllllllllllllllllllll",this.state.croppedImage);
    return (
      <div className="App2">
        {(done==false) ? (<>
        
        <div className="crop-container">
          <ImageEditorRc
            ref={ref => (this.cropper = ref)}
            crossOrigin="true" // boolean, set it to true if your image is cors protected or it is hosted on cloud like aws s3 image server
            src={this.props.base64URL}
            style={{ height: '250px', width: 100+'%'}}
            aspectRatio={1 / 1}
            className={'test'}
            guides={false}
            cropShape="round"
            cropBoxResizable={false}
            imageName="image_name.jpg"
            responseType="blob/base64"
          />
        </div>
        <div className="controls text-center mt-2">
          {/* <FileBase64 multiple={false} onDone={this.getFiles.bind(this)} /> */}
          <button className="btn dark-btn mr-2"
            onClick={this.showCroppedImage.bind(this)}
          >Select &amp; Crop
          </button>
          <button className="btn dark-btn"
            onClick={this.closeEditor}
          >Cancel
          </button>
          
          {/* <button className="btn dark-btn"
            onClick={this.testfunction}
          >Test Function
        </button>           */}
          {/* <Slider
            value={this.state.zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => this.onZoomChange(zoom)}
          /> */}
        </div>
        </>) : (<>
        <div className="text-center mb-4">
        <img src={this.state.croppedImage} className={`img-fluid img-responsive rounded-circle`}/>
        </div>
        <div className="text-center mt-2">
        <button className="btn dark-btn mr-2"
            onClick={this.resetCroppedImage.bind(this)}
          >Reset Image
        </button>
        <button className="btn dark-btn"
            onClick={this.doneCroppedImage}
          >Done
        </button>
        </div>
        {/* <ImgDialog img={this.state.croppedImage} onClose={this.handleClose} /> */}
        </>)}
      </div>
    )
  }
}

const styles = {
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
}

const StyledApp = withStyles(styles)(imageEditor)

const rootElement = document.getElementById('root')
ReactDOM.render(<StyledApp />, rootElement)
