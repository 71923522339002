import React, { Component } from 'react'
import logo from "../images/logo.png";
import axios from './apiConnection/axios';

import {Link } from "react-router-dom"; 

export default class ResetPassword extends Component {

    constructor(props) {  
        
        super(props);
        this.state = {
            emailid: '',
            emailidError: '',
            Message: '',
            MessageType: ''
        }
        // alert(window.location.href)
    }


    resetPassword = async () => {
          var email = this.state.emailid
        if(!email){
            this.setState({
                Message: 'Email address should not be empty.',
                MessageType: "danger"
            });
            return;
        } else if((typeof email !== "undefined")){
              let lastAtPos = email.lastIndexOf('@');
              let lastDotPos = email.lastIndexOf('.');
              var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
              if (!pattern.test(email)) {
                this.setState({
                    Message: 'Invalid Email address.',
                    MessageType: "danger"
                });
                return;
            } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                this.setState({
                    Message: 'Invalid Email address.',
                    MessageType: "danger"
                });
                return;
            }
          }  


        const resetpasswordinfo = {
            email: this.state.emailid,
            action: "FORGOT_PASSWORD",
            // redirect_url: 'http://localhost:3000/createpassword',
            redirect_url:process.env.REACT_APP_PASSWORD_CREATE_LINK,
        }
        console.log(resetpasswordinfo);

        await axios.post(`/users/send-reset-password-link`, resetpasswordinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log("@response",res);
                
                        if (res.data.code === 200) {
                            this.setState({
                                Message: 'Check your registered email for a notification from us.',
                                MessageType: "success"
                            });
                            // window.location.href = "/createpassword";

                        } else if (res.data.code === 201) {
                            this.setState({
                                Message: res.data.message,
                                MessageType: "danger"
                            });
                        }

                
            })
            .catch((error) => {
                console.log(error)
            })


    }

    changeFunction = (e) => {
        if (e.target.id === 'emailid') {
            this.setState({ emailid: e.target.value });
        } 
        this.setState({
            Message: null,
            MessageType: null
        });
    }

    render() {
        const { Message, MessageType } = this.state;
        return (
            <>
    <div className="body-content">
    <div className="general-user-wrap d-flex py-3 justify-content-between  align-items-center">
            <div className="container">
                <div className="row justify-content-between  align-items-center">
                    <div className="col-lg-6 col-md-5 px-lg-5">
                        <div className="welcome-text text-center">
                            <img src={logo} alt="logo" width="250"/>
                            <h3 className="mb-4 mt-5">
                                Welcome to Core Ai
                            </h3>
                            <p>
                            We provide insights on your sales opportunities so you are focused on the right tasks, at the right time, to win.
                            </p>
                        </div>

                    </div>
                    <div className="col-lg-6 col-md-7">
                        <div className="form-wrap d-flex align-items-center justify-content-center">
                            <div className="w-100">
                                <h3 className="text-center mt-0 mb-3">
                                    Reset Your Password
                                </h3>
                                <p className="text-center mb-lg-5 mb-4">
                                    Please enter your registered email to send reset link.
                                </p>
                                <form>
                                {((Message) && (Message !== null)) ?
                                            (
                                                <div className={`alert alert-${MessageType}`}>
                                                    {Message}
                                                </div>
                                            ) : (``)
                                        }
                                    <div className="form-group mb-lg-5">
                                        <label className="mb-0">Registered Email</label>
                                        <input type="email" id='emailid' className="form-control f-600 f2015" onChange={this.changeFunction} autocomplete={`no`} />
                                        
                                        <small className="color_red">{this.setState.emailidError}</small>
                                    </div>
                                <button type="button" onClick={() => this.resetPassword()} className="btn py-lg-2 dark-btn w-100">Send</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

            </>
        )
    }
}
