import React, { Component } from 'react'
import logo from "../images/logo.png";
import $ from 'jquery';
import moment from 'moment';
import axios from './apiConnection/axios';

export default class AuthScreen extends Component {

    constructor(props) {
        console.log(localStorage.getItem('adminsaToken'));
        super(props);
        this.state = {
            username: '',
            password: '',
            digit1: '',
            digit2: '',
            digit3: '',
            digit4: '',
            digit5: '',
            Message: '',
            MessageType: '',
            showauth: '',
            showauthfull: '',
            time: {}, seconds: 30
        }
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.verifyOTP = this.verifyOTP.bind(this);
        this.sendotp = this.sendotp.bind(this);
    }

    startTimer = () => {
        if (this.state.seconds === 0) {
            this.setState({ seconds: 30 }, () => {
                this.timer = setInterval(this.countDown, 1000);
                this.sendotp();
            });
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        if (this.state.seconds > 0) {
            let seconds = this.state.seconds - 1;
            this.setState({
                time: this.secondsToTime(seconds),
                seconds: seconds,
            });

            // Check if we're at zero.
            if (seconds == 0) {
                clearInterval(this.timer);
            }
        }
    }
    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }
    componentDidMount() {
        var s = localStorage.getItem('showauth');
        var s1 = s.substring(0, 4);
        var s2 = s.substring(10, s.length);
        this.setState({
            showauth: s1 + 'xxxxxx' + s2,
            showauthfull: localStorage.getItem('showauth'),
            emailormobile: localStorage.getItem('emailormobile'),
            twofa_method: localStorage.getItem('twofa_method'),
        })
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        this.timer = setInterval(this.countDown, 1000);

        // this.startTimer();     
    }

    verifyOTP = async (e) => {
        e.preventDefault();
        e.stopPropagation()
        if (((this.state.digit1).length === 1) && ((this.state.digit2).length === 1) && ((this.state.digit3).length === 1) && ((this.state.digit4).length === 1) && ((this.state.digit5).length === 1)) {
            var otpValue = this.state.digit1 + this.state.digit2 + this.state.digit3 + this.state.digit4 + this.state.digit5
            const payLoad = {
                otp: parseInt(otpValue),
                username: localStorage.getItem('usernamefor2fa')
            }
            console.log(payLoad);

            await axios.post(`/users/verifyAuth`, payLoad, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                }
            })
                .then(res => {
                    if (res.data.code === 200) {
                        if (res.data.data.role_id === 1) {
                            localStorage.setItem('adminsaToken', res.data.data.role_id)
                            localStorage.setItem('adminsaUserId', res.data.data.id)
                            localStorage.setItem('full_name', res.data.data.first_name + ' ' + res.data.data.last_name);

                            localStorage.setItem('userid', res.data.data.id)
                            this.setState({
                                is_loggedin: 1,
                                role_id: 1,
                                Message: 'Logged in successfully.',
                                MessageType: 'success'
                            });
                            window.location.href = "/system-admin/dashboard";
                        }
                        if ((res.data.data.role_id === 4) || (res.data.data.role_id === 3) || (res.data.data.role_id === 2)) {
                            localStorage.setItem('adminsrToken', res.data.data.role_id)
                            localStorage.setItem('adminsrUserId', res.data.data.id)

                            localStorage.setItem('userid', res.data.data.id)
                            localStorage.setItem('full_name', res.data.data.first_name + ' ' + res.data.data.last_name);
                            var user_role_id = res.data.data.role_id;
                            this.setState({
                                is_loggedin: 1,
                                role_id: user_role_id,
                                Message: 'Logged in successfully.',
                                MessageType: 'success'
                            });
                            setTimeout(function () {
                                window.location.href = "/app/dashboard";
                            }, 1000);

                        }
                    } else if (res.data.code === 201) {
                        this.setState({
                            Message: 'The code you entered is incorrect.',
                            MessageType: 'danger'
                        });
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            this.setState({
                Message: 'The code you entered is incorrect.',
                MessageType: 'danger'
            });
        }
    }

    changeFunction = (e) => {
        var s = e.target.value;
        var s1 = s.substring(0, 1);
        if (e.target.id === 'digit-1') {
            this.setState({ digit1: e.target.value });
        } else if (e.target.id === 'digit-2') {
            this.setState({ digit2: e.target.value });
        } else if (e.target.id === 'digit-3') {
            this.setState({ digit3: e.target.value });
        } else if (e.target.id === 'digit-4') {
            this.setState({ digit4: e.target.value });
        } else if (e.target.id === 'digit-5') {
            this.setState({ digit5: e.target.value });
        }
        this.setState({
            Message: null,
            MessageType: null
        });
    }

    handleChangeUP = (e) => {
        const { maxLength, value, name } = e.target;
        if (parseInt(e.target.value) > 9) {
            e.target.value = e.target.value.substring(1, 1);
            e.preventDefault();
            return;
        }
    }

    handleChange = (e) => {
        const { maxLength, value, name } = e.target;

        const [fieldName, fieldIndex] = name.split("-");
        if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode === 8) || (e.keyCode === 8)) {
            // 0-9 only
        } else {
            e.preventDefault();
            return;
        }

        let fieldIntIndex = parseInt(fieldIndex, 10);
        if (fieldIntIndex === 5) {
            if ((e.keyCode === 8) && (value.length === 0)) {
                const prevfield = document.querySelector(
                    `input[name=digit-${fieldIntIndex - 1}]`
                );
                if (prevfield !== null) {
                    prevfield.focus();
                    return;
                }
            }
            if (e.keyCode === 8) {
                const currentfield = document.querySelector(
                    `input[name=digit-${fieldIntIndex}]`
                );
                if (currentfield !== null) {
                    currentfield.focus();
                    return;
                }
            }
            if (value.length >= maxLength) {
                e.preventDefault();
                return;
            }

        }
        if (e.keyCode === 8) {
            if (fieldIntIndex >= 1) {

                // Get the next input field using it's name
                const prevfield = document.querySelector(
                    `input[name=digit-${fieldIntIndex - 1}]`
                );

                // If found, focus the prev field
                if (prevfield !== null) {
                    prevfield.value = "";
                    prevfield.focus();
                }
            }
        }

        // Check if no of char in field == maxlength
        if (value.length === maxLength) {

            // It should not be last input field
            if (fieldIntIndex < 5) {

                // Get the next input field using it's name
                const nextfield = document.querySelector(
                    `input[name=digit-${fieldIntIndex + 1}]`
                );

                // If found, focus the next field
                if (nextfield !== null) {
                    nextfield.focus();
                }
            }
        }
    };

    sendotp = async () => {

        var emailormobile = this.state.emailormobile; // 1 for email and 0 for mobile.
        var method = parseInt(this.state.twofa_method); // 1 for email and 0 for mobile.
        var redirect_url = "";
        const profileinfo = {
            'emailormobile': emailormobile,
            'method': method,
            'redirect_url': process.env.REACT_APP_PASSWORD_CREATE_LINK,
        }
        console.log(profileinfo);
        await axios.post(`/users/send-otp`, profileinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res);
                if (res.data.code === 200) {
                    console.log('OTP Sent.')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    render() {
        const { Message, MessageType, showauth } = this.state;
        return (
            <>
                <div className="body-content">
                    <div className="general-user-wrap d-flex py-3 justify-content-between  align-items-center">
                        <div className="container">
                            <div className="row justify-content-between  align-items-center">
                                <div className="col-lg-6 col-md-5 px-lg-5">
                                    <div className="welcome-text text-center">
                                        <img src={logo} alt="logo" width="250" />
                                        <h3 className="mb-4 mt-5">
                                            Welcome to Core Ai
                                        </h3>
                                        <p>
                                            We provide insights on your sales opportunities so you are focused on the right tasks, at the right time, to win.
                                        </p>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-7">
                                    <div className="form-wrap d-flex align-items-center justify-content-center">
                                        <div className="w-100">
                                            <h3 className="text-center mt-0 mb-3">
                                                Authenticate Your Account
                                            </h3>
                                            <p className="text-center mb-lg-5 mb-4">
                                                Please confirm your account by entering the authorization code sent to <span
                                                    className="f-600 color-dark"> {showauth}</span>
                                            </p>
                                            <form className="digit-group">
                                                {((Message) && (Message !== null)) ?
                                                    (
                                                        <div className={`alert alert-${MessageType}`}>
                                                            {Message}
                                                        </div>
                                                    ) : (``)
                                                }
                                                <div className="form-group mb-lg-5">
                                                    <div className="otp-input d-flex justify-content-between">
                                                        <input className="form-control f-600 f2015" type="number" id="digit-1" onChange={this.changeFunction}
                                                            name="digit-1" data-next="digit-2" min="1" max="9" onKeyDown={this.handleChange} onKeyUp={this.handleChangeUP} ref={(ip1) => this.myInp1 = ip1} maxLength="1" />
                                                        <input className="form-control f-600 f2015" type="number" id="digit-2" onChange={this.changeFunction}
                                                            name="digit-2" data-next="digit-3" data-previous="digit-1" min="0" max="9" onKeyDown={this.handleChange} onKeyUp={this.handleChangeUP} ref={(ip2) => this.myInp2 = ip2} maxLength="1" />
                                                        <input className="form-control f-600 f2015" type="number" id="digit-3" onChange={this.changeFunction}
                                                            name="digit-3" data-next="digit-4" data-previous="digit-2" min="0" max="9" onKeyDown={this.handleChange} onKeyUp={this.handleChangeUP} ref={(ip3) => this.myInp3 = ip3} maxLength="1" />
                                                        <input className="form-control f-600 f2015" type="number" id="digit-4" onChange={this.changeFunction}
                                                            name="digit-4" data-next="digit-5" data-previous="digit-3" min="0" max="9" onKeyDown={this.handleChange} onKeyUp={this.handleChangeUP} ref={(ip4) => this.myInp4 = ip4} maxLength="1" />
                                                        <input className="form-control f-600 f2015" type="number" id="digit-5" onChange={this.changeFunction}
                                                            name="digit-5" data-next="digit-6" data-previous="digit-4" min="0" max="9" onKeyDown={this.handleChange} onKeyUp={this.handleChangeUP} ref={(ip5) => this.myInp5 = ip5} maxLength="1" />
                                                    </div>
                                                </div>
                                                <button type="button" onClick={this.verifyOTP} className="btn py-lg-2 mt-lg-4 dark-btn w-100">Submit</button>

                                                <div className="text-center mt-3">
                                                    <div className="color_blue">
                                                        {(this.state.time.s === 0) ? (<>
                                                            <a className="underline color_blue p-0" onClick={() => this.startTimer()} > Resend OTP</a>
                                                        </>
                                                        ) : (
                                                            <>
                                                                <i className="color_light_gray p-0" > Resend OTP</i> in {this.state.time.s} seconds.                                                </>

                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}