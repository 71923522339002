import axios from 'axios';


const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});
var user_id = ""
if (localStorage.getItem('userid')) {
    user_id = localStorage.getItem('userid')
}
instance.defaults.headers.common['userid'] = user_id;

export default instance;