import React, { Component } from 'react'
import { render } from 'react-dom';

import revenue from "../../images/revenue.svg";
import growth from "../../images/growth.svg";
import conversionrate from "../../images/conversion-rate.svg";
import compliant from "../../images/compliant.svg";
import { CSVLink } from "react-csv";
import prospect from "../../images/work-in-progress.svg";
import speedometer from "../../images/speedometer.svg";
import increasedrevenue from "../../images/increased-revenue.svg";
import taskcomplete from "../../images/task-complete.svg";
import closeicon from "../../images/growth-icon.png";
import measure from "../../images/measure-icon.png";
import CustomSelect from "./elements/customSelect";
import axios from '../apiConnection/axios';
import salesteam from "../../images/sales-team.svg";

export default class reports extends Component {
    constructor(props) {
        super(props);
        const year = new Date().getFullYear();
        this.state = {
            year: year,
            sales_team: "",
            showLegends: false,
            defaultQuarterText: "All Data ",
            defaultQuarterTextCurrentYear: "Year To Date",
            quarterList: [
                { id: -1, name: "All Data ", value: -1 },
                { id: 0, name: "Year To Date " + year, value: 0 },
                { id: 1, name: "1st Quarter " + year, value: 1 },
                { id: 2, name: "2nd Quarter " + year, value: 2 },
                { id: 3, name: "3rd Quarter " + year, value: 3 },
                { id: 4, name: "4th Quarter " + year, value: 4 },
            ],
            user_id: "",
            repSecondExport: [],
            repSecondExportHeader: [
                { label: "Opportunity Name", key: "name" },
                { label: "Speed to Close (Days)", key: "speed_to_close" }
            ],
            repSecondExportFilename: "",
            ninthExport: [],
            ninthExportHeader: [
                { label: "Division/Team Name", key: "name" },
                { label: "Targeted Coaching Sessions", key: "targeted_coaching_sessions" },
                { label: "Remaing to Target", key: "remaining_coaching_sessions" },
                { label: "Completed", key: "completed_coaching_sessions" },
                { label: "Scheduled", key: "scheduled_coaching_sessions" },
                { label: "In Process", key: "in_process_coaching_sessions" }
            ],
            ninthExportFilename: "",
            eightExport: [],
            eightExportHeader: [
                { label: "Sales Rep Name", key: "name" },
                { label: "Targeted Coaching Sessions", key: "targeted_coaching_sessions" },
                { label: "Remaing to Target", key: "remaining_coaching_sessions" },
                { label: "Completed", key: "completed_coaching_sessions" },
                { label: "Scheduled", key: "scheduled_coaching_sessions" },
                { label: "In Process", key: "in_process_coaching_sessions" }
            ],
            eightExportFilename: "",
            seventhExport: [],
            seventhExportHeader: [
                { label: "Sales Rep Name", key: "name" },
                { label: "Expected Revenue", key: "expected_revenue" }
            ],
            seventhExportFilename: "",
            sixthExport: [],
            sixthExportHeader: [
                { label: "Sales Rep Name", key: "name" },
                { label: "Opportunity Conversion Rate(%)", key: "conversion_rate" }
            ],
            sixthExportFilename: "",
            fifthExport: [],
            fifthExportHeader: [
                { label: "Sales Rep Name", key: "name" },
                { label: "Task Progression (%)", key: "task_progression" }
            ],
            fifthExportFilename: "",
            fourthExport: [],
            fourthExportHeader: [
                { label: "Sales Rep Name", key: "name" },
                { label: "Average speed to close (Days)", key: "speed_to_close" }
            ],
            fourthExportFilename: "",
            thirdExport: [],
            thirdExportHeader: [
                { label: "Sales Rep Name", key: "name" },
                { label: "Opportunity", key: "opportunity" },
                { label: "Opportunity Status", key: "status" },
                { label: "Opportunity Closed Date", key: "end_date" },
                { label: "Revenue ($)", key: "revenue" }
            ],
            thirdExportFilename: "",
            secondExport: [],
            secondExportHeader: [
                { label: "Sales Rep Name", key: "name" },
                { label: "Average Revenue ($)", key: "average_revenue" }
            ],
            secondExportFilename: "",
            firstExport: [],
            firstExportHeader: [
                { label: "Sales Rep Name", key: "name" },
                { label: "Average Revenue ($)", key: "average_revenue" }
            ],
            firstExportFilename: "",
            salesRepThirdExport: [],
            salesRepThirdExportHeader: [
                { label: "Opportunity Name", key: "opportunity_name" },
                { label: "Opportunity Status", key: "opportunity_status" },
                { label: "Start Date in CoreAi", key: "start_date_in_core_ai" },
                { label: "Opportunity Closed Date", key: "end_date" },
                { label: "Expected Revenue ($)", key: "expected_revenue" },
                { label: "Revenue ($)", key: "revenue" }
            ],
            salesRepThirdExportFilename: "",


            companyAdminfourthExport: [],
            companyAdminfourthExportHeader: [
                { label: "Sales Division/Team", key: "name" },
                { label: "Average speed to close (Days)", key: "speed_to_close" }
            ],
            companyAdminfourthExportFilename: "",

            companyAdminfifthExport: [],
            companyAdminfifthExportHeader: [
                { label: "Sales Division/Team", key: "name" },
                { label: "Average Revenue ($)", key: "average_revenue" }
            ],
            companyAdminfifthExportFilename: "",
            user_type: "",
            team_name: "",
            company_name: "",

        }
    }

    componentDidMount = () => {
        const user_id = localStorage.getItem('userid');
        const user_role = localStorage.getItem('adminsrToken');
        const full_name = localStorage.getItem('full_name');

        this.setState({ user_role, full_name, sales_team: 'Team_Name' });
        this.setState({ user_id: user_id }, () => {
            if (user_role == 2) {
                this.getDivisionManagerType();
                this.getCompanyName();
                setTimeout(() => {
                    this.changeQuarter(-1, 'quarter_select_first')
                    this.changeQuarter(-1, 'quarter_select_second')
                    this.changeQuarter(-1, 'quarter_select_third');
                    this.changeQuarter(-1, 'quarter_select_fourth');
                    this.changeQuarter(-1, 'quarter_select_fifth');
                    this.changeQuarter(-1, 'quarter_select_sixth');
                    this.changeQuarter(0, 'quarter_select_eight');
                }, 1500);
            } else if (user_role == 3) {
                this.changeQuarter(0, 'quarter_select_eight');
                this.changeQuarter(-1, 'quarter_select_seventh');
                this.changeQuarter(-1, 'quarter_select_sixth');
                this.changeQuarter(-1, 'quarter_select_fifth');
                this.changeQuarter(-1, 'quarter_select_fourth');
                this.changeQuarter(-1, 'quarter_select_third');
                this.changeQuarter(-1, 'quarter_select_second');
                this.changeQuarter(-1, 'quarter_select_first');
                this.getTeamName();
            } else if (user_role == 4) {
                this.changeQuarter(-1, 'quarter_select_second');
                this.changeQuarter(-1, 'quarter_select_third');
                this.changeQuarter(-1, 'rep_quarter_select_first');
                this.changeQuarter(-1, 'rep_quarter_select_fourth');

            }
        });

    }
    // For Header and Show Team Name
    getTeamName = async () => {
        // http://localhost:3004/api/v1/dashboard/get-company-admin-dashboard-title
        const { user_id } = this.state;
        const payload = {
            company_admin_id: user_id,
        }
        await axios.post(`dashboard/get-company-admin-dashboard-title`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var sales_team = res.data.data[0].node_name;
                    // var sales_team = res.data.data[0].company_name + ' ' + res.data.data[0].node_name;
                    this.setState({ sales_team });
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }
    // For Title fetch
    getCompanyName = async () => {
        const { user_id } = this.state;
        const payload = {
            company_admin_id: user_id,
        }
        await axios.post(`dashboard/get-company-admin-dashboard-title`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    this.setState({ team_name: res.data.data[0].node_name, company_name: res.data.data[0].company_name }, () => {
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    // For Header and Show Role wise headers
    getDivisionManagerType = async () => {
        // http://localhost:3004/api/v1/dashboard/get-company-admin-dashboard-title
        const { user_id } = this.state;
        const payload = {
            user_id,
        }
        await axios.post(`reports/check-company-admin`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var user_type = res.data.data[0].user;
                    // var sales_team = res.data.data[0].company_name + ' ' + res.data.data[0].node_name;
                    this.setState({ user_type });
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    // Quarter filter change function
    changeQuarter = (ele, Uid) => {
        const { user_role } = this.state;
        var year = new Date().getFullYear();
        var month = new Date().getMonth() + 1;
        var day = new Date().getDate();
        if (month.toString().length < 2) {
            month = '0' + month;
        }
        if (day.toString().length < 2) {
            day = '0' + day;
        }
        var startdate = "";
        var enddate = "";
        var filter = "Quarter";
        if (ele == 0) {
            startdate = year + '-01-01';
            enddate = year + '-' + month + '-' + day;
            filter = "";
        } else if (ele == 1) {
            startdate = year + '-01-01';
            enddate = year + '-03-31';
        } else if (ele == 2) {
            startdate = year + '-04-01';
            enddate = year + '-06-30';
        } else if (ele == 3) {
            startdate = year + '-07-01';
            enddate = year + '-09-30';
        } else if (ele == 4) {
            startdate = year + '-10-01';
            enddate = year + '-12-31';
        } else if (ele === -1) {
            startdate = '';
            enddate = '';
            filter = "";
            if (Uid == "quarter_select_eight") {
                var year = new Date().getFullYear();
                var month = new Date().getMonth() + 1;
                var day = new Date().getDate();
                if (month.toString().length < 2) {
                    month = '0' + month;
                }
                if (day.toString().length < 2) {
                    day = '0' + day;
                }
                startdate = '2021-01-01';
                enddate = year + '-' + month + '-' + day;

            }
        }

        if (Uid == "quarter_select_eight") {
            this.setState({ start_date_eight: startdate, end_date_eight: enddate }, () => {
                (user_role == 2) ? this.getCAEightAPICall(filter) : (user_role == 3) ? this.getEightAPICall(filter) : this.getEightAPICall();
            })
        }
        if (Uid == "quarter_select_seventh") {
            this.setState({ start_date_seventh: startdate, end_date_seventh: enddate }, () => {
                (user_role == 2) ? this.getSeventhAPICall() : (user_role == 3) ? this.getSeventhAPICall() : this.getSeventhAPICall();
            })
        }
        if (Uid == "quarter_select_sixth") {
            this.setState({ start_date_sixth: startdate, end_date_sixth: enddate }, () => {
                (user_role == 2) ? this.getCASixthAPICall() : (user_role == 3) ? this.getSixthAPICall() : this.getSixthAPICall();
            })
        }
        if (Uid == "quarter_select_fifth") {
            this.setState({ start_date_fifth: startdate, end_date_fifth: enddate }, () => {
                (user_role == 2) ? this.getCAFifthAPICall() : (user_role == 3) ? this.getFifthAPICall() : this.getFifthAPICall();
            })
        }
        if (Uid == "quarter_select_fourth") {
            this.setState({ start_date_fourth: startdate, end_date_fourth: enddate }, () => {
                (user_role == 2) ? this.getCAFourthAPICall() : (user_role == 3) ? this.getFourthAPICall() : this.getFourthAPICall();
            })
        }
        if (Uid == "quarter_select_third") {
            this.setState({ start_date_third: startdate, end_date_third: enddate }, () => {
                (user_role == 2) ? this.getCAThirdAPICall() : (user_role == 3) ? this.getThirdAPICall() : (user_role == 4) ? this.getRepThirdAPICall() : this.getRepThirdAPICall();
            })
        }
        if (Uid == "quarter_select_second") {
            this.setState({ start_date_second: startdate, end_date_second: enddate }, () => {
                (user_role == 2) ? this.getCASecondAPICall() : (user_role == 3) ? this.getSecondAPICall() : this.getRepSecondAPICall();
            })
        }
        if (Uid == "quarter_select_first") {
            this.setState({ start_date_first: startdate, end_date_first: enddate }, () => {
                (user_role == 2) ? this.getCAFirstAPICall() : (user_role == 3) ? this.getFirstAPICall() : this.getFirstAPICall();
            })
        }
        if (Uid == "rep_quarter_select_first") {
            this.setState({ start_date_first: startdate, end_date_first: enddate }, () => {
                (user_role == 2) ? this.getRepFirstAPICall() : (user_role == 3) ? this.getRepFirstAPICall() : this.getRepFirstAPICall();
            })
        }
        if (Uid == "rep_quarter_select_fourth") {
            this.setState({ start_date_fourth: startdate, end_date_fourth: enddate }, () => {
                (user_role == 2) ? this.getRepFourthAPICall() : (user_role == 3) ? this.getRepFourthAPICall() : this.getRepFourthAPICall();
            })
        }

        // if (Uid=="quarter_select_sixth"){
        //     this.setState({start_date_sixth : startdate, end_date_sixth : enddate}, ()=> {
        //         (user_role==2) ? this.getSixthAPICall() :(user_role==3) ? this.getSixthAPICall() : this.getSixthAPICall();   
        //     })
        // }
    }

    // to get total for calculate progress bar percentages
    add = (accumulator, a) => {
        return parseFloat(accumulator) + parseFloat(a);
    }



    /////////////////////////////  203
    // Sales Manager API Start 
    //   reports/sales-manager/coaching-compliance
    getEightAPICall = async (filter) => {
        const { user_id, start_date_eight, end_date_eight, eightExport, eightExportHeader, year } = this.state;
        const payload = {
            sales_manager_id: user_id,
            start_date: start_date_eight,
            end_date: end_date_eight,
            filter_type: filter,
            year: year
        }
        await axios.post(`reports/sales-manager/coaching-compliance`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var eightExport = [];

                    if ((res.data.data[0].coaching_compliance) && (res.data.data[0].coaching_compliance.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].coaching_compliance)) {
                            var userArray = {
                                name: res.data.data[0].coaching_compliance[key].sales_rep_first_name + ' ' + res.data.data[0].coaching_compliance[key].sales_rep_last_name,
                                targeted_coaching_sessions: res.data.data[0].coaching_compliance[key].target_no_of_coaching_session,
                                remaining_coaching_sessions: res.data.data[0].coaching_compliance[key].remaining_sessions_count,
                                completed_coaching_sessions: res.data.data[0].coaching_compliance[key].completed_sessions_count,
                                scheduled_coaching_sessions: res.data.data[0].coaching_compliance[key].scheduled_sessions_count,
                                in_process_coaching_sessions: res.data.data[0].coaching_compliance[key].in_process_sessions_count,
                            }
                            eightExport.push(userArray);
                        }
                    }

                    var export_file_name = "Coaching_Compliance" + ((start_date_eight !== null && start_date_eight != "" && start_date_eight != "2021-01-01") ? `-${start_date_eight}` : ``) + ((end_date_eight !== null && end_date_eight != "" && start_date_eight != "2021-01-01") ? `-${end_date_eight}` : ``);
                    this.setState({
                        eightExportFilename: export_file_name,
                        eightExport,
                        sessions_complaince: res.data.data[0].coaching_compliance
                    }, () => {
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    //   reports/sales-manager/expected-opportunity-revenue      
    getSeventhAPICall = async () => {
        const { user_id, start_date_seventh, end_date_seventh, seventhExport, seventhExportHeader } = this.state;
        const payload = {
            sales_manager_id: user_id,
            start_date: start_date_seventh,
            end_date: end_date_seventh
        }
        await axios.post(`reports/sales-manager/expected-opportunity-revenue`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesSeventh = [];
                    var valuesSeventh = [];
                    // var valuesSeventhFormatted = [];
                    var seventhExport = [];

                    if ((res.data.data[0].excepected_opportunity_revenue_by_sales_rep) && (res.data.data[0].excepected_opportunity_revenue_by_sales_rep.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].excepected_opportunity_revenue_by_sales_rep)) {
                            // var valuesSeventh = (res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].opportunity_percentage).toFixed(0);
                            valuesSeventh.push(parseInt(res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].excepected_opp_revenue));
                            // valuesSeventhFormatted.push(res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].excepected_opp_revenue_formatted);
                            var userArray = {
                                name: res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].sales_rep_first_name + ' ' + res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].sales_rep_last_name,
                                // username : res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].sales_rep_username,
                                // email : res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].sales_rep_email,
                                expected_revenue: res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].excepected_opp_revenue,
                            }
                            seventhExport.push(userArray);
                        }
                        var total = valuesSeventh.reduce(this.add, 0);
                        for (const [key, value] of Object.entries(res.data.data[0].excepected_opportunity_revenue_by_sales_rep)) {
                            // $percentages[$value] = $count / $total;
                            // var valuesSeventh = (res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].opportunity_percentage).toFixed(0);
                            var percentage = ((res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].excepected_opp_revenue / total) * 100);
                            if (isNaN(percentage) || percentage === null) {
                                percentageValuesSeventh.push(0);
                            } else {
                                percentageValuesSeventh.push(percentage.toFixed(0));
                            }
                        }
                    }

                    var export_file_name = "Expected_Opportunity_Revenue_By_Sales_Rep" + ((start_date_seventh !== null && start_date_seventh != "") ? `-${start_date_seventh}` : ``) + ((end_date_seventh !== null && end_date_seventh != "") ? `-${end_date_seventh}` : ``);
                    this.setState({
                        seventhExportFilename: export_file_name,
                        percentageValuesSeventh, valuesSeventh, seventhExport,
                        excepected_opportunity_revenue_by_sales_rep: res.data.data[0].excepected_opportunity_revenue_by_sales_rep
                    }, () => {
                        console.log(this.state.percentageValuesSeventh);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-manager/opportunity-conversion-rate
    getSixthAPICall = async () => {
        const { user_id, start_date_sixth, end_date_sixth, sixthExport, sixthExportHeader } = this.state;
        const payload = {
            sales_manager_id: user_id,
            start_date: start_date_sixth,
            end_date: end_date_sixth
        }
        await axios.post(`reports/sales-manager/opportunity-conversion-rate`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesSixth = [];
                    var valuesSixth = [];
                    var sixthExport = [];

                    if ((res.data.data[0].opportunity_conversion_rate_per_sales_rep) && (res.data.data[0].opportunity_conversion_rate_per_sales_rep.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].opportunity_conversion_rate_per_sales_rep)) {
                            // var valuesSixth = (res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].opportunity_percentage).toFixed(0);
                            valuesSixth.push(parseInt(res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].opp_conversion_rate_per_sales_rep));
                            var percentage = res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].opp_conversion_rate_per_sales_rep;
                            if (isNaN(percentage) || percentage === null) {
                                percentage = 0;
                            } else {
                                percentage = percentage.toFixed(0);
                            }
                            var userArray = {
                                name: res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].sales_rep_first_name + ' ' + res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].sales_rep_last_name,
                                // username : res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].sales_rep_username,
                                // email : res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].sales_rep_email,
                                conversion_rate: percentage,
                            }
                            sixthExport.push(userArray);
                        }
                        var total = valuesSixth.reduce(this.add, 0);
                        for (const [key, value] of Object.entries(res.data.data[0].opportunity_conversion_rate_per_sales_rep)) {
                            // $percentages[$value] = $count / $total;
                            // var valuesSixth = (res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].opportunity_percentage).toFixed(0);
                            var percentage = parseInt(res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].opp_conversion_rate_per_sales_rep);
                            if (isNaN(percentage) || percentage === null) {
                                percentageValuesSixth.push(0);
                            } else {
                                percentageValuesSixth.push(percentage.toFixed(0));
                            }
                        }
                    }
                    var export_file_name = "Opportunity_Conversion_Rate_Per_Sales_Rep" + ((start_date_sixth !== null && start_date_sixth != "") ? `-${start_date_sixth}` : ``) + ((end_date_sixth !== null && end_date_sixth != "") ? `-${end_date_sixth}` : ``);

                    this.setState({
                        sixthExportFilename: export_file_name,
                        percentageValuesSixth, valuesSixth, sixthExport,
                        opportunity_conversion_rate_per_sales_rep: res.data.data[0].opportunity_conversion_rate_per_sales_rep
                    }, () => {
                        console.log(this.state.percentageValuesSixth);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-manager/task-progression
    getFifthAPICall = async () => {
        const { user_id, start_date_fifth, end_date_fifth, fifthExport, fifthExportHeader } = this.state;
        const payload = {
            sales_manager_id: user_id,
            start_date: start_date_fifth,
            end_date: end_date_fifth
        }
        await axios.post(`reports/sales-manager/task-progression`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesFifth = [];
                    var valuesFifth = [];
                    var fifthExport = [];

                    var headersArray = [];
                    if ((res.data.data[0].sales_stages) && (res.data.data[0].sales_stages.length > 0)) {
                        headersArray.push({ label: "Sales Rep Name", key: "name" });
                        for (const [key, value] of Object.entries(res.data.data[0].sales_stages)) {
                            headersArray.push({ label: res.data.data[0].sales_stages[key].stage_name + '(%)', key: res.data.data[0].sales_stages[key].stage_name });
                        }

                    }
                    if ((res.data.data[0].task_progression) && (res.data.data[0].task_progression.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].task_progression)) {
                            var userArray = {};
                            var repname = res.data.data[0].task_progression[key].sales_rep_first_name + ' ' + res.data.data[0].task_progression[key].sales_rep_last_name
                            userArray['name'] = repname;
                            for (const [key2, value3] of Object.entries(res.data.data[0].task_progression[key].task_progression)) {
                                var keyshow = res.data.data[0].task_progression[key].task_progression[key2].stage_name;
                                var valueshow = res.data.data[0].task_progression[key].task_progression[key2].task_progression_percentage.toFixed(0);
                                userArray[keyshow] = valueshow;
                            }
                            fifthExport.push(userArray);
                        }
                    }
                    var export_file_name = "Task_progression" + ((start_date_fifth !== null && start_date_fifth != "") ? `-${start_date_fifth}` : ``) + ((end_date_fifth !== null && end_date_fifth != "") ? `-${end_date_fifth}` : ``);

                    this.setState({
                        fifthExportFilename: export_file_name,
                        fifthExportHeader: headersArray,
                        percentageValuesFifth, valuesFifth, fifthExport,
                        task_progression: res.data.data[0].task_progression,
                        sales_stages: res.data.data[0].sales_stages
                    }, () => {
                        console.log(this.state.task_progression);
                        console.log(this.state.sales_stages);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-manager/avergae-speed-to-close
    getFourthAPICall = async () => {
        const { user_id, start_date_fourth, end_date_fourth, fourthExport, fourthExportHeader } = this.state;
        const payload = {
            sales_manager_id: user_id,
            start_date: start_date_fourth,
            end_date: end_date_fourth
        }
        await axios.post(`reports/sales-manager/avergae-speed-to-close`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesFourth = [];
                    var valuesFourth = [];
                    var fourthExport = [];

                    if ((res.data.data[0].average_speed_to_close) && (res.data.data[0].average_speed_to_close.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].average_speed_to_close)) {
                            // var valuesFourth = (res.data.data[0].average_speed_to_close[key].opportunity_percentage).toFixed(0);
                            var percentage = parseFloat(res.data.data[0].average_speed_to_close[key].opp_speed_to_close);
                            if (isNaN(percentage) || percentage === null) {
                                percentage = 0;
                            } else {
                                percentage = percentage.toFixed(0);
                            }
                            valuesFourth.push(percentage);
                            var userArray = {
                                name: res.data.data[0].average_speed_to_close[key].sales_rep_first_name + ' ' + res.data.data[0].average_speed_to_close[key].sales_rep_last_name,
                                speed_to_close: percentage,
                            }
                            fourthExport.push(userArray);
                        }
                        var total = valuesFourth.reduce(this.add, 0);
                        for (const [key, value] of Object.entries(res.data.data[0].average_speed_to_close)) {
                            var percentage = ((parseFloat(res.data.data[0].average_speed_to_close[key].opp_speed_to_close / total)) * 100);
                            if (isNaN(percentage) || percentage === null) {
                                percentageValuesFourth.push(0);
                            } else {
                                percentageValuesFourth.push(percentage.toFixed(0));
                            }
                        }
                        console.log(total);
                    }
                    var export_file_name = "Average_Speed_To_Close" + ((start_date_fourth !== null && start_date_fourth != "") ? `-${start_date_fourth}` : ``) + ((end_date_fourth !== null && end_date_fourth != "") ? `-${end_date_fourth}` : ``);

                    this.setState({
                        fourthExportFilename: export_file_name,
                        percentageValuesFourth, valuesFourth, fourthExport,
                        average_speed_to_close: res.data.data[0].average_speed_to_close
                    }, () => {
                        console.log(this.state.percentageValuesFourth, total);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-manager/revenue-by-opportunity
    getThirdAPICall = async () => {
        const { user_id, start_date_third, end_date_third, thirdExport, thirdExportHeader } = this.state;
        const payload = {
            sales_manager_id: user_id,
            start_date: start_date_third,
            end_date: end_date_third
        }
        await axios.post(`reports/sales-manager/revenue-by-opportunity`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesThird = [];
                    var valuesThird = [];
                    var thirdExport = [];

                    if ((res.data.data[0].revenue_by_opportunity) && (res.data.data[0].revenue_by_opportunity.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].revenue_by_opportunity)) {
                            var userArray = {
                                name: res.data.data[0].revenue_by_opportunity[key].sales_rep_first_name + ' ' + res.data.data[0].revenue_by_opportunity[key].sales_rep_last_name,
                                opportunity: res.data.data[0].revenue_by_opportunity[key].opportunity_name,
                                status: res.data.data[0].revenue_by_opportunity[key].opportunity_status,
                                end_date: res.data.data[0].revenue_by_opportunity[key].opportunity_closed_date,
                                revenue: res.data.data[0].revenue_by_opportunity[key].opportunity_revenue,
                            }
                            thirdExport.push(userArray);
                        }
                    }
                    var export_file_name = "Revenue_By_Opportunity" + ((start_date_third !== null && start_date_third != "") ? `-${start_date_third}` : ``) + ((end_date_third !== null && end_date_third != "") ? `-${end_date_third}` : ``);

                    this.setState({
                        thirdExportFilename: export_file_name,
                        percentageValuesThird, valuesThird, thirdExport,
                        revenue_by_opportunity: res.data.data[0].revenue_by_opportunity
                    }, () => {
                        // console.log(this.state.percentageValuesThird, total);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-manager/average-revenue-per-opportunity        
    getSecondAPICall = async () => {
        const { user_id, start_date_second, end_date_second, secondExport, secondExportHeader } = this.state;
        const payload = {
            sales_manager_id: user_id,
            start_date: start_date_second,
            end_date: end_date_second
        }
        await axios.post(`reports/sales-manager/average-revenue-per-opportunity`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesSecond = [];
                    var valuesSecond = [];
                    var secondExport = [];

                    if ((res.data.data[0].average_revenue_per_opportunity) && (res.data.data[0].average_revenue_per_opportunity.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].average_revenue_per_opportunity)) {
                            // var valuesSecond = (res.data.data[0].average_revenue_per_opportunity[key].opportunity_percentage).toFixed(0);
                            valuesSecond.push(parseInt(res.data.data[0].average_revenue_per_opportunity[key].average_revenue));
                            var percentage = res.data.data[0].average_revenue_per_opportunity[key].average_revenue;
                            if (isNaN(percentage) || percentage === null) {
                                percentage = 0;
                            } else {
                                percentage = percentage.toFixed(0);
                            }
                            var userArray = {
                                name: res.data.data[0].average_revenue_per_opportunity[key].sales_rep_first_name + ' ' + res.data.data[0].average_revenue_per_opportunity[key].sales_rep_last_name,
                                // username : res.data.data[0].average_revenue_per_opportunity[key].sales_rep_username,
                                // email : res.data.data[0].average_revenue_per_opportunity[key].sales_rep_email,
                                average_revenue: percentage,
                            }
                            secondExport.push(userArray);
                        }
                        var total = valuesSecond.reduce(this.add, 0);
                        for (const [key, value] of Object.entries(res.data.data[0].average_revenue_per_opportunity)) {
                            // $percentages[$value] = $count / $total;
                            // var valuesSecond = (res.data.data[0].average_revenue_per_opportunity[key].opportunity_percentage).toFixed(0);
                            var percentage = parseFloat((res.data.data[0].average_revenue_per_opportunity[key].average_revenue / total) * 100);
                            if (isNaN(percentage) || percentage === null) {
                                percentageValuesSecond.push(0);
                            } else {
                                percentageValuesSecond.push(percentage.toFixed(0));
                            }
                        }
                    }
                    var export_file_name = "Average_Revenue_Per_Opportunity" + ((start_date_second !== null && start_date_second != "") ? `-${start_date_second}` : ``) + ((end_date_second !== null && end_date_second != "") ? `-${end_date_second}` : ``);

                    this.setState({
                        secondExportFilename: export_file_name,
                        percentageValuesSecond, valuesSecond, secondExport,
                        average_revenue_per_opportunity: res.data.data[0].average_revenue_per_opportunity
                    }, () => {
                        console.log(this.state.percentageValuesSecond, total);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-manager/opportunity-task-completion
    getFirstAPICall = async () => {
        const { user_id, start_date_first, end_date_first, firstExport, firstExportHeader } = this.state;
        const payload = {
            sales_manager_id: user_id,
            start_date: start_date_first,
            end_date: end_date_first
        }
        await axios.post(`reports/sales-manager/opportunity-task-completion`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesFirst = [];
                    var valuesFirst = [];
                    var firstExport = [];
                    var headersArray = [];
                    if ((res.data.data[0].sales_stages) && (res.data.data[0].sales_stages.length > 0)) {
                        headersArray.push({ label: "Sales Rep Name", key: "name" });
                        headersArray.push({ label: "Opportunity Name", key: "opportunity_name" });
                        headersArray.push({ label: "Opportunity Status", key: "opportunity_status" });
                        for (const [key, value] of Object.entries(res.data.data[0].sales_stages)) {
                            headersArray.push({ label: res.data.data[0].sales_stages[key].stage_name, key: String(res.data.data[0].sales_stages[key].stage_id) });
                        }

                    }
                    if ((res.data.data[0].opportunity_task_completion) && (res.data.data[0].opportunity_task_completion.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].opportunity_task_completion)) {
                            var userArray = {};

                            var repname = res.data.data[0].opportunity_task_completion[key].sales_rep_first_name + ' ' + res.data.data[0].opportunity_task_completion[key].sales_rep_last_name;
                            var opportunity_name = res.data.data[0].opportunity_task_completion[key].opportunity_name;
                            var opportunity_status = res.data.data[0].opportunity_task_completion[key].status;
                            userArray['name'] = repname;
                            userArray['opportunity_name'] = opportunity_name;
                            userArray['opportunity_status'] = opportunity_status;
                            for (const [key2, value2] of Object.entries(res.data.data[0].opportunity_task_completion[key].task_completion)) {
                                var keyshow = String(res.data.data[0].opportunity_task_completion[key].task_completion[key2].stage_id);
                                var valueshow = String(res.data.data[0].opportunity_task_completion[key].task_completion[key2].completed_tasks);
                                userArray[keyshow] = valueshow;
                            }
                            firstExport.push(userArray);
                        }
                    }
                    var export_file_name = "Opportunity_Task_Completion" + ((start_date_first !== null && start_date_first != "") ? `-${start_date_first}` : ``) + ((end_date_first !== null && end_date_first != "") ? `-${end_date_first}` : ``);

                    this.setState({
                        firstExportFilename: export_file_name,
                        firstExportHeader: headersArray,
                        percentageValuesFirst, valuesFirst, firstExport,
                        opportunity_task_completion: res.data.data[0].opportunity_task_completion,
                        sales_stages: res.data.data[0].sales_stages
                    }, () => {
                        // console.log(this.state.percentageValuesFirst, total);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    ///////////////////////// 205
    // Sales Rep API Start 

    // reports/sales-rep/task-progression
    getRepFirstAPICall = async () => {
        const { user_id, start_date_first, end_date_first, firstExport, firstExportHeader } = this.state;
        const payload = {
            sales_rep_id: user_id,
            start_date: start_date_first,
            end_date: end_date_first
        }
        await axios.post(`reports/sales-rep/task-progression`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesFirst = [];
                    var valuesFirst = [];
                    var firstExport = [];

                    var headersArray = [];
                    if ((res.data.data[0].sales_stages) && (res.data.data[0].sales_stages.length > 0)) {
                        headersArray.push({ label: "Opportunity Name", key: "name" });
                        for (const [key, value] of Object.entries(res.data.data[0].sales_stages)) {
                            headersArray.push({ label: res.data.data[0].sales_stages[key].stage_name + '(%)', key: res.data.data[0].sales_stages[key].stage_name });
                        }

                    }
                    if ((res.data.data[0].task_completion) && (res.data.data[0].task_completion.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].task_completion)) {
                            var userArray = {};
                            // var repname = res.data.data[0].task_completion[key].sales_rep_first_name + ' '+res.data.data[0].task_completion[key].sales_rep_last_name
                            var opportunity_name = res.data.data[0].task_completion[key].opportunity_name;
                            userArray['name'] = opportunity_name;
                            for (const [key2, value3] of Object.entries(res.data.data[0].task_completion[key].stages_arr)) {
                                var keyshow = res.data.data[0].task_completion[key].stages_arr[key2].stage_name;
                                var valueshow = res.data.data[0].task_completion[key].stages_arr[key2].percentage;
                                userArray[keyshow] = valueshow;
                            }
                            firstExport.push(userArray);
                        }
                    }
                    var export_file_name = "Task_progression" + ((start_date_first !== null && start_date_first != "") ? `-${start_date_first}` : ``) + ((end_date_first !== null && end_date_first != "") ? `-${end_date_first}` : ``);

                    this.setState({
                        firstExportFilename: export_file_name,
                        firstExportHeader: headersArray,
                        percentageValuesFirst, valuesFirst, firstExport,
                        task_completion2: res.data.data[0].task_completion,
                        sales_stages: res.data.data[0].sales_stages
                    }, () => {
                        console.log(this.state.task_completion);
                        console.log(this.state.sales_stages);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-rep/avergae-speed-to-close
    getRepSecondAPICall = async () => {
        const { user_id, start_date_second, end_date_second, repSecondExport, repSecondExportHeader } = this.state;
        const payload = {
            sales_rep_id: user_id,
            start_date: start_date_second,
            end_date: end_date_second
        }
        await axios.post(`reports/sales-rep/avergae-speed-to-close`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var repSpeedPercent = [];
                    var valuesSecondRep = [];
                    var repSecondExport = [];
                    var closed_won_oppo = 0;
                    var closed_won_lost_oppo = 0;
                    var average_speed_closed_won_oppo = 0;
                    var average_speed_closed_won_lost_oppo = 0;
                    var total_won_lost_oppo = 0;
                    var total_won_opp = 0;
                    var total_won_lost_days = 0;
                    var total_won_days = 0;
                    if ((res.data.data[0].average_speed_to_close) && (res.data.data[0].average_speed_to_close.length > 0)) {
                        var max_days = 0;
                        total_won_lost_oppo = res.data.data[0].average_speed_to_close.length;
                        for (const [key, value] of Object.entries(res.data.data[0].average_speed_to_close)) {
                            // var valuesFourth = (res.data.data[0].average_speed_to_close[key].opportunity_percentage).toFixed(0);
                            var days = parseFloat(res.data.data[0].average_speed_to_close[key].opp_speed_to_close);
                            if (isNaN(days) || days === null) {
                                days = 0;
                            } else {
                                days = days.toFixed(0);
                            }
                            if (parseInt(days) > parseInt(max_days)) {
                                max_days = days;
                            }
                            valuesSecondRep.push(days);
                            var userArray = {
                                name: res.data.data[0].average_speed_to_close[key].opportunity_name,
                                speed_to_close: days,
                            }
                            repSecondExport.push(userArray);

                            if (res.data.data[0].average_speed_to_close[key].opportunity_status == 1) {
                                total_won_opp = total_won_opp + 1;
                                total_won_days = total_won_days + parseInt(days);
                            }
                            total_won_lost_days = total_won_lost_days + parseInt(days);
                        }
                        if (total_won_lost_oppo > 0) {
                            average_speed_closed_won_lost_oppo = total_won_lost_days / total_won_lost_oppo;
                            average_speed_closed_won_lost_oppo = average_speed_closed_won_lost_oppo.toFixed(0)
                            // average_speed_closed_won_lost_oppo = average_speed_closed_won_lost_oppo
                        }

                        if (total_won_opp > 0) {
                            average_speed_closed_won_oppo = total_won_days / total_won_opp;
                            average_speed_closed_won_oppo = average_speed_closed_won_oppo.toFixed(0);
                            // average_speed_closed_won_oppo = average_speed_closed_won_oppo
                        }
                        console.log("MAX Days:", max_days);
                        var total = valuesSecondRep.reduce(this.add, 0);
                        for (const [key, value] of Object.entries(res.data.data[0].average_speed_to_close)) {
                            var days = res.data.data[0].average_speed_to_close[key].opp_speed_to_close;
                            if (isNaN(days) || days === null) {
                                repSpeedPercent.push(0);
                            } else {
                                if (max_days > 0) {
                                    var days_percent = (parseInt(days) / max_days) * 100;
                                    repSpeedPercent.push(days_percent.toFixed(0));
                                } else {
                                    repSpeedPercent.push(0);
                                }

                            }
                        }
                    }
                    var export_file_name = "Speed_To_Close" + ((start_date_second !== null && start_date_second != "") ? `-${start_date_second}` : ``) + ((end_date_second !== null && end_date_second != "") ? `-${end_date_second}` : ``);
                    this.setState({
                        repSecondExportFilename: export_file_name,
                        repSpeedPercent, valuesSecondRep, repSecondExport,
                        rep_average_speed_to_close: res.data.data[0].average_speed_to_close, average_speed_closed_won_lost_oppo: average_speed_closed_won_lost_oppo,
                        average_speed_closed_won_oppo: average_speed_closed_won_oppo
                    }, () => {
                        console.log(this.state.daysValuesSecond, total);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-rep/revenue-by-opportunity
    getRepThirdAPICall = async () => {
        const { user_id, start_date_third, end_date_third, thirdExport, thirdExportHeader } = this.state;
        const payload = {
            sales_rep_id: user_id,
            start_date: start_date_third,
            end_date: end_date_third
        }
        await axios.post(`reports/sales-rep/revenue-by-opportunity`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res.data.data[0].average_speed_to_close)
                if (res.data.code === 200) {
                    var salesRepPercentageValuesThird = [];
                    var salesRepValuesThird = [];
                    var salesRepThirdExport = [];

                    if ((res.data.data[0].average_speed_to_close) && (res.data.data[0].average_speed_to_close.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].average_speed_to_close)) {
                            var opportunity_status = res.data.data[0].average_speed_to_close[key].opportunity_status;


                            var userArray = {
                                opportunity_name: res.data.data[0].average_speed_to_close[key].opportunity_name,
                                opportunity_status: (opportunity_status == '0') ? 'Open' : opportunity_status == '1' ? 'Closed-WON' : 'Closed-LOST',
                                start_date_in_core_ai: res.data.data[0].average_speed_to_close[key].opportunity_start_date_ai,
                                end_date: res.data.data[0].average_speed_to_close[key].opportunity_close_date,
                                expected_revenue: parseFloat(res.data.data[0].average_speed_to_close[key].opportunity_expected_revenue).toFixed(2),
                                revenue: parseFloat(res.data.data[0].average_speed_to_close[key].opportunity_revenue).toFixed(2),
                            }
                            salesRepThirdExport.push(userArray);
                        }
                    }
                    var export_file_name = "Revenue_by_opportunity" + ((start_date_third !== null && start_date_third != "") ? `-${start_date_third}` : ``) + ((end_date_third !== null && end_date_third != "") ? `-${end_date_third}` : ``);

                    this.setState({
                        salesRepThirdExportFilename: export_file_name,
                        salesRepPercentageValuesThird, salesRepValuesThird, salesRepThirdExport,
                        sales_rep_average_speed_to_close: res.data.data[0].average_speed_to_close
                    }, () => {
                        // console.log(this.state.percentageValuesThird, total);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-rep/opportunity-task-completion
    getRepFourthAPICall = async () => {
        const { user_id, start_date_fourth, end_date_fourth, fourthExport, fourthExportHeader } = this.state;
        const payload = {
            sales_rep_id: user_id,
            start_date: start_date_fourth,
            end_date: end_date_fourth
        }
        await axios.post(`reports/sales-rep/opportunity-task-completion`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    console.log(res.data.data);
                    var percentageValuesFourth = [];
                    var valuesFourth = [];
                    var fourthExport = [];
                    var headersArray = [];
                    if ((res.data.data[0].sales_stages) && (res.data.data[0].sales_stages.length > 0)) {
                        headersArray.push({ label: "Opportunity Name", key: "opportunity_name" });
                        headersArray.push({ label: "Opportunity Status", key: "opportunity_status" });
                        for (const [key, value] of Object.entries(res.data.data[0].sales_stages)) {
                            headersArray.push({ label: res.data.data[0].sales_stages[key].stage_name, key: String(res.data.data[0].sales_stages[key].stage_id) });
                        }

                    }
                    if ((res.data.data[0].task_completion) && (res.data.data[0].task_completion.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].task_completion)) {
                            var userArray = {};
                            var opportunity_name = res.data.data[0].task_completion[key].opportunity_name;
                            var opportunity_status = res.data.data[0].task_completion[key].opportunity_satus;
                            userArray['opportunity_name'] = opportunity_name;
                            userArray['opportunity_status'] = (opportunity_status === '0') ? 'Open' : opportunity_status === '1' ? 'Closed-WON' : 'Closed-LOST';
                            for (const [key2, value2] of Object.entries(res.data.data[0].task_completion[key].stages_arr)) {
                                var keyshow = String(res.data.data[0].task_completion[key].stages_arr[key2].stage_id);
                                var valueshow = String(res.data.data[0].task_completion[key].stages_arr[key2].ratio);
                                userArray[keyshow] = valueshow;
                            }
                            fourthExport.push(userArray);
                        }
                    }
                    var export_file_name = "Opportunit_Task_Completion" + ((start_date_fourth !== null && start_date_fourth != "") ? `-${start_date_fourth}` : ``) + ((end_date_fourth !== null && end_date_fourth != "") ? `-${end_date_fourth}` : ``);

                    this.setState({
                        fourthExportFilename: export_file_name,
                        fourthExportHeader: headersArray,
                        percentageValuesFourth, valuesFourth, fourthExport,
                        task_completion: res.data.data[0].task_completion,
                        sales_stages: res.data.data[0].sales_stages
                    }, () => {
                        // console.log(this.state.percentageValuesFirst, total);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    ///////////////////////// 204
    // Company Admin API Start 

    //   reports/company-admin/excpected-revenue-sales-team      
    getCAFirstAPICall = async () => {
        const { user_id, start_date_first, end_date_first, seventhExport, seventhExportHeader, user_type } = this.state;
        const payload = {
            company_admin_id: user_id,
            start_date: start_date_first,
            end_date: end_date_first
        }
        await axios.post(`reports/company-admin/excpected-revenue-sales-team`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesFirst = [];
                    var valuesFirst = [];
                    var firstExport = [];

                    if ((res.data.data && res.data.data.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data)) {
                            // var valuesFirst = (res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].opportunity_percentage).toFixed(0);
                            valuesFirst.push(parseInt(res.data.data[key].excepected_opportunity_revenue));
                            var userArray = {
                                name: res.data.data[key].org_structure_name,
                                // username : res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].sales_rep_username,
                                // email : res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].sales_rep_email,
                                excpected_revenue: res.data.data[key].excepected_opportunity_revenue,
                            }
                            firstExport.push(userArray);
                        }
                        var total = valuesFirst.reduce(this.add, 0);
                        for (const [key, value] of Object.entries(res.data.data)) {
                            // $percentages[$value] = $count / $total;
                            // var valuesSeventh = (res.data.data[0].excepected_opportunity_revenue_by_sales_rep[key].opportunity_percentage).toFixed(0);
                            var percentage = ((res.data.data[key].excepected_opportunity_revenue / total) * 100);
                            if (isNaN(percentage) || percentage === null) {
                                percentageValuesFirst.push(0);
                            } else {
                                percentageValuesFirst.push(percentage.toFixed(0));
                            }
                        }
                    }

                    var export_file_name = "Expected_Opportunity_Revenue_By_" + ((user_type == 'division_manager') ? `Sales_Team` : ((user_type == 'org_head') ? `Sales_Division` : null)) + ((start_date_first !== null && start_date_first != "") ? `-${start_date_first}` : ``) + ((end_date_first !== null && end_date_first != "") ? `-${end_date_first}` : ``);
                    this.state.firstExportHeader[0].label = ((user_type == 'division_manager') ? `Sales Team` : ((user_type == 'org_head') ? `Sales Division` : null)) + ` Name`;
                    this.state.firstExportHeader[1].label = 'Expected Revenue($)'
                    this.state.firstExportHeader[1].key = 'excpected_revenue'
                    this.setState({
                        firstExportFilename: export_file_name,
                        percentageValuesFirst, valuesFirst, firstExport,
                        excepected_opportunity_revenue_by_sales_team: res.data.data
                    }, () => {
                        console.log(this.state.percentageValuesFirst);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-manager/opportunity-conversion-rate
    getCASecondAPICall = async () => {
        const { user_id, start_date_second, end_date_second, sixthExport, sixthExportHeader, user_type } = this.state;
        const payload = {
            company_admin_id: user_id,
            start_date: start_date_second,
            end_date: end_date_second
        }
        await axios.post(`reports/company-admin/opportunity-conversion-rate`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesSecond = [];
                    var valuesSecond = [];
                    var secondExport = [];

                    if ((res.data.data && res.data.data.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data)) {
                            // var valuesSixth = (res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].opportunity_percentage).toFixed(0);
                            valuesSecond.push(parseInt(res.data.data[key].opp_coversion_rate));
                            var percentage = res.data.data[key].opp_coversion_rate;
                            if (isNaN(percentage) || percentage === null) {
                                percentage = 0;
                            } else {
                                percentage = percentage.toFixed(0);
                            }
                            var userArray = {
                                name: res.data.data[key].org_structure_name,
                                // username : res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].sales_rep_username,
                                // email : res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].sales_rep_email,
                                conversion_rate: percentage,
                            }
                            secondExport.push(userArray);
                        }
                        var total = valuesSecond.reduce(this.add, 0);
                        for (const [key, value] of Object.entries(res.data.data)) {
                            // $percentages[$value] = $count / $total;
                            // var valuesSixth = (res.data.data[0].opportunity_conversion_rate_per_sales_rep[key].opportunity_percentage).toFixed(0);
                            var percentage = parseInt(res.data.data[key].opp_coversion_rate);
                            if (isNaN(percentage) || percentage === null) {
                                percentageValuesSecond.push(0);
                            } else {
                                percentageValuesSecond.push(percentage.toFixed(0));
                            }
                        }
                    }
                    var export_file_name = "Opportunity_Conversion_Rate_Per_" + ((user_type == 'division_manager') ? `Sales_Team` : ((user_type == 'org_head') ? `Sales_Division` : null)) + ((start_date_second !== null && start_date_second != "") ? `-${start_date_second}` : ``) + ((end_date_second !== null && end_date_second != "") ? `-${end_date_second}` : ``);
                    this.state.secondExportHeader[0].label = ((user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null) + ` Name`;
                    this.state.secondExportHeader[1].label = 'Opportunity Conversion Rate(%)'
                    this.state.secondExportHeader[1].key = 'conversion_rate'
                    this.setState({
                        secondExportFilename: export_file_name,
                        percentageValuesSecond, valuesSecond, secondExport,
                        opportunity_conversion_rate_per_sales_team: res.data.data
                    }, () => {
                        console.log(this.state.percentageValuesSecond);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/company-admin/task-progression
    getCAThirdAPICall = async () => {
        const { user_id, start_date_third, end_date_third, thirdExport, thirdExportHeader, user_type } = this.state;
        const payload = {
            company_admin_id: user_id,
            start_date: start_date_third,
            end_date: end_date_third
        }
        await axios.post(`reports/company-admin/task-progression`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesThird = [];
                    var valuesThird = [];
                    var thirdExport = [];

                    var headersArray = [];
                    if ((res.data.data[0].sales_stages) && (res.data.data[0].sales_stages.length > 0)) {
                        headersArray.push({ label: ((user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : ``), key: "name" });
                        for (const [key, value] of Object.entries(res.data.data[0].sales_stages)) {
                            headersArray.push({ label: res.data.data[0].sales_stages[key].stage_name + '(%)', key: res.data.data[0].sales_stages[key].stage_name });
                        }

                    }
                    if ((res.data.data[0].task_progression) && (res.data.data[0].task_progression.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].task_progression)) {
                            var userArray = {};
                            var teamname = res.data.data[0].task_progression[key].org_structure_name;
                            userArray['name'] = teamname;
                            for (const [key2, value3] of Object.entries(res.data.data[0].task_progression[key].task_progression)) {
                                var keyshow = res.data.data[0].task_progression[key].task_progression[key2].stage_name;
                                var valueshow = res.data.data[0].task_progression[key].task_progression[key2].task_progression_percentage.toFixed(0);
                                userArray[keyshow] = valueshow;
                            }
                            thirdExport.push(userArray);
                        }
                    }
                    var export_file_name = "Task_progression" + ((start_date_third !== null && start_date_third != "") ? `-${start_date_third}` : ``) + ((end_date_third !== null && end_date_third != "") ? `-${end_date_third}` : ``);
                    this.state.thirdExportHeader[0].label = ((user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null);

                    this.setState({
                        thirdExportFilename: export_file_name,
                        thirdExportHeader: headersArray,
                        percentageValuesThird, valuesThird, thirdExport,
                        task_progression: res.data.data[0].task_progression,
                        sales_stages: res.data.data[0].sales_stages
                    }, () => {
                        console.log(this.state.task_progression);
                        console.log(this.state.sales_stages);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/company-admin/avergae-speed-to-close
    getCAFourthAPICall = async () => {
        const { user_id, start_date_fourth, end_date_fourth, fourthExport, fourthExportHeader, user_type } = this.state;
        const payload = {
            company_admin_id: user_id,
            start_date: start_date_fourth,
            end_date: end_date_fourth
        }
        await axios.post(`reports/company-admin/avergae-speed-to-close`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesFourth = [];
                    var valuesFourth = [];
                    var companyAdminfourthExport = [];

                    if ((res.data.data) && (res.data.data.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data)) {
                            // var valuesFourth = (res.data.data[key].opportunity_percentage).toFixed(0);
                            var percentage = parseFloat(res.data.data[key].average_speed_to_close);
                            if (isNaN(percentage) || percentage === null) {
                                percentage = 0;
                            } else {
                                percentage = percentage.toFixed(0);
                            }
                            valuesFourth.push(percentage);
                            var userArray = {
                                name: res.data.data[key].org_structure_name,
                                speed_to_close: percentage,
                            }
                            companyAdminfourthExport.push(userArray);
                        }
                        var total = valuesFourth.reduce(this.add, 0);
                        for (const [key, value] of Object.entries(res.data.data)) {
                            var percentage = ((parseFloat(res.data.data[key].average_speed_to_close / total)) * 100);
                            if (isNaN(percentage) || percentage === null) {
                                percentageValuesFourth.push(0);
                            } else {
                                percentageValuesFourth.push(percentage.toFixed(0));
                            }
                        }
                        console.log(total);
                    }
                    var export_file_name = "Average_Speed_To_Close" + ((start_date_fourth !== null && start_date_fourth != "") ? `-${start_date_fourth}` : ``) + ((end_date_fourth !== null && end_date_fourth != "") ? `-${end_date_fourth}` : ``);
                    this.state.companyAdminfourthExportHeader[0].label = ((user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null) + ` Name`;

                    this.setState({
                        companyAdminfourthExportFilename: export_file_name,
                        percentageValuesFourth, valuesFourth, companyAdminfourthExport,
                        average_speed_to_close: res.data.data
                    }, () => {
                        console.log(this.state.percentageValuesFourth, total);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-manager/average-revenue-per-opportunity        
    getCAFifthAPICall = async () => {
        const { user_id, start_date_fifth, end_date_fifth, fifthExport, fifthExportHeader, user_type } = this.state;
        const payload = {
            company_admin_id: user_id,
            start_date: start_date_fifth,
            end_date: end_date_fifth
        }
        await axios.post(`reports/company-admin/revenue-by-opportunity`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    var percentageValuesFifth = [];
                    var valuesFifth = [];
                    var companyAdminfifthExport = [];

                    if ((res.data.data) && (res.data.data.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data)) {
                            // var valuesFifth = (res.data.data[key].opportunity_percentage).toFixed(0);
                            valuesFifth.push(parseInt(res.data.data[key].average_revenue));
                            var percentage = res.data.data[key].average_revenue;
                            if (isNaN(percentage) || percentage === null) {
                                percentage = 0;
                            } else {
                                percentage = parseFloat(percentage).toFixed(0);
                            }
                            var userArray = {
                                name: res.data.data[key].org_structure_name,
                                // username : res.data.data[key].sales_rep_username,
                                // email : res.data.data[key].sales_rep_email,
                                average_revenue: percentage,
                            }
                            companyAdminfifthExport.push(userArray);
                        }
                        var total = valuesFifth.reduce(this.add, 0);
                        for (const [key, value] of Object.entries(res.data.data)) {
                            // $percentages[$value] = $count / $total;
                            // var valuesFifth = (res.data.data[key].opportunity_percentage).toFixed(0);
                            var percentage = parseFloat((res.data.data[key].average_revenue / total) * 100);
                            if (isNaN(percentage) || percentage === null) {
                                percentageValuesFifth.push(0);
                            } else {
                                percentageValuesFifth.push(parseFloat(percentage).toFixed(0));
                            }
                        }
                    }
                    var export_file_name = "Average_Revenue_Per_Opportunity" + ((start_date_fifth !== null && start_date_fifth != "") ? `-${start_date_fifth}` : ``) + ((end_date_fifth !== null && end_date_fifth != "") ? `-${end_date_fifth}` : ``);
                    this.state.companyAdminfifthExportHeader[0].label = ((user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null) + ` Name`;

                    this.setState({
                        companyAdminfifthExportFilename: export_file_name,
                        percentageValuesFifth, valuesFifth, companyAdminfifthExport,
                        average_revenue_per_opportunity: res.data.data
                    }, () => {
                        console.log(this.state.percentageValuesFifth, total);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-manager/opportunity-task-completion
    getCASixthAPICall = async () => {
        const { user_id, start_date_sixth, end_date_sixth, sixthExport, sixthExportHeader, user_type } = this.state;
        const payload = {
            company_admin_id: user_id,
            start_date: start_date_sixth,
            end_date: end_date_sixth
        }
        await axios.post(`reports/company-admin/opportunity-task-completion`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res);


                if (res.data.code === 200) {
                    console.log("@TASK COMPLETION DEBUG: ", res.data.data[0].opportunity_task_completion);
                    // opportunity_task_completion
                    var percentageValuesSixth = [];
                    var valuesSixth = [];
                    var sixthExport = [];
                    var headersArray = [];
                    if ((res.data.data[0].sales_stages) && (res.data.data[0].sales_stages.length > 0)) {
                        headersArray.push({ label: ((user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null), key: "name" });



                        // headersArray.push({label: "Opportunity Name", key: "opportunity_name"});
                        // headersArray.push({label: "Opportunity Status", key: "opportunity_status"});
                        for (const [key, value] of Object.entries(res.data.data[0].sales_stages)) {
                            headersArray.push({ label: res.data.data[0].sales_stages[key].stage_name, key: String(res.data.data[0].sales_stages[key].stage_id) });
                        }

                    }
                    if ((res.data.data[0].opportunity_task_completion) && (res.data.data[0].opportunity_task_completion.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].opportunity_task_completion)) {
                            var orgStructureArray = {};

                            var org_structure_name = res.data.data[0].opportunity_task_completion[key].org_structure_name;
                            orgStructureArray['name'] = org_structure_name;
                            // userArray['opportunity_name'] = opportunity_name;
                            // userArray['opportunity_status'] = opportunity_status;
                            console.log("@OFFICE@", res.data.data[0].opportunity_task_completion[key].average_completed_task[0].average_completed_task);
                            for (const [key2, value2] of Object.entries(res.data.data[0].opportunity_task_completion[key].average_completed_task)) {
                                var keyshow = String(res.data.data[0].opportunity_task_completion[key].average_completed_task[key2].stage_id);
                                var valueshow = String(res.data.data[0].opportunity_task_completion[key].average_completed_task[key2].average_completed_task);
                                orgStructureArray[keyshow] = valueshow;
                            }
                            sixthExport.push(orgStructureArray);
                        }
                    }
                    var export_file_name = "Opportunity_Average_Task_Completion" + ((start_date_sixth !== null && start_date_sixth != "") ? `-${start_date_sixth}` : ``) + ((end_date_sixth !== null && end_date_sixth != "") ? `-${end_date_sixth}` : ``);
                    this.state.sixthExportHeader[0].label = ((user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null) + ` Name`;

                    this.setState({
                        sixthExportFilename: export_file_name,
                        sixthExportHeader: headersArray,
                        percentageValuesSixth, valuesSixth, sixthExport,
                        opportunity_task_completion: res.data.data[0].opportunity_task_completion,
                        sales_stages: res.data.data[0].sales_stages
                    }, () => {
                        console.log("HARISH DEBUG");
                        console.log(this.state.opportunity_task_completion);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // reports/sales-manager/opportunity-task-completion
    getCAEightAPICall = async (filter) => {
        const { user_id, start_date_eight, end_date_eight, ninthExport, ninthExportHeader, user_type, year } = this.state;
        const payload = {
            company_admin_id: user_id,
            start_date: start_date_eight,
            end_date: end_date_eight,
            filter_type: filter,
            year: year
        }
        await axios.post(`reports/company-admin/coaching-compliance`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res);
                if (res.data.code === 200) {
                    var ninthExport = [];
                    var headersArray = [];
                    headersArray.push({ label: ((user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null), key: "name" });
                    headersArray.push({ label: "Targeted Coaching Sessions", key: "targeted_coaching_sessions" });
                    headersArray.push({ label: "Remaing to Target", key: "remaining_coaching_sessions" });
                    headersArray.push({ label: "Completed", key: "completed_coaching_sessions" });
                    headersArray.push({ label: "Scheduled", key: "scheduled_coaching_sessions" });
                    headersArray.push({ label: "In Process", key: "in_process_coaching_sessions" });

                    if ((res.data.data[0].coaching_compliance) && (res.data.data[0].coaching_compliance.length > 0)) {
                        for (const [key, value] of Object.entries(res.data.data[0].coaching_compliance)) {
                            var userArray = {
                                name: (user_type == 'division_manager') ? res.data.data[0].coaching_compliance[key].team_name : res.data.data[0].coaching_compliance[key].division_name,
                                targeted_coaching_sessions: res.data.data[0].coaching_compliance[key].target_no_of_coaching_session,
                                remaining_coaching_sessions: res.data.data[0].coaching_compliance[key].remaining_sessions_count,
                                completed_coaching_sessions: res.data.data[0].coaching_compliance[key].completed_sessions_count,
                                scheduled_coaching_sessions: res.data.data[0].coaching_compliance[key].scheduled_sessions_count,
                                in_process_coaching_sessions: res.data.data[0].coaching_compliance[key].in_process_sessions_count,
                            }
                            ninthExport.push(userArray);
                        }
                    }

                    var export_file_name = "Coaching_Compliance" + ((start_date_eight !== null && start_date_eight != "" && start_date_eight != "2021-01-01") ? `-${start_date_eight}` : ``) + ((end_date_eight !== null && end_date_eight != "" && start_date_eight != "2021-01-01") ? `-${end_date_eight}` : ``);
                    this.setState({
                        ninthExportFilename: export_file_name,
                        ninthExport, ninthExportHeader: headersArray,
                        sessions_complaince: res.data.data[0].coaching_compliance
                    }, () => {
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    render() {


        const { user_role, user_type, team_name, company_name, full_name, sales_team, excepected_opportunity_revenue_by_sales_rep, percentageValuesSeventh, valuesSeventh, seventhExport, seventhExportHeader, seventhExportFilename,
            opportunity_conversion_rate_per_sales_rep, percentageValuesSixth, valuesSixth, sixthExport, sixthExportHeader, sixthExportFilename,
            task_progression, sales_stages, percentageValuesFifth, valuesFifth, fifthExport, fifthExportHeader, fifthExportFilename,
            average_speed_to_close, percentageValuesFourth, valuesFourth, fourthExport, fourthExportHeader, fourthExportFilename,
            revenue_by_opportunity, percentageValuesThird, valuesThird, thirdExport, thirdExportHeader, thirdExportFilename,
            average_revenue_per_opportunity, percentageValuesSecond, valuesSecond, secondExport, secondExportHeader, secondExportFilename,
            opportunity_task_completion, percentageValuesFirst, valuesFirst, firstExport, firstExportHeader, firstExportFilename, rep_average_speed_to_close,
            repSecondExport, repSecondExportHeader, repSecondExportFilename, repSpeedPercent, average_speed_closed_won_lost_oppo, average_speed_closed_won_oppo,
            task_completion, task_completion2,
            sales_rep_average_speed_to_close, salesRepThirdExport, salesRepThirdExportHeader, salesRepThirdExportFilename,
            excepected_opportunity_revenue_by_sales_team, opportunity_conversion_rate_per_sales_team,
            companyAdminfifthExport, companyAdminfifthExportHeader, companyAdminfifthExportFilename,
            companyAdminfourthExport, companyAdminfourthExportHeader, companyAdminfourthExportFilename,
            sessions_complaince, eightExport, eightExportHeader, eightExportFilename,
            ninthExport, ninthExportHeader, ninthExportFilename
        } = this.state;


        const salesRepCsvReportThird = {
            data: (salesRepThirdExport) ? salesRepThirdExport : [],
            headers: salesRepThirdExportHeader,
            filename: `${salesRepThirdExportFilename}.csv`
        };
        const csvReportRepSecond = {
            data: (repSecondExport) ? repSecondExport : [],
            headers: repSecondExportHeader,
            filename: `${repSecondExportFilename}.csv`
        };
        const csvReportNinth = {
            data: (ninthExport) ? ninthExport : [],
            headers: ninthExportHeader,
            filename: `${ninthExportFilename}.csv`
        };
        const csvReportEight = {
            data: (eightExport) ? eightExport : [],
            headers: eightExportHeader,
            filename: `${eightExportFilename}.csv`
        };
        const csvReport = {
            data: (seventhExport) ? seventhExport : [],
            headers: seventhExportHeader,
            filename: `${seventhExportFilename}.csv`
        };
        const csvReportSixth = {
            data: (sixthExport) ? sixthExport : [],
            headers: sixthExportHeader,
            filename: `${sixthExportFilename}.csv`
        };
        const csvReportFifth = {
            data: (fifthExport) ? fifthExport : [],
            headers: fifthExportHeader,
            filename: `${fifthExportFilename}.csv`
        };

        const csvReportFourth = {
            data: (fourthExport) ? fourthExport : [],
            headers: fourthExportHeader,
            filename: `${fourthExportFilename}.csv`
        };
        const csvReportThird = {
            data: (thirdExport) ? thirdExport : [],
            headers: thirdExportHeader,
            filename: `${thirdExportFilename}.csv`
        };
        const csvReportSecond = {
            data: (secondExport) ? secondExport : [],
            headers: secondExportHeader,
            filename: `${secondExportFilename}.csv`
        };
        const csvReportFirst = {
            data: (firstExport) ? firstExport : [],
            headers: firstExportHeader,
            filename: `${firstExportFilename}.csv`
        };



        const csvcompanyAdminReportFourth = {
            data: (companyAdminfourthExport) ? companyAdminfourthExport : [],
            headers: companyAdminfourthExportHeader,
            filename: `${companyAdminfourthExportFilename}.csv`
        };

        const csvcompanyAdminReportFifth = {
            data: (companyAdminfifthExport) ? companyAdminfifthExport : [],
            headers: companyAdminfifthExportHeader,
            filename: `${companyAdminfifthExportFilename}.csv`
        };


        return (
            <>
                <main className="main-contant opportunity-management-main">
                    <div className="contents">
                        <div className="mb-3 mb-lg-4">
                            <h3 className="title-head m-0 py-1">
                                {(user_type == 'division_manager') ?
                                    (<>
                                        {((team_name !== undefined) && (team_name !== "")) ? team_name + ` Reports` : ``}
                                    </>)
                                    : (user_type == 'org_head') ? (<>
                                        {((company_name !== undefined) && (company_name !== "")) ? company_name + ` Reports` : ``}
                                    </>) : null}
                            </h3>

                            <h3 className="title-head m-0 py-1">
                                {(user_role == 4) ? (<>Reports for {full_name}</>) : (user_role == 3) ? (<>{sales_team} Reports</>) : null}
                            </h3>
                        </div>
                        <div className="accordion report-main-wrap" id="accordionTable">

                            {(user_role == 3) ? (<>
                                {/* fourth report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={speedometer} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Average Speed to Close
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Average time in days from the Start Date of Opportunity to the Closed Date of Opportunity (marked as Closed-WON/LOST) for each Sales Rep.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportFourth}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_fourth" dataUid={`quarter_select_fourth`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseTwo" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5 opportunity-graph">
                                            <div className="row align-items-center">
                                                <div className="col-xl-12">
                                                    <div className="table-container">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="with_180 border-0"><i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Rep </th>
                                                                        <th className="border-0"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(average_speed_to_close && average_speed_to_close.length > 0) ? (
                                                                        average_speed_to_close.map((users, i) => {
                                                                            var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((users.sales_rep_profile_pic_url !== "") && (users.sales_rep_profile_pic_url !== null)) ? users.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                            return (
                                                                                <>

                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="main-profile d-flex  align-items-center">
                                                                                                <div className="img-wrap">
                                                                                                    <img src={profilepic} alt="" />
                                                                                                </div>
                                                                                                <div className="ml-2">
                                                                                                    <div className="name mb-1">
                                                                                                        {`${users.sales_rep_first_name} ${users.sales_rep_last_name}`}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="progress w-100 bg-transparent">
                                                                                                <div className="progress-bar bg_color_light_blue" role="progressbar" style={{ width: `${percentageValuesFourth[i]}%` }} aria-valuenow={`${percentageValuesFourth[i]}`} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                                <small className="d-inline-block px-2"> {valuesFourth[i]}  {(parseInt(valuesFourth[i]) > 1 || parseInt(valuesFourth[i]) == 0) ? `Days` : `Day`}  </small>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                </>
                                                                            );
                                                                        })
                                                                    ) : null}


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* fourth report end */}

                                {/* second report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={conversionrate} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Opportunity Conversion Rate per Sales Rep
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Percent of Closed-WON opportunities to the total number of opportunities for each Sales Rep in the sales team.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportSixth}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_sixth" dataUid={`quarter_select_sixth`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapsesix" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5 opportunity-graph">
                                            <div className="row align-items-center">
                                                <div className="col-xl-12">
                                                    <div className="table-container">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="with_180 border-0"><i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Rep </th>
                                                                        <th className="border-0"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(opportunity_conversion_rate_per_sales_rep && opportunity_conversion_rate_per_sales_rep.length > 0) ? (
                                                                        opportunity_conversion_rate_per_sales_rep.map((users, i) => {
                                                                            var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((users.sales_rep_profile_pic_url !== "") && (users.sales_rep_profile_pic_url !== null)) ? users.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="main-profile d-flex  align-items-center">
                                                                                                <div className="img-wrap">
                                                                                                    <img src={profilepic} alt="" />
                                                                                                </div>
                                                                                                <div className="ml-2">
                                                                                                    <div className="name mb-1">
                                                                                                        {`${users.sales_rep_first_name} ${users.sales_rep_last_name}`}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="progress w-100 bg-transparent">
                                                                                                <div className="progress-bar bg_color_light_blue" role="progressbar" style={{ width: `${percentageValuesSixth[i]}%` }} aria-valuenow={`${percentageValuesSixth[i]}`} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                                <small className="d-inline-block px-2"> {percentageValuesSixth[i]}% </small>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                </>
                                                                            )
                                                                        })
                                                                    ) : null}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* second report end */}

                                {/* third report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={prospect} width="55" className="iconImg" alt="prospect" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Task Progression
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Average percent completion of tasks per opportunity for each Sales Rep reported by Sales Stage.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportFifth}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_fifth" dataUid={`quarter_select_fifth`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseOne" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5">
                                            <div className="table-container">
                                                <div className="table-responsive">
                                                    <table className="table table-stripedss">
                                                        <thead>
                                                            <tr>
                                                                <th >
                                                                    <i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Rep
                                                                </th>
                                                                {(sales_stages && sales_stages.length > 0) ? (
                                                                    sales_stages.map((sales_stage, i) => {
                                                                        var iconpic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((sales_stage.stage_icon_url !== "") && (sales_stage.stage_icon_url !== null)) ? sales_stage.stage_icon_url : 'default_sales_stage.png');

                                                                        return (<>
                                                                            <th className="text-center">
                                                                                <span className="stage-icon mr-1 shadow-none">
                                                                                    <img src={iconpic} className={`mr-1`} width={`24`} />
                                                                                </span>
                                                                                {/* <i className="ri-lightbulb-line color_blue f-400 ri-2x mr-2"></i> */}
                                                                                {sales_stage.stage_name}
                                                                            </th>
                                                                        </>)
                                                                    })
                                                                ) : null}
                                                                {/* <th className="text-center"><i
                                                                                            className="ri-search-eye-line color_blue f-400 ri-2x mr-2 flip-icon"></i>Qualify
                                                                                        </th>
                                                                                        <th className="text-center"> <i
                                                                                            className="ri-group-line color_blue f-400 ri-2x mr-2"></i>Develop
                                                                                        </th>
                                                                                        <th className="text-center"><i
                                                                                            className="ri-time-line color_blue f-400 ri-2x mr-2"></i>Validate
                                                                                        </th>
                                                                                        <th className="text-center"><i
                                                                                            className="ri-bar-chart-2-line color_blue f-400 ri-2x mr-2"></i>Close
                                                                                        </th>
                                                                                        <th className="text-center"><i
                                                                                            className="ri-focus-2-line color_blue f-400 ri-2x mr-2"></i>Measure
                                                                                        </th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(task_progression && task_progression.length > 0) ? (
                                                                task_progression.map((task_progress, i) => {
                                                                    var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((task_progress.sales_rep_profile_pic_url !== "") && (task_progress.sales_rep_profile_pic_url !== null)) ? task_progress.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                    return (<>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="main-profile d-flex  align-items-center">
                                                                                    <div className="img-wrap">
                                                                                        <img src={profilepic} alt="" />
                                                                                    </div>
                                                                                    <div className="ml-2">
                                                                                        <div className="name mb-1">
                                                                                            {`${task_progress.sales_rep_first_name} ${task_progress.sales_rep_last_name}`}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            {(task_progress.task_progression && task_progress.task_progression.length > 0) ? (
                                                                                task_progress.task_progression.map((task_pro, i) => {
                                                                                    return (<>
                                                                                        <td className="text-center">
                                                                                            {(parseInt(task_pro.task_progression_percentage.toFixed(0)) >= 50) ? (
                                                                                                <span
                                                                                                    className="d-inline-block text-white hight-light-btn bg_color_light_green">
                                                                                                    {`${task_pro.task_progression_percentage.toFixed(0)}%`}
                                                                                                </span>
                                                                                            ) : (
                                                                                                `${task_pro.task_progression_percentage.toFixed(0)}%`
                                                                                            )}
                                                                                        </td>
                                                                                    </>);
                                                                                })
                                                                            ) : null}
                                                                            {/* <td className="text-center">
                                                                                                46%
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                43%
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                58%
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                57%
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                78%
                                                                                            </td> */}
                                                                        </tr>

                                                                    </>);
                                                                })
                                                            ) : null}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* third report end */}

                                {/* seventh report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={taskcomplete} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Opportunity Task Completion
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Number of completed tasks for each sales stage of an opportunity for each Sales Rep.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportFirst}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_first" dataUid={`quarter_select_first`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseEight" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5">
                                            <div className="table-container">
                                                <div className="table-responsive">
                                                    <table className="table table-stripedss">
                                                        <thead>
                                                            <tr>
                                                                <th><i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Rep </th>
                                                                <th className="text-center">Opportunity Name</th>
                                                                <th className="text-center">Opportunity Status</th>
                                                                {(sales_stages && sales_stages.length > 0) ? (
                                                                    sales_stages.map((sales_stage, i) => {
                                                                        var iconpic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((sales_stage.stage_icon_url !== "") && (sales_stage.stage_icon_url !== null)) ? sales_stage.stage_icon_url : 'default_sales_stage.png');

                                                                        return (<>
                                                                            <th className="text-center">
                                                                                <span className="stage-icon mr-1">
                                                                                    <img src={iconpic} className={`mr-1`} width={`24`} />
                                                                                </span>
                                                                                {/* <i className="ri-lightbulb-line color_blue f-400 ri-2x mr-2"></i> */}
                                                                                {sales_stage.stage_name}
                                                                            </th>
                                                                        </>)
                                                                    })
                                                                ) : null}
                                                                {/* <th className="text-center"><i className="ri-lightbulb-line color_blue f-400 ri-2x mr-2"></i>Prospect
                                                                                        </th>
                                                                                        <th className="text-center"><i className="ri-search-eye-line color_blue f-400 ri-2x mr-2 flip-icon"></i>Qualify
                                                                                        </th>
                                                                                        <th className="text-center"> <i className="ri-group-line color_blue f-400 ri-2x mr-2"></i>Develop
                                                                                        </th>
                                                                                        <th className="text-center"><i className="ri-time-line color_blue f-400 ri-2x mr-2"></i>Validate
                                                                                        </th>
                                                                                        <th className="text-center"><i className="ri-bar-chart-2-line color_blue f-400 ri-2x mr-2"></i>Close
                                                                                        </th>
                                                                                        <th className="text-center"><i className="ri-focus-2-line color_blue f-400 ri-2x mr-2"></i>Measure
                                                                                        </th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(opportunity_task_completion && opportunity_task_completion.length > 0) ? (
                                                                opportunity_task_completion.map((task_completion, i) => {
                                                                    var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((task_completion.sales_rep_profile_pic_url !== "") && (task_completion.sales_rep_profile_pic_url !== null)) ? task_completion.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                    return (<>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="main-profile d-flex  align-items-center">
                                                                                    <div className="img-wrap">
                                                                                        <img src={profilepic} alt="" />
                                                                                    </div>
                                                                                    <div className="ml-2">
                                                                                        <div className="name mb-1">
                                                                                            {`${task_completion.sales_rep_first_name} ${task_completion.sales_rep_last_name}`}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">{`${task_completion.opportunity_name}`}</td>
                                                                            <td className="text-center">{`${task_completion.status}`}</td>

                                                                            {(task_completion.task_completion && task_completion.task_completion.length > 0) ? (
                                                                                task_completion.task_completion.map((task_com, i) => {
                                                                                    return (<>
                                                                                        <td className="text-center">
                                                                                            {(parseInt(task_com.completed_tasks) >= 50) ? (
                                                                                                <span
                                                                                                    className="d-inline-block text-white hight-light-btn bg_color_light_green">
                                                                                                    {`${task_com.completed_tasks}`}
                                                                                                </span>
                                                                                            ) : (
                                                                                                `${task_com.completed_tasks}`
                                                                                            )}
                                                                                        </td>
                                                                                    </>);
                                                                                })
                                                                            ) : null}
                                                                            {/* <td className="text-center">
                                                                                                46%
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                43%
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                58%
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                57%
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                78%
                                                                                            </td> */}
                                                                        </tr>

                                                                    </>);
                                                                })
                                                            ) : null}

                                                            {/* 
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="main-profile d-flex  align-items-center">
                                                                                                <div className="img-wrap">
                                                                                                    <img src={profile6} alt="" />
                                                                                                </div>
                                                                                                <div className="ml-2">
                                                                                                    <div className="name mb-1">
                                                                                                        Anthony Miles
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="text-center">Opportunity K35</td>
                                                                                        <td className="text-center">
                                                                                            3
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            46
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            43
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            58
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            57
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            78
                                                                                        </td>
                                                                                    </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* seventh report end */}

                                {/* sixth report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={revenue} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Average Revenue per Opportunity
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Average revenue for all opportunities in Closed-WON status for each Sales Rep in the sales team.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportSecond}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_second" dataUid={`quarter_select_second`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseSeven" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5 opportunity-graph">
                                            <div className="row align-items-center">
                                                <div className="col-xl-12">
                                                    <div className="table-container">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="with_180 border-0"><i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Rep </th>
                                                                        <th className="border-0"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(average_revenue_per_opportunity && average_revenue_per_opportunity.length > 0) ? (
                                                                        average_revenue_per_opportunity.map((users, i) => {
                                                                            var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((users.sales_rep_profile_pic_url !== "") && (users.sales_rep_profile_pic_url !== null)) ? users.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="main-profile d-flex  align-items-center">
                                                                                                <div className="img-wrap">
                                                                                                    <img src={profilepic} alt="" />
                                                                                                </div>
                                                                                                <div className="ml-2">
                                                                                                    <div className="name mb-1">
                                                                                                        {`${users.sales_rep_first_name} ${users.sales_rep_last_name}`}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="progress w-100 bg-transparent">
                                                                                                <div className="progress-bar bg_color_light_blue" role="progressbar" style={{ width: `${percentageValuesSecond[i]}%` }} aria-valuenow={`${percentageValuesSecond[i]}`} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                                <small className="d-inline-block px-2"> ${valuesSecond[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </small>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                </>
                                                                            )
                                                                        })
                                                                    ) : null}


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* sixth report end */}

                                {/* fifth report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={increasedrevenue} width="50" className="iconImg" alt="revenue" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Revenue by Opportunity
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Revenue for each opportunity in Closed-WON status for each Sales Rep in the sales team.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportThird}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_third" dataUid={`quarter_select_third`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseThree" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5">
                                            <div className="table-container">
                                                <div className="table-responsive">
                                                    <table className="table table-stripedss">
                                                        <thead>
                                                            <tr>
                                                                < th><i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Rep </th>
                                                                <th className="text-center">Opportunity</th>
                                                                <th className="text-center">Opportunity Status
                                                                </th>
                                                                <th className="text-center">Opportunity Closed Date
                                                                </th>
                                                                <th className="text-center">Revenue
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(revenue_by_opportunity && revenue_by_opportunity.length > 0) ? (
                                                                revenue_by_opportunity.map((users, i) => {
                                                                    var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((users.sales_rep_profile_pic_url !== "") && (users.sales_rep_profile_pic_url !== null)) ? users.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>
                                                                                    <div className="main-profile d-flex  align-items-center">
                                                                                        <div className="img-wrap">
                                                                                            <img src={profilepic} alt="" />
                                                                                        </div>
                                                                                        <div className="ml-2">
                                                                                            <div className="name mb-1">
                                                                                                {`${users.sales_rep_first_name} ${users.sales_rep_last_name}`}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-center">{`${users.opportunity_name}`}</td>
                                                                                <td className="text-center">
                                                                                    {`${users.opportunity_status}`}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {`${users.opportunity_closed_date}`}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    ${`${users.opportunity_revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                                                                </td>

                                                                            </tr>

                                                                        </>
                                                                    )
                                                                })
                                                            ) : null}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* fifth report end */}

                                {/* first report start  */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={growth} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4" >
                                                <div className="org-name f2015 w-auto f-600">
                                                    Expected Opportunity Revenue by Sales Rep
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Total sum of the expected revenue for all opportunities in Open status for each Sales Rep in the sales team.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReport}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_seventh" dataUid={`quarter_select_seventh`}
                                                    onSelect={this.changeQuarter}
                                                />


                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapsefive" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5 opportunity-graph">
                                            <div className="row align-items-center">
                                                <div className="col-xl-12">
                                                    <div className="table-container">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="with_180 border-0"><i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Rep </th>
                                                                        <th className="border-0"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(excepected_opportunity_revenue_by_sales_rep && excepected_opportunity_revenue_by_sales_rep.length > 0) ? (
                                                                        excepected_opportunity_revenue_by_sales_rep.map((users, i) => {
                                                                            var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((users.sales_rep_profile_pic_url !== "") && (users.sales_rep_profile_pic_url !== null)) ? users.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="main-profile d-flex  align-items-center">
                                                                                                <div className="img-wrap">
                                                                                                    <img src={profilepic} alt="" />
                                                                                                </div>
                                                                                                <div className="ml-2">
                                                                                                    <div className="name mb-1">
                                                                                                        {`${users.sales_rep_first_name} ${users.sales_rep_last_name}`}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="progress w-100 bg-transparent">
                                                                                                <div className="progress-bar bg_color_light_blue" role="progressbar" style={{ width: `${percentageValuesSeventh[i]}%` }} aria-valuenow={`${percentageValuesSeventh[i]}`} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                                {/* <small className="d-inline-block px-2"> ${valuesSeventh[i].toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} </small> */}
                                                                                                <small className="d-inline-block px-2"> ${valuesSeventh[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </small>

                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                </>
                                                                            )
                                                                        })

                                                                    ) : null}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* first report end */}

                                {/* eight report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseEighth" aria-expanded="false" aria-controls="collapseEighth">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={compliant} width="50" className="iconImg" alt="compliant" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Coaching Compliance
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Number of coaching sessions by status for each sales rep.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportEight}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterTextCurrentYear}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_eight" dataUid={`quarter_select_eight`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseEighth" aria-expanded="false" aria-controls="collapseEighth">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseEighth" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5">
                                            <div className="table-container">
                                                <div className="table-responsive">
                                                    <table className="table table-stripedss">
                                                        <thead>
                                                            <tr>
                                                                <th><i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Rep </th>
                                                                <th className="text-center">Target Coaching Sessions</th>
                                                                <th className="text-center">Remaining to Target</th>
                                                                <th className="text-center">Completed</th>
                                                                <th className="text-center">Scheduled</th>
                                                                <th className="text-center">In Process</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(sessions_complaince && sessions_complaince.length > 0) ? (
                                                                sessions_complaince.map((sessions_complainc, i) => {
                                                                    var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((sessions_complainc.sales_rep_profile_pic_url !== "") && (sessions_complainc.sales_rep_profile_pic_url !== null)) ? sessions_complainc.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                    return (<>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="main-profile d-flex  align-items-center">
                                                                                    <div className="img-wrap">
                                                                                        <img src={profilepic} alt="" />
                                                                                    </div>
                                                                                    <div className="ml-2">
                                                                                        <div className="name mb-1">
                                                                                            {`${sessions_complainc.sales_rep_first_name} ${sessions_complainc.sales_rep_last_name}`}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {/* {`${(parseFloat(sessions_complainc.target_no_of_coaching_session).toFixed(1))}`} */}
                                                                                {`${(sessions_complainc.target_no_of_coaching_session == 0 || sessions_complainc.target_no_of_coaching_session == 0.0 || sessions_complainc.target_no_of_coaching_session == null) ? "N/A" : (parseFloat(sessions_complainc.target_no_of_coaching_session).toFixed(1))}`}

                                                                            </td>
                                                                            <td className="text-center">
                                                                                {(sessions_complainc.target_no_of_coaching_session == 0 || sessions_complainc.target_no_of_coaching_session == 0.0 || sessions_complainc.target_no_of_coaching_session == null) ? (

                                                                                    <span
                                                                                        className="d-inline-block">
                                                                                        {`${(sessions_complainc.target_no_of_coaching_session == 0 || sessions_complainc.target_no_of_coaching_session == 0.0 || sessions_complainc.target_no_of_coaching_session == null) ? "N/A" : (parseFloat(sessions_complainc.remaining_sessions_count).toFixed(1))}`}
                                                                                    </span>
                                                                                ) : (
                                                                                    <div>

                                                                                    {(sessions_complainc.remaining_sessions_count == 0 || sessions_complainc.remaining_sessions_count == 0.0) ? (
                                                                                        <span
                                                                                            className="d-inline-block text-white hight-light-btn bg_color_light_green">
                                                                                            {`${(parseFloat(sessions_complainc.remaining_sessions_count).toFixed(1))}`}
                                                                                        </span>
                                                                                    ) : (
                                                                                        `${parseFloat(sessions_complainc.remaining_sessions_count).toFixed(1)}`
                                                                                    )}
    
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {/* {`${sessions_complainc.completed_sessions_count}`} */}
                                                                                {`${(sessions_complainc.completed_sessions_count == 0 || sessions_complainc.completed_sessions_count == null) ? "N/A" : (sessions_complainc.completed_sessions_count)}`}
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {/* {`${sessions_complainc.scheduled_sessions_count}`} */}
                                                                                {`${(sessions_complainc.scheduled_sessions_count == 0 || sessions_complainc.scheduled_sessions_count == null) ? "N/A" : (sessions_complainc.scheduled_sessions_count)}`}

                                                                            </td>
                                                                            <td className="text-center">
                                                                                {/* {`${sessions_complainc.in_process_sessions_count}`} */}

                                                                                {`${(sessions_complainc.in_process_sessions_count == 0 || sessions_complainc.in_process_sessions_count == null) ? "N/A" : (sessions_complainc.in_process_sessions_count)}`}

                                                                            </td>
                                                                        </tr>

                                                                    </>);
                                                                })
                                                            ) : null}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* eight report end */}


                            </>) : null}





                            {(user_role == 4) ? (<>
                                {/* Harish Sir - second report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={speedometer} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Speed to Close
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Average time in days from the Start Date of Opportunity to the Closed Date of Opportunity (marked as Closed-WON/LOST).</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportRepSecond}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_second" dataUid={`quarter_select_second`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseTwo" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5 opportunity-graph">
                                            <div className="row align-items-center">
                                                <div className="col-xl-9">
                                                    <div className="table-container">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="with_180 border-0">Opportunity Name</th>
                                                                        <th className="border-0"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(rep_average_speed_to_close && rep_average_speed_to_close.length > 0) ? (
                                                                        rep_average_speed_to_close.map((oppo_data, i) => {


                                                                            return (

                                                                                <>

                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="main-profile d-flex  align-items-center">

                                                                                                <div className="ml-2">
                                                                                                    <div className="name mb-1">
                                                                                                        {oppo_data.opportunity_name}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="progress w-100 bg-transparent">
                                                                                                {oppo_data.opportunity_status == 1 ?
                                                                                                    <div className="progress-bar bg_color_light_blue" role="progressbar" style={{ width: `${repSpeedPercent[i]}%` }} aria-valuenow={`${oppo_data.opp_speed_to_close}`} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                                    : <div className="progress-bar bg_color_orange" role="progressbar" style={{ width: `${repSpeedPercent[i]}%` }} aria-valuenow={`${oppo_data.opp_speed_to_close}`} aria-valuemin="0" aria-valuemax="100"></div>}
                                                                                                <small className="d-inline-block px-2"> {oppo_data.opp_speed_to_close}  {(parseInt(oppo_data.opp_speed_to_close) > 1 || parseInt(oppo_data.opp_speed_to_close) == 0) ? `Days` : `Day`}  </small>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                </>
                                                                            );
                                                                        })
                                                                    ) : <tr>
                                                                        <td>
                                                                            No data is available for this report as there are no closed opportunities.
                                                                        </td>
                                                                    </tr>}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3">
                                                    <div className="sessions-info-wrap mt-4">
                                                        <div className="row dashboard-card-row">
                                                            <div className="col-xl-12 col-lg-6 col-sm-6">
                                                                <div className="graph-indicator pl-1 mb-2 mb-lg-3">
                                                                    <span className="icon-graph  bg-orange-graph "></span> Closed-WON
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-12 col-lg-6 col-sm-6">
                                                                <div className="graph-indicator pl-1 mb-2 mb-lg-3">
                                                                    <span className="icon-graph bg-light-blue-graph"></span> Closed-LOST
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row dashboard-card-row">
                                                            <div className="col-xl-12 col-lg-6 col-sm-6 px-2">
                                                                <div className="session-list my-1 my-lg-3 action-awating d-flex justify-content-between align-items-center">
                                                                    <div className="d-flex  align-items-center">
                                                                        <div className="float-left">
                                                                            <div className="session-count color_orange w-auto">
                                                                                {average_speed_closed_won_lost_oppo}
                                                                            </div>
                                                                        </div>
                                                                        <div className="float-right w-100">
                                                                            <div className="session-info w-100 pl-2">
                                                                                <small className="w-100 color_dark f-500">Average Speed to Close in Days <span className="white-space-pre">(Closed-WON & Closed-LOST)</span></small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-12 col-lg-6 col-sm-6 px-2">
                                                                <div
                                                                    className="session-list my-1 my-lg-3   d-flex justify-content-between align-items-center">
                                                                    <div className="d-flex  align-items-center">
                                                                        <div className="float-left">
                                                                            <div className="session-count  color_blue w-auto">
                                                                                {average_speed_closed_won_oppo}
                                                                            </div>
                                                                        </div>

                                                                        <div className="float-right w-100">
                                                                            <div className="session-info w-100 pl-2">
                                                                                <small className="w-100 color_dark f-500">Average Sales Cycle in Days (Closed-WON)</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseTwo" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5 opportunity-graph">
                                            <div className="row align-items-center">
                                                <div className="col-xl-12">
                                                    <div className="table-container">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="with_180 border-0"> Opportunity Name </th>
                                                                        <th className="border-0"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(rep_average_speed_to_close && rep_average_speed_to_close.length > 0) ? (
                                                                        rep_average_speed_to_close.map((oppo_data, i) => {


                                                                            return (
                                                                                <>

                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="main-profile d-flex  align-items-center">
                                                                                                {/* <div className="img-wrap">
                                                                                                                                                <img src={profilepic} alt="" />
                                                                                                                                            </div> */}
                                                                                                <div className="ml-2">
                                                                                                    <div className="name mb-1">
                                                                                                        {oppo_data.opportunity_name}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="progress w-100 bg-transparent">
                                                                                                <div className="progress-bar bg_color_light_blue" role="progressbar" style={{ width: `${oppo_data.opp_speed_to_close}%` }} aria-valuenow={`${oppo_data.opp_speed_to_close}`} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                                <small className="d-inline-block px-2"> {oppo_data.opp_speed_to_close} {(parseInt(oppo_data.opp_speed_to_close) > 1 || parseInt(oppo_data.opp_speed_to_close) == 0) ? `Days` : `Day`} </small>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                </>
                                                                            );
                                                                        })
                                                                    ) : null}


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* second report end */}

                                {/* Rep first report start  */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={prospect} width="55" className="iconImg" alt="prospect" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Task Progression
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Percent completion of tasks per opportunity by Sales Stage.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportFirst}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="rep_quarter_select_first" dataUid={`rep_quarter_select_first`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseOne" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5">
                                            <div className="table-container">
                                                <div className="table-responsive">
                                                    <table className="table table-stripedss">
                                                        <thead>
                                                            <tr>
                                                                <th >
                                                                    Opportunity Name
                                                                </th>
                                                                {(sales_stages && sales_stages.length > 0) ? (
                                                                    sales_stages.map((sales_stage, i) => {
                                                                        var iconpic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((sales_stage.stage_icon_url !== "") && (sales_stage.stage_icon_url !== null)) ? sales_stage.stage_icon_url : 'default_sales_stage.png');

                                                                        return (<>
                                                                            <th className="text-center">
                                                                                <span className="stage-icon mr-1 shadow-none">
                                                                                    <img src={iconpic} className={`mr-1`} width={`24`} />
                                                                                </span>
                                                                                {/* <i className="ri-lightbulb-line color_blue f-400 ri-2x mr-2"></i> */}
                                                                                {sales_stage.stage_name}
                                                                            </th>
                                                                        </>)
                                                                    })
                                                                ) : null}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(task_completion2 && task_completion2.length > 0) ? (
                                                                task_completion2.map((task_com, i) => {
                                                                    var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((task_com.sales_rep_profile_pic_url !== "") && (task_com.sales_rep_profile_pic_url !== null)) ? task_com.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                    return (<>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="main-profile d-flex  align-items-center">
                                                                                    <div className="ml-2">
                                                                                        <div className="name mb-1">
                                                                                            {`${task_com.opportunity_name}`}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            {(task_com.stages_arr && task_com.stages_arr.length > 0) ? (
                                                                                task_com.stages_arr.map((task_pro, i) => {
                                                                                    return (<>
                                                                                        <td className="text-center">
                                                                                            {(parseInt(task_pro.percentage) >= 50) ? (
                                                                                                <span
                                                                                                    className="d-inline-block text-white hight-light-btn bg_color_light_green">
                                                                                                    {`${(task_pro.percentage !== null && (task_pro.percentage != "N/A" && task_pro.percentage != "-")) ? task_pro.percentage + "%" : (task_pro.percentage != "N/A" || task_pro.percentage != "-") ? task_pro.percentage : "0%"}`}
                                                                                                </span>
                                                                                            ) : (
                                                                                                // `${(task_pro.percentage && task_pro.percentage!==null) ? task_pro.percentage : 0}%`
                                                                                                `${(task_pro.percentage !== null && (task_pro.percentage != "N/A" && task_pro.percentage != "-")) ? task_pro.percentage + "%" : (task_pro.percentage != "N/A" || task_pro.percentage != "-") ? task_pro.percentage : "0%"}`

                                                                                            )}
                                                                                        </td>
                                                                                    </>);
                                                                                })
                                                                            ) : null}
                                                                        </tr>

                                                                    </>);
                                                                })
                                                            ) : null}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Rep first report end */}

                                {/* Rep fourth report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={taskcomplete} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Opportunity Task Completion
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Number of completed tasks for each sales stage for each opportunity.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportFourth}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="rep_quarter_select_fourth" dataUid={`rep_quarter_select_fourth`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseEight" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5">
                                            <div className="table-container">
                                                <div className="table-responsive">
                                                    <table className="table table-stripedss">
                                                        <thead>
                                                            <tr>
                                                                <th>Opportunity Name</th>
                                                                <th className="text-center">Opportunity Status</th>
                                                                {(sales_stages && sales_stages.length > 0) ? (
                                                                    sales_stages.map((sales_stage, i) => {
                                                                        var iconpic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((sales_stage.stage_icon_url !== "") && (sales_stage.stage_icon_url !== null)) ? sales_stage.stage_icon_url : 'default_sales_stage.png');

                                                                        return (<>
                                                                            <th className="text-center">
                                                                                <span className="stage-icon mr-1">
                                                                                    <img src={iconpic} className={`mr-1`} width={`24`} />
                                                                                </span>
                                                                                {/* <i className="ri-lightbulb-line color_blue f-400 ri-2x mr-2"></i> */}
                                                                                {sales_stage.stage_name}
                                                                            </th>
                                                                        </>)
                                                                    })
                                                                ) : null}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(task_completion && task_completion.length > 0) ? (
                                                                task_completion.map((task_comp, i) => {
                                                                    var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((task_comp.sales_rep_profile_pic_url !== "") && (task_comp.sales_rep_profile_pic_url !== null)) ? task_comp.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                    return (<>
                                                                        <tr>

                                                                            <td className="">
                                                                                <div className="main-profile d-flex  align-items-center">
                                                                                    <div className="ml-2">
                                                                                        <div className="name mb-1">
                                                                                            {`${task_comp.opportunity_name}`}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">{`${task_comp.opportunity_satus === '0' ? 'Open' : task_comp.opportunity_satus === '1' ? 'Closed-WON' : 'Closed-LOST'}`}</td>

                                                                            {(task_comp.stages_arr && task_comp.stages_arr.length > 0) ? (
                                                                                task_comp.stages_arr.map((task_com, i) => {
                                                                                    return (<>
                                                                                        <td className="text-center">
                                                                                            {(parseInt(task_com.ratio) >= 50) ? (
                                                                                                <span
                                                                                                    className="d-inline-block text-white hight-light-btn bg_color_light_green">
                                                                                                    {`${task_com.ratio}`}
                                                                                                </span>
                                                                                            ) : (
                                                                                                `${task_com.ratio}`
                                                                                            )}
                                                                                        </td>
                                                                                    </>);
                                                                                })
                                                                            ) : null}
                                                                        </tr>

                                                                    </>);
                                                                })
                                                            ) : null}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Rep fourth report end */}

                                {/* Manish - third report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={increasedrevenue} width="50" className="iconImg" alt="revenue" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Revenue by Opportunity
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Revenue for each opportunity, all statuses.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...salesRepCsvReportThird}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_third" dataUid={`quarter_select_third`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseThree" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5">
                                            <div className="table-container">
                                                <div className="table-responsive">
                                                    <table className="table table-stripedss">
                                                        <thead>
                                                            <tr>
                                                                <th>Opportunity Name</th>
                                                                <th className="text-center">Opportunity Status</th>
                                                                <th className="text-center">Start Date in CoreAi</th>
                                                                <th className="text-center">Opportunity Closed Date
                                                                </th>
                                                                <th className="text-center">Expected Revenue
                                                                </th>
                                                                <th className="text-center">Revenue
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(sales_rep_average_speed_to_close && sales_rep_average_speed_to_close.length > 0) ? (
                                                                sales_rep_average_speed_to_close.map((users, i) => {
                                                                    var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((users.sales_rep_profile_pic_url !== "") && (users.sales_rep_profile_pic_url !== null)) ? users.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>
                                                                                    <div className="main-profile d-flex  align-items-center">
                                                                                        <div className="ml-2">
                                                                                            <div className="name mb-1">
                                                                                                {`${users.opportunity_name}`}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {users.opportunity_status == '0' ? 'Open' : users.opportunity_status == '1' ? 'Closed-WON' : 'Closed-LOST'}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {users.opportunity_start_date_ai}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {users.opportunity_close_date == null ? `-` : `${(users.opportunity_close_date)}`}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    ${`${parseFloat(users.opportunity_expected_revenue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    ${`${parseFloat(users.opportunity_revenue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                                                                </td>

                                                                            </tr>

                                                                        </>
                                                                    )
                                                                })
                                                            ) : null}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* third report end */}
                            </>) : null}





                            {(user_role == 2) ? (<>
                                {/*Company Admin first report start  */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={growth} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4" >
                                                <div className="org-name f2015 w-auto f-600">
                                                    Expected Opportunity Revenue by {(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null}
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Total sum of the expected revenue for all opportunities in Open status for each Sales {(user_type == 'division_manager') ? `Team in the Division` : (user_type == 'org_head') ? `Divisions in the Organization` : null}.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportFirst}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_first" dataUid={`quarter_select_first`}
                                                    onSelect={this.changeQuarter}
                                                />


                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapsefive" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5 opportunity-graph">
                                            <div className="row align-items-center">
                                                <div className="col-xl-12">
                                                    <div className="table-container">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="with_180 border-0"><img src={salesteam} alt="" />{(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null} </th>
                                                                        {/* <th className="with_180 border-0"><i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Team/Division </th> */}
                                                                        <th className="border-0"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(excepected_opportunity_revenue_by_sales_team && excepected_opportunity_revenue_by_sales_team.length > 0) ? (
                                                                        excepected_opportunity_revenue_by_sales_team.map((users, i) => {
                                                                            var profilepic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((users.sales_rep_profile_pic_url !== "") && (users.sales_rep_profile_pic_url !== null)) ? users.sales_rep_profile_pic_url : 'default-avatar.png');

                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="main-profile d-flex  align-items-center">
                                                                                                {/* <div className="img-wrap">
                                                                                                                    <img src={profilepic} alt="" />
                                                                                                                </div> */}
                                                                                                <div className="ml-2">
                                                                                                    <div className="name mb-1">

                                                                                                        {users.org_structure_name}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="progress w-100 bg-transparent">
                                                                                                <div className="progress-bar bg_color_light_blue" role="progressbar" style={{ width: `${percentageValuesFirst[i]}%` }} aria-valuenow={`${percentageValuesFirst[i]}`} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                                {/* {valuesFirst.length>0 && <small className="d-inline-block px-2"> ${valuesFirst[i].toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} </small> } */}
                                                                                                {valuesFirst.length > 0 && <small className="d-inline-block px-2"> ${valuesFirst[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </small>}

                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                </>
                                                                            )
                                                                        })

                                                                    ) : null}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* first report end */}

                                {/*Company Admin second report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={conversionrate} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Opportunity Conversion Rate per {(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null}
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Number of Closed-WON opportunities/Total Number of Opportunities for each {(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null}.</p>
                                                {/* <p className="f2015 mt-2 mb-0">Number of Closed-WON opportunities/Total Number of Opportunities for the company for the selected quarter/year.</p> */}

                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportSecond}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_second" dataUid={`quarter_select_second`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapsesix" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5 opportunity-graph">
                                            <div className="row align-items-center">
                                                <div className="col-xl-12">
                                                    <div className="table-container">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="with_180 border-0"><img src={salesteam} alt="" />{(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null} </th>
                                                                        <th className="border-0"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(opportunity_conversion_rate_per_sales_team && opportunity_conversion_rate_per_sales_team.length > 0) ? (
                                                                        opportunity_conversion_rate_per_sales_team.map((users, i) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="main-profile d-flex  align-items-center">
                                                                                                <div className="ml-2">
                                                                                                    <div className="name mb-1">
                                                                                                        {users.org_structure_name}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="progress w-100 bg-transparent">
                                                                                                <div className="progress-bar bg_color_light_blue" role="progressbar" style={{ width: `${percentageValuesSecond[i]}%` }} aria-valuenow={`${percentageValuesSecond[i]}`} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                                <small className="d-inline-block px-2"> {percentageValuesSecond[i]}% </small>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                </>
                                                                            )
                                                                        })
                                                                    ) : null}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* second report end */}

                                {/*Company Admin third report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={prospect} width="55" className="iconImg" alt="prospect" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Task Progression
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Average Percent Completion of Tasks per Sales Stage for each {(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null}.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportThird}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_third" dataUid={`quarter_select_third`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseOne" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5">
                                            <div className="table-container">
                                                <div className="table-responsive">
                                                    <table className="table table-stripedss">
                                                        <thead>
                                                            <tr>
                                                                <th ><img src={salesteam} alt="" />{(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null}</th>
                                                                {/* <th >
                                                                                            <i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Team
                                                                                        </th> */}
                                                                {(sales_stages && sales_stages.length > 0) ? (
                                                                    sales_stages.map((sales_stage, i) => {
                                                                        var iconpic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((sales_stage.stage_icon_url !== "") && (sales_stage.stage_icon_url !== null)) ? sales_stage.stage_icon_url : 'default_sales_stage.png');

                                                                        return (<>
                                                                            <th className="text-center">
                                                                                <span className="stage-icon mr-1 shadow-none">
                                                                                    <img src={iconpic} className={`mr-1`} width={`24`} />
                                                                                </span>
                                                                                {/* <i className="ri-lightbulb-line color_blue f-400 ri-2x mr-2"></i> */}
                                                                                {sales_stage.stage_name}
                                                                            </th>
                                                                        </>)
                                                                    })
                                                                ) : null}

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(task_progression && task_progression.length > 0) ? (
                                                                task_progression.map((task_progress, i) => {

                                                                    return (<>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="main-profile d-flex  align-items-center">
                                                                                    <div className="ml-2">
                                                                                        <div className="name mb-1">
                                                                                            {`${task_progress.org_structure_name}`}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            {(task_progress.task_progression && task_progress.task_progression.length > 0) ? (
                                                                                task_progress.task_progression.map((task_pro, i) => {
                                                                                    return (<>
                                                                                        <td className="text-center">
                                                                                            {(parseInt(task_pro.task_progression_percentage.toFixed(0)) >= 50) ? (
                                                                                                <span
                                                                                                    className="d-inline-block text-white hight-light-btn bg_color_light_green">
                                                                                                    {`${task_pro.task_progression_percentage.toFixed(0)}%`}
                                                                                                </span>
                                                                                            ) : (
                                                                                                `${task_pro.task_progression_percentage.toFixed(0)}%`
                                                                                            )}
                                                                                        </td>
                                                                                    </>);
                                                                                })
                                                                            ) : null}

                                                                        </tr>

                                                                    </>);
                                                                })
                                                            ) : null}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* third report end */}

                                {/*Company Admin fourth report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={speedometer} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Average Speed to Close
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Average time in days from the Start Date of Opportunity to the Closed Date of Opportunity (marked as Closed-WON/LOST) for each  {(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null}.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvcompanyAdminReportFourth}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_fourth" dataUid={`quarter_select_fourth`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseTwo" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5 opportunity-graph">
                                            <div className="row align-items-center">
                                                <div className="col-xl-12">
                                                    <div className="table-container">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="with_180 border-0"><img src={salesteam} alt="" />{(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Division` : null} </th>
                                                                        <th className="border-0"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(average_speed_to_close && average_speed_to_close.length > 0) ? (
                                                                        average_speed_to_close.map((users, i) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="main-profile d-flex  align-items-center">
                                                                                                <div className="ml-2">
                                                                                                    <div className="name mb-1">
                                                                                                        {`${users.org_structure_name}`}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="progress w-100 bg-transparent">
                                                                                                <div className="progress-bar bg_color_light_blue" role="progressbar" style={{ width: `${percentageValuesFourth[i]}%` }} aria-valuenow={`${percentageValuesFourth[i]}`} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                                <small className="d-inline-block px-2"> {valuesFourth[i]} {(parseInt(valuesFourth[i]) > 1 || parseInt(valuesFourth[i]) == 0) ? `Days` : `Day`} </small>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            );
                                                                        })
                                                                    ) : null}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* fourth report end */}

                                {/*Company Admin fifth report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={revenue} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Average Revenue per Opportunity
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Average revenue for all opportunities in Closed-WON status for each {(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null}.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvcompanyAdminReportFifth}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_fifth" dataUid={`quarter_select_fifth`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseSeven" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5 opportunity-graph">
                                            <div className="row align-items-center">
                                                <div className="col-xl-12">
                                                    <div className="table-container">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="with_180 border-0"><img src={salesteam} alt="" />{(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null} </th>
                                                                        {/* <th className="with_180 border-0"><i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Rep </th> */}
                                                                        <th className="border-0"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(average_revenue_per_opportunity && average_revenue_per_opportunity.length > 0) ? (
                                                                        average_revenue_per_opportunity.map((users, i) => {

                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="main-profile d-flex  align-items-center">
                                                                                                <div className="ml-2">
                                                                                                    <div className="name mb-1">
                                                                                                        {`${users.org_structure_name}`}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="progress w-100 bg-transparent">
                                                                                                <div className="progress-bar bg_color_light_blue" role="progressbar" style={{ width: `${percentageValuesFifth[i]}%` }} aria-valuenow={`${percentageValuesFifth[i]}`} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                                <small className="d-inline-block px-2">   ${valuesFifth[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</small>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                </>
                                                                            )
                                                                        })
                                                                    ) : null}


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* fifth report end */}

                                {/*Company Admin sixth report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={taskcomplete} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Opportunity Average Task Completion
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Average number of completed tasks for each sales stage of an opportunity for each {(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null}.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportSixth}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterText}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_sixth" dataUid={`quarter_select_sixth`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseEight" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5">
                                            <div className="table-container">
                                                <div className="table-responsive">
                                                    <table className="table table-stripedss">
                                                        <thead>
                                                            <tr>
                                                                <th className="with_180"><img src={salesteam} alt="" />{(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null} </th>
                                                                {/* <th><i className="ri-user-line color_blue f-400 ri-2x mr-2"></i> Sales Division/Team </th> */}
                                                                {(sales_stages && sales_stages.length > 0) ? (
                                                                    sales_stages.map((sales_stage, i) => {
                                                                        var iconpic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((sales_stage.stage_icon_url !== "") && (sales_stage.stage_icon_url !== null)) ? sales_stage.stage_icon_url : 'default_sales_stage.png');

                                                                        return (<>
                                                                            <th className="text-center">
                                                                                <span className="stage-icon mr-1">
                                                                                    <img src={iconpic} className={`mr-1`} width={`24`} />
                                                                                </span>
                                                                                {/* <i className="ri-lightbulb-line color_blue f-400 ri-2x mr-2"></i> */}
                                                                                {sales_stage.stage_name}
                                                                            </th>
                                                                        </>)
                                                                    })
                                                                ) : null}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(opportunity_task_completion && opportunity_task_completion.length > 0) ? (
                                                                opportunity_task_completion.map((task_completion, i) => {
                                                                    return (<>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="main-profile d-flex  align-items-center">
                                                                                    <div className="ml-2">
                                                                                        <div className="name mb-1">
                                                                                            {`${task_completion.org_structure_name}`}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            {(task_completion.average_completed_task && task_completion.average_completed_task.length > 0) ? (
                                                                                task_completion.average_completed_task.map((task_com, i) => {
                                                                                    return (<>
                                                                                        <td className="text-center">
                                                                                            {task_com.average_completed_task == "-" || task_com.average_completed_task == "N/A" ? `${task_com.average_completed_task}` : (parseInt(task_com.average_completed_task) >= 50) ? (
                                                                                                <span
                                                                                                    className="d-inline-block text-white hight-light-btn bg_color_light_green">
                                                                                                    {`${task_com.average_completed_task}%`}
                                                                                                </span>
                                                                                            ) : (
                                                                                                `${task_com.average_completed_task}%`
                                                                                            )}
                                                                                        </td>
                                                                                    </>);
                                                                                })
                                                                            ) : null}
                                                                        </tr>
                                                                    </>);
                                                                })
                                                            ) : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* sixth report end */}

                                {/*Company Admin seventh report start */}
                                <div className="table-wrap p-3 p-xl-4 mb-4">
                                    <div className="table-row d-lg-flex justify-content-between flex-wrap align-items-center">
                                        <div className="d-lg-flex justify-content-between align-items-center left-info-text pointer" data-toggle="collapse"
                                            data-target="#collapseNinth" aria-expanded="false" aria-controls="collapseNinth">
                                            <div className="mr-3 sell-icon-wrap no-radius">
                                                <img src={compliant} width="50" className="iconImg" alt="speedometer" />
                                            </div>
                                            <div className="pl-lg-5 ml-lg-4">
                                                <div className="org-name f2015 w-auto f-600">
                                                    Coaching Compliance
                                                </div>
                                                <p className="f2015 mt-2 mb-0">Number of coaching sessions by status for each {(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null}.</p>
                                            </div>
                                        </div>
                                        <div className="right-action d-flex align-items-center justify-content-center mb-1">
                                            <button className="btn white-btn px-4 mx-1"><CSVLink {...csvReportNinth}>Export to CSV</CSVLink></button>
                                            <div className="top-heading mx-1 px-1">
                                                <CustomSelect
                                                    className="selectpicker form-control f-600 W-100"
                                                    defaultText={this.state.defaultQuarterTextCurrentYear}
                                                    optionsList={this.state.quarterList}
                                                    name="quarter_select_eight" dataUid={`quarter_select_eight`}
                                                    onSelect={this.changeQuarter}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target="#collapseNinth" aria-expanded="false" aria-controls="collapseNinth">
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseNinth" className="collapse" data-parent="#accordionTable">
                                        <div className="team-row report-wrap my-3 p-3 px-xl-5">
                                            <div className="table-container">
                                                <div className="table-responsive">
                                                    <table className="table table-stripedss">
                                                        <thead>
                                                            <tr>
                                                                <th className="with_180"><img src={salesteam} alt="" />{(user_type == 'division_manager') ? `Sales Team` : (user_type == 'org_head') ? `Sales Division` : null} </th>
                                                                <th className="text-center">Target Coaching Sessions</th>
                                                                <th className="text-center">Remaining to Target</th>
                                                                <th className="text-center">Completed</th>
                                                                <th className="text-center">Scheduled</th>
                                                                <th className="text-center">In Process</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(sessions_complaince && sessions_complaince.length > 0) ? (
                                                                sessions_complaince.map((sessions_complainc, i) => {
                                                                    var entitity_name = (user_type == 'org_head') ? sessions_complainc.division_name : sessions_complainc.team_name;
                                                                    return (<>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="main-profile d-flex  align-items-center">
                                                                                    <div className="ml-2">
                                                                                        <div className="name mb-1">
                                                                                            {`${entitity_name}`}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {/* {`${parseFloat(sessions_complainc.target_no_of_coaching_session).toFixed(1)}`} */}
                                                                                {`${(sessions_complainc.target_no_of_coaching_session == 0 || sessions_complainc.target_no_of_coaching_session == 0.0 || sessions_complainc.target_no_of_coaching_session == null) ? "N/A" : (parseFloat(sessions_complainc.target_no_of_coaching_session).toFixed(1))}`}

                                                                            </td>
                                                                            <td className="text-center">
                                                                                {(sessions_complainc.target_no_of_coaching_session == 0 || sessions_complainc.target_no_of_coaching_session == 0.0 || sessions_complainc.target_no_of_coaching_session == null) ? (

                                                                                    <span
                                                                                        className="d-inline-block">
                                                                                        {`${(sessions_complainc.target_no_of_coaching_session == 0 || sessions_complainc.target_no_of_coaching_session == 0.0 || sessions_complainc.target_no_of_coaching_session == null) ? "N/A" : (parseFloat(sessions_complainc.remaining_sessions_count).toFixed(1))}`}
                                                                                    </span>
                                                                                ) : (
                                                                                    <div>

                                                                                    {(sessions_complainc.remaining_sessions_count == 0 || sessions_complainc.remaining_sessions_count == 0.0) ? (
                                                                                        <span
                                                                                            className="d-inline-block text-white hight-light-btn bg_color_light_green">
                                                                                            {`${(parseFloat(sessions_complainc.remaining_sessions_count).toFixed(1))}`}
                                                                                        </span>
                                                                                    ) : (
                                                                                        `${parseFloat(sessions_complainc.remaining_sessions_count).toFixed(1)}`
                                                                                    )}
    
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                            {/* <td className="text-center">{`${sessions_complainc.completed_sessions_count}`}
                                                                                            
                                                                                            </td>
                                                                                            <td className="text-center">{`${sessions_complainc.scheduled_sessions_count}`}</td>
                                                                                            <td className="text-center">{`${sessions_complainc.in_process_sessions_count}`}</td> */}
                                                                            <td className="text-center">
                                                                                {/* {`${sessions_complainc.completed_sessions_count}`} */}
                                                                                {`${(sessions_complainc.completed_sessions_count == 0 || sessions_complainc.completed_sessions_count == null) ? "N/A" : (sessions_complainc.completed_sessions_count)}`}
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {/* {`${sessions_complainc.scheduled_sessions_count}`} */}
                                                                                {`${(sessions_complainc.scheduled_sessions_count == 0 || sessions_complainc.scheduled_sessions_count == null) ? "N/A" : (sessions_complainc.scheduled_sessions_count)}`}

                                                                            </td>
                                                                            <td className="text-center">
                                                                                {/* {`${sessions_complainc.in_process_sessions_count}`} */}

                                                                                {`${(sessions_complainc.in_process_sessions_count == 0 || sessions_complainc.in_process_sessions_count == null) ? "N/A" : (sessions_complainc.in_process_sessions_count)}`}

                                                                            </td>
                                                                        </tr>

                                                                    </>);
                                                                })
                                                            ) : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* seventh report end */}

                            </>) : null}


                        </div>
                    </div>
                </main>
            </>);
    }

}