import React, { Component } from 'react'
import logo from "../images/logo.png";
import axios from './apiConnection/axios';

export default class CreatePassword extends Component {
    constructor(props) {
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        this.state = {
            token: query.get('token'),
            user_id: query.get('user_id'),
            password: '',
            crmpassword: '',
            Message: '',
            MessageType: '',
            first: "0",
            showError: '',
        }
    }
    componentDidMount() {
        this.checkLikeExpire();
        this.setState({
            token: this.props.match.params.token,
            user_id: this.props.match.params.uid,
            first: String(this.props.match.params.first)
        })
    }


    checkLikeExpire = async () => {
        await axios.post(`/users/check-password-link-token`, { "token": this.props.match.params.token }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res);

                if (res.data.code === 200) {
                    this.setState({
                        showError: "NO",
                    });
                } else {
                    this.setState({
                        showError: "YES",
                    });
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }


    confirmPassword = async () => {
        const confirmpasswordinfo = {
            password: this.state.password,
            token: this.state.token,
            user_id: this.state.user_id,
            type: this.state.first
        }
        console.log(confirmpasswordinfo);
        if (this.state.password != this.state.crmpassword) {
            this.setState({
                Message: 'Passwords do not match.',
                MessageType: 'danger'
            });
        }
        else if ((this.state.password).length <= 11) {
            this.setState({
                Message: 'Password should be atleast 12 characters long.',
                MessageType: 'danger'
            });
        }
        else if ((this.state.password).length >= 128) {
            this.setState({
                Message: 'Password can be maximum 128 characters long.',
                MessageType: 'danger'
            });
        }
        else if (!(this.state.password).match(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{12,128}$/)) {
            this.setState({
                Message: 'Password should be a combination of alpha (atleast one uppercase & one lowercase), numbers and special characters.',
                MessageType: 'danger'
            });
        }
        else {

            await axios.post(`/users/update-password`, confirmpasswordinfo, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                }
            })
                .then(res => {
                    console.log(res);
                    this.setPermissions(res.data.data.role_id);
                    if (res.data.code === 200) {
                        if (this.state.first === "0") {
                            this.setState({
                                Message: "Password has beed updated.",
                                MessageType: 'success'
                            })
                            window.location.href = "/login";

                        } else if (this.state.first === "1") {
                            var profileImg = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((res.data.data.profile_pic_url !== "") && (res.data.data.profile_pic_url !== null)) ? res.data.data.profile_pic_url : 'default-avatar.png');
    
                            localStorage.setItem('profilePic', profileImg);
                    
                            if (res.data.data.role_id === 1) {
                                localStorage.setItem('adminsaToken', res.data.data.role_id)
                                localStorage.setItem('adminsaUserId', res.data.data.id)
                                localStorage.setItem('full_name', res.data.data.first_name + ' ' + res.data.data.last_name);
    
                                localStorage.setItem('userid', res.data.data.id)
                                this.setState({
                                    is_loggedin: 1,
                                    role_id: 1,
                                    Message: 'Logged in successfully.',
                                    MessageType: 'success'
                                });
                                this.setPermissions(res.data.data.role_id);
                            }
                            if ((res.data.data.role_id === 4) || (res.data.data.role_id === 3) || (res.data.data.role_id === 2)) {
                                localStorage.setItem('adminsrToken', res.data.data.role_id)
                                localStorage.setItem('adminsrUserId', res.data.data.id)
    
                                localStorage.setItem('userid', res.data.data.id)
                                localStorage.setItem('full_name', res.data.data.first_name + ' ' + res.data.data.last_name);
                                var user_role_id = res.data.data.role_id;
                                this.setState({
                                    is_loggedin: 1,
                                    role_id: user_role_id,
                                    Message: 'Logged in successfully.',
                                    MessageType: 'success'
                                });
                                this.setPermissions(res.data.data.role_id);

                            }
                        }

                    } else if (res.data.code === 201) {
                        this.setState({
                            Message: res.data.message,
                            MessageType: 'danger'
                        });
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }
    setPermissions = async (role_id) => {
        const payLoad = {
            role_id: role_id
        }
        await axios.post(`/permissions/get-modules-by-role-id`, payLoad, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                if (res.data.code === 200) {
                    localStorage.setItem('permissions', JSON.stringify(res.data.data));
                    if (role_id===1){
                        window.location.href = "/system-admin/dashboard";
                    } else if ((role_id===2) || (role_id===3) || (role_id===4)){
                        window.location.href = "/app/myprofile";
                    }
                } else if (res.data.code === 201) {

                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    changeFunction = (e) => {
        if (e.target.id === 'password') {
            this.setState({ password: e.target.value });
        }
        else if (e.target.id === 'crmpassword') {
            this.setState({ crmpassword: e.target.value });
        }
        this.setState({
            Message: null,
            MessageType: null
        });
    }

    render() {
        const { Message, MessageType, first, showError } = this.state;
        return (<>
            <div className="body-content">
                {((showError === "YES")) ?
                    (
                        <div className="text-center f-500 warning-wrap-100 d-flex p-3 justify-content-between  align-items-center">
                            <div className="w-100">

                                <div className="divconmoon icon-warning mb-3 warning-icon"></div>
                                <h3 className="py-4">Link has been expired.</h3>
                                {/* <Link to={`./login`} className="color_blue underline">Go to login</Link> */}
                                <a href={`/`} className="color_blue underline">Go to login</a>
                            </div>
                        </div>
                    ) : (``)
                }
                {((showError === "NO")) ?
                    (
                        <div>
                            <div className="general-user-wrap d-flex py-3 justify-content-between  align-items-center">
                                <div className="container">

                                    <div className="row justify-content-between  align-items-center">
                                        <div className="col-lg-6 col-md-5 px-lg-5">
                                            <div className="welcome-text text-center">
                                                <img src={logo} alt="logo" width="250" />
                                                <h3 className="mb-4 mt-5">
                                                    Welcome to Core Ai
                                                </h3>
                                                <p>
                                                    We provide insights on your sales opportunities so you are focused on the right tasks, at the right time, to win.
                                                </p>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-7">
                                            <div className="form-wrap d-flex align-items-center justify-content-center">
                                                <div className="w-100">
                                                    <h3 className="text-center mt-0 mb-3">
                                                        Create New Password
                                                    </h3>
                                                    <p className="text-center mb-lg-5 mb-4">
                                                        {(first === "0") ? (`Your new password must be different from last 3 Passwords.`) : (``)}
                                                    </p>
                                                    <form>

                                                        {((Message) && (Message !== null)) ?
                                                            (
                                                                <div className={`alert alert-${MessageType}`}>
                                                                    {Message}
                                                                </div>
                                                            ) : (``)
                                                        }
                                                        <div className="form-group mb-lg-5">
                                                            <label className="mb-0">Password</label>
                                                            <input type="password" id='password' className="form-control f-600 f2015" onChange={this.changeFunction} />
                                                        </div>
                                                        <div className="form-group mb-lg-5">
                                                            <label className="mb-0">Confirm Password</label>
                                                            <input type="password" id='crmpassword' className="form-control f-600 f2015" onChange={this.changeFunction} />
                                                        </div>
                                                        <button type="button" onClick={() => this.confirmPassword()} className="btn py-lg-2 dark-btn w-100">Create Password</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    ) : (``)
                }
            </div>

        </>
        )
    }
}