import React, { Component } from 'react'
// import logo from "../../../images/logo.png";
import logo from "../../../images/header-logo.svg";

import profile2x from "../../../images/default-avatar.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import axios from '../../apiConnection/axios';
import {
  Link
} from "react-router-dom";

export default class Header extends Component {
  constructor(props) {

    super(props);
    this.state = {
        profileImg: profile2x,
    }
}
  componentDidMount(){
    this.getProfile();   
   }
   getProfile = async () => {
       if (localStorage.getItem('userid')) {
           var user_id = localStorage.getItem('userid')
           const profileinfo = {
               'user_id': user_id,
           }        
           await axios.post(`/users/get-profile`, profileinfo, {
               headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json;charset=UTF-8',
               }
           })
               .then(res => {
                   if (res.data.code === 200) {
                       this.setState(prevState=>({...prevState,
                           profile_pic_url:res.data.data.profile_pic_url,
                           profileImg: process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((res.data.data.profile_pic_url!=="") && (res.data.data.profile_pic_url!==null)) ? res.data.data.profile_pic_url : 'default-avatar.png'),
                       }));
                   } 
               })
               .catch((error) => {
                   console.log(error)
               })

       }
   }  
  render() {
    const {profileImg} = this.state;
    return (
      <>
        <header>
          <nav className="navbar navbar-expand-lg p-0">
            <div className="container px-3 justify-content-between align-items-center">
              <span className="color_blue f2015 f-600 d-block d-lg-none toggler"><i className="ri-menu-line"></i></span>
              <Link to={'./login'} className="navbar-brand"><img src={logo} alt="logo" /></Link>
              <div className="right-content-menu d-flex justify-content-between align-items-center">
                <div className="img-wrap">
                  <Link to={`./myprofile`}>
                    <img src={profileImg} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </>
    )
  }
}
