import React, { Component } from 'react'
import CustomSelect from "./elements/customSelect";
import { Link } from 'react-router-dom';
import { Line, Doughnut } from 'react-chartjs-2';
import speedometer from '../../images/speedometer.svg';
import revenue from '../../images/revenue.svg';
import conversionRate from '../../images/conversion-rate.svg';
import Chart from 'react-apexcharts';
import lightFilter from '../../images/lightFilter.svg';
import Utility from "../../components/utility";
import axios from '../apiConnection/axios';

import $ from 'jquery';
const utilityObj = new Utility;
export default class dashboard extends Component {
    constructor(props) {
        super(props);
        const year = new Date().getFullYear();
        var totals = 0;
        function sleep(num) {
            let now = new Date();
            let stop = now.getTime() + num;
            while (true) {
                now = new Date();
                if (now.getTime() > stop) return;
            }
        }
        this.state = {
            GraphAPIdata: [],
            first_card: 0,
            second_card: 0,
            third_card: 0,
            opportunities_per_stage: [],
            showLegends: false,
            defaultQuarterText: "All Data ",
            allAwaitingActionItems: [],
            company_name: "",
            team_name: "",
            topcards_link: "",
            coaching_session_compliance: "",
            action_items: {
                "upcoming_sessions": {
                    "upcoming_sessions_count": 0,
                    "upcoming_sessions_unread_items": "no"
                },
                "awaiting_feedback_sessions": {
                    "awaiting_feedback_sessions_count": 0,
                    "awaiting_feedback_sessions_unread_items": "no"
                },
                "analytical_feedbacks_coachings_count": {
                    "analytical_feedback_sessions_count": 0,
                    "analytical_feedback_sessions_unread_items": "no"
                },
                "awaiting_completion_sessions": {
                    "awaiting_clouser_sessions_count": 0,
                    "awaiting_clouser_sessions_unread_items": "no"
                },
                "awaiting_clouser_sessions": {
                    "awaiting_clouser_sessions_count": 0,
                    "awaiting_clouser_sessions_unread_items": "no"
                }

            },
            year: year,
            quarterList: [
                { id: -1, name: "All Data ", value: -1 },
                { id: 0, name: "Year To Date " + year, value: 0 },
                { id: 1, name: "1st Quarter " + year, value: 1 },
                { id: 2, name: "2nd Quarter " + year, value: 2 },
                { id: 3, name: "3rd Quarter " + year, value: 3 },
                { id: 4, name: "4th Quarter " + year, value: 4 },
            ],
            // donutseries: [100, 55, 41, 1, 15, 16],
            donutseries: [],
            donuttotal: 1,
            donutoptions: {
                plotOptions: {
                    pie: {
                        donut: {
                            size: '60%',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '22px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: undefined,
                                    offsetY: -10,
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '16px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 400,
                                    color: undefined,
                                    offsetY: 16,
                                    formatter: function (val, w) {
                                        var total = w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        }, 0)
                                        sleep(500);
                                        return parseFloat((val / total) * 100).toFixed(0) + '%';
                                    }
                                },
                                total: {

                                    formatter: function (w) {
                                        totals = w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b;
                                        }, 0)
                                        sleep(500);
                                        return totals;
                                    },
                                    show: true,
                                    showAlways: false,
                                    label: 'Total',
                                    fontSize: '22px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: '#373d3f',

                                }
                            },
                        }
                    },
                },
                //   labels: ['Prospect', 'Qualify', 'Develop', 'Validate', 'Close', 'Measure'],
                labels: [],
                chart: {
                    type: 'donut',
                },
                // colors: ['#005491', '#FF7C00', '#FC530E', '#FFB708', '#6E9204', '#424242', '#003665', '#3291D1'],          
                colors: ['#005491', '#FF7C00', '#FC530E', '#FFB708', '#6E9204', '#424242'],
                yaxis: {
                    floating: false,
                },
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'left',
                    offsetX: 0,
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(0) + " %"
                    },
                    offsetY: -10,
                    style: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        },
                        dataLabels: {
                            enabled: false,
                            formatter: function (val) {
                                return val.toFixed(0) + " %"
                            },
                        },
                    }
                }],

            },


            series: [
                {
                    name: 'Sales Representative 1',
                    data: [6, 19, 15, 16, 13, 17, 9, 7, 10, 18, 12, 14],
                },
                {
                    name: 'Sales Representative 2',
                    data: [7, 16, 13, 9, 14, 10, 11, 17, 6, 5, 12, 18],
                },
                {
                    name: 'Sales Representative 3',
                    data: [13, 10, 18, 14, 11, 12, 15, 8, 16, 17, 9, 19],
                },
                {
                    name: 'Sales Representative 4',
                    data: [15, 18, 19, 17, 8, 10, 16, 12, 5, 14, 6, 11],
                },
            ],
            options: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },

                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    // colors: ['#003366', 'red', 'green', '#E4D00A'],
                    width: 3,
                    dashArray: 2,

                },
                markers: {
                    color: 'red'
                },
                grid: {
                    show: false,
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                colors: ['#005491', '#FF7C00', '#FC530E', '#FFB708', '#6E9204', '#424242', '#003665', '#3291D1'],
                // colors: ['#005491', '#FF7C00', '#FC530E', '#FFB708'],          
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    opposite: false,
                    labels: {
                        formatter: function (val, index) {
                            return val;
                        }
                    }
                },
                states: {
                    normal: {
                        filter: {
                            type: 'darken',
                            value: 1,
                        }
                    },
                    hover: {
                        filter: {
                            type: 'darken',
                            value: 1,
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'darken',
                            value: 0.35,
                        }
                    },
                },

                legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: false,
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    tooltipHoverFormatter: undefined,
                    customLegendItems: [],
                    offsetX: 0,
                    offsetY: 0,
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 0
                    },
                    onItemClick: {
                        toggleDataSeries: true
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    },
                },
            },


            open_opportunities: 0,
            closed_won_opportunities: 0,
            closed_lost_opportunities: 0,
            assigned_tasks: 0,
            rejected_tasks: 0,
            approved_tasks: 0,
            tasks_awaiting_review: 0,
            scheduled_sessions: 0,
            completed_sessions: 0
        };
    }


    componentDidMount() {
        // $('.coaching-section .apexcharts-legend').hide(); 
        $('#linechart1').css('margin-top', '-50px');
        const user_id = localStorage.getItem('userid');
        const user_role = localStorage.getItem('adminsrToken');


        this.setState({ user_role });
        this.setState({ user_id: user_id }, () => {
            if (user_role == 2) {
                // http://localhost:3004/api/v1/dashboard/company-admin-coaching-data
                this.getGraphandOtherForDivisionManager();
                this.setState({ topcards_link: './reports' });
            } else if (user_role == 3) {
                this.getAwaitingActionManager();
                this.getGraphandOtherForManager();
                this.setState({ topcards_link: './reports' });
            } else if (user_role == 4) {
                this.getAwaitingActionRep();
                this.getGraphandOtherForRep();
                this.setState({ topcards_link: './opportunities' });
            }
            this.changeQuarter(-1, 'quarter_select');
        });

    }
    showLegends = () => {
        if (this.state.showLegends === true) {
            this.setState({ showLegends: false })
            $('.coaching-section .apexcharts-legend').css('opacity', '0');
            $('.coaching-section .apexcharts-legend').css('pointer-events', 'none');
            // $('#linechart1').css('margin-top', '-50px');  

        } else {
            this.setState({ showLegends: true })
            $('.coaching-section .apexcharts-legend').css('opacity', '1');
            $('.coaching-section .apexcharts-legend').css('pointer-events', 'inherit');
            // $('#linechart1').css('margin-top', '0px');  

        }

    }
    // dashboard/sales-manager-awaiting-tasks
    getAwaitingActionRep = async () => {
        const { user_id } = this.state;
        const payload = {
            sales_rep_id: user_id,
        }
        await axios.post(`dashboard/sales-rep-awaiting-tasks`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {

                    this.setState({ team_name: res.data.data[0].team_name, company_name: res.data.data[0].company_name, allAwaitingActionItems: res.data.data[0] }, () => {
                        console.log(this.state.allAwaitingActionItems.tasks);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    // dashboard/company-admin-coaching-data
    getGraphandOtherForDivisionManager = async () => {
        const { user_id, year } = this.state;
        const payload = {
            company_admin_id: user_id,
            year: year,
        }
        await axios.post(`dashboard/company-admin-coaching-data`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {

                    this.setState({ GraphAPIdata: res.data.data[0], coaching_session_compliance: res.data.data[0].coaching_session_compliance }, () => {
                        var repseriesvalue = [];
                        var averageseriesvalue = [];
                        var divisionNames = [];
                        var valuesforDivitions = [];
                        var series = [];
                        var averageName = "";
                        if ((res.data.data[0].coaching_session_graph) && (res.data.data[0].coaching_session_graph.length > 0)) {

                            if (res.data.data[0].coaching_session_graph[0].division && res.data.data[0].coaching_session_graph[0].division.length > 0) {
                                for (const [key, value] of Object.entries(res.data.data[0].coaching_session_graph[0].division)) {
                                    divisionNames.push(res.data.data[0].coaching_session_graph[0].division[key].division_name);
                                    valuesforDivitions[res.data.data[0].coaching_session_graph[0].division[key].division_name] = [];

                                }
                                for (const [key, value] of Object.entries(res.data.data[0].coaching_session_graph)) {
                                    if (res.data.data[0].coaching_session_graph[key].company_average && res.data.data[0].coaching_session_graph[key].company_average != undefined) {
                                        averageseriesvalue.push(res.data.data[0].coaching_session_graph[key].company_average);
                                        averageName = "Company Average";
                                        for (const [key2, value2] of Object.entries(res.data.data[0].coaching_session_graph[key].division)) {
                                            console.log(res.data.data[0].coaching_session_graph[key].division[key2].division_name);
                                            valuesforDivitions[res.data.data[0].coaching_session_graph[key].division[key2].division_name].push(res.data.data[0].coaching_session_graph[key].division[key2].division_coaching_count);
                                        }
                                    }
                                }
                                console.log(divisionNames);

                                series.push({
                                    name: averageName,
                                    data: averageseriesvalue
                                });

                                for (const [key, value] of Object.entries(divisionNames)) {
                                    console.log(valuesforDivitions[divisionNames[key]]);
                                    series.push({
                                        name: divisionNames[key],
                                        data: valuesforDivitions[divisionNames[key]]
                                    });

                                }
                                // this.setState({series : series}, ()=>{
                                //     console.log(this.state.series);
                                // })                                
                            }
                            else if (res.data.data[0].coaching_session_graph[0].team && res.data.data[0].coaching_session_graph[0].team.length > 0) {
                                for (const [key, value] of Object.entries(res.data.data[0].coaching_session_graph[0].team)) {
                                    divisionNames.push(res.data.data[0].coaching_session_graph[0].team[key].team_name);
                                    valuesforDivitions[res.data.data[0].coaching_session_graph[0].team[key].team_name] = [];

                                }
                                for (const [key, value] of Object.entries(res.data.data[0].coaching_session_graph)) {
                                    if (res.data.data[0].coaching_session_graph[key].division_avergae) {
                                        averageseriesvalue.push(res.data.data[0].coaching_session_graph[key].division_avergae);
                                        averageName = "Division Average";
                                        for (const [key2, value2] of Object.entries(res.data.data[0].coaching_session_graph[key].team)) {
                                            console.log(res.data.data[0].coaching_session_graph[key].team[key2].team_name);
                                            valuesforDivitions[res.data.data[0].coaching_session_graph[key].team[key2].team_name].push(res.data.data[0].coaching_session_graph[key].team[key2].sales_team_coaching_count);
                                        }
                                    }
                                }
                                console.log(divisionNames);

                                series.push({
                                    name: averageName,
                                    data: averageseriesvalue
                                });

                                for (const [key, value] of Object.entries(divisionNames)) {
                                    console.log(valuesforDivitions[divisionNames[key]]);
                                    series.push({
                                        name: divisionNames[key],
                                        data: valuesforDivitions[divisionNames[key]]
                                    });

                                }
                                // this.setState({series : series}, ()=>{
                                //     console.log(this.state.series);
                                // })                                
                            }



                        }
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }

    //  dashboard/sales-manager-coaching-data
    getGraphandOtherForManager = async () => {
        const { user_id, year } = this.state;
        const payload = {
            sales_manager_id: user_id,
            year: year,
        }
        await axios.post(`dashboard/sales-manager-coaching-data`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {

                    this.setState({ GraphAPIdata: res.data.data[0], coaching_session_compliance: res.data.data[0].coaching_session_compliance, action_items: res.data.data[0].action_items }, () => {
                        var repseriesvalue = [];
                        var averageseriesvalue = [];
                        var salesrepnames = [];
                        var valuesforreps = [];
                        var series = [];
                        if ((res.data.data[0].coaching_session_graph) && (res.data.data[0].coaching_session_graph.length > 0)) {

                            if (res.data.data[0].coaching_session_graph[0].sales_rep && res.data.data[0].coaching_session_graph[0].sales_rep.length > 0) {
                                for (const [key, value] of Object.entries(res.data.data[0].coaching_session_graph[0].sales_rep)) {
                                    salesrepnames.push(res.data.data[0].coaching_session_graph[0].sales_rep[key].sales_rep_name);
                                    valuesforreps[res.data.data[0].coaching_session_graph[0].sales_rep[key].sales_rep_name] = [];

                                }
                                for (const [key, value] of Object.entries(res.data.data[0].coaching_session_graph)) {
                                    averageseriesvalue.push(res.data.data[0].coaching_session_graph[key].team_avergae);
                                    for (const [key2, value2] of Object.entries(res.data.data[0].coaching_session_graph[key].sales_rep)) {
                                        console.log(res.data.data[0].coaching_session_graph[key].sales_rep[key2].sales_rep_name);
                                        valuesforreps[res.data.data[0].coaching_session_graph[key].sales_rep[key2].sales_rep_name].push(res.data.data[0].coaching_session_graph[key].sales_rep[key2].sales_rep_coaching_count);
                                    }
                                }
                                console.log(salesrepnames);
                                series.push({
                                    name: "Team Average",
                                    data: averageseriesvalue
                                });

                                for (const [key, value] of Object.entries(salesrepnames)) {
                                    console.log(valuesforreps[salesrepnames[key]]);
                                    series.push({
                                        name: salesrepnames[key],
                                        data: valuesforreps[salesrepnames[key]]
                                    });

                                }
                                // this.setState({series : series}, ()=>{
                                //     console.log(this.state.series);
                                // })                                
                            }

                        }
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }



    //  dashboard/sales-rep-coaching-data
    getGraphandOtherForRep = async () => {
        const { user_id, year } = this.state;
        const payload = {
            sales_rep_id: user_id,
            year: year,
        }
        await axios.post(`dashboard/sales-rep-coaching-data`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {

                    this.setState({ GraphAPIdata: res.data.data[0], coaching_session_compliance: res.data.data[0].coaching_session_compliance, action_items: res.data.data[0].action_items }, () => {
                        var repseriesvalue = [];
                        var averageseriesvalue = [];
                        if ((res.data.data[0].coaching_session_graph) && (res.data.data[0].coaching_session_graph.length > 0)) {
                            for (const [key, value] of Object.entries(res.data.data[0].coaching_session_graph)) {
                                repseriesvalue.push(res.data.data[0].coaching_session_graph[key].sales_rep_coaching_count);
                                averageseriesvalue.push(res.data.data[0].coaching_session_graph[key].team_avergae);
                            }
                        }
                        // series: [
                        //     {
                        //       name: 'Team Average',
                        //       data: [2, 3, 5, 1, 3, 2, 4, 1, 3, 2, 4, 1],
                        //     },
                        //     {
                        //       name: 'Sessions',
                        //       data: [0, 2, 5, 2, 4, 1, 1, 2, 4, 1, 1, 2],
                        //     },              
                        //   ],
                        this.setState({
                            series: [
                                {
                                    name: 'Team Average',
                                    data: averageseriesvalue,
                                },
                                {
                                    name: 'Sessions',
                                    data: repseriesvalue,
                                }
                            ]
                        }, () => {
                            console.log(this.state.series);
                        })
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }



    // dashboard/sales-manager-awaiting-tasks
    getAwaitingActionManager = async () => {
        const { user_id } = this.state;
        const payload = {
            sales_manager_id: user_id,
        }
        await axios.post(`dashboard/sales-manager-awaiting-tasks`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {

                    this.setState({ team_name: res.data.data[0].team_name, company_name: res.data.data[0].company_name, allAwaitingActionItems: res.data.data[0] }, () => {
                        console.log(this.state.allAwaitingActionItems.tasks);
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    changeQuarter = (ele, Uid) => {
        const { user_role } = this.state;
        const year = new Date().getFullYear();
        if (ele == 0) {
            this.setState({ startDate: year + '-01-01', endDate: year + '-12-31' }, () => {
                (user_role == 2) ? this.getDataDivisionManager() : (user_role == 3) ? this.getDataManager() : this.getDataRep();
            })
        } else if (ele == 1) {
            this.setState({ startDate: year + '-01-01', endDate: year + '-03-31' }, () => {
                (user_role == 2) ? this.getDataDivisionManager() : (user_role == 3) ? this.getDataManager() : this.getDataRep();
            })
        } else if (ele == 2) {
            this.setState({ startDate: year + '-04-01', endDate: year + '-06-30' }, () => {
                (user_role == 2) ? this.getDataDivisionManager() : (user_role == 3) ? this.getDataManager() : this.getDataRep();
            })
        } else if (ele == 3) {
            this.setState({ startDate: year + '-07-01', endDate: year + '-09-30' }, () => {
                (user_role == 2) ? this.getDataDivisionManager() : (user_role == 3) ? this.getDataManager() : this.getDataRep();
            })
        } else if (ele == 4) {
            this.setState({ startDate: year + '-10-01', endDate: year + '-12-31' }, () => {
                (user_role == 2) ? this.getDataDivisionManager() : (user_role == 3) ? this.getDataManager() : this.getDataRep();
            })
        } else if (ele == -1) {
            this.setState({ startDate: '2021-01-01', endDate: year + '-12-31' }, () => {
                (user_role == 2) ? this.getDataDivisionManager() : (user_role == 3) ? this.getDataManager() : this.getDataRep();
            })
        }
    }



    getDataRep = async () => {
        const { startDate, endDate, user_id } = this.state;
        const payload = {
            sales_rep_id: user_id,
            start_date: startDate,
            end_date: endDate
        }
        await axios.post(`dashboard/get-sales-rep-dashboard-data`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    this.setState(prevState => ({
                        ...prevState,
                        donutseries: [],
                        donutoptions: {
                            ...prevState.donutoptions,
                            labels: [],
                        }
                    }), () => {
                        this.setState({
                            allLoadedData: res.data.data[0],
                            first_card: res.data.data[0].top_cards.opportunity_speed_to_close,
                            second_card: res.data.data[0].top_cards.opportunity_average_revenue,
                            third_card: res.data.data[0].top_cards.opp_coversion_rate,
                            opportunities_per_stage: res.data.data[0].opportunities_per_stage,
                        }, () => {
                            var seriesvalue = [];
                            var serieslabels = [];
                            var legendslabels = [];
                            var sum = 0;
                            if ((res.data.data[0].opportunities_per_stage) && (res.data.data[0].opportunities_per_stage.length > 0)) {
                                for (const [key, value] of Object.entries(res.data.data[0].opportunities_per_stage)) {
                                    var percent = (res.data.data[0].opportunities_per_stage[key].opportunity_percentage).toFixed(0);
                                    seriesvalue.push(res.data.data[0].opportunities_per_stage[key].opportunity_count);
                                    serieslabels.push(res.data.data[0].opportunities_per_stage[key].stage_name + ' [' + percent + '%] ');
                                    legendslabels.push(res.data.data[0].opportunities_per_stage[key].stage_name);
                                    // serieslabels.push(res.data.data[0].opportunities_per_stage[key].stage_name);
                                }
                            }
                            sum = seriesvalue.reduce((partialSum, a) => partialSum + a, 0);
                            this.setState(prevState => ({
                                ...prevState,
                                donutseries: seriesvalue,
                                donuttotal: sum,
                                serieslabels,
                                donutoptions: {
                                    ...prevState.donutoptions,
                                    labels: legendslabels,
                                }
                            }),
                                () => {
                                    console.log(this.state.donutoptions);
                                    console.log(this.state.donutseries);
                                })
                        });
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    getDataManager = async () => {
        const { startDate, endDate, user_id } = this.state;
        const payload = {
            sales_manager_id: user_id,
            start_date: startDate,
            end_date: endDate
        }
        await axios.post(`dashboard/get-sales-manager-dashboard-data`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    this.setState(prevState => ({
                        ...prevState,
                        donutseries: [],
                        donutoptions: {
                            ...prevState.donutoptions,
                            labels: [],
                        }
                    }), () => {
                        this.setState({
                            allLoadedData: res.data.data[0],
                            first_card: res.data.data[0].top_cards.opportunity_speed_to_close,
                            second_card: res.data.data[0].top_cards.opportunity_average_revenue,
                            third_card: res.data.data[0].top_cards.opp_coversion_rate,
                            opportunities_per_stage: res.data.data[0].opportunities_per_stage,
                        }, () => {
                            var seriesvalue = [];
                            var serieslabels = [];
                            var legendslabels = [];
                            var sum = 0;
                            if ((res.data.data[0].opportunities_per_stage) && (res.data.data[0].opportunities_per_stage.length > 0)) {
                                for (const [key, value] of Object.entries(res.data.data[0].opportunities_per_stage)) {
                                    var percent = (res.data.data[0].opportunities_per_stage[key].opportunity_percentage).toFixed(0);
                                    seriesvalue.push(res.data.data[0].opportunities_per_stage[key].opportunity_count);
                                    serieslabels.push(res.data.data[0].opportunities_per_stage[key].stage_name + ' [' + percent + '%] ');
                                    legendslabels.push(res.data.data[0].opportunities_per_stage[key].stage_name);
                                    // serieslabels.push(res.data.data[0].opportunities_per_stage[key].stage_name);
                                }
                            }
                            sum = seriesvalue.reduce((partialSum, a) => partialSum + a, 0);
                            this.setState(prevState => ({
                                ...prevState,
                                donutseries: seriesvalue,
                                donuttotal: sum,
                                serieslabels,
                                donutoptions: {
                                    ...prevState.donutoptions,
                                    labels: legendslabels,
                                }
                            }),
                                () => {
                                    console.log(this.state.donutoptions);
                                    console.log(this.state.donutseries);
                                })
                        });
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    getDataDivisionManager = async () => {
        const { startDate, endDate, user_id } = this.state;
        const payload1 = {
            company_admin_id: user_id,
        }
        await axios.post(`dashboard/get-company-admin-dashboard-title`, payload1, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {

                    this.setState({ team_name: res.data.data[0].node_name, company_name: res.data.data[0].company_name }, () => {

                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
        const payload = {
            company_admin_id: user_id,
            start_date: startDate,
            end_date: endDate
        }
        await axios.post(`dashboard/get-company-admin-dashboard-data`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    this.setState(prevState => ({
                        ...prevState,
                        donutseries: [],
                        donutoptions: {
                            ...prevState.donutoptions,
                            labels: [],
                        }
                    }), () => {
                        this.setState({
                            allLoadedData: res.data.data[0],
                            first_card: res.data.data[0].top_cards.opportunity_speed_to_close,
                            second_card: res.data.data[0].top_cards.opportunity_average_revenue,
                            third_card: res.data.data[0].top_cards.opp_coversion_rate,
                            opportunities_per_stage: res.data.data[0].opportunities_per_stage,
                            open_opportunities: res.data.data[0].status_update.open_opportunities,
                            closed_won_opportunities: res.data.data[0].status_update.closed_won_opportunities,
                            closed_lost_opportunities: res.data.data[0].status_update.closed_lost_opportunities,
                            assigned_tasks: res.data.data[0].status_update.assigned_tasks,
                            rejected_tasks: res.data.data[0].status_update.rejected_tasks,
                            approved_tasks: res.data.data[0].status_update.approved_tasks,
                            tasks_awaiting_review: res.data.data[0].status_update.tasks_awaiting_review,
                            scheduled_sessions: res.data.data[0].status_update.scheduled_sessions,
                            completed_sessions: res.data.data[0].status_update.completed_sessions
                        }, () => {
                            var seriesvalue = [];
                            var serieslabels = [];
                            var legendslabels = [];
                            var sum = 0;
                            if ((res.data.data[0].opportunities_per_stage) && (res.data.data[0].opportunities_per_stage.length > 0)) {
                                for (const [key, value] of Object.entries(res.data.data[0].opportunities_per_stage)) {
                                    var percent = (res.data.data[0].opportunities_per_stage[key].opportunity_percentage).toFixed(0);
                                    seriesvalue.push(res.data.data[0].opportunities_per_stage[key].opportunity_count);
                                    serieslabels.push(res.data.data[0].opportunities_per_stage[key].stage_name + ' [' + percent + '%] ');
                                    legendslabels.push(res.data.data[0].opportunities_per_stage[key].stage_name);
                                    // serieslabels.push(res.data.data[0].opportunities_per_stage[key].stage_name);
                                }
                            }
                            sum = seriesvalue.reduce((partialSum, a) => partialSum + a, 0);
                            this.setState(prevState => ({
                                ...prevState,
                                donutseries: seriesvalue,
                                donuttotal: sum,
                                serieslabels,
                                donutoptions: {
                                    ...prevState.donutoptions,
                                    labels: legendslabels,
                                }
                            }),
                                () => {
                                    console.log(this.state.donutoptions);
                                    console.log(this.state.donutseries);
                                })
                        });
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getOrganizationTitle = async () => {
        const { user_id } = this.state;
        const payload = {
            company_admin_id: user_id,
        }
        await axios.post(`dashboard/get-company-admin-dashboard-title`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {

                    this.setState({ team_name: res.data.data[0].team_name, company_name: res.data.data[0].company_name }, () => {

                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    markreadCommonFunction = async (notification_type, redirectlink) => {
        const { user_id, user_role } = this.state;
        const payload = {
            user_id: user_id,
            notification_type: notification_type,
            user_role
        }
        await axios.post(`notifications/mark-action-item-read-by-user`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                if (res.data.code === 200) {
                    setTimeout(() => { window.location.href = `./${redirectlink}`; }, 500);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    render() {
        const { donuttotal, team_name, company_name, topcards_link, user_role, first_card, second_card, third_card, opportunities_per_stage, allAwaitingActionItems, coaching_session_compliance, action_items } = this.state;
        var self = this;
        $(document).ready(function () {
            $(".apexcharts-legend-series").on("click", function () {
                self.showLegends();
            });
        });
        return (
            <>
                <main className="main-contant opportunity-management-main">
                    <div className="contents">
                        <div className="d-flex flex-wrap justify-content-between mb-lg-4 
 mb-3  align-items-center">
                            <h3 className="title-head m-0 py-2">{team_name == "CEO" ? company_name : company_name + ' - ' + team_name}
                                {/* {(user_role==4) ? ` Sales Rep` : (user_role==3) ? ` Sales Manager` : (user_role==2) ? ` Division Manager` : ``} Dashboard */}
                            </h3>
                            <form>
                                <div className="form-group mb-0 top-heading">
                                    <CustomSelect
                                        className="selectpicker form-control f-600 W-100"
                                        defaultText={this.state.defaultQuarterText}
                                        name="quarter_select" dataUid={`quarter_select`}
                                        optionsList={this.state.quarterList}
                                        onSelect={this.changeQuarter}
                                    />

                                    {/* <select data-style="bg-white rounded-pill form-control"
                                className="selectpicker form-control f-600 W-100">
                                <option value="0">1 st Quarter 2021</option>
                                <option value="0">2 nd Quarter 2021</option>
                                <option value="0">3 rd Quarter 2021</option>
                                <option value="0">4 th Quarter 2021</option>
                            </select> */}
                                </div>
                            </form>
                        </div>
                        <div className="row dashboard-main-row card-row dashboard-card-row">
                            <div className="col-xl-8 col-8-size px-2">
                                <div className="row card-row dashboard-card-row">
                                    <div className="col-xl-4 col-sm-12 px-2">
                                        <Link to={`${topcards_link}`}  >
                                            <div
                                                className="d-flex p-4  cards align-items-center w-100 bg-theme justify-content-between">
                                                <div className="text-white ">
                                                    <div className="clearfix d-flex align-items-center mb-2">
                                                        <div className="float-left icon">
                                                            <img src={speedometer} width="32" alt="speedometer" />
                                                            {/* <i className="ri-notification-line ri-2x text-white"></i> */}
                                                        </div>
                                                        <div className="float-right text-head pl-2">
                                                            Opportunity Average Speed to Close
                                                        </div>
                                                    </div>
                                                    <div className="point-counts">
                                                        {/* {parseFloat(first_card).toFixed(1)}<small className="f-300"> Days</small> */}
                                                        125<small className="f-300"> Days</small>

                                                    </div>
                                                </div>
                                                <div className="iconmoon icon-chevron-right f-100  text-white"></div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-xl-4 col-sm-12 px-2">
                                        <Link to={`${topcards_link}`}  >
                                            <div
                                                className="d-flex p-4  cards align-items-center w-100 bg-theme justify-content-between">
                                                <div className="text-white ">
                                                    <div className="clearfix d-flex align-items-center mb-2">
                                                        <div className="float-left icon">
                                                            <img src={revenue} width="32" alt="revenue" />
                                                            {/* <i className="ri-notification-line ri-2x text-white"></i> */}
                                                        </div>
                                                        <div className="float-right text-head pl-2">
                                                            Opportunity Average Revenue
                                                        </div>
                                                    </div>
                                                    <div className="point-counts">
                                                        {/* $ {second_card.toFixed(0)} */}
                                                        $ 575,000
                                                    </div>
                                                </div>
                                                <div className="iconmoon icon-chevron-right f-100  text-white"></div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-xl-4 col-sm-12 px-2">
                                        <Link to={`${topcards_link}`}  >
                                            <div
                                                className="d-flex p-4  cards align-items-center w-100 bg-theme justify-content-between">
                                                <div className="text-white ">
                                                    <div className="clearfix d-flex align-items-center mb-2">
                                                        <div className="float-left icon">
                                                            <img src={conversionRate} width="32" alt="conversion" />
                                                            {/* <i className="ri-notification-line ri-2x text-white"></i> */}
                                                        </div>
                                                        <div className="float-right text-head pl-2">
                                                            Opportunity Conversion Rate
                                                        </div>
                                                    </div>
                                                    <div className="point-counts">
                                                        45%
                                                        {/* {third_card.toFixed(0)}% */}
                                                    </div>
                                                </div>
                                                <div className="iconmoon icon-chevron-right f-100  text-white"></div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-12 px-2">
                                        <div className="cord-body-wrap w-100 bg-white p-3 p-xl-4">
                                            <div className="d-flex  align-items-center  justify-content-between">
                                                <div className="w-75">
                                                    <div className="card-heading">
                                                        Coaching Sessions Over Time
                                                    </div>
                                                    <small>
                                                        {(user_role == 4) ? (
                                                            'Coaching sessions over 12 months for the Sales Rep.'
                                                        ) :
                                                            (user_role == 3) ? (
                                                                'Coaching sessions over 12 months for each Sales Rep.'
                                                            ) : ((user_role == 2) && (team_name == "CEO")) ? (
                                                                'Coaching sessions over 12 months for each Sales division'
                                                            ) : (
                                                                'Coaching sessions over 12 months for each Sales team'
                                                            )
                                                        }
                                                    </small>
                                                </div>
                                                <div className="actions graph-wrap-dropdown position-relative">
                                                    <div className="dropdown">
                                                        <button className="btn dropdown-toggle" type="button" onClick={() => this.showLegends()}>
                                                            <img src={lightFilter} width="26" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="graph-wrap">
                                                <h3 className="text-center ">
                                                    <div className="coaching-section">
                                                        <Chart id={`linechart1`} options={this.state.options} series={this.state.series} type="line" height={350} />
                                                    </div>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 px-2">
                                        <div className="cord-body-wrap w-100 bg-white p-3 p-xl-4">
                                            <div className="w-75">
                                                <div className="card-heading">
                                                    Opportunities Per Stage
                                                </div>
                                                <small>Percentage of opportunities per sales stage for the sales team.</small>
                                            </div>
                                            <div className="graph-wrap donut-wrap pt-3">
                                                <div className="text-center pt-4">
                                                    {(donuttotal > 0) ? (
                                                        <Chart options={this.state.donutoptions} series={this.state.donutseries} type="donut" width={`100%`} />
                                                    ) : (
                                                        <h2 className="card-heading">No open opportunities found.</h2>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 px-2">
                                        <div className="cord-body-wrap w-100 bg-white p-3 p-xl-4">
                                            <div className="d-flex  align-items-center  justify-content-between">
                                                <div className="w-75">
                                                    <div className="card-heading">
                                                        Coaching Sessions Compliance

                                                    </div>
                                                    <small>
                                                        {(user_role == 4) ? (
                                                            'Coaching sessions Completed, In Process and Scheduled compared to the yearly target sessions.'
                                                        ) :
                                                            (user_role == 3) ? (
                                                                'Coaching sessions Completed, In Process and Scheduled compared to the yearly target sessions.'
                                                            ) : (user_role == 2) ? (
                                                                'Coaching sessions Completed, In Process and Scheduled compared to the yearly target sessions.'
                                                            ) : (
                                                                ''
                                                            )
                                                        }


                                                    </small>
                                                </div>
                                                <Link to={`./reports`}>
                                                    <div className="iconmoon icon-chevron-right f-100"></div>
                                                </Link>
                                            </div>
                                            <div className="sessions-info-wrap mt-4">
                                                <div className="row dashboard-card-row">
                                                    <div className="col-xl-6 col-lg-12 col-sm-6 px-2">
                                                        <div
                                                            className="session-list my-2 d-flex justify-content-between align-items-center">
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    <div className="session-count color_blue">
                                                                        {/* {coaching_session_compliance.target_no_of_coaching_session} */}
                                                                        0
                                                                    </div>
                                                                </div>
                                                                <div className="float-right w-100">
                                                                    <div className="session-info w-100 px-2">
                                                                        <small className="w-100 color_dark">Target Number of
                                                                            Coaching Sessions</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-12 col-sm-6 px-2">
                                                        <div
                                                            className="session-list my-2 action-Awaiting d-flex justify-content-between align-items-center">
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    <div className="session-count color_orange">
                                                                        {/* {coaching_session_compliance.remaining_sessions} */}
                                                                        32
                                                                    </div>
                                                                </div>
                                                                <div className="float-right w-100">
                                                                    <div className="session-info w-100 px-2">
                                                                        <small className="w-100 color_dark"> Coaching Sessions
                                                                            Remaining to Target</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="progress-bar-wrap mt-4">
                                                    <div className="progress-bar-list mb-4">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <small className="d-block mb-2 color_dark">Completed</small>
                                                            <small className="d-block mb-2 color_dark">
                                                                {/* {coaching_session_compliance.completed_coachings_count} */}
                                                                10/20
                                                                {/* {coaching_session_compliance.target_no_of_coaching_session}                                                         */}
                                                            </small>
                                                        </div>
                                                        <div className="progress w-100">
                                                            {/* <div className="progress-bar bg-completed" style={{width:(coaching_session_compliance.completed_coachings_count/coaching_session_compliance.target_no_of_coaching_session)*100+'%'}} role="progressbar"></div> */}
                                                            <div className="progress-bar bg-completed" style={{ width: "50%" }} role="progressbar"></div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="progress-bar-list mb-4  ">
                                                    <div className="d-flex align-items-center  justify-content-between">
                                                        <small className="d-block mb-2 color_dark">In Process </small>
                                                        <small className="d-block mb-2 color_dark">
                                                            {/* {coaching_session_compliance.inprogress_coachings_count} */}
                                                            16/32
                                                            {/* {coaching_session_compliance.target_no_of_coaching_session}                                                     */}
                                                        </small>
                                                    </div>
                                                    <div className="progress w-100">
                                                        {/* <div className="progress-bar bg-success" style={{width:(coaching_session_compliance.inprogress_coachings_count/coaching_session_compliance.target_no_of_coaching_session)*100+'%'}}  role="progressbar"></div> */}
                                                        <div className="progress-bar bg-completed" style={{ width: "50%" }} role="progressbar"></div>

                                                    </div>
                                                </div>
                                                <div className="progress-bar-list">
                                                    <div className="d-flex align-items-center  justify-content-between">
                                                        <small className="d-block mb-2 color_dark">Scheduled</small>
                                                        <small className="d-block mb-2 color_dark">
                                                            {/* {coaching_session_compliance.scheduled_coachings_count} */}
                                                            34/52
                                                            {/* {coaching_session_compliance.target_no_of_coaching_session}                                                     */}
                                                        </small>
                                                    </div>
                                                    <div className="progress w-100">
                                                        {/* <div className="progress-bar bg-scheduled " style={{width:(coaching_session_compliance.scheduled_coachings_count/coaching_session_compliance.target_no_of_coaching_session)*100+'%'}}  role="progressbar"></div> */}
                                                        <div className="progress-bar bg-completed" style={{ width: "70%" }} role="progressbar"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-4-size px-2">
                                {this.state.user_role == 2 ?
                                    <div className="row card-row dashboard-card-row right-row h-100">
                                        <div className="col-6-size col-lg-12 col-md-6 px-2">
                                            <div className="cord-body-wrap w-100 bg-white p-3 p-xl-4">
                                                <div className="card-heading">
                                                    Status Update
                                                </div>
                                                <div className="session-list-wrap py-3">
                                                    <Link to={`${topcards_link}`}  >
                                                        <div className="session-list d-flex justify-content-between align-items-center">
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    <div className="session-count color_blue">
                                                                        {this.state.open_opportunities}
                                                                    </div>
                                                                </div>
                                                                <div className="float-right">
                                                                    <div className="session-info px-3">
                                                                        <p className="mb-1">Open Opportunities</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="iconmoon icon-chevron-right f-100"></div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="session-list-wrap py-3">
                                                    <Link to={`${topcards_link}`}  >
                                                        <div className="session-list d-flex justify-content-between align-items-center">
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    <div className="session-count color_blue">
                                                                        {this.state.closed_won_opportunities}
                                                                    </div>
                                                                </div>
                                                                <div className="float-right">
                                                                    <div className="session-info px-3">
                                                                        <p className="mb-1">Closed-WON Opportunities</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="iconmoon icon-chevron-right f-100"></div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="session-list-wrap py-3">
                                                    <Link to={`${topcards_link}`}  >
                                                        <div className="session-list d-flex justify-content-between align-items-center">
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    <div className="session-count color_blue">
                                                                        {this.state.closed_lost_opportunities}
                                                                    </div>
                                                                </div>
                                                                <div className="float-right">
                                                                    <div className="session-info px-3">
                                                                        <p className="mb-1">Closed-LOST Opportunities</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="iconmoon icon-chevron-right f-100"></div>
                                                        </div>
                                                    </Link>
                                                </div>


                                                <div className="session-list-wrap py-3">
                                                    <Link to={`${topcards_link}`}  >
                                                        <div className="session-list d-flex justify-content-between align-items-center">
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    <div className="session-count color_blue">
                                                                        {this.state.assigned_tasks}
                                                                    </div>
                                                                </div>
                                                                <div className="float-right">
                                                                    <div className="session-info px-3">
                                                                        <p className="mb-1">Assigned Tasks</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="iconmoon icon-chevron-right f-100"></div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="session-list-wrap py-3">
                                                    <Link to={`${topcards_link}`}  >
                                                        <div className="session-list d-flex justify-content-between align-items-center">
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    <div className="session-count color_blue">
                                                                        {this.state.tasks_awaiting_review}
                                                                    </div>
                                                                </div>
                                                                <div className="float-right">
                                                                    <div className="session-info px-3">
                                                                        <p className="mb-1">Tasks Awaiting Review</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="iconmoon icon-chevron-right f-100"></div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="session-list-wrap py-3">
                                                    <Link to={`${topcards_link}`}  >
                                                        <div className="session-list d-flex justify-content-between align-items-center">
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    <div className="session-count color_blue">
                                                                        {this.state.rejected_tasks}
                                                                    </div>
                                                                </div>
                                                                <div className="float-right">
                                                                    <div className="session-info px-3">
                                                                        <p className="mb-1">Rejected Tasks</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="iconmoon icon-chevron-right f-100"></div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="session-list-wrap py-3">
                                                    <Link to={`${topcards_link}`}  >
                                                        <div className="session-list d-flex justify-content-between align-items-center">
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    <div className="session-count color_blue">
                                                                        {this.state.approved_tasks}
                                                                    </div>
                                                                </div>
                                                                <div className="float-right">
                                                                    <div className="session-info px-3">
                                                                        <p className="mb-1">Approved Tasks</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="iconmoon icon-chevron-right f-100"></div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="session-list-wrap py-3">
                                                    <Link to={`./calendar`}  >
                                                        <div className="session-list d-flex justify-content-between align-items-center">
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    <div className="session-count color_blue">
                                                                        {this.state.scheduled_sessions}
                                                                    </div>
                                                                </div>
                                                                <div className="float-right">
                                                                    <div className="session-info px-3">
                                                                        <p className="mb-1">Scheduled Sessions</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="iconmoon icon-chevron-right f-100"></div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="session-list-wrap py-3">
                                                    <Link to={`${topcards_link}`}  >
                                                        <div className="session-list d-flex justify-content-between align-items-center">
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    <div className="session-count color_blue">
                                                                        {this.state.completed_sessions}
                                                                    </div>
                                                                </div>
                                                                <div className="float-right">
                                                                    <div className="session-info px-3">
                                                                        <p className="mb-1">Completed Sessions</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="iconmoon icon-chevron-right f-100"></div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div> :
                                    <div className="row card-row dashboard-card-row right-row h-100">
                                        <div className="col-6-size col-lg-12 col-md-6 px-2">
                                            <div className="dashboard-right-bar-card1 cord-body-wrap w-100 bg-white p-3 p-xl-3">
                                                <div className="card-heading">
                                                    Action Items
                                                </div>

                                                <small>Click a category to take action.</small>
                                                <div className="session-list-wrap py-3">
                                                    <div className={`session-list ${(action_items.upcoming_sessions.upcoming_sessions_count == '0') ? `action-completed` : null} d-flex justify-content-between align-items-center`}>
                                                        <div className="clearfix d-flex  align-items-center">
                                                            <div className="float-left">
                                                                {(action_items.upcoming_sessions.upcoming_sessions_count == '0') ? (
                                                                    <div className="session-count ">
                                                                        <i className="ri-check-line f-600 ri-1x"></i>
                                                                    </div>
                                                                ) : (
                                                                    <div className="session-count color_blue">
                                                                        {action_items.upcoming_sessions.upcoming_sessions_count}
                                                                        {(action_items.upcoming_sessions.upcoming_sessions_unread_items == 'yes') ? (<span className="indicator"></span>) : null}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="float-right">
                                                                <div className="session-info px-3">
                                                                    <p className="mb-1">Upcoming Sessions</p>
                                                                    <small className={(action_items.upcoming_sessions.upcoming_sessions_count == '0') ? null : `color_orange`}>There are {action_items.upcoming_sessions.upcoming_sessions_count} upcoming sessions.</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Link onClick={() => this.markreadCommonFunction(`upcoming_session`, `coaching`)}>
                                                            <div className="iconmoon icon-chevron-right f-100"></div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="session-list-wrap py-3">
                                                    <div className={`session-list ${(action_items.awaiting_feedback_sessions.awaiting_feedback_sessions_count == '0') ? `action-completed` : null} d-flex justify-content-between align-items-center`}>
                                                        <div className="clearfix d-flex  align-items-center">
                                                            <div className="float-left">
                                                                {(action_items.awaiting_feedback_sessions.awaiting_feedback_sessions_count == '0') ? (
                                                                    <div className="session-count ">
                                                                        <i className="ri-check-line f-600 ri-1x"></i>
                                                                    </div>
                                                                ) : (
                                                                    <div className="session-count color_blue">
                                                                        {action_items.awaiting_feedback_sessions.awaiting_feedback_sessions_count}
                                                                        {(action_items.awaiting_feedback_sessions.awaiting_feedback_sessions_unread_items == 'yes') ? (<span className="indicator"></span>) : null}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="float-right">
                                                                <div className="session-info px-3">
                                                                    <p className="mb-1">Sessions Awaiting Feedback</p>
                                                                    <small className={(action_items.awaiting_feedback_sessions.awaiting_feedback_sessions_count == '0') ? null : `color_orange`}>There are {action_items.awaiting_feedback_sessions.awaiting_feedback_sessions_count} sessions waiting for your feedback.</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Link onClick={() => this.markreadCommonFunction(`awaiting_feedback`, `coaching`)}>
                                                            <div className="iconmoon icon-chevron-right f-100"></div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                {(user_role == 4) ? (

                                                    <div className="session-list-wrap py-3">
                                                        <div className={`session-list ${(action_items.awaiting_clouser_sessions.awaiting_clouser_sessions_count == '0') ? `action-completed` : null} d-flex justify-content-between align-items-center`}>
                                                            <div className="clearfix d-flex  align-items-center">
                                                                <div className="float-left">
                                                                    {(action_items.awaiting_clouser_sessions.awaiting_clouser_sessions_count == '0') ? (
                                                                        <div className="session-count ">
                                                                            <i className="ri-check-line f-600 ri-1x"></i>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="session-count color_blue">
                                                                            {action_items.awaiting_clouser_sessions.awaiting_clouser_sessions_count}
                                                                            {(action_items.awaiting_clouser_sessions.awaiting_clouser_sessions_unread_items == 'yes') ? (<span className="indicator"></span>) : null}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="float-right">
                                                                    <div className="session-info px-3">
                                                                        <p className="mb-1">Opportunities Awaiting Closure</p>
                                                                        <small className={(action_items.awaiting_clouser_sessions.awaiting_clouser_sessions_count == '0') ? null : `color_orange`}>There are {action_items.awaiting_clouser_sessions.awaiting_clouser_sessions_count} opportunities waiting to be closed.</small>
                                                                    </div>
                                                                </div>
                                                                <Link onClick={() => this.markreadCommonFunction(`opportunity_awaiting_closure`, `opportunities`)}>
                                                                    <div className="iconmoon icon-chevron-right f-100"></div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {(user_role == 3) ? (
                                                    <>
                                                        <div className="session-list-wrap py-3">
                                                            <div className={`session-list ${(action_items.analytical_feedbacks_coachings_count.analytical_feedback_sessions_count == '0') ? `action-completed` : null} d-flex justify-content-between align-items-center`}>
                                                                <div className="clearfix d-flex  align-items-center">
                                                                    <div className="float-left">
                                                                        {(action_items.analytical_feedbacks_coachings_count.analytical_feedback_sessions_count == '0') ? (
                                                                            <div className="session-count ">
                                                                                <i className="ri-check-line f-600 ri-1x"></i>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="session-count color_blue">
                                                                                {action_items.analytical_feedbacks_coachings_count.analytical_feedback_sessions_count}
                                                                                {(action_items.analytical_feedbacks_coachings_count.analytical_feedback_sessions_unread_items == 'yes') ? (<span className="indicator"></span>) : null}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="float-right">
                                                                        <div className="session-info px-3">
                                                                            <p className="mb-1">Analytics Awaiting Review</p>
                                                                            <small className={(action_items.analytical_feedbacks_coachings_count.analytical_feedback_sessions_count == '0') ? null : `color_orange`}>There are {action_items.analytical_feedbacks_coachings_count.analytical_feedback_sessions_count} sessions with analytics feedback awaiting review.</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Link onClick={() => this.markreadCommonFunction(`analytical_feedback`, `coaching`)}>
                                                                    <div className="iconmoon icon-chevron-right f-100"></div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="session-list-wrap py-3">
                                                            <div className={`session-list ${(action_items.awaiting_completion_sessions.awaiting_clouser_sessions_count == '0') ? `action-completed` : null} d-flex justify-content-between align-items-center`}>
                                                                <div className="clearfix d-flex  align-items-center">
                                                                    <div className="float-left">
                                                                        {(action_items.awaiting_completion_sessions.awaiting_clouser_sessions_count == '0') ? (
                                                                            <div className="session-count ">
                                                                                <i className="ri-check-line f-600 ri-1x"></i>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="session-count color_blue">
                                                                                {action_items.awaiting_completion_sessions.awaiting_clouser_sessions_count}
                                                                                {(action_items.awaiting_completion_sessions.awaiting_clouser_sessions_unread_items == 'yes') ? (<span className="indicator"></span>) : null}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="float-right">
                                                                        <div className="session-info px-3">
                                                                            <p className="mb-1">Sessions Awaiting Completion</p>
                                                                            <small className={(action_items.awaiting_completion_sessions.awaiting_clouser_sessions_count == '0') ? null : `color_orange`}>There are {action_items.awaiting_completion_sessions.awaiting_clouser_sessions_count} sessions waiting to be completed.</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Link onClick={() => this.markreadCommonFunction(`awaiting_completion`, `coaching`)}>
                                                                    <div className="iconmoon icon-chevron-right f-100"></div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-6-size col-lg-12 col-md-6 px-2">
                                            <div className=" dashboard-right-bar-card2 cord-body-wrap w-100 bg-white p-3 p-xl-3">
                                                <div className="card-heading">
                                                    Tasks Awaiting Action
                                                </div>
                                                <small>There are {(allAwaitingActionItems.tasks_count) ? allAwaitingActionItems.tasks_count : `no`} {(allAwaitingActionItems.tasks_count == 1) ? ` task ` : ` tasks `} waiting for you to take action.</small>
                                                <div className="user-card-container">
                                                    <div className="user-list-wrap w-100">
                                                        {(allAwaitingActionItems.tasks) ?
                                                            allAwaitingActionItems.tasks.map((task) => {
                                                                return (
                                                                    <Link to={`./task-details/${task.opportunity_id}`} key={task.task_id}>
                                                                        <div className="session-list-wrap py-3">
                                                                            <div className="session-list d-flex justify-content-between align-items-center">
                                                                                <div className="clearfix d-flex  align-items-center">
                                                                                    <div className="float-right">
                                                                                        <div className="session-info pr-3">
                                                                                            <p className="mb-1 color_black" title={task.opportunity_name}>{task.opportunity_name.substr(0, 25)}{(task.opportunity_name.length > 25) ? `\u2026` : null}</p>
                                                                                            <small title={task.task_title}>{(task.task_title).substr(0, 50)}{((task.task_title).length > 50) ? `\u2026` : null}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {(task.task_status == 6) ? (
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <span className="status-tag status-tag-open  mx-2">Awaiting Review</span>
                                                                                        <i className="iconmoon icon-chevron-right f-100"></i>
                                                                                    </div>
                                                                                ) : null}

                                                                                {(task.task_status == 1) ? (
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <span className="status-tag status-tag-open  mx-2">Assigned</span>
                                                                                        <i className="iconmoon icon-chevron-right f-100"></i>
                                                                                    </div>
                                                                                ) : null}

                                                                                {(task.task_status == 3) ? (
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <span className="status-tag status-tag-rejeccted  mx-2">Rejected</span>
                                                                                        <i className="iconmoon icon-chevron-right f-100"></i>
                                                                                    </div>
                                                                                ) : null}

                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                );
                                                            }) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main>

            </>
        )
    }
}
