import React, { Component } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import {
    Link
} from "react-router-dom";

export default class SuccessUserManagement extends Component {
    constructor(props) {
        console.log(localStorage.getItem('getAlluserCount'));
        super(props);
        this.state = {
            userCount: localStorage.getItem('getAlluserCount')
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { userCount } = this.state
        return (<>
            <main className="main-contant">
                <div className="contents">
                    <h3 className="title-head m-0">User Management</h3>
                    <div className="steps-container d-flex justify-content-center">
                        <div className="steps-list-wrap d-flex color_blue justify-content-between py-3 align-items-center">
                            <div className="steps-list text-center f2015 px-4">
                                <div className="steps-count steps-completed">
                                    1
                                </div>
                                <div className="steps-text mt-2">
                                    Upload
                                </div>
                            </div>
                            <div className="steps-list text-center f2015 px-4">
                                <div className="steps-count steps-completed">
                                    2
                                </div>
                                <div className="steps-text mt-2">
                                    Confirmation
                                </div>
                            </div>
                            <div className="steps-list text-center f2015 px-4">
                                <div className="steps-count steps-completed">
                                    3
                                </div>
                                <div className="steps-text mt-2">
                                    Success
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="invitation-sent upload-files mx-auto d-flex justify-content-center align-items-center">
                        <div className="invitation-box text-center color_blue">
                            <i className="iconmoon icon-check check-successfully"></i>
                            <p className="my-4">
                                {userCount} {((userCount == 1) || (userCount == 0)) ? ` user ` : ` users `}created <span className="d-sm-block"> {((userCount == 1) || (userCount == 0)) ? ` Invitation ` : ` Invitations `}sent successfully.</span>
                            </p>
                            <Link type="button" to={`./user-management`} className="btn dark-btn px-5">View Users</Link>
                        </div>
                    </div>
                </div>
            </main>


        </>
        )
    }
}
