import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV} from '@fortawesome/free-solid-svg-icons';

class tableContextMenu extends Component {
    render() {
        console.log("ROW INDEX", this.props.rowIndex);
        var user_index = this.props.rowIndex;
        var user_status = "";
        var user_name = "";
        var user_id = "";
        if(this.props.formatExtraData){
            var user_detail = this.props.formatExtraData.user_data[user_index];
            
            user_status = user_detail.status;
            user_name = user_detail.fullname;
            user_id = user_detail.id;
            console.log("#######",user_status);

        }
        console.log("FORMAT EXTRA DATA",this.props.formatExtraData.user_data[user_index]);
        
        return (
            <div className="actions">
            <div className="dropdown">
                <button className="btn dropdown-toggle" type="button"
                    id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <FontAwesomeIcon icon={faEllipsisV}/>    
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                  <a className="dropdown-item" onClick={() => this.props.setProfileShow(true, user_name, user_id)}>
                    <i className="iconmoon icon-viewprofile"></i> View
                    Profile</a>
                {user_status=="Inactive"?
                <><a className="dropdown-item" onClick={() => this.props.setActivateUserShow(true, user_name, user_id )}>
                <i className="iconmoon icon-check"></i> Activate
                User</a>
                </>
                :<>    
                <a className="dropdown-item" onClick={() => this.props.setDeactivateUserShow(true, user_name, user_id)}>
                    <i className="iconmoon icon-deactivate"></i> Deactivate
                    User</a>
                    </>
                }
                </div>
            </div>
        </div>
        );
    }
}

export default tableContextMenu;