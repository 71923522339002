import React, { Component } from 'react'
// import logo from "../../../images/logo.png";
import logo from "../../../images/header-logo.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import use_profile2x from "../../../images/use-profile2x.png";
import axios from '../../apiConnection/axios';
import moment from 'moment';

import {
	Link
  } from "react-router-dom"; 

import $ from 'jquery';

export default class Header extends Component {
  constructor(props) {

    super(props);
    this.state = {
        profileImg: use_profile2x,        
        notifications: [],
        notifications_count: 0,
        show_notification:false,
        redirect_url:"",
    }
}
  componentDidMount(){
    this.setState({profileImg:localStorage.getItem('profilePic'), user_id:localStorage.getItem('userid'), });
    this.getNotifications();
    if (localStorage.getItem('userid')) {
      var user_id = localStorage.getItem('userid')
      this.setState({user_id} , ()=>{
        this.getProfile();
      })
    }
   }
  getProfile = async () => {
    const {user_id} = this.state;
        const profileinfo = {
            'user_id': user_id,
        }        
        await axios.post(`/users/get-profile`, profileinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res);
                if (res.data.code === 200) {
                  if (res.data.data.status!="2"){
                    localStorage.removeItem('userid');
                    window.location.href = "/login";
                  }
                } else if (res.data.code === 201) {
                    localStorage.removeItem('userid');
                    window.location.href = "/login";
                }
            })
            .catch((error) => {
              localStorage.removeItem('userid');
              window.location.href = "/login";
            })
    
}


    getNotifications = async () => {
    var user_id = localStorage.getItem('userid');
    var user_role = localStorage.getItem('adminsrToken');
    var notification_for = "";
    if(user_role==3){
      this.setState({redirect_url:"scheduler?view=week"});
      notification_for = "sales_manager";
    }else if(user_role==4){
      this.setState({redirect_url:"calendar?view=day"});
      notification_for = "sales_rep";
    }
   
    const post_data = {
        user_id:user_id,
        notification_for:notification_for
    }        
    if(user_role==3 || user_role==4){
        await axios.post(`notifications/get-notifications-by-user`, post_data, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
        .then(res => {
          console.log("debug====",res.data.data[0].unread_message_count);
            if (res.data.code === 200) {
              console.log("DEBUG@:",res.data.data[0].notifications);
              this.setState({notifications:res.data.data[0].notifications,notifications_count:res.data.data[0].unread_message_count,});
            } else {
            }
        })
        .catch((error) => {
            console.log(error)
        })
      }

}

readMarkNotifications = async () => {

  if(this.state.show_notification){
    // this.setState({show_notification:false});
  }else{
    this.setState({show_notification:true});
  }
  var user_id = localStorage.getItem('userid');
 
 
  const post_data = {
      user_id:user_id,
  }        
  
      await axios.post(`notifications/mark-message-read-by-user`, post_data, {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
          }
      })
      .then(res => {
          if (res.data.code === 200) {
            this.getNotifications();
          } else {
          }
      })
      .catch((error) => {
          console.log(error)
      })
    

}
    render() {
      setTimeout(() => {
        this.getProfile();
      }, 2000)
      const {profileImg, notifications_count, notifications,show_notification,redirect_url} = this.state;
      console.log("SUPERD DEBUG : ",notifications);
        return (
            <>
    <header className="opportunity-management-header">
      <nav className="navbar navbar-expand-lg p-0">
        <div className="container px-3 justify-content-between align-items-center">
          <span className="color_blue f2015 f-600 d-block d-lg-none toggler"><i className="ri-menu-line"></i></span>            
          <a href="javascript:;" className="navbar-brand"><img src={logo} alt="logo"/></a>

          <div className={"right-content-menu d-flex justify-content-between align-items-center " + (show_notification ? 'show' : '')}>
          <div className=" notifications px-md-4 mx-md-4 mx-1 px-2" id="notificationsButton" data-toggle="" aria-haspopup={show_notification} aria-expanded="true"  onClick={() => this.readMarkNotifications()}>

                    <i className="ri-notification-line ri-3x"></i>
                    <span className="notification-count">{notifications_count}</span>
                    <div className={"dropdown dropdown-menu "+ (show_notification ? 'show' : '')} aria-labelledby="notificationsButton" >
                    <div className="dropdown-inner-wrap">
                    {(notifications && notifications.length>0) ? (
                    notifications.map((notification,index) => {
                      if(notification.notification_for=="sales_manager"){

                        if(notification.notification_type=="session_rescheduled"){
                        return(
                          
                        <a className="dropdown-item" href={redirect_url+"&session_event_id="+notification.session_event_id} target="_self" >
                        <div className="d-sm-flex justify-content-between align-items-center m-2">
                          <div className="n-name f-500">
                          {notification.session_events} - {notification.stage_name} Stage
                          </div>
                          <div className="n-time light_text">
                            {/* {moment.utc(notification.created_at).local().startOf('seconds').fromNow()} */}
                            {notification.time_passed}
                          </div>
                        </div>
                        <div className="date-time light_text m-2">
                          <i className="ri-time-line"></i> {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")}

<span className="white-space-pre">   {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("hh:mma")} - {moment(parseInt(notification.end_date_tmestamp)*1000).utc().format("hh:mma")}</span> 
                        </div>
                        <div className="d-sm-flex justify-content-between align-items-center m-2">
                        <div className="scheduled-wrap color_light_blue">
                          Session Reschedule Requested
                        </div>
                        <div className="right-wrap d-flex justify-content-between align-items-center">
                          {/* <i className="ri-calendar-todo-line mx-1"></i> */}
                          <span className="status-tag border-0  bg_color_blue text-white f-300 px-2"><small className="ri-calendar-todo-line"></small> Calendar</span>
                          {/* <span className="status-tag border-0  bg_color_blue text-white mx-3 f-300 px-2"><small className="ri-check-line"></small> Assigned</span>
                          <span className="color_blue"><small className="ri-history-line color_blue"></small> Reschedule</span> */}
                        </div>
                      </div>
                      </a>  )}
                      else if(notification.notification_type=="session_invitation"){
                        return ( <a className="dropdown-item" href={redirect_url+"&session_event_id="+notification.session_event_id}>
                        <div className="d-sm-flex justify-content-between align-items-center m-2">
                          <div className="n-name f-500">
                          {notification.session_events} - {notification.stage_name} Stage
                          </div>
                          <div className="n-time light_text">
                          {moment.utc(notification.created_at).local().startOf('seconds').fromNow()}
                          </div>
                        </div>
                        <div className="date-time light_text m-2">
                          <i className="ri-time-line"></i> {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")} <span className="white-space-pre"> {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("hh:mma")} - {moment(parseInt(notification.end_date_tmestamp)*1000).utc().format("hh:mma")}</span> 
                        </div>
                        <div className="d-sm-flex justify-content-between align-items-center m-2">
                          <div className="scheduled-wrap color_blue">
                          Session Invitation Accepted
                          </div>                         
                        </div>
                      </a>)
                      }else if(notification.notification_type=="session_reminder"){
                        return (
                        <a className="dropdown-item" href={redirect_url+"&session_event_id="+notification.session_event_id}>
                        <div className="d-sm-flex justify-content-between align-items-center m-2">
                          <div className="n-name f-500">
                          {notification.session_events} - {notification.stage_name} Stage
                          </div>
                          <div className="n-time light_text">
                          {moment.utc(notification.created_at).local().startOf('seconds').fromNow()}
                          </div>
                        </div>
                        <div className="date-time light_text m-2">
                          <i className="ri-time-line"></i> {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")} <span className="white-space-pre"> {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("hh:mma")} - {moment(parseInt(notification.end_date_tmestamp)*1000).utc().format("hh:mma")}</span> 
                        </div>
                        <div className="d-sm-flex justify-content-between align-items-center m-2">
                          <div className="scheduled-wrap color_light">
                            Session starts in 10 minutes
                          </div>
                          <div className="right-wrap d-flex justify-content-between align-items-center">
                            <span className="status-tag border-0  bg_color_blue text-white f-300 px-2"><small className="ri-video-chat-line"></small> Launch Session</span>
                          </div>
                        </div>
                      </a>)
                      }
                      }else if(notification.notification_for=="sales_rep"){
                        if(notification.notification_type=="session_rescheduled"){
                          return(
                            
                          <a className="dropdown-item" href={redirect_url+"&session_event_id="+notification.session_event_id}>
                          <div className="d-sm-flex justify-content-between align-items-center m-2">
                            <div className="n-name f-500">
                            {notification.session_events} - {notification.stage_name} Stage
                            </div>
                            <div className="n-time light_text">
                            {moment.utc(notification.created_at).local().startOf('seconds').fromNow()}
                            </div>
                          </div>
                          <div className="date-time light_text m-2">
                            <i className="ri-time-line"></i> {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")} <span className="white-space-pre"> {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("hh:mma")} - {moment(parseInt(notification.end_date_tmestamp)*1000).utc().format("hh:mma")}</span> 
                          </div>
                          <div className="d-sm-flex justify-content-between align-items-center m-2">
                            <div className="scheduled-wrap color_light_blue">
                              Session Rescheduled
                            </div>
                            <div className="right-wrap d-flex justify-content-between align-items-center">
                              <i className="ri-calendar-todo-line mx-1"></i>
                              <span className="status-tag border-0  bg_color_blue text-white mx-3 f-300 px-2"><small className="ri-check-line"></small> Accept</span>
                              <span className="color_blue"><small className="ri-history-line color_blue"></small> Reschedule</span>
                            </div>
                          </div>
                        </a>)}
                        else if(notification.notification_type=="session_invitation"){
                          return ( <a className="dropdown-item" href={redirect_url+"&session_event_id="+notification.session_event_id}>
                          <div className="d-sm-flex justify-content-between align-items-center m-2">
                            <div className="n-name f-500">
                            {notification.session_events} - {notification.stage_name} Stage
                            </div>
                            <div className="n-time light_text">
                            {moment.utc(notification.created_at).local().startOf('seconds').fromNow()}
                            </div>
                          </div>
                          <div className="date-time light_text m-2">
                            <i className="ri-time-line"></i> {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")} <span className="white-space-pre"> {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("hh:mma")} - {moment(parseInt(notification.end_date_tmestamp)*1000).utc().format("hh:mma")}</span> 
                          </div>
                          <div className="d-sm-flex justify-content-between align-items-center m-2">
                            <div className="scheduled-wrap color_blue">
                              Session Invitation
                            </div>
                            <div className="right-wrap d-flex justify-content-between align-items-center">
                              <i className="ri-calendar-todo-line color_blue mx-1"></i>
                              <span className="status-tag border-0  bg_color_blue f-300 text-white mx-md-3 mx-1 px-2"><small className="ri-check-line"></small>  Accept</span>
                              <span className="color_blue f-300"><small className="ri-history-line  color_blue"></small> Reschedule</span>
                            </div>
                          </div>
                        </a>)
                        }else if(notification.notification_type=="session_reminder"){
                          return(
                          <a className="dropdown-item" href={redirect_url+"&session_event_id="+notification.session_event_id}>
                          <div className="d-sm-flex justify-content-between align-items-center m-2">
                            <div className="n-name f-500">
                            {notification.session_events} - {notification.stage_name} Stage
                            </div>
                            <div className="n-time light_text">
                            {moment.utc(notification.created_at).local().startOf('seconds').fromNow()}
                            </div>
                          </div>
                          <div className="date-time light_text m-2">
                            <i className="ri-time-line"></i> {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")} <span className="white-space-pre">  {moment(parseInt(notification.start_date_tmestamp)*1000).utc().format("hh:mma")} - {moment(parseInt(notification.end_date_tmestamp)*1000).utc().format("hh:mma")}</span> 
                          </div>
                          <div className="d-sm-flex justify-content-between align-items-center m-2">
                            <div className="scheduled-wrap color_light">
                              Session starts in 10 minutes
                            </div>
                            <div className="right-wrap d-flex justify-content-between align-items-center">
                              <span className="status-tag border-0  bg_color_blue text-white f-300 px-2"><small className="ri-video-chat-line"></small> Join Session</span>
                              {/* <span className="color_blue"><small className="ri-history-line color_blue"></small> Reschedule</span> */}
                            </div>
                          </div>
                        </a>)
                        }
                      }
                    })):null}  


                    {/* <a className="dropdown-item" href="javascript:;">
                      <div className="d-sm-flex justify-content-between align-items-center m-2">
                        <div className="n-name f-500">
                          Coaching for Opportunity D57 - Validate Stage
                        </div>
                        <div className="n-time light_text">
                          5 min ago
                        </div>
                      </div>
                      <div className="date-time light_text m-2">
                        <i className="ri-time-line"></i> Thursday, 1 December 2021 <span className="white-space-pre">1:00pm - 2:00pm</span> 
                      </div>
                      <div className="d-sm-flex justify-content-between align-items-center m-2">
                        <div className="scheduled-wrap color_blue">
                          Session Invitation
                        </div>
                        <div className="right-wrap d-flex justify-content-between align-items-center">
                          <i className="ri-calendar-todo-line color_blue mx-1"></i>
                          <span className="status-tag border-0  bg_color_blue f-300 text-white mx-md-3 mx-1 px-2"><small className="ri-check-line"></small>  Accept</span>
                          <span className="color_blue f-300"><small className="ri-history-line  color_blue"></small> Reschedule</span>
                        </div>
                      </div>
                    </a> */}
                    {/* <a className="dropdown-item" href="javascript:;">
                      <div className="d-sm-flex justify-content-between align-items-center m-2">
                        <div className="n-name f-500">
                        Coaching for Opportunity L62 - Prospect Stage
                        </div>
                        <div className="n-time light_text">
                          10 min ago
                        </div>
                      </div>
                      <div className="date-time light_text m-2">
                        <i className="ri-time-line"></i> Thursday, 1 December 2021 <span className="white-space-pre">1:00pm - 2:00pm</span> 
                      </div>
                      <div className="d-sm-flex justify-content-between align-items-center m-2">
                        <div className="scheduled-wrap color_light_blue">
                          Session Rescheduled
                        </div>
                        <div className="right-wrap d-flex justify-content-between align-items-center">
                          <i className="ri-calendar-todo-line mx-1"></i>
                          <span className="status-tag border-0  bg_color_blue text-white mx-3 f-300 px-2"><small className="ri-check-line"></small> Accept</span>
                          <span className="color_blue"><small className="ri-history-line color_blue"></small> Reschedule</span>
                        </div>
                      </div>
                    </a> */}
                    {/* <a className="dropdown-item" href="javascript:;">
                      <div className="d-sm-flex justify-content-between align-items-center m-2">
                        <div className="n-name f-500">
                          Coaching for Opportunity F37 - Develop Stage
                        </div>
                        <div className="n-time light_text">
                          13 min ago
                        </div>
                      </div>
                      <div className="date-time light_text m-2">
                        <i className="ri-time-line"></i> Thursday, 1 December 2021 <span className="white-space-pre">1:00pm - 2:00pm</span> 
                      </div>
                      <div className="d-sm-flex justify-content-between align-items-center m-2">
                        <div className="scheduled-wrap color_light">
                          Session starts in 10 minutes
                        </div>
                        <div className="right-wrap d-flex justify-content-between align-items-center">
                          <span className="status-tag border-0  bg_color_blue text-white f-300 px-2"><small className="ri-video-chat-line"></small> Join Session</span>
                        </div>
                      </div>
                    </a> */}
                    {/* <a className="dropdown-item" href="javascript:;">
                      <div className="d-sm-flex justify-content-between align-items-center m-2">
                        <div className="n-name f-500">
                          Coaching for Opportunity D57 - Validate Stage
                        </div>
                        <div className="n-time light_text">
                          5 min ago
                        </div>
                      </div>
                      <div className="date-time light_text m-2">
                        <i className="ri-time-line"></i> Thursday, 1 December 2021 <span className="white-space-pre">1:00pm - 2:00pm</span> 
                      </div>
                      <div className="d-sm-flex justify-content-between align-items-center m-2">
                        <div className="scheduled-wrap color_blue">
                          Session Invitation
                        </div>
                        <div className="right-wrap d-flex justify-content-between align-items-center">
                          <i className="ri-calendar-todo-line color_blue mx-1"></i>
                          <span className="status-tag border-0  bg_color_blue f-300 text-white mx-md-3 mx-1 px-2"><small className="ri-check-line"></small>  Accept</span>
                          <span className="color_blue f-300"><small className="ri-history-line  color_blue"></small> Reschedule</span>
                        </div>
                      </div>
                    </a> */}
                    </div>

                    </div>
                </div>
            <div className="img-wrap">
            <Link to={`./myprofile`}>
                <img src={profileImg} alt=""/>
            </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>            
    </>
        )
    }
}
