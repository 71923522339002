import React from 'react';
import { Route, Redirect } from "react-router-dom";

const AdminGuardedRoute = ({ Main, Sidebar, Header, auth, roleid, sendotp, ...rest }) => (

    <Route {...rest} render={(props) => (
        (roleid.includes(parseInt(auth)) || (parseInt(auth) === parseInt(sendotp)))
        ? 
        (parseInt(auth) === parseInt(sendotp)) ? 
        (
        <>
            <Main {...props} />
        </>
        ) 
        : 
        (
        <>
            <Header {...props} />
            <Sidebar {...props} />
            <Main {...props} />
        </>
        )
            // <Component {...props} />
            : 
            // (console.log(roleid, auth))
            <Redirect to='/' />
    )} />
)

export default AdminGuardedRoute;