import React, { Component } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Modal from './modals/modal';
import {
    Link
} from "react-router-dom";
import prospect from "../../images/prospect-icon.png";
import quality from "../../images/quality-icon.png";
import axios from '../apiConnection/axios';
import Moment from 'react-moment';

export default class InactiveSalesStages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reactivateStageShow: false,
            reactivateTaskShow: false,
            salesProcess: "",
            rowData: {},
            rowTaskData: {},
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getsalesProcess();
    }

    getsalesProcess = async () => {
        await axios.get(`sales-process/get-inactive-stages-and-tasks`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    this.setState({ salesProcess: res.data.data });
                    console.log(this.state.salesProcess);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    setReactivateTaskShow = (newValue, salesTaskData) => {
        console.log(salesTaskData)

        if (newValue === false) {
            this.setState({ reactivateTaskShow: newValue });
        }
        else {
            this.setState({ reactivateTaskShow: newValue, rowTaskData: salesTaskData });
            this.setState({ reactivateTaskShow: newValue });
        }
    }
    UpdateTaskStatus = async () => {
        if (this.state.rowTaskData.status == 1) {
            this.state.rowTaskData.status = "0"
        }
        else if (this.state.rowTaskData.status == 0) {
            this.state.rowTaskData.status = "1"
        }
        const updateTaskinfo = {
            type: "task",
            sales_process_id: this.state.rowTaskData.id,
            status: this.state.rowTaskData.status,
        }
        await axios.post(`sales-process/update-sales-process-status`, updateTaskinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        }).then(res => {
            console.log(res);
            if (res.data.code === 200) {
                this.getsalesProcess();
                this.setReactivateTaskShow(false)
            }
            else {
                this.setState({
                    Message: "Server Error.",
                    MessageType: 'danger',
                    fileextractDone: 0,
                });
            }
        })
            .catch((error) => {
                console.log(error)
            })

    }

    setReactivateStageShow = (newValue, salesProcesssData) => {
        this.setState({ reactivateStageShow: newValue, salesProcesssData });
        if (newValue === false) {
            this.setState({ reactivateStageShow: newValue });
        }
        else {
            this.setState({ reactivateStageShow: newValue, rowData: salesProcesssData });
            this.setState({ reactivateStageShow: newValue });
        }
    }


    UpdateStageStatus = async () => {
        if (this.state.rowData.status == 1) {
            this.state.rowData.status = "0"
        }
        else if (this.state.rowData.status == 0) {
            this.state.rowData.status = "1"
        }
        const updateStageinfo = {
            type: "stage",
            sales_process_id: this.state.rowData.stage_id,
            status: this.state.rowData.status,
        }
        await axios.post(`sales-process/update-sales-process-status`, updateStageinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        }).then(res => {
            console.log(res);
            if (res.data.code === 200) {

                this.getsalesProcess();

                this.setReactivateStageShow(false)
            }
            else {
                this.setState({
                    Message: "Server Error.",
                    MessageType: 'danger',
                    fileextractDone: 0,
                });
            }
        })
            .catch((error) => {
                console.log(error)
            })

    }

    stageReactivate = () => {
        const { stage_name } = this.state.rowData

        return (<>
            <form className="my-4">
                <div className="text-center mx-3">
                    <p className="m-0"> Reactivating this stage will reactivate all the tasks.<br />
                        <span className="d-block mb-2"></span>
                        Are you sure you want to reactivate the stage <span className="f-600"> {stage_name}
                        </span>?
                    </p>
                    <div className="modal-footer border-0 mb-2 justify-content-center">
                        <button type="button" className="btn dark-btn mx-2" onClick={() => this.UpdateStageStatus(false)}>Confirm</button>
                        <button type="button" className="btn light-btn mx-2" onClick={() => this.setReactivateStageShow(false)} >Cancel</button>
                    </div>
                </div>
            </form>

        </>);
    }






    taskReactivate = () => {
        return (<>
            <form className="my-4">
                <div className="text-center mx-3">
                    <p className="m-0">
                        Are you sure you want to reactivate the task?
                    </p>
                    <div className="modal-footer border-0 mt-4 justify-content-center">
                        <button type="button" className="btn dark-btn mx-2" onClick={() => this.UpdateTaskStatus(false)}>Confirm</button>
                        <button type="button" className="btn light-btn mx-2" onClick={() => this.setReactivateTaskShow(false)} >Cancel</button>
                    </div>
                </div>
            </form>
        </>);
    }



    render() {
        const { reactivateStageShow, reactivateTaskShow, salesProcess } = this.state


        return (<>

            <Modal
                title=""
                contentClasses="py-3 py-lg-5"
                size="md"
                onClose={() => this.setReactivateStageShow(false)}
                show={reactivateStageShow}>
                {this.stageReactivate()}
            </Modal>

            <Modal
                title=""
                contentClasses="py-3 py-lg-5"
                size="md"
                onClose={() => this.setReactivateTaskShow(false)}
                show={reactivateTaskShow}>
                {this.taskReactivate()}
            </Modal>
            <main className="main-contant">
                <div className="contents">
                    <h3 className="title-head mb-lg-4 mb-3 text-center">Inactive Stages and Tasks</h3>
                    <Link className="mt-0 mb-3 d-block go-back d-flex align-items-center" to={`./sales-process`}>
                        <i className="iconmoon icon-chevron-left"></i> <span className="d-none d-sm-inline-block"> Go Back </span></Link>
                    <div className="accordion" id="accordionTable">
                        {((salesProcess) && (salesProcess !== "")) ? (salesProcess.map((salesProcesss) => {
                            var icon = salesProcesss.stage_icon_url;
                            var showicon = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((icon !== "") && (icon !== null)) ? icon : 'default_sales_stage.png');
                            if (salesProcesss.show === "yes") {
                                return (
                                    <div className={`table-wrap ${(salesProcesss.status === "0") ? `deactivated` : ``}  p-3 p-xl-4 mb-4`}>
                                        <div className="table-row d-sm-flex justify-content-between align-items-center">
                                            <div className="d-sm-flex justify-content-between align-items-center">
                                                <div className="mr-3 sell-icon-wrap">
                                                    <img src={showicon} width="55" className="iconImg" alt={salesProcesss.stage_name} />
                                                </div>
                                                <div className="pl-sm-5 ml-sm-4">
                                                    <div className="org-name f2015 w-auto f-600">
                                                        {salesProcesss.stage_name}
                                                    </div>
                                                    <p className="f2015 mt-2 mb-0 word-break-all">{salesProcesss.stage_description}</p>
                                                    <small className="mb-0 d-block">
                                                        Tasks marked with  <span className="color_blue f2015">*</span> are required to be completed for each stage.
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="actions">
                                                {(salesProcesss.status === "0") ? (
                                                    <div className="dropdown">
                                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="iconmoon icon-ellipse-v"></i>
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton5">
                                                            <a className="dropdown-item" onClick={() => this.setReactivateStageShow(true, salesProcesss)}><i className="fa fa-history"></i>&nbsp;Reactivate Stage</a>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target={`#collapse-${salesProcesss.stage_id}`} aria-expanded="false" aria-controls={`collapse-${salesProcesss.stage_id}`}>
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div id={`collapse-${salesProcesss.stage_id}`} className="collapse" data-parent="#accordionTable">
                                            <div className="team-row my-3 px-3 py-2 px-xl-4">
                                                <div className="table-container pl-2">
                                                    <div className="table-responsives">
                                                        {((salesProcesss.tasks) && (salesProcesss.tasks.length > 0)) ? (
                                                            <table className="table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Task</th>
                                                                        <th>Start Date</th>
                                                                        <th>End Date</th>
                                                                        {(salesProcesss.status === "1") ? (<th></th>) : ``}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {salesProcesss.tasks.map((salesTasks) => {

                                                                        var newdate = new Date(salesTasks.start_date);
                                                                        var newenddate = new Date(salesTasks.updated_at);


                                                                        return (<tr>
                                                                            <td className="text-with-wrap">
                                                                                {(salesTasks.task_required === "1") ? (
                                                                                    <i className="fa fa-asterisk" aria-hidden="true"></i>
                                                                                ) : (``)}
                                                                                <span className="text d-inline-block">{salesTasks.task_title}</span></td>
                                                                            <td>
                                                                                <Moment format="MM/DD/YYYY">
                                                                                    {newdate}
                                                                                </Moment>
                                                                            </td>
                                                                            <td>
                                                                                <Moment format="MM/DD/YYYY">
                                                                                    {newenddate}
                                                                                </Moment>
                                                                            </td>
                                                                            {(salesProcesss.status === "1") ? (
                                                                                <td>
                                                                                    <div className="actions">
                                                                                        <div className="dropdown">
                                                                                            <button className="btn dropdown-toggle" type="button"
                                                                                                id="dropdownMenuButton3" data-toggle="dropdown"
                                                                                                aria-haspopup="true" aria-expanded="false">
                                                                                                <i className="iconmoon icon-ellipse-v"></i>
                                                                                            </button>
                                                                                            <div className="dropdown-menu"
                                                                                                aria-labelledby="dropdownMenuButton3">
                                                                                                <a className="dropdown-item" onClick={() => this.setReactivateTaskShow(true, salesTasks)}><i className="fa fa-history"></i>
                                                                                                    &nbsp; Reactivate Task</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            ) : ``}
                                                                        </tr>)
                                                                    }
                                                                    )}

                                                                </tbody>
                                                            </table>

                                                        ) : (
                                                            <center>No tasks in stage.</center>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                        ) : (
                            <center>No Inactive Stages and Tasks Available</center>
                        )
                        }


                    </div>
                </div>
            </main>


        </>
        )
    }
}
