import React from "react";
export default class Utility extends React.Component {
  constructor(props) {
    super(props);
  }

  loaderView(action="") {
    if(action == "show"){console.log("loader show");
        document.getElementById("customeloader").setAttribute("style","visibility:visible;pointer-events:initial; ");
    }

    if(action == "hide"){console.log(" loader hide");
      document.getElementById("customeloader").setAttribute("style","visibility:hidden;pointer-events:none; ");
    }
  }
}