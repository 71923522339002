import React, { Component } from 'react'
import profile1 from "../../images/profile1.png";
import profile3 from "../../images/profile3.png";
import profile2 from "../../images/profile2.png"
// import profileImg from `${process.env.REACT_APP_STATIC_FILES_PATH}/default-avatar.png`;
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faEllipsisV, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import {
  Link
} from "react-router-dom";
import Modal from "./modals/modal";
import axios from '../apiConnection/axios';
import CustomSelect from "./elements/customSelect";
import ImageEditor from '../imageCropper/imageEditor';
import $ from "jquery";
import { Last } from 'react-bootstrap/esm/PageItem';
import Utility from "../utility";
const utilityObj = new Utility;


export default class LandingOrganizationStructure extends Component {

  constructor(props) {
    super(props);

    var current_year  = new Date().getFullYear();
    var starting_year = 2021;
    var year_list = [];
    for (var year_counter=0; year_counter<5;year_counter++){
      var year_obj = {};
      year_obj.id = year_counter+1;
      year_obj.name = starting_year+year_counter;
      year_obj.value = starting_year+year_counter;    
      year_list.push(year_obj);

    }
    this.state = {
      profileImg: process.env.REACT_APP_STATIC_FILES_PATH + '/' + 'default-avatar.png',
      profileShow: false,
      editprofileShow: false,
      resetLinkSendshow: false,
      resetLinkSendSuccessshow: false,
      deactivateUsershow: false,
      deactivateDivisionshow: false,
      deactivateTeamshow: false,
      orgHeadEditShow: false,
      addDivisionShow: false,
      editDivisionShow: false,
      addTeamShow: false,
      editTeamShow: false,
      orgTargetShow: false,
      divisionTargetShow: false,
      teamTargetShow: false,
      selectedUserName: "",
      selectedDivisionName: "",
      selectedTeamName: "",
      salesReps: [],
      salesDivisions: [],
      salesTeams: [],
      showList: false,
      organizationDetails: [],
      head_position: "",
      head_first_name: "",
      head_last_name: "",
      head_email: "",
      NewDivisionJson: [],
      MessageNewTeam: null,
      MessageNewTeamType: '',
      MessageNewDiv: [],
      MessageNewDivType: '',
      TeamParent: null,
      TargetsTitle: "",
      popuptitle: "",
      orgUserData: {},

      rowData: {},
      Revenue: null, Coachings: null,
      OrgTagetsID: null,
      TargetYear: current_year,
      //for add division form.
      add_division_name: "",
      add_division_parent: 0,
      add_division_user_id: null,
      user_detail: {},
      user_org_detail: {},
      edit_user_detail: {},
      salesRepUsersListSelect: [],
      NewTeamJson: { "users": { "users_list": [] } },
      TempArray: [],
      mainRepArray: [],
      editDivisionData: [],
      editTeamData: [], saveEnable: true,
      organization_head_mobile_no: "",
      defaultdivisionSelectText: "",
      stored_division_manager: "",
      stored_sales_manager: "",
      Organizations: [],
      showTeamManager: true,
      showTeamOption: true,
      showDivisionOption: true,
      divisionList: [],

      // divisionList: [
      //   { id: 1, name: "SUV Sales Division", value: "1" },
      //   { id: 2, name: "Sedan Sales Division", value: "2" },
      //   { id: 3, name: "Hatchback Sales Division", value: "3" },
      //   { id: 4, name: "Venity Sales Division", value: "4" },
      // ],
      defaultyearSelectText: current_year,
      yearList: year_list,
      defaultuserSelectTextf: "Select User",
      userList: [
        { id: 0, name: "Create New User", value: "0" },
        { id: 1, name: "Vince Simon", value: "1" },
        { id: 2, name: "Joseph Gates", value: "2" },
        { id: 3, name: "Clark Winson", value: "3" },
      ],
      DivisionUserNeworOld: "",
      EditDivisionUserNeworOld: "",
      NewTeamManagerNeworOld: "",
      NewTeamRepNeworOld: [],
      defaultteamSelectText: "Team A",
      teamList: [
        { id: 1, name: "Team A" },
        { id: 2, name: "Team B" },
        { id: 3, name: "Team C" },
        { id: 4, name: "Team D" },
      ],
      defaultroleSelectText: "Sales Manager",
      roleList: [
        { id: 1, name: "Sales Rep" },
        // { id: 2, name: "Sales Manager" },
      ],
      orgName: "",
      team_manager_first_name: "",
      team_manager_last_name: "",
      team_manager_email: "",
      team_manager_mobile: "",
      current_year:current_year,
    };
    this.changeAddDivisionUser = this.changeAddDivisionUser.bind(this);
    this.changeEditDivisionUserTyped = this.changeEditDivisionUserTyped.bind(this);
    this.changeAddTeamUserTyped = this.changeAddTeamUserTyped.bind(this);
    this.changeAddTeamManagerTyped = this.changeAddTeamManagerTyped.bind(this);
    this.changeEditTeamManagerTyped = this.changeEditTeamManagerTyped.bind(this);
    this.changeAddDivision = this.changeAddDivision.bind(this);
    this.changeAddTeam = this.changeAddTeam.bind(this);
    this.changeEditDivision = this.changeEditDivision.bind(this);
    this.changeEditTeam = this.changeEditTeam.bind(this);
    this.changeTargets = this.changeTargets.bind(this);
    this.focusTarget = this.focusTarget.bind(this);
    this.blureTarget = this.blureTarget.bind(this);
    this.keypressTargets = this.keypressTargets.bind(this);
    this.getAllDivisionsTeams = this.getAllDivisionsTeams.bind(this);
    this.maskNumbersInWord = this.maskNumbersInWord.bind(this);
    this.maskWordtoNumber = this.maskWordtoNumber.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);



  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getOrganizationData();
    this.getOrganizationDetails();
    this.getAllDivisionsTeams();
    this.getAllUsers();
  }

  getAllUsers = async () => {
    await axios.get(`/users/get-active-users`, {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      if (res.data.code === 200) {
        console.log(res.data.data);
        var userList = [
          { id: 0, name: "Create New User", value: "0" }
        ];
        var Users = res.data.data;

        var merged = userList.concat(Users);

        this.setState({ salesRepUsersListSelect: Users, userList: merged });
      }
      else {
        this.setState({
          Message: "Server Error.",
          MessageType: 'danger',
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })

  }
  getAllDivisionsTeams = async () => {
    await axios.get(`/organizations/get-all-divisions-teams`, {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      if (res.data.code === 200) {
        this.setState({ all_division_teams: res.data.data });
      }
      else {
        this.setState({
          Message: "Server Error.",
          MessageType: 'danger',
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })


  }

  // getUserDetails = async () => {
  //   const payload = {
  //     "auth_user_id":1000
  //   }
  //   await axios.post(`users/userslist`, payload, {
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json;charset=UTF-8',
  //     }
  //   })
  //     .then(res => {
  //       console.log(res)
  //       if (res.data.code === 200) {
  //         this.setState({
  //           userList: res.data.data,
  //         })
  //         console.log(this.state.userList)

  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })    
  // }


  getOrganizationData = async () => {
    await axios.get(`organizations/get-organization`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({ orgName: res.data.data[0].org_name })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  getOrganizationDetails = async () => {
    await axios.get(`organizations/get-organization-structure`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          if (res.data.data.length <= 0) {
            window.location.href = "./initiate-organization-structure";
          }
          this.setState({ "organization_structure": res.data.data });
          var organization_structure = res.data.data;
          if (organization_structure[0]) {
            let divisions = organization_structure[0].child;
            var divisions_list = [];
            for (var i = 0; i < divisions.length; i++) {
              var obj = {};
              obj.id = divisions[i].id;
              obj.name = divisions[i].node_name;
              obj.value = divisions[i].id;
              if (divisions[i].user.length > 0) {
                obj.user_name = divisions[i].user[0].first_name + " " + divisions[i].user[0].last_name;
              }
              if (i == 0) {
                // this.setState({ defaultdivisionSelectText: obj.name });
                this.setState({ defaultdivisionSelectText: "Select Division" });
                //  this.setState({ division_id: divisions[i].id });
                this.setState({ division_id: "" });
                var division_manager = "";
                if (divisions[i].user.length > 0 && divisions[i].user[0]) {
                  division_manager = divisions[i].user[0].first_name + " " + divisions[i].user[0].last_name;
                  // this.setState({ division_manager: division_manager });
                  this.setState({ division_manager: "" });
                  this.setState({ stored_division_manager: division_manager });

                }
                var team_data = divisions[i].child;
                var team_list = [];
                for (var k = 0; k < team_data.length; k++) {
                  var obj1 = {};
                  obj1.id = team_data[k].id
                  obj1.name = team_data[k].node_name;
                  obj1.value = team_data[k].id;
                  if (team_data[i].user.length > 0) {
                    obj1.user_name = team_data[i].user[0].first_name + " " + team_data[i].user[0].last_name;
                  }
                  if (k == 0) {
                    // this.setState({ defaultteamSelectText: obj1.name });
                    // this.setState({ team_id: obj1.id });
                    this.setState({ defaultteamSelectText: "Select Team" });
                    this.setState({ team_id: "" });
                    var sales_manager = "";
                    if (team_data[i].user.length > 0 && team_data[i].user[0]) {
                      sales_manager = team_data[i].user[0].first_name + " " + team_data[i].user[0].last_name;
                      this.setState({ sales_manager: "" });
                      // this.setState({ sales_manager: sales_manager });
                      this.setState({ stored_sales_manager: sales_manager });

                    }
                  }
                  team_list.push(obj1);
                }
                this.setState({ teamList: team_list });
                // this.setState({ teamList: [] });

              }
              divisions_list.push(obj);
            }
            this.setState({ divisionList: divisions_list });


            console.log("ORG ^ division list", this.state.divisionList);
          }
          console.log("get org structure api integration in react", this.state.organization_structure);

          this.setState({
            Organizations: res.data.data,
          })
          console.log(this.state.Organizations)

        }
        else {
          this.setState({
            Message: "Server Error.",
            MessageType: 'danger',
            fileextractDone: 0,
          });
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }


  handleDivisionClick = async (ele) => {
    var div_list = this.state.divisionList;
    this.setState({ division_id: ele });
    for (var j = 0; j < div_list.length; j++) {
      if (ele === div_list[j].id) {
        this.setState({ division_manager: div_list[j].user_name });
      }
    }


    var org_structure_data = this.state.organization_structure;
    for (var i = 0; i < org_structure_data.length; i++) {
      if (org_structure_data[0]) {
        let divisions = org_structure_data[0].child;

        for (var i = 0; i < divisions.length; i++) {
          if (divisions[i].id == ele) {
            var team_data = divisions[i].child;
            var team_list = [];
            console.log("@TEAM DATA", team_data);

            for (var k = 0; k < team_data.length; k++) {

              var obj1 = {};
              obj1.id = team_data[k].id
              obj1.name = team_data[k].node_name;
              obj1.value = team_data[k].id;
              if (team_data[k].user.length > 0) {
                obj1.user_name = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                if (k == 0) {
                  // this.setState({ defaultteamSelectText: obj1.name });
                  this.setState({ defaultteamSelectText: "Select Team" });
                  var sales_manager = "";
                  if (team_data[k].user[0]) {
                    sales_manager = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                    // this.setState({ sales_manager: sales_manager });
                    this.setState({ sales_manager: "" });
                  }
                }
              }
              team_list.push(obj1);
            }
            this.setState({ teamList: team_list });

          }

        }

      }
    }


  }
  handleTeamClick = (ele) => {
    this.setState({ team_id: ele });
    // this.setState({ sales_manager: "TEAM MANAGER " + ele });


    var org_structure_data = this.state.organization_structure;
    for (var i = 0; i < org_structure_data.length; i++) {
      if (org_structure_data[0]) {
        let divisions = org_structure_data[0].child;

        console.log("Division ADAT", divisions);
        for (var j = 0; j < divisions.length; j++) {
          var team_data = divisions[j].child;
          console.log("TEAM ADAT", team_data);
          for (var k = 0; k < team_data.length; k++) {
            console.log("team _data ele", team_data[k]); console.log("elee", ele);
            if (team_data[k].id === ele) {
              var sales_manager = "";
              if (team_data[k].user[0]) {
                sales_manager = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                //set sales manager 
                this.setState({ sales_manager: sales_manager });
              } else {
                this.setState({ sales_manager: "" });
              }
            }
          }
        }

      }
    }
  }




  appendSalesRep() {
    var newRep = `division-${this.state.salesReps.length}`;
    this.setState(prevState => ({ salesReps: prevState.salesReps.concat([newRep]) }));
  }
  removesalesReps = (deleteval) => {
    this.setState({
      salesReps: this.state.salesReps.filter(function (salesReps) {
        return salesReps !== deleteval
      })
    }, () => {
      this.removeEditTeamReps(parseInt(deleteval));
    });
  }

  appendDivision() {
    var newDivision = `division-${this.state.salesDivisions.length}`;
    this.setState(prevState => ({ salesDivisions: prevState.salesDivisions.concat([newDivision]) }));
  }


  removesalesDivision = (deleteval) => {
    this.setState({
      salesDivisions: this.state.salesDivisions.filter(function (salesDivisions) {
        return salesDivisions !== deleteval
      })
    });
  }

  appendTeam() {
    var newTeam = `team-${this.state.salesTeams.length}`;
    this.setState(prevState => ({ salesTeams: prevState.salesTeams.concat([newTeam]) }));
  }

  updateOrganization = async () => {
    this.setOrgHeadEditShow(false)
    const organizationInfo = {
      head_position: this.state.head_position,
      head_first_name: this.state.head_first_name,
      head_last_name: this.state.head_last_name,
      head_email: this.state.head_email,
      organization_head_mobile_no: this.state.organization_head_mobile_no,
    }
    console.log(organizationInfo);

    await axios.post(`update/organization`, organizationInfo, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState({
            Message: 'Success',
            MessageType: 'Success'
          });

        } else if (res.data.code === 201) {
          this.setState({
            Message: 'Error',
            MessageType: 'danger'
          });
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }
  changeFunction = (e) => {
    if (e.target.id === 'head_position') {
      this.setState({ head_position: e.target.value });
    } else if (e.target.id === 'head_first_name') {
      this.setState({ head_first_name: e.target.value });
    }
    else if (e.target.id === 'head_last_name') {
      this.setState({ head_last_name: e.target.value });
    }
    else if (e.target.id === 'head_email') {
      this.setState({ head_email: e.target.value });
    }
    else if (e.target.id === 'organization_head_mobile_no') {
      this.setState({ organization_head_mobile_no: e.target.value });
      this.formatPhoneNumber(e.target.value);
    }
  }


  setEditTeamShow = (newValue, organizationTeam) => {
    var existing_userid = ""
    var sel_user_id = 0;
    if (organizationTeam !== undefined) {
      this.setState({ NewTeamManagerNeworOld: "" });
      console.log("Edit Sales Manager: ", organizationTeam.user[0]);
      var defaultdivisionSelectText = organizationTeam.parent_name;
      var defaultmanagerSelectText = "Select User";
      var defaultmanagerEmail = "";
      var defaultmanagerMobile = "";
      existing_userid = "no_user";
      if (organizationTeam.user.length > 0) {
        if (organizationTeam.user[0].status != "0") {
          defaultmanagerSelectText = organizationTeam.user[0].first_name + ' ' + organizationTeam.user[0].last_name;
          defaultmanagerEmail = organizationTeam.user[0].email;
          defaultmanagerMobile = organizationTeam.user[0].mobile;
          existing_userid = organizationTeam.user[0].id;
          sel_user_id = organizationTeam.user[0].id;

        }
      }
      this.setState({
        editTeamData: organizationTeam,
        defaultdivisionSelectText: defaultdivisionSelectText,
        defaultmanagerSelectText: defaultmanagerSelectText,
        defaultmanagerEmail: defaultmanagerEmail,
        defaultmanagerMobile: defaultmanagerMobile,
      });
      var arr = [];
      var salesRepIDs = [];
      var salesRepName = [];
      var salesRepEmail = [];
      var salesRepMobile = [];
      var salesReps = [];
      var userList = this.state.userList;
      console.log(organizationTeam);
      console.log("TEAM DATA:", userList);
      console.log("TEAM DATA INPUT:", organizationTeam.users);
      for (var i = 0; i < organizationTeam.users.length; i++) {
        for (var j = 0; j < userList.length; j++) {


          if (organizationTeam.users[i].id === userList[j].value) {
            console.log("Matched TEAM DATA: USER LIST ID: ", userList[j]);
            salesRepName.push(userList[j].name);
            salesRepEmail.push(organizationTeam.users[i].email);
            salesRepMobile.push(organizationTeam.users[i].mobile);
            salesRepIDs.push(userList[j].value);
            // if (salesRepName.length>0){
            //   var newRep = `division-${this.state.salesReps.length}`;
            //   this.setState(prevState => ({ salesReps: prevState.salesReps.concat([newRep]) }));
            // }             
          }
          else {
            console.log("TEAM DATA: USER LIST ID: ", userList[j].value);
            console.log("TEAM DATA INPUT & TEAM USER ID: ", organizationTeam.users[i].id);
          }
        }
      }

      console.log("salesRepName", salesRepName);
      console.log("salesRepEmail", salesRepEmail);
      console.log("salesRepMobile", salesRepMobile);
      console.log("salesRepIDs", salesRepIDs);

      this.setState({
        salesRepName,
        salesRepEmail,
        salesRepMobile,
        salesRepIDs,
        salesReps: salesRepName,
        SalesRepJson: salesRepIDs,
      });
      console.log(salesRepEmail);
      console.log("SALES REPS IDS", salesRepIDs);
      // var existing_userid = "";
      // if(organizationTeam.user[0].id){
      //   existing_userid = organizationTeam.user[0].id;
      // }else{
      //   existing_userid = "no_user";
      // }
      this.setState(prevState => ({
        ...prevState,
        EditTeamJson: {
          ...prevState.EditTeamJson,
          0: {
            "org_structure_id": organizationTeam.id,
            "node_name": organizationTeam.node_name,
            "parent": organizationTeam.parent,
            "user_type": "old",
            "user": {
              "user_id": sel_user_id,
            },
            "existing_uid": existing_userid,
            "users": {
              "user_ids": salesRepIDs,
              "new_users": []
            },
          }
        }
      }), () => {
        this.setState({ TeamName: organizationTeam.node_name });
        if (organizationTeam.users !== undefined) {
          this.setState({ salesReps: organizationTeam.users });
          this.setState(prevState => ({
            ...prevState,
            editTeamData: {
              ...prevState.editTeamData,
              "users": salesRepIDs
            }
          }));
        } else {
          this.setState({ salesReps: [0] })
          this.setState(prevState => ({
            ...prevState,
            editTeamData: {
              ...prevState.editTeamData,
              "users": [0]
            }
          }));
        }

        console.log(this.state.EditTeamJson);
        this.setState({ editTeamShow: newValue });
        console.log(this.state.salesReps)

      })
    } else {
      this.setState({ editTeamShow: newValue, salesReps: [], salesDivisions: [] });
    }
    this.setState({
      MessageNewTeam: null,
      MessageNewTeamType: '',
    });

    console.log(organizationTeam);
  }




  removeEditTeamReps = (Uid) => {
    var uid = Uid;
    const { SalesRepJson } = this.state;
    if (SalesRepJson !== undefined) {
      var org_structure = [SalesRepJson];
      var obj = org_structure[0]; console.log(this.state.salesReps)

      const propertyValues = Object.values(obj);
      propertyValues.splice(uid, 1);
      this.setState(prevState => ({
        ...prevState,
        EditTeamJson: {
          ...prevState.EditTeamJson,
          0: {
            ...prevState.EditTeamJson[0],
            "users": propertyValues
          }
        }
      }), () => {
        console.log(this.state.EditTeamJson);
      })
    }
  }


  changeEditTeamReps = (ele, Uid) => {
    var uid = Uid;
    this.setState(prevState => ({
      ...prevState,
      SalesRepJson: {
        ...prevState.SalesRepJson,
        [uid]: ele,
      }
    }), () => {
      console.log(this.state.SalesRepJson);
      var org_structure = [this.state.SalesRepJson];
      var obj = org_structure[0];
      const propertyValues = Object.values(obj);
      console.log(propertyValues);
      const toFindDuplicates = propertyValues => propertyValues.filter((item, index) => propertyValues.indexOf(item) !== index)
      const duplicateElementa = toFindDuplicates(propertyValues);
      console.log(duplicateElementa);
      if (duplicateElementa.length === 0) {
        console.log('No Duplicates.')
        this.setState(prevState => ({
          ...prevState,
          EditTeamJson: {
            ...prevState.EditTeamJson,
            0: {
              ...prevState.EditTeamJson[0],
              "users": {
                ...prevState.EditTeamJson[0].users,
                "user_ids": propertyValues
              }
            }
          }
        }), () => {
          console.log(this.state.EditTeamJson);
          this.getUser(ele, Uid, 'editteamrep');
        })
      } else {
        console.log('Duplicates.')
        this.getUser(null, Uid, 'editteamrep');
      }
    })
  }
  changeEditDivisionForTeam = (ele, Uid) => {
    console.log(ele);
    console.log(Uid);


    this.setState(prevState => ({
      ...prevState,
      EditTeamJson: {
        ...prevState.EditTeamJson,
        0: {
          ...prevState.EditTeamJson[0],
          "parent": ele,

        }
      }
    }), () => {
      console.log(this.state.EditTeamJson);
    })
  }
  changeEditTeam(e) {
    var reg_name_lastname = /^[0-9a-zA-Z\s]*$/;

    var stored_value = e.target.value;
    this.setState({ TeamName: stored_value });
    //Validation to the input field
    // if (!reg_name_lastname.test(stored_value)) { //
    //   e.preventDefault();
    //   // this.setState({TeamName:""});
    //   stored_value.slice(0, - 1);
    //   return;
    // } else {
    //   this.setState({ TeamName: stored_value });
    // }



    if (e.target.value.trim().length > 30) {
      this.setState({
        MessageNewTeam: "Team name cannot exceed 30 characters",
        MessageNewTeamType: 'danger',
      });
      // return;
    }
    if (e.target.value.trim().length <= 30) {
      this.setState({
        MessageNewTeam: null,
        MessageNewTeamType: '',
      });
    }

    var data_division = parseInt(e.target.getAttribute('data-division_id'));
    this.setState(prevState => ({
      ...prevState,
      EditTeamJson: {
        ...prevState.EditTeamJson,
        0: {
          ...prevState.EditTeamJson[0],
          "org_structure_id": data_division,
          "node_name": stored_value,
        }
      }
    }), () => {
      console.log(this.state.EditTeamJson);
    })
  }

  editTeam = () => {
    const { NewTeamManagerNeworOld, salesRepName, salesRepEmail, salesRepMobile, MessageNewTeam, MessageNewTeamType, editTeamData, profileImg, defaultdivisionSelectText, defaultmanagerEmail, defaultmanagerMobile, defaultmanagerSelectText } = this.state;
    console.log("defaultmanagerEmail", this.state.defaultmanagerEmail);
    // var sales_rep_users_list =   this.state.userList.splice(index, 0);;

    var sales_manager_input = "disabled";
    if (NewTeamManagerNeworOld == "new") {
      sales_manager_input = "";
    }
    console.log("sales_manager_input", sales_manager_input);
    console.log("NewTeamManagerNeworOld", NewTeamManagerNeworOld);
    return (<>
      {((MessageNewTeam) && (MessageNewTeam !== null)) ?
        (
          <div className={`alert alert-${MessageNewTeamType}`}>
            {MessageNewTeam}
          </div>
        ) : (``)
      }
      <form>
        <div className="form-group">
          <label htmlFor="" className="mb-0">Sales Divison</label>
          <CustomSelect
            className="form-control f-600 text-left"
            defaultText={this.state.defaultdivisionSelectText}
            optionsList={this.state.divisionList}
            name="sales_division" dataUid={editTeamData.id}
            onSelect={this.changeEditDivisionForTeam}
          />
        </div>
        <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Team
          Details</span></div>
        <div className="form-group">
          <label htmlFor="" className="mb-0">Team Name <span className="mandatory color_red">*</span></label>
          <input type="text" value={this.state.TeamName} data-division_id={editTeamData.id} data-data_parent={editTeamData.parent} onChange={this.changeEditTeam} className="form-control f-600 f2015" defaultValue={editTeamData.node_name} placeholder="Team Name" />
        </div>
        <div className="clearfix">
          <div className="profile-pic float-sm-left">
            <div className="img-wrap">
              <img className="profile-pic-img" id={`editteammanager-pic-${editTeamData.id}`} src={profileImg} alt="profile" />
            </div>
            {/* <div className="p-image">
              <i className="fa fa-camera upload-button" id="editteambtn"></i>
              <input className="file-upload" id="editteamfile" type="file" accept="image/*" />
            </div> */}
          </div>
          <div className="profile-info float-sm-left">
            {/* <div className="form-group">
              <label htmlFor="" className="mb-0">Sales Manager Name</label>
              <CustomSelect
                className="form-control f-600 text-left"
                defaultText={this.state.defaultuserSelectText}
                optionsList={this.state.userList}
              />
            </div> */}
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <label htmlFor="" className="mb-0">Manager Name <span className="mandatory color_red">*</span></label>
                  {(editTeamData.user !== undefined) ? (
                    <CustomSelect
                      className="form-control f-600 text-left"
                      defaultText={this.state.defaultmanagerSelectText}
                      optionsList={this.state.userList}
                      // name="edit_sales_team" dataUid={editTeamData.id}
                      name="edit_sales_team" dataUid={0}
                      onSelect={this.changeEditTeamUser}
                    />
                  ) : (``)}
                </div>
              </div>
            </div>
            {sales_manager_input == "" ? <>
              <div class="row">
                <div class="col-md-6">
                  <div className="form-group">
                    <label className="mb-0">First Name <span className="mandatory color_red">*</span></label>
                    <input type="text" id={`addteam-manager-first_name`} className="form-control f-600 f2015"
                      placeholder="First Name" defaultValue="" data-Uid={0} onChange={this.changeEditTeamManagerTyped} name={`first_name`} />
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="form-group">
                    <label className="mb-0">Last Name <span className="mandatory color_red">*</span></label>
                    <input type="text" id={`addteam-manager-last_name`} className="form-control f-600 f2015"
                      placeholder="Last Name" defaultValue="" data-Uid={0} onChange={this.changeEditTeamManagerTyped} name={`last_name`} />
                  </div>
                </div>
              </div>



            </> : null}
            <div className="form-group">
              <label htmlFor="" className="mb-0">Sales Manager Email  {sales_manager_input == "" ? <span className="mandatory color_red">*</span> : null}</label>
              {(editTeamData.user !== undefined) ? (
                <input type="email" disabled={sales_manager_input} name="email" onChange={this.changeEditTeamManagerTyped} id={`editteammanager-email-0`} className="form-control f-600 f2015" defaultValue="" value={defaultmanagerEmail}
                  placeholder="Email" />
              ) : (``)}
            </div>
            <div className="form-group">
              <label htmlFor="" className="mb-0">Mobile Number  {sales_manager_input == "" ? <span className="mandatory color_red">*</span> : null}</label>
              {(editTeamData.user !== undefined) ? (
                <input type="number" disabled={sales_manager_input} name="mobile" onChange={this.changeEditTeamManagerTyped} id={`editteammanager-mobile-0`} className="form-control f-600 f2015" defaultValue="" value={defaultmanagerMobile}
                  placeholder="Mobile Number" />
              ) : (``)}
            </div>
          </div>
        </div>
        <div className="modal-footer border-0 justify-content-center">
          <button type="button" className="btn dark-btn mx-2" onClick={() => this.submitEditTeam()}>Save</button>
          <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setEditTeamShow(false)}>Cancel</button>
        </div>
      </form>
    </>);
  }

  submitEditTeam = async () => {

    console.log(this.state.EditTeamJson);
    const { EditTeamJson, NewTeamManagerNeworOld } = this.state;

    console.log("EDIT TEAM PAYLOAD:", EditTeamJson[0]);
    console.log("STATE:", this.state.defaultmanagerEmail);

    var message = "";
    var message_type = "";
    var sales_manager_first_name = "";
    var sales_manager_last_name = "";
    var sales_manager_email = "";
    var sales_manager_mobile = "";
    if (NewTeamManagerNeworOld == 'new') {
      if (EditTeamJson[0].user.user_id == 0) {
        if (EditTeamJson[0].user.first_name != undefined && EditTeamJson[0].user.first_name != "") {
          sales_manager_first_name = EditTeamJson[0].user.first_name;
        }

        if (EditTeamJson[0].user.last_name != undefined && EditTeamJson[0].user.last_name != "") {
          sales_manager_last_name = EditTeamJson[0].user.last_name;
        }

        if (EditTeamJson[0].user.email != undefined && EditTeamJson[0].user.email != "") {
          sales_manager_email = EditTeamJson[0].user.email;
        }

        if (EditTeamJson[0].user.mobile != undefined && EditTeamJson[0].user.mobile != "") {
          sales_manager_mobile = EditTeamJson[0].user.mobile;
        }
        var sales_team_name = "";
        if (EditTeamJson[0].node_name != undefined && EditTeamJson[0].node_name != "") {
          sales_team_name = EditTeamJson[0].node_name;
        }
        if (sales_team_name == "") {
          message = "Team Name is required";
          message_type = "danger";
        } else if (sales_manager_first_name == "") {
          message = "First Name is required";
          message_type = "danger";
        } else if (sales_manager_last_name == "") {
          message = "Last Name is required";
          message_type = "danger";
        } else if (sales_manager_email == "") {
          message = "Email is required";
          message_type = "danger";
        } else if (sales_manager_mobile == "") {
          message = "Mobile is required and should be a valid format.";
          message_type = "danger";
        }

        if (sales_manager_email != "") {
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(sales_manager_email)) {
            message = "Invalid Email";
            message_type = "danger";
          }
        }

        if (sales_manager_mobile != "") {
          if (!(/^[0-9]{11,14}$/g.test(sales_manager_mobile))) {
            message = "Phone number cannot be less than 11 digits and cannot exceed 14 digits.";
            message_type = "danger";
          }
        }
      }
      else {
        message = "Sales Manager is required";
        message_type = "danger";
      }


    } else {


      var sales_team_name = "";
      if (EditTeamJson[0].node_name != undefined && EditTeamJson[0].node_name != "") {
        sales_team_name = EditTeamJson[0].node_name;
      }
      if (sales_team_name == "") {
        message = "Team name is required";
        message_type = "danger";
      }
      else if (sales_team_name == "") {

        message = "Sales Manager is required";
        message_type = "danger";
      }
    }
    if (EditTeamJson[0].node_name.trim().length > 30) {
      message = "Team name can not exceed 30 characters";
      message_type = "danger";

    }

    if (message_type == "danger") {
      this.setState({ MessageNewTeam: message, MessageNewTeamType: message_type });
      console.log("Error Message", this.state.MessageNewDivision);
      console.log("Error Message Type", this.state.MessageNewDivisionType);
      return false;
    }

    await axios.post(`/organizations/edit-team`, EditTeamJson[0], {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState({
            MessageNewTeam: res.data.message,
            MessageNewTeamType: 'success'
          });
          setTimeout(
            () => {
              this.setEditTeamShow(false);
              this.componentDidMount();
            },
            3000);

          this.getUserDetails()
          this.getOrganizationDetails();
          this.getAllDivisionsTeams()
        }
        else {
          this.setState({
            MessageNewTeam: res.data.message,
            MessageNewTeamType: 'danger',
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  changeEditTeamUser = (ele, Uid) => {
    console.log("Change Element of Team", ele);
    console.log("Change Team Uid", Uid);

    if (Uid === 0) {
      if (ele == 0) {
        this.setState({ defaultmanagerEmail: "", defaultmanagerMobile: "" });
        this.setState(prevState => ({
          ...prevState,
          EditTeamJson: {
            ...prevState.EditTeamJson,
            [0]: {
              ...prevState.EditTeamJson[0],
              "user_type": "new",
              "user": {
                ...prevState.EditTeamJson[0].user,
                "user_id": ele
              },
            }
          }
        }), () => {

          this.setState({ NewTeamManagerNeworOld: "new", defaultmanagerEmail: "", defaultmanagerMobile: "" });
        })
      } else {
        this.setState(prevState => ({
          ...prevState,
          EditTeamJson: {
            ...prevState.EditTeamJson,
            [0]: {
              ...prevState.EditTeamJson[0],
              "user_type": "old",
              "user": {
                ...prevState.EditTeamJson[0].user,
                "user_id": ele
              },
            }
          }
        }), () => {

          this.setState({ NewTeamManagerNeworOld: "old" });
          this.getUser(ele, Uid, 'editteammanager');
        })
      }

    } else if (Uid > 0) {
      if (ele == 0) {
        var oldStatus = this.state.NewTeamRepNeworOld;
        if (!oldStatus.includes(Uid)) {
          oldStatus.push(Uid);
        }
        this.setState({ NewTeamRepNeworOld: oldStatus });
      } else {
        var oldStatus = this.state.NewTeamRepNeworOld;
        if (oldStatus.includes(Uid)) {
          const index = oldStatus.indexOf(Uid);
          if (index > -1) {
            oldStatus.splice(index, 1);
          }
        }
        console.log("old status", oldStatus);
        console.log("old status Uid", Uid);
        this.setState({ NewTeamRepNeworOld: oldStatus });
      }
      var users = this.state.TempArray;
      if (users === undefined) {
        users = [];
      } else {
        users = this.state.TempArray;
      }
      if (!users.includes(ele)) {
        users[Uid] = ele;
      }
      var newArray = [];
      for (const [key, value] of Object.entries(users)) {
        if ((key === Uid) && (users[key] !== users[Uid])) {
          users[Uid] = ele;
          newArray.push(ele);
        } else {
          newArray.push(value);

        }
      }
      this.setState(prevState => ({
        ...prevState,
        TempArray: users
      }))
      this.setState(prevState => ({
        ...prevState,
        EditTeamJson: {
          ...prevState.EditTeamJson,
          [0]: {
            ...prevState.EditTeamJson[0],
            "users": {
              "user_ids": newArray
            }
          }
        }
      }), () => {
        this.getUser(ele, Uid, 'addteam-rep');
        console.log(this.state.EditTeamJson);
      })
    }


  }

  changeUserTeam = (ele, Uid) => {
    console.log("Change Element of Team", ele);
    console.log("Change Team Uid", Uid);
    if (Uid === 0) {
      if (ele == 0) {
        // NewTeamManagerNeworOld : "",
        // NewTeamRepNeworOld:[],
        this.setState(prevState => ({
          ...prevState,
          NewTeamJson: {
            ...prevState.NewTeamJson,
            [0]: {
              ...prevState.NewTeamJson[0],
              "user_type": "new",
              "user": {
                "user_id": ele
              },
            }
          }
        }), () => {

          this.setState({ NewTeamManagerNeworOld: "new" })
        })
        this.setState({})
      } else {
        this.setState(prevState => ({
          ...prevState,
          NewTeamJson: {
            ...prevState.NewTeamJson,
            [0]: {
              ...prevState.NewTeamJson[0],
              "user_type": "old",
              "user": {
                "user_id": ele
              }
            }
          }
        }), () => {
          this.getUser(ele, 0, 'addteam-manager');
          this.setState({ NewTeamManagerNeworOld: "old" })
        })
        console.log("SET STATE", this.state);
      }
    } else if (Uid > 0) {
      if (ele == 0) {
        var oldStatus = this.state.NewTeamRepNeworOld;
        if (!oldStatus.includes(Uid)) {
          oldStatus.push(Uid);
        }
        this.setState({ NewTeamRepNeworOld: oldStatus });
      } else {
        var oldStatus = this.state.NewTeamRepNeworOld;
        if (oldStatus.includes(Uid)) {
          const index = oldStatus.indexOf(Uid);
          if (index > -1) {
            oldStatus.splice(index, 1);
          }
        }
        console.log("old status", oldStatus);
        console.log("old status Uid", Uid);
        this.setState({ NewTeamRepNeworOld: oldStatus });
      }
      var users = this.state.TempArray;
      if (users === undefined) {
        users = [];
      } else {
        users = this.state.TempArray;
      }
      if (!users.includes(ele)) {
        users[Uid] = ele;
      }
      var newArray = [];
      for (const [key, value] of Object.entries(users)) {
        if ((key === Uid) && (users[key] !== users[Uid])) {
          users[Uid] = ele;
          newArray.push(ele);
        } else {
          newArray.push(value);

        }
      }
      this.setState(prevState => ({
        ...prevState,
        TempArray: users
      }))
      this.setState(prevState => ({
        ...prevState,
        NewTeamJson: {
          ...prevState.NewTeamJson,
          [0]: {
            ...prevState.NewTeamJson[0],
            "users": {
              "user_ids": newArray
            }
          }
        }
      }), () => {
        this.getUser(ele, Uid, 'addteam-rep');
        console.log(this.state.NewTeamJson);
      })
    }

  }
  changeAddTeam(e) {

    var reg_name_lastname = /^[0-9a-zA-Z\s]*$/;

    var stored_value = e.target.value;
    this.setState({ TeamName: stored_value });

    console.log("TeamName", e.target.value);

    if (e.target.value.trim().length > 30) {
      this.setState({
        MessageNewTeam: "Team name cannot exceed 30 characters.",
        MessageNewTeamType: 'danger',
      });
    }
    if (e.target.value.trim().length <= 30) {
      this.setState({
        MessageNewTeam: null,
        MessageNewTeamType: '',
      });
    }
    var data_division = parseInt(e.target.getAttribute('data-division_id'));
    var data_parent = parseInt(e.target.getAttribute('data-parent_id'));
    this.setState(prevState => ({
      ...prevState,
      NewTeamJson: {
        ...prevState.NewTeamJson,
        [data_division]: {
          ...prevState.NewTeamJson[data_division],
          // "node_name": this.state.TeamName,
          "node_name": e.target.value,
          "parent": data_parent,
          "user_type": this.state.NewTeamManagerNeworOld
        }
      }
    }), () => {
      console.log("ADD TEAM PAYLOAD", this.state.NewTeamJson);
    })
    //   {
    //     "node_name" : "Division 9",
    //     "parent" : "1",
    //     "user" : {
    //         "user_id" : "30"
    //     },
    //     "users" : {
    //             "user_id" : ["4","5","6","7"] 
    //     }
    // }        
  }
  setAddTeamShow = (newValue, parent_id) => {
    this.setState({ addTeamShow: newValue, TeamParent: parent_id, salesReps: [], salesDivisions: [], sales_manager_input: "disabled", TeamName: "", team_manager_first_name: "", team_manager_last_name: "", team_manager_email: "", team_manager_mobile: "", NewTeamManagerNeworOld: "old" });


    this.setState(prevState => ({
      ...prevState,
      NewTeamJson: {
        ...prevState.NewTeamJson,
        [0]: {
          ...prevState.NewTeamJson[0],
          "node_name": "",
          "parent": parent_id,
          "user_type": "",
          "user": {
            "user_id": ""
          },
          "users": {
            "user_ids": [],
            "new_users": []
          }
        }
      }
    }), () => {
      console.log(this.state.NewTeamJson);
    })
    this.setState({
      MessageNewTeam: null,
      MessageNewTeamType: '',
    });
  }

  addTeam = () => {
    const { MessageNewTeam, MessageNewTeamType, TeamParent, profileImg, NewTeamManagerNeworOld, NewTeamRepNeworOld, mainRepArray, team_manager_first_name, team_manager_last_name, team_manager_email, team_manager_mobile } = this.state;
    var sales_manager_input = "disabled";
    if (NewTeamManagerNeworOld == "new") {
      sales_manager_input = "";
    }

    return (<>
      {((MessageNewTeam) && (MessageNewTeam !== null)) ?
        (
          <div className={`alert alert-${MessageNewTeamType}`}>
            {MessageNewTeam}
          </div>
        ) : (``)
      }
      <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Team
        Details</span></div>
      <form>
        <div className="form-group">
          <label htmlFor="" className="mb-0">Team Name <span className="mandatory color_red">*</span></label>
          <input type="text" id="TeamName" value={this.state.TeamName} data-division_id={0} data-parent_id={TeamParent} className="form-control f-600 f2015" defaultValue="" placeholder="Team Name" onChange={this.changeAddTeam} />
        </div>
        <div className="clearfix">
          <div className="profile-pic float-sm-left">
            <div className="img-wrap">
              <img className="profile-pic-img" id={`addteam-manager-pic-0`} src={profileImg} alt="profile" />
            </div>
          </div>
          <div className="profile-info float-sm-left">
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <label htmlFor="" className="mb-0">Manager Name <span className="mandatory color_red">*</span></label>
                  <CustomSelect
                    className="form-control f-600 text-left"
                    defaultText={this.state.defaultuserSelectTextf}
                    optionsList={this.state.userList}
                    name="user_id" dataUid={0}
                    onSelect={this.changeUserTeam}
                  />
                </div>
              </div>
            </div>
            {sales_manager_input == "" ? <>
              <div class="row">
                <div class="col-md-6">
                  <div className="form-group">
                    <label className="mb-0">First Name <span className="mandatory color_red">*</span></label>
                    <input type="text" id={`addteam-manager-first_name`} className="form-control f-600 f2015"
                      placeholder="First Name" defaultValue="" value={team_manager_first_name} data-Uid={0} onChange={this.changeAddTeamManagerTyped} name={`first_name`} />
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="form-group">
                    <label className="mb-0">Last Name <span className="mandatory color_red">*</span></label>
                    <input type="text" id={`addteam-manager-last_name`} className="form-control f-600 f2015"
                      placeholder="Last Name" defaultValue="" value={team_manager_last_name} data-Uid={0} onChange={this.changeAddTeamManagerTyped} name={`last_name`} />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="" className="mb-0">Sales Manager Email {sales_manager_input == "" ? <span className="mandatory color_red">*</span> : null}</label>
                <input type="email" id={`addteam-manager-email`} name={`email`} onChange={this.changeAddTeamManagerTyped} disabled={sales_manager_input} className="form-control f-600 f2015" defaultValue="" value={team_manager_email}
                  placeholder="Email" />
              </div>
              <div className="form-group">
                <label htmlFor="" className="mb-0">Mobile Number {sales_manager_input == "" ? <span className="mandatory color_red">*</span> : null}</label>
                <input type="number" id={`addteam-manager-mobile`} name={`mobile`} onChange={this.changeAddTeamManagerTyped} disabled={sales_manager_input} className="form-control f-600 f2015" defaultValue="" value={team_manager_mobile}
                  placeholder="Mobile Number" />
              </div>

            </> : <>  <div className="form-group">
              <label htmlFor="" className="mb-0">Sales Manager Email {sales_manager_input == "" ? <span className="mandatory color_red">*</span> : null}</label>
              <input type="email" id={`addteam-manager-email`} name={`email`} onChange={this.changeAddTeamManagerTyped} disabled={sales_manager_input} className="form-control f-600 f2015" defaultValue=""
                placeholder="Email" />
            </div>
              <div className="form-group">
                <label htmlFor="" className="mb-0">Mobile Number {sales_manager_input == "" ? <span className="mandatory color_red">*</span> : null}</label>
                <input type="number" id={`addteam-manager-mobile`} name={`mobile`} onChange={this.changeAddTeamManagerTyped} disabled={sales_manager_input} className="form-control f-600 f2015" defaultValue=""
                  placeholder="Mobile Number" />
              </div></>}

          </div>
        </div>
        <div className="clearfix mt-2">
        </div>
        {this.state.salesReps.map((input, index) =>
        (<>
          <div className="clearfix mt-2" key={index}>
            <div className="profile-pic float-sm-left">
              <div className="img-wrap">
                <img className="profile-pic-img" id={`addteam-rep-pic-${index + 2}`} src={profileImg} alt="profile" />
              </div>
            </div>
            <div className="profile-info float-sm-left">
              <div className="form-group">
                <small className="text-right bg-white d-block pl-3 link color_red" onClick={() => this.removesalesReps(input)}> - Clear</small>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="mb-0">Representative Name</label>
                    <CustomSelect
                      className="form-control f-600 text-left"
                      defaultText={this.state.defaultuserSelectTextf}
                      optionsList={this.state.salesRepUsersListSelect}
                      name="user_id" dataUid={index + 2}
                      onSelect={this.changeUserTeam}
                    />
                  </div>
                </div>
              </div>
              {(NewTeamRepNeworOld.includes(index + 2)) ? (
                <>
                  <div className="row" id="new_rep_team[]">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-0">First Name</label>
                        <input type="text" id={`addteam-rep-first_name-${index + 2}`} className="form-control f-600 f2015"
                          placeholder="First Name" defaultValue={this.state.rep_first_name ? this.state.rep_first_name[index + 2] : ""} data-Uid={index + 2} onChange={this.changeAddTeamUserTyped} name={`First Name`} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-0">Last Name</label>
                        <input type="text" id={`addteam-rep-last_name-${index + 2}`} className="form-control f-600 f2015"
                          placeholder="Last Name" defaultValue={this.state.rep_first_name ? this.state.rep_first_name[index + 2] : ""} data-Uid={index + 2} onChange={this.changeAddTeamUserTyped} name={`Last Name`} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="" className="mb-0">Email</label>
                        <input type="email" id={`addteam-rep-email-${index + 2}`} className="form-control f-600 f2015"
                          defaultValue={this.state.rep_email ? this.state.rep_email[index + 2] : ""} data-Uid={index + 2} onChange={this.changeAddTeamUserTyped} placeholder="Email" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="" className="mb-0">Mobile Number</label>
                        <input type="number" id={`addteam-rep-mobile-${index + 2}`} className="form-control f-600 f2015"
                          defaultValue={this.state.rep_mobile ? this.state.rep_mobile[index + 2] : ""} data-Uid={index + 2} onChange={this.changeAddTeamUserTyped} placeholder="Mobile Number" />
                      </div>
                    </div>
                  </div>

                </>
              ) : (
                <>
                  <div className="form-group">
                    <label htmlFor="" className="mb-0">Sales Representative Email</label>
                    <input type="email" disabled id={`addteam-rep-email-${index + 2}`} className="form-control f-600 f2015" defaultValue="" placeholder="Email" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="" className="mb-0">Mobile Number</label>
                    <input type="number" disabled id={`addteam-rep-mobile-${index + 2}`} className="form-control f-600 f2015" defaultValue=""
                      placeholder="Mobile Number" />
                  </div>

                </>
              )}
            </div>
          </div>

        </>))}

      </form>
      <div className="modal-footer border-0 justify-content-center">
        <button type="button" className="btn dark-btn mx-2" onClick={this.submitAddTeam}>Save</button>
        <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setAddTeamShow(false, TeamParent)}>Cancel</button>
      </div>

    </>);
  }

  submitAddTeam = async () => {
    const { NewTeamJson, NewTeamManagerNeworOld } = this.state;
    // this.NewTeamJson[0].user_type = NewTeamManagerNeworOld;
    // console.log();

    var message = "";
    var message_type = "";
    var sales_manager_first_name = "";
    var sales_manager_last_name = "";
    var sales_manager_email = "";
    var sales_manager_mobile = "";
    if (NewTeamManagerNeworOld == 'new') {
      console.log("USER LIST LENGTH:", NewTeamJson[0].user.user_id.length);
      if (NewTeamJson[0].user.user_id == 0) {
        if (NewTeamJson[0].user.first_name != undefined && NewTeamJson[0].user.first_name != "") {
          sales_manager_first_name = NewTeamJson[0].user.first_name;
        }

        if (NewTeamJson[0].user.last_name != undefined && NewTeamJson[0].user.last_name != "") {
          sales_manager_last_name = NewTeamJson[0].user.last_name;
        }

        if (NewTeamJson[0].user.email != undefined && NewTeamJson[0].user.email != "") {
          sales_manager_email = NewTeamJson[0].user.email;
        }

        if (NewTeamJson[0].user.mobile != undefined && NewTeamJson[0].user.mobile != "") {
          sales_manager_mobile = NewTeamJson[0].user.mobile;
        }
        var sales_team_name = "";
        if (NewTeamJson[0].node_name != undefined && NewTeamJson[0].node_name != "") {
          sales_team_name = NewTeamJson[0].node_name;
        }
        if (sales_team_name == "") {
          message = "Team Name is required";
          message_type = "danger";
        } else if (sales_manager_first_name == "") {
          message = "First Name is required";
          message_type = "danger";
        } else if (sales_manager_last_name == "") {
          message = "Last Name is required";
          message_type = "danger";
        } else if (sales_manager_email == "") {
          message = "Email is required";
          message_type = "danger";
        } else if (sales_manager_mobile == "") {
          message = "Mobile is required and should be a valid format.";
          message_type = "danger";
        }

        if (sales_manager_email != "") {
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(sales_manager_email)) {
            message = "Invalid Email";
            message_type = "danger";
          }
        }

        if (sales_manager_mobile != "") {
          if (!(/^[0-9]{11,14}$/g.test(sales_manager_mobile))) {
            message = "Phone number cannot be less than 11 digits and cannot exceed 14 digits.";
            message_type = "danger";
          }
        }
      }
      else {
        message = "Sales Manager is required";
        message_type = "danger";
      }


    } else {



      var sales_team_name = "";
      if (NewTeamJson[0].node_name != undefined && NewTeamJson[0].node_name != "") {
        sales_team_name = NewTeamJson[0].node_name;
      }
      if (sales_team_name == "") {
        message = "Team name is required";
        message_type = "danger";
      }
      // console.log("NEW TEAM JSON",NewTeamJson[0].user.user_id);
      else if (sales_team_name == "") {

        message = "Sales Manager is required";
        message_type = "danger";
      }
      if (NewTeamJson[0].user.user_id != "" && NewTeamJson[0].user.user_id != 0 && NewTeamJson[0].users.user_ids.length > 0) {

        var selected_users = NewTeamJson[0].users.user_ids;
        if (selected_users.indexOf(NewTeamJson[0].user.user_id) != -1) {

          message = "Same user can not be selected.";
          message_type = "danger";
        }
      }


    }
    if (NewTeamJson[0].node_name.trim().length > 30) {
      message = "Team name can not exceed 30 characters";
      message_type = "danger";
    }
    this.setState({ MessageNewTeam: message, MessageNewTeamType: message_type });
    if (message_type == "danger") {
      console.log("Error Message", this.state.MessageNewDivision);
      console.log("Error Message Type", this.state.MessageNewDivisionType);
      return false;
    }


    await axios.post(`/organizations/create-team`, NewTeamJson[0], {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState({
            MessageNewTeam: res.data.message,
            MessageNewTeamType: 'success',
          });
          setTimeout(
            () => {
              this.setAddTeamShow(false);
              this.componentDidMount();

            },
            3000);
          this.getUserDetails()
          this.getOrganizationDetails();

        }
        else {
          this.setState({
            MessageNewTeam: res.data.message,
            MessageNewTeamType: 'danger',
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  setEditDivisionShow = (newValue, organizationDivision, popuptitle) => {
    if (organizationDivision !== undefined) {
      this.setState({
        editDivisionShow: newValue,
        editDivisionData: organizationDivision,
        popuptitle,
        EditDivisionUserNeworOld: 'old',
        manager_first_name: "",
        manager_last_name: "",
        manager_email: "",
        manager_mobile: "",
      });

      var DivisionName = organizationDivision.node_name;
      if (organizationDivision.user[0] !== undefined) {
        var div_username = "Select User";
        var div_useremail = "";
        var div_usermobile = "";
        if (organizationDivision.user[0].status != "0") {
          div_username = organizationDivision.user[0].first_name + ' ' + organizationDivision.user[0].last_name;
          div_useremail = organizationDivision.user[0].email;
          div_usermobile = organizationDivision.user[0].mobile;
        }
        console.log("Edit Division User", organizationDivision.user[0]);
        this.setState({
          defaultuserSelectText: div_username,
          defaultuserEmail: div_useremail,
          defaultuserMobile: div_usermobile,
          DivisionName: DivisionName
        });
        this.setState(prevState => ({
          ...prevState,
          EditDivisionJson: {
            ...prevState.EditDivisionJson,
            0: {
              "org_structure_id": organizationDivision.id,
              "node_name": DivisionName,
              "parent": organizationDivision.parent,
              "user_type": "old",
              "user": {
                "user_id": organizationDivision.user[0].id,
              },
              "existing_uid": organizationDivision.user[0].id,
            }
          }
        }), () => {
          this.setState({ DivisionName: organizationDivision.node_name });

          console.log(this.state.EditDivisionJson);
        })
      } else {
        this.setState({
          defaultuserSelectText: "Select User",
          defaultuserEmail: "",
          defaultuserMobile: "",
        });
        this.setState(prevState => ({
          ...prevState,
          EditDivisionJson: {
            ...prevState.EditDivisionJson,
            0: {
              "org_structure_id": organizationDivision.id,
              "node_name": organizationDivision.node_name,
              "parent": organizationDivision.parent,
              "user_type": "old",
              "user": {
                "user_id": "",
              },
              "existing_uid": "no_user",
            }
          }
        }), () => {
          this.setState({ DivisionName: organizationDivision.node_name });

          console.log(this.state.EditDivisionJson);
        })
      }

    } else {
      this.setState({
        editDivisionShow: newValue,
      });
    }
    this.setState({
      MessageNewDivision: null,
      MessageNewDivisionType: '',
      salesReps: [], salesDivisions: []
    });

    console.log(organizationDivision);
  }
  changeEditDivisionUser = (ele, Uid) => {
    console.log(ele);
    this.setState(prevState => ({
      ...prevState,
      EditDivisionJson: {
        ...prevState.EditDivisionJson,
        0: {
          ...prevState.EditDivisionJson[0],
          "user": {
            ...prevState.EditDivisionJson[0].user,
            "user_id": ele,
          }
        }
      }
    }), () => {
      this.getUser(ele, Uid, 'editdivision');
      console.log(this.state.EditDivisionJson);
    })

  }
  changeEditDivision(e) {



    var reg_name_lastname = /^[0-9a-zA-Z\s]*$/;

    var stored_value = e.target.value;
    this.setState({ DivisionName: stored_value });
    var data_division = parseInt(e.target.getAttribute('data-division_id'));
    var data_parent = parseInt(e.target.getAttribute('data-parent_id'));

    this.setState(prevState => ({
      ...prevState,
      EditDivisionJson: {
        ...prevState.EditDivisionJson,
        [0]: {
          ...prevState.EditDivisionJson[0],
          "org_structure_id": data_division,
          "node_name": stored_value,
          "parent": data_parent
        }
      }
    }), () => {
      console.log(this.state.EditDivisionJson);
    })

    if (e.target.value.trim().length > 30) {
      this.setState({
        MessageNewDivision: "Division name can not exceed 30 characters",
        MessageNewDivisionType: 'danger',
      });
      return;
    }
    if (e.target.value.trim().length <= 30) {
      this.setState({
        MessageNewDivision: null,
        MessageNewDivisionType: '',
      });
    }


  }
  editDivision = () => {
    const { MessageNewDivision, MessageNewDivisionType, editDivisionData, profileImg, defaultuserEmail, defaultuserMobile, EditDivisionUserNeworOld } = this.state;
    console.log(editDivisionData.parent);
    return (<>
      {((MessageNewDivision) && (MessageNewDivision !== null)) ?
        (
          <div className={`alert alert-${MessageNewDivisionType}`}>
            {MessageNewDivision}
          </div>
        ) : (``)
      }

      <form>
        <div className="form-group">
          <label htmlFor="" className="mb-0">{editDivisionData.parent == "0" ? "Organization Head Position" : "Sales Division Name"} <span className="mandatory color_red">*</span></label>
          <input type="text" value={this.state.DivisionName} data-division_id={editDivisionData.id} data-parent_id={editDivisionData.parent} onChange={this.changeEditDivision} className="form-control f-600 f2015" defaultValue={editDivisionData.node_name}
            placeholder="Sales Division Name" />
        </div>
        <div className="clearfix">
          <div className="profile-pic float-sm-left">
            <div className="img-wrap">
              <img className="profile-pic-img" id={`editdivision-pic-0`} src={profileImg} alt="profile" />
            </div>
          </div>
          <div className="profile-info float-sm-left">
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <label htmlFor="" className="mb-0">Manager Name</label>
                  {(editDivisionData !== undefined) ? (
                    <CustomSelect
                      className="form-control f-600 text-left"
                      // defaultText="Select User"
                      defaultText={this.state.defaultuserSelectText}
                      optionsList={this.state.userList}
                      name="user_id" dataUid={0}
                      onSelect={this.changeEditUser}
                    />
                  ) : (``)}

                </div>
              </div>
            </div>

            {(EditDivisionUserNeworOld == "new") ? (
              <div className="row" id="edit_new_user_division">
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-0">First Name <span className="mandatory color_red">*</span></label>
                    <input type="text" id={`editdivision-first_name-0`} className="form-control f-600 f2015"
                      placeholder="Head First Name" value={this.state.manager_first_name} data-division_id={0} onChange={this.changeEditDivisionUserTyped} name={`First Name`} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-0">Last Name <span className="mandatory color_red">*</span></label>
                    <input type="text" id={`editdivision-last_name-0`} className="form-control f-600 f2015"
                      placeholder="Head Last Name" value={this.state.manager_last_name} data-division_id={0} onChange={this.changeEditDivisionUserTyped} name={`Last Name`} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="" className="mb-0">Division Manager Email <span className="mandatory color_red">*</span></label>
                    <input type="email" id={`editdivision-email-0`} className="form-control f-600 f2015" defaultValue=""
                      value={this.state.manager_email} data-division_id={0} onChange={this.changeEditDivisionUserTyped} placeholder="Email" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="" className="mb-0">Mobile Number <span className="mandatory color_red">*</span></label>
                    <input type="number" id={`editdivision-mobile-0`} className="form-control f-600 f2015" defaultValue=""
                      value={this.state.manager_mobile} data-division_id={0} onChange={this.changeEditDivisionUserTyped} placeholder="Mobile Number" />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="form-group">
                  <label htmlFor="" className="mb-0">Division Manager Email</label>
                  {(editDivisionData.user !== undefined) ? (
                    <input type="email" disabled id={`editdivision-email-0`} className="form-control f-600 f2015" defaultValue={defaultuserEmail}
                      placeholder="Email" />
                  ) : (``)}
                </div>
                <div className="form-group">
                  <label htmlFor="" className="mb-0">Mobile Number</label>
                  {(editDivisionData.user !== undefined) ? (
                    <input type="number" disabled id={`editdivision-mobile-0`} className="form-control f-600 f2015" defaultValue={`${defaultuserMobile}`}
                      placeholder="Mobile Number" />
                  ) : (``)}
                </div>

              </>
            )}
          </div>
        </div>
      </form>
      <div className="modal-footer border-0 justify-content-center">
        <button type="button" className="btn dark-btn mx-2" onClick={() => this.submitEditDivision()}>Save</button>
        <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setEditDivisionShow(false)}>Cancel</button>
      </div>

    </>);
  }


  getUser = async (id, boxid, updateEle) => {
    const userPayload = {
      id
    }
    await axios.post(`/users/get-user`, userPayload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res);
        console.log(boxid);
        console.log(updateEle);
        if (res.data.code === 200) {
          this.setState({
            defaultmanagerEmail: res.data.data.email,
            defaultmanagerMobile: res.data.data.mobile
          });
          document.getElementById(`${updateEle}-email-${boxid}`).value = res.data.data.email;
          document.getElementById(`${updateEle}-mobile-${boxid}`).value = res.data.data.mobile;
          var profile_pic_url = res.data.data.profile_pic_url;
          var profileImg = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((res.data.data.profile_pic_url != "") && (res.data.data.profile_pic_url != null)) ? res.data.data.profile_pic_url : 'default-avatar.png');
          document.getElementById(`${updateEle}-pic-${boxid}`).src = profileImg;
        }
        else {
          document.getElementById(`${updateEle}-email-${boxid}`).value = "";
          document.getElementById(`${updateEle}-mobile-${boxid}`).value = "";
          var profileImg = process.env.REACT_APP_STATIC_FILES_PATH + '/default-avatar.png';
          document.getElementById(`${updateEle}-pic-${boxid}`).src = profileImg;
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  submitEditDivision = async () => {
    const { EditDivisionUserNeworOld, manager_first_name, manager_last_name, manager_email, manager_mobile, EditDivisionJson } = this.state;

    if (EditDivisionUserNeworOld == 'new') {
      var message = "";
      var message_type = "";
      if (manager_first_name == "") {
        message = "First Name is required";
        message_type = "danger";
      } else if (manager_last_name == "") {
        message = "Last Name is required";
        message_type = "danger";
      } else if (manager_email == "") {
        message = "Email is required";
        message_type = "danger";
      } else if (manager_mobile == "") {
        message = "Mobile is required and should be a valid format.";
        message_type = "danger";
      }
      else {

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(manager_email)) {
          message = "Invalid Email";
          message_type = "danger";
        }


        if (!(/^[0-9]{11,14}$/g.test(manager_mobile))) {
          message = "Phone number cannot be less than 11 digits and cannot exceed 14 digits.";
          message_type = "danger";
        }



      }
      if (EditDivisionJson[0].node_name.trim().length > 30) {
        message = "Division name cannot exceed 30 characters";
        message_type = "danger";

      }
      // else{        
      //   message = "";
      //   message_type = "";         
      // }    

      this.setState({ MessageNewDivision: message, MessageNewDivisionType: message_type });
      if (message_type == "danger") {
        console.log("Error Message", this.state.MessageNewDivision);
        console.log("Error Message Type", this.state.MessageNewDivisionType);
        return false;
      }

      this.setState(prevState => ({
        ...prevState,
        EditDivisionJson: {
          ...prevState.EditDivisionJson,
          [0]: {
            ...prevState.EditDivisionJson[0],
            "user_type": "new",
            "user": {
              "first_name": manager_first_name,
              "last_name": manager_last_name,
              "email": manager_email,
              "mobile": manager_mobile,
            }
          }
        }
      }), async () => {
        const { EditDivisionJson } = this.state;

        await axios.post(`/organizations/edit-division`, EditDivisionJson[0], {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            console.log(res);
            if (res.data.code === 200) {
              this.setState({
                MessageNewDivision: res.data.message,
                MessageNewDivisionType: 'success',
                EditDivisionJson: [],

              });
              setTimeout(
                () => {
                  this.setEditDivisionShow(false);
                  this.componentDidMount();

                },
                3000);
              this.getUserDetails()
              this.getOrganizationDetails();

            }
            else {
              this.setState({
                MessageNewDivision: res.data.message,
                MessageNewDivisionType: 'danger',
              });
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
    } else {
      this.setState(prevState => ({
        ...prevState,
        EditDivisionJson: {
          ...prevState.EditDivisionJson,
          [0]: {
            ...prevState.EditDivisionJson[0],
            "user_type": "old",
          }
        }
      }), async () => {
        const { EditDivisionJson } = this.state;
        if (EditDivisionJson[0].node_name.trim().length > 30) {
          message = "Division name can not exceed 30 characters";
          message_type = "danger";

        } else {
          message = "";
          message_type = "";
        }
        this.setState({ MessageNewDivision: message, MessageNewDivisionType: message_type });
        if (message_type == "danger") {
          console.log("Error Message", this.state.MessageNewDivision);
          console.log("Error Message Type", this.state.MessageNewDivisionType);
          return false;
        }
        await axios.post(`/organizations/edit-division`, EditDivisionJson[0], {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            console.log(res);
            if (res.data.code === 200) {
              this.setState({
                MessageNewDivision: res.data.message,
                MessageNewDivisionType: 'success',
                EditDivisionJson: [],

              });
              setTimeout(
                () => {
                  this.setEditDivisionShow(false);
                  this.componentDidMount();

                },
                3000);
              this.getUserDetails()
              this.getOrganizationDetails();

            }
            else {
              this.setState({
                MessageNewDivision: res.data.message,
                MessageNewDivisionType: 'danger',
              });
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
    }

  }



  changeUser = (ele, Uid) => {
    console.log(ele);
    console.log(Uid);
    this.setState(prevState => ({
      ...prevState,
      NewDivisionJson: {
        ...prevState.NewDivisionJson,
        [Uid]: {
          ...prevState.NewDivisionJson[Uid],
          "user": {
            "user_id": ele
          }
        }
      }
    }), () => {
      if (ele == 0) {
        this.setState({ DivisionUserNeworOld: 'new' });

        // if ( document.getElementById("new_user_division").classList.contains('d-none') ){
        //   document.getElementById("new_user_division").classList.remove('d-none');
        // }
        var profileImg = process.env.REACT_APP_STATIC_FILES_PATH + '/default-avatar.png';
        document.getElementById(`adddivision-pic-0`).src = profileImg;

      } else {
        // if ( !document.getElementById("new_user_division").classList.contains('d-none') ){
        //   document.getElementById("new_user_division").classList.add('d-none');
        // }
        this.setState({ DivisionUserNeworOld: 'old' });
        this.getUser(ele, Uid, 'adddivision');
      }
      console.log(this.state.NewDivisionJson);

    })
  }

  changeEditUser = (ele, Uid) => {
    console.log(ele);
    console.log(Uid);



    this.setState(prevState => ({
      ...prevState,
      EditDivisionJson: {
        ...prevState.EditDivisionJson,
        [0]: {
          ...prevState.EditDivisionJson[0],
          "user": {
            ...prevState.EditDivisionJson[0].user,
            "user_id": ele
          }
        }
      }
    }), () => {
      if (ele == 0) {
        this.setState({ EditDivisionUserNeworOld: 'new' });

        // if ( document.getElementById("new_user_division").classList.contains('d-none') ){
        //   document.getElementById("new_user_division").classList.remove('d-none');
        // }
        var profileImg = process.env.REACT_APP_STATIC_FILES_PATH + '/default-avatar.png';

        document.getElementById(`editdivision-pic-0`).src = profileImg;

      } else {
        // if ( !document.getElementById("new_user_division").classList.contains('d-none') ){
        //   document.getElementById("new_user_division").classList.add('d-none');
        // }
        this.setState({ EditDivisionUserNeworOld: 'old' });
        this.getUser(ele, Uid, 'editdivision');
      }
      console.log(this.state.EditDivisionJson);
      console.log(this.state.EditDivisionUserNeworOld);

    })
  }

  changeAddDivision(e) {
    var reg_name_lastname = /^[0-9a-zA-Z\s]*$/;

    var stored_value = e.target.value;
    this.setState({ DivisionName: stored_value });
    //Validation to the input field
    // if (!reg_name_lastname.test(stored_value)) { //
    //   e.preventDefault();
    //   this.setState({ DivisionName: "" });
    //   stored_value.slice(0, - 1);
    //   return;
    // } else {
    //   this.setState({ DivisionName: stored_value });
    // }



    if (e.target.value.trim().length > 30) {
      this.setState({
        MessageNewDivision: "Division name can not exceed 30 characters",
        MessageNewDivisionType: 'danger',
      });
      return;
    }
    if (e.target.value.trim().length <= 30) {
      this.setState({
        MessageNewDivision: null,
        MessageNewDivisionType: '',
      });
    }
    var data_division = parseInt(e.target.getAttribute('data-division_id'));
    var data_parent = parseInt(e.target.getAttribute('data-parent_id'));
    this.setState(prevState => ({
      ...prevState,
      NewDivisionJson: {
        ...prevState.NewDivisionJson,
        [data_division]: {
          ...prevState.NewDivisionJson[data_division],
          "node_name": stored_value,
          "parent": data_parent
        }
      }
    }), () => {
      console.log(this.state.NewDivisionJson);
    })
  }

  changeAddDivisionUser(e) {
    var reg_name_lastname = /^[0-9a-zA-Z\s]*$/;

    var stored_value = e.target.value;
    var stored_id = e.target.id;
    if (stored_id == 'adddivision-first_name-0') {
      this.setState({ 'manager_first_name': stored_value });
    } else if (stored_id == 'adddivision-last_name-0') {
      this.setState({ 'manager_last_name': stored_value });
    } else if (stored_id == 'adddivision-email-0') {
      this.setState({ 'manager_email': stored_value });
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(stored_value)) {
        this.setState({
          MessageNewDivision: "Invalid Email. ",
          MessageNewDivisionType: 'danger',
        });
      } else {
        this.setState({
          MessageNewDivision: "",
          MessageNewDivisionType: "",
        });
      }
    } else if (stored_id == 'adddivision-mobile-0') {
      this.setState({ 'manager_mobile': stored_value });

      var pattern = new RegExp(/^[0-9]{11,14}$/g);
      if (!pattern.test(stored_value)) {
        this.setState({
          MessageNewDivision: "Phone number cannot be less than 11 digits and cannot exceed 14 digits.",
          MessageNewDivisionType: 'danger',
        });
      }
      // else if ((stored_value.length >= 15) || (stored_value.length < 11)) {
      //   this.setState({
      //     MessageNewDivision: "Phone No. should be greater than 10 digits and contain numbers only2. ",
      //     MessageNewDivisionType: 'danger',
      //   });

      // } 
      else {
        this.setState({
          MessageNewDivision: "",
          MessageNewDivisionType: '',
        });
      }
    }


  }
  changeEditDivisionUserTyped(e) {
    var reg_name_lastname = /^[0-9a-zA-Z\s]*$/;
    var message = "";
    var message_type = "";
    var stored_value = e.target.value;
    var stored_id = e.target.id;

    if (stored_id == 'editdivision-first_name-0') {
      if (stored_value.length > 50) {
        message = "First name should be max 50 characters.";
        message_type = "danger";
      } else {
        message = null;
        message_type = null;
      }



      this.setState({ 'manager_first_name': stored_value });
    } else if (stored_id == 'editdivision-last_name-0') {
      if (stored_value.length > 50) {
        message = "Last name should be max 50 characters.";
        message_type = "danger";
      } else {
        message = null;
        message_type = null;
      }
      this.setState({ 'manager_last_name': stored_value });

    } else if (stored_id == 'editdivision-email-0') {
      this.setState({ 'manager_email': stored_value });
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(stored_value)) {
        message = "Invalid Email";
        message_type = "danger";
      } else {
        message = "";
        message_type = "";
      }
    } else if (stored_id == 'editdivision-mobile-0') {
      this.setState({ 'manager_mobile': stored_value });

      if (!(/^[0-9]{11,14}$/g.test(stored_value))) {
        message = "Phone number cannot be less than 11 digits and cannot exceed 14 digits.";
        message_type = "danger";
      } else {
        message = "";
        message_type = "";
      }
    }

    this.setState({ MessageNewDivision: message, MessageNewDivisionType: message_type });
  }

  changeAddTeamUserTyped(e) {
    var Uid = parseInt(e.target.getAttribute('data-Uid'));
    var stored_value = e.target.value;
    var stored_id = e.target.id;
    var mainArray = this.state.mainRepArray[Uid];
    console.log("main Array@", mainArray);
    var TempArray = this.state.TempArray;
    if (TempArray == undefined) {
      TempArray = {
        "first_name": "",
        "last_name": "",
        "email": "",
        "mobile": ""
      }
    }

    if (stored_id == `addteam-rep-first_name-${Uid}`) {
      this.setState(prevState => ({
        ...prevState,
        mainRepArray: {
          ...prevState.mainRepArray,
          [Uid]: {
            ...prevState.mainRepArray[Uid],
            "first_name": stored_value,
          }
        }
      }))
    } else if (stored_id == `addteam-rep-last_name-${Uid}`) {
      this.setState(prevState => ({
        ...prevState,
        mainRepArray: {
          ...prevState.mainRepArray,
          [Uid]: {
            ...prevState.mainRepArray[Uid],
            "last_name": stored_value,
          }
        }
      }))
    } else if (stored_id == `addteam-rep-email-${Uid}`) {
      this.setState(prevState => ({
        ...prevState,
        mainRepArray: {
          ...prevState.mainRepArray,
          [Uid]: {
            ...prevState.mainRepArray[Uid],
            "email": stored_value,
          }
        }
      }))
    } else if (stored_id == `addteam-rep-mobile-${Uid}`) {
      this.setState(prevState => ({
        ...prevState,
        mainRepArray: {
          ...prevState.mainRepArray,
          [Uid]: {
            ...prevState.mainRepArray[Uid],
            "mobile": stored_value,
          }
        }
      }))
    }

    var new_users_json_object = this.state.mainRepArray;
    const new_users_arr = [];
    console.log("New Users Object", new_users_json_object);
    for (let key in new_users_json_object) {
      console.log(new_users_json_object[key]);
      new_users_arr.push(new_users_json_object[key]);
    }
    console.log("New Users Array", new_users_arr);
    // var users_ids = this.NewTeamJson[0].users.user_ids;
    var users_ids = [];
    this.setState(prevState => ({
      ...prevState,
      NewTeamJson: {
        ...prevState.NewTeamJson,
        [0]: {
          ...prevState.NewTeamJson[0],
          "users": {
            "user_ids": users_ids,
            "new_users": new_users_arr
          }
        }
      }
    }), () => {
      console.log("AFTER SALES REP LIST ADDED", this.state.NewTeamJson);
    })
    console.log(this.state.mainRepArray);

  }
  changeAddTeamManagerTyped(e) {
    console.log(e.target.id);
    console.log(e.target.name);
    var input_name = e.target.name;
    var input_value = e.target.value;
    if (input_name == "first_name") {

      this.setState(prevState => ({
        ...prevState,
        NewTeamJson: {
          ...prevState.NewTeamJson,
          [0]: {
            ...prevState.NewTeamJson[0],
            "user": {
              ...prevState.NewTeamJson[0].user,
              first_name: input_value
            }
          }
        }
      }), () => {
        console.log("NEW USER PAYLOD1", this.state.NewTeamJson);
        this.setState({ team_manager_first_name: input_value });
      })
    }
    if (input_name == "last_name") {
      this.setState(prevState => ({
        ...prevState,
        NewTeamJson: {
          ...prevState.NewTeamJson,
          [0]: {
            ...prevState.NewTeamJson[0],
            "user": {
              ...prevState.NewTeamJson[0].user,
              last_name: input_value
            }
          }
        }
      }), () => {
        console.log("NEW USER PAYLOD", this.state.NewTeamJson);
        this.setState({ team_manager_last_name: input_value });

      })
    }
    if (input_name == "email") {
      this.setState(prevState => ({
        ...prevState,
        NewTeamJson: {
          ...prevState.NewTeamJson,
          [0]: {
            ...prevState.NewTeamJson[0],
            "user": {
              ...prevState.NewTeamJson[0].user,
              email: input_value
            }
          }
        }
      }), () => {
        console.log("NEW USER PAYLOD", this.state.NewTeamJson);
        this.setState({ team_manager_email: input_value });

      })
    }
    if (input_name == "mobile") {
      this.setState(prevState => ({
        ...prevState,
        NewTeamJson: {
          ...prevState.NewTeamJson,
          [0]: {
            ...prevState.NewTeamJson[0],
            "user": {
              ...prevState.NewTeamJson[0].user,
              mobile: input_value
            }
          }
        }
      }), () => {
        console.log("NEW USER PAYLOD", this.state.NewTeamJson);
        this.setState({ team_manager_mobile: input_value });

      })
    }









  }
  changeEditTeamManagerTyped(e) {

    var input_name = e.target.name;
    var input_value = e.target.value;
    if (input_name == "first_name") {
      
       if (input_value.length > 50){
        this.setState({
          MessageNewTeam: "First name should be max 50 characters.",
          MessageNewTeamType: 'danger',
        });
      }
       else {
        this.setState({
          MessageNewTeam: "",
          MessageNewTeamType: '',
        });
      }
      // this.setState(prevState => ({
      //   ...prevState,
      //   mainRepArray: {
      //     ...prevState.mainRepArray,
      //     [Uid]: {
      //       ...prevState.mainRepArray[Uid],
      //       "first_name": stored_value,
      //     }
      //   }
      // }))    
      console.log("staete before", this.state.EditTeamJson);
      this.setState(prevState => ({
        ...prevState,
        EditTeamJson: {
          ...prevState.EditTeamJson,
          [0]: {
            ...prevState.EditTeamJson[0],
            "user": {
              ...prevState.EditTeamJson[0].user,
              first_name: input_value
            }
          }
        }
      }), () => {
        console.log("NEW USER PAYLOD1", this.state.EditTeamJson);
      })
    }
    if (input_name == "last_name") {
      if (input_value.length > 50){
        this.setState({
          MessageNewTeam: "Last name should be max 50 characters.",
          MessageNewTeamType: 'danger',
        });
      }
       else {
        this.setState({
          MessageNewTeam: "",
          MessageNewTeamType: '',
        });
      }
      this.setState(prevState => ({
        ...prevState,
        EditTeamJson: {
          ...prevState.EditTeamJson,
          [0]: {
            ...prevState.EditTeamJson[0],
            "user": {
              ...prevState.EditTeamJson[0].user,
              last_name: input_value
            }
          }
        }
      }), () => {
        console.log("NEW USER PAYLOD", this.state.EditTeamJson);
      })
    }
    if (input_name == "email") {
      this.setState({ defaultmanagerEmail: input_value });
      this.setState(prevState => ({
        ...prevState,
        EditTeamJson: {
          ...prevState.EditTeamJson,
          [0]: {
            ...prevState.EditTeamJson[0],
            "user": {
              ...prevState.EditTeamJson[0].user,
              email: input_value
            }
          }
        }
      }), () => {
        console.log("NEW USER PAYLOD", this.state.EditTeamJson);
        this.setState({ defaultmanagerEmail: input_value });
      })
    }
    if (input_name == "mobile") {


      this.setState({ defaultmanagerMobile: input_value });
      this.setState(prevState => ({
        ...prevState,
        EditTeamJson: {
          ...prevState.EditTeamJson,
          [0]: {
            ...prevState.EditTeamJson[0],
            "user": {
              ...prevState.EditTeamJson[0].user,
              mobile: input_value
            }
          }
        }
      }), () => {
        console.log("NEW USER PAYLOD", this.state.EditTeamJson);
        this.setState({ defaultmanager: input_value });
      })
    }

  }

  setAddDivisionShow = (newValue, parent_id) => {
    this.setState({ addDivisionShow: newValue, DivisionParent: parent_id, DivisionName: "" });

    this.setState(prevState => ({
      ...prevState,
      NewDivisionJson: {
        ...prevState.NewDivisionJson,
        [0]: {
          ...prevState.NewDivisionJson[0],
          "node_name": "",
          "parent": parent_id
        }
      }
    }))
    this.setState({
      DivisionUserNeworOld: 'old',
      manager_first_name: "",
      manager_last_name: "",
      manager_email: "",
      manager_mobile: "",
      MessageNewDivision: null,
      MessageNewDivisionType: '',
      salesReps: [], salesDivisions: []
    });
  }

  addDivision = () => {
    var { MessageNewDivision, MessageNewDivisionType, DivisionParent, profileImg, DivisionUserNeworOld } = this.state;
    return (<>
      {((MessageNewDivision) && (MessageNewDivision !== null)) ?
        (
          <div className={`alert alert-${MessageNewDivisionType}`}>
            {MessageNewDivision}
          </div>
        ) : (``)
      }
      <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Sales
        Division</span>
        {/* <small className="float-right bg-white d-inline-block pl-3 link" onClick={() => this.appendDivision()}> + Add</small> */}
      </div>
      <form>
        <div className="form-group">
          <label htmlFor="" className="mb-0 w-100">Sales Division Name  <span className="mandatory color_red">*</span></label>
          <input type="text" name="DivisionName" id={`adddivision_name`} value={this.state.DivisionName} data-parent_id={DivisionParent} data-division_id={0} className="form-control f-600 f2015" defaultValue="" placeholder="Sales Division Name" onChange={this.changeAddDivision} />
        </div>
        <div className="clearfix">
          <div className="profile-pic float-sm-left">
            <div className="img-wrap">
              <img className="profile-pic-img" id={`adddivision-pic-0`} src={profileImg} alt="profile" />
            </div>
          </div>
          <div className="profile-info float-sm-left">
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <label htmlFor="" className="mb-0">Manager Name</label>
                  <CustomSelect
                    className="form-control f-600 text-left"
                    defaultText={this.state.defaultuserSelectTextf}
                    optionsList={this.state.userList}
                    name="user_id" dataUid={0}
                    onSelect={this.changeUser}
                  />
                </div>
              </div>
            </div>
            {(DivisionUserNeworOld == 'new') ? (
              <div className="row" id="new_user_division">
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-0">First Name <span className="mandatory color_red">*</span></label>
                    <input type="text" id={`adddivision-first_name-0`} className="form-control f-600 f2015"
                      placeholder="Head First Name" value={this.state.manager_first_name} data-division_id={0} onChange={this.changeAddDivisionUser} name={`First Name`} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-0">Last Name <span className="mandatory color_red">*</span></label>
                    <input type="text" id={`adddivision-last_name-0`} className="form-control f-600 f2015"
                      placeholder="Head Last Name" value={this.state.manager_last_name} data-division_id={0} onChange={this.changeAddDivisionUser} name={`Last Name`} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="" className="mb-0">Division Manager Email <span className="mandatory color_red">*</span></label>
                    <input type="email" id={`adddivision-email-0`} className="form-control f-600 f2015" defaultValue=""
                      value={this.state.manager_email} data-division_id={0} onChange={this.changeAddDivisionUser} placeholder="Email" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="" className="mb-0">Mobile Number <span className="mandatory color_red">*</span></label>
                    <input type="number" id={`adddivision-mobile-0`} className="form-control f-600 f2015" defaultValue=""
                      value={this.state.manager_mobile} data-division_id={0} onChange={this.changeAddDivisionUser} placeholder="Mobile Number" />
                  </div>
                </div>
              </div>

            ) : (
              <>
                <div className="form-group">
                  <label htmlFor="" className="mb-0">Division Manager Email</label>
                  <input type="email" disabled id={`adddivision-email-0`} className="form-control f-600 f2015" defaultValue=""
                    placeholder="Email" />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="mb-0">Mobile Number</label>
                  <input type="number" disabled id={`adddivision-mobile-0`} className="form-control f-600 f2015" defaultValue=""
                    placeholder="Mobile Number" />
                </div>
              </>
            )}
          </div>
        </div>
        {this.state.salesDivisions.map((input, index) =>
        (<>

          <div className="form-group">
            <label htmlFor="" className="mb-0 w-100">Sales Division Name  <span className="float-right bg-white d-inline-block pl-3 link color_red" onClick={() => this.removesalesDivision(input)}> - Clear</span></label>
            <input type="text" name="node_name" data-division_id={index + 1} className="form-control f-600 f2015" defaultValue="" placeholder="Sales Division Name" onChange={this.changeAddDivision} />
          </div>
          <div className="clearfix">
            <div className="profile-pic float-sm-left">
              <div className="img-wrap">
                <img className="profile-pic-img" id="salesDivision" src={profileImg} alt="profile" />
              </div>
            </div>
            <div className="profile-info float-sm-left">
              <div className="form-group">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="mb-0">Manager Name</label>
                    <CustomSelect
                      className="form-control f-600 text-left"
                      defaultText={this.state.defaultuserSelectTextf}
                      optionsList={this.state.userList}
                      name="user_id" dataUid={index + 1}
                      onSelect={this.changeUser}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="" className="mb-0">Division Manager Email</label>
                <input type="email" disabled className="form-control f-600 f2015" defaultValue="ned.badman@abbott.com"
                  placeholder="Email" />
              </div>
              <div className="form-group">
                <label htmlFor="" className="mb-0">Mobile Number</label>
                <input type="number" id='division_mobile_no3' className="form-control f-600 f2015" defaultValue="+91-700-014-6625"
                  placeholder="Mobile Number" />
              </div>
            </div>
          </div>
        </>))}

      </form>
      <div className="modal-footer border-0 justify-content-center">
        <button type="button" className="btn dark-btn mx-2" onClick={() => this.submitAddDivision()}>Save</button>
        <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setAddDivisionShow(false)}>Cancel</button>
      </div>
    </>);
  }

  submitAddDivision = async () => {
    const { DivisionUserNeworOld, manager_first_name, manager_last_name, manager_email, manager_mobile, NewDivisionJson } = this.state;
    var message = this.state.MessageNewDivision;
    var message_type = this.state.MessageNewDivisionType;
    if (DivisionUserNeworOld == 'new') {

      if (manager_first_name == "") {
        message = "First Name is required";
        message_type = "danger";
      } else if (manager_last_name == "") {
        message = "Last Name is required";
        message_type = "danger";
      } else if (manager_email == "") {
        message = "Email is required";
        message_type = "danger";
      } else if (manager_mobile == "") {
        message = "Mobile is required and should be a valid format.";
        message_type = "danger";
      }

      this.setState({ MessageNewDivision: message, MessageNewDivisionType: message_type });
      if (message_type == "danger") {
        console.log("Error Message", this.state.MessageNewDivision);
        console.log("Error Message Type", this.state.MessageNewDivisionType);
        return false;
      }

      this.setState(prevState => ({
        ...prevState,
        NewDivisionJson: {
          ...prevState.NewDivisionJson,
          [0]: {
            ...prevState.NewDivisionJson[0],
            "user_type": "new",
            "user": {
              "first_name": manager_first_name,
              "last_name": manager_last_name,
              "email": manager_email,
              "mobile": manager_mobile,
            }
          }
        }
      }), async () => {
        const { NewDivisionJson } = this.state;

        console.log(NewDivisionJson[0]);
        await axios.post(`/organizations/create-division`, NewDivisionJson[0], {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            console.log(res);
            if (res.data.code === 200) {
              this.setState({
                MessageNewDivision: res.data.message,
                MessageNewDivisionType: 'success',
                NewDivisionJson: []
              });
              setTimeout(
                () => {
                  this.setAddDivisionShow(false);
                  this.componentDidMount();
                },
                3000);
              this.getUserDetails()
              this.getOrganizationDetails();

            }
            else {
              this.setState({
                MessageNewDivision: res.data.message,
                MessageNewDivisionType: 'danger',
              });
            }
          })
          .catch((error) => {
            console.log(error)
          })
      });

    } else {
      this.setState(prevState => ({
        ...prevState,
        NewDivisionJson: {
          ...prevState.NewDivisionJson,
          [0]: {
            ...prevState.NewDivisionJson[0],
            "user_type": "old",
          }
        }
      }), async () => {
        const { NewDivisionJson } = this.state;
        console.log(NewDivisionJson[0]);
        await axios.post(`/organizations/create-division`, NewDivisionJson[0], {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            console.log(res);
            if (res.data.code === 200) {
              this.setState({
                MessageNewDivision: res.data.message,
                MessageNewDivisionType: 'success',
                NewDivisionJson: []

              });
              setTimeout(
                () => {
                  this.setAddDivisionShow(false);
                  this.componentDidMount();

                },
                3000);
              this.getUserDetails()
              this.getOrganizationDetails();

            }
            else {
              this.setState({
                MessageNewDivision: res.data.message,
                MessageNewDivisionType: 'danger',
              });
            }
          })
          .catch((error) => {
            console.log(error)
          })

      });
    }

  }


  setOrgTargetShow = (newValue) => {
    
    this.setstate({TargetYear:this.state.current_year},()=>{this.setState({ orgTargetShow: newValue });});
  }

  orgTarget = () => {
    return (<>

      <form>
        <div className="form-group">
          <label className="mb-0">Year</label>
          <CustomSelect
            className="form-control f-600 text-left"
            defaultText={this.state.defaultyearSelectText}
            optionsList={this.state.yearList}
          />
        </div>
        <div className="form-group">
          <label className="mb-0">No. Coaching Sessions per Sales Rep</label>
          <input type="text" className="form-control f-600 f2015" defaultValue="12" placeholder="12" />
        </div>
        <div className="form-group input-prefix-group">
          <label className="mb-0">Revenue Budget</label>
          <span className="input-prefix f-600 f2015">$</span>
          <input type="text" className="form-control f-600 f2015" defaultValue="1,100,000" placeholder="Revenue Budget" pattern="[0-9]+[ ]+[aA-zZ]+" />
        </div>
        <div className="modal-footer border-0 justify-content-center">
          <button type="button" className="btn dark-btn mx-2" onClick={() => this.setOrgTargetShow(false)}>Save</button>
          <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setOrgTargetShow(false)}>Cancel</button>
        </div>
      </form>

    </>)
  }

  setDivisionTargetShow = (newValue) => {
    this.setState({
      defaultyearSelectText:this.state.current_year
    },()=>{ this.setState({ divisionTargetShow: newValue });});
   
  }

  divisionTarget = () => {
    return (<>
      <form>
        <div className="form-group">
          <label htmlFor="" className="mb-0">Year</label>
          <CustomSelect
            className="form-control f-600 text-left"
            defaultText={this.state.defaultyearSelectText}
            optionsList={this.state.yearList}
          />
        </div>
        <div className="form-group">
          <label htmlFor="" className="mb-0">No. Coaching Sessions per Sales Rep</label>
          <input type="number" className="form-control f-600 f2015" defaultValue="7" placeholder="eg. 2" />
        </div>
        <div className="form-group input-prefix-group">
          <label className="mb-0">Revenue Budget</label>
          <span className="input-prefix f-600 f2015">$</span>
          <input type="text" className="form-control f-600 f2015" defaultValue="500,000" placeholder="Revenue Budget" pattern="[0-9]+[ ]+[aA-zZ]+" />
        </div>
      </form>

      <div className="modal-footer border-0 justify-content-center">
        <button type="button" className="btn dark-btn mx-2" onClick={() => this.setDivisionTargetShow(false)}>Save</button>
        <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setDivisionTargetShow(false)}>Cancel</button>
      </div>
    </>)
  }

  setTeamTargetShow = async (newValue, OrgTagetsID, TargetsTitle) => {
    this.setState({ teamTargetShow: newValue, OrgTagetsID, TargetsTitle });
    this.setState({
      targetMessage: null,
      targetMessageType: "",
      // TargetYear: this.state.yearList[0].value
      TargetYear: this.state.current_year
    })
    var compareYear = parseInt(this.state.yearList[0].value);
    var todaydate = new Date().getFullYear();
  
    const payload = {
      "org_structure_id": OrgTagetsID,
      "year": this.state.TargetYear
    }
    await axios.post(`organizations/get-parameters`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          if (res.data.data.length > 0) {
            this.setState({
              Coachings: res.data.data[0].coaching_sessions,
              Revenue: this.maskNumbersInWord(res.data.data[0].revenue_budget),
            })
          } else {
            this.setState({
              Coachings: "",
              Revenue: "",
            })
          }

        } else {
          this.setState({ teamTargetShow: false, OrgTagetsID: null });
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  changeYear = (ele, Uid) => {
    var todaydate = new Date().getFullYear();
    if (ele < todaydate) {
      this.setState({ saveEnable: false });
    } else {
      this.setState({ saveEnable: true });
    }
    this.setState({
      Coachings: "",
      Revenue: "",
    })
    const { OrgTagetsID } = this.state;
    this.setState({ TargetYear: ele }, async () => {
      const payload = {
        "org_structure_id": OrgTagetsID,
        "year": ele
      }
      await axios.post(`organizations/get-parameters`, payload, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        }
      })
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            if (res.data.data.length > 0) {
              if (res.data.data.length > 0) {
                this.setState({
                  Coachings: res.data.data[0].coaching_sessions,
                  Revenue: res.data.data[0].revenue_budget,
                })
              } else {
                this.setState({
                  Coachings: "",
                  Revenue: "",
                })
              }
            }
          } else {
            this.setState({ teamTargetShow: false, OrgTagetsID: null });
          }

        })
        .catch((error) => {
          console.log(error)
        })

    });
  }
  changeTargets(e) {
    var data_id = e.target.getAttribute('data-id');
    if (data_id === "coaching-sessions") {
      this.setState({ Coachings: e.target.value });
    } else {
      this.setState({ Revenue: e.target.value });
    }
  }
  focusTarget(e) {
    console.log('focus target', e.target.value);
    var formatedVal = this.maskWordtoNumber(e.target.value);
    this.setState({ Revenue: formatedVal });
  }
  blureTarget(e) {
    console.log('blur target', e.target.value);
    var formatedVal = this.maskNumbersInWord(e.target.value);
    this.setState({ Revenue: formatedVal });

  }
  keypressTargets(e) {

    console.log('key press ', e.key)

    let isnum = /^\d+$/.test(e.key);

    if (e.key == 'Backspace') {
      //continue
    } else if (isnum) {
      //continue
    } else {
      e.preventDefault();
    }
  }
  maskNumbersInWord(num) {
    console.log('maskNumbersInWord called !');

    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(2) + 'K'; // convert to K for number from > 1000 < 1 million 
    } else if (num >= 1000000 && num < 1000000000) {
      return (num / 1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million  < 1 billion
    } else if (num >= 1000000000 && num < 1000000000000) {
      return (num / 1000000000).toFixed(2) + 'B'; // convert to B for number from > 1 billion 
    } else if (num >= 1000000000000) {
      return (num / 1000000000000).toFixed(2) + 'T'; // convert to B for number from > 1 billion 
    } else if (num <= 999) {
      return num; // if value < 1000, nothing to do
    }


  }
  maskWordtoNumber(word) {
    console.log('maskWordtoNumber called !');

    if (word.includes('K')) {
      //thousand
      var num = word.replace("K", "");
      var extracted_num = parseFloat(num) * 1000;
      var final_num = parseFloat(extracted_num);
    } else if (word.includes('M')) {
      //million
      var num = word.replace("M", "");
      var extracted_num = parseFloat(num) * 1000000;
      var final_num = parseFloat(extracted_num);
    } else if (word.includes('B')) {
      //billion
      var num = word.replace("B", "");
      var extracted_num = parseFloat(num) * 1000000000;
      var final_num = parseFloat(extracted_num);
    } else {
      var final_num = word;
    }

    return final_num

  }
  updateTargets = async () => {
    const { OrgTagetsID, TargetYear, Revenue, Coachings } = this.state;
    const payload = {
      "org_structure_id": OrgTagetsID,
      "year": TargetYear,
      "coaching_sessions": Coachings,
      "revenue_budget": this.maskWordtoNumber(Revenue)
    }
    await axios.post(`organizations/add-parameters`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {

          console.log('res data', res.data);
          this.setState({
            targetMessage: res.data.message,
            targetMessageType: "success"
          }, () => {
            setTimeout(() => {
              this.setState({
                targetMessage: "",
                targetMessageType: null,
              });
            }, 3000);
          })

        } else {
          this.setState({
            targetMessage: res.data.message,
            targetMessageType: "danger"
          }, () => {
            setTimeout(() => {
              this.setState({
                targetMessage: "",
                targetMessageType: null,
              });
            }, 3000);
          })
        }

      })
      .catch((error) => {
        console.log(error)
      })

  }
  teamTarget = () => {
    const { targetMessage, targetMessageType, OrgTagetsID, TargetYear, Revenue, Coachings, saveEnable } = this.state;
    
    return (<>
      {((targetMessage) && (targetMessage !== null)) ?
        (
          <div className={`alert alert-${targetMessageType}`}>
            {targetMessage}
          </div>
        ) : (``)
      }
      <form>
        <div className="form-group">
          <label htmlFor="" className="mb-0">Year</label>
          <CustomSelect
            className="form-control f-600 text-left"
            defaultText={this.state.TargetYear}
            optionsList={this.state.yearList}
            name="targets" dataUid={OrgTagetsID}
            onSelect={this.changeYear}

          />
        </div>
        <div className="form-group">
          <label htmlFor="" className="mb-0">No. Coaching Sessions per Sales Rep</label>
          <input type="number" onChange={this.changeTargets} data-id="coaching-sessions" className="form-control f-600 f2015" value={Coachings} placeholder="Whole numbers only." />
        </div>
        <div className="form-group input-prefix-group">
          <label className="mb-0">Revenue Budget</label>
          <span className="input-prefix f-600 f2015">$</span>
          <input type="text" onFocus={this.focusTarget} onBlur={this.blureTarget} onKeyDown={this.keypressTargets} onChange={this.changeTargets} data-id="revenue-budget" className="form-control f-600 f2015" value={Revenue} placeholder="Revenue Budget" pattern="[0-9]" />
        </div>
      </form>

      <div className="modal-footer border-0 justify-content-center">
        {(saveEnable === true) ? (
          <button type="button" className="btn dark-btn mx-2" onClick={() => this.updateTargets()}>Save</button>
        ) : (``)}
        <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setTeamTargetShow(false)}>Cancel</button>
      </div>
    </>)
  }


  setOrgHeadEditShow = (newValue) => {
    this.setState({ orgHeadEditShow: newValue });
  }

  orgHeadEdit = () => {
    const { profileImg } = this.state;
    return (<>
      <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Organization Head
        Position</span></div>
      <form>
        <div className="form-group">
          <label htmlFor="" className="mb-0">Head Position</label>
          <input type="text" id="head_position" defaultValue="CEO" className="form-control f-600 f2015" placeholder="Head Position" onChange={this.changeFunction} />
        </div>
        <div className="clearfix">
          <div className="profile-pic float-sm-left">
            <div className="img-wrap">
              <img className="profile-pic-img" id="orgCeo" src={profileImg} alt="profile" />
            </div>
            <div className="p-image">
              <i className="fa fa-camera upload-button" id="orgCeobtn"></i>
              <input className="file-upload" id="orgCeofile" type="file" accept="image/*" />
            </div>
          </div>
          <div className="profile-info float-sm-left">
            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="" className="mb-0">Head First Name</label>
                  <CustomSelect
                    className="form-control f-600 text-left"
                    defaultText={this.state.defaultuserSelectTextf}
                    optionsList={this.state.userList} id="head_first_name" onChange={this.changeFunction}
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="" className="mb-0">Head Last Name</label>
                  <CustomSelect
                    className="form-control f-600 text-left"
                    defaultText={this.state.defaultuserSelectTextl}
                    optionsList={this.state.userList} id="head_last_name" onChange={this.changeFunction}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="" className="mb-0">Organization Head Email</label>
              <input type="email" id="head_email" disabled className="form-control f-600 f2015" defaultValue="jackson.Stark@abbott.com"
                placeholder="Email" onChange={this.changeFunction} />
            </div>
            <div className="form-group">
              <label htmlFor="" className="mb-0">Mobile Number</label>
              <input type="number" id='organization_head_mobile_no' className="form-control f-600 f2015" defaultValue="+91-700-014-6625"
                placeholder="Mobile Number" onChange={this.changeFunction} />
            </div>
          </div>
        </div>
      </form>
      <div className="modal-footer justify-content-center">
        <button type="button" className="btn dark-btn mx-2" onClick={() => this.updateOrganization(false)}>Save</button>
        <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setOrgHeadEditShow(false)}>Cancel</button>
      </div>
    </>);
  }

  // setDeactivateUserShow = (newValue, selectedUserName) => {
  //   this.setState({ deactivateUsershow: newValue, selectedUserName });
  // }

  setDeactivateUserShow = (newValue, orgUserData) => {
    console.log(orgUserData)
    if (newValue === false) {
      this.setState({
        deactivateUsershow: newValue, orgUserData, Message: '', MessageType: '',
      });
    }
    else {
      this.setState({ deactivateUsershow: newValue, rowData: orgUserData });
      this.setState({ deactivateUsershow: newValue });
    }
  }

  userDeactivate = () => {
    const { Message, MessageType } = this.state
    return (<>
      {((Message) && (Message !== null)) ?
        (
          <div className={`alert alert-${MessageType}`}>
            {Message}
          </div>
        ) : (``)
      }
      <div className="my-3">
        <p className="m-0 text-center">
          {/* {this.state.orgUserData.node_name} */}
          Are you sure you want to deactivate  <span className="d-sm-block word-break-all f-600"> {this.state.rowData.first_name} {this.state.rowData.last_name} ? </span> </p>
        <p></p>
        {/* {(this.state.rowData.status === "1") ? `deactivate` : `activate`} */}

        <div className="modal-footer border-0 justify-content-center">
          <button type="button" className="btn dark-btn mx-2" onClick={() => this.UpdateUsertatus()}>Yes</button>
          <button type="button" className="btn light-btn mx-2" onClick={() => this.setDeactivateUserShow(false)} >No</button>
        </div>

      </div>
    </>);
  }
  UpdateUsertatus = async () => {
    if (this.state.rowData.status == 0) {
      this.state.rowData.status = "1"
    }
    else {
      this.state.rowData.status = "0"
    }
    // if (this.state.rowData.status == 1) {
    //   this.state.rowData.status = "0"
    // }
    // else if (this.state.rowData.status == 0) {
    //   this.state.rowData.status = "1"
    // }
    const updateStageinfo = {
      user_id: this.state.rowData.id,
      status: this.state.rowData.status,
    }
    await axios.post(`users/update-status`, updateStageinfo, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      console.log(res);
      if (res.data.code === 200) {
        this.getOrganizationDetails();
        this.getAllDivisionsTeams();
        this.getAllUsers();
        this.setState({
          Message: res.data.message,
          MessageType: 'success',
        });

        setTimeout(
          () => {
            this.setDeactivateUserShow(false);
          },
          3000);
      }
      else {
        this.setState({
          Message: "Server Error.",
          MessageType: 'danger',
          fileextractDone: 0,
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })

  }
  setResetLinkSendShow = (newValue) => {
    if (newValue === true) {
      this.setState({ resetLinkSendshow: newValue, profileShow: false, });
    } else {
      this.setState({
        resetLinkSendSuccessshow: newValue,
        profileShow: true,
      });
    }

  }

  resetLinkSend = () => {
    return (<>

      <div className="my-3">
        <p className="m-0 text-center">
          Are you sure you want to reset the password for user <span>Winton Sandoval</span>? </p>
        <p></p>

        <div className="modal-footer border-0 justify-content-center">
          <button type="button" className="btn dark-btn mx-2" onClick={() => this.setResetLinkSendSuccessShow(true)}>Yes</button>
          <button type="button" className="btn light-btn mx-2" onClick={() => this.setResetLinkSendShow(false)} >No</button>
        </div>

      </div>
    </>);
  }

  setResetLinkSendSuccessShow = (newValue) => {
    if (newValue === true) {
      this.setState({ resetLinkSendSuccessshow: newValue });
      this.setState({ resetLinkSendshow: false });
    } else {
      this.setState({
        resetLinkSendSuccessshow: newValue,
        profileShow: true,
      });

    }


  }

  resetLinkSendSuccess = () => {
    return (<>
      <div className="my-3 text-center">
        <div className="iconmoon icon-check check-successfully"></div>
        <p className="m-0 text-center">
          Reset password link sent. </p>


        <div className="modal-footer border-0 justify-content-center">
          <button type="button" className="btn dark-btn mx-2" onClick={() => this.setResetLinkSendSuccessShow(false)}>Okay</button>
        </div>

      </div>
    </>);
  }
  setDeactivateDivisionShow = (newValue, selectedDivisionName, selectedDivisionId) => {
    this.setState({ deactivateDivisionshow: newValue, selectedDivisionName, selectedDivisionId });
    this.setState({
      MessageDeactivateDivision: null,
      MessageDeactivateDivisionType: null,
      deactivateText: true
    })
  }
  UpdateDivisionStatus = async () => {
    const payload = {
      org_structure_id: this.state.selectedDivisionId,
      status: 0,
    }
    await axios.post(`organizations/update-organization-structure-status`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({
            MessageDeactivateDivision: "Division deactivated successfully.",
            MessageDeactivateDivisionType: "success"
          })
          setTimeout(
            () => {
              this.setDeactivateDivisionShow(false);
              this.componentDidMount();
            },
            3000);
        } else {
          this.setState({
            MessageDeactivateDivision: "Error in update status.",
            MessageDeactivateDivisionType: "danger"
          })
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }
  divisionDeactivate = () => {
    const { MessageDeactivateDivision, MessageDeactivateDivisionType, deactivateText } = this.state;
    return (<>
      {((MessageDeactivateDivision) && (MessageDeactivateDivision !== null)) ?
        (
          <div className={`alert alert-${MessageDeactivateDivisionType}`}>
            {MessageDeactivateDivision}
          </div>
        ) : (``)}
      <form>
        <div className="text-center mx-2 my-4">

          <p className="m-0"> There are active teams and users in the <span className="d-lg-block f-600"> {this.state.selectedDivisionName}</span> <br />
            Do you want to deactivate this division, the teams, and users? </p>

          <div className="form-group mt-4">
            <input type="text" className="form-control f2015 text-center"
              placeholder="Type Deactivate here and click confirm for deactivate." data-action={`deactivate-division`} onChange={(e) => { this.deactivateText(e) }} />
          </div>

          <div className="modal-footer border-0 mb-4 justify-content-center">
            <button type="button" disabled={deactivateText} className="btn dark-btn mx-2 deactivate-division" onClick={() => this.UpdateDivisionStatus()}>Confirm</button>
            <button type="button" className="btn light-btn mx-2" onClick={() => this.setDeactivateDivisionShow(false)} >Cancel</button>
          </div>
          <Link to={`./user-management`} className="color_blue py-4 underline f2015" >Go to Users</Link>
        </div>
      </form>

    </>);
  }


  setDeactivateTeamShow = (newValue, selectedTeamName, selectedTeamId) => {
    this.setState({ deactivateTeamshow: newValue, selectedTeamName, selectedTeamId });
    this.setState({
      MessageDeactivateTeam: null,
      MessageDeactivateTeamType: null,
      deactivateText: true
    })
  }
  UpdateTeamStatus = async () => {
    const payload = {
      org_structure_id: this.state.selectedTeamId,
      status: 0,
    }
    await axios.post(`organizations/update-organization-structure-status`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({
            MessageDeactivateTeam: "Team deactivated successfully.",
            MessageDeactivateTeamType: "success"
          })
          setTimeout(
            () => {
              this.setDeactivateTeamShow(false);
              this.componentDidMount();
            },
            3000);
        } else {
          this.setState({
            MessageDeactivateTeam: "Error in update status.",
            MessageDeactivateTeamType: "danger"
          })
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }
  teamDeactivate = () => {
    const { MessageDeactivateTeam, MessageDeactivateTeamType, deactivateText } = this.state;
    return (<>
      {((MessageDeactivateTeam) && (MessageDeactivateTeam !== null)) ?
        (
          <div className={`alert alert-${MessageDeactivateTeamType}`}>
            {MessageDeactivateTeam}
          </div>
        ) : (``)}
      <form>
        <div className="text-center mx-2 my-4">

          <p className="m-0"> There are active users in team   <span className="d-inline-block f-600 "> {this.state.selectedTeamName}</span> <span className="d-xl-block"></span> <br />
            Do you want to deactivate this team and all members of the team? </p>
          <div className="form-group mt-4">
            <input type="text" className="form-control f2015 text-center"
              placeholder="Type Deactivate here and click confirm for deactivate." data-action={`deactivate-team`} onChange={(e) => { this.deactivateText(e) }} />
          </div>

          <div className="modal-footer border-0 mb-4 justify-content-center">
            <button type="button" disabled={deactivateText} className="btn dark-btn mx-2 deactivate-team" onClick={() => this.UpdateTeamStatus()}>Confirm</button>
            <button type="button" className="btn light-btn mx-2" onClick={() => this.setDeactivateTeamShow(false)} >Cancel</button>
          </div>
          <Link to={`./user-management`} className="color_blue py-4 underline f2015" >Go to Users</Link>
        </div>
      </form>
    </>);
  }
  sendResetPasswordLink = async (email) => {
    utilityObj.loaderView("show");
    const sentResetPAsswordinfo = {
      email: email,
      redirect_url: process.env.REACT_APP_PASSWORD_CREATE_LINK,
    }
    console.log(sentResetPAsswordinfo);
    await axios.post(`/users/send-reset-password-link`, sentResetPAsswordinfo, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        if (res.data.code === 200) {
          utilityObj.loaderView("hide");
          this.setState({
            Message: "Password reset link was sent to user.",
            MessageType: 'success',
          });


        } else {

        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  setProfileShow = async (newValue, selectedUserId, selectedUserName,) => {
    if (selectedUserId != undefined) {
      console.log("selectedUserId", selectedUserId);
      this.setState({ selectedUserId: selectedUserId });


      await axios.post(`users/get-profile-org-structure`, { "user_id": selectedUserId }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        }
      }).then(res => {
        console.log("RESPONSE OF APIS", res);
        if (res.data.code === 200) {
          this.setState({ "user_org_detail": {} });
          this.setState({ "user_detail": res.data.data[0].user_detail[0] });
          if (res.data.data[1].organization_details.length > 0) {
            this.setState({ "user_org_detail": res.data.data[1].organization_details[0] });
          }
          var org_structure_id = res.data.data[0].org_structure_id;
          var organization_structure = this.state.organization_structure;
          if (organization_structure[0]) {
            let divisions = organization_structure[0].child;
            var divisions_list = [];
            for (var i = 0; i < divisions.length; i++) {
              var team_data = divisions[i].child;
              var team_list = [];
              for (var k = 0; k < team_data.length; k++) {



                if (team_data[k].id == org_structure_id) {

                  var division_id = divisions[i].id;
                  var division_name = divisions[i].node_name;
                  var division_manager = divisions[i].user[0].first_name + " " + divisions[i].user[0].last_name;
                  var team_id = team_data[k].id
                  var team_name = team_data[k].node_name;
                  var team_manager = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                  this.setState({ defaultdivisionSelectText: division_name });
                  this.setState({ defaultteamSelectText: team_name });
                  this.setState({ division_manager: division_manager });
                  this.setState({ sales_manager: team_manager });
                }
              }
            }
          }
        }
        else {
          this.setState({
            Message: res.data.message,
            MessageType: 'danger',
            fileextractDone: 0,
          });
        }
      })
        .catch((error) => {
          console.log(error)
        })
    }
    this.setState({ profileShow: newValue });
    this.setState({
      Message: "",
      MessageType: '',
    });
  }

  userProfile = () => {
    const { user_detail, user_org_detail, orgName, Message, MessageType, profileImg } = this.state;
    var division_name = "";
    var division_manager = "";
    var sales_team = "";
    var sales_manager_name = "";
    console.log("user_org_detail", user_org_detail);
    if (user_org_detail.division != "") {
      if (user_org_detail.hasOwnProperty("division")) {
        division_name = user_org_detail.division.node_name;
      } else {
        division_name = "";
      }
      if (user_org_detail.hasOwnProperty("division_manager") && user_org_detail.division_manager.length > 0) {
        division_manager = user_org_detail.division_manager[0].first_name + " " + user_org_detail.division_manager[0].last_name;
      }

      if (user_org_detail.hasOwnProperty("team") && user_org_detail.team.user.length > 0) {
        sales_team = user_org_detail.team.node_name;
        sales_manager_name = user_org_detail.team.user[0].first_name + " " + user_org_detail.team.user[0].last_name;
      }
    }
    return (<>
      {((Message) && (Message !== null)) ?
        (
          <div className={`alert alert-${MessageType}`}>
            {Message}
          </div>
        ) : (``)
      }

      <form>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Personal
              Details</span></div>

            <div className="clearfix px-2">
              <div className="profile-pic float-sm-right ">
                <div className="img-wrap mr-0 mx-auto">
                  {/* <img className="profile-pic-img" src={profileImg} alt="profile" /> */}
                  <img className="profile-pic-img" src={this.getProfileImageByPath(user_detail.profile_pic_url)} alt="profile" />
                </div>
              </div>
              <div className="profile-info float-sm-left">
                <div className="form-group">
                  <label className="mb-0">Full Name</label>

                  <div className="feild-text word-break-all py-3 f-600 f2015">
                    {user_detail ? user_detail.first_name + " " + user_detail.last_name : ""}
                  </div>

                </div>
                <div className="form-group">
                  <label className="mb-0">Email ID</label>
                  <div className="feild-text py-3 f-600 f2015">

                    {user_detail ? user_detail.email : ""}
                  </div>
                </div>
                <div className="form-group">
                  <label className="mb-0">Mobile Number</label>
                  <div className="feild-text py-3 f-600 f2015">
                    {user_detail ? user_detail.mobile : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-head my-3 f2015 clearfix"><span
              className="bg-white d-inline-block f-600 pr-3">Login Details</span></div>
            <div className="form-group px-2">
              <label className="mb-0">User Name</label>
              <div className="feild-text py-3 f-600 f2015">
                {user_detail ? user_detail.username : ""}
              </div>
            </div>
            <div className="form-group clearfix px-2">
              {user_detail.status != 0 ? <a className="color_blue py-3 underline f2015" onClick={() => this.sendResetPasswordLink(user_detail.email)}>Send Reset Password Link</a> : ""}
            </div>

          </div>
          <div className="col-lg-6">
            <div className="form-head mb-3 f2015  clearfix"><span
              className="bg-white d-inline-block f-600 pr-3">Organization Details</span></div>
            <div className="form-group px-2">
              <label className="mb-0">Organization Name</label>
              <div className="feild-text py-3 f-600 f2015">
                {orgName}
              </div>
            </div>
            <div className="form-group px-2">
              <label className="mb-0">User Role</label>
              <div className="feild-text py-3 f-600 f2015">
                {division_name ? user_detail ? user_detail.role_id == 2 ? "Company Admin" : user_detail.role_id == 3 ? "Sales Manager" : user_detail.role_id == 4 ? "Sales Rep" : "" : "" : "CEO"}
              </div>
            </div>
            {division_name ?
              <div className="form-group px-2">
                <label className="mb-0">Sales Division</label>
                <div className="feild-text py-3 f-600 f2015">
                  {division_name}
                </div>
              </div> : null}
            {division_manager && user_detail.role_id != 2 ?
              <div className="form-group px-2">
                <label className="mb-0">Division Manager</label>
                <div className="feild-text py-3 f-600 f2015">
                  {division_manager}
                </div>
              </div>
              : null}
            {sales_team && user_detail.role_id != 2 ?
              <div className="form-group px-2">
                <label className="mb-0">Sales Team</label>
                <div className="feild-text py-3 f-600 f2015">
                  {sales_team}
                </div>
              </div> : null}
            {user_detail ? user_detail.role_id == 4 ?
              <div className="form-group px-2">
                <label className="mb-0">Sales Manager</label>
                <div className="feild-text py-3 f-600 f2015">
                  {sales_manager_name}
                </div>
              </div> : null : null}

            {user_detail.role_id == 4 ?
              <div className="d-block color_blue text-right f2015 px-2 pb-2">
                <small className="iconmoon icon-edit2 f2015 editicon"></small>&nbsp;
                <a className="color_blue underline" id="closeProfile" onClick={() => this.setEditProfileShow(true, user_detail.id)}>Edit
                  Details   </a>
              </div> :
              ``}


          </div>
        </div>
      </form>
    </>

    );
  }

  setEditProfileShow = (newValue) => {
    this.setState({ editprofileShow: newValue });
    if (newValue === true) {
      this.setProfileShow(false);
    } else {
      // this.setProfileShow(true);

    }
    this.setState({
      Message: "",
      MessageType: '',
    });
  }
  handleEditSubmit = async () => {
    let postBodyData = {
      "first_name": this.state.user_detail.first_name,
      "last_name": this.state.user_detail.last_name,
      "email": this.state.user_detail.email,
      "phone": this.state.user_detail.mobile,
      "role_id": this.state.user_detail.role_id,
      "division_id": this.state.division_id,
      "team_id": this.state.team_id,
      "edit_user_id": this.state.selectedUserId
    }
    console.log("postBodyData", postBodyData);
    await axios.post(`users/add-manual`, postBodyData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      console.log(res);
      if (res.data.code === 200) {
        this.componentDidMount();
        this.setProfileShow(false);
        setTimeout(() => {
          this.setEditProfileShow(false);
        }, 3000);
        this.setState({
          Message: res.data.message,
          MessageType: 'success',
          fileextractDone: 0,
        });
      }
      else {
        this.setState({
          Message: res.data.message,
          MessageType: 'danger',
          fileextractDone: 0,
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })
  }
  handleChange(event) {
    console.log(event.target);
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
    switch (name) {
      case 'first_name':

        var pattern = new RegExp(/^[0-9a-zA-Z\s]*$/i);
        if (!pattern.test(value)) {
          this.setState({
            Message: "First name should be characters only.",
            MessageType: 'danger',
          });
        } else {
          this.setState({
            Message: "",
            MessageType: '',
          });
        }
        break;

      case 'last_name':
        var pattern = new RegExp(/^[0-9a-zA-Z\s]*$/i);
        if (!pattern.test(value)) {
          this.setState({
            Message: "Last name should be characters only.",
            MessageType: 'danger',
          });
        } else {
          this.setState({
            Message: "",
            MessageType: '',
          });

        }
        break;



      case 'email':
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(value)) {
          this.setState({
            Message: "Invalid Email",
            MessageType: 'danger',
          });
        } else {
          this.setState({
            Message: "",
            MessageType: '',
          });
          var user_name = value.split('@')[0];
          console.log("USERNAME", user_name);
          this.setState({ user_name: user_name });
        }
        break;

      case 'phone':

        var pattern = new RegExp(/^[0-9]{11,14}$/g);
        if (!pattern.test(value)) {
          this.setState({
            Message: "Phone number cannot be less than 11 digits and cannot exceed 14 digits.",
            MessageType: 'danger',
          });
        } else if ((value.length >= 15) || (value.length < 11)) {
          this.setState({
            Message: "Phone number cannot be less than 11 digits and cannot exceed 14 digits.",
            MessageType: 'danger',
          });

        } else {
          this.setState({
            Message: "",
            MessageType: '',
          });
        }
        break;

      default:
        break;
    }
    console.log("FORM DATA", this.state);
  }

  editHandleChange(event) {
    console.log(event.target);
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ edit_user_detail: { [name]: value } });
    switch (name) {
      case 'email':
        var user_name = value.split('@')[0];
        this.setState({ user_name: user_name });
        break;


      default:
        break;
    }
    console.log("FORM DATA", this.state);
  }

  handleRoleClick = (ele) => {
    this.setState({ role_id: ele });

    if (ele == 4) {
      this.setState({ showTeamOption: true, showDivisionOption: true, showTeamManager: true });
    } else if (ele == 3) {
      this.setState({ showTeamOption: true, showDivisionOption: true, showTeamManager: false });
      this.setState({ team_id: "", defaultteamSelectText: "Select Team", sales_manager: "" });
    } else if (ele == 2) {
      this.setState({ showTeamOption: false, showDivisionOption: false, showTeamManager: false });
      this.setState({ team_id: "", defaultteamSelectText: "Select Team", sales_manager: "" });
    }
  };

  handleDivisionClick = async (ele) => {
    var div_list = this.state.divisionList;
    this.setState({ division_id: ele });
    for (var j = 0; j < div_list.length; j++) {
      if (ele === div_list[j].id) {
        this.setState({ division_manager: div_list[j].user_name });
      }
    }


    var org_structure_data = this.state.organization_structure;
    for (var i = 0; i < org_structure_data.length; i++) {
      if (org_structure_data[0]) {
        let divisions = org_structure_data[0].child;

        for (var i = 0; i < divisions.length; i++) {
          if (divisions[i].id == ele) {
            var team_data = divisions[i].child;
            var team_list = [];
            console.log("@TEAM DATA", team_data);

            for (var k = 0; k < team_data.length; k++) {

              var obj1 = {};
              obj1.id = team_data[k].id
              obj1.name = team_data[k].node_name;
              obj1.value = team_data[k].id;
              if (team_data[k].user.length > 0) {
                obj1.user_name = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                if (k == 0) {
                  // this.setState({ defaultteamSelectText: obj1.name });
                  this.setState({ defaultteamSelectText: "Select Team" });
                  var sales_manager = "";
                  if (team_data[k].user[0]) {
                    sales_manager = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                    // this.setState({ sales_manager: sales_manager });
                    this.setState({ sales_manager: "" });
                  }
                }
              }
              team_list.push(obj1);
            }
            this.setState({ teamList: team_list });

          }

        }

      }
    }


  }
  handleTeamClick = (ele) => {
    this.setState({ team_id: ele });
    // this.setState({ sales_manager: "TEAM MANAGER " + ele });


    var org_structure_data = this.state.organization_structure;
    for (var i = 0; i < org_structure_data.length; i++) {
      if (org_structure_data[0]) {
        let divisions = org_structure_data[0].child;

        console.log("Division ADAT", divisions);
        for (var j = 0; j < divisions.length; j++) {
          var team_data = divisions[j].child;
          console.log("TEAM ADAT", team_data);
          for (var k = 0; k < team_data.length; k++) {
            console.log("team _data ele", team_data[k]); console.log("elee", ele);
            if (team_data[k].id === ele) {
              var sales_manager = "";
              if (team_data[k].user[0]) {
                sales_manager = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                //set sales manager 
                this.setState({ sales_manager: sales_manager });
              } else {
                this.setState({ sales_manager: "" });
              }
            }
          }
        }

      }
    }
  }


  edituserProfile = () => {



    const { user_org_detail, user_detail, profileImg, orgName, showTeamOption, showDivisionOption, showTeamManager, first_name, last_name, email, phone, user_name, role_id, division_id, division_manager, team_id, sales_manager, Message, MessageType, defaultdivisionSelectText, stored_division_manager, stored_sales_manager } = this.state;
    console.log("DIVISION MANAGER", division_manager);
    console.log("SALES MANAGER", sales_manager);
    console.log("user_org_detail", user_org_detail);
    var division_name = "";
    // var division_manager = "";
    var sales_team = "";
    var sales_manager_name = "";
    var division_manager_name = "";
    if (user_org_detail) {
      if (user_org_detail.hasOwnProperty("division")) {
        division_name = user_org_detail.division.node_name;
      }
      if (user_org_detail.hasOwnProperty("division_manager") && user_org_detail.division_manager.length > 0) {
        division_manager_name = user_org_detail.division_manager[0].first_name + " " + user_org_detail.division_manager[0].last_name;
      }

      if (user_org_detail.hasOwnProperty("team") && user_org_detail.team.user.length > 0) {
        sales_team = user_org_detail.team.node_name;
        sales_manager_name = user_org_detail.team.user[0].first_name + " " + user_org_detail.team.user[0].last_name;
      }
    }

    return (
      <form>
        {((Message) && (Message !== null)) ?
          (
            <div className={`alert alert-${MessageType}`}>
              {Message}
            </div>
          ) : (``)
        }
        <div className="row">
          <div className="col-lg-6">
            <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Personal
              Details</span></div>
            <div className="clearfix px-2">
              <div className="profile-pic float-sm-right">
                <div className="img-wrap">
                  <img className="profile-pic-img" id="editprofile" src={profileImg} alt="profile" />
                </div>
              </div>
              <div className="profile-info float-sm-left">
                <div className="form-group">
                  <label className="mb-0">Full Name</label>

                  <div className="feild-text word-break-all py-3 f-600 f2015">
                    {user_detail ? user_detail.first_name + " " + user_detail.last_name : ""}
                  </div>

                </div>
                <div className="form-group">
                  <label className="mb-0">Email ID</label>
                  <div className="feild-text py-3 f-600 f2015">

                    {user_detail ? user_detail.email : ""}
                  </div>
                </div>
                <div className="form-group">
                  <label className="mb-0">Mobile Number</label>
                  <div className="feild-text py-3 f-600 f2015">
                    {user_detail ? user_detail.mobile : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-head  f2015 my-3 clearfix" ><span
              className="bg-white d-inline-block f-600 pr-3">Login Details</span></div>
            <div className="form-group px-2">
              <label className="mb-0">User Name</label>
              <div className="feild-text py-3 f-600 f2015">
                {user_detail ? user_detail.username : ""}
              </div>
            </div>
            <div className="form-group clearfix px-2">
              {user_detail.status != 0 ? <a className="color_blue py-3 underline f2015" onClick={() => this.sendResetPasswordLink(user_detail.email)}>Send Reset Password Link</a> : ""}

              {/* <a className="color_blue py-3 underline f2015" onClick={() => this.sendResetPasswordLink(user_detail.email)}>Send Reset Password Link</a> */}
            </div>

          </div>
          <div className="col-lg-6">
            <div className="form-head mb-3 f2015  clearfix"><span
              className="bg-white d-inline-block f-600 pr-3">Organization Details</span></div>
            <div className="form-group px-2">
              <label className="mb-0">Organization Name</label>
              <div className="feild-text py-3 f-600 f2015">
                {orgName}
              </div>
            </div>
            <div className="form-group px-2">
              <label className="mb-0">User Role</label>
              <div className="feild-text py-3 f-600 f2015">
                {user_detail.role_id == 2 ? "Company Admin" : user_detail.role_id == 3 ? "Sales Manager" : user_detail.role_id == 4 ? "Sales Rep" : ""}
              </div>
            </div>

            <div className="form-group px-2">
              <label className="mb-0">Sales Division<span className="color_red"> *</span></label>
              <CustomSelect
                className="form-control f-600 text-left"
                defaultText={division_name}
                optionsList={this.state.divisionList}
                onSelect={this.handleDivisionClick}


              />
            </div>

            {(showDivisionOption) ? (<>
              <div className="form-group px-2">
                <label className="mb-0">Division Manager</label>
                <div className="feild-text py-3 f-600 f2015">
                  {division_manager_name == "" ? stored_division_manager : division_manager_name}

                </div>
              </div>
            </>) : null}

            {showTeamOption ? <>
              <div className="form-group px-2">
                <label className="mb-0">Sales Team<span className="color_red"> *</span></label>
                <CustomSelect
                  className="form-control f-600 text-left"
                  defaultText={sales_team}
                  optionsList={this.state.teamList}
                  onSelect={this.handleTeamClick}

                />
              </div>
            </> : null}

            {(showTeamManager) ? <>
              <div className="form-group px-2">
                <label className="mb-0">Sales Manager</label>
                <div className="feild-text py-3 f-600 f2015">
                  {sales_manager_name == "" ? stored_sales_manager : sales_manager_name}
                </div>
              </div>
            </> : null}
          </div>
        </div>
        <div className="modal-footer border-0 justify-content-center pt-0">
          <button type="button" className="btn dark-btn mx-2" onClick={() => this.handleEditSubmit()}>Save</button>
          <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setEditProfileShow(false)}>Cancel</button>
        </div>
      </form>
    );
  }


  deactivateText = e => {
    console.log(e.target.value);
    if (((e.target.value).toLowerCase() === "deactivate")) {
      $('.' + e.target.dataset.action).prop("disabled", false);
      this.setState({ deactivateText: false })
    } else {
      $('.' + e.target.dataset.action).prop("disabled", true);
      this.setState({ deactivateText: true })
    }
  };

  formatPhoneNumber = (str) => {
    if (str !== null && str !== "" && str.length >= 10) {
      var newArray = str.match
        (/^([0-9]{1,3})?(\d{3})(\d{3})(\d{4})$/);

      // Checking the international code
      var intlCountryCode = (newArray[1] ? '+' + newArray[1] : '');

      // Resolving the above array we get
      // the international number
      var internationalNumber = intlCountryCode +
        '-' + newArray[2] + '-' + newArray[3]
        + '-' + newArray[4];

      this.setState({ formatedMobileNo: internationalNumber })

      return null
    } else {
      return null
    }
  };

  getProfileImageByPath = (image) => {
    var user_image = process.env.REACT_APP_STATIC_FILES_PATH + '/' + ((image) ? image : 'default-avatar.png');
    return user_image
  };


  render() {
    const { classes } = this.props;
    const { orgName, popuptitle, profileImg, profileShow, editprofileShow, resetLinkSendshow,
      resetLinkSendSuccessshow, deactivateUsershow, orgHeadEditShow,
      addDivisionShow, orgTargetShow, editDivisionShow, addTeamShow,
      editTeamShow, deactivateDivisionshow, deactivateTeamshow,
      divisionTargetShow, teamTargetShow
      , node_name, MessageNewTeam, MessageNewTeamType, MessageNewDiv, MessageNewDivType, TargetsTitle } = this.state
    console.log("ORGANIZATION DATA : ", this.state.Organizations);
    // const allOrganizations = this.state.Organizations.map(organization => {
    //   console.log(organization.node_name);
    // })

    return (<>
      <Modal
        title="User Profile"
        contentClasses="viewProfile"
        size="xl"
        onClose={() => this.setProfileShow(false)}
        show={profileShow}>
        {this.userProfile()}
      </Modal>
      <Modal
        title="Edit User Profile"
        contentClasses="editProfile"
        size="xl"
        onClose={() => this.setEditProfileShow(false)}
        show={editprofileShow}>
        {this.edituserProfile()}
      </Modal>

      <Modal
        title=""
        contentClasses="py-3 py-lg-5"
        size="md"
        onClose={() => this.setResetLinkSend(false)}
        show={resetLinkSendshow}>
        {this.resetLinkSend()}
      </Modal>

      <Modal
        title=""
        contentClasses="py-3 py-lg-5"
        size="md"
        onClose={() => this.setResetLinkSendSuccess(false)}
        show={resetLinkSendSuccessshow}>
        {this.resetLinkSendSuccess()}
      </Modal>

      <Modal
        title=""
        contentClasses="py-3 py-lg-5"
        size="md"
        onClose={() => this.setDeactivateUserShow(false)}
        show={deactivateUsershow}>
        {this.userDeactivate()}
      </Modal>

      <Modal
        title="Edit Organization Head"
        contentClasses=""
        size="md"
        onClose={() => this.setOrgHeadEditShow(false)}
        show={orgHeadEditShow}>
        {this.orgHeadEdit()}
      </Modal>

      <Modal
        title="Add Division"
        contentClasses=""
        size="md"
        onClose={() => this.setAddDivisionShow(false)}
        show={addDivisionShow}>
        {this.addDivision()}
      </Modal>

      <Modal
        title="Organization Targets"
        contentClasses=""
        size="md"
        onClose={() => this.setOrgTargetShow(false)}
        show={orgTargetShow}>
        {this.orgTarget()}
      </Modal>

      <Modal
        title={popuptitle}
        contentClasses=""
        size="md"
        onClose={() => this.setEditDivisionShow(false)}
        show={editDivisionShow}>
        {this.editDivision()}
      </Modal>

      <Modal
        title="Add New Team"
        contentClasses=""
        size="md"
        onClose={() => this.setAddTeamShow(false)}
        show={addTeamShow}>
        {this.addTeam()}
      </Modal>

      <Modal
        title="Edit Team"
        contentClasses=""
        size="md"
        onClose={() => this.setEditTeamShow(false)}
        show={editTeamShow}>
        {this.editTeam()}
      </Modal>

      <Modal
        title=""
        contentClasses="py-3 py-md-5"
        size="lg"
        onClose={() => this.setDeactivateDivisionShow(false)}
        show={deactivateDivisionshow}>
        {this.divisionDeactivate()}
      </Modal>

      <Modal
        title=""
        contentClasses="py-3 py-md-5"
        size="lg"
        onClose={() => this.setDeactivateTeamShow(false)}
        show={deactivateTeamshow}>
        {this.teamDeactivate()}
      </Modal>

      <Modal
        title="Division Targets"
        contentClasses=""
        size="md"
        onClose={() => this.setDivisionTargetShow(false)}
        show={divisionTargetShow}>
        {this.divisionTarget()}
      </Modal>

      <Modal
        title={TargetsTitle}
        contentClasses=""
        size="md"
        onClose={() => this.setTeamTargetShow(false)}
        show={teamTargetShow}>
        {this.teamTarget()}
      </Modal>

      <main className="main-contant">
        <div className="contents">
          <h3 className="title-head mt-0 mb-4">{orgName}</h3>
          <div className="accordion" id="accordionTable">
            {this.state.Organizations.map((organization) => {
              // if (organizationDivision.status === "1") {
              return (
                <div>
                  <div className="table-wrap p-3">
                    <div className="table-row d-sm-flex justify-content-between align-items-center">
                      <div className="org-name f2015">
                        Organization Head
                      </div>
                      <div className="profile-card-wrap d-flex justify-content-center align-items-center">

                        {organization.user.length > 0 && (organization.user[0].status !== "0") ?
                          <div className="profile-card flex-wrap d-flex align-items-center">
                            <div className="img-wrap mr-3">
                              <img src={this.getProfileImageByPath(organization.user[0].profile_pic_url)} alt="profile" />

                            </div>
                            <div className="profile-info">
                              <div className="name f-500">
                                {organization.user[0].first_name + ' ' + organization.user[0].last_name}
                              </div>
                              <div className="designation">
                                {organization.node_name}
                              </div>
                            </div>
                            <i className="ri-more-fill f-600 show-profile-action"></i>
                          </div>
                          :
                          <div className="profile-card flex-wrap d-flex align-items-center flex-wrap pr-3">
                            <div className="img-wrap mr-3 empty-img ">
                            </div>
                            <div className="profile-info">
                              <div className="name f-500">
                                <div class="add-profile-btn color_blue underline" onClick={() => this.setEditDivisionShow(true, organization, organization.node_name)}>
                                  Add Organization Head
                                </div>
                              </div>
                            </div>
                          </div>
                        }


                        <div className="profile-action">
                          <ul className="list-unstyled mb-0">
                            <li><a className="text-white" onClick={() => this.setProfileShow(true, organization.user[0].id)}>
                              <i className="iconmoon icon-viewprofile"></i> View
                              Profile</a>
                            </li>
                            <li>
                              <a className="text-white" onClick={() => this.setDeactivateUserShow(true, organization.user[0])}>
                                <i className="iconmoon icon-deactivate"></i> Deactivate User</a>
                            </li>
                            <li className=" hide-profile-action"><a className="text-white"><i
                              className="iconmoon icon-close"></i> Close</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="actions">
                        <div className="dropdown">
                          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </button>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" onClick={() => this.setEditDivisionShow(true, organization, organization.node_name)}>
                              <i className="iconmoon icon-edit1"></i> Edit</a>
                            <a className="dropdown-item" onClick={() => this.setAddDivisionShow(true, organization.id)}>
                              <i className="iconmoon icon-add"></i> Add New Division</a>
                            <a className="dropdown-item" onClick={() => this.setTeamTargetShow(true, organization.id, 'Org Targets')}>
                              <i className="iconmoon icon-org-target"></i> Org Targets</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {organization.child.map(organizationDivision => {

                    if (organizationDivision.status === "1") {
                      return (

                        <div>
                          <div className="table-wrap p-3">
                            <div className="table-row d-sm-flex justify-content-between align-items-center" id={`heading${organizationDivision.id}`}>
                              <div className="org-name f2015">
                                {organizationDivision.node_name}
                              </div>
                              {organizationDivision.user.length > 0 ?
                                organizationDivision.user.map(organizationUser => {
                                  return (

                                    <div>
                                      <div className="profile-card-wrap d-flex justify-content-center align-items-center">


                                        {(organizationUser.status !== "0") ? <div className="profile-card flex-wrap d-flex align-items-center">
                                          <div className="img-wrap mr-3">
                                            <img src={this.getProfileImageByPath(organizationUser.profile_pic_url)} alt="profile" />
                                          </div>

                                          <div className="profile-info">
                                            <div className="name f-500 text-capitalize">
                                              {organizationUser.first_name} {organizationUser.last_name}
                                            </div>
                                            <div className="designation">
                                              Division Manager
                                            </div>
                                          </div>
                                          <i className="ri-more-fill f-600 show-profile-action"></i>
                                        </div> :
                                          <div className="profile-card flex-wrap d-flex align-items-center flex-wrap pr-3">
                                            <div className="img-wrap mr-3 empty-img ">
                                            </div>
                                            <div className="profile-info">
                                              <div className="name f-500">
                                                <div class="add-profile-btn color_blue underline" onClick={() => this.setEditDivisionShow(true, organizationDivision, 'Edit Division')}>
                                                  Add Division Manager
                                                </div>
                                              </div>
                                            </div>
                                          </div>}




                                        <div className="profile-action">
                                          <ul className="list-unstyled mb-0">
                                            <li><a className="text-white" onClick={() => this.setProfileShow(true, organizationUser.id)}>
                                              <i className="iconmoon icon-viewprofile"></i> View
                                              Profile</a></li>
                                            <li><a className="text-white" onClick={() => this.setDeactivateUserShow(true, organizationUser)}>
                                              <i className="iconmoon icon-deactivate"></i> Deactivate
                                              User</a></li>
                                            <li className="hide-profile-action"><a className="text-white"><i
                                              className="iconmoon icon-close"></i> Close</a></li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }) :
                                <div>
                                  <div className="profile-card-wrap d-flex justify-content-center align-items-center">

                                    <div className="profile-card flex-wrap d-flex align-items-center flex-wrap pr-3">
                                      <div className="img-wrap mr-3 empty-img ">
                                      </div>
                                      <div className="profile-info">
                                        <div className="name f-500">
                                          <div class="add-profile-btn color_blue underline" onClick={() => this.setEditDivisionShow(true, organizationDivision, 'Edit Division')}>
                                            Add Division Manager
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }


                              <div className="actions">
                                <div className="dropdown">
                                  <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                  </button>
                                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" onClick={() => this.setEditDivisionShow(true, organizationDivision, 'Edit Division')}>
                                      <i className="iconmoon icon-edit1"></i> Edit Division</a>
                                    <a className="dropdown-item" onClick={() => this.setDeactivateDivisionShow(true, organizationDivision.node_name, organizationDivision.id)}>
                                      <i className="iconmoon icon-deactivate-division"></i> Deactivate Division</a>
                                    <a className="dropdown-item" onClick={() => this.setAddTeamShow(true, organizationDivision.id)}>
                                      <i className="iconmoon icon-add"></i> Add New Team</a>
                                    <a className="dropdown-item" onClick={() => this.setTeamTargetShow(true, organizationDivision.id, 'Division Targets')}>
                                      <i className="iconmoon icon-org-target"></i> Division Targets</a>
                                  </div>
                                </div>
                                <button className="dropdown-toggle" type="button" data-toggle="collapse" data-target={`#collapse${organizationDivision.id}`}
                                  aria-expanded="false" aria-controls={`collapse${organizationDivision.id}`}>
                                  <i className="iconmoon icon-chevron-down"></i>
                                </button>
                              </div>
                            </div>

                            <div id={`collapse${organizationDivision.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionTable">
                              {organizationDivision.child.map(divisionsTeam => {

                                if (divisionsTeam.status === '1') {
                                  return (
                                    <div>
                                      <div className="team-row my-3 p-4">
                                        <div className="table-row d-flex justify-content-between" id="headingOne">
                                          <h3 className="title-head m-0">{divisionsTeam.node_name}</h3>
                                          <div className="actions">
                                            <div className="dropdown">
                                              <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <FontAwesomeIcon icon={faEllipsisV} />
                                              </button>
                                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" onClick={() => this.setEditTeamShow(true, divisionsTeam)}>
                                                  <i className="iconmoon icon-edit1"></i> Edit Team</a>
                                                <a className="dropdown-item" onClick={() => this.setDeactivateTeamShow(true, divisionsTeam.node_name, divisionsTeam.id)}>
                                                  <i className="iconmoon icon-deactivate-division"></i> Deactivate Team</a>
                                                <a className="dropdown-item" onClick={() => this.setTeamTargetShow(true, divisionsTeam.id, 'Team Targets')}>
                                                  <i className="iconmoon icon-org-target"></i> Team Targets</a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="team-member-row mt-2 mt-lg-2">
                                          <div className="d-flex justify-content-center align-items-center">
                                            {divisionsTeam.user.length > 0 ? divisionsTeam.user.map(divisionsUser => {
                                              return (
                                                <div>
                                                  <div className="profile-card-wrap d-flex justify-content-center align-items-center">

                                                    {(divisionsUser.status !== "0") ?
                                                      <div className="profile-card flex-wrap d-flex align-items-center">
                                                        <div className="img-wrap mr-3">
                                                          <img src={this.getProfileImageByPath(divisionsUser.profile_pic_url)} alt="profile" />
                                                        </div>
                                                        <div className="profile-info">
                                                          <div className="name f-500 text-capitalize">
                                                            {divisionsUser.first_name} {divisionsUser.last_name}
                                                          </div>
                                                          <div className="designation">
                                                            {(divisionsUser.role_id === 3) ? (`Sales Manager`) : ((divisionsUser.role_id === 4) ? (`Sales Rep`) : (`NA`))}
                                                          </div>
                                                        </div>
                                                        <i className="ri-more-fill f-600 show-profile-action"></i>
                                                      </div> :
                                                      <div className="profile-card flex-wrap d-flex align-items-center flex-wrap pr-3">
                                                        <div className="img-wrap mr-3 empty-img ">
                                                        </div>
                                                        <div className="profile-info">
                                                          <div className="name f-500">
                                                            <div class="add-profile-btn color_blue underline" onClick={() => this.setEditTeamShow(true, divisionsTeam)}>
                                                              Add Sales Manager
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>}


                                                    <div className="profile-action">
                                                      <ul className="list-unstyled mb-0">
                                                        <li><a className="text-white" onClick={() => this.setProfileShow(true, divisionsUser.id)}>
                                                          <i className="iconmoon icon-viewprofile"></i> View
                                                          Profile</a></li>
                                                        <li><a className="text-white" onClick={() => this.setDeactivateUserShow(true, divisionsUser)}>
                                                          <i className="iconmoon icon-deactivate"></i> Deactivate
                                                          User</a>
                                                        </li>
                                                        <li className="hide-profile-action"><a className="text-white"><i
                                                          className="iconmoon icon-close"></i>
                                                          Close</a></li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            }) :
                                              <div>
                                                <div className="profile-card-wrap d-flex justify-content-center align-items-center">

                                                  <div className="profile-card flex-wrap d-flex align-items-center flex-wrap pr-3">
                                                    <div className="img-wrap mr-3 empty-img ">
                                                    </div>
                                                    <div className="profile-info">
                                                      <div className="name f-500">
                                                        <div class="add-profile-btn color_blue underline" onClick={() => this.setEditTeamShow(true, divisionsTeam)}>
                                                          Add Sales Manager
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            }
                                          </div>
                                          <div className="py-3 d-flex flex-wrap justify-content-center align-items-center inner-row">
                                            {divisionsTeam.users.map(divisionsUsers => {
                                              return (
                                                <div>
                                                  {(divisionsUsers.status !== "0") ?
                                                    <div className="profile-card-wrap m-2 d-flex justify-content-center align-items-center">
                                                      <div className="profile-card flex-wrap d-flex align-items-center">
                                                        <div className="img-wrap mr-3">
                                                          {/* <img src={profileImg} alt="profile" /> */}
                                                          <img src={this.getProfileImageByPath(divisionsUsers.profile_pic_url)} alt="profile" />
                                                        </div>
                                                        <div className="profile-info">
                                                          <div className="name f-500 text-capitalize">
                                                            {divisionsUsers.first_name} {divisionsUsers.last_name}
                                                          </div>
                                                          <div className="designation">
                                                            {(divisionsUsers.role_id === 3) ? (`Sales Manager`) : ((divisionsUsers.role_id === 4) ? (`Sales Rep`) : (`NA`))}
                                                          </div>
                                                        </div>
                                                        <i className="ri-more-fill f-600 show-profile-action"></i>
                                                      </div>
                                                      <div className="profile-action">
                                                        <ul className="list-unstyled mb-0">
                                                          <li><a className="text-white" onClick={() => this.setProfileShow(true, divisionsUsers.id)}>
                                                            <i className="iconmoon icon-viewprofile"></i>   View
                                                            Profile</a></li>
                                                          <li>
                                                            <a className="text-white" onClick={() => this.setDeactivateUserShow(true, divisionsUsers)}>
                                                              <i className="iconmoon icon-deactivate"></i> Deactivate
                                                              User</a></li>
                                                          <li className="hide-profile-action"><a className="text-white"><i
                                                            className="iconmoon icon-close"></i>
                                                            Close</a></li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                    : null}
                                                </div>
                                              )
                                            })}

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                } // if team status is 1.

                              })}

                            </div>
                          </div>
                          <hr />
                        </div>
                      )
                    }
                    // if division status is 1.

                  })}
                </div>
              )
              // }
            }



            )}






          </div>

          <Link to={`./inactive-organization-structure`} className="color_blue py-4 underline f2015" > Inactive Divisions and Teams</Link>
        </div>

      </main>

    </>
    )
    // })
  }
}
