import React, { Component } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Modal from './modals/modal';
import { Link } from "react-router-dom";
import axios from '../apiConnection/axios';

export default class InactiveOrganizationStructure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reactivateDivisionshow: false,
            reactivateTeamshow: false,
            selectedDivisionName: "",
            selectedTeamName: "",
            selectedTeamId: "",
            selectedDivisionId: "",
            inactiveOrganizations: [],
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getInactiveOrganizations();
    }


    getInactiveOrganizations = async () => {
        await axios.get(`organizations/get-inactive-organizaion-structure`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    this.setState({
                        inactiveOrganizations: res.data.data,
                    })
                    console.log(this.state.inactiveOrganizations)

                }

            })
            .catch((error) => {
                console.log(error)
            })
    }


    UpdateDivisionStatus = async () => {
        const payload = {
            org_structure_id: this.state.selectedDivisionId,
            status: 1,
        }
        await axios.post(`organizations/update-organization-structure-status`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    this.setState({ MessageDeactivateDivision: "Division reactivated successfully.", MessageDeactivateDivisionType: "success" });
                    setTimeout(() => {
                        this.setState({ MessageDeactivateDivision: null, MessageDeactivateDivisionType: "" });
                        this.setReactivateDivisionShow(false);
                        this.componentDidMount();
                    }, 2000);


                } else {
                    this({
                        MessageDeactivateDivision: "Error in update status.",
                        MessageDeactivateDivisionType: "danger"
                    })
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }


    setReactivateDivisionShow = (newValue, selectedDivisionName, selectedDivisionId) => {
        this.setState({ reactivateDivisionshow: newValue, selectedDivisionName, selectedDivisionId });
    }
    divisionReactivate = () => {
        const { MessageDeactivateDivision, MessageDeactivateDivisionType } = this.state;
        return (<>
            {((MessageDeactivateDivision) && (MessageDeactivateDivision !== null)) ?
                (
                    <div className={`alert alert-${MessageDeactivateDivisionType}`}>
                        {MessageDeactivateDivision}
                    </div>
                ) : (``)}
            <form className="my-3">
                <div className="text-center mx-3">
                    <p className="m-0"> Reactivating this division will reactivate all the teams and members. <br />
                        <span className="d-block mb-3 mb-xl-3"></span>
                        Are you sure you want to reactivate the division <span className="f-600"> {this.state.selectedDivisionName}?
                        </span>
                    </p>
                    <div className="modal-footer border-0 mb-2 justify-content-center">
                        <button type="button" className="btn dark-btn mx-2" onClick={() => this.UpdateDivisionStatus()}>Confirm</button>
                        <button type="button" className="btn light-btn mx-2" onClick={() => this.setReactivateDivisionShow(false)} >Cancel</button>
                    </div>
                </div>
            </form>

        </>);
    }


    UpdateTeamStatus = async () => {
        const payload = {
            org_structure_id: this.state.selectedTeamId,
            status: 1,
        }
        await axios.post(`organizations/update-organization-structure-status`, payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    this.setState({ MessageDeactivateTeam: "Team reactivated successfully.", MessageDeactivateTeamType: "success" });
                    setTimeout(() => {
                        this.setState({ MessageDeactivateTeam: null, MessageDeactivateTeamType: "" });
                        this.setReactivateTeamShow(false);
                        this.componentDidMount();
                    }, 2000);


                } else {
                    this({
                        MessageDeactivateTeam: "Error in update status.",
                        MessageDeactivateTeamType: "danger"
                    })
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    setReactivateTeamShow = (newValue, selectedTeamName, selectedTeamId) => {
        this.setState({ reactivateTeamshow: newValue, selectedTeamName, selectedTeamId });
    }

    teamReactivate = () => {
        const { MessageDeactivateTeam, MessageDeactivateTeamType } = this.state;
        return (<>
            {((MessageDeactivateTeam) && (MessageDeactivateTeam !== null)) ?
                (
                    <div className={`alert alert-${MessageDeactivateTeamType}`}>
                        {MessageDeactivateTeam}
                    </div>
                ) : (``)}
            <form className="my-3">
                <div className="text-center mx-3">
                    <p className="m-0"> Reactivating this team will reactivate all the members.
                        <span className="d-block mb-3 mb-lg-3"></span>
                        Are you sure you want to reactivate the team <span className="f-600"> {this.state.selectedTeamName}?</span>
                    </p>
                    <div className="modal-footer border-0 mt-4 justify-content-center">
                        <button type="button" className="btn dark-btn mx-2" onClick={() => this.UpdateTeamStatus()}>Confirm</button>
                        <button type="button" className="btn light-btn mx-2" onClick={() => this.setReactivateTeamShow(false)} >Cancel</button>
                    </div>
                </div>
            </form>
        </>);
    }



    render() {
        const { reactivateDivisionshow, reactivateTeamshow } = this.state


        return (<>

            <Modal
                title=""
                contentClasses="py-3 py-lg-5"
                size="md"
                onClose={() => this.setReactivateDivisionShow(false)}
                show={reactivateDivisionshow}>
                {this.divisionReactivate()}
            </Modal>

            <Modal
                title=""
                contentClasses="py-3 py-lg-5"
                size="md"
                onClose={() => this.setReactivateTeamShow(false)}
                show={reactivateTeamshow}>
                {this.teamReactivate()}
            </Modal>

            <main className="main-contant">
                <div className="contents">
                    <h3 className="title-head mt-0 text-center mb-lg-4 mb-3 ">Inactive Divisions and Teams</h3>

                    <Link className="mt-0 mb-3 d-block go-back d-flex align-items-center" to={`./organization-structure`}>
                        <i className="iconmoon icon-chevron-left"></i> <span className="d-none d-sm-inline-block"> Go Back </span></Link>


                    <div className="accordion" id="accordionTable">
                        {this.state.inactiveOrganizations.map((inactiveOrganization) =>


                            <div>
                                {inactiveOrganization.division.map((OrganizationDivision) => {
                                    if (OrganizationDivision.show === "yes") {
                                        return (
                                            <div>
                                                <div className={(OrganizationDivision.status === "1") ? `table-wrap  p-3` : `table-wrap  p-3 deactivated`}>
                                                    <div className="table-row d-sm-flex justify-content-between align-items-center" id={`heading${OrganizationDivision.id}`}>
                                                        <div className="org-name f2015 w-auto f-600">
                                                            {OrganizationDivision.node_name} <span className="f-300">{(OrganizationDivision.status === "1") ? `(Active)` : `(Inactive)`}</span>
                                                        </div>

                                                        <div className="actions">
                                                            {(OrganizationDivision.status !== "1") ?
                                                                (

                                                                    <div className="dropdown">
                                                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <FontAwesomeIcon icon={faEllipsisV} />
                                                                        </button>
                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                            <a className="dropdown-item" onClick={() => this.setReactivateDivisionShow(true, OrganizationDivision.node_name, OrganizationDivision.id)}>
                                                                                <i className="fa fa-history mr-1"></i> Reactivate Division</a>
                                                                        </div>
                                                                    </div>
                                                                ) : (``)}
                                                            <button className="dropdown-toggle" type="button" data-toggle="collapse" data-target={`#collapse${OrganizationDivision.id}`}
                                                                aria-expanded="false" aria-controls={`collapse${OrganizationDivision.id}`}>
                                                                <i className="iconmoon icon-chevron-down"></i>
                                                            </button>
                                                        </div>

                                                    </div>
                                                    <div id={`collapse${OrganizationDivision.id}`} className="collapse" aria-labelledby="headingOne"
                                                        data-parent="#accordionTable">
                                                        {OrganizationDivision.team.map((OrganizationTeams) =>
                                                            <div className="team-row my-3 p-4">
                                                                <div>
                                                                    <div className="table-row d-flex justify-content-between align-items-center" id="headingOne">
                                                                        <div className="org-name f2015 w-auto f-600">
                                                                            {OrganizationTeams.node_name} <span className="f-300">(Inactive)</span>
                                                                        </div>
                                                                        {((OrganizationDivision.status === "1") && (OrganizationTeams.status !== "1")) ?
                                                                            (
                                                                                <div className="actions">
                                                                                    <div className="dropdown">
                                                                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                                                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            <FontAwesomeIcon icon={faEllipsisV} />
                                                                                        </button>
                                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                            <a className="dropdown-item" onClick={() => this.setReactivateTeamShow(true, OrganizationTeams.node_name, OrganizationTeams.id)}>
                                                                                                <i className="fa fa-history mr-1"></i>Reactivate team</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            :
                                                                            (``)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        )
                                    }
                                }
                                )}
                            </div>

                        )}

                    </div>
                </div>
            </main>


        </>
        )
    }
}
