import React, { Component } from 'react';
import axios from './apiConnection/axios';
import Utility from "./utility";
const utilityObj = new Utility;

export default class NylasAuth extends React.Component {
    componentDidMount() {
        utilityObj.loaderView("show");
        this.getToken();
    }

    getToken = async () =>{
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());   
        console.log(params);     
        const payload = {
            "user_id" : params.state,
            "access_token" : params.access_token,
            "account_id" : params.account_id,
            "email_address" : params.email_address,
            "namespace_id" : params.namespace_id,
            "provider" : params.provider,
            "sid" : params.sid,
            "state" : params.state
        }    
            await axios.post(`/nylas/store-token`, payload, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                }
            })
                .then(res => {
                    // alert(process.env.REACT_APP_PROFILE_PAGE_URL);
                        // window.location.href = process.env.REACT_APP_PROFILE_PAGE_URL;
                        this.getZoomIntegration()
                })
                .catch((error) => {
                    console.log(error)
                })

    }
    getZoomIntegration = async () => {
        const BaseURL = `${process.env.REACT_APP_NYLAS_URL_ZOOM}`;
        const payload = {
                "name": "Test App",
                "provider": "zoom",
                "settings": {
                  "client_id": `${process.env.REACT_APP_ZOOM_APP_CLIENT_ID}`,
                  "client_secret": `${process.env.REACT_APP_ZOOM_APP_CLIENT_SECRET}`
                },
                "redirect_uris": [
                    `${process.env.REACT_APP_ZOOM_REDIRECT_URL}`,
                    `${process.env.REACT_APP_ZOOM_REDIRECT_URL1}`,
                    `${process.env.REACT_APP_ZOOM_REDIRECT_URL2}`,
                ],
                "expires_in": 1209600
        }    
        const headers = {
            'Authorization' : `Basic ${process.env.REACT_APP_ZOOM_API_AUTH}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        }
            await axios.post(`${BaseURL}/connect/integrations`, payload, {
                headers,
            })
                .then((res) => {
                    console.log(res);
                    this.getZoomIntegrationStep1();
                })
                .catch((response) => {
                    console.log(response);
                    this.getZoomIntegrationStep1();
                })
    }
    getZoomIntegrationStep1 = async () => {
        const BaseURL = `${process.env.REACT_APP_NYLAS_URL_ZOOM}`;
        const payload = {
                "name": "Test App",
                "provider": "zoom",
                "settings": {
                  "client_id": `${process.env.REACT_APP_ZOOM_APP_CLIENT_ID}`,
                  "client_secret": `${process.env.REACT_APP_ZOOM_APP_CLIENT_SECRET}`
                },
                "redirect_uris": [
                    `${process.env.REACT_APP_ZOOM_REDIRECT_URL}`,
                    `${process.env.REACT_APP_ZOOM_REDIRECT_URL1}`,
                    `${process.env.REACT_APP_ZOOM_REDIRECT_URL2}`,
                ],
                "expires_in": 1209600
        }    
        const headers = {
            'Authorization' : `Basic ${process.env.REACT_APP_ZOOM_API_AUTH}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        }
            await axios.patch(`${BaseURL}/connect/integrations/zoom`, payload, {
                headers,
            })
                .then((res) => {
                    console.log(res);
                    this.getZoomIntegrationStep2();
                })
                .catch((response) => {
                    console.log(response);
                    this.getZoomIntegrationStep2();
                })
    }


    getZoomIntegrationStep2 = async () => {
        const BaseURL = `${process.env.REACT_APP_NYLAS_URL_ZOOM}`;
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());   

        const payload = {
            "provider": "zoom",
            "redirect_uri": `${process.env.REACT_APP_ZOOM_REDIRECT_URL}`,
            "expires_in": 43200,
            "account_id": params.account_id,
        }    
        const headers = {
            'Authorization' : `Basic ${process.env.REACT_APP_ZOOM_API_AUTH}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        }
            await axios.post(`${BaseURL}/connect/auth`, payload, {
                headers,
            })
                .then(res => {
                    console.log(res);
                    console.log(res.data.data);
                        window.location.href = res.data.data.url;
                        utilityObj.loaderView("show");

                })
                .catch((error) => {
                    console.log(error)
                })
    }    
    render() {
        return (<>Please wait while redirecting to Zoom App Integration.</>);
    }
}