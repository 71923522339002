import React, { Component } from 'react'

export default class logout extends Component {

    componentDidMount() {
        localStorage.removeItem('usernamefor2fa');
        localStorage.removeItem('sentotpToken');
        localStorage.removeItem('adminsaToken');
        localStorage.removeItem('admincaToken');
        localStorage.removeItem('adminsmToken');
        localStorage.removeItem('adminsrToken');
        window.location.href = "/login";
    }
    render() {
        return (
            <>
                
            </>
        )
    }
}
