import React, { Component } from 'react'
import profile1 from "../../images/profile1.png"
import profile2 from "../../images/profile2.png"
import profile3 from "../../images/profile3.png"
import profile4 from "../../images/profile4.png"
import profile5 from "../../images/profile5.png"
import OrganizationSetup from "../../images/OrganizationSetup.svg"
import ActiveUser from "../../images/ActiveUsers.svg"
import SalesProcess from "../../images/SalesProcess.svg"
import OrganizationTargets from "../../images/OrganizationTargets.svg"
import GraphPNG from "../../images/graph.png"
import UserActivity from "../../images/UserActivity.svg"
import LatestUpdate from "../../images/LatestUpdate.svg"
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import axios from '../apiConnection/axios';

import Chart from "react-apexcharts";
export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active_divisions: 0,
            active_teams: 0,
            total_active_users: 0,
            active_license: 0,
            user_percent: 0,
            total_sales_stages: 0,
            total_sales_tasks: 0,
            top_target_revenue: 0,
            top_target_revenue_word: "",
            coaching_session_per_sales_rep: 0,
            sales_process_url: "sales-process",
            sales_process_label: "sales-process",
            organization_setup_url: "organization-structure",
            organization_setup_label: "organization-structure",
            active_users_url: "user-management",
            active_users_label: "View All Users",
            organization_target_url: "",
            organization_target_label: "",
            revenue_card_url: "",
            revenue_card_label: "",
            latest_updates: [],
            user_activities: [],
            active_users: '',
            userscount: '', formated_dates: '',
            series: [{

                name: "Users",
                data: [],
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },

                dataLabels: {
                    enabled: false
                },
                // colors: ['#005491', '#FF7C00', '#FC530E', '#FFB708', '#6E9204', '#424242', '#003665', '#3291D1'],          

                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: ['#005491'],
                    width: 3,
                    dashArray: 0,
                },
                markers: {
                    color: 'red'
                },

                grid: {
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [['01', 'Aug'], ['02', 'Aug'], ['03', 'Aug'], ['04', 'Aug'], ['05', 'Aug'],
                    ['06', 'Aug'], ['07', 'Aug'], ['08', 'Aug'], ['09', 'Aug'], ['10', 'Aug'],
                    ['11', 'Aug'], ['12', 'Aug'], ['13', 'Aug'], ['14', 'Aug'], ['15', 'Aug'],
                    ['16', 'Aug'], ['17', 'Aug'], ['18', 'Aug']],

                },
                yaxis: {
                    opposite: true,
                    title: {
                        text: "Users"
                    }
                },
                states: {
                    normal: {
                        filter: {
                            type: 'darken',
                            value: 1,
                        }
                    },
                    hover: {
                        filter: {
                            type: 'lighten',
                            value: 0.1,
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'darken',
                            value: 0.35,
                        }
                    },
                },

            },


        };
        this.getDashboardData = this.getDashboardData.bind(this);

        this.maskNumbersInWord = this.maskNumbersInWord.bind(this);
        this.getUserActivity = this.getUserActivity.bind(this);
        this.getLatestUpdats = this.getLatestUpdats.bind(this);


    }
    componentDidMount() {
        this.getDashboardData();
        this.getUserActivity();
        this.getLatestUpdats();
        this.getActiveUsers();
        this.getWeekActiveUsers();
    }
    getActiveUsers = async () => {
        // get-active-user-graph-data
        await axios.get(`dashboard/get-active-user-graph-data`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                if (res.data.code === 200) {
                    this.setState({ active_users: res.data.data });
                    var userscount = [];
                    var formated_dates = [];
                    for (const [key, value] of Object.entries(res.data.data)) {
                        userscount.push(res.data.data[key].user_count);
                        const myArray = res.data.data[key].formattd_date.split(" ");
                        formated_dates.push(myArray);
                    }
                    // Object.keys(res.data.data).forEach((key, value) => {
                    //     userscount.push(key);
                    //     formated_dates.push(value);
                    //     // do something with obj[key]
                    //  });
                    this.setState({
                        userscount, formated_dates
                    })
                    console.log(userscount);
                    this.setState({
                        series: [{
                            name: "Users",
                            data: userscount,
                        }],
                    })

                    this.setState(prevState => ({
                        ...prevState,
                        options: {
                            ...prevState.options,
                            xaxis: {
                                categories: formated_dates,

                            },
                        }
                    }));

                    console.log(formated_dates);
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }


    getWeekActiveUsers = async () => {
        // get-average-active-user-week
        await axios.get(`dashboard/get-average-active-user-week`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                if (res.data.code === 200) {
                    this.setState({ week_active_users: res.data.data[0].user_count });
                    console.log(res.data.data[0].user_count);
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    maskNumbersInWord(num) {
        num = parseInt(num);
        var response = [];
        if (num == "") {
            num = 0;
        }
        var numvalue = 0;
        var numword = "";
        if (num > 999 && num < 1000000) {
            numvalue = (num / 1000).toFixed(1); // convert to K for number from > 1000 < 1 million 
            numword = "K";
        } else if (num >= 1000000 && num < 1000000000) {
            numvalue = (num / 1000000).toFixed(1); // convert to M for number from > 1 million  < 1 billion
            numword = "M";
        } else if (num >= 1000000000 && num < 1000000000000) {
            numvalue = (num / 1000000000).toFixed(1); // convert to B for number from > 1 billion 
            numword = "B";
        } else if (num >= 1000000000000) {
            numvalue = (num / 1000000000000).toFixed(1); // convert to B for number from > 1 billion 
            numword = "T";
        } else if (num <= 999) {
            numvalue = num; // if value < 1000, nothing to do
        }
        response = [numvalue, numword];
        return response;

    }

    getDashboardData = async () => {
        await axios.get(`dashboard/get-system-admin-top-cards-data`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log("get-system-admin-top-cards-data", res.data.data[0]);
                if (res.data.code === 200) {
                    var dashboard_card_data = res.data.data;
                    var org_card_url = dashboard_card_data[0].organization_setup.active_divisions < 1 ? "initiate-organization-structure" : "organization-structure";
                    var org_card_label = dashboard_card_data[0].organization_setup.active_divisions < 1 ? "Setup Organization Structure" : "Organization Setup";

                    var user_card_url = dashboard_card_data[1].active_users.total_active_users < 1 ? "initiate-organization-structure" : dashboard_card_data[1].active_users.users_exists == "yes" ? "user-management" : "initiate-user-management";
                    var user_card_label = dashboard_card_data[1].active_users.total_active_users < 1 ? "Setup Organization Structure" : dashboard_card_data[1].active_users.users_exists == "yes" ? "View All Users" : "Add Sales Reps";

                    var sales_card_url = ((dashboard_card_data[2].sales_process.total_sales_stages < 1) && (dashboard_card_data[2].sales_process.active_sales_stages < 1)) ? "initiate-sales-process" : "sales-process";
                    var sales_card_label = ((dashboard_card_data[2].sales_process.total_sales_stages < 1) && (dashboard_card_data[2].sales_process.active_sales_stages < 1)) ? "Create Sales Process" : "View Sales Process";

                    

                    var revenue_card_url = dashboard_card_data[3].organization_targets.top_target_revenue == 0 ? "organization-structure" : "organization-structure";
                    var revenue_card_label = dashboard_card_data[3].organization_targets.top_target_revenue == 0 || dashboard_card_data[3].organization_targets.coaching_session_per_sales_rep == 0 ? "Add Organization Targets" : "Organization Setup";
                    console.log("ORG_REVENUE", dashboard_card_data[3].organization_targets.top_target_revenue);
                    var org_revenue_format = this.maskNumbersInWord(dashboard_card_data[3].organization_targets.top_target_revenue);
                    var org_revenue = org_revenue_format[0];
                    var org_revenue_word = org_revenue_format[1];
                    var total_active_users = dashboard_card_data[1].active_users.total_active_users;
                    var active_license = dashboard_card_data[1].active_users.active_licenses;
                    var user_percent = (total_active_users / active_license) * 100;
                    console.log("ORG_REVENUE_FORMAT", org_revenue_format);

                    this.setState({
                        active_divisions: dashboard_card_data[0].organization_setup.active_divisions,
                        active_teams: dashboard_card_data[0].organization_setup.active_teams,
                        total_active_users: total_active_users,
                        active_license: active_license,
                        user_percent: user_percent,
                        total_sales_stages: dashboard_card_data[2].sales_process.total_sales_stages,
                        total_sales_tasks: dashboard_card_data[2].sales_process.total_sales_tasks,
                        active_sales_stages: dashboard_card_data[2].sales_process.active_sales_stages,
                        active_sales_tasks: dashboard_card_data[2].sales_process.active_sales_tasks,
                        top_target_revenue: org_revenue != 0 ? org_revenue : "",
                        top_target_revenue_word: org_revenue_word,
                        coaching_session_per_sales_rep: dashboard_card_data[3].organization_targets.coaching_session_per_sales_rep,
                        sales_process_url: sales_card_url,
                        sales_process_label: sales_card_label,
                        organization_setup_url: org_card_url,
                        organization_setup_label: org_card_label,
                        active_users_url: user_card_url,
                        active_users_label: user_card_label,
                        revenue_card_url: revenue_card_url,
                        revenue_card_label: revenue_card_label
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getUserActivity = async () => {
        var parameters = { category: "USER_MANAGEMENT" }
        await axios.post(`activity-logs/get`, parameters, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                if (res.data.code === 200) {
                    this.setState({ user_activities: res.data.data });
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }
    getLatestUpdats = async () => {

        await axios.post(`activity-logs/get`, {}, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                if (res.data.code === 200) {
                    this.setState({ latest_updates: res.data.data });
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    render() {
        const { active_divisions, active_teams, week_active_users, total_active_users, active_license, user_percent, active_sales_stages, active_sales_tasks, total_sales_stages, total_sales_tasks, top_target_revenue, top_target_revenue_word, coaching_session_per_sales_rep, sales_process_url, sales_process_label, organization_setup_url, organization_setup_label, active_users_url, active_users_label, revenue_card_url, revenue_card_label, latest_updates, user_activities } = this.state;
        console.log("Render user activities:", user_activities.length);
        var default_user_pic = process.env.REACT_APP_STATIC_FILES_PATH + '/' + 'default-avatar.png';
        return (
            <>
                <main className="main-contant">
                    <div className="contents">
                        <div className="row card-row dashboard-card-row admin-card-row">
                            <div className="col-xl-3 col-lg-6 px-2">
                                <div className="cord-body-wrap w-100 bg-white">
                                    <div
                                        className="card-head text-center f-600 f2015 d-flex justify-content-center align-items-center">
                                        <img src={OrganizationSetup} alt="dashboard-icon" className="mr-1" width="24" />
                                        Organization Setup
                                    </div>
                                    <div className="profile-card-container">
                                        <div className="top-content position-relative">
                                            {active_divisions > 0 ? <>
                                                <div className="d-flex px-3 py-2 justify-content-between w-100 align-items-center">
                                                    <div className="profile-name">
                                                        Active Divisions
                                                    </div>
                                                    <div className="counts">
                                                        {active_divisions}
                                                    </div>
                                                </div>
                                                <div className="border"></div>
                                                <div className="d-flex px-3 py-2 justify-content-between w-100 align-items-center">
                                                    <div className="profile-name">
                                                        Active Teams
                                                    </div>
                                                    <div className="counts">
                                                        {active_teams}
                                                    </div>
                                                </div>
                                            </> : <div className="profile-card-container-empty text-center py-5 px-3">The organization structure is not set up.</div>}
                                        </div>
                                        <div className="bottom-content">
                                            <Link to={organization_setup_url}
                                                className="d-block p-3 color_blue underline text-center"> {organization_setup_label} </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 px-2">
                                <div className="cord-body-wrap w-100 bg-white">
                                    <div
                                        className="card-head text-center f-600 f2015 d-flex justify-content-center align-items-center">
                                        <img src={ActiveUser} alt="dashboard-icon" className="mr-1" width="24" />
                                        Active Users
                                    </div>
                                    <div className="profile-card-container">
                                        <div className="top-content position-relative">
                                            {total_active_users > 0 ? <>
                                                <div className="d-flex px-3 py-2 justify-content-between w-100 align-items-center">
                                                    <div className="profile-name">
                                                        Total Active Users
                                                    </div>
                                                    <div className="counts">
                                                        {total_active_users}
                                                    </div>
                                                </div>
                                                <div class="p-3 text-right"><div class="progress w-100">
                                                    <div class="progress-bar bg-success " style={{ width: `${user_percent}%` }} role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                                    </div></div>
                                                    <small class="d-block mt-2">{active_license} Active Licenses</small></div>


                                            </> : <div className="profile-card-container-empty text-center py-5 px-3">Users cannot be setup without an organization structure.</div>}
                                        </div>
                                        <div className="bottom-content">
                                            <Link to={active_users_url} className="d-block p-3 color_blue underline text-center">{active_users_label}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 px-2">
                                <div className="cord-body-wrap w-100 bg-white">
                                    <div
                                        className="card-head text-center f-600 f2015 d-flex justify-content-center align-items-center">
                                        <img src={SalesProcess} alt="dashboard-icon" className="mr-1" width="24" />
                                        Sales Process
                                    </div>
                                    <div className="profile-card-container">
                                        <div className="top-content position-relative">
                                            {total_sales_stages > 0 ? <>
                                                <div className="d-flex px-3 py-2 justify-content-between w-100 align-items-center">
                                                    <div className="profile-name">
                                                        Total Sales Stages
                                                    </div>
                                                    <div className="counts">
                                                        {active_sales_stages}
                                                    </div>
                                                </div>
                                                <div className="border"></div>
                                                <div className="d-flex px-3 py-2 justify-content-between w-100 align-items-center">
                                                    <div className="profile-name">
                                                        Total Sales Tasks
                                                    </div>
                                                    <div className="counts">
                                                        {active_sales_tasks}
                                                    </div>
                                                </div>
                                            </> : <div className="profile-card-container-empty text-center py-5 px-3">The sales process is not set up.  </div>}

                                        </div>
                                        <div className="bottom-content">
                                            <Link to={sales_process_url} className="d-block p-3 color_blue underline text-center">{sales_process_label}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 px-2">
                                <div className="cord-body-wrap w-100 bg-white">
                                    <div
                                        className="card-head text-center f-600 f2015 d-flex justify-content-center align-items-center">
                                        <img src={OrganizationTargets} alt="dashboard-icon" className="mr-1" width="24" />
                                        Organization Targets
                                    </div>
                                    <div className="profile-card-container">
                                        <div className="top-content position-relative">
                                            {top_target_revenue > 0 || coaching_session_per_sales_rep > 0 ? <>
                                                <div className="d-flex px-3 py-2 justify-content-between w-100 align-items-center">
                                                    <div className="profile-name">
                                                        Top Target Revenue
                                                    </div>
                                                    <div className="counts">
                                                        {top_target_revenue}<small>{top_target_revenue_word}</small>
                                                    </div>
                                                </div>
                                                <div className="border"></div>
                                                <div className="d-flex px-3 py-2 justify-content-between w-100 align-items-center">
                                                    <div className="profile-name">
                                                        Coaching Sessions <br />per Sales Rep
                                                    </div>
                                                    <div className="counts">
                                                        {coaching_session_per_sales_rep}
                                                    </div>
                                                </div>
                                            </> : <div className="profile-card-container-empty text-center py-5 px-3">The organization targets are not set up for the current year.</div>}
                                        </div>
                                        <div className="bottom-content">
                                            <Link to={organization_setup_url} className="d-block p-3 color_blue underline text-center">{revenue_card_label}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row card-row dashboard-card-row">
                            <div className="col-lg-9 px-2">
                                <div className="cord-body-wrap w-100 bg-white p-3">
                                    <div className="card-heading  f-600 f2015">
                                        Active Users
                                    </div>
                                    <div className="graph-wrap">
                                        {/* <Line data={chartdata} options={options} height={100} className="dashboardGraph"/> */}
                                        <Chart options={this.state.options} series={this.state.series} type="line" height={280} />

                                        {/* <h3 className="text-center py-5">GRAPH</h3> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 px-2">
                                <div className="cord-body-wrap w-100 bg-theme p-3">
                                    <div className="card-heading  text-white f-600 f2015">
                                        Users in the Last Week
                                    </div>
                                    <div className="last-week-user-count mt-4 text-white f-600 ">
                                        {week_active_users}
                                    </div>

                                    <p className="text-white">{(week_active_users === 1) ? `User ` : `Users `} per Day</p>
                                    <div className="graph-wrap pt-4 ">
                                        <img src={GraphPNG} className="img-fluid mt-4" />
                                        {/* <h3 className="text-center py-5 text-white"></h3> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row card-row dashboard-card-row">
                            <div className="col-lg-6 px-2">
                                <div className="cord-body-wrap w-100 bg-white">
                                    <div
                                        className="card-head text-center f-600 f2015 d-flex justify-content-center align-items-center">
                                        <img src={UserActivity} alt="dashboard-icon" className="mr-1" width="24" />
                                        User Activity
                                    </div>
                                    <div className="user-card-container">

                                        <div className="user-list-wrap p-3 w-100">
                                            {user_activities.length > 0 ?
                                                user_activities.map((activity) => {
                                                    return (<>
                                                        <div className="user-list d-flex  align-items-center">
                                                            <div className="img-wrap mr-3">
                                                                <img src={((activity.user[0].profile_pic_url != "") && (activity.user[0].profile_pic_url != null) && (activity.user[0].profile_pic_url != 'null')) ? process.env.REACT_APP_STATIC_FILES_PATH + '/' + activity.user[0].profile_pic_url : default_user_pic} alt="" />
                                                            </div>
                                                            <div className="info-text">
                                                                <div className="name mb-1" dangerouslySetInnerHTML={{ __html: activity.description }}>


                                                                </div>

                                                                <small>{activity.formated_time}</small>
                                                            </div>
                                                        </div></>)
                                                }) : <center class="d-block py-3 f-600"> No User Activity</center>}


                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 px-2">

                                <div className="cord-body-wrap w-100 bg-white">
                                    <div
                                        className="card-head text-center f-600 f2015 d-flex justify-content-center align-items-center">
                                        <img src={LatestUpdate} alt="dashboard-icon" className="mr-1" width="24" />
                                        Latest Updates
                                    </div>
                                    <div className="user-card-container">
                                        <div className="user-list-wrap p-3 w-100">
                                            {latest_updates.length > 0 ?
                                                latest_updates.map((latest) => {
                                                    return (<>
                                                        <div className="user-list d-flex  align-items-center">
                                                            <div className="info-text">
                                                                <div className="name mb-1" dangerouslySetInnerHTML={{ __html: latest.description }}>

                                                                    {/* Sales Division <span className="f-600"> SUV Sales Division </span>
                                                deactivated. */}
                                                                </div>
                                                                <small>{latest.formated_time}</small>
                                                            </div>
                                                        </div></>)
                                                }) : <center className="d-block py-3 f-600"> No Latest Updates</center>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}
