import React, { Component } from 'react'
import profile1 from "../../images/profile1.png";
import profile3 from "../../images/profile3.png";
import profile2 from "../../images/default-avatar.png";
import csv from "../../images/csv-file.png"
import Modal from "./modals/modal";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faEllipsisV, faChevronLeft, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import CustomSelect from "./elements/customSelect";
import { CSVReader } from 'react-papaparse';
import axios from '../apiConnection/axios';
import { Alert } from 'bootstrap';


export default class InitiateUserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileImg: process.env.REACT_APP_STATIC_FILES_PATH + '/' + 'default-avatar.png',
      showTeamOption: true,
      first_name: "",
      last_name: "",
      email: "",
      errors: {
        first_name: '',
        last_name: ''
      },
      user_status: [],
      user_role: [],
      sales_division: [],
      organization_structure: [],
      users: {},
      addUserShow: false,
      profileShow: false,
      deactivateUsershow: false,
      inviteSendUsershow: false,
      selectedUserName: "",
      defaultdivisionSelectText: "SUV Sales Division",
      divisions: ['Headings', 'Org Head', 'division-2'],
      users_data: [],
      redirect_url: "http://localhost:3001/createpassword",
      selectedFile: "",
      csvToJson: "",
      csvErrors: "",
      submitJson: [],
      Message: "",
      MessageType: "",
      fileextractDone: 0,
      errorCells: "",
      addUseAble: "",
      addManually: "",
      divisionList: [],
      defaultyearSelectText: "2021",
      yearList: [
        { id: 1, name: "2021" },
        { id: 2, name: "2022" },
        { id: 3, name: "2023" },
        { id: 4, name: "2024" },
        { id: 5, name: "2025" },
      ],
      defaultuserSelectText: "Vince Simon",
      userList: [
        { id: 1, name: "Vince Simon" },
        { id: 2, name: "Joseph Gates" },
        { id: 3, name: "Clark Winson" },
      ],
      defaultteamSelectText: "",
      teamList: [],
      defaultroleSelectText: "Select Role",
      roleList: [
        // { id: 3, name: "Sales Manager", value: 3 },
        { id: 4, name: "Sales Rep", value: 4 },
      ],
      orgName: ""
    }
    this.handleChange = this.handleChange.bind(this);
    this.getOrgStructure = this.getOrgStructure.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAllDivisionsTeams = this.getAllDivisionsTeams.bind(this);

  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getOrgStructure();
    this.getAllDivisionsTeams();
    this.getOrganizationData();
    this.getsalesRep();
  }
  getOrganizationData = async () => {
    await axios.get(`organizations/get-organization`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({ orgName: res.data.data[0].org_name })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  setAddUserShow = (newValue) => {
    this.setState({ addUserShow: newValue });
  }

  onChange(e) {
    var data_division = e.target.getAttribute('data-division_id')
    this.setState(prevState => ({
      ...prevState,
      submitJson: {
        ...prevState.submitJson,
        [`Headings`]: {
          ...prevState.submitJson[`division-0`],
          [`Head Position`]: 'Head Position',
          [`Sales Division Name`]: 'Sales Division Name',
          [`Sales Team Name`]: 'Sales Team Name',
          [`First Name`]: 'First Name',
          [`Last Name`]: 'Last Name',
          [`Email`]: 'Email',
          [`Mobile`]: 'Mobile',
        }
      }
    }))

    if (data_division == 'Org Head') {
      this.setState(prevState => ({
        ...prevState,
        submitJson: {
          ...prevState.submitJson,
          [data_division]: {
            ...prevState.submitJson[data_division],
            [e.target.name]: e.target.value,
            [`Head Position`]: 'CEO',
            [`Sales Team Name`]: '',
            [`Last Name`]: '',
            [`Mobile`]: '',

          }
        }
      }))

    } else {
      this.setState(prevState => ({
        ...prevState,
        submitJson: {
          ...prevState.submitJson,
          [data_division]: {
            ...prevState.submitJson[data_division],
            [e.target.name]: e.target.value,
            [`Head Position`]: 'Division Manager',
            [`Sales Team Name`]: '',
            [`Last Name`]: '',
            [`Mobile`]: '',

          }
        }
      }))

    }
    // this.setState({submitJson:{[e.target.name]: e.target.value}});  
    // this.setState({ [e.target.name] : e.target.value });
  }
  handleMannualUpload = () => {
    console.log(this.state.submitJson);
    var fullerror_message = "";
    var cell = "";
    var row = 0;
    var col = 0;
    {
      this.state.submitJson.map((value, index, array2) => {
        row = index + 1;
        if (index == 0) {
          for (var i = 0; i <= 6; i++) {
            col = this.numToSSColumn(i + 1);
            if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i]))) {
              cell = 'Cell ' + col + '' + row;
              fullerror_message = fullerror_message + ' ' + cell;
            }
          }
          this.setState(prevState => ({
            ...prevState,
            users_data: [
              ...prevState.users_data,
              {
                "data": array2[index].data,
                // "data": ["Head Position","Sales Division Name","Sales Team Name","First Name","Last Name","Email","Mobile"]
              }
            ]
          })
          );

        } else if (index == 1) {
          for (var i = 0; i <= 6; i++) {
            col = this.numToSSColumn(i + 1);
            if (i === 0) {
              if (!(/^[a-zA-Z\s]*$/.test(array2[index].data[i]))) {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (Only letters are allowed) <br>";
              }
            }
            if (i === 1 || i === 2) {
              if (array2[index].data[i] == '') {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (Division Name/ Team Name is required.) <br>";
              }
            }
            if (i === 3) {
              if (array2[index].data[i] !== '') {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (First Name is required.) <br>";
              }
            } if (i === 4) {
              if (array2[index].data[i] !== '') {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (Last Name is required.) <br>";
              }
            }

            if (i === 6) { //mobile
              if (!(/^[0-9]{11,14}$/g.test(array2[index].data[i]))) {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (Invalid phone)\n <br>";
              }
            }
            if (i === 5) { //email
              let lastAtPos = array2[index].data[i].lastIndexOf('@');
              let lastDotPos = array2[index].data[i].lastIndexOf('.');
              var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
              if (!pattern.test(array2[index].data[i])) {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (Invalid Email)\n <br>";
              } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && array2[index].data[i].indexOf('@@') === -1 && lastDotPos > 2 && (array2[index].data[i].length - lastDotPos) > 2)) {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (Invalid Email)\n <br>";
              }
            }
          }
          this.setState(prevState => ({
            ...prevState,
            users_data: [
              ...prevState.users_data,
              {
                "data": array2[index].data,
              }
            ]
          })
          );


        } else {
          for (var i = 0; i <= 6; i++) {
            col = this.numToSSColumn(i + 1);
            if (i === 0) { //Role
              if (!(/^[a-zA-Z\s]*$/.test(array2[index].data[i]))) {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (Only letters are allowed) <br>";
              }
            }
            // if (i === 1) { //Division & Team Name
            //   if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i]))) {
            //     cell = 'Cell ' + col + '' + row;
            //     fullerror_message = fullerror_message + ' ' + cell+" (Division Name is not valid) <br>";
            //   }
            // }
            // if ((i === 2) && array2[index].data[i] != "") {
            //   if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i]))) {
            //     cell = 'Cell ' + col + '' + row;
            //     fullerror_message = fullerror_message + ' ' + cell+" (Team Name is not valid) <br>";
            //   }
            // }
            if (i === 3) {
              if (array2[index].data[i] !== '') {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (First Name is required.) <br>";
              }
            } if (i === 4) {
              if (array2[index].data[i] !== '') {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (Last Name is required.) <br>";
              }
            }
            if (i === 6) { //mobile
              if (!(/^[0-9]{11,14}$/g.test(array2[index].data[i]))) {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (Invalid phone)\n <br>";
              }
            }
            if (i === 5) { //email
              let lastAtPos = array2[index].data[i].lastIndexOf('@');
              let lastDotPos = array2[index].data[i].lastIndexOf('.');
              var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
              if (!pattern.test(array2[index].data[i])) {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (Invalid Email)\n <br>";
              } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && array2[index].data[i].indexOf('@@') === -1 && lastDotPos > 2 && (array2[index].data[i].length - lastDotPos) > 2)) {
                cell = 'Cell ' + col + '' + row;
                fullerror_message = fullerror_message + ' ' + cell + " (Invalid Email)\n <br>";
              }
            }
          }
          this.setState(prevState => ({
            ...prevState,
            users_data: [
              ...prevState.users_data,
              {
                "data": array2[index].data,
              }
            ]
          })
          );

        }
      }).slice(0, 1)
    }
    console.log(this.state.users_data);
    if (fullerror_message == "") {
      this.setState({ fileextractDone: 1 });
    } else {
      this.setState({
        fileextractDone: 0,
        errorCells: fullerror_message,
        MessageType: "danger",
        Message: "file have errors."
      });
    }
  }
  userUpload = async () => {
    const usersdata = {
      data: this.state.users_data,
    }
    await axios.post(`/organizations/check-exists-users-csv`, usersdata, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState({
            Message: "CSV Uploaded successfully.",
            MessageType: 'success',
            fileextractDone: 0,
          });
          localStorage.setItem('getAllusersUrl', res.data.data.url);
          localStorage.setItem('getAllusersData', JSON.stringify(res.data.data));
        }
        else {
          this.setState({
            Message: "CSV Upload Failed.",
            MessageType: 'danger',
            fileextractDone: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  handleOnDrop = (data) => {
    this.setState({ csvToJson: data });
    // console.log(this.state.csvToJson);
    var fullerror_message = "";
    var cell = "";
    var row = 0;
    var col = 0;
    {
      var file_data = this.state.csvToJson;
      if (file_data.length === 0) {
        console.log("DATALENGTH", 0);
        fullerror_message = " <br/> CSV file is empty. Nothing to import.  <br/>";
        // return false;
      }
      else if (file_data.length === 1) {
        console.log("DATALENGTH", 1);
        fullerror_message = " <br/> CSV file is empty. Nothing to import. <br/>";
        // return false;
      } else {

        if (file_data.length === 2) {
          console.log("FILE DATA", file_data);
          console.log("DATALENGTH", 2);
          console.log("DATALENGTH0", file_data[0].data);
          console.log("DATALENGTH1", file_data[1].data);
          if (file_data[0].data.length <= 1 || file_data[1].data.length <= 1)
            fullerror_message = " <br/> CSV file is empty. Nothing to import. <br/>";
        }

        if (file_data[0].data[0] != "Head Position" || file_data[0].data[1] != "Sales Division Name" || file_data[0].data[2] != "Sales Team Name" || file_data[0].data[3] != "First Name" || file_data[0].data[4] != "Last Name" || file_data[0].data[5] != "Email" || file_data[0].data[6] != "Mobile" || fullerror_message != "") {
          fullerror_message = " <br/> The column header is incorrect. <br/>";
        }

        else {
          this.state.csvToJson.map((value, index, array2) => {
            row = index + 1;
            if (index == 0) {
              for (var i = 0; i <= 6; i++) {
                col = this.numToSSColumn(i + 1);
                if (array2[index].data[i].trim() === "") {
                  cell = 'Cell ' + col + '' + row;
                  fullerror_message = fullerror_message + ' ' + cell + " (Data is missing)";
                }
                else if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i]))) {
                  cell = 'Cell ' + col + '' + row;
                  fullerror_message = fullerror_message + ' ' + cell;
                }
              }
              this.setState(prevState => ({
                ...prevState,
                users_data: [
                  ...prevState.users_data,
                  {
                    "data": array2[index].data,
                    // "data": ["Head Position","Sales Division Name","Sales Team Name","First Name","Last Name","Email","Mobile"]
                  }
                ]
              })
              );
              this.setState(prevState => ({
                ...prevState,
                submitJson: {
                  ...prevState.submitJson,
                  [`Headings`]: {
                    ...prevState.submitJson[`Headings`],
                    [`Head Position`]: 'Head Position',
                    [`Sales Division Name`]: 'Sales Division Name',
                    [`Sales Team Name`]: 'Sales Team Name',
                    [`First Name`]: 'First Name',
                    [`Last Name`]: 'Last Name',
                    [`Email`]: 'Email',
                    [`Mobile`]: 'Mobile',
                  }
                }
              }))
            } else if (index === data.length - 1) {
              if ((array2[index].data[0] === "Sales Rep") || (array2[index].data[0] === "Sales Representative")) {
                for (var i = 0; i <= 6; i++) {
                  col = this.numToSSColumn(i + 1);
                  if (i === 0) { //Role
                    if (!(/^[a-zA-Z\s]*$/.test(array2[index].data[i]))) {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Only letters are allowed) <br>";
                    }
                  }
                  if (i === 0) {
                    if (array2[index].data[i] === "") {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Head Position is required) <br>";
                    }
                    else if ((array2[index].data[i] !== "Sales Rep") && (array2[index].data[i] !== "Sales Representative") ) {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Head Position should be Sales Rep or Sales Representative) <br>";
                    }
                  }
                  if (i === 1) { //Division & Team Name
                    if (array2[index].data[i] === "") {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Division Name is required) <br>";
                    }
                    // else if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i]))) {
                    //   cell = 'Cell ' + col + '' + row;
                    //   fullerror_message = fullerror_message + ' ' + cell+" (Division Name is not valid) <br>";
                    // }

                  }
                  if (i === 2) {
                    if (array2[index].data[i] === "") {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Team Name is required) <br>";
                    }
                    // else if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i]))) {
                    //   cell = 'Cell ' + col + '' + row;
                    //   fullerror_message = fullerror_message + ' ' + cell+" (Team Name is not valid) <br>";
                    // }

                  }
                  if (i === 3) {
                    if (array2[index].data[i] === "") {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (First Name is required.) <br>";
                    }
                  } if (i === 4) {
                    if (array2[index].data[i] === "") {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Last Name is required.) <br>";
                    }
                  }
                  if (i === 6) { //mobile
                    if (array2[index].data[i] === "") {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Phone is required.) <br>";
                    }
                    else if (!(/^[0-9]{11,14}$/g.test(array2[index].data[i]))) {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Invalid phone)\n <br>";
                    }
                  }
                  if (i === 5) { //email

                    let lastAtPos = array2[index].data[i].lastIndexOf('@');
                    let lastDotPos = array2[index].data[i].lastIndexOf('.');
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

                    if (array2[index].data[i] === "") {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Email is required.) <br>";
                    } else if (!pattern.test(array2[index].data[i])) {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Invalid Email)\n <br>";
                    } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && array2[index].data[i].indexOf('@@') === -1 && lastDotPos > 2 && (array2[index].data[i].length - lastDotPos) > 2)) {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Invalid Email)\n <br>";
                    }
                  }
                }
                this.setState(prevState => ({
                  ...prevState,
                  users_data: [
                    ...prevState.users_data,
                    {
                      "data": array2[index].data,
                    }
                  ]
                })
                );
                this.setState(prevState => ({
                  ...prevState,
                  submitJson: {
                    ...prevState.submitJson,
                    [`division-` + parseInt(index - 1)]: {
                      ...prevState.submitJson[`division-` + parseInt(index - 1)],
                      [`Head Position`]: array2[0],
                      [`Sales Division Name`]: array2[1],
                      [`Sales Team Name`]: array2[2],
                      [`First Name`]: array2[3],
                      [`Last Name`]: array2[4],
                      [`Email`]: array2[5],
                      [`Mobile`]: array2[6],
                    }
                  }
                }))
              }

            } else {
              for (var i = 0; i <= 6; i++) {
                col = this.numToSSColumn(i + 1);
                if (i === 0) { //Role
                  if (!(/^[a-zA-Z\s]*$/.test(array2[index].data[i]))) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Only letters are allowed) <br>";
                  }
                }
                if (i === 0) {
                  if (array2[index].data[i] === "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Head Position is required) <br>";
                  }
                  else if ((array2[index].data[i] !== "Sales Rep") && (array2[index].data[i] !== "Sales Representative") ) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Head Position should be Sales Rep or Sales Representative) <br>";
                  }
                }
                if (i === 1) { //Division & Team Name
                  if (array2[index].data[i] === "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Division Name is required) <br>";
                  }
                  // else if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i]))) {
                  //   cell = 'Cell ' + col + '' + row;
                  //   fullerror_message = fullerror_message + ' ' + cell+" (Division Name is not valid) <br>";
                  // }

                }
                if (i === 2) {
                  if (array2[index].data[i] === "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Team Name is required) <br>";
                  }
                  // else if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i]))) {
                  //   cell = 'Cell ' + col + '' + row;
                  //   fullerror_message = fullerror_message + ' ' + cell+" (Team Name is not valid) <br>";
                  // }

                }
                if (i === 3) {
                  if (array2[index].data[i] === "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (First Name is required.) <br>";
                  }
                } if (i === 4) {
                  if (array2[index].data[i] === "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Last Name is required.) <br>";
                  }
                }
                if (i === 6) { //mobile
                  if (array2[index].data[i] === "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Phone is required.) <br>";
                  }
                  else if (!(/^[0-9]{11,14}$/g.test(array2[index].data[i]))) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Invalid phone)\n <br>";
                  }
                }
                if (i === 5) { //email

                  let lastAtPos = array2[index].data[i].lastIndexOf('@');
                  let lastDotPos = array2[index].data[i].lastIndexOf('.');
                  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

                  if (array2[index].data[i] === "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Email is required.) <br>";
                  } else if (!pattern.test(array2[index].data[i])) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Invalid Email)\n <br>";
                  } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && array2[index].data[i].indexOf('@@') === -1 && lastDotPos > 2 && (array2[index].data[i].length - lastDotPos) > 2)) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Invalid Email)\n <br>";
                  }
                }
              }
              this.setState(prevState => ({
                ...prevState,
                users_data: [
                  ...prevState.users_data,
                  {
                    "data": array2[index].data,
                  }
                ]
              })
              );
              this.setState(prevState => ({
                ...prevState,
                submitJson: {
                  ...prevState.submitJson,
                  [`division-` + parseInt(index - 1)]: {
                    ...prevState.submitJson[`division-` + parseInt(index - 1)],
                    [`Head Position`]: array2[0],
                    [`Sales Division Name`]: array2[1],
                    [`Sales Team Name`]: array2[2],
                    [`First Name`]: array2[3],
                    [`Last Name`]: array2[4],
                    [`Email`]: array2[5],
                    [`Mobile`]: array2[6],
                  }
                }
              }))
            }
          }).slice(0, 1)
        }
      }
    }

    if (fullerror_message == "") {
      this.setState({ fileextractDone: 1 });
    } else {
      this.setState({
        fileextractDone: 0,
        errorCells: fullerror_message,
        MessageType: "danger",
        Message: "file have errors."
      });
    }
    console.log(this.state.users_data);
  };
  numToSSColumn = (num) => {
    var s = '', t;

    while (num > 0) {
      t = (num - 1) % 26;
      s = String.fromCharCode(65 + t) + s;
      num = (num - t) / 26 | 0;
    }
    return s || undefined;
  }
  handleOnError = (err, file, inputElem, reason) => {
    this.setState({ csvErrors: err });
    console.log(this.state.csvErrors);
  };

  handleOnRemoveFile = (data) => {
    this.setState({ csvToJson: "" });
    console.log(this.state.csvToJson);
  };
  getsalesRep = async () => {
    await axios.get(`users/check-sales-rep-exists`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        // alert(res.data.code)
        if (res.data.code === 200) {
          this.setState({
            addManually: "NO",
          });
        }
        else {
          this.setState({
            addManually: "YES",
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  getOrgStructure = async () => {

    await axios.get(`organizations/get-organization-structure`, {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {

      if (res.data.code === 200) {
        if (res.data.data.length > 0) {
          this.setState({
            addUseAble: "YES",
          });
        }
        if (res.data.data.length <= 0) {
          this.setState({
            addUseAble: "NO",
          });
        }
        this.setState({ "organization_structure": res.data.data });
        var organization_structure = res.data.data;
        if (organization_structure[0]) {

          let divisions = organization_structure[0].child;
          var divisions_list = [];
          for (var i = 0; i < divisions.length; i++) {
            var obj = {};
            obj.id = divisions[i].id;
            obj.name = divisions[i].node_name;
            obj.value = divisions[i].id;
            obj.user_name = divisions[i].user[0].first_name + " " + divisions[i].user[0].last_name;
            if (i == 0) {
              // this.setState({ defaultdivisionSelectText: obj.name });
              // this.setState({ division_id: divisions[i].id });
              this.setState({ defaultdivisionSelectText: "Select Division" });
              this.setState({ division_id: "" });
              var division_manager = "";
              if (divisions[i].user[0]) {
                division_manager = divisions[i].user[0].first_name + " " + divisions[i].user[0].last_name;
                // this.setState({ division_manager: division_manager });
                this.setState({ division_manager: "" });
              }
              var team_data = divisions[i].child;
              var team_list = [];
              for (var k = 0; k < team_data.length; k++) {
                var obj1 = {};
                obj1.id = team_data[k].id
                obj1.name = team_data[k].node_name;
                obj1.value = team_data[k].id;
                obj1.user_name = team_data[i].user[0].first_name + " " + team_data[i].user[0].last_name;
                if (k == 0) {
                  // this.setState({ defaultteamSelectText: obj1.name });
                  // this.setState({ team_id: obj1.id });
                  this.setState({ defaultteamSelectText: "Select Team" });
                  this.setState({ team_id: "" });
                  var sales_manager = "";
                  if (team_data[i].user[0]) {
                    sales_manager = team_data[i].user[0].first_name + " " + team_data[i].user[0].last_name;
                    // this.setState({ sales_manager: sales_manager });
                    this.setState({ sales_manager: "" });
                  }
                }
                team_list.push(obj1);
              }
              // this.setState({ teamList: team_list });
              this.setState({ teamList: [] });

            }
            divisions_list.push(obj);
          }
          this.setState({ divisionList: divisions_list });

          console.log("ORG ^ division list", this.state.divisionList);
        }

        console.log("get org structure api integration in react", this.state.organization_structure);
      }
      else {
        this.setState({
          Message: "Server Error.",
          MessageType: 'danger',
          fileextractDone: 0,
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })


  }
  getAllDivisionsTeams = async () => {
    await axios.get(`/organizations/get-all-divisions-teams`, {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      if (res.data.code === 200) {
        this.setState({ "all_division_teams": res.data.data });
      }
      else {
        this.setState({
          Message: "Server Error.",
          MessageType: 'danger',
          fileextractDone: 0,
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })


  }
  handleSubmit = async (event) => {
    event.preventDefault();
    var error_message = "";
    var is_validation_pass = true;
    let postBodyData = {
      "first_name": this.state.first_name,
      "last_name": this.state.last_name,
      "email": this.state.email,
      "phone": this.state.phone,
      "role_id": this.state.role_id,
      "division_id": this.state.division_id,
      "team_id": this.state.team_id
      // "role_id":3,
      //"division_id":2,
      //"team_id":3
    }
    console.log("postBodyData", postBodyData);
    if (postBodyData.first_name === "") {
      is_validation_pass = false;
      error_message = error_message + "First Name is required.";
    }
    if (postBodyData.last_name === "") {
      is_validation_pass = false;
      error_message = error_message + " Last Name is required.";
    }

    if (postBodyData.email === "") {
      is_validation_pass = false;
      error_message = error_message + " Email is required.";
    } else {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(postBodyData.email)) {
        is_validation_pass = false;
        error_message = error_message + " Invalid Email";
      }
    }
    if (postBodyData.phone == "") {
      is_validation_pass = false;
      error_message = error_message + " Phone is required.";
    } else {
      var pattern = new RegExp(/^[0-9]{11,14}$/g);
      if (!pattern.test(postBodyData.phone)) {
        is_validation_pass = false;
        error_message = error_message + " Invalid Phone.";
      }
    }
    if (postBodyData.role_id == "") {
      is_validation_pass = false;
      error_message = error_message + " Select Role.";
    } if (postBodyData.division_id == "") {
      is_validation_pass = false;
      error_message = error_message + " Select Division.";
    } if (postBodyData.team_id == "") {
      is_validation_pass = false;
      error_message = error_message + " Select Team.";
    }

    if (is_validation_pass) {
      await axios.post(`users/add-manual`, postBodyData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        }
      }).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState({
            Message: "User created successfully. Invitation sent.",
            MessageType: 'success',
          }, () => {
            setTimeout(() => {
              this.setState({
                Message: "",
                MessageType: null,
              });
              this.cancelPreviewProfileShow(false);
              window.location.href = "/system-admin/user-management";
            }, 3000);
          });

        }
        else {
          this.setState({
            Message: res.data.message,
            MessageType: 'danger',
            fileextractDone: 0,
          });
        }
      })
        .catch((error) => {
          console.log(error)
        })
    } else {
      this.setState({
        Message: error_message,
        MessageType: 'danger',
      });
    }
  }

  handleChange(event) {
    console.log(event.target);
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
    switch (name) {
      case 'first_name':

        var pattern = new RegExp(/^[0-9a-zA-Z\s]*$/i);
        if (!pattern.test(value)) {
          this.setState({
            Message: "First name should be characters only.",
            MessageType: 'danger',
          });
        } else {
          this.setState({
            Message: "",
            MessageType: '',
          });
        }
        break;

      case 'last_name':
        var pattern = new RegExp(/^[0-9a-zA-Z\s]*$/i);
        if (!pattern.test(value)) {
          this.setState({
            Message: "Last name should be characters only.",
            MessageType: 'danger',
          });
        } else {
          this.setState({
            Message: "",
            MessageType: '',
          });

        }
        break;



      case 'email':
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(value)) {
          this.setState({
            Message: "Invalid Email",
            MessageType: 'danger',
          });
        } else {
          this.setState({
            Message: "",
            MessageType: '',
          });
          var user_name = value.split('@')[0];
          console.log("USERNAME", user_name);
          this.setState({ user_name: user_name });
        }
        break;

      case 'phone':

        var pattern = new RegExp(/^[0-9]{11,14}$/g);
        if (!pattern.test(value)) {
          this.setState({
            Message: "Phone number cannot be less than 11 digits and cannot exceed 14 digits.",
            MessageType: 'danger',
          });
        } else if ((value.length >= 15) || (value.length < 11)) {
          this.setState({
            Message: "Phone number cannot be less than 11 digits and cannot exceed 14 digits. ",
            MessageType: 'danger',
          });

        } else {
          this.setState({
            Message: "",
            MessageType: '',
          });
        }
        break;

      default:
        break;
    }
    console.log("FORM DATA", this.state);
  }


  handleRoleClick = (ele) => {
    this.setState({ role_id: ele });

    if (ele == 4) {
      this.setState({ showTeamOption: true });
    } else if (ele == 3) {
      this.setState({ showTeamOption: false });
      this.setState({ team_id: "" });
    }

  };

  handleDivisionClick = async (ele) => {
    var div_list = this.state.divisionList;


    this.setState({ division_id: ele });
    for (var j = 0; j < div_list.length; j++) {
      if (ele === div_list[j].id) {
        this.setState({ division_manager: div_list[j].user_name });
      }
    }


    var org_structure_data = this.state.organization_structure;
    for (var i = 0; i < org_structure_data.length; i++) {
      if (org_structure_data[0]) {
        let divisions = org_structure_data[0].child;

        for (var i = 0; i < divisions.length; i++) {
          if (divisions[i].id == ele) {
            var team_data = divisions[i].child;
            var team_list = [];
            console.log("@TEAM DATA", team_data);

            for (var k = 0; k < team_data.length; k++) {

              var obj1 = {};
              obj1.id = team_data[k].id
              obj1.name = team_data[k].node_name;
              obj1.value = team_data[k].id;
              if (team_data[k]) {
                obj1.user_name = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                if (k == 0) {
                  this.setState({ defaultteamSelectText: "Select Team" });
                  // this.setState({ defaultteamSelectText: obj1.name });
                  var sales_manager = "";
                  if (team_data[k].user[0]) {
                    sales_manager = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                    // this.setState({ sales_manager: sales_manager });
                    this.setState({ sales_manager: "" });
                  }
                }
              }
              team_list.push(obj1);
            }
            this.setState({ teamList: team_list });

          }

        }

      }
    }


  }
  handleTeamClick = (ele) => {
    this.setState({ team_id: ele });
    this.setState({ sales_manager: "TEAM MANAGER " + ele });


    var org_structure_data = this.state.organization_structure;
    for (var i = 0; i < org_structure_data.length; i++) {
      if (org_structure_data[0]) {
        let divisions = org_structure_data[0].child;

        for (var j = 0; j < divisions.length; j++) {
          var team_data = divisions[j].child;
          for (var k = 0; k < team_data.length; k++) {
            if (team_data[k].id == ele) {
              var sales_manager = "";
              if (team_data[k].user[0]) {
                sales_manager = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                //set sales manager 
                this.setState({ sales_manager: sales_manager });
              }
            }
          }
        }

      }
    }
  }
  addUser = () => {
    const { profileImg, orgName, showTeamOption, first_name, last_name, email, phone, user_name, role_id, division_id, division_manager, team_id, sales_manager, Message, MessageType } = this.state;

    return (<>
      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          {((Message) && (Message !== null)) ?
            (
              <div className={`alert alert-${MessageType}`}>
                {Message}
              </div>
            ) : (``)
          }
          <div className="row">
            <div className="col-lg-6">
              <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Personal
                Details</span></div>
              <div className="clearfix px-2">
                <div className="profile-pic float-sm-right">
                  <div className="img-wrap">
                    <img className="profile-pic-img" id="editprofile" src={profileImg} alt="profile" />
                  </div>
                  {/* <div className="p-image">
                    <i className="fa fa-camera upload-button" id="editprofilebtn"></i>
                    <input className="file-upload" id="editprofilefile" type="file" accept="image/*" />
                  </div> */}
                </div>
                <div className="profile-info float-sm-left">
                  <div className="row mt-4 mt-sm-0">
                    <div className="col form-group">
                      <label htmlFor="first_name" className="mb-0">First Name</label>
                      <input type="text" className="form-control f-600 f2015" name="first_name" value={first_name} defaultValue="" id="first_name" onChange={this.handleChange}
                        placeholder="First Name" />
                      {/* <span className='error'>kjlkld</span>  */}
                    </div>
                    <div className="col form-group">
                      <label htmlFor="last_name" className="mb-0">Last Name</label>
                      <input type="text" className="form-control f-600 f2015" name="last_name" value={last_name} defaultValue="" id="last_name" onChange={this.handleChange} placeholder="Last Name" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Email ID</label>
                    <input type="email" name="email" id="email" onChange={this.handleChange} className="form-control f-600 f2015" value={email} defaultValue=""
                      placeholder="Email" />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Mobile Number</label>
                    <input type="text" className="form-control f-600 f2015" name="phone" id="phone" onChange={this.handleChange} value={phone} defaultValue=""
                      placeholder="+1-252-266-3259" />
                  </div>
                </div>
              </div>
              <div className="form-head  f2015 my-3 clearfix" ><span
                className="bg-white d-inline-block f-600 pr-3">Login Details</span></div>
              <div className="form-group px-2">
                <label className="mb-0">User Name</label>
                <input type="text" className="form-control f-600 f2015" id="user_name" disabled="disabled" value={user_name} defaultValue="" placeholder="User Name" />
              </div>
              {/* <div className="form-group clearfix px-2">
                  <a className="color_blue py-3 underline f2015" href="javascript:;">Send Reset Password Link</a>
                </div> */}

            </div>
            <div className="col-lg-6">
              <div className="form-head mb-3 f2015  clearfix"><span
                className="bg-white d-inline-block f-600 pr-3">Organization Details</span></div>
              <div className="form-group px-2">
                <label className="mb-0">Organization Name</label>
                <div className="feild-text py-3 f-600 f2015">
                  {orgName}
                </div>
              </div>
              <div className="form-group px-2">
                <label className="mb-0">User Role</label>
                <CustomSelect
                  name="role_id"
                  className="form-control f-600 text-left"
                  defaultText={this.state.defaultroleSelectText}
                  optionsList={this.state.roleList}
                  onSelect={this.handleRoleClick}

                />

              </div>
              <div className="form-group px-2">
                <label className="mb-0">Sales Division</label>
                <CustomSelect name="division_id"
                  className="form-control f-600 text-left"
                  defaultText={this.state.defaultdivisionSelectText}
                  optionsList={this.state.divisionList}
                  onSelect={this.handleDivisionClick}
                />
              </div>
              <div className="form-group px-2">
                <label className="mb-0">Division Manager</label>
                <input type="text" disabled="disabled" className="form-control f-600 W-100 f2015" value={division_manager} />
              </div>
              {showTeamOption ? <>
                <div className="form-group px-2">
                  <label className="mb-0">Sales Team</label>
                  <CustomSelect name="team_id"
                    className="form-control f-600 text-left"
                    defaultText={this.state.defaultteamSelectText}
                    optionsList={this.state.teamList}
                    onSelect={this.handleTeamClick}
                  />
                </div>
                <div className="form-group px-2">
                  <label className="mb-0">Sales Manager</label>
                  <input type="text" disabled="disabled" className="form-control f-600 W-100 f2015" value={sales_manager} />
                </div></> : null}
            </div>
          </div>
        </div>
        <div className="modal-footer border-0 justify-content-center">
          {/* <button type="button" className="btn dark-btn mx-2" onClick={() => this.setPreviewProfileShow(false)}>Save</button> */}
          <button type="submit" className="btn dark-btn mx-2">Save</button>

          <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.cancelPreviewProfileShow(false)}>Cancel</button>
        </div>
      </form>

    </>);
  }

  cancelPreviewProfileShow = (newValue) => {
    this.setState({ addprofileShow: false });
    this.setState({ addUserShow: false });
  }
  render() {
    const { profileShow, editprofileShow, deactivateUsershow, addUserShow, inviteSendUsershow, MessageType, Message, fileextractDone, errorCells, addUseAble, addManually } = this.state

    return (<>
      <Modal
        title="Add User"
        contentClasses="editProfile"
        size="xl"
        onClose={() => this.setAddUserShow(false)}
        show={addUserShow}>
        {this.addUser()}
      </Modal>

      <main className="main-contant">
        <div className="contents">
          <h3 className="title-head m-0">User Management</h3>
          {((addUseAble === "NO")) ?
            (
              <div className="emptyOrganization text-center  py-4 justify-content-center align-items-center">
                <div>
                  <div className="divconmoon icon-warning mb-3"></div>
                  <p>
                    Users cannot be created without a complete organization  structure.
                    <div className="white-space-pre">Please complete the organization setup.</div>
                  </p>
                  <div className="color_blue py-2 underline">
                    {/* <a href={`./initiate-organization-structure`}  className="color_blue">Go to Organization  setup</a> */}
                    <Link to={`./initiate-organization-structure`} className="color_blue underline f16 f-500 px-0 py-2">Go to Organization  setup</Link>
                  </div>
                </div>
              </div>

            ) : (``)
          }
          {((addUseAble === "YES")) ?
            (
              <div>
                <div className="steps-container d-flex justify-content-center" >
                  <div className="steps-list-wrap d-flex color_blue justify-content-between py-3 align-items-center">
                    <div className="steps-list text-center f2015 px-4">
                      <div className="steps-count steps-completed">
                        1
                      </div>
                      <div className="steps-text mt-2">
                        Upload
                      </div>
                    </div>
                    <div className="steps-list text-center f2015 px-4">
                      <div className="steps-count">
                        2
                      </div>
                      <div className="steps-text mt-2">
                        Confirmation
                      </div>
                    </div>
                    <div className="steps-list text-center f2015 px-4">
                      <div className="steps-count">
                        3
                      </div>
                      <div className="steps-text mt-2">
                        Success
                      </div>
                    </div>
                  </div>
                </div>
                <div className="upload-files mx-auto f2015">
                  <div className="text-center">
                    <h5 className="top-fix-head min_head">To create new users with role Sales Rep: </h5>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="text-area text-left py-3 mx-auto f2015">
                        1. Download CSV template. <br />
                        2. Populate the template with data. <br />
                        3. Drag and drop the CSV file.<br />
                        4. Click Upload.
                      </div>
                    </div>
                    <img src={csv} alt="csv-file" />
                    <div className="color_blue py-4 underline">
                      <a href="../users_template.csv" className="color_blue" target="_blank">Click here to download CSV template</a>
                    </div>
                    <div className="color_blue py-4 underline">
                      <a href="../users_template.pdf" className="color_blue" target="_blank" download>Click here to download CSV instructions</a>
                    </div>
                  </div>
                  {((Message) && (Message !== null) && (MessageType === "success")) ?
                    (

                      <div className="upload-successfully mt-2">
                        <div className="drop-zone-wrap p-2">
                          <div className="drop-zone f2015 f-500">
                            <span className="drop-zone__prompt mt-3">
                              <i className="iconmoon icon-check check-successfully"></i>
                              <div className="color_blue mt-3">The user list has been uploaded successfully.</div>
                            </span>
                          </div>
                        </div>
                        <div className="text-center py-4">
                          <Link to={`./confirm-user-management`} type="btn" className="btn dark-btn">View Users List</Link>
                        </div>
                      </div>

                    ) : (``)
                  }

                  {((Message) && (Message !== null) && (MessageType === "danger")) ?
                    (
                      <div className="errors-div">
                        <div className="drop-zone-wrap p-2">
                          <div className="drop-zone f2015 f-500">
                            <span className="drop-zone__prompt mt-3">
                              <i className="iconmoon icon-warning"></i>
                              <div className="color_blue mt-3">The following errors have been found in the uploaded CSV file: <br /><span dangerouslySetInnerHTML={{ __html: errorCells }}></span><br /><span>Please correct the errors in the CSV file, then click Restart Process and upload the corrected file.</span></div>
                            </span>
                          </div>
                          <div className="text-center py-4">
                            <a href="./initiate-user-management" className="color_blue underline f-2015"><i className="fa fa-refresh"></i> Restart Process</a>
                          </div>
                        </div>
                      </div>
                    ) : (``)
                  }
                  {((fileextractDone === 0) && ((!Message) || (Message === null) || (MessageType === ""))) ?
                    (

                      <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        onRemoveFile={this.handleOnRemoveFile}
                        style={{
                          dropArea: {
                            borderColor: 'gray',
                            borderRadius: 20,
                            maxHeight: 200
                          },
                          dropAreaActive: {
                            borderColor: '#003366',
                          },
                          dropFile: {
                            width: 240,
                            height: 120,
                            background: 'transparent',
                          },
                          fileSizeInfo: {
                            color: 'black',
                            backgroundColor: 'transparent',
                            borderRadius: 3,
                            lineHeight: 1,
                            marginBottom: '0.5em',
                            padding: '0 0.4em',
                          },
                          fileNameInfo: {
                            color: 'black',
                            backgroundColor: 'transparent',
                            borderRadius: 3,
                            fontSize: 14,
                            lineHeight: 1,
                            padding: '0 0.4em',
                          },
                          removeButton: {
                            color: '#003366',
                          },
                          progressBar: {
                            backgroundColor: '#003366',
                          },
                        }}
                      >
                        <div className="drop-zone-wrap p-2" >
                          <div className="drop-zone f2015 f-500">
                            <span className="drop-zone__prompt mt-3">
                              Drag and drop CSV file or
                              <span className="color_blue underline"> Browse here</span>
                            </span>
                          </div>
                        </div>
                      </CSVReader>
                    ) : (``)}
                  {(fileextractDone === 1) ? (
                    <div className="upload-successfully mt-2">
                      <div className="drop-zone-wrap p-2">
                        <div className="drop-zone f2015 f-500">
                          <span className="drop-zone__prompt mt-3">
                            <i className="iconmoon icon-check check-successfully"></i>
                            <div className="color_blue mt-3">The CSV file was extracted successfully. Click Upload to complete the process.</div>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (``)}
                  <div className="text-center py-4">
                    {(fileextractDone === 1) ? (
                      <button onClick={() => this.userUpload()} type="btn" className="btn dark-btn">Upload</button>
                    ) : (``)}
                    {(fileextractDone === 0) ? (<>

                      {(addManually === "YES") ? (<>
                      </>
                      ) : (``)}
                    </>
                    ) : (``)}
                  </div>
                </div>
              </div>
            ) : (``)
          }


        </div>
      </main>


    </>
    )
  }
}
