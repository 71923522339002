import React from 'react'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Modal from "../../components/system_admin/modals/modal";
import img5 from "../../images/user-img5.png";
import img6 from "../../images/user-img6.png";
import TimePicker from 'react-time-picker';
import CustomSelect from "../system_admin/elements/customSelect";
import axios from '../apiConnection/axios';
import { Link} from 'react-router-dom';
import { weekdays } from 'moment'
import $ from 'jquery';
import moment from 'moment'

import zIndex from '@material-ui/core/styles/zIndex'

import Utility from "../../components/utility";
const utilityObj = new Utility;

export default class DemoApp extends React.Component {
  constructor(props) {

    super(props);
    const urlSearchParameters = new URLSearchParams(window.location.search);
    const parameters = Object.fromEntries(urlSearchParameters.entries());
    var notification_session_event_id = "";
    var calendar_v = "GENERAL";
    if(parameters.session_event_id){
      notification_session_event_id = parameters.session_event_id;
    }
    const user_role = localStorage.getItem('adminsrToken');
    var calender_initial_view ="";
    if(user_role==4){
      if(notification_session_event_id!=""){
        this.showNotificationEventModal(notification_session_event_id);
       }else{
         calender_initial_view = new Date();
       }
    }else{
       calender_initial_view = new Date();
    }
   
    var calendar_default_view = "dayGridMonth";

    if(parameters.view=="day"){
      calendar_default_view = "timeGridDay";
    }
   

 
    this.state = {
      test:"test",
      events:[],
      weekendsVisible: true,
      currentEvents: [], 
      summary_view:1,
      session_event_detail:"",
       eventDetailShow : false,
       rescheduleSessionShow : false, 
       acceptanceSessionShow : false, 
       rescheduleActive : false,
       defaulttaskSelectText:"Confirmed customer's buying process1",
       taskList: [
         { id: 1, name: "Confirmed customer's buying process1" },
         { id: 2, name: "Confirmed customer's buying process2" },
         { id: 3, name: "Confirmed customer's buying process3" },
         { id: 4, name: "Confirmed customer's buying process4" },
       ],
       defaultopprtunitySelectText:"Opportunity 51",
       opportunityList: [
         { id: 1, name: "Opportunity 51" },
         { id: 2, name: "Opportunity 52" },
         { id: 3, name: "Opportunity 53" },
         { id: 4, name: "Opportunity 54" },
       ],
       nylasData : "start",
       eventInfo: [],
       rescheduleReason: "",
       eventID : "",
       ButtonHideA : false,
       calendar_default_view : calendar_default_view, 
       notification_session_event_id : notification_session_event_id,
       sales_manager_ids:[],
       session_start_time:calender_initial_view,

    }


}


  componentDidMount() {
    
    const user_role = localStorage.getItem('adminsrToken');
    
    if(user_role==4 || user_role==3){
      this.getSessionEvents(user_role);
    }else{
      this.getOrganizationResources(user_role);
    }
    this.setState({ user_role });

    const user_id = localStorage.getItem('userid');
    this.setState({ user_id }, ()=>{
        this.getNylasToken();
    });

    // if(user_role==4){
    //   if(this.state.notification_session_event_id!=""){
    //     this.showNotificationEventModal(this.state.notification_session_event_id);
    //    }
    // }


  }
  showNotificationEventModal  = async(session_event_id)=>{
    utilityObj.loaderView("show");
    var payload = {session_event_id:session_event_id}
    await axios.post(`sales-rep/get-session-event-detail`,payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
        .then(res => {
            if(res.data.code===200){
              var event_data = {};
              var response_data = res.data.data[0];
              
           
                var session_start_time = response_data.start;   
                this.setState({session_start_time});
                if(response_data.status=="INVITESENT"){
                  event_data = response_data;
                  event_data.sales_manager_profile_pic_url = response_data.sales_rep_profile_pic_url;
                  event_data.sales_manager_name = response_data.sales_rep_name;
                  event_data.end_time = response_data.end;
                  event_data.start_time = response_data.start;
                
                  this.setState({eventInfo:response_data,eventTitle:response_data.title,eventID:response_data.id},()=>{this.setAcceptanceSessionShow(true);});
                    
                } else{
                  event_data.extendedProps = response_data;
                  event_data.status = response_data.status;
                  event_data.title = response_data.title;
                  event_data.extendedProps.sales_manager_profile_pic_url = response_data.sales_rep_profile_pic_url;
                  event_data.extendedProps.sales_manager_name = response_data.sales_rep_name;
                  event_data.extendedProps.end_time = response_data.end;
                  event_data.extendedProps.start_time = response_data.start;
                  this.setState({session_event_detail:event_data},()=>{this.setEventDetailShow(true); });
                }
              
             
                
               utilityObj.loaderView("hide");
            }else{
               utilityObj.loaderView("hide");
            }
          }
       
        )
      .catch((error) => { 
        console.log(error)
    });
  }
    getNylasToken = async () => {
        const {user_id} = this.state;
        const profileinfo = {
            user_id,
        }        

        await axios.post(`/nylas/get-token`, profileinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                if (res.data.code === 200) {
                    this.setState({nylasData : res.data.data});
                } else {
                    this.setState({nylasData : []});
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }
  setRescheduleSessionShow =  (newValue) => {
    this.setState({rescheduleSessionShow:newValue});
  }
  showRescheduleSession = () => {
    const {viewModel,eventDetailShow,createSessionShow,rescheduleSessionShow,value, onChange} = this.state;

    return(<> 
      <form>

         
       <div className="modal-body">
         <div className="form-group">
           <label className="mb-0">Event Title</label>
           <div className="feild-text py-2 f-600 f2015">
             <input type="text" className="form-control f-600 f2015" defaultValue="Coaching for Opportunity 51"
                       placeholder="Full Name"/>
           </div>
         </div>
         <div className="form-group">
           <label className="mb-0">Select Opportunity</label>
           <div className="feild-text  f-600 f2015">
             <CustomSelect
               className="form-control f-600 text-left"
               defaultText={this.state.defaultopprtunitySelectText}
               optionsList={this.state.opportunityList}
               />
           </div>
         </div>
         <div className="form-group">
           <label className="mb-0">Select Task</label>
           <div className="feild-text  f-600 f2015">
           <CustomSelect
               className="form-control f-600 text-left"
               defaultText={this.state.defaulttaskSelectText}
               optionsList={this.state.taskList}
               />
           </div>
         </div>
         <div className="form-group">
           <ul className="shedule-details f-500 list-unstyled">
             <li className="pb-2 pt-1 mb-2 mt-1">
               <i className="ri-lightbulb-line color_blue mr-2"></i> Prospect Stage
             </li>
             <li className="pb-2 pt-1 mb-2 mt-1">
               <i className="ri-calendar-event-line color_blue mr-2 "></i> Thursday, 1 December 2021
             </li>
             <li className="pb-2 pt-1 mb-2 mt-1">
               <i className="ri-time-line color_blue mr-3"></i> 
               <TimePicker
    onChange={onChange}
    value={"11:00"} disableClock={true}
  />  <TimePicker
  onChange={onChange} disableClock={true}
  value={"12:00"}
/>
             </li>
             <li className="pb-2 pt-1 mb-2 mt-1">
               <i className="ri-live-line color_blue mr-2 "></i> <span className="color_blue underline"> https://us02web.zoom.us/j/863708448?pwd=N2FwWD </span>
             </li>
             <li className="pb-2 pt-1 mb-2 mt-1">
               <div className="main-profile d-flex  align-items-center">
                 <div className="img-wrap">
                     <img src={img5} alt="" />
                 </div>
                 <div className="ml-2">
                     <div className="name f-500 mb-1">
                     Anthony Miles
                     </div>
                 </div>
             </div>
             </li>
             <li className="pb-2 pt-1 mb-2 mt-1">
               <div className="main-profile d-flex  align-items-center">
                 <div className="img-wrap">
                     <img src={img6} alt="" />
                 </div>
                 <div className="ml-2">
                     <div className="name f-500 mb-1">
                         George Swanson
                     </div>
                 </div>
             </div>
             </li>
           </ul>
         </div>
        
       </div>
       {/* <div className="modal-footer justify-content-center">
         <button type="button" className="btn dark-btn mx-2" onClick={()=>this.setAcceptanceSessionShow(false)}>Reschedule</button>
         <button type="button" className="btn light-btn  cancel-btn mx-2" data-dismiss="modal" onClick={() => this.setRescheduleSessionShow(false)}>Cancel</button>
       </div> */}
     </form>
     </>
     
     
     );
  }
  setEventDetailShow = (newValue) => {
    this.setState({eventDetailShow:newValue});
  }
  showSessionDetail = () => {
    const { session_event_detail } = this.state;
   
    if(session_event_detail){
     
      var stage_pic =  process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((session_event_detail.extendedProps.stage_icon_url!=="") && (session_event_detail.extendedProps.stage_icon_url!==null) )? session_event_detail.extendedProps.stage_icon_url : 'default_sales_stage.png');  
      var sales_manager_pic =  process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((session_event_detail.extendedProps.sales_manager_profile_pic_url!=="") && (session_event_detail.extendedProps.sales_manager_profile_pic_url!==null) )? session_event_detail.extendedProps.sales_manager_profile_pic_url : 'default-avatar.png');  
      var sales_rep_name =  localStorage.getItem('full_name');
      var sales_rep_profile_pic =  localStorage.getItem('profilePic');
    return(<>  <form>
      <div className="modal-body">
        <div className="form-group">
          <label className="mb-0">Event Title</label>
          <div className="feild-text py-2 f-600 f2015">
          {session_event_detail.extendedProps.event_title}
          </div>
        </div>
        <div className="form-group">
          <label className="mb-0">Opportunity</label>
          <div className="feild-text py-2 f-600 f2015">
          {session_event_detail.title}
          </div>
        </div>
        <div className="form-group">
          <label className="mb-0">Task</label>
          <div className="feild-text py-2 f-600 f2015">
          {session_event_detail.extendedProps.task}
          </div>
        </div>
        <div className="form-group">
          <ul className="shedule-details f-500 list-unstyled">
            <li className="pb-2 pt-1 mb-2 mt-1">
              {/* <i className="ri-lightbulb-line color_blue mr-2"></i>  */}
              <img src={stage_pic} className='color_blue mr-2' alt="" width="25px" height="25px"/>
              <span className="d-inline-block" style={{marginLeft: "3px"}}>

               {session_event_detail.extendedProps.stage} Stage
               </span></li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <i className="ri-calendar-event-line color_blue mr-2 "></i>   {/* Thursday, 1 December 2021 */}
              {moment(session_event_detail.extendedProps.start_time).format("dddd, DD MMMM yyyy")}
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <i className="ri-time-line color_blue mr-2 pr-1"></i>{moment(session_event_detail.extendedProps.start_time).format("hh:mma")}  -  {moment(session_event_detail.extendedProps.end_time).format("hh:mma")}

            </li>
            <li className="pb-2 pt-1 mb-2 mt-1 d-flex align-items-center">
              <i className="ri-live-line color_blue mr-3"></i><div style={{wordBreak: 'break-all'}}>
               {session_event_detail.extendedProps.meeting_link!="" ? <a className="color_blue underline"  href={session_event_detail.extendedProps.meeting_link} target="_blank"> 
                 {session_event_detail.extendedProps.meeting_link}</a>:"Meeting link will generate shortly."}
                 </div>
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <div className="main-profile d-flex  align-items-center">
                <div className="img-wrap">
                <img src={sales_manager_pic} alt="" />
                </div>
                <div className="ml-2">
                    <div className="name f-500 mb-1">
                    {session_event_detail.extendedProps.sales_manager_name}
                    </div>
                </div>
            </div>
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <div className="main-profile d-flex  align-items-center">
                <div className="img-wrap">
                <img src={sales_rep_profile_pic} alt="" />
                </div>
                <div className="ml-2">
                    <div className="name f-500 mb-1">
                        {sales_rep_name}
                    </div>
                </div>
            </div>
            </li>
          </ul>
        </div>
        <div className="form-group">
          <label className="mb-0">Description</label>
          <div className="feild-text py-2 word-break-all f2015">
          {session_event_detail.extendedProps.description} 
          </div>
        </div>
        {session_event_detail.extendedProps.status=="RESCHEDULED"?
        <div className="form-group">
          <label className="mb-0">Reschedule Reason</label>
          <div class="rescheduled_note">
          {(session_event_detail.extendedProps.reschedule_reason && session_event_detail.extendedProps.reschedule_reason.length>0) ? (
          session_event_detail.extendedProps.reschedule_reason.map((reschedule_note) => {return(
             <div className="form-group">           
                <div className="feild-text py-3 word-break-all f2015 color_dark ">{reschedule_note.note_body}</div>           
        </div>);
          }))
          :null}
          </div>
          {/* id="scroll-hide" */}
          {/* <textarea type="text" className="form-control f-600 f2015" disabled="disabled" rows={2} maxRows={2} id={`rescheduleReason`} value={session_event_detail.extendedProps.note_body}   ></textarea> */}
        </div>
        :null}
        {/* <div className="form-group">
          <label className="mb-0">Task</label>
          <textarea type="text" className="form-control f-600 f2015" value=" Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt." placeholder="Task"></textarea>
        </div> */}
      </div>
      {/* <div className="modal-footer justify-content-center">
        <button type="button" className="btn dark-btn mx-2">Accept</button>
        <button type="button" className="btn light-btn  cancel-btn mx-2" data-dismiss="modal">Rescheduled</button>
      </div> */}
    </form>
    </>);
    }
    };

    showReschedulePopup = (newValue)=>{
      this.setState(prevState => ({
        ...prevState,
        rescheduleActive:newValue
      }), ()=>{
        
      });
  
      // this.setRescheduleSessionShow(true);
      // this.showRescheduleSession(true);
    }
    setAcceptanceSessionShow = (newValue) => {
      this.setState(prevState => ({
        ...prevState,
        acceptanceSessionShow:newValue, rescheduleReason:""
      }), ()=>{
        
      });
    };

    showAcceptanceSessionDetail = () => {
      const {eventInfo, eventTitle, Message, MessageType, rescheduleReason, ButtonHideA} = this.state;
      var stage_pic =  process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((eventInfo.stage_icon_url!=="") && (eventInfo.stage_icon_url!==null) ) ? eventInfo.stage_icon_url : 'default_sales_stage.png');  
      var sales_man_pic =  process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((eventInfo.sales_manager_profile_pic_url!=="") && (eventInfo.sales_manager_profile_pic_url!==null) )? eventInfo.sales_manager_profile_pic_url : 'default-avatar.png');  
      var sales_rep_name =  localStorage.getItem('full_name');
      var sales_rep_profile_pic =  localStorage.getItem('profilePic');

      return(<>  <form>
        <div className="modal-body">
        {((Message) && (Message !== null)) ?
          (
            <div className={`alert alert-${MessageType}`}>
              {Message}
            </div>
          ) : (``)
        }   

          <div className="form-group">
            <label className="mb-0">Event Title</label>
            <div className="feild-text py-2 f-600 f2015">
              {eventInfo.event_title}
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Opportunity</label>
            <div className="feild-text py-2 f-600 f2015">
              {eventTitle}
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Task</label>
            <div className="feild-text py-2 f-600 f2015">
            {eventInfo.task}
            </div>
          </div>
          <div className="form-group">
            <ul className="shedule-details list-unstyled">
              <li className="pb-2 pt-1 mb-2 mt-1">
                {/* <div className="main-profile d-flex  align-items-center">
                  <div className="img-wrap">
                      <img src={stage_pic} alt="" class="color_blue mr-3" alt="" width="16px" height="16px"/>
                  </div>
                  <div className="ml-2">
                      <div className="name f-500 mb-1">
                      {eventInfo.stage} Stage
                      </div>
                  </div>
              </div> */}
              <div class=" d-flex  align-items-center">
                <div class="img-wrap">
                <img src={stage_pic} alt="" class="color_blue mr-2" width="25px" height="25px"/>

              </div>
             <div class="name f-500 " style={{marginLeft: "3px"}}> {eventInfo.stage} Stage</div>
            
              </div>
              </li>              
              {/* <li className="pb-2 pt-1 mb-2 mt-1">
                <i className="ri-lightbulb-line color_blue mr-2"></i> {eventInfo.stage} Stage
              </li> */}
              <li className="pb-2 pt-1 mb-2 mt-1">
                <i className="ri-calendar-event-line color_blue mr-3"></i> 
                {/* Thursday, 1 December 2021 */}
                {moment(eventInfo.start_time).format('dddd, DD MMMM yyyy')}
                
              </li>
              <li className="pb-2 pt-1 mb-2 mt-1">
                <i className="ri-time-line color_blue mr-3"></i>{moment(eventInfo.start_time).format('hh:mm a')}  -  {moment(eventInfo.end_time).format('hh:mm a')}
              </li>
              <li className="pb-2 pt-1 mb-2 mt-1 d-flex align-items-center">
                <i className="ri-live-line color_blue mr-3"></i>
                <div style={{wordBreak: 'break-all'}}>
                <a className="color_blue underline"  href={eventInfo.meeting_link} target="_blank">{eventInfo.meeting_link} </a>
                </div>
              </li>


              <li className="pb-2 pt-1 mb-2 mt-1">
                <div className="main-profile d-flex  align-items-center">
                  <div className="img-wrap">
                      <img src={sales_man_pic} alt="" />
                  </div>
                  <div className="ml-2">
                      <div className="name f-500 mb-1">
                      {eventInfo.sales_manager_name}                  
                      </div>
                  </div>
              </div>
              </li>

              <li className="pb-2 pt-1 mb-2 mt-1">
                <div className="main-profile d-flex  align-items-center">
                  <div className="img-wrap">
                      <img src={sales_rep_profile_pic} alt="" />
                  </div>
                  <div className="ml-2">
                      <div className="name f-500 mb-1">
                      {sales_rep_name}
                      </div>
                  </div>
              </div>
              </li>              
            </ul>
          </div>
          <div className="form-group">
            <label className="mb-0">Description</label>
            <div className="feild-text py-2 word-break-all f2015">
            {eventInfo.description}
            </div>
          </div>
          {this.state.rescheduleActive ? (            
          <div className="form-group">
          <label className="mb-0">Reschedule Reason</label>
          {/* id="scroll-hide" */}
          <textarea type="text" className="form-control f-600 f2015"  rows={2} maxRows={2} id={`rescheduleReason`} defaultValue={rescheduleReason} onChange={(e)=>this.changeFunction(e)} placeholder="Please add next available date and time for the Sales Manager." ></textarea>
        </div>
          ) : (
            ""
          )}
        </div>
        {this.state.rescheduleActive ? (            
          <div className="modal-footer justify-content-center">
{(ButtonHideA!==true) ? (
  <button type="button" className="btn dark-btn px-3 mx-2" onClick={()=>this.confirmAccept('RESCHEDULED')}>Reschedule</button>
) : (
  <button type="button" className="btn light-btn px-3 mx-2" disabled>Reschedule</button>
)}            
          <button type="button" className="btn light-btn f-400 cancel-btn mx-2" onClick={()=>this.showReschedulePopup(false)} data-dismiss="modal">Cancel</button>
        </div>
          ) : (
            <div className="modal-footer justify-content-center">
            {/* <button type="button" className="btn dark-btn mx-2" onClick={()=>this.setAcceptanceSessionShow(false)}>Accept</button> */}
            <button type="button" className="btn dark-btn mx-2" onClick={()=>this.confirmAccept('SCHEDULED')}>Accept</button>
            <button type="button" className="btn light-btn f-400 cancel-btn mx-2" onClick={()=>this.showReschedulePopup(true)} data-dismiss="modal">Reschedule</button>
          </div>
          )}
       
       
      </form>
      </>);
      };
      changeFunction = (e) => {
        if(e.target.value.trim().length == 0){
          e.target.value = "";
        }
          var s = e.target.value;
          var keyid = e.target.id;
    
          if (keyid == "rescheduleReason") {
            if (e.target.value.length>255){
              this.setState(prevState => ({
                ...prevState,
                Message : "Reschedule reason text should be max 255 characters.",
                MessageType : "danger",
                ButtonHideA : true
              }), ()=>{
                this.setState(prevState => ({
                  ...prevState,
                  rescheduleReason: s
                }));        
              });

            } else {
              this.setState(prevState => ({
                ...prevState,
                Message : null,
                MessageType : null,
                ButtonHideA : false
              }), ()=>{
                this.setState(prevState => ({
                  ...prevState,
                  rescheduleReason: s
                }));        
              });        
            }  

          } 
        }
      confirmAccept = async (status) => {
          const {user_id, eventInfo, rescheduleReason, eventID } = this.state;
          if ((status=="RESCHEDULE") && ((rescheduleReason=="") || (!rescheduleReason))){
            this.setState({
              Message: "Reschedule reason is required.", 
              MessageType : "danger"
              }, ()=>{
                setTimeout(() => {
                  this.setState({
                    Message: "", 
                    MessageType : null
                  });
                }, 3000)
              })  
              return;  
          }

          const payload = {
            "session_event_id" : eventID,
            "sales_rep_id" : user_id,
            "status" : status,
            "note_body" : rescheduleReason
          }
          await axios.post(`calendar/accept-request-reschedule-session`, payload, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
            }
          })
          .then(res => {
           
            if (res.data.code === 200) {
              if (status=="SCHEDULED") {
                this.setState({
                  Message: "Event schedule request accepted.", 
                  MessageType : "success"
              }, ()=>{
                setTimeout(() => {
                  this.setState({
                    Message: "", 
                    MessageType : null
                  });
                  this.setAcceptanceSessionShow(false);
                  // this.componentDidMount();
                  const user_role = localStorage.getItem('adminsrToken');
                  this.getSessionEvents(user_role);
                }, 3000)
              })

              } else if (status=="RESCHEDULED"){

                this.setState({
                        Message: "Event reschedule request updated.", 
                        MessageType : "success"
                    }, ()=>{
                      setTimeout(() => {
                        this.setState({
                          Message: "", 
                          MessageType : null
                        });
                        this.setAcceptanceSessionShow(false);
                        // this.componentDidMount();
                        const user_role = localStorage.getItem('adminsrToken');
                         this.getSessionEvents(user_role);
                      }, 3000)
                    })
              }
            } else {
              this.setState({
                Message: res.data.message, 
                MessageType : "danger"
                }, ()=>{
                  setTimeout(() => {
                    this.setState({
                      Message: "", 
                      MessageType : null
                    });
                  }, 3000)
                })              
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
      getOrganizationResources = async (user_role) => {
  
        await axios.get(`organizations/get-organization-structure`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
            .then(res => {
           
                if(res.data.code==200){
                  
                  var user_id = localStorage.getItem('userid');
                  var org_data = res.data.data;
                  var org_resources = [];
                  var sales_manager_ids = [];
                      if(org_data.length>0){
                        var is_org_head = 0;
                        if(org_data[0].user && org_data[0].user.length>0){
                          if(org_data[0].user[0].id == user_id){
                            is_org_head = 1;
                            this.setState({is_org_head:1});
                            this.setState({resource_name:org_data[0].node_name});
    
                          }
                        }
    
                        var division_data = org_data[0].child;
    
                      
                        if(user_role==3){
                          if(division_data && division_data.length>0){
                            for(var j=0;j<division_data.length;j++){
                            var sales_manager_data = division_data[j].child;
                            
                            
                            for(var k=0;k<sales_manager_data.length;k++){
                              if(sales_manager_data[k].user.length>0){              
                              var sales_manager_detail = sales_manager_data[k].user[0];
                            
                            
                                  if(sales_manager_detail.id==user_id){
                                    var node_name = sales_manager_data[k].node_name;
                                    this.setState({resource_name:node_name});
                                    var sales_rep_data = sales_manager_data[k].users;
                                   
                                    if(sales_rep_data && sales_rep_data.length>0){
                                      for(var m=0;m<sales_rep_data.length;m++){
                                        var sales_rep_details = sales_rep_data[m];                             
                                       
                                        var resource_obj = {};
                                        resource_obj.id= sales_rep_details.id;
                                        var salesrep_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((sales_rep_details.profile_pic_url!=="") && (sales_rep_details.profile_pic_url!==null) )? sales_rep_details.profile_pic_url : 'default-avatar.png');                                 
                                        resource_obj.name= sales_rep_details.first_name+" "+sales_rep_details.last_name+":@:"+salesrep_profile_pic;            
                                        org_resources.push(resource_obj);
                                      }
                                    }
                                    break;
                                  }
                              }
    
                            }
                            }
                          }
                        
                         
                          
                          }else if(user_role==2){
                            
                            if(is_org_head==0){
                            if(division_data && division_data.length>0){
                              for(var j=0;j<division_data.length;j++){
                                var division_detail = division_data[j];
                                
                                //DIVISION MANAGER IN ORGANISATION
                                if(division_detail.user && division_detail.user.length>0){
                                  if(user_id==division_detail.user[0].id){
                                    // var resource_obj = {};
                                    var division_manager_id =  division_detail.user[0].id;
                                    // resource_obj.id = division_manager_id;
                                    // var div_manager_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((division_detail.user[0].profile_pic_url!=="") && (division_detail.user[0].profile_pic_url!==null) )? division_detail.user[0].profile_pic_url : 'default-avatar.png');                                 
                                    // resource_obj.name= division_detail.user[0].first_name+" "+division_detail.user[0].last_name+":@:"+div_manager_profile_pic;            
                                    // org_resources.push(resource_obj);
                                    this.setState({resource_name:division_detail.node_name});
    
                                      //SALES MANAGERS IN DIVISION
                                    var teams_in_division = division_detail.child;
                                    if(teams_in_division && teams_in_division.length>0){
                                      for(var s_m= 0; s_m<teams_in_division.length;s_m++){
                                        var team_detail = teams_in_division[s_m];
                                        
                                       
                                        if(team_detail.user && team_detail.user.length>0){
                                            var resource_obj = {};
                                            var sales_manager_id =  team_detail.user[0].id;
                                            
                                            sales_manager_ids.push(sales_manager_id);
                                            resource_obj.id = sales_manager_id;
                                            var sales_manager_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((team_detail.user[0].profile_pic_url!=="") && (team_detail.user[0].profile_pic_url!==null) )? team_detail.user[0].profile_pic_url : 'default-avatar.png');                                 
                                            resource_obj.name= team_detail.user[0].first_name+" "+team_detail.user[0].last_name+":@:"+sales_manager_profile_pic;            
                                            org_resources.push(resource_obj);
    
                                            var sales_reps_data = team_detail.users;
                                            if(sales_reps_data && sales_reps_data.length>0){
                                              for(var s_r=0;s_r<sales_reps_data.length;s_r++){
                                                var sales_rep_detail = sales_reps_data[s_r];
                                                
                                                var resource_obj = {};
                                                var sales_rep_id =  sales_rep_detail.id;
                                                resource_obj.id = sales_rep_id;
                                                var sales_rep_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((sales_rep_detail.profile_pic_url!=="") && (sales_rep_detail.profile_pic_url!==null) )? sales_rep_detail.profile_pic_url : 'default-avatar.png');                                 
                                                resource_obj.name= sales_rep_detail.first_name+" "+sales_rep_detail.last_name+":@:"+sales_rep_profile_pic;            
                                                resource_obj.parentId = sales_manager_id;
                                                org_resources.push(resource_obj);
                                              }
                                            }
    
                                        
                                        }
                                      }
    
                                    }
                                  }
                                }
                              
    
                              }}                      
                            }else if(is_org_head==1){
                             
                              if(division_data && division_data.length>0){
                                for(var j=0;j<division_data.length;j++){
                                  var division_detail = division_data[j];
                                  
                                  //DIVISION MANAGER IN ORGANISATION
                                  if(division_detail.user && division_detail.user.length>0){
                                   
                                      var resource_obj = {};
                                      var division_manager_id =  division_detail.user[0].id;
                                      resource_obj.id = division_manager_id;
                                      var div_manager_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((division_detail.user[0].profile_pic_url!=="") && (division_detail.user[0].profile_pic_url!==null) )? division_detail.user[0].profile_pic_url : 'default-avatar.png');                                 
                                      resource_obj.name= division_detail.user[0].first_name+" "+division_detail.user[0].last_name+":@:"+div_manager_profile_pic;            
                                      org_resources.push(resource_obj);
      
                                        //SALES MANAGERS IN DIVISION
                                      var teams_in_division = division_detail.child;
                                      if(teams_in_division && teams_in_division.length>0){
                                        for(var s_m= 0; s_m<teams_in_division.length;s_m++){
                                          var team_detail = teams_in_division[s_m];
                                          
                                         
                                          if(team_detail.user && team_detail.user.length>0){
                                              var resource_obj = {};
                                              var sales_manager_id =  team_detail.user[0].id;
                                             
                                              sales_manager_ids.push(sales_manager_id);
                                              resource_obj.id = sales_manager_id;
                                              var sales_manager_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((team_detail.user[0].profile_pic_url!=="") && (team_detail.user[0].profile_pic_url!==null) )? team_detail.user[0].profile_pic_url : 'default-avatar.png');                                 
                                              resource_obj.name= team_detail.user[0].first_name+" "+team_detail.user[0].last_name+":@:"+sales_manager_profile_pic;            
                                              resource_obj.parentId = division_manager_id;
                                              org_resources.push(resource_obj);
      
                                              var sales_reps_data = team_detail.users;
                                              if(sales_reps_data && sales_reps_data.length>0){
                                                for(var s_r=0;s_r<sales_reps_data.length;s_r++){
                                                  var sales_rep_detail = sales_reps_data[s_r];
                                                 
                                                  var resource_obj = {};
                                                  var sales_rep_id =  sales_rep_detail.id;
                                                  resource_obj.id = sales_rep_id;
                                                  var sales_rep_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((sales_rep_detail.profile_pic_url!=="") && (sales_rep_detail.profile_pic_url!==null) )? sales_rep_detail.profile_pic_url : 'default-avatar.png');                                 
                                                  resource_obj.name= sales_rep_detail.first_name+" "+sales_rep_detail.last_name+":@:"+sales_rep_profile_pic;            
                                                  resource_obj.parentId = sales_manager_id;
                                                  org_resources.push(resource_obj);
                                                }
                                              }
      
                                          
                                          }
                                        }
      
                                      }
                                    
                                  }
                                
      
                                }}
                            } 
                          
                            this.setState({sales_manager_ids:sales_manager_ids},()=>this.getSessionEvents(user_role));
                            
                          }
                           
                            
    
                      }
                }
              }
           
            )
          .catch((error) => { 
            console.log(error)
        });
        
      }
      getSessionEvents = async (sales_user_role) => {
       
        if(sales_user_role==2){
          const payload = {
            sales_manager_id: this.state.sales_manager_ids,
          }
        await axios.post(`/calendar/companyadmin/get-session-events`, payload, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            
            if (res.data.code === 200) {              
              var events_list = res.data.data;
              if(events_list.length>0){
                this.setState({events:events_list});
              }
            }
            
          })
          .catch((error) => {
            console.log(error)
          })
        }else if(sales_user_role==3){
          var sales_manager_id = localStorage.getItem('userid');
        const payload = {
          sales_manager_id: sales_manager_id,
        }
        await axios.post(`calendar/get-session-events`, payload, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            console.log(res)
            if (res.data.code === 200) {
            
              var events_list = res.data.data;
              if(events_list.length>0){
                this.setState({events:events_list});
              }
            }
            
          })
          .catch((error) => {
            console.log(error)
          })
        } else if(sales_user_role==4){
          var sales_rep_id = localStorage.getItem('userid');
          const payload = {
            sales_rep_id: sales_rep_id,
          }
          await axios.post(`scheduler/sales-rep/get-session-events`, payload, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
            }
          })
            .then(res => {
              console.log(res)
              if (res.data.code === 200) {
               
                var events_list = res.data.data;
                if(events_list.length>0){
                  this.setState({events:events_list});
                }
              }
              
            })
            .catch((error) => {
              console.log(error)
            })

           
          }
         
      }
    
  render() {
    const {calendar_default_view,events,eventDetailShow,rescheduleSessionShow,acceptanceSessionShow, nylasData, user_role,summary_view} = this.state;

    if(this.state.session_start_time){
    return (<>
      <Modal 
      title="Session Details" 
      contentClasses="" 
      size="m" 
      onClose={() => this.setEventDetailShow(false)} 
      show={eventDetailShow}>
          {this.showSessionDetail()}
      </Modal> 
      <Modal  classname="calendar-modal" className="calendar-wrap"
                title="Reschedule Session" 
                contentClasses="" 
                size="m" 
                onClose={() => this.setRescheduleSessionShow(false)} 
                show={rescheduleSessionShow}>
                    {this.showRescheduleSession()}
                </Modal> 
      <Modal  classname="calendar-modal" className="calendar-wrap"
                title="Session Details" 
                contentClasses="" 
                size="m" 
                onClose={() => this.setAcceptanceSessionShow(false)} 
                show={acceptanceSessionShow}>
                    {this.showAcceptanceSessionDetail()}
      </Modal>           

      <main className="main-contant opportunity-management-main">
      <div className="contents">
      <div className='demo-app'>
        {this.renderSidebar()}
        <div className='demo-app-main mt-5'>
        {((user_role==3) && (nylasData!=="start")) ? (
                                                    (nylasData && nylasData.access_token && nylasData.access_token!=="" && nylasData.success && nylasData.success==="true") ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <>
                                                        {/* <div className="divconmoon icon-warning mb-3"></div> */}
                                                        <div className="d-flex align-items-center " style={{maxWidth:'950px',transform:'translateY(-50px)'}}>
                                                          <i class="ri-alert-fill mr-2 ri-3x color_blue"></i>                                                        
                                                          <p className="color_black mb-0">The Core Ai calendar is not connected to your calendar. Events will not be automatically updated from Core Ai to your calendar. To connect your calendar to Core Ai, go to your&nbsp;
                                                          <Link to={`./myprofile`} className="color_blue underline">Profile Page</Link>.
                                                          </p>                                                        
                                                        </div>
                                                        </>
                                                    )

                                                ) :null}   


{(user_role!=4 )?



                <div className="calendar_custom_btn  d-flex mb-5 align-items-center justify-content-between">
                        <h5 className="f-600 mb-0">
                        Summary View
                        </h5>
                        <div className="">
                          <Link className='btn btn-dark' to={`./scheduler`} >
                          Day
                          </Link>
                          <Link className='btn btn-dark' to={`./scheduler?view=week`} >
                          Week
                          </Link>
                          <Link className='btn btn-dark active'>
                          Month
                          </Link>
                  </div>
                  </div>       
                    
                 :null}   

<div className="cardWrap  calendar-sales_rep " id="calendar-view">
{(user_role==4 ) ?


       <div className="calendar-sales_rep_wrap  position-relative">
         <div className="calendar_custom_btn   d-flex mb-5 align-items-center justify-content-between" style={{transform: "translateY(-12px)",position:'absolute'}}>
                        {/* <h5 className="f-600 mb-0">
                        {summary_view==1 ? "Summary View" :""}
                        </h5> */}
                       
                  </div>
              
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            views={ {
              timeGridWeek: { // name of view
                titleFormat: { year: 'numeric', month: 'long' ,day:'numeric' }
                // other view-specific options here
              }
            }}
            allDaySlot= {false}
            // dayHeaderFormat={weekdays}
            slotMinTime="00:00"
            slotMaxTime="24:00"
            slotDuration="00:60" 
            initialView={calendar_default_view}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={this.state.weekendsVisible}
          
            // events={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
            events={events}
            select={this.handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            viewDidMount={this.getCalendarView}
            // add={this.initial_event}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
         
            initialDate={this.state.session_start_time}
          />
          </div>
     
          :
         
          
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              // right: 'dayGridMonth,timeGridWeek,timeGridDay'
              right: ''

            }}
        
            allDaySlot= {false}
            // dayHeaderFormat={weekdays}
            slotMinTime="00:00"
            slotMaxTime="24:00"
            slotDuration="00:60" 
            initialView={calendar_default_view}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={this.state.weekendsVisible}
            // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
            events={events}
            select={this.handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            
            // add={this.initial_event}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
          />
      
      
     
          }
          </div>

        </div>
      </div>
            </div>
            </main>
            </>
    )}else{
      return null;
    }
  }

  renderSidebar() {
    return (
      <div className='demo-app-sidebar'>
        
       
      </div>
    )
  }
  getCalendarView = (data)=>{
    if(data.view.type=="dayGridMonth"){
      this.setState({summary_view:1});
    }else{
      this.setState({summary_view:0});
    }
  }
 
 initial_event=(eventinfo)=>{
  
  
  
  return this.state.session_events;
 }
  handleDateSelect = (selectInfo) => {
    // let title = prompt('Please enter a new title for your coaching session')
    // let calendarApi = selectInfo.view.calendar

    // calendarApi.unselect() // clear date selection

    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay
    //   })
    // }
  }

  handleEventClick = (info) => {
   
    var user_role_id = localStorage.getItem('adminsrToken');
    if(user_role_id==2 || user_role_id==3){
    var eventObj = info.event;
   
    if (eventObj.url) {  

      window.open(eventObj.url, "_self");

      info.jsEvent.preventDefault(); // prevents browser from following link in current tab.
    }
  }else{
    info.jsEvent.preventDefault();
    
    this.setState(prevState => ({
      ...prevState,
      eventInfo : info.event.extendedProps, eventID : info.event._def.publicId, eventTitle : info.event.title
    }), ()=>{
      if(info.event.extendedProps.icon == "ri-question-line"){
          this.setAcceptanceSessionShow(true);
      } else if(info.event.extendedProps.icon == "ri-refresh-line"){
        this.setState({session_event_detail:info.event});
          this.setEventDetailShow(true);
      } else {
        this.setState({session_event_detail:info.event});
          this.setEventDetailShow(true);
      }
    });
  }
   
  }

  handleEvents = (events,eventInfo) => {
  
    this.setState({
      currentEvents: events
    })
  }

}

function renderEventContent(eventInfo) {
 
    
   let session_start_time = moment(eventInfo.event.extendedProps.start_time).format("hh:mma");
  let session_end_time = moment(eventInfo.event.extendedProps.end_time).format("hh:mma");


  eventInfo.timeText="";
 
  let coaching_session = "coaching-"+eventInfo.event.extendedProps.icon;
  let status_color = eventInfo.event.backgroundColor;
  let status_event;
  if(eventInfo.event.extendedProps.status=="INVITESENT"){
    status_color = "#003366";
    status_event = "Invite Sent";
  } else if(eventInfo.event.extendedProps.status=="RESCHEDULED"){
    status_event = "Rescheduled";
  }else if(eventInfo.event.extendedProps.status=="SCHEDULED"){
    status_event = "Scheduled";
  }else if(eventInfo.event.extendedProps.status=="CANCELED"){
    status_event = "Cancelled";
  }else if(eventInfo.event.extendedProps.status=="COMPLETED"){
    status_event = "Completed";
  }
  var user_role_id = localStorage.getItem('adminsrToken');
  return (
    <><div class="fc-event-main p-0 m-0" style={{zIndex:0}}>
    <div class={coaching_session}>
      <b>{eventInfo.timeText}</b>
      <small class={eventInfo.event.extendedProps.icon} ></small> {eventInfo.event.title}
      </div>
      </div>
      {eventInfo.event.title!="" && user_role_id==4?
      <div className='event_pophover_wrapper' style={{color:"rgba(0, 0, 0, 0.65)"}}>
          <span className='top_after'></span>
          <div className='mb-2 pb-1 feild-text py-2 word-break-all f-600 f2015'><span>{eventInfo.event.title}</span> </div>
          <i class={eventInfo.event.extendedProps.icon} style={{color:status_color}}></i>
             <span class="f-14" style={{color:status_color}}> {status_event}</span>
          <div style={{lineHeight: "1"}}><i class="ri-time-line color_blue"></i>
             <span class="f-500"> {session_start_time} - {session_end_time}</span>
           </div> 
      </div>:null
}
    </>
  )
}

function renderSidebarEvent(event) {
 
  return (
    <li key={event.id}>
      <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
      <i>{event.title}</i>
    </li>
  )
}
