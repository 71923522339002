import React, { Component } from 'react'
import Modal from "./modals/modal"
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import CustomSelect from "./elements/customSelect";
import { ReactMic } from 'react-mic';

import "react-datepicker/dist/react-datepicker.css";

import profile1 from "../../images/profile1.png"
import profile2 from "../../images/profile2.png"
import profile3 from "../../images/profile3.png"
import profile5 from "../../images/profile5.png"
import profile6 from "../../images/profile6.png"
import profile7 from "../../images/profile7.png"
import profile8 from "../../images/profile8.png"
import profile9 from "../../images/profile9.png"
import profile10 from "../../images/profile10.png"
import profile11 from "../../images/profile11.png"
import profile12 from "../../images/profile12.png"
import voiceImg from "../../images/voice-img.png"
import moment from 'moment';
import axios from '../apiConnection/axios';
import Utility from "../../components/utility";

const utilityObj = new Utility;


export default class CoachingSalesManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false, isPaused : false, time : '',
      interval : null,
      eventData : [], markCompleteID : '' ,
      salesRepNametoShow : '',
      record : false,
      recordedBlob : '',
      editOpportunityShow: false,
      addNotesShow: false,
      notesType: 'voice',
      startDate: new Date('2021-06-09'),
      endDate: new Date('2021-06-09'),
      defaultStageSelectText: "Prospect",
      stageList: [
        { id: 1, name: "Prospect" },
        { id: 2, name: "Qualify" },
        { id: 3, name: "Develop" },
        { id: 4, name: "Validate" },
      ],
      defaultStatusSelectText: "Open",
      statusList: [
        { id: 1, name: "Open" },
        { id: 2, name: "Closed-WON" },
        { id: 3, name: "Closed-LOST" },
      ],
      AllDataLoaded : [],
      salesReps : [],
      reps_array: [],
      noteforsalesrepbody : "",
      Notesforsalesrep : "",
      session_survey:[],
      survey_submit_btn:"",
      survey_session_id:"",
      is_validation_fail:0,
      salesrepSurvey: "", 
      salesManagerSurvey:"" , 
    };

    this.updateBlob = this.updateBlob.bind(this);
  }

  componentDidMount = () =>{
    window.scrollTo(0, 0);
    var full_name = localStorage.getItem('full_name');
    const user_id = localStorage.getItem('userid');
    const user_role = localStorage.getItem('adminsrToken');
    const profileImg = localStorage.getItem('profilePic');
    this.setState({ profileImg, salesRepName: full_name, user_role, user_id: user_id }, () => {
      var survey_for;
      if (user_role==='4'){
        // this.getOpportunitiesBySalesRep();
        this.getloadRepView();
        survey_for = "";
      } else if (user_role==='3'){
        this.getloadManagerView();
        this.getReps();
        survey_for = "sales_manager";
        // this.getSurvey(survey_for);
      }
      // this.getSurvey(survey_for);
    });
  
        // this.setFunctionPermissions(user_role,6);

  }

  handleChange = (e,index) => {
    
      var value = e.target.value;
      var name = e.target.name;
       if(value.length>1000){
        this.setState({  MessageNotes:"Survey answer should be max 1000 characters.", 
        MessageTypeNotes : "danger"});
      }else{
        this.setState({  MessageNotes:"", 
        MessageTypeNotes : ""});
      }
    let session_survey = [...this.state.session_survey];
    let session_survey_data = {
          ...session_survey[index],
          ans: value
      }
     
      session_survey[index] = session_survey_data;
      this.setState({survey_submit_btn:""});
      for(var counter =0; counter<session_survey.length;counter++){
        if(session_survey[counter].ans==""){
          this.setState({survey_submit_btn:"disabled"});
        }

      }

    this.setState({session_survey}, ()=>{
     
    });
    }
  saveSurvey = async (survey_status) => {
    const { user_id, survey_session_id } = this.state;
    var survey_data = this.state.session_survey;
    var is_validation_fail = this.state.is_validation_fail;
    for(var s_counter=0; s_counter<survey_data.length; s_counter++){
      console.log("@SURVEY FORM DATA: ",survey_data[s_counter].ans);
      console.log("@SURVEY FORM DATA: ",survey_data[s_counter].ans.length);
      if(survey_data[s_counter].ans.length>1000){
        is_validation_fail=1;
      }
    }
    if(is_validation_fail==0){
    const payload = {
      "session_id": survey_session_id,
      "survey_status" : survey_status,
      "user_id": user_id,
      "survey": this.state.session_survey
    }
     
    var success_message = "Survey saved successfully.";
    if(survey_status=="SUBMIT"){
      success_message = "Survey submitted successfully.";
    }

    await axios.post(`survey/save-survey-progress-by-user`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {

          
          this.setState({
            MessageNotes:success_message, 
            MessageTypeNotes : "success"
            },()=>{
              setTimeout(() => {
                this.setState({
                  MessageNotes: "",
                  MessageTypeNotes : ""                 
                });
                if(survey_status=="SUBMIT"){
                  if(this.state.user_role==3){
                  this.getloadManagerView();
                  }else{
                  this.getloadRepView();
                  }
                this.setState({session_survey_status:"SUBMIT"});
                }
                this.setState({launchSurveyUnlockShow:false });              
              }, 3000);
          // this.setState({launchSurveyUnlockShow:false });          
        
      })
    }
    })
      .catch((error) => {
        console.log(error)
      })
    }else{
      this.setState({  MessageNotes:"Survey answer should be max 1000 characters.", 
        MessageTypeNotes : "danger"});
    }
  }
  getSurvey = async (survey_for) => {
    const { user_id,survey_session_id } = this.state;
    const payload = {
      "session_id": survey_session_id,
      "survey_for" : survey_for,
      "user_id": user_id
    }
    await axios.post(`survey/get-survey-progress-by-user`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          console.log("SURVEY QUESTIONS",res.data.data[0].survey);
          this.setState({ session_survey_status: res.data.data[0].survey_status });
          this.setState({ session_survey: res.data.data[0].survey });
          for(var counter =0; counter<res.data.data[0].survey.length;counter++){
            if(res.data.data[0].survey[counter].ans==""){
              this.setState({survey_submit_btn:"disabled"});              
            }
    
          }
         
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  getReps = async () => {
    const { user_id } = this.state;
    const payload = {
      sales_manager_id: user_id,
    }
    await axios.post(`opportunity/get-sales-rep-by-sales-manager`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({ salesReps: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  getloadManagerView = async () =>{
    // coaching/get-data-by-sales-manager
    utilityObj.loaderView("show");

    const { user_id, reps_array} = this.state;
    const payload = {
      sales_manager_id: user_id,
      sales_rep_arr : reps_array
    }
    await axios.post(`coaching/get-data-by-sales-manager`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        if (res.data.code === 200) {
          this.setState({ AllDataLoaded: res.data.data }, ()=>{
            console.log('Test Data : ', this.state.AllDataLoaded);
          });
        }
        utilityObj.loaderView("hide");
      })
      .catch((error) => {
        console.log(error)
      })    
  }

  getloadRepView = async () =>{
    // coaching/get-data-by-sales-rep
    utilityObj.loaderView("show");

    const { user_id} = this.state;
    const payload = {
      sales_rep_id: user_id,
    }
    await axios.post(`coaching/get-data-by-sales-rep`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        if (res.data.code === 200) {
          this.setState({ AllDataLoaded: res.data.data }, ()=>{
            console.log('Test Data : ', this.state.AllDataLoaded);
          });
        }
        utilityObj.loaderView("hide");
      })
      .catch((error) => {
        console.log(error)
      })    
  }

  setCoachingDetailsShow = (newValue) => {
    this.setState({ addCoachingShow: newValue });
  }
  setAddNotesShow = (newValue) => {
    this.setState({ AddNotesShowUnlockShow: newValue });

  }
  setLaunchSurveyShow =   (newValue,session_id) => {
    var survey_for = "sales_manager";
    if(this.state.user_role==4){
      survey_for = "sales_rep";
    }
    this.setState({ survey_session_id: session_id },()=>{
      this.getSurvey(survey_for); 
      setTimeout(function() { //Start the timer
        this.setState({ launchSurveyUnlockShow: newValue }); //After 1 second, set render to true
    }.bind(this), 200)
      
      
   
  });
    console.log("session_id",session_id);
  }
  setMarkComplete = (newValue, data) => {
    if (newValue===true){
      this.setState({ eventData : data, markCompleteID : data.id, salesRepNametoShow : data.sales_rep[0].first_name+' '+  data.sales_rep[0].last_name}, ()=>{
        this.getNotesForSalesRep(data.id);
      });
    } else {
      this.setState({
        MarkCompleteshow: newValue,
        MarkCompleteshow2: newValue
      })
    }
  }



  getNotesForSalesRep = async (session_id) =>{
    const payload = {
        session_id: session_id,
    }
    await axios.post(`coaching/precheck-mark-complete`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
       
        if (res.data.code === 200) {
          this.setState({ 
            salesrepSurvey: res.data.data[0].salesrep_survey, 
            salesManagerSurvey: res.data.data[0].sales_manager_survey, 
            salesManagerNotes: res.data.data[0].sales_manager_notes,
          })
       
          if(res.data.data[0].salesrep_survey == 1 && res.data.data[0].sales_manager_survey == 1 && res.data.data[0].sales_manager_notes == 1){
            this.setState({
              MarkCompleteshow: true
            })
          }
          else {
              this.setState({
                MarkCompleteshow2: true
              })
            }
            
          // if (res.data.data.length!==0){
          //   this.setState({ noteforsalesrepbody: res.data.data[0].note_body, Notesforsalesrep : res.data.data }, ()=>{
          //     if (res.data.data[0].note_body && (res.data.data[0].note_body!=='')){
          //           this.setState({
          //             MarkCompleteshow: true
          //           })
          //     } else {
          //       this.setState({
          //         MarkCompleteshow2: true
          //       })
          //     }
          //   });
          // } else {
          //   this.setState({
          //     MarkCompleteshow2: true
          //   })
          // }
       
        }
      })
      .catch((error) => {
        console.log(error)
      })
}
  

  // getNotesForSalesRep = async (session_id) =>{
  //         const { user_id } = this.state;
  //         const payload = {
  //             session_id: session_id,
  //             user_id: user_id,
  //             note_for: 'SALESREP',
  //         }
  //         await axios.post(`coaching/get-coaching-session-notes`, payload, {
  //           headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json;charset=UTF-8',
  //           }
  //         })
  //           .then(res => {
  //             console.log(res)
  //             if (res.data.code === 200) {
  //               if (res.data.data.length!==0){
  //                 this.setState({ noteforsalesrepbody: res.data.data[0].note_body, Notesforsalesrep : res.data.data }, ()=>{
  //                   if (res.data.data[0].note_body && (res.data.data[0].note_body!=='')){
  //                         this.setState({
  //                           MarkCompleteshow: true
  //                         })
  //                   } else {
  //                     this.setState({
  //                       MarkCompleteshow2: true
  //                     })
  //                   }
  //                 });
  //               } else {
  //                 this.setState({
  //                   MarkCompleteshow2: true
  //                 })
  //               }
  //               console.log(res.data.data);
  //             }
  //           })
  //           .catch((error) => {
  //             console.log(error)
  //           })
  // }

  addCoachingDetails = () => {
    return (<>
      <form>
        <div className="form-group">
          <label className="mb-0">Event Title</label>
          <div className="feild-text py-3 f-600 f2015">
            Coaching for Opportunity F42
          </div>
        </div>
        <div className="form-group">
          <label className="mb-0">Opportunity</label>
          <div className="feild-text py-3 f-600 f2015">
            Opportunity F42
          </div>
        </div>
        <div className="form-group">
          <label className="mb-0">Task</label>
          <div className="feild-text py-3 f-600 f2015">
            Confirmed customer’s buying process…
          </div>
        </div>
        <div className="form-group">
          <ul className="shedule-details f-500 list-unstyled">
            <li className="pb-2 pt-1 mb-2 mt-1">
              <i className="ri-lightbulb-line color_blue mr-2"></i> Prospect Stage
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <i className="ri-calendar-event-line color_blue mr-2 "></i> Thursday, 1 December 2021
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <i className="ri-time-line color_blue mr-2"></i> 1:00pm - 2:00pm
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <i className="ri-live-line color_blue mr-2 "></i> <span className="color_blue underline"> https://us02web.zoom.us/j/863708448?pwd=N2FwWD </span>
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <div className="main-profile d-flex  align-items-center">
                <div className="img-wrap">
                  <img src={profile1} alt="" />
                </div>
                <div className="ml-2">
                  <div className="name f-500 mb-1">
                    Anthony Miles
                  </div>
                </div>
              </div>
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <div className="main-profile d-flex  align-items-center">
                <div className="img-wrap">
                  <img src={profile2} alt="" />
                </div>
                <div className="ml-2">
                  <div className="name f-500 mb-1">
                    George Swanson
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="form-group">
          <label className="mb-0">Task</label>
          <div className="feild-text py-3  f2015">
            Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt.
          </div>
        </div>
        {/* <div className="form-group">
  <label className="mb-0">Task</label>
  <textarea type="text" className="form-control f-600 f2015" value=" Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt." placeholder="Task"></textarea>
</div> */}
        {/* <div className="modal-footer justify-content-center">
<button type="button" className="btn dark-btn mx-2">Accept</button>
<button type="button" className="btn light-btn  cancel-btn mx-2" data-dismiss="modal">Rescheduled</button>
</div> */}
      </form>

    </>);
  }




  startRecording = () => {
    const {isPaused} = this.state;
    if (isPaused!==true){
      this.setState({
        time : 0
      }, () => {
        this.setState({ record: true }, ()=>{
          this.watchUpdate(true);
        });
      })
    } else {
      this.setState({ record: true, isPaused : false }, ()=>{
        this.watchUpdate(true);
      });
    }   

  }
 
  pauseRecording = (value) => {
      this.watchPause(value);
  }

  stopRecording = () => {
    this.setState({ record: false });
    this.watchUpdate(false);
  }
 
  onData = (recordedBlob) => {
    console.log('chunk of real-time data is: ', recordedBlob);
  }
 
  onStop = (recordedBlob) => {
    console.log('recordedBlob is: ', recordedBlob);   
     
    const file2 = new File([recordedBlob.blob], "testfile.wav",{ type: "audio/wav" });

    var recordedBlob1 = file2;
    this.updateBlob(recordedBlob1);
  }
  updateBlob = (recordedBlob) => {
    this.setState({recordedBlob}, ()=>{
      this.saveNote();
    })
  }
  setNotesType = (newValue) => {
    this.setState({ notesType: newValue });
  }
  setAddNotes = (newValue, title, id) => {
      this.setState({recordedBlob:''}, ()=>{
        this.setState({ time:0, addNotesShow: newValue,  AddNoteTitle : title, AddNoteID : id });
      });
  }


  

  timerLoad = () => {

    if (this.state.isActive && this.state.isPaused === false) {
      this.state.interval = setInterval(() => { this.setState({
        time : parseInt(this.state.time + 1000),
      })}, 1000);
    } else {
      clearInterval(this.state.interval);
    }
  }
  
  watchUpdate = (newValue) => {
    this.setState({
      isActive : newValue
    }, ()=>{
      this.timerLoad();
    })
  };

  watchPause = (newValue) => {
    this.setState({
      isPaused : newValue
    }, ()=>{
      this.timerLoad();
    })
  };


  addNotes = () => {
    const {record , isPaused, time,  ButtonHideA, notebody, MessageNotes, MessageTypeNotes, AddNoteTitle, AddNoteID, recordedBlob,notesType } = this.state;
    return (<>
      <form className="">
      {((MessageNotes) && (MessageNotes !== null)) ?
          (
            <div className={`alert alert-${MessageTypeNotes}`}>
              {MessageNotes}
            </div>
          ) : (``)
        }           
        <div className="form-group px-2">
          <div className="feild-text py-3 f-600 f2015 text-center">{AddNoteTitle}</div>
        </div>
        <div className="form-group px-2">
          <div className="select-voice-notes d-flex  p-2 mx-auto">
            <span className={(notesType === 'voice') ? `active text-center` : `text-center`} onClick={() => this.setNotesType('voice')}><i className="ri-mic-2-line"></i> Voice</span>
            <span className={(notesType === 'text') ? `active text-center` : `text-center`} onClick={() => this.setNotesType('text')}><i className="ri-list-unordered"></i> Text</span>
          </div>
        </div>


        {(notesType === 'voice') ?
          (<>
            <div className="form-group px-2 pt-3 text-center">
              {((record==true) && (isPaused==false)) ? (<>
              
                <div className="voice-wrap mx-auto">
                  <div className="show-voice-blue mx-auto" onClick={() =>this.stopRecording()}>
                    <i className="ri-stop-fill text-white ri-3x"></i>
                  </div>
                  {/* <div className="pause-btn rounded-circle bg_color_blue text-center" onClick={()=>this.pauseRecording(true)}>
                    <i className="ri-pause-mini-line text-white"></i>
                  </div> */}
                </div>
                <label className="mb-0 mt-3">Click to Stop</label>
              </>

              ) : (
                <>
                    <div className="voice-wrap mx-auto ">
                    {(isPaused==true) ? (
                      <div className="show-voice mx-auto " onClick={() =>this.pauseRecording(false)} >
                        <i className="ri-mic-fill text-white ri-3x"></i>
                      </div>                      
                    ) : (
                      <div className="show-voice mx-auto " onClick={() =>this.startRecording()} >
                        <i className="ri-mic-fill text-white ri-3x"></i>
                      </div>
                    )}                      
                    </div>
                    <label className="mb-0 mt-3">{(isPaused==true) ? (`Click to Resume`) : (`Click to Record`)}</label>                
                </>
              )}
            </div>
            <div className="form-group px-2 text-center">
              <div className="voice-time mb-4 color_blue">
              <span className="digits">
                {("0" + Math.floor((time / 600000) % 60)).slice(-2)}:
              </span>
              <span className="digits">
                {("0" + Math.floor((time / 60000) % 60)).slice(-2)}.
              </span>
              <span className="digits mili-sec">
                {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
              </span>                
                {/* 0:00:00 */}
              </div>
              {/* <img src={voiceImg} alt="voice" className="img-fluid" /> */}
              {/* <a class="fa fa-download" download="recording.wav" aria-hidden="true" href={recordedBlob.blobURL}>DOWNLOAD</a> */}
            
              <div style={{maxWidth:'100%',overflow:'hidden'}}>
                  <ReactMic
                  mimeType="audio/wav"
                    pause={this.state.isPaused}
                    visualSetting="frequencyBars"                    
                    record={this.state.record}
                    className="sound-wave"
                    onStop={this.onStop}
                    onData={this.onData}
                    strokeColor="#f5aba7"
                    backgroundColor="#FFFFFF" />
                </div>         

            </div>
          </>) : (<>
            <div className="form-group">
              <label className="mb-0">Notes</label>
              <textarea className="form-control f-600 f2015"  rows={2} maxRows={2} id={`notebody`} value={notebody} onChange={(e)=>this.changeFunction(e)} placeholder="Click to add notes." ></textarea>
            </div>
          </>)
        }
        {((notesType === 'voice') && (record!=true) && (recordedBlob!='')) ? (<></>
            // <div className="modal-footer justify-content-center">
            //   <button type="button" className="btn dark-btn  cancel-btn mx-2" onClick={() => this.saveNote()}>Save</button>
            //   <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setAddNotes(false)}>Cancel</button>
            // </div>
        ) : null}
        {((notesType === 'text') && (ButtonHideA!==true)) ? (
            <div className="modal-footer justify-content-center">
              <button type="button" className="btn dark-btn  cancel-btn mx-2" onClick={() => this.saveNote()}>Save</button>
              <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setAddNotes(false)}>Cancel</button>
            </div>
        ) : null}        
      </form>
    </>);
  }
  changeFunction = (e) => {
    if(e.target.value.trim().length == 0){
      e.target.value = "";
    }
      var s = e.target.value;
      var keyid = e.target.id;

      if (keyid == "notebody") {
        if (e.target.value.length>500){
          this.setState(prevState => ({
            ...prevState,
            MessageNotes : "Notes should be max 500 characters.",
            MessageTypeNotes : "danger",
            ButtonHideA : true
          }), ()=>{
            this.setState(prevState => ({
              ...prevState,
              notebody: s
            }));        
          });

        } else {
          this.setState(prevState => ({
            ...prevState,
            MessageNotes : null,
            MessageTypeNotes : null,
            ButtonHideA : false
          }), ()=>{
            this.setState(prevState => ({
              ...prevState,
              notebody: s
            }));        
          });        
        }  

      } 
    }

  saveNote = async () => {
    const {notebody, user_id, AddNoteID, notesType, recordedBlob} = this.state;
    var note_content_type = (notesType==='voice') ? '1' : '0';
    var note_for = "INTERNAL";
    utilityObj.loaderView("show");
    let payload, headers;
    if (note_content_type=='1') {
      payload = new FormData() ;
      //    payload.append('session_id', AddNoteID) ;
      //    payload.append('note_content_type', note_content_type) ;
      //    payload.append('note_type', 1) ;
      //    payload.append('user_id', user_id) ;
      //   payload.append('note_for', note_for) ;
        payload.append('file', recordedBlob) ;
       
        headers = {
          "Content-Type": "multipart/form-data",
          "is_audio_note":"AUDIO",
          "session_id":AddNoteID,
          "note_content_type":note_content_type,
          "note_type":1,
          "user_id":user_id,
          "note_for":note_for
        } ;
    } else {
      payload = {
        "session_id":AddNoteID,
        "note_content_type": note_content_type,
        "note_type":1,
        "user_id":user_id,
        "note_body":notebody,
        "note_for":note_for
      }
      headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        "is_audio_note":"",
      }      

    }

    await axios.post(`coaching/save-coaching-session-notes`, payload, {
      headers
    })
    .then(res => {
      console.log(res)
      if (res.data.code === 200) {
        this.setState({
          MessageNotes: 'Note updated successfully.', 
          MessageTypeNotes : "success"
          }, ()=>{
            setTimeout(() => {
              this.setState({
                MessageNotes: "", 
                MessageTypeNotes : null,
                notebody :'',
                recordedBlob:'',
              });
              this.setAddNotes(false);              
            }, 3000)
          })              
          utilityObj.loaderView("hide");
      } else {
        this.setState({
          MessageNotes: res.data.message, 
          MessageTypeNotes : "danger"
          }, ()=>{
            setTimeout(() => {
              this.setState({
                MessageNotes: "", 
                MessageTypeNotes : null
              });
            }, 3000)
          })     
          utilityObj.loaderView("hide");
         
      }
    })
    .catch((error) => {
      console.log(error)
      utilityObj.loaderView("hide");
    })
  }



  markCompleteBySalesManager = async () => {
    const { markCompleteID, user_id } = this.state;
    utilityObj.loaderView("show");
    // /coaching/mark-complete-session
    const payload = {
      "session_id":markCompleteID,
      "user_id":user_id,
      "status" : "completed_sessions"
    }
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    }      


  await axios.post(`coaching/mark-complete-session`, payload, {
    headers
  })
  .then(res => {
    console.log(res)
    if (res.data.code === 200) {
      this.setState({
        MessageComplete: 'The session was marked as completed.', 
        MessageTypeComplete : "success"
        }, ()=>{
          setTimeout(() => {
            this.setState({
              MessageComplete: "", 
              MessageTypeComplete : null,
            });
            this.setMarkComplete(false); 
            this.componentDidMount();             
          }, 3000)
        })              
        utilityObj.loaderView("hide");
    } else {
      this.setState({
        MessageComplete: res.data.message, 
        MessageTypeComplete : "danger"
        }, ()=>{
          setTimeout(() => {
            this.setState({
              MessageComplete: "", 
              MessageTypeComplete : null
            });
          }, 3000)
        })     
        utilityObj.loaderView("hide");
       
    }
  })
  .catch((error) => {
    console.log(error)
    utilityObj.loaderView("hide");
  })

  }

  MarkCompleteModal = () => {
    const {eventData, MessageComplete, MessageTypeComplete, salesRepNametoShow} = this.state;
    return (<>
    <form>
    {((MessageComplete) && (MessageComplete !== null)) ?
          (
            <div className={`alert alert-${MessageTypeComplete}`}>
              {MessageComplete}
            </div>
          ) : (``)
        }       
    <div className="text-center mx-2 my-4">

             <p className="m-0"> The session data for <span className="f-600"> {eventData.title} </span>  will become available to <span className="f-600"> {salesRepNametoShow}</span>.<br/>
             Are you sure you want to mark the session Complete?</p>

              
      <div className="modal-footer border-0 mb-4 justify-content-center">
            <button type="button" className="btn dark-btn mx-2" onClick={() => this.markCompleteBySalesManager()}>Yes</button>
            <button type="button" className="btn light-btn mx-2" onClick={() => this.setMarkComplete(false)} >No</button>
      </div>
      <Link onClick = {() => this.redirectDetails(eventData)} className="color_blue py-4 underline f2015" >View Session Details</Link>
    </div>
    </form>

      </> );
  }

  MarkCompleteModal2 = () => {
    const {eventData,salesrepSurvey,salesManagerSurvey,salesManagerNotes} = this.state;
    return (<>
    <form>
     
    <div className="text-center mx-2 my-4">
    {salesrepSurvey == 0 ?
    <p className="mb-3"> The Sales Rep survey has not been submitted. Please notify the Sales Rep to complete the survey using the Launch Survey context menu.
    </p>
 : null}
   {salesManagerSurvey == 0 ?
    <p className="mb-3"> The Sales Manager survey has not been submitted. Please use the Launch Survey context menu on the event card to complete the survey.
    </p>
 : null}
   {salesManagerNotes == 0 ?
          <p className="mb-3"> There are no Notes for Sales Rep for this coaching session. <br/>The session can not be completed.</p>
 : null}


              
      <div className="modal-footer border-0 mb-4 justify-content-center">


      { (salesManagerNotes == 0) ?
            <button type="button" className="btn dark-btn mx-2" onClick = {() => this.redirectDetails(eventData)}>Go to Session</button>
            :<button type="button" className="btn dark-btn mx-2" onClick = {() => this.setMarkComplete(false)}>Ok</button>

            }

            <button type="button" className="btn light-btn mx-2" onClick={() => this.setMarkComplete(false)} >Cancel</button>
      </div>
    </div>
    </form>

      </> );
  }  
  setLaunchSurvey = () => {
    const { session_survey, session_survey_status,survey_submit_btn, MessageNotes, MessageTypeNotes,survey_session_id } = this.state;
   
    return (<>
       {((MessageNotes) && (MessageNotes !== null)) ?
          (
            <div className={`alert alert-${MessageTypeNotes}`}>
              {MessageNotes}
            </div>
          ) : (``)
        }
      <form className="manager-survey-wrap">
      {(session_survey && session_survey.length>0) ? (
        session_survey.map((survey_data,survey_index) => {
          var ratings = [1,2,3,4,5]
          return (
            survey_data.type=="RATING" ? <div className="form-group px-2">
            <div className="feild-text py-3 f-600 f2015"> {survey_data.question}</div>
            <div className="ratings-wrap">
              <ul className="list-unstyled mt-3 d-flex m-0"> 
              {ratings.map((rating_value, index) => {
                var checked = "";
                if(rating_value==survey_data.ans){
                  checked = "checked";
                }
                return   <li className=" mr-2"> 
                  <label className="customRadio text-check">
                    <input type="radio" name={survey_data.que_id} checked={checked} value={rating_value} onChange={(e)=>this.handleChange(e,survey_index)} />
                    <span className="checkmark" >{rating_value}</span>
                  </label>
                </li>
              })}            
               
              </ul>
              <div className="show-point mt-2 d-flex justify-content-between align-items-center">
                <label className="mb-0">Lowest</label>
                <label className="mb-0">Highest</label>
              </div>
            </div>
            </div>  :

            <div className="form-group">
                <div className="feild-text py-3 f-600 f2015">{survey_data.question} </div>
                <textarea className="form-control f-400 f2015 " style={{borderBottomColor: "#003366;"}} placeholder="" name={survey_data.que_id} onChange={(e)=>this.handleChange(e,survey_index)}>{survey_data.ans}</textarea>
            </div>  

          )
      })
      ) : (``)}
       
        
      </form>
      {session_survey_status!="SUBMIT"?<>
      <div className="modal-footer justify-content-center">
        <button type="button" disabled={survey_submit_btn} className="btn dark-btn mx-2" onClick={() => this.saveSurvey("SUBMIT")}>Submit</button>
        <button type="button" className="btn bg-transparent color_blue f-400 d-flex align-items-center" onClick={() => this.saveSurvey("DRAFT")}><i className="ri-save-line pr-1 "></i> Save Progress</button>
      </div>
      <div className="form-group mt-2" style={{textAlign:"center"}}>
      <label className="color_light_gray" style={{fontSize:"12px"}}>The Submit button will become active after all the survey questions have been completed.</label>
      </div>
      </>:null}
    </>)
  }

  setFilterRep = (rep_id) => {
    if (typeof this.state.reps_array !== 'undefined') {
      var reps_array = this.state.reps_array;
    } else {
      var reps_array = [];
    }
    if (!reps_array.includes(rep_id)){
      reps_array.push(rep_id);
    } else {
      var index = reps_array.indexOf(rep_id);
      if (index !== -1) {
        reps_array.splice(index, 1);
      }      
    }
    this.setState({reps_array}, ()=>{
      console.log(this.state.reps_array);
      this.getloadManagerView();
    });
  }


  setEventDetailShow = (newValue, data) => {
    if (newValue===true){
      this.setState({session_event_detail:data}, ()=>{
        this.setState({ eventDetailShow: newValue });
      });
    } else {
      this.setState({ eventDetailShow: newValue });
    }
  }
  showSessionDetail = () => {
    const { onChange, session_event_detail, salesRepName, profileImg, user_role } = this.state;
    if(session_event_detail){
      // var profileImgRep = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((session_event_detail.sales_rep[0].profile_pic_url!=="") && (session_event_detail.sales_rep[0].profile_pic_url!==null) ) ? session_event_detail.sales_rep[0].profile_pic_url : 'default-avatar.png');
      // var saleRepName2 = session_event_detail.sales_rep[0].first_name+' '+session_event_detail.sales_rep[0].last_name;
      var profileImgRep = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ ((user_role==="3") ? (((session_event_detail.sales_rep[0].profile_pic_url!=="") && (session_event_detail.sales_rep[0].profile_pic_url!==null) ) ? session_event_detail.sales_rep[0].profile_pic_url : 'default-avatar.png') : 
      (((session_event_detail.sales_manager[0].profile_pic_url!=="") && (session_event_detail.sales_manager[0].profile_pic_url!==null) ) ? session_event_detail.sales_manager[0].profile_pic_url : 'default-avatar.png'));
      var saleRepName2 = ((user_role==="3") ? (session_event_detail.sales_rep[0].first_name+' '+session_event_detail.sales_rep[0].last_name) : (session_event_detail.sales_manager[0].first_name+' '+session_event_detail.sales_manager[0].last_name));

     var stage_pic =  process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((session_event_detail.stage_obj.icon_url!=="") && (session_event_detail.stage_obj.icon_url!==null) )? session_event_detail.stage_obj.icon_url : 'default_sales_stage.png');  
    //  var sales_rep_pic =  process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((session_event_detail.sales_rep_profile_pic_url!=="") && (session_event_detail.sales_rep_profile_pic_url!==null) )? session_event_detail.sales_rep_profile_pic_url : 'default-avatar.png');  
    //  var sales_manager_name =  localStorage.getItem('full_name');
    //  var sales_manager_profile_pic =  localStorage.getItem('profilePic');
    return (<>  <form>


      <div className="modal-body">
        <div className="form-group">
          <label className="mb-0">Event Title</label>
          <div className="feild-text py-2 f-600 f2015">
          {session_event_detail.title}
          </div>
        </div>
        <div className="form-group">
          <label className="mb-0">Opportunity</label>
          <div className="feild-text py-2 f-600 f2015">
          {session_event_detail.opportunity_name}
          </div>
        </div>
        <div className="form-group">
          <label className="mb-0">Task</label>
          <div className="feild-text py-2 f-600 f2015">
          {session_event_detail.task_title}
          </div>
        </div>

        <div className="form-group">
          <ul className="shedule-details f-500 list-unstyled">
            <li className="pb-2 pt-1 mb-2 mt-1">
                <img src={stage_pic} className='color_blue mr-2' alt="" width="25px" height="25px"/>
                <span className="d-inline-block" style={{marginLeft: "3px"}}>
                {session_event_detail.stage_obj.stage_name} Stage
                </span>
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <i className="ri-calendar-event-line color_blue mr-3 "></i> 
              {/* Thursday, 1 December 2021 */}
              {moment(parseInt(session_event_detail.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")}
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">  
              <i className="ri-time-line color_blue mr-3"></i>
              {moment(parseInt(session_event_detail.start_date_tmestamp)*1000).utc().format("hh:mma")}  -  {moment(parseInt(session_event_detail.end_date_tmestamp)*1000).utc().format("hh:mma")}
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1 d-flex align-items-center">
              <i className="ri-live-line color_blue mr-3 "></i>
              <div style={{wordBreak: 'break-all'}}>
               {session_event_detail.meeting_link!="" ? <a className="color_blue underline"  href={session_event_detail.meeting_link} target="_blank"> 
                 {session_event_detail.meeting_link}</a>:"Meeting link will generate shortly."}
                 </div>
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <div className="main-profile d-flex  align-items-center">
                <div className="img-wrap" >
                  <img src={profileImg} alt="" />
                </div>
                <div className="ml-1">
                  <div className="name f-500 mb-1 ml-1" style={{paddingLeft :"2px"}}>
                    {salesRepName}
                  </div>
                </div>
              </div>
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <div className="main-profile d-flex  align-items-center">
                <div className="img-wrap">
                  <img src={profileImgRep} alt="" />
                </div>
                <div className="ml-1">
                  <div className="name f-500 mb-1 ml-1" style={{paddingLeft :"2px"}}>
                     {saleRepName2}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="form-group">
          <label className="mb-0">Description</label>
          <div className="feild-text py-3 word-break-all f2015">
              {session_event_detail.description} 
          </div>
        </div>
        {/* <div className="form-group">
            <label className="mb-0">Task</label>
            <textarea type="text" className="form-control f-600 f2015" value=" Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt." placeholder="Task"></textarea>
          </div> */}
      </div>
      {/* <div className="modal-footer justify-content-center">
          <button type="button" className="btn dark-btn mx-2">Accept</button>
          <button type="button" className="btn light-btn  cancel-btn mx-2" data-dismiss="modal">Rescheduled</button>
        </div> */}
    </form>
    </>


    );
      }

  };


  redirectDetails = (OppoDetails) =>{
    localStorage.setItem('OppoDetails', JSON.stringify(OppoDetails));
    setTimeout(() => { window.location.href = "./coaching-detail/"+OppoDetails.id; }, 500);
    // window.history.replaceState(null, "Schedule Event", "../scheduler?preset=true")
  }
  
  render() {
    const { AllDataLoaded, eventDetailShow, user_role, salesReps, salesRepName, profileImg, addCoachingShow, addNotesShow, launchSurveyUnlockShow ,MarkCompleteshow, MarkCompleteshow2 } = this.state;
    var survey_modal_title= "Sales Manager Survey";
    if(user_role == 4){
      survey_modal_title = "Sales Rep Survey";
    }
    return (
      <>
        <Modal classname="calendar-modal" className="calendar-wrap"
          title="Session Details"
          contentClasses=""
          size="m"
          onClose={() => this.setEventDetailShow(false)}
          show={eventDetailShow}>
          {this.showSessionDetail()}
        </Modal>

        <Modal
          title="Session Details"
          contentClasses=""
          size="md"
          onClose={() => this.setCoachingDetailsShow(false)}
          show={addCoachingShow}>
          {this.addCoachingDetails()}
        </Modal>

        <Modal
          title={(this.state.notesType === "text") ? "Add Text Note" : "Add Voice Note"}
          contentClasses=""
          size="md"
          onClose={() => this.setAddNotes(false)}
          show={addNotesShow}>
          {this.addNotes()}
        </Modal>

        <Modal
          title={survey_modal_title}
          contentClasses=""
          size="lg"
          onClose={() => this.setLaunchSurveyShow(false)}
          show={launchSurveyUnlockShow}>
          {this.setLaunchSurvey()}
        </Modal>


        <Modal 
          title="" 
          contentClasses="py-3 py-md-5" 
          size="lg" 
          onClose={() => this.setMarkComplete(false)} 
          show={MarkCompleteshow}>
          {this.MarkCompleteModal()}
      </Modal> 
      <Modal 
          title="" 
          contentClasses="py-3 py-md-5" 
          size="lg" 
          onClose={() => this.setMarkComplete(false)} 
          show={MarkCompleteshow2}>
          {this.MarkCompleteModal2()}
      </Modal> 

        <main className="main-contant opportunity-management-main">
          <div className="contents">
            <div className="d-flex flex-wrap justify-content-between mb-4 mb-lg-5 align-items-center">
              <h3 className="title-head m-0 py-2">Coaching Management</h3>

<div className="users-wrap d-flex flex-wrap align-items-center justify-content-end">
  {(salesReps && salesReps.length>0) ? (
    salesReps.map((rep) => {
      var profileImgRep = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((rep.sales_rep_profile_pic_url!=="") && (rep.sales_rep_profile_pic_url!==null) )? rep.sales_rep_profile_pic_url : 'default-avatar.png');
      var repName = rep.sales_rep_first_name + ' ' +rep.sales_rep_last_name;
      if (typeof this.state.reps_array !== 'undefined') {
        var reps_array = this.state.reps_array;
      } else {
        var reps_array = [];
      }
      var classes = (!reps_array.includes(rep.sale_rep_id)) ? 'img-wrap' : 'img-wrap img-selected';
      return (
          <div className={classes} onClick={() => this.setFilterRep(rep.sale_rep_id)}>
            <img src={profileImgRep} alt={repName} title={repName}/>
          </div>                      
      )
   })
  ) : (``)}
</div>
            </div>

            <div className="opportunity-management-card-container d-flex">
              <div className="card-row coaching-card-rows">
                <div className="cord-body-wrap">
                  <div className="card-head mb-2 text-center f-600 f2015 d-flex justify-content-center align-items-center">
                    <i className="ri-live-line ri-2x f-100 mr-1"></i> Upcoming Sessions (
                    {(AllDataLoaded && AllDataLoaded.length>0) ? (
                      (AllDataLoaded[0].upcoming_session && AllDataLoaded[0].upcoming_session.count>0) ? (
                        AllDataLoaded[0].upcoming_session.count
                      ) : ('0')
                    ) : 0}
                      )
                  </div>
                  <div className="profile-card-container px-2 py-1">
                    {(AllDataLoaded && AllDataLoaded.length>0) ? (
                      (AllDataLoaded[0].upcoming_session && AllDataLoaded[0].upcoming_session.count>0) ? (
                      AllDataLoaded[0].upcoming_session.data.map((data) => {
                        var profileImgRep = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ ((user_role==="3") ? (((data.sales_rep[0].profile_pic_url!=="") && (data.sales_rep[0].profile_pic_url!==null) ) ? data.sales_rep[0].profile_pic_url : 'default-avatar.png') : 
                        (((data.sales_manager[0].profile_pic_url!=="") && (data.sales_manager[0].profile_pic_url!==null) ) ? data.sales_manager[0].profile_pic_url : 'default-avatar.png'));
                        var saleRepName2 = ((user_role==="3") ? (data.sales_rep[0].first_name+' '+data.sales_rep[0].last_name) : (data.sales_manager[0].first_name+' '+data.sales_manager[0].last_name));
                        // var profileImgManager = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((profileImg!=="") && (profileImg!==null) ) ? profileImg : 'default-avatar.png');
                        return (
                        <div className="profile-wrap  mb-3">
                          <div className="d-flex p-3 justify-content-between w-100 align-items-center">
                            <div className="profile-name">
                              {data.title}
                            </div>
                            <div className="actions">
                              <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="ri-more-fill f-600"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                  <a onClick={() => this.setEventDetailShow(true, data)} className="dropdown-item"><i
                                    className="iconmoon icon-viewprofile"></i> View Details</a>
                                  {/* <Link to={`./coaching-detail`} className="dropdown-item" >
                                <i className="ri-list-check-2 "></i> View Tasks</Link> */}
    
                                </div>
                              </div>
                            </div>
                          </div>
                          <ul className="shedule-details list-unstyled px-3">
                            <li>
                              <i className="ri-calendar-event-line color_blue "></i> {moment(parseInt(data.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")}
                            </li>
                            <li>
                              <i className="ri-time-line color_blue"></i> {moment(parseInt(data.start_date_tmestamp)*1000).utc().format("hh:mma")} - {moment(parseInt(data.end_date_tmestamp)*1000).utc().format("hh:mma")}
                            </li>
                          </ul>
                          <div className="d-flex px-3 profile-status-wrap py-2 justify-content-end w-100 align-items-end">
                            <div className="img-wrap ml-1">
                              <img src={profileImg} alt={salesRepName} title={salesRepName} />
                            </div>
                            {/* profile_pic_url */}
                            <div className="img-wrap ml-1">
                              <img src={profileImgRep} alt={saleRepName2} title={saleRepName2}/>
                            </div>
                          </div>
                        </div>
                        )
    
                       })
                      ) : (<div className="profile-card-container-empty text-center py-5">
                    No Sessions
                  </div>)

                    ) : (<div className="profile-card-container-empty text-center py-5">
                    No Sessions
                  </div>)}
      
                  </div>
                </div>
              </div>

              <div className="card-row coaching-card-rows">
                <div className="cord-body-wrap">
                  <div className="card-head mb-2 text-center bg_color_yellow f-600 f2015 d-flex justify-content-center align-items-center">
                    <i className="ri-feedback-line ri-2x f-100 mr-1"></i> Awaiting Feedback (
                      {(AllDataLoaded && AllDataLoaded.length>0) ? (
                      (AllDataLoaded[0].awaiting_feedback && AllDataLoaded[0].awaiting_feedback.count>0) ? (
                        AllDataLoaded[0].awaiting_feedback.count
                      ) : ('0')
                    ) : 0}
                    )
                  </div>
                  <div className="profile-card-container px-2 py-1">
                  {(AllDataLoaded && AllDataLoaded.length>0) ? (
                      (AllDataLoaded[0].awaiting_feedback && AllDataLoaded[0].awaiting_feedback.count>0) ? (
                      AllDataLoaded[0].awaiting_feedback.data.map((data) => {
                        var profileImgRep = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ ((user_role==="3") ? (((data.sales_rep[0].profile_pic_url!=="") && (data.sales_rep[0].profile_pic_url!==null) ) ? data.sales_rep[0].profile_pic_url : 'default-avatar.png') : 
                        (((data.sales_manager[0].profile_pic_url!=="") && (data.sales_manager[0].profile_pic_url!==null) ) ? data.sales_manager[0].profile_pic_url : 'default-avatar.png'));
                        var saleRepName2 = ((user_role==="3") ? (data.sales_rep[0].first_name+' '+data.sales_rep[0].last_name) : (data.sales_manager[0].first_name+' '+data.sales_manager[0].last_name));


                        // var profileImgManager = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((profileImg!=="") && (profileImg!==null) ) ? profileImg : 'default-avatar.png');
                        return (
                        <div className="profile-wrap  mb-3">
                          <div className="d-flex p-3 justify-content-between w-100 align-items-center">
                            <div className="profile-name">
                              {data.title}
                            </div>
                            <div className="actions">
                                  <div className="dropdown">
                                  <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton10"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="ri-more-fill f-600"></i>
                                  </button>
                                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                    {user_role==3?<a onClick={() => this.setAddNotes(true, data.title, data.id)} className="dropdown-item"><i
                                      className="ri-add-line"></i> Add Notes</a>:null}
                                  
                                    {data.survey_status!="SUBMIT" ? <a onClick={() => this.setLaunchSurveyShow(true,data.id)} className="dropdown-item"><i
                                      className="ri-survey-line"></i> Launch Survey</a> : null}
                                    <Link onClick={() => this.redirectDetails(data)} 
                                    // to={`./coaching-detail`} 
                                    className="dropdown-item" >
                                      <i className="iconmoon icon-viewprofile"></i> View Details</Link>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <ul className="shedule-details list-unstyled px-3">
                            <li>
                              <i className="ri-calendar-event-line color_blue "></i> {moment(parseInt(data.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")}
                            </li>
                            <li>
                              <i className="ri-time-line color_blue"></i> {moment(parseInt(data.start_date_tmestamp)*1000).utc().format("hh:mma")} - {moment(parseInt(data.end_date_tmestamp)*1000).utc().format("hh:mma")}
                            </li>
                          </ul>
                          <div className="d-flex px-3 profile-status-wrap py-2 justify-content-end w-100 align-items-end">
                            <div className="img-wrap ml-1">
                              <img src={profileImg} alt={salesRepName} title={salesRepName} />
                            </div>
                            {/* profile_pic_url */}
                            <div className="img-wrap ml-1">
                              <img src={profileImgRep} alt={saleRepName2} title={saleRepName2}/>
                            </div>
                          </div>
                        </div>
                        )
    
                       })
                      ) : (<div className="profile-card-container-empty text-center py-5">
                      No Sessions
                    </div>)

                    ) : (<div className="profile-card-container-empty text-center py-5">
                    No Sessions
                  </div>)}

                  </div>
                </div>
              </div>

{(user_role==="3") ? (
              <div className="card-row coaching-card-rows">
              <div className="cord-body-wrap">
                <div className="card-head mb-2 text-center bg_color_light_blue f-600 f2015 d-flex justify-content-center align-items-center">
                  <i className="ri-bar-chart-box-line ri-2x f-100 mr-1"></i> Analytical Feedback (
                    {(AllDataLoaded && AllDataLoaded.length>0) ? (
                    (AllDataLoaded[0].analytical_feedback && AllDataLoaded[0].analytical_feedback.count>0) ? (
                      AllDataLoaded[0].analytical_feedback.count
                    ) : ('0')
                  ) : 0}
                  )
                </div>
                <div className="profile-card-container px-2 py-1">
                {(AllDataLoaded && AllDataLoaded.length>0) ? (
                    (AllDataLoaded[0].analytical_feedback && AllDataLoaded[0].analytical_feedback.count>0) ? (
                    AllDataLoaded[0].analytical_feedback.data.map((data) => {
                      var profileImgRep = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ ((user_role==="3") ? (((data.sales_rep[0].profile_pic_url!=="") && (data.sales_rep[0].profile_pic_url!==null) ) ? data.sales_rep[0].profile_pic_url : 'default-avatar.png') : 
                      (((data.sales_manager[0].profile_pic_url!=="") && (data.sales_manager[0].profile_pic_url!==null) ) ? data.sales_manager[0].profile_pic_url : 'default-avatar.png'));
                      var saleRepName2 = ((user_role==="3") ? (data.sales_rep[0].first_name+' '+data.sales_rep[0].last_name) : (data.sales_manager[0].first_name+' '+data.sales_manager[0].last_name));
                    // var profileImgManager = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((profileImg!=="") && (profileImg!==null) ) ? profileImg : 'default-avatar.png');
                      return (
                      <div className="profile-wrap  mb-3">
                        <div className="d-flex p-3 justify-content-between w-100 align-items-center">
                          <div className="profile-name">
                            {data.title}
                          </div>
                          <div className="actions">
                              <div className="dropdown">
                              <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton10"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="ri-more-fill f-600"></i>
                              </button>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                              {data.survey_status!="SUBMIT" ? <a onClick={() => this.setLaunchSurveyShow(true,data.id)} className="dropdown-item"><i
                                  className="ri-survey-line"></i> Launch Survey</a> : null}
                                <Link onClick={() => this.redirectDetails(data)} className="dropdown-item" >
                                  <i className="iconmoon icon-viewprofile"></i> View Details</Link>
                              </div>
                            </div>                              
                                {/* <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton10"
                                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="ri-more-fill f-600"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                  <a onClick={() => this.setLaunchSurveyShow(true)} className="dropdown-item"><i
                                    className="ri-survey-line"></i> Launch Survey</a>
                                  <Link to={`./coaching-detail`} className="dropdown-item" >
                                    <i className="iconmoon icon-viewprofile"></i> View Details</Link>
                                </div>
                              </div> */}
                            </div>
                        </div>
                        <ul className="shedule-details list-unstyled px-3">
                          <li>
                            <i className="ri-calendar-event-line color_blue "></i> {moment(parseInt(data.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")}
                          </li>
                          <li>
                            <i className="ri-time-line color_blue"></i> {moment(parseInt(data.start_date_tmestamp)*1000).utc().format("hh:mma")} - {moment(parseInt(data.end_date_tmestamp)*1000).utc().format("hh:mma")}
                          </li>
                        </ul>
                        <div className="d-flex px-3 profile-status-wrap py-2 justify-content-end w-100 align-items-end">
                          <div className="img-wrap ml-1">
                            <img src={profileImg} alt={salesRepName} title={salesRepName} />
                          </div>
                          {/* profile_pic_url */}
                          <div className="img-wrap ml-1">
                            <img src={profileImgRep} alt={saleRepName2} title={saleRepName2}/>
                          </div>
                        </div>
                      </div>
                      )
  
                     })
                    ) : (<div className="profile-card-container-empty text-center py-5">
                    No Sessions
                  </div>)

                  ) : (<div className="profile-card-container-empty text-center py-5">
                  No Sessions
                </div>)}                    

{/* 
                <div className="profile-card-container-empty text-center py-5">
                  No Sessions
                </div> */}
              </div>
              </div>
            </div>
) : null}

              <div className="card-row coaching-card-rows">
                <div className="cord-body-wrap">
                  <div className="card-head mb-2 text-center bg_color_orange f-600 f2015 d-flex justify-content-center align-items-center">
                    <i className="ri-timer-line ri-2x f-100 mr-1"></i> Awaiting Completion (
                      {(AllDataLoaded && AllDataLoaded.length>0) ? (
                      (AllDataLoaded[0].awaiting_completion && AllDataLoaded[0].awaiting_completion.count>0) ? (
                        AllDataLoaded[0].awaiting_completion.count
                      ) : ('0')
                    ) : 0}
                    )
                  </div>
                  <div className="profile-card-container px-2 py-1">
                  {(AllDataLoaded && AllDataLoaded.length>0) ? (
                      (AllDataLoaded[0].awaiting_completion && AllDataLoaded[0].awaiting_completion.count>0) ? (
                      AllDataLoaded[0].awaiting_completion.data.map((data) => {
                        var profileImgRep = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ ((user_role==="3") ? (((data.sales_rep[0].profile_pic_url!=="") && (data.sales_rep[0].profile_pic_url!==null) ) ? data.sales_rep[0].profile_pic_url : 'default-avatar.png') : 
                        (((data.sales_manager[0].profile_pic_url!=="") && (data.sales_manager[0].profile_pic_url!==null) ) ? data.sales_manager[0].profile_pic_url : 'default-avatar.png'));
                        var saleRepName2 = ((user_role==="3") ? (data.sales_rep[0].first_name+' '+data.sales_rep[0].last_name) : (data.sales_manager[0].first_name+' '+data.sales_manager[0].last_name));
                        // var profileImgManager = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((profileImg!=="") && (profileImg!==null) ) ? profileImg : 'default-avatar.png');
                        return (
                        <div className="profile-wrap  mb-3">
                          <div className="d-flex p-3 justify-content-between w-100 align-items-center">
                            <div className="profile-name">
                              {data.title}
                            </div>
                            <div className="actions">
                                    <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton51"
                                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <i className="ri-more-fill f-600"></i>
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton51">
                                      {user_role==3 ? <a onClick={() => this.setMarkComplete(true, data)} className="dropdown-item"><i className="ri-checkbox-circle-line"></i> Mark Complete</a>:null}
                                    
                                      {data.survey_status!="SUBMIT" ? <a onClick={() => this.setLaunchSurveyShow(true, data.id)} className="dropdown-item"><i
                                        className="ri-survey-line"></i> Launch Survey</a> :null}
                                      <Link onClick={() => this.redirectDetails(data)} className="dropdown-item" >
                                        <i className="iconmoon icon-viewprofile"></i> View Details</Link>


                                    </div>
                                  </div>                              
                                {/* <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton51"
                                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="ri-more-fill f-600"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton51">
                                  <a onClick={() => this.setLaunchSurveyShow(true)} className="dropdown-item"><i
                                    className="ri-survey-line"></i> Launch Survey</a>
                                  <Link to={`./coaching-detail`} className="dropdown-item" >
                                    <i className="iconmoon icon-viewprofile"></i> View Details</Link>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <ul className="shedule-details list-unstyled px-3">
                            <li>
                              <i className="ri-calendar-event-line color_blue "></i> {moment(parseInt(data.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")}
                            </li>
                            <li>
                              <i className="ri-time-line color_blue"></i> {moment(parseInt(data.start_date_tmestamp)*1000).utc().format("hh:mma")} - {moment(parseInt(data.end_date_tmestamp)*1000).utc().format("hh:mma")}
                            </li>
                          </ul>
                          <div className="d-flex px-3 profile-status-wrap py-2 justify-content-end w-100 align-items-end">
                            <div className="img-wrap ml-1">
                              <img src={profileImg} alt={salesRepName} title={salesRepName} />
                            </div>
                            {/* profile_pic_url */}
                            <div className="img-wrap ml-1">
                              <img src={profileImgRep} alt={saleRepName2} title={saleRepName2}/>
                            </div>
                          </div>
                        </div>
                        )
    
                       })
                      ) : (<div className="profile-card-container-empty text-center py-5">
                      No Sessions
                    </div>)

                    ) : (<div className="profile-card-container-empty text-center py-5">
                    No Sessions
                  </div>)}                      

                  </div>
                </div>
              </div>

              <div className="card-row coaching-card-rows">
                <div className="cord-body-wrap">
                  <div className="card-head mb-2 bg_color_light_green text-center f-600 f2015 d-flex justify-content-center align-items-center">
                    <i className="ri-video-line ri-2x f-100 mr-1"></i> Completed Sessions (
                      {(AllDataLoaded && AllDataLoaded.length>0) ? (
                      (AllDataLoaded[0].completed_sessions && AllDataLoaded[0].completed_sessions.count>0) ? (
                        AllDataLoaded[0].completed_sessions.count
                      ) : ('0')
                    ) : 0}
                    )
                  </div>
                  <div className="profile-card-container px-2 py-1">
                  {(AllDataLoaded && AllDataLoaded.length>0) ? (
                      (AllDataLoaded[0].completed_sessions && AllDataLoaded[0].completed_sessions.count>0) ? (
                      AllDataLoaded[0].completed_sessions.data.map((data) => {
                        var profileImgRep = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ ((user_role==="3") ? (((data.sales_rep[0].profile_pic_url!=="") && (data.sales_rep[0].profile_pic_url!==null) ) ? data.sales_rep[0].profile_pic_url : 'default-avatar.png') : 
                        (((data.sales_manager[0].profile_pic_url!=="") && (data.sales_manager[0].profile_pic_url!==null) ) ? data.sales_manager[0].profile_pic_url : 'default-avatar.png'));
                        var saleRepName2 = ((user_role==="3") ? (data.sales_rep[0].first_name+' '+data.sales_rep[0].last_name) : (data.sales_manager[0].first_name+' '+data.sales_manager[0].last_name));
                        // var profileImgManager = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((profileImg!=="") && (profileImg!==null) ) ? profileImg : 'default-avatar.png');
                        return (
                        <div className="profile-wrap  mb-3">
                          <div className="d-flex p-3 justify-content-between w-100 align-items-center">
                            <div className="profile-name">
                              {data.title}
                            </div>
                            <div className="actions">
                                    <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton6"
                                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <i className="ri-more-fill f-600"></i>
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton6">
                                    <Link onClick={() => this.redirectDetails(data)} className="dropdown-item" >
                                        <i className="iconmoon icon-viewprofile"></i> View Details</Link>
                                    </div>
                                  </div>
                                {/* <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton6"
                                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="ri-more-fill f-600"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton6">
                                <Link to={`./coaching-detail`} className="dropdown-item" >
                                    <i className="iconmoon icon-viewprofile"></i> View Details</Link>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <ul className="shedule-details list-unstyled px-3">
                            <li>
                              <i className="ri-calendar-event-line color_blue "></i> {moment(parseInt(data.start_date_tmestamp)*1000).utc().format("dddd, DD MMMM yyyy")}
                            </li>
                            <li>
                              <i className="ri-time-line color_blue"></i> {moment(parseInt(data.start_date_tmestamp)*1000).utc().format("hh:mma")} - {moment(parseInt(data.end_date_tmestamp)*1000).utc().format("hh:mma")}
                            </li>
                          </ul>
                          <div className="d-flex px-3 profile-status-wrap py-2 justify-content-end w-100 align-items-end">
                            <div className="img-wrap ml-1">
                              <img src={profileImg} alt={salesRepName} title={salesRepName} />
                            </div>
                            {/* profile_pic_url */}
                            <div className="img-wrap ml-1">
                              <img src={profileImgRep} alt={saleRepName2} title={saleRepName2}/>
                            </div>
                          </div>
                        </div>
                        )
    
                       })
                      ) : (<div className="profile-card-container-empty text-center py-5">
                      No Sessions
                    </div>)

                    ) : (<div className="profile-card-container-empty text-center py-5">
                    No Sessions
                  </div>)} 

                  </div>
                </div>
              </div>


            </div>
          </div>
        </main>

      </>
    )
  }
}
