import React, { Component } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import DatePicker from "react-datepicker";
import ImageEditor from '../imageCropper/imageEditor';
import "react-datepicker/dist/react-datepicker.css";
import axios from '../apiConnection/axios';
import moment from 'moment';
import CustomSelect from "./elements/customSelect";

import Modal from "./modals/modal";

import {
    Link
} from "react-router-dom";
import Utility from "../../components/utility";
const utilityObj = new Utility;
export default class SalesProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otpUserShow: false,
            file: null,
            base64URL: "",
            cropImageShow: false,
            editStageShow: false,
            addTaskShow: false,
            editTaskShow: false,
            deactivateTaskShow: false,
            deactivateStageShow: false,
            startDate: new Date(),
            salesProcess: "",
            rowData: {},
            rowTaskData: {},
            rowTaskAddData: {},
            rowTaskEditData: {},
            edit_stage_id: "",
            stage_id: '',
            edit_stage_name: "",
            edit_stage_description: "",
            stage_name1: "",
            task_description: "",
            task_required: "0",
            stageList: "",
            defaultStageSelectText: "Select Stage",
        };
        this.changeFunction = this.changeFunction.bind(this);
        this.changeTaskFunction = this.changeTaskFunction.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getsalesProcess();
        this.getStages();
    }


    getsalesProcess = async () => {
        utilityObj.loaderView("show");
        await axios.get(`sales-process/get-stages-and-tasks`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                utilityObj.loaderView("hide");
                if (res.data.code === 200) {
                    this.setState({ salesProcess: res.data.data });
                }
            })
            .catch((error) => {
                utilityObj.loaderView("hide");
                console.log(error)
            })
    }





    // setDeactivateStageShow = (newValue) => {
    //     this.setState({ deactivateStageShow: newValue });
    // }





    setDeactivateTaskShow = (newValue, salesTaskData) => {
        console.log(salesTaskData)

        if (newValue === false) {
            this.setState({ deactivateTaskShow: newValue });
        }
        else {
            this.setState({ deactivateTaskShow: newValue, rowTaskData: salesTaskData });
            this.setState({ deactivateTaskShow: newValue });
        }
    }

    UpdateTaskStatus = async () => {
        utilityObj.loaderView("show");
        if (this.state.rowTaskData.status == 1) {
            this.state.rowTaskData.status = "0"
        }
        else if (this.state.rowTaskData.status == 0) {
            this.state.rowTaskData.status = "1"
        }
        const updateTaskinfo = {
            type: "task",
            sales_process_id: this.state.rowTaskData.id,
            status: this.state.rowTaskData.status,
        }
        await axios.post(`sales-process/update-sales-process-status`, updateTaskinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        }).then(res => {
            console.log(res);
            utilityObj.loaderView("hide");
            if (res.data.code === 200) {
                this.getsalesProcess();
                this.setDeactivateTaskShow(false)
            }
            else {
                utilityObj.loaderView("hide");
                this.setState({
                    Message: "Server Error.",
                    MessageType: 'danger',
                    fileextractDone: 0,
                });
            }
        })
            .catch((error) => {
                utilityObj.loaderView("hide");
                console.log(error)
            })

    }




    setEditStageShow = (newValue, salesProcesss) => {
        console.log(salesProcesss)
        if (newValue === false) {
            this.setState({ editStageShow: newValue });
        }
        else {
            this.setState({ editStageShow: newValue });
            this.setState({ rowData: salesProcesss });
            this.setState({ edit_stage_name: salesProcesss.stage_name });
            this.setState({ edit_stage_id: salesProcesss.stage_id });
            this.setState({ edit_stage_description: salesProcesss.stage_description });
            // this.setState({ stage_order: salesProcesss.ordering });
            var icon = salesProcesss.stage_icon_url;
            var showicon = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((icon !== "") && (icon !== null)) ? icon : 'default_sales_stage.png');
            this.setState({ edit_stage_icon: showicon });
            this.setState({ edit_stage_icon_filename: icon });
            this.setState({
                Message: '',
                MessageType: ''
            });

        }
    }

    handleClick(e) {
        if (this.state.fileworking !== true) {
            this.setState({ fileworking: true });
            this.inputFile.click();
        } else {
            this.setState({ fileworking: false });
            this.inputFile.click();
        }
    }

    updateIconPic = async () => {
        const data = new FormData()
        data.append('file', this.state.uploadFile)
        await axios.post(`/users/update-icon-pic`,
            data, {
            headers: {
                'Accept': 'multipart/form-data',
                'Content-Type': 'multipart/form-data;charset=UTF-8',
            }
        },
        )
            .then(res => { // then print response status
                console.log(res);
                var icon = res.data.filename;
                var showicon = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((icon !== "") && (icon !== null)) ? icon : 'default_sales_stage.png');
                this.setState({ edit_stage_icon: showicon });
                this.setState({ edit_stage_icon_filename: icon });

                // this.setState({ edit_stage_icon: res.data.filename });

            })
            .catch(err => {
                console.log(err)
            })
    }

    getImgFromCrop = async (valu) => {
        this.setState({ profileImg: valu })
        console.log(valu)
        await fetch(valu)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], "testfile", { type: "image/png" })
                const url = URL.createObjectURL(file);
                this.setState({ profileImg: url, uploadFile: file })
                console.log(url);
                this.updateIconPic();
                this.setState({
                    base64URL: "",
                    file: null
                })
                this.setcropImage(false);
            })
    }
    closeEditor = () => {
        this.setState({ profileImg: "", uploadFile: null })
        this.setState({ base64URL: "", file: null })
        this.setcropImage(false);
        this.componentDidMount();
    }
    setcropImage = (newValue) => {
        this.setState({ cropImageShow: newValue });
    }
    cropImage = () => {
        return (<>
            <ImageEditor base64URL={this.state.base64URL} closeEditor={this.closeEditor} getImgFromCrop={this.getImgFromCrop} />
        </>);
    }

    getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("Called", reader);
                baseURL = reader.result;
                console.log(baseURL);
                resolve(baseURL);
            };
            console.log(fileInfo);
        });
    };

    handleFileInputChange = e => {
        console.log(e.target.files[0]);
        let { file } = this.state;

        file = e.target.files[0];

        this.getBase64(file)
            .then(result => {
                file["base64"] = result;
                console.log("File Is", file);
                this.setState({
                    base64URL: result,
                    file
                });
            })
            .catch(err => {
                console.log(err);
            });

        this.setState({
            file: e.target.files[0]
        });
        if (e.target.files[0] != undefined) {
            this.setcropImage(true);
        }

    };
    setEditStage = async () => {
        utilityObj.loaderView("show");
        var setnStageinfo = {
            stage_id: this.state.edit_stage_id,
            stage_name: this.state.edit_stage_name,
            stage_description: this.state.edit_stage_description,
            // order: this.state.stage_order,
            stage_icon_url: (((this.state.edit_stage_icon_filename !== "") && (this.state.edit_stage_icon_filename !== null)) ? this.state.edit_stage_icon_filename : 'default_sales_stage.png'),
        }

        await axios.post(`/sales-process/edit-stage`, setnStageinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                if (res.data.code === 200) {
                    this.getsalesProcess();
                    this.setState({
                        Message: "Sales stage update successfully ",
                        MessageType: 'success'
                    });

                    setTimeout(() => { this.setEditStageShow(false) }, 1500);
                } else {
                    utilityObj.loaderView("hide");
                    this.setState({
                        Message: res.data.message,
                        MessageType: 'danger'
                    });
                    setTimeout(() => { this.setEditStageShow(false) }, 1500);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    setDeactivateStageShow = (newValue, salesProcesssData) => {
        if (newValue === false) {
            this.setState({ deactivateStageShow: newValue });
        }
        else {
            this.setState({ deactivateStageShow: newValue, rowData: salesProcesssData });
            this.setState({ deactivateStageShow: newValue });
        }
    }
    UpdateStageStatus = async () => {
        utilityObj.loaderView("show");
        if (this.state.rowData.status == 1) {
            this.state.rowData.status = "0"
        }
        else if (this.state.rowData.status == 0) {
            this.state.rowData.status = "1"
        }
        const updateStageinfo = {
            type: "stage",
            sales_process_id: this.state.rowData.stage_id,
            status: this.state.rowData.status,
        }
        await axios.post(`sales-process/update-sales-process-status`, updateStageinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        }).then(res => {
            console.log(res);
            utilityObj.loaderView("hide");
            if (res.data.code === 200) {
                this.getStages();
                this.getsalesProcess();
                this.setDeactivateStageShow(false)
            }
            else {
                utilityObj.loaderView("hide");
                this.setState({
                    Message: "Server Error.",
                    MessageType: 'danger',
                    fileextractDone: 0,
                });
            }
        })
            .catch((error) => {
                utilityObj.loaderView("hide");
                console.log(error)
            })

    }
    changeFunction = (event) => {

        if (event.target.name === "stage_name") {
            this.setState({ edit_stage_name: event.target.value });
        }
        else if (event.target.name === "stage_description") {
            this.setState({ edit_stage_description: event.target.value });
            if (event.target.value.trim().length > 255) {
                this.setState({
                    Message: "Stage description cannot be more than 255 characters.",
                    MessageType: 'danger',
                });
            }
            if (event.target.value.trim().length <= 255) {
                this.setState({
                    Message: null,
                    MessageType: '',
                });
            }
        }
        // else if (event.target.name === "stage_order") {
        //     this.setState({ stage_order: event.target.value });
        // }
    }

    setAddTaskShow = (newValue, stageTaskData) => {
        this.setState({ startDate: new Date() })
        if (newValue === false) {
            this.setState({ addTaskShow: newValue, task_required: 0 });
        }
        else {
            this.setState({ addTaskShow: newValue, rowTaskAddData: stageTaskData });

            this.setState({ Message: '', MessageType: '', task_title: '' });
        }
    }


    changeTaskFunction = (e) => {
        if (e.target.id === 'stage_name1') {
            this.setState({ stage_name1: e.target.value });
        } else if (e.target.id === 'task_description') {
            this.setState({ task_description: e.target.value });

            // if (e.target.value.trim().length > 0) {
            //     this.setState({ Message: "", MessageType: '', });
            // }

            if (e.target.value.trim().length > 255) {
                this.setState({
                    Message: "Task description cannot be more than 255 characters.",
                    MessageType: 'danger',
                });
            }
            if (e.target.value.trim().length <= 255) {
                this.setState({
                    Message: null,
                    MessageType: '',
                });
            }

        }

        else if (e.target.id === 'task_required') {
            console.log(e.target.checked);
            if (e.target.checked === true) {
                this.setState({ task_required: 1 });
            } else {
                this.setState({ task_required: 0 });
            }
            console.log(this.state.task_required);
        }
    }

    addTaskOnStage = async (e) => {

        // task_description.trim().length === 0
        // var task_title = ''
        // alert(task_title)
        const { startDate, task_description, task_required } = this.state;
        var startDatesend = new Date(startDate);
        const addTaskOnStageinfo = {
            stage_id: this.state.rowTaskAddData.stage_id,
            task_title: task_description,
            task_required: task_required,
            start_date: moment(startDatesend).format('MM/DD/YYYY'),

        }
        if (task_description.trim().length <= 0) {
            this.setState({
                Message: "Task name is required",
                MessageType: 'danger',
            });
        }

        else {
            this.setState({ Message: "", MessageType: '', });
            await axios.post(`sales-process/add-task`, addTaskOnStageinfo, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                }
            })
                .then(res => {
                    console.log(res)
                    if (res.data.code === 200) {
                        this.setState({
                            Message: res.data.message,
                            MessageType: "success"
                        });
                        setTimeout(() => {
                            this.setAddTaskShow(false);
                            this.getsalesProcess();
                        }, 3000);

                    } else {
                        this.setState({
                            Message: res.data.message,
                            MessageType: "danger"
                        });
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }



    }


    getStages = async () => {
        await axios.get(`sales-process/get-active-stages`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    this.setState({ stageList: res.data.data });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    changeSelect = (ele, Uid) => {
        console.log(ele);
        console.log(Uid);
        this.setState({ stage_id: ele })
    }


    setEditTaskShow = (newValue, salesTaskData, salesProcesss) => {
        if (newValue === false) {
            this.setState({ editTaskShow: newValue });
        }
        else {

            this.setState({
                editTaskShow: newValue,
                rowTaskEditData: salesTaskData,
                Message: '', MessageType: '',
                task_title: salesTaskData.task_title,
                task_required: salesTaskData.task_required,
                start_date: salesTaskData.startDate
            })
            // this.setState({ startDate: new Date(salesTaskData.start_date) })
            this.setState({ startDate: new Date(salesTaskData.StartDateFormat) })
            this.setState({ defaultStageSelectText: salesProcesss.stage_name, stage_id: salesProcesss.stage_id })
        }
    }





    editTaskOnStage = async () => {
        var startDatesend = new Date(this.state.startDate);
        if (this.state.stage_id === "") {
            this.state.stage_id = this.state.rowTaskEditData.stage_id
        }
        if (this.state.task_description === "") {
            this.state.task_description = this.state.rowTaskEditData.task_title
        }
        const editTaskOnStageinfo = {
            stage_id: this.state.stage_id,
            task_id: this.state.rowTaskEditData.id,
            task_title: this.state.task_description,
            task_required: this.state.task_required.toString(),
            start_date: moment(startDatesend).format('MM/DD/YYYY'),
        }
        await axios.post(`sales-process/edit-task`, editTaskOnStageinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    this.setState({
                        Message: res.data.message,
                        MessageType: "success"
                    });
                    setTimeout(() => {
                        this.setEditTaskShow(false);
                        this.getsalesProcess();
                        this.state.task_description = ""
                    }, 3000);
                } else {
                    this.setState({
                        Message: res.data.message,
                        MessageType: "danger"
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }


    editStage = () => {
        const { edit_stage_id, edit_stage_name, edit_stage_description, edit_stage_icon, Message, MessageType } = this.state;

        return (<>
            {edit_stage_id ? <>
                <form>
                    {((Message) && (Message !== null)) ?
                        (
                            <div className={`alert alert-${MessageType}`}>
                                {Message}
                            </div>
                        ) : (``)
                    }
                    <div className="clearfix mb-4">
                        <div className="profile-pic float-sm-left">
                            <div className="img-wrap">
                                <img className="profile-pic-img" id="salesProcessicon" src={edit_stage_icon} alt="stage icon" />
                                <div className="p-image" onClick={this.handleClick}>
                                    <i className="fa fa-camera upload-button" id="salesProcessbtn" ></i>
                                    <input value="" className="file-upload" id="salesProcessfile" ref={(ref) => this.inputFile = ref} onChange={(e) => this.handleFileInputChange(e)} type="file" accept="image/*" />
                                </div>
                            </div>
                        </div>
                        <div className="profile-info float-sm-left">
                            <div className="form-group">
                                <label className="mb-0">Stage Name</label>
                                <input type="text" id='stage_name' name="stage_name" onChange={this.changeFunction} className="form-control f-600 f20'1'5" value={edit_stage_name}
                                    placeholder="Stage Name" />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="mb-0">Description</label>
                        <textarea id='stage_description' name="stage_description" onChange={this.changeFunction} className="form-control f-600 f2015" placeholder="Description">{edit_stage_description}</textarea>
                    </div>
                </form>
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn dark-btn mx-2" onClick={() => this.setEditStage()}>Save</button>
                    <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setEditStageShow(false)}>Cancel</button>
                </div>
            </> : ""}
        </>);
    }


    addTask = () => {
        const { stage_name } = this.state.rowTaskAddData
        const { Message, MessageType } = this.state
        return (<>
            <form>
                {((Message) && (Message !== null)) ?
                    (
                        <div className={`alert alert-${MessageType}`}>
                            {Message}
                        </div>
                    ) : (``)
                }
                <div className="form-group">
                    <label className="mb-0">Stage Name</label>
                    <div className="feild-text py-3 f-600 f2015 color_light_gray">{stage_name}</div>
                </div>
                <div className="form-group">
                    <label className="mb-0">Task Name</label>
                    <textarea id='task_description' name="task_description" onChange={this.changeTaskFunction} className="form-control f-600 f2015" placeholder="Task Name"></textarea>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="mb-0">Start Date</label>
                            <span className="date-icon"><i className="ri-calendar-line"></i></span>
                            <DatePicker
                                dateFormat="MM-dd-yyyy"
                                className="form-control f-600 f2015"
                                selected={this.state.startDate}
                                onChange={(date) => this.setState({ startDate: date })}
                                minDate={moment().toDate()}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="mb-0">Required</label>
                            <label className="switch d-block mt-2">
                                <input type="checkbox" id='task_required' name="task_required" onChange={this.changeTaskFunction} />
                                <span className="slider"></span>
                            </label>
                        </div>
                    </div>
                </div>

            </form>
            <div className="modal-footer justify-content-center">
                <button type="button" className="btn dark-btn mx-2" onClick={() => this.addTaskOnStage(false)}>Save</button>
                <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setAddTaskShow(false)}>Cancel</button>
            </div>
        </>)
    }

    editTask = () => {
        // const { task_title, task_required, start_date } = this.state.rowTaskEditData
        const { task_title, task_required, start_date, Message, MessageType, stage_name, stageList } = this.state

        return (<>
            <form>
                {((Message) && (Message !== null)) ?
                    (
                        <div className={`alert alert-${MessageType}`}>
                            {Message}
                        </div>
                    ) : (``)
                }
                <div className="form-group">
                    <label className="mb-0">Stage Name</label>
                    {/* <div className="feild-text py-3 f-600 f2015 color_light_gray">{stage_name}</div> */}
                    <CustomSelect
                        className="form-control f-600 text-left"
                        defaultText={this.state.defaultStageSelectText}
                        name="stage_id" dataUid={`stage_id`}
                        optionsList={stageList}
                        onSelect={this.changeSelect}
                    />
                </div>
                <div className="form-group">
                    <label className="mb-0">Task Name</label>
                    <textarea className="form-control f-600 f2015" onChange={this.changeTaskFunction} id="task_description" name="task_description" defaultValue={task_title} placeholder="Description"></textarea>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="mb-0">Start Date</label>
                            <span className="date-icon"><i className="ri-calendar-line"></i></span>
                            <DatePicker
                                dateFormat="MM-dd-yyyy"
                                className="form-control f-600 f2015"
                                selected={this.state.startDate}
                                onChange={(date) => this.setState({ startDate: date })}
                                minDate={moment().toDate()}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="mb-0">Required</label>
                            <label className="switch d-block mt-2">
                                {/* {(task_required === "1") ?
                                 <input type="checkbox" onChange={this.changeTaskFunction} checked id='task_required' name="task_required" />
                                    : <input type="checkbox" onChange={this.changeTaskFunction} id='task_required' name="task_required" />} */}
                                <input type="checkbox" checked={(task_required == 1) ? true : false} onChange={this.changeTaskFunction} id='task_required' name="task_required" />
                                <span className="slider"></span>
                            </label>
                        </div>
                    </div>
                </div>

            </form>
            <div className="modal-footer justify-content-center">
                <button type="button" className="btn dark-btn mx-2" onClick={() => this.editTaskOnStage()}>Save</button>
                <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setEditTaskShow(false)}>Cancel</button>
            </div>
        </>
        )
    }



    deactivateTask = () => {
        return (<>
            <div className="text-center m-4">
                <p className="m-0">
                    Are you sure you want to  {(this.state.rowTaskData.status === "1") ? `deactivate` : `activate`} task?
                </p>
                <div className="modal-footer border-0 justify-content-center">
                    <button type="button" className="btn dark-btn mx-2" onClick={() => this.UpdateTaskStatus()}>Yes</button>
                    <button type="button" className="btn light-btn mx-2" data-dismiss="modal" onClick={() => this.setDeactivateTaskShow(false)}>No</button>
                </div>
            </div>
        </>)
    }

    deactivateStage = () => {
        const { stage_name } = this.state.rowData

        return (<>
            <div className="text-center m-4">
                <p className="m-0"> Deactivating the stage will apply to all the tasks of the stage. <br />
                    <span className="d-block mb-2"></span>
                    Are you sure you want to deactivate the <span className="f-600"> {stage_name}
                    </span> stage?
                </p>
                <div className="modal-footer border-0 justify-content-center">
                    <button type="button" className="btn dark-btn mx-2" onClick={() => this.UpdateStageStatus()}>Yes</button>
                    <button type="button" className="btn light-btn mx-2" data-dismiss="modal" onClick={() => this.setDeactivateStageShow(false)}>No</button>
                </div>
            </div>
        </>)
    }

    render() {
        const { editStageShow, addTaskShow, editTaskShow, deactivateTaskShow, deactivateStageShow, salesProcess, edit_stage_id, edit_stage_name, edit_stage_description,
            cropImageShow } = this.state
        return (<>

            <Modal
                title=""
                contentClasses=""
                size="md"
                onClose={() => this.setcropImage(false)}
                show={cropImageShow}>
                {this.cropImage()}
            </Modal>
            <Modal
                title="Edit Stage"
                contentClasses=""
                size="md"
                onClose={() => this.setEditStageShow(false)}
                show={editStageShow}>
                {this.editStage()}
            </Modal>

            <Modal
                title="Add Task"
                contentClasses=""
                size="md"
                onClose={() => this.setAddTaskShow(false)}
                show={addTaskShow}>
                {this.addTask()}
            </Modal>

            <Modal
                title="Edit Task"
                contentClasses=""
                size="md"
                onClose={() => this.setEditTaskShow(false)}
                show={editTaskShow}>
                {this.editTask()}
            </Modal>

            <Modal
                title=""
                contentClasses=""
                size="md"
                onClose={() => this.setDeactivateTaskShow(false)}
                show={deactivateTaskShow}>
                {this.deactivateTask()}
            </Modal>

            <Modal
                title=""
                contentClasses=""
                size="md"
                onClose={() => this.setDeactivateStageShow(false)}
                show={deactivateStageShow}>
                {this.deactivateStage()}
            </Modal>

            <main className="main-contant">
                <div className="contents">
                    <h3 className="title-head mb-3 mb-lg-4 ">Sales Process </h3>
                    <div className="accordion" id="accordionTable">
                        {((salesProcess) && (salesProcess !== "")) ?
                            salesProcess.map((salesProcesss) => {
                                var icon = salesProcesss.stage_icon_url;
                                var profileImg = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((icon !== "") && (icon !== null)) ? icon : 'default_sales_stage.png');
                                if (salesProcesss.status === "1") {
                                    return (<div className="table-wrap p-3 p-xl-4 mb-4">
                                        <div className="table-row d-sm-flex justify-content-between align-items-center">
                                            <div className="d-sm-flex justify-content-between align-items-center">
                                                <div className="mr-3 sell-icon-wrap">
                                                    <img src={profileImg} width="55" className="iconImg" alt={salesProcesss.stage_name} />
                                                </div>
                                                <div className="pl-sm-5 ml-sm-4">
                                                    <div className="org-name f2015 w-auto f-600">
                                                        {salesProcesss.stage_name}
                                                    </div>
                                                    <p className="f2015 mt-2 mb-1">{salesProcesss.stage_description}</p>
                                                    <small className="mb-0 d-block">
                                                        Tasks marked with  <span className="color_blue f2015">*</span> are required to be completed for each stage.
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="actions">
                                                <div className="dropdown">
                                                    <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="iconmoon icon-ellipse-v"></i>
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton5">
                                                        <a className="dropdown-item" onClick={() => this.setEditStageShow(true, salesProcesss)}><i className="iconmoon icon-edit1"></i> Edit Stage</a>
                                                        <a className="dropdown-item" onClick={() => this.setDeactivateStageShow(true, salesProcesss)}>
                                                            <i
                                                                className={(salesProcesss.status === "1") ? `iconmoon icon-deactivate-division` : `iconmoon icon-check`}></i> {(salesProcesss.status === "1") ? `Deactivate` : `Activate`} Stage</a>
                                                        <a className="dropdown-item" onClick={() => this.setAddTaskShow(true, salesProcesss)}> <i className="iconmoon icon-add"></i> Add New
                                                            Task</a>
                                                    </div>
                                                </div>
                                                <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                                    data-target={`#collapse-${salesProcesss.stage_id}`} aria-expanded="false" aria-controls={`collapse-${salesProcesss.stage_id}`}>
                                                    <i className="iconmoon icon-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div id={`collapse-${salesProcesss.stage_id}`} className="collapse" data-parent="#accordionTable">
                                            <div className="team-row my-3 px-3 py-2 px-xl-4">

                                                <div className="table-container pl-2">
                                                    <div className="table-responsives">
                                                        {((salesProcesss.tasks) && (salesProcesss.tasks.length > 0)) ? (
                                                            <div >
                                                                <table className="table mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Task</th>
                                                                            <th>Start Date</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {salesProcesss.tasks.map((salesTasks) => {
                                                                            // console.log(format(new Date(salesTasks.start_date), 'yyyy/MM/dd kk:mm:ss'))
                                                                            var newdate = salesTasks.StartDateFormat; //(format(new Date(salesTasks.start_date), 'MM/dd/yyyy'));

                                                                            // var newdates = new Date(salesTasks.start_date);
                                                                            if (salesTasks.status === "1") {

                                                                                return (<tr>
                                                                                    <td className="text-with-wrap salesProcesssTable">
                                                                                        {(salesTasks.task_required === "1") ? (
                                                                                            <i className="fa fa-asterisk" aria-hidden="true"></i>
                                                                                        ) : (``)}
                                                                                        <span className="text d-inline-block">{salesTasks.task_title}</span></td>
                                                                                    <td>
                                                                                        {/* {salesTasks.status} */}
                                                                                        {/* <Moment format="MM/DD/YYYY">
                                                                                            {newdate}
                                                                                        </Moment> */}

                                                                                        {newdate}


                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="actions">
                                                                                            <div className="dropdown">
                                                                                                <button className="btn dropdown-toggle" type="button"
                                                                                                    id="dropdownMenuButton3" data-toggle="dropdown"
                                                                                                    aria-haspopup="true" aria-expanded="false">
                                                                                                    <i className="iconmoon icon-ellipse-v"></i>
                                                                                                </button>
                                                                                                <div className="dropdown-menu"
                                                                                                    aria-labelledby="dropdownMenuButton3">
                                                                                                    <a className="dropdown-item" onClick={() => this.setEditTaskShow(true, salesTasks, salesProcesss)}><i
                                                                                                        className="iconmoon icon-edit1"></i> Edit Task</a>
                                                                                                    {/* <a className="dropdown-item" onClick={() => this.setDeactivateTaskShow(true, salesTasks)}><i
                                                                                                    className="iconmoon icon-deactivate"></i> Deactivate Task</a> */}


                                                                                                    <a className="dropdown-item" onClick={() => this.setDeactivateTaskShow(true, salesTasks)}>
                                                                                                        <i
                                                                                                            className={(salesTasks.status === "1") ? `iconmoon icon-deactivate` : `iconmoon icon-check`}></i> {(salesTasks.status === "1") ? `Deactivate` : `Activate`} Task</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>)
                                                                            }
                                                                        }
                                                                        )}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        ) : (
                                                            <center>No tasks in stage.</center>
                                                        )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }
                            })
                            : null
                        }


                    </div>
                    <Link className="color_blue py-4 underline f2015" to={`./inactive-sales-stages`}> Inactive Stages and Tasks</Link>
                </div>
            </main>



        </>

        )
    }
}
