import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./modal.css";
import Modal from "react-bootstrap/Modal";


const MyModal = props => {
  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <Modal show={props.show} size={props.size}  className={props.contentClasses}
    >


      {(props.title!=="") 
      ? (
      <Modal.Header className="p-0 border-0">
        <h5 className="modal-title p-3  text-center w-100 text-white">{props.title}</h5>
        <button type="button" className="close" onClick={props.onClose} >
            <span aria-hidden="true">&times;</span>
        </button>                
      </Modal.Header>
      ) 
      : (``)}
      <Modal.Body >
        {props.children}
      </Modal.Body>
      {/* <Modal.Footer>This is the footer</Modal.Footer> */}
    </Modal>


,
    document.getElementById("root")
  );
};

export default MyModal;
