import React, { Component } from 'react'
import Modal from "./modals/modal"
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import CustomSelect from "./elements/customSelect";

import "react-datepicker/dist/react-datepicker.css";
import axios from '../apiConnection/axios';


// import img1 from "../../images/porospect-icon.png";
// import img2 from "../../images/user-img2.png";
// import img3 from "../../images/user-img3.png"
// import img4 from "../../images/user-img4.png";
// import img5 from "../../images/user-img5.png"
// import img6 from "../../images/user-img6.png";
// import profile1 from "../../images/profile1.png"
// import profile2 from "../../images/profile2.png"
// import profile3 from "../../images/profile3.png"
// import profile6 from "../../images/profile6.png"
// import profile7 from "../../images/profile7.png"
// import profile8 from "../../images/profile8.png"
// import profile9 from "../../images/profile9.png"
// import profile10 from "../../images/profile10.png"
// import profile11 from "../../images/profile11.png"
// import profile12 from "../../images/profile12.png"
// import prospect from "../../images/porospect-op-icon.svg"
// import qualify from "../../images/qualify-op-icon.svg"
// import develop from "../../images/develop-op-icon.svg"
// import validate from "../../images/validate-op-icon.svg"
// import close from "../../images/close-op-icon.svg"
// import measure from "../../images/measure-op-icon.svg"
import profile5 from "../../images/profile5.png"
import backgroundImg from "../../images/back-ground-img.png"
import Moment from 'react-moment';
import moment from 'moment';
import Utility from "../../components/utility";
const utilityObj = new Utility;

export default class opportunity extends Component {
  constructor(props) {
    super(props);
    this.state = {
          function_permissions:[],
      editOpportunityShow: false,
      editOpportunityUnlockedShow: false,
      startDate: new Date(),
      datetoday: new Date(),
      endDate: new Date(),
      defaultStageSelectText: "Select Stage",
      stageList: "",
      stagesPresent: false,
      defaultStatusSelectText: "Open",
      statusList: [
        { id: 1, name: "Open", value: 0 },
        { id: 2, name: "Closed-WON", value: 1 },
        { id: 3, name: "Closed-LOST",  value: 2 },
      ],

      opportunity_name: "",
      customer_name: "",
      sales_rep_id: "",
      start_date: "",
      start_date_coreai: "",
      expected_completion_date: "",
      expected_revenue: "",
      stage_id: "",
      status: "",
      opportunity_price: "",
      created_by: "",
      Message: "",
      MessageType: null,
      salesRepName: "",
      OpportunitiesList: [],
      profileImg : profile5,
      reps_array : [],
      user_role : '',
      ButtonHideA : false,
      ButtonHideB : false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getStages();
    var full_name = localStorage.getItem('full_name');
    const user_id = localStorage.getItem('userid');
    const user_role = localStorage.getItem('adminsrToken');
    
    this.setState({ salesRepName: full_name });
    this.setState({ user_role });
    this.setState({ user_id: user_id }, () => {
      if (user_role==='4'){
        this.getOpportunitiesBySalesRep();
      } else if (user_role==='3'){
        this.getOpportunitiesBySalesManager();
        this.getReps();
      }
    });
        this.setFunctionPermissions(user_role,6);
        this.setState({profileImg:localStorage.getItem('profilePic')});

      }
      setFunctionPermissions = async (role_id,permissions_module_id) => {

        const payLoad = {
            role_id: role_id ,
            permissions_module_id:permissions_module_id
        }
        await axios.post(`/permissions/get-functions-rights-by-role-module`, payLoad, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
        .then(res => {
             
            if (res.data.code === 200) {    
              var response_data = res.data.data;
                    var opportunity_functions = [];
                    // localStorage.setItem('function_permissions', JSON.stringify(response_data));
                    var counter = 0;
                    for(var j=0;j<response_data.length;j++){
                      if(response_data[j].access==="1"){
                      opportunity_functions[counter]=response_data[j].permission_key;
                      counter++;
                      }
                    }
                    this.setState({function_permissions:opportunity_functions});
                    // var permi = JSON.parse(localStorage.getItem('function_permissions'));
                 
            } else if (res.data.code === 201) {
               
            }
        })
        .catch((error) => {
            console.log(error)
        }) 
    }
  

  getStages = async () => {
    await axios.get(`sales-process/get-active-stages`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({ stageList: res.data.data });

          if(res.data.data.length > 0){
            this.setState({ stagesPresent: true });
          }

        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  getReps = async () => {
    const { user_id } = this.state;
    const payload = {
      sales_manager_id: user_id,
    }
    await axios.post(`opportunity/get-sales-rep-by-sales-manager`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({ salesReps: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  
  checkValidations = async (form_data) => {
    console.log("form data", form_data);
    var message = "";
    var message_type = "";
    this.setState({
      Message: "",
      MessageType: ""
    });
    if (form_data.opportunity_name == "") {
      message = "Opportunity Name is required.";
      this.setState({
        Message: message,
        MessageType: "danger"
      });
      return true;
    } else if (form_data.opportunity_name.length > 140) {
      message = "Opportunity Name cannot be longer than 140 characters.";
      this.setState({
        Message: message,
        MessageType: "danger"
      });
      return true;
    }

    if (form_data.customer_name == "") {
      message = "Customer Name is required.";
      this.setState({
        Message: message,
        MessageType: "danger"
      });
      return true;
    } else if (form_data.customer_name.length > 50) {
      message = "Customer Name cannot be longer than 50 characters.";
      this.setState({
        Message: message,
        MessageType: "danger"
      });
      return true;
    }

    if (form_data.stage_id == "") {
      message = "Please select a Stage from the list.";
      this.setState({
        Message: message,
        MessageType: "danger"
      });
      return true;
    }

    if (form_data.expected_revenue === "") {
      message = "The Expected Revenue is required for reporting.";
      this.setState({
        Message: message,
        MessageType: "danger"
      });
      return true;
    } else {
      var expected_revenue_regex = /^\d*(\.\d{0,2})?$/;
      
      if (!(expected_revenue_regex.test(form_data.expected_revenue))) {
        message = "Decimals allowed up to 2 digits.";
        this.setState({
          Message: message,
          MessageType: "danger"
        });
        return true;
      }
     
    }
    return false;

  }

  addOpportunity = async () => {
      const {startDate, endDate, opportunity_name, customer_name, stage_id, user_id, expected_revenue, function_permissions} = this.state;
    var startDatesend = new Date(startDate);
    var endDatesend = new Date(endDate);
    var todayDate = new Date();
    const payload = {
      opportunity_name,
      customer_name,
      sales_rep_id: user_id,
      start_date: moment(startDatesend).format('YYYY-MM-DD HH:mm:ss'),
      start_date_coreai: moment(todayDate).format('YYYY-MM-DD HH:mm:ss'),
      expected_completion_date: moment(endDatesend).format('YYYY-MM-DD HH:mm:ss'),
      expected_revenue: expected_revenue,
      stage_id,
      status: "0",
      opportunity_price: "0.00",
      created_by: user_id
    }
    var validation_fail = await this.checkValidations(payload);
    console.log(validation_fail);
    if (!validation_fail) {
      await axios.post(`opportunity/add-opportunity`, payload, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        }
      })
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            this.setState({
              Message: res.data.message,
              MessageType: "success",
              opportunity_name:'',
              customer_name:'',
              endDate: new Date(),
              startDate: new Date(),
              datetoday: new Date(),
            });
            setTimeout(() => { this.resetAddOpportunityForm(); }, 3000);
          } else {
            this.setState({
              Message: res.data.message,
              MessageType: "danger"
            });
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

  }
  getOpportunitiesBySalesRep = async () => {
    utilityObj.loaderView("show");

    const { user_id } = this.state;
    const payload = {
      sales_rep_id: user_id
    }
    await axios.post(`opportunity/get-stages-opportunity-by-salesrep`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({ OpportunitiesList: res.data.data });
          
        }
        utilityObj.loaderView("hide");
      })
      .catch((error) => {
        console.log(error)
      })
  }
  getOpportunitiesBySalesManager = async () => {
    utilityObj.loaderView("show");

    const { user_id, reps_array} = this.state;
    const payload = {
      sales_manager_id: user_id,
      sales_rep_arr : reps_array
    }
    await axios.post(`opportunity/get-stages-opportunity-by-salesmanager`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({ OpportunitiesList: res.data.data });
          
        }
        utilityObj.loaderView("hide");
      })
      .catch((error) => {
        console.log(error)
      })
  }
  resetAddOpportunityForm = () => {
    this.setState({
      Message: null,
      MessageType: null,
      defaultStageSelectText: "Select Stage"
    });
    this.setAddOpportunityDetailsUnlockShow(false);
    this.getOpportunitiesBySalesRep();
    this.setState({
      opportunity_name: "",
      customer_name: "",
      start_date: "",
      start_date_coreai: "",
      expected_completion_date: "",
      stage_id: "",
      status: "0",
      opportunity_price: "0.00",
      expected_revenue: ""
    })
    this.getOpportunitiesBySalesRep();

  }

  setEditOpportunityDetailsShow = (newValue, opportunityId) => {
    this.setState({ editOpportunityShow: newValue, opportunityId }, ()=>{
      if (opportunityId!=="" && opportunityId!==undefined){
        this.loadOpportunityDetails();
      }
    });
  }

  loadOpportunityDetails = async () => {
    utilityObj.loaderView("show");
    this.setState({ selectedopportunityData: "" });

    const { opportunityId} = this.state;
    const payload = {
      opportunity_id: opportunityId,
    }
    await axios.post(`opportunity/get-single-opportunity-details`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          console.log(res.data.data[0]);
          this.setState({ selectedopportunityData: res.data.data[0], selectedopportunityEditedData: res.data.data[0] }, () => {

          });
        }
        utilityObj.loaderView("hide");
      })
      .catch((error) => {
        console.log(error)
      })
  }

  editOpportunityDetails = () => {
    const {selectedopportunityData} = this.state;
    if (selectedopportunityData!==undefined && selectedopportunityData!==""){
      var start_date = new Date(selectedopportunityData.start_date);
      var start_date_ai = new Date(selectedopportunityData.start_date_ai);
      var expected_completion = new Date(selectedopportunityData.expected_completion);
      var completion_date = '-'      
      var updated_at = '-'      
      if (selectedopportunityData.completion_date!==null && selectedopportunityData.completion_date!==""){
        var completion_date = new Date(selectedopportunityData.completion_date);
      }
      if (selectedopportunityData.status==="1" || selectedopportunityData.status==="2"){
        var updated_at = new Date(selectedopportunityData.close_date);
      }
      var status = "";
      if (selectedopportunityData.status==='0'){
        status = "Open";
      }else if (selectedopportunityData.status==='1'){
        status = "Closed-WON";
      }else if (selectedopportunityData.status==='2'){
        status = "Closed LOST";
      }
    return (<>
      <form>
        <div className="form-group">
          <label className="mb-0">Opportunity Name<span className="mandatory color_red">*</span></label>
          <div className="feild-text py-3 f-600 f2015 word-break-all">
            {selectedopportunityData.opp_name}
          </div>
        </div>
        <div className="form-group">
          <label className="mb-0">Customer Account Name<span className="mandatory color_red">*</span></label>
          <div className="feild-text py-3 f-600 f2015 word-break-all">
          {selectedopportunityData.customer_name}
          </div>
        </div>
        <div className="form-group">
          <label className="mb-0">Sales Rep Name<span className="mandatory color_red">*</span></label>
          <div className="feild-text py-3 f-600 f2015 disabled__text">
          {selectedopportunityData.sales_rep_first_name +' '+selectedopportunityData.sales_rep_last_name}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Start Date of Opportunity</label>
              <div className="feild-text py-3 f-600 f2015">
              {moment(start_date).format('MM-DD-yyyy')}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Start Date in Core Ai</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
              {moment(start_date_ai).format('MM-DD-yyyy')}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Expected Completion Date</label>
              <div className="feild-text py-3 f-600 f2015">
              {moment(expected_completion).format('MM-DD-yyyy')}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Completion Date</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
              {(selectedopportunityData.completion_date!==null && selectedopportunityData.completion_date!=="") ? (
              moment(completion_date).format('MM-DD-yyyy')
              ) : (`-`)}        
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Stage<span className="mandatory color_red">*</span></label>
              <div className="feild-text py-3 f-600 f2015">
              {selectedopportunityData.stage_name}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Expected Revenue<span className="mandatory color_red">*</span></label>
              <div className="feild-text py-3 f-600 f2015">
              {/* ${selectedopportunityData.expected_revenue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} */}
              ${selectedopportunityData.expected_revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Status</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
              {status}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Opportunity Revenue</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
              {/* ${selectedopportunityData.price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} */}
              ${selectedopportunityData.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Opportunity Close Date</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
              {(selectedopportunityData.status==="1" || selectedopportunityData.status==="2") ? (
              moment(updated_at).format('MM-DD-yyyy')
              ) : (`-`)}        
              </div>
            </div>
          </div>
        </div>

        {(selectedopportunityData.status==='0') ? (
        <div className="d-block color_blue text-right f2015 pb-2">
          <small className="iconmoon icon-edit2 f2015 editicon"></small> <a
            className="color_blue underline " onClick={() => this.setEditOpportunityDetailsUnlockShow(true, selectedopportunityData.id)}> Edit
            Details</a>
        </div>
        ) : null}
      </form>
      {/* <div className="modal-footer justify-content-center">
          <button type="button" className="btn dark-btn mx-2" onClick={() => this.setEditStageShow(false)}>Save</button>
          <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setEditStageShow(false)}>Cancel</button>
        </div>  */}
    </>);
    } else {
      return(<></>);
    }
  }

  setEditOpportunityDetailsUnlockShow = (newValue, opportunityId) => {
    this.setState({ editOpportunityUnlockShow: newValue, Message:null, MessageType:null}, ()=>{
      if (newValue === true) {
        this.setEditOpportunityDetailsShow(false);
        if (opportunityId!=="" && opportunityId!==undefined){
          this.loadOpportunityDetails();
        }        
      } else {
        console.log(this.state.selectedopportunityEditedData);
        // this.setEditOpportunityDetailsShow(true, this.state.opportunityId);
      }
    });
  }
  editOpportunityUnlockDetails = () => {
    const {Message, MessageType, ButtonHideA, ButtonHideB, selectedopportunityData, selectedopportunityEditedData, stageList, statusList, user_role} = this.state;
    if (selectedopportunityEditedData!==undefined && selectedopportunityEditedData!==""){
      var start_date = new Date(selectedopportunityEditedData.start_date);
      var start_date_ai = new Date(selectedopportunityEditedData.start_date_ai);
      var expected_completion = new Date(selectedopportunityEditedData.expected_completion);
      var completion_date = '-'      
      var close_date = '-'      
      if (selectedopportunityData.completion_date!==null && selectedopportunityData.completion_date!==""){
        var completion_date = new Date(selectedopportunityData.completion_date);
      }
      if (selectedopportunityData.status==="1" || selectedopportunityData.status==="2"){
        var close_date = new Date(selectedopportunityData.close_date);
      }
      var status = "";
      var extraclass = "";
      if (selectedopportunityData.status==='0'){
        status = "Open";
        extraclass = "color_light_gray"
      }else if (selectedopportunityData.status==='1'){
        status = "Closed-WON";
        extraclass = "color_green"
      }else if (selectedopportunityData.status==='2'){
        status = "Closed LOST";
        extraclass = "color_red"
      }    
    return (<>
      <form>
      {((Message) && (Message !== null)) ?
          (
            <div className={`alert alert-${MessageType}`}>
              {Message}
            </div>
          ) : (``)
        }        
        <div className="form-group">
          <label className="mb-0">Opportunity Name<span className="mandatory color_red">*</span></label>
          <input type="text" className="form-control f-600 f2015"
            placeholder="Opportunity Name" id="edit_opportunity_name" value={selectedopportunityEditedData.opp_name} onChange={this.changeFunctionEdit} />
        </div>
        <div className="form-group">
          <label className="mb-0">Customer Account Name<span className="mandatory color_red">*</span></label>
          <input type="text" className="form-control f-600 f2015" id="edit_customer_name" value={selectedopportunityEditedData.customer_name}
            placeholder="Customer Account Name"  onChange={this.changeFunctionEdit} />
        </div>
        <div className="form-group">
          <label className="mb-0">Sales Rep Name</label>
          <div className="feild-text py-3 f-600 f2015 disabled__text">
          {selectedopportunityData.sales_rep_first_name + ' ' +selectedopportunityData.sales_rep_last_name}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Start Date of Opportunity<span className="mandatory color_red">*</span></label>
              <span className="date-icon"><i className="ri-calendar-line"></i></span>
              <DatePicker
                dateFormat="MM-dd-yyyy"
                className="form-control f-600 f2015 with-icon"
                selected={start_date}
                onChange={(date) => {
                  this.setState(prevState => ({
                    ...prevState,
                    selectedopportunityEditedData: 
                      {
                        ...prevState.selectedopportunityEditedData,
                        start_date : date,
                      }
                  }), () => {
                    console.log(moment(date).format('d MMM'))
                  })
                }
              }
                maxDate={moment().toDate()}
                minDate={moment().subtract(365, 'days').toDate()}
              />              
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Start Date in Core Ai</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
              {moment(start_date_ai).format('MM-DD-yyyy')}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Expected Completion Date</label>
              <span className="date-icon"><i className="ri-calendar-line"></i></span>
              <DatePicker
                dateFormat="MM-dd-yyyy"
                className="form-control f-600 f2015 with-icon"
                selected={new Date(selectedopportunityEditedData.expected_completion)}
                onChange={(date) => {
                  this.setState(prevState => ({
                    ...prevState,
                    selectedopportunityEditedData: 
                      {
                        ...prevState.selectedopportunityEditedData,
                        expected_completion : date,
                      }
                  }), () => {
                    console.log(moment(date).format('d MMM'))
                  }); 
                }}
                minDate={moment().toDate()}
                maxDate={moment().add(365, 'days').toDate()}
              />              
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Completion Date</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
              {(selectedopportunityData.completion_date!==null && selectedopportunityData.completion_date!=="") ? (
              moment(completion_date).format('MM-DD-yyyy')
              ) : (`-`)} 
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Stage<span className="mandatory color_red">*</span></label>
              <CustomSelect
                className="form-control f-600 text-left"
                defaultText={selectedopportunityData.stage_name}
                name="stage_id" dataUid={`stage_id`}
                optionsList={stageList}
                onSelect={this.changeSelectStage}
              />              
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Expected Revenue<span className="mandatory color_red">*</span></label>
              <span className="prifix f-600 f2015">$</span>
              <input type="text" step=".01" placeholder="0.00" className="form-control f-600 f2015 pl-3" id="edit_expected_revenue" 
              onChange={this.changeFunctionEdit} onKeyDown={this.preventNonNumericalInput} value={selectedopportunityEditedData.expected_revenue}
              />
            </div>
          </div>        
          {((user_role==4) && (selectedopportunityData.status==='0')) ? (
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Status</label>
              <CustomSelect
                className="form-control f-600 text-left"
                defaultText={status}
                name="status_id" dataUid={`status_id`}
                optionsList={statusList}
                onSelect={this.changeSelectStatus}
              />
            </div>
          </div>

          ) : (
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Status</label>
              <div className={`feild-text py-3 f-600 f2015 ${extraclass}`}>
              {status}
              </div>
            </div>
          </div>

          )}  

          {((user_role==4) && ((selectedopportunityEditedData.status!=="0") && (selectedopportunityEditedData.status!==0) ) && ((selectedopportunityData.status==="0") || (selectedopportunityData.status===0) )) ? (
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Opportunity Revenue<span className="mandatory color_red">*</span></label>
              <span className="prifix f-600 f2015">$</span>
              <input type="text" step=".01" placeholder="0.00" className="form-control f-600 f2015 pl-3" id="price" 
              onChange={this.changeFunctionEdit} onKeyDown={this.preventNonNumericalInput} value={selectedopportunityEditedData.price}
              />
            </div>
          </div>  
          ) : (
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Opportunity Revenue</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
                $ {selectedopportunityEditedData.price}
              </div>
            </div>
          </div>
          )}

          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Opportunity Close Date</label>
              <div className="feild-text py-3 f-600 f2015">

              {((selectedopportunityEditedData.status=="1") || (selectedopportunityEditedData.status=="2") || (selectedopportunityEditedData.status==1) || (selectedopportunityEditedData.status==2)) ? (
               
               ((user_role==4) && ((selectedopportunityData.status!=="1") && (selectedopportunityData.status!=="2") && (selectedopportunityData.status!==1) && (selectedopportunityData.status!==2))) ? 
               (
                <DatePicker
                  dateFormat="MM-dd-yyyy"
                  className="form-control f-600 f2015 with-icon"
                  selected={new Date(selectedopportunityEditedData.close_date)}
                  onChange={(date) => {
                    this.setState(prevState => ({
                      ...prevState,
                      selectedopportunityEditedData: 
                        {
                          ...prevState.selectedopportunityEditedData,
                          close_date : date,
                        }
                    }), () => {
                      console.log(moment(date).format('d MMM'))
                    }); 
                  }}
                  maxDate={moment().toDate()}
                  minDate={moment().subtract(365, 'days').toDate()}
                  />      ) : ( moment(close_date).format('MM-DD-yyyy'))
              ) : (`-`)}        
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="modal-footer justify-content-center">
        {((!ButtonHideA) && (!ButtonHideB)) ? (
          <>
          <button type="button" className="btn dark-btn mx-2" onClick={() => this.saveOpportunity()}>Save</button>
          <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setEditOpportunityDetailsUnlockShow(false, selectedopportunityData.id)}>Cancel</button>          
          </>
        ) : null}
      </div>
    </>);

  } else {
    return (<></>)
  }
}

saveOpportunity = async () => {
  const {selectedopportunityData, selectedopportunityEditedData, stageList, statusList, user_role, user_id} = this.state;
  var start_date_send = new Date(selectedopportunityEditedData.start_date);
  var expected_completion_send = new Date(selectedopportunityEditedData.expected_completion);
  var todayDate = new Date();

  const payload = {
    role_id : user_role,
    opportunity_id : selectedopportunityEditedData.id,
    opportunity_name : selectedopportunityEditedData.opp_name,
    customer_name : selectedopportunityEditedData.customer_name,
    start_date : moment(start_date_send).format('YYYY-MM-DD HH:mm:ss'),
    expected_completion :  moment(expected_completion_send).format('YYYY-MM-DD HH:mm:ss'),
    expected_revenue : selectedopportunityEditedData.expected_revenue,
    stage_id : selectedopportunityEditedData.stage_id,
    status : selectedopportunityEditedData.status,
    opportunity_price : selectedopportunityEditedData.price,
    opportunity_close_date : moment(selectedopportunityEditedData.close_date).format('YYYY-MM-DD HH:mm:ss'),
    user_id: user_id
  }  
  // var validation_fail = await this.checkValidations(payload);
  // console.log(validation_fail);
  // if (!validation_fail) {
    await axios.post(`opportunity/edit-opportunity`, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({
            Message: res.data.message,
            MessageType: "success",
          }, ()=> {
            setTimeout(() => { 
              this.setState({ 
                Message: null,
                MessageType: null
              })               
              this.setEditOpportunityDetailsUnlockShow(false, selectedopportunityEditedData.id)
              this.componentDidMount();
             }, 3000);

          });
        } else {
          this.setState({
            Message: res.data.message,
            MessageType: "danger"
          }, ()=>{
            setTimeout(() => {
              this.setState({ 
                Message: null,
                MessageType: null
              }) 
             }, 3000);
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  // }

}
changeSelectStage = (ele, Uid) => {
  this.setState(prevState => ({
    ...prevState,
    selectedopportunityEditedData: 
      {
        ...prevState.selectedopportunityEditedData,
        stage_id : ele,
      }
  }));    
}

changeSelectStatus = (ele, Uid) => {
  this.setState(prevState => ({
    ...prevState,
    selectedopportunityEditedData: 
      {
        ...prevState.selectedopportunityEditedData,
        status : ele,
      }
  }), ()=> {
    
        if (ele==1){
          this.setState(prevState => ({
            ...prevState,
            selectedopportunityEditedData: 
              {
                ...prevState.selectedopportunityEditedData,
                price : '0.00',
                close_date : new Date()
              }
          }));    
        } else if (ele==2){
          this.setState(prevState => ({
            ...prevState,
            selectedopportunityEditedData: 
              {
                ...prevState.selectedopportunityEditedData,
                price : '0.00',
                close_date : new Date()
              }
          }));      
        } else if (ele==0){
          this.setState(prevState => ({
            ...prevState,
            selectedopportunityEditedData: 
              {
                ...prevState.selectedopportunityEditedData,
                price : '0.00',
                close_date : this.state.selectedopportunityData.close_date
              }
          }));      
        }
  });    
}


changeFunctionEdit = (e) => {
  if(e.target.value.trim().length == 0){
    e.target.value = "";
  }
    var s = e.target.value;
    var keyid = e.target.id;

    if (keyid === "edit_opportunity_name") {
      if (e.target.value.length>140){
        this.setState({
          Message : "Opportunity name should be max 140 characters.",
          MessageType : "danger",
          ButtonHideA : true
        })
      } else {
        this.setState({
          Message : null,
          MessageType : null,
          ButtonHideA : false
        })
      }

      this.setState(prevState => ({
        ...prevState,
        selectedopportunityEditedData: 
          {
            ...prevState.selectedopportunityEditedData,
            opp_name : s,
          }
      }));      
    } else if (keyid === "edit_customer_name") {
      if (e.target.value.length>50){
        this.setState({
          Message : "Customer account name should be max 50 characters.",
          MessageType : "danger",
          ButtonHideB : true
        })
      } else {
        this.setState({
          Message : null,
          MessageType : null,
          ButtonHideB : false
        })
      }

      this.setState(prevState => ({
        ...prevState,
        selectedopportunityEditedData: 
          {
            ...prevState.selectedopportunityEditedData,
            customer_name : s,
          }
      }));      
    }  else if (keyid === "price") {
      if ((this.state.selectedopportunityEditedData.status==='1') || (this.state.selectedopportunityEditedData.status===1)){
        this.setState(prevState => ({
          ...prevState,
          selectedopportunityEditedData: 
            {
              ...prevState.selectedopportunityEditedData,
              price : s,
            }
        }));      
      } else {
        this.setState(prevState => ({
          ...prevState,
          selectedopportunityEditedData: 
            {
              ...prevState.selectedopportunityEditedData,
              price : '0.00',
            }
        }));          
      }
    } else if (keyid === "edit_expected_revenue") {
      if (!(/^\d*(\.\d{0,2})?$/).test(s)) {
        this.setState(prevState => ({
          ...prevState,
          selectedopportunityEditedData: 
            {
            ...prevState.selectedopportunityEditedData,
              expected_revenue : "",
            }
        }));      
        e.preventDefault();
      } else {
        this.setState(prevState => ({
          ...prevState,
          selectedopportunityEditedData: 
            {
            ...prevState.selectedopportunityEditedData,
              expected_revenue : s,
            }
        }));           
      }        
    }
    console.log(this.state.selectedopportunityEditedData);
}


  changeSelect = (ele, Uid) => {
    console.log(ele);
    console.log(Uid);
    this.setState({ stage_id: ele })
  }
 
  changeFunction = (e) => {
    if(e.target.value.trim().length == 0){
      e.target.value = "";
    }
      var s = e.target.value;
      var keyid = e.target.id;

      if (keyid === "opportunity_name") {
        if (e.target.value.length>140){
          this.setState({
            Message : "Opportunity name should be max 140 characters.",
            MessageType : "danger",
            ButtonHideA : true
          })
        } else {
          this.setState({
            Message : null,
            MessageType : null,
            ButtonHideA : false
          })
        }  
        this.setState({ opportunity_name: s })
      } else if (keyid === "customer_name") {
        if (e.target.value.length>50){
          this.setState({
            Message : "Customer account name should be max 50 characters.",
            MessageType : "danger",
            ButtonHideB : true
          })
        } else {
          this.setState({
            Message : null,
            MessageType : null,
            ButtonHideB : false
          })
        }
  
        this.setState({ customer_name: s })
      } else if (keyid === "expected_revenue") {
        if (!(/^\d*(\.\d{0,2})?$/).test(s)) {
          this.setState({ expected_revenue: ""})
          e.preventDefault();
        } else {
          this.setState({ expected_revenue: s })
        }        
      }
  }

  preventNonNumericalInput = (e) => {
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);

    if (charCode != 46 && charCode > 31 
      && (charCode < 48 || charCode > 57) && (charCode!==190) && (charCode!==6) && (charCode!==8))
      e.preventDefault();
    // if ((!charStr.match(/^[0-9]+$/)) && ((!charCode===6) && (!charCode===190) && (!charCode===8)))
    //   e.preventDefault();
  }

  setAddOpportunityDetailsUnlockShow = (newValue) => {
    this.setState({ addOpportunityUnlockShow: newValue, Message: "", MessageType: null, ButtonHideA: false, ButtonHideB: false, endDate: new Date(), startDate: new Date(), datetoday: new Date(), });
  }
  addOpportunityUnlockDetails = () => {
    const { stageList, ButtonHideA, ButtonHideB, datetoday, opportunity_name, customer_name, salesRepName, Message, MessageType, expected_revenue } = this.state;
    return (<>
      <form>
        {((Message) && (Message !== null)) ?
          (
            <div className={`alert alert-${MessageType}`}>
              {Message}
            </div>
          ) : (``)
        }
        <div className="form-group">
          <label className="mb-0">Opportunity Name<span className="mandatory color_red">*</span></label>
          <input type="text" className="form-control f-600 f2015" id="opportunity_name" onChange={this.changeFunction} value={opportunity_name}
            placeholder="Opportunity Name" />
        </div>
        <div className="form-group">
          <label className="mb-0">Customer Account Name<span className="mandatory color_red">*</span></label>
          <input type="text" className="form-control f-600 f2015" id="customer_name" onChange={this.changeFunction} value={customer_name}
            placeholder="Customer Account Name" />
        </div>
        <div className="form-group">
          <label className="mb-0">Sales Rep Name</label>
          <div className="feild-text py-3 f-600 f2015 disabled__text">
            {salesRepName}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Start Date of Opportunity<span className="mandatory color_red">*</span></label>
              <span className="date-icon"><i className="ri-calendar-line"></i></span>
              <DatePicker
                dateFormat="MM-dd-yyyy"
                className="form-control f-600 f2015 with-icon"
                selected={this.state.startDate}
                onChange={(date) => this.setState({ startDate: date }, () => {
                  console.log(moment(date).format('d MMM'))
                })}
                maxDate={moment().toDate()}
                minDate={moment().subtract(365, 'days').toDate()}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Start Date in Core Ai</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
                {moment(datetoday).format('MM-DD-yyyy')}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Expected Completion Date</label>
              <span className="date-icon"><i className="ri-calendar-line"></i></span>
              <DatePicker
                dateFormat="MM-dd-yyyy"
                className="form-control f-600 f2015 with-icon"
                selected={this.state.endDate}
                onChange={(date) => this.setState({ endDate: date })}
                minDate={moment().toDate()}
                maxDate={moment().add(365, 'days').toDate()}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Completion Date</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
                -
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Stage<span className="mandatory color_red">*</span></label>
              <CustomSelect
                className="form-control f-600 text-left"
                defaultText={this.state.defaultStageSelectText}
                name="stage_id" dataUid={`stage_id`}
                optionsList={stageList}
                onSelect={this.changeSelect}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Expected Revenue<span className="mandatory color_red">*</span></label>
              <span className="prifix f-600 f2015">$</span>
              <input type="text" step=".01" placeholder="0.00" className="form-control f-600 f2015 pl-3" id="expected_revenue" 
              onChange={this.changeFunction} onKeyDown={this.preventNonNumericalInput} value={expected_revenue}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Status</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
                Open
              </div>

              {/* <CustomSelect
                  className="form-control f-600 text-left disabled__text"
                  defaultText={this.state.defaultStatusSelectText}
                  optionsList={this.state.statusList}
                  /> */}
              {/* 
                <select data-style="bg-white rounded-pill form-control" className="selectpicker form-control f-600 W-100">
                  <option value="0">Open</option>
                  <option value="0" className="color_green">Close-WON</option>
                  <option value="0" className="color_red">Close-LOST</option>
                </select> */}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Opportunity Revenue</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
                $ -
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-0">Opportunity Close Date</label>
              <div className="feild-text py-3 f-600 f2015 disabled__text">
                -
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="modal-footer justify-content-center">
        {((ButtonHideA==false) && (ButtonHideB==false)) ? 
        <button type="button" className="btn dark-btn mx-2" onClick={() => this.addOpportunity()}>Save</button>        
        : null}
        <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setAddOpportunityDetailsUnlockShow(false)}>Cancel</button>
      </div>
    </>);
  }

  setFilterRep = (rep_id) => {
    if (typeof this.state.reps_array !== 'undefined') {
      var reps_array = this.state.reps_array;
    } else {
      var reps_array = [];
    }
    if (!reps_array.includes(rep_id)){
      reps_array.push(rep_id);
    } else {
      var index = reps_array.indexOf(rep_id);
      if (index !== -1) {
        reps_array.splice(index, 1);
      }      
    }
    this.setState({reps_array}, ()=>{
      console.log(this.state.reps_array);
      this.getOpportunitiesBySalesManager();
    });
  }

  render() {
        const {salesReps, user_role, profileImg, editOpportunityShow, editOpportunityUnlockShow, addOpportunityUnlockShow, OpportunitiesList, function_permissions} = this.state;
    return (
      <>
        <Modal
          title="Opportunity Details"
          contentClasses=""
          size="md"
          onClose={() => this.setEditOpportunityDetailsShow(false)}
          show={editOpportunityShow}>
          {this.editOpportunityDetails()}
        </Modal>

        <Modal
          title="Edit Opportunity Details"
          contentClasses=""
          size="md"
          onClose={() => this.setEditOpportunityDetailsUnlockShow(false)}
          show={editOpportunityUnlockShow}>
          {this.editOpportunityUnlockDetails()}
        </Modal>

        <Modal
          title="Add Opportunity"
          contentClasses=""
          size="md"
          onClose={() => this.setAddOpportunityDetailsUnlockShow(false)}
          show={addOpportunityUnlockShow}>
          {this.addOpportunityUnlockDetails()}
        </Modal>

        <main className="main-contant opportunity-management-main" style={{ backgroundImage: `url(${backgroundImg})` }}>
          <div className="contents">
            <div className="d-flex flex-wrap justify-content-between mb-4 mb-lg-5 align-items-center">
              <h3 className="title-head m-0 py-2">Opportunity Management</h3>
              { function_permissions.indexOf("assign_task") > -1 ?

              <div className="users-wrap d-flex flex-wrap align-items-center justify-content-end">
                {(salesReps && salesReps.length>0) ? (
                  salesReps.map((rep) => {
                    var profileImgRep = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((rep.sales_rep_profile_pic_url!=="") && (rep.sales_rep_profile_pic_url!==null) )? rep.sales_rep_profile_pic_url : 'default-avatar.png');
                    var repName = rep.sales_rep_first_name + ' ' +rep.sales_rep_last_name;
                    if (typeof this.state.reps_array !== 'undefined') {
                      var reps_array = this.state.reps_array;
                    } else {
                      var reps_array = [];
                    }
                    var classes = (!reps_array.includes(rep.sale_rep_id)) ? 'img-wrap' : 'img-wrap img-selected';
                    return (
                        <div className={classes} onClick={() => this.setFilterRep(rep.sale_rep_id)}>
                          <img src={profileImgRep} alt={repName} title={repName}/>
                        </div>                      
                    )
                 })
                ) : (``)}
          </div>
            :null
          }

        { function_permissions.indexOf("create_opportunity") > -1 ?
              <div className="right-content">
                <button type="button" disabled={!this.state.stagesPresent} className="btn dark-btn py-0 px-4 addBtn" onClick={() => this.setAddOpportunityDetailsUnlockShow(true)}>+ <span className="btn-text"> Add Opportunity</span></button>
              </div>
            :null
        }
            </div>
            <div className="opportunity-management-card-container d-flex">

              {((OpportunitiesList) && (OpportunitiesList.length > 0)) ? (
                OpportunitiesList.map((OpportunitiesLists) => {
                  if((OpportunitiesLists.status=="1") || ((OpportunitiesLists.opportunities) && (OpportunitiesLists.opportunities).length>0)){
                  // var stage_icon_url = OpportunitiesLists.stage_icon_url;
                  var icon = OpportunitiesLists.stage_icon_url;
                  var showicon = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((icon !== "") && (icon !== null)) ? icon : 'prospect-icon.png');

                  return (<div className="card-row" key={OpportunitiesLists.id}>
                    <div className="cord-body-wrap">
                      <div className="card-head mb-2 text-center f-600 f2015 d-flex justify-content-center align-items-center">
                        <span className="opportunity-icon mr-1">
                          <img src={showicon} alt="icon" className="mr-1" width="24" />
                        </span>
                        {OpportunitiesLists.stage_name} ({OpportunitiesLists.opportunity_count})
                      </div>
                      <div className="profile-card-container px-2 py-1">

                      {((OpportunitiesLists.opportunities) && (OpportunitiesLists.opportunities.length > 0)) ? (

                        OpportunitiesLists.opportunities.map((listsname) => {
                          var classforstatus = (listsname.status_val === "Open") ? "status-open" : ((listsname.status_val === "Closed-WON") ? "status-success" : "status-loss")
                          // return (listsname.opp_name);
                          var profileImgRep = process.env.REACT_APP_STATIC_FILES_PATH +'/'+ 'default-avatar.png';
                          var repName = ""
                          if (user_role==3){
                            repName = listsname.sales_rep.sales_rep_first_name +' '+ listsname.sales_rep.sales_rep_last_name
                            profileImgRep = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((listsname.sales_rep.sales_rep_profile_pic_url!=="") && (listsname.sales_rep.sales_rep_profile_pic_url!==null) ) ? listsname.sales_rep.sales_rep_profile_pic_url : 'default-avatar.png');
                          } else if (user_role==4){
                            profileImgRep = profileImg;
                          }
                          return (
                              <div className="profile-wrap  mb-3" key={listsname.id}>
                                <div className="d-flex p-3 pb-4 justify-content-between w-100 align-items-center">
                                  <div className="profile-name f2015 no-white-space pr-3">
                                    {listsname.opp_name}
                                  </div>
                                  <div className="actions">
                                    <div className="dropdown">
                                      <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton2"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="ri-more-fill f-600"></i>
                                      </button>
                                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <a onClick={() => this.setEditOpportunityDetailsShow(true, listsname.opportunity_id)} className="dropdown-item"><i
                                          className="iconmoon icon-viewprofile"></i> View Details</a>
                                        <Link to={`./task-details/${listsname.opportunity_id}`} className="dropdown-item" >
                                          <i className="ri-list-check-2 "></i> View Tasks</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex px-3 profile-status-wrap py-2 justify-content-between w-100 align-items-end">
                                  <div className={`profile-status  ${classforstatus}`}>
                                    Status : {listsname.status_val}
                                  </div>
                                  <div className="img-wrap m-0">
                                    <img src={`${profileImgRep}`} alt={repName} title={repName}/>
                                  </div>
                                </div>
                              </div>

                          );
                        })
                      ) : (
                        //No Opportunity
                        <div>
                          <div className="profile-card-container-empty text-center py-5">
                            No Opportunities
                          </div>
                        </div>
                      )}
                      </div>
                    </div>
                  </div>)


                  }

                })


              ) : null}

            </div>
          </div>
        </main>
      </>
    )
  }
}
