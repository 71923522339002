import React, { Component } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import {
  Link
} from "react-router-dom";

export default class SuccessOrganizationStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getOrganizationData: '',
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { getOrganizationData } = this.state;

    return (<>

      <main className="main-contant">
        <div className="contents">
          <h3 className="title-head mt-0 mb-4 mb-lg-5">Organization Setup</h3>
          <div className="upload-files mx-auto f2015">
            <div className="text-center">
              <h5 className="top-fix-head">To create the Organization Setup: </h5>
              <div className="text-area text-left py-4 mx-auto f2015">
                1. Download CSV template. <br />
                2. Populate the template with data. <br />
                3. Drag and drop the CSV file.<br />
                4. Click Upload.
              </div>
            </div>
            <div className="upload-successfully mt-2">
              <div className="drop-zone-wrap p-2">
                <div className="drop-zone f2015 f-500" style={{ border: "2px dashed #39B54A" }}>
                  <span className="drop-zone__prompt mt-3">
                    <i className="iconmoon icon-check check-successfully"></i>
                    <div className="color_blue mt-3">The Organization Setup was uploaded successfully.</div>
                    <div className="color_blue mt-3 underline" download>Click here to download CSV {getOrganizationData} </div>
                  </span>
                </div>
              </div>
              <div className="text-center py-4">
                <Link to={`./organization-structure`}><button type="btn" className="btn dark-btn">View Organization Setup</button></Link>
              </div>
            </div>

          </div>
        </div>
      </main>
    </>
    )
  }
}
