import React, { Component } from 'react'
import logo from "../images/logo.png";
import axios from './apiConnection/axios';
import { Link } from "react-router-dom";

export default class Login extends Component {
    constructor(props) {
        console.log(localStorage.getItem('adminsaToken'));
        super(props);
        this.state = {
            username: '',
            password: '',
            usernameError: '',
            passwordError: '',
            Message: '',
            MessageType: '',
            is_loggedin: 0,
            role_id: 1
        }
    }

    userLogin = async () => {
        const logininfo = {
            username: this.state.username,
            password: this.state.password,
        }
        console.log(logininfo);

        await axios.post(`/users/login`, logininfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log("TEST DEBUG", res);
                console.log(res);
                if (res.data.code === 200) {
                    if (parseInt(res.data.data.twofa_enable) === 1) {
                        localStorage.setItem('usernamefor2fa', res.data.data.username)
                        localStorage.setItem('sentotpToken', 1)
                        if (parseInt(res.data.data.twofa_method) === 1) {
                            localStorage.setItem('showauth', res.data.data.email);
                            localStorage.setItem('emailormobile', res.data.data.email)
                            localStorage.setItem('twofa_method', res.data.data.twofa_method)
                        } else {
                            localStorage.setItem('showauth', res.data.data.mobile);
                            localStorage.setItem('emailormobile', res.data.data.email)
                            localStorage.setItem('twofa_method', res.data.data.twofa_method)
                        }
                        window.location.href = "/auth";
                    } else {

                        if (res.data.data.role_id === 1) {
                            localStorage.setItem('adminsaToken', res.data.data.role_id)
                            localStorage.setItem('adminsaUserId', res.data.data.id)
                            localStorage.setItem('full_name', res.data.data.first_name + ' ' + res.data.data.last_name);

                            localStorage.setItem('userid', res.data.data.id)
                            this.setState({
                                is_loggedin: 1,
                                role_id: 1,
                                Message: 'Logged in successfully.',
                                MessageType: 'success'
                            });
                            window.location.href = "/system-admin/dashboard";
                        }
                        if ((res.data.data.role_id === 4) || (res.data.data.role_id === 3) || (res.data.data.role_id === 2)) {
                            localStorage.setItem('adminsrToken', res.data.data.role_id)
                            localStorage.setItem('adminsrUserId', res.data.data.id)

                            localStorage.setItem('userid', res.data.data.id)
                            localStorage.setItem('full_name', res.data.data.first_name + ' ' + res.data.data.last_name);
                            var user_role_id = res.data.data.role_id;
                            this.setState({
                                is_loggedin: 1,
                                role_id: user_role_id,
                                Message: 'Logged in successfully.',
                                MessageType: 'success'
                            });
                            setTimeout(function () {
                                window.location.href = "/app/dashboard";
                            }, 1000);

                        }
                        var profileImg = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((res.data.data.profile_pic_url !== "") && (res.data.data.profile_pic_url !== null)) ? res.data.data.profile_pic_url : 'default-avatar.png');

                        localStorage.setItem('profilePic', profileImg);

                    }
                    // this.props.history.push("/system-admin/initiate-organization-structure");
                } else if (res.data.code === 201) {
                    this.setState({
                        Message: res.data.message,
                        MessageType: 'danger'
                    });
                }
                // }
            })
            .catch((error) => {
                console.log(error)
            })

        if (this.state.is_loggedin === 1) {
            await this.setPermissions(this.state.role_id);
        }
    }
    setPermissions = async (role_id) => {
        const payLoad = {
            role_id: role_id
        }
        await axios.post(`/permissions/get-modules-by-role-id`, payLoad, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                if (res.data.code === 200) {
                    localStorage.setItem('permissions', JSON.stringify(res.data.data));
                } else if (res.data.code === 201) {

                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    changeFunction = (e) => {
        if (e.target.id === 'username') {
            this.setState({ username: e.target.value });
        } else if (e.target.id === 'password') {
            this.setState({ password: e.target.value });
        }
        this.setState({
            Message: null,
            MessageType: null
        });
    }

    render() {
        const { Message, MessageType } = this.state;
        return (
            <>
                <div className="body-content">
                    <div className="general-user-wrap d-flex py-3 justify-content-between  align-items-center">
                        <div className="container">
                            <div className="row justify-content-between  align-items-center">
                                <div className="col-lg-6 col-md-5 px-lg-5">
                                    <div className="welcome-text text-center">
                                        <img src={logo} alt="logo" width="250" />
                                        <h3 className="mb-4 mt-5">
                                            Welcome to Core Ai
                                        </h3>
                                        <p>
                                            We provide insights on your sales opportunities so you are focused on the right tasks, at the right time, to win.
                                        </p>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-7">
                                    <div className="form-wrap d-flex align-items-center justify-content-center">
                                        <div className="w-100">
                                            <h3 className="text-center mt-0 mb-3">
                                                Login
                                            </h3>
                                            <p className="text-center mb-lg-5 mb-4">
                                                Enter your Username and Password to Login
                                            </p>
                                            <form>

                                                {((Message) && (Message !== null)) ?
                                                    (
                                                        <div className={`alert alert-${MessageType}`}>
                                                            {Message}
                                                        </div>
                                                    ) : (``)
                                                }

                                                <div className="form-group mb-lg-2">
                                                    <label className="mb-0">Username</label>
                                                    <input type="text" id='username' className="form-control f-600 f2015" onChange={this.changeFunction} autocomplete={`no`} />
                                                    <small className="color_red">{this.setState.usernameError}</small>
                                                </div>
                                                <div className="form-group mb-lg-5">
                                                    <label className="mb-0">Password</label>
                                                    <input type="password" id='password' className="form-control f-600 f2015" onChange={this.changeFunction} />
                                                    <small className="color_red">{this.setState.passwordError}</small>
                                                </div>
                                                <div className="clearfix form-group mb-lg-5">
                                                    <div className="float-left">
                                                        <label className="customCheck text-check">
                                                            Remember Me
                                                            <input type="checkbox" />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <div className="float-right">
                                                        <Link to={`./resetpassword`} className="color_blue underline px-0 py-2">Forgot Password?</Link>
                                                    </div>
                                                </div>
                                                <button type="button" onClick={() => this.userLogin()} className="btn py-lg-2 dark-btn w-100">Login</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}