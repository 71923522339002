import React, { Component } from 'react';
import axios from './apiConnection/axios';
import Utility from "./utility";
const utilityObj = new Utility;

export default class ZoomAuth extends React.Component {
    componentDidMount() {
        utilityObj.loaderView("show");
        const user_id = localStorage.getItem('userid');
        this.setState({ user_id }, ()=>{
            this.getToken();
        });   
    }

    getToken = async () =>{
        const {user_id} = this.state;
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());   
        console.log(params);     
        const payload = {
            "grant_id" : params.grant_id,
            "conference_provider" : params.provider,
            "success" : params.success,
            "user_id" : user_id
        }    
            await axios.post(`/nylas/store-grant`, payload, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                }
            })
            .then(res => {
                
                    window.location.href = process.env.REACT_APP_PROFILE_PAGE_URL;
            })
            .catch((error) => {
                console.log(error)
                window.location.href = process.env.REACT_APP_PROFILE_PAGE_URL;
            })

    }

    
    render() {

        return (<>Please wait while redirecting back to CoreAi.</>);
    }
}

