import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import moment from 'moment'
import axios from '../apiConnection/axios';

//import 'moment/locale/zh-cn';
// import 'antd/lib/style/index.less';     //Add this code for locally example
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler'
import withDragDropContext from './commons/withDnDContext'
import Modal from "../system_admin/modals/modal";

import img5 from "../../images/user-img5.png";
import img6 from "../../images/user-img6.png";
import $, { event } from 'jquery';
import {
  Link
} from "react-router-dom";
import './../../scss/css/style.css';
import './../../scss/css/antd-globals-hiding-hack.css';
import CustomSelect from "../system_admin/elements/customSelect";
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import 'react-big-scheduler/lib/css/style.css'
import Utility from "../utility";
const utilityObj = new Utility;

class SchedulerCommon extends Component {

  constructor(props) {
    
    super(props);

    var today = new moment().format(DATE_FORMAT);
    
    // var dd = String(today.getDate()).padStart(2, '0');
    // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = today.getFullYear();

    // today = yyyy + '-' + mm + '-' + dd +' ';

    var view_day;
    var schedulerData;
    const urlSearchParameters = new URLSearchParams(window.location.search);
    const parameters = Object.fromEntries(urlSearchParameters.entries()); 
    
    var week_cell_width = "12 %";
    var day_cell_width = "8 %";
    var minute_step = 60;
    if(window.innerWidth<820){
       week_cell_width = "30 %";
      day_cell_width = "30 %";
    }
    if(parameters.view=="week"){
    
      view_day = parameters.date;
       schedulerData = new SchedulerData(view_day, ViewTypes.Week, false, false, {
        // displayWeekend: false,
        weekCellWidth: week_cell_width,
        dayCellWidth: day_cell_width,
        resourceName: "Resources",
        nonAgendaSlotMinHeight: 44,
        minuteStep: minute_step,
              eventItemPopoverEnabled:true,
      });
    }else if(parameters.date && parameters.date!=""){
     
      view_day = parameters.date;
       schedulerData = new SchedulerData(view_day, ViewTypes.Day, false, false, {
        // displayWeekend: false,
        weekCellWidth: week_cell_width,
        dayCellWidth: day_cell_width,
        resourceName: "Resources",
        nonAgendaSlotMinHeight: 44,
        minuteStep: minute_step,
              eventItemPopoverEnabled:true,
      });
    }else{
       schedulerData = new SchedulerData(today, ViewTypes.Day, false, false, {
        // displayWeekend: false,
        weekCellWidth: week_cell_width,
        dayCellWidth: day_cell_width,
        resourceName: "Resources",
        nonAgendaSlotMinHeight: 44,
        minuteStep: minute_step,
              eventItemPopoverEnabled:true,
      });
    }
    var notification_session_event_id = "";
    if(parameters.session_event_id){
      notification_session_event_id = parameters.session_event_id;
    }
 
    var user_role_id = localStorage.getItem('adminsrToken');
 
    var resources = [];
    var scheduler_wraper = "";
    if (user_role_id == 2) {
     
      scheduler_wraper = "company-admin-scheduler";
     
      resources = [];
    
    } else {
    
      scheduler_wraper = "sales-manager-scheduler";
      resources = [];
    }

    this.state = {user_role_id:user_role_id,
      startDate: new Date(),
      scheduler_wraper: scheduler_wraper,
      schedule_time: "10:00",
      viewModel: schedulerData,
      eventDetailShow: false,
      createSessionShow: false,
      rescheduleSessionShow: false,
      defaulttaskSelectText: "Select Task",
      taskList: [
        { id: 1, name: "Confirmed customer's buying process1" },
        { id: 2, name: "Confirmed customer's buying process2" },
        { id: 3, name: "Confirmed customer's buying process3" },
        { id: 4, name: "Confirmed customer's buying process4" },
      ],
      defaultopprtunitySelectText: "Select Opportunity",
      opportunityList: [
        { id: 1, name: "Opportunity 51" },
        { id: 2, name: "Opportunity 52" },
        { id: 3, name: "Opportunity 53" },
        { id: 4, name: "Opportunity 54" },
      ],
      resources: resources,
      events: [],
      eventInfo : {
        "opportunity_id" : "",
        "title" : "",
        "task_id" : "",
        "stage_id" : 1,
        "start_time" : "",
        "end_time" : "",
        "description" : "",
        "sales_manager_id" : "",
        "sales_rep_id" : "",
        "created_by" : ""
      },
      startTime : moment().format('HH:mm'),
      endTime : moment().format('HH:mm') ,
      nylasData : "start",
      sales_rep_id : "",
      stage_name : '',
      stage_icon :"",
      buttonHideA : false,
      SetTask : [],
      SetOppoDetails : [],
      params : {},
      rescheduled_start_time:"",
      rescheduled_end_time:"",
      notification_session_event_id:notification_session_event_id,
      is_org_head:0,
      sales_manager_ids:[],
      resource_name:"",
      
    }
   
    schedulerData.localeMoment.locale('en');
    // schedulerData.setEvents(this.state.events);
  }



  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());   
    if (params.preset==='true'){
      var SetTask = JSON.parse(localStorage.getItem('Task'));
      var SetOppoDetails = JSON.parse(localStorage.getItem('OppoDetails'));
        this.setState({
          SetTask,
          SetOppoDetails,
          params
        }, ()=>{
          
          this.loadDefaultPresetPopup();
        });
    } 

   


    this.setState({eventInfo : {
      "opportunity_id" : "",
      "title" : "",
      "task_id" : "",
      "stage_id" : "",
      "start_time" : "",
      "end_time" : "",
      "description" : "",
      "sales_manager_id" : "",
      "sales_rep_id" : "",
      "created_by" : ""
    }});



    const user_id = localStorage.getItem('userid');
    const user_role = localStorage.getItem('adminsrToken');
    
    this.getOrganizationResources(); 
    this.setState({ user_role, user_id }, () => {
      this.setState(prevState => ({
        ...prevState,
        eventInfo: 
        {
          ...prevState.eventInfo,
          sales_manager_id : this.state.user_id,
          created_by:this.state.user_id
        }
      }));
      // this.getSessionEvents();
      this.getNylasToken();
      this.getProfile();
    });

    if(this.state.notification_session_event_id!=""){
     this.showNotificationEventModal(this.state.notification_session_event_id);
    }

  }

  showNotificationEventModal  = async(session_event_id)=>{
    utilityObj.loaderView("show");
    var payload = {session_event_id:session_event_id}
    await axios.post(`get-session-event-detail`,payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
        .then(res => {
            if(res.data.code===200){
              var event_data = res.data.data[0];
            
              this.setState({session_event_detail:event_data},()=>{
                if(event_data.status=="RESCHEDULED"){
                  this.setRescheduleSessionShow(true);
                }else{
                  this.setEventDetailShow(true);
                }
              });
             
            
               utilityObj.loaderView("hide");
            }else{
               utilityObj.loaderView("hide");
            }
          }
       
        )
      .catch((error) => { 
        console.log(error)
    });
  }

  editSessionShow = async () =>{
    console.log("HELLO DEBUG");
    this.setEventDetailShow(false)
    this.setRescheduleSessionShow(true); 
  }

  rescheduleEvent = async (reschedule_session_event_id) =>{
    const {user_id,  rescheduled_start_time, rescheduled_end_time, rescheduled_description} = this.state;
    const currenttime = moment(new Date()).format("X");
    const starttime = moment(rescheduled_start_time).format("X");
    if (starttime<=currenttime){
      this.setState({
        Message : "Event time should be a future time.",
        MessageType : "danger",
        buttonHideA : true,
      });
      return false;
    }
    var payload = {
      "sales_manager_id" : user_id,
      "session_event_id" : reschedule_session_event_id,
    }
    if (rescheduled_description && rescheduled_description.length>500){
      this.setState({
        Message : "Event description should be max 500 characters.",
        MessageType : "danger",
        buttonHideA : true,
      });
      return false;
    } else {
      this.setState({
        Message : null,
        MessageType : null,
        buttonHideA : false,
      })
    }
    if(rescheduled_description!=""){
      payload.description = rescheduled_description;
    }
    var restartDate;
    var reendDate;
    if(rescheduled_start_time!="" ){
      payload.start_time = rescheduled_start_time;
      restartDate = moment(rescheduled_start_time).format("X");
      payload.start_timestamp = restartDate;
     
    }
    if( rescheduled_end_time!=""){
      payload.end_time = rescheduled_end_time;
      reendDate = moment(rescheduled_end_time).format("X");
      payload.end_timestamp = reendDate;
    }
 
    var diff = (restartDate - reendDate);
   
    if (diff>0){
      this.setState({
        Message : 'Start time should be less than end time.',
        MessageType : 'danger', 
      })
      setTimeout(() => { 
        this.setState({ 
          Message : '',
          MessageType : ''
          })               
       }, 3000); 

      return false;
    }
    utilityObj.loaderView("show");
    await axios.post(`calendar/update-session-event`,payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
        .then(res => {
         
            if(res.data.code===200){
              this.setRescheduleSessionShow(false);
              this.setState({responseData : res.data.data,
                Message : 'Session rescheduled successfully.',
                MessageType : 'success', 
                stage_name : '',
                stage_icon :"",            
              });
              setTimeout(() => { 
                this.setState({ 
                  Message : '',
                  MessageType : '',
                  });
                  this.setRescheduleSessionShow(false); 
                  
                                   

               }, 3000); 
              //  this.setEventDetailShow(false);
              //  this.componentDidMount();
               this.getOrganizationResources(); 
               utilityObj.loaderView("hide");
            }else{
              this.setState({responseData : res.data.data,
                Message : 'Error in reschedule session.',
                MessageType : 'danger', 
              });
              setTimeout(() => { 
                this.setState({ 
                  Message : '',
                  MessageType : ''
                  })               
               }, 3000);           
               utilityObj.loaderView("hide");
            }
          }
       
        )
      .catch((error) => { 
        console.log(error)
    });

  }



  getOrganizationResources = async () => {
  
    await axios.get(`organizations/get-organization-structure`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
        .then(res => {
       
            if(res.data.code==200){
              
              var user_id = localStorage.getItem('userid');
              var org_data = res.data.data;
              var org_resources = [];
              var sales_manager_ids = [];
                  if(org_data.length>0){
                    var is_org_head = 0;
                    if(org_data[0].user && org_data[0].user.length>0){
                      if(org_data[0].user[0].id == user_id){
                        is_org_head = 1;
                        this.setState({is_org_head:1});

                      }
                    }

                    var division_data = org_data[0].child;

                  
                    if(this.state.user_role_id==3){
                      if(division_data && division_data.length>0){
                        for(var j=0;j<division_data.length;j++){
                        var sales_manager_data = division_data[j].child;
                        
                       
                        for(var k=0;k<sales_manager_data.length;k++){
                          if(sales_manager_data[k].user.length>0){              
                          var sales_manager_detail = sales_manager_data[k].user[0];
                        
                         
                              if(sales_manager_detail.id==user_id){
                                var node_name = sales_manager_data[k].node_name;
                                this.setState({resource_name:node_name});
                                var sales_rep_data = sales_manager_data[k].users;
                               
                                if(sales_rep_data && sales_rep_data.length>0){
                                  for(var m=0;m<sales_rep_data.length;m++){
                                    var sales_rep_details = sales_rep_data[m];                             
                                   
                                    var resource_obj = {};
                                    resource_obj.id= sales_rep_details.id;
                                    var salesrep_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((sales_rep_details.profile_pic_url!=="") && (sales_rep_details.profile_pic_url!==null) )? sales_rep_details.profile_pic_url : 'default-avatar.png');                                 
                                    resource_obj.name= sales_rep_details.first_name+" "+sales_rep_details.last_name+":@:"+salesrep_profile_pic;            
                                    org_resources.push(resource_obj);
                                  }
                                }
                                break;
                              }
                          }

                        }
                        }
                      }
                  
                     
                      
                      }else if(this.state.user_role_id==2){
                        
                        if(is_org_head==0){
                        if(division_data && division_data.length>0){
                          for(var j=0;j<division_data.length;j++){
                            var division_detail = division_data[j];
                            
                            //DIVISION MANAGER IN ORGANISATION
                            if(division_detail.user && division_detail.user.length>0){
                              if(user_id==division_detail.user[0].id){
                                // var resource_obj = {};
                                var division_manager_id =  division_detail.user[0].id;
                                // resource_obj.id = division_manager_id;
                                // var div_manager_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((division_detail.user[0].profile_pic_url!=="") && (division_detail.user[0].profile_pic_url!==null) )? division_detail.user[0].profile_pic_url : 'default-avatar.png');                                 
                                // resource_obj.name= division_detail.user[0].first_name+" "+division_detail.user[0].last_name+":@:"+div_manager_profile_pic;            
                                // org_resources.push(resource_obj);
                                this.setState({resource_name:division_detail.node_name});

                                  //SALES MANAGERS IN DIVISION
                                var teams_in_division = division_detail.child;
                                if(teams_in_division && teams_in_division.length>0){
                                  for(var s_m= 0; s_m<teams_in_division.length;s_m++){
                                    var team_detail = teams_in_division[s_m];
                                    
                                   
                                    if(team_detail.user && team_detail.user.length>0){
                                        var resource_obj = {};
                                        var sales_manager_id =  team_detail.user[0].id;
                                       
                                        sales_manager_ids.push(sales_manager_id);
                                        resource_obj.id = sales_manager_id;
                                        var sales_manager_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((team_detail.user[0].profile_pic_url!=="") && (team_detail.user[0].profile_pic_url!==null) )? team_detail.user[0].profile_pic_url : 'default-avatar.png');                                 
                                        resource_obj.name= team_detail.user[0].first_name+" "+team_detail.user[0].last_name+":@:"+sales_manager_profile_pic;            
                                        org_resources.push(resource_obj);

                                        var sales_reps_data = team_detail.users;
                                        if(sales_reps_data && sales_reps_data.length>0){
                                          for(var s_r=0;s_r<sales_reps_data.length;s_r++){
                                            var sales_rep_detail = sales_reps_data[s_r];
                                           
                                            var resource_obj = {};
                                            var sales_rep_id =  sales_rep_detail.id;
                                            resource_obj.id = sales_rep_id;
                                            var sales_rep_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((sales_rep_detail.profile_pic_url!=="") && (sales_rep_detail.profile_pic_url!==null) )? sales_rep_detail.profile_pic_url : 'default-avatar.png');                                 
                                            resource_obj.name= sales_rep_detail.first_name+" "+sales_rep_detail.last_name+":@:"+sales_rep_profile_pic;            
                                            resource_obj.parentId = sales_manager_id;
                                            org_resources.push(resource_obj);
                                          }
                                        }

                                    
                                    }
                                  }

                                }
                              }
                            }
                          

                          }}                      
                        }else if(is_org_head==1){
                         
                          if(division_data && division_data.length>0){
                            for(var j=0;j<division_data.length;j++){
                              var division_detail = division_data[j];
                              
                              //DIVISION MANAGER IN ORGANISATION
                              if(division_detail.user && division_detail.user.length>0){
                               
                                  var resource_obj = {};
                                  var division_manager_id =  division_detail.user[0].id;
                                  resource_obj.id = division_manager_id;
                                  var div_manager_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((division_detail.user[0].profile_pic_url!=="") && (division_detail.user[0].profile_pic_url!==null) )? division_detail.user[0].profile_pic_url : 'default-avatar.png');                                 
                                  resource_obj.name= division_detail.user[0].first_name+" "+division_detail.user[0].last_name+":@:"+div_manager_profile_pic;            
                                  org_resources.push(resource_obj);
  
                                    //SALES MANAGERS IN DIVISION
                                  var teams_in_division = division_detail.child;
                                  if(teams_in_division && teams_in_division.length>0){
                                    for(var s_m= 0; s_m<teams_in_division.length;s_m++){
                                      var team_detail = teams_in_division[s_m];
                                      
                                     
                                      if(team_detail.user && team_detail.user.length>0){
                                          var resource_obj = {};
                                          var sales_manager_id =  team_detail.user[0].id;
                                          
                                          sales_manager_ids.push(sales_manager_id);
                                          resource_obj.id = sales_manager_id;
                                          var sales_manager_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((team_detail.user[0].profile_pic_url!=="") && (team_detail.user[0].profile_pic_url!==null) )? team_detail.user[0].profile_pic_url : 'default-avatar.png');                                 
                                          resource_obj.name= team_detail.user[0].first_name+" "+team_detail.user[0].last_name+":@:"+sales_manager_profile_pic;            
                                          resource_obj.parentId = division_manager_id;
                                          org_resources.push(resource_obj);
  
                                          var sales_reps_data = team_detail.users;
                                          if(sales_reps_data && sales_reps_data.length>0){
                                            for(var s_r=0;s_r<sales_reps_data.length;s_r++){
                                              var sales_rep_detail = sales_reps_data[s_r];
                                           
                                              var resource_obj = {};
                                              var sales_rep_id =  sales_rep_detail.id;
                                              resource_obj.id = sales_rep_id;
                                              var sales_rep_profile_pic = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((sales_rep_detail.profile_pic_url!=="") && (sales_rep_detail.profile_pic_url!==null) )? sales_rep_detail.profile_pic_url : 'default-avatar.png');                                 
                                              resource_obj.name= sales_rep_detail.first_name+" "+sales_rep_detail.last_name+":@:"+sales_rep_profile_pic;            
                                              resource_obj.parentId = sales_manager_id;
                                              org_resources.push(resource_obj);
                                            }
                                          }
  
                                      
                                      }
                                    }
  
                                  }
                                
                              }
                            
  
                            }}
                        } 
                        
                        this.setState({sales_manager_ids:sales_manager_ids});
                        
                      }
                      var viewModel = this.state.viewModel;

                      viewModel.setResources(org_resources);
                      this.setState({viewModel:viewModel});

                      
                        this.setState({resources:org_resources},()=>{this.getSessionEvents();});

                  }
            }
          }
       
        )
      .catch((error) => { 
        console.log(error)
    });
    
  }
  getOrganizationData = async () => {
    await axios.get(`organizations/get-organization`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
       
        if (res.data.code === 200) {
          this.setState({ resource_name: res.data.data[0].org_name })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  getSessionEvents = async () => {
    var sales_manager_id = localStorage.getItem('userid');
    if(this.state.user_role_id==3 || this.state.user_role_id==2){
      if(this.state.user_role_id==2 && this.state.is_org_head==1){
        this.getOrganizationData();
      }
     
    var payload, post_url;
    if(this.state.user_role_id==3){
      post_url = "scheduler/get-session-events";
      payload = {
        sales_manager_id: sales_manager_id,
      }} 
      
      if(this.state.user_role_id==2){
        post_url = "/scheduler/companyadmin/get-session-events"
        payload = {
          sales_manager_id: this.state.sales_manager_ids,
        }}

       
    await axios.post(post_url, payload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
       
        if (res.data.code === 200) {
         
          var events_list = res.data.data;
          var viewModel = this.state.viewModel;

          viewModel.setEvents(events_list);
          this.setState({viewModel:viewModel});
          this.setState({events:events_list});
        }
        
      })
      .catch((error) => {
        console.log(error)
      })
    }
  }

  
  getNylasToken = async () => {
    const {user_id} = this.state;
    const profileinfo = {
        user_id,
    }        

    await axios.post(`/nylas/get-token`, profileinfo, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
        .then(res => {
            if (res.data.code === 200) {
              this.setState(prevState => ({
                ...prevState,
                nylasData : res.data.data
              }))
            } else {
                this.setState({nylasData : []});
            }
        })
        .catch((error) => {
            console.log(error)
        })

}

getProfile = async () => {
  const {user_id} = this.state;
      const profileinfo = {
        user_id,
      }        
      await axios.post(`/users/get-profile`, profileinfo, {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
          }
      })
          .then(res => {
              if (res.data.code === 200) {
                  this.setState(prevState=>({...prevState,
                      user_id: res.data.data.id,
                      first_name: res.data.data.first_name,
                      last_name: res.data.data.last_name,
                      profile_pic_url:res.data.data.profile_pic_url,
                      profileImg: process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((res.data.data.profile_pic_url!=="") && (res.data.data.profile_pic_url!==null) )? res.data.data.profile_pic_url : 'default-avatar.png'),
                  }));
                  var profileImg = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((res.data.data.profile_pic_url!=="") && (res.data.data.profile_pic_url!==null) )? res.data.data.profile_pic_url : 'default-avatar.png');

                  localStorage.setItem('profilePic',profileImg);
              } 
          })
          .catch((error) => {
              console.log(error)
          })

}

  getOpportunitiesBySalesRep = async () =>{
    const {user_id, sales_rep_id, SetOppoDetails, SetTask, params} = this.state;
    // reports/sales-rep/get-opportunities-by-status
    // reports/sales-manager/get-opportunities-by-status
    const userInfo = {
    "sales_rep_id" : sales_rep_id,
    "opportuinity_status" : "0",
    }        
    await axios.post(`/reports/sales-rep/get-opportunities-by-status`, userInfo, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
        .then(res => {
            if (res.data.code === 200) {
              var opportunityList = [];
              var stageList = [];
              // var rep_name = "";
              // var rep_pic = "";
              var stage_name ="";
              var stage_icon ="";
              console.log('Stage Lists : ', res.data.data);
  
              for ( const [key,value] of Object.entries(res.data.data)) {
                var oppArray = {};
                var stageArray = {};
                oppArray['id'] = key;
                oppArray['name'] = res.data.data[key].opp_name;
                oppArray['value'] = res.data.data[key].opportunity_id;
                opportunityList[res.data.data[key].opportunity_id] = oppArray;
                stageArray['stage_id'] = res.data.data[key].stage_obj.id;
                stageArray['stage_name'] = res.data.data[key].stage_obj.stage_name;
                stageArray['stage_icon'] = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((res.data.data[key].stage_obj.stage_icon_url!=="") && (res.data.data[key].stage_obj.stage_icon_url!==null) )? res.data.data[key].stage_obj.stage_icon_url : 'prospect-icon.png');
                stageList[res.data.data[key].opportunity_id] = stageArray;
                  // rep_name = res.data.data[key].sales_rep.sales_rep_first_name+' '+res.data.data[key].sales_rep.sales_rep_last_name;
                  // rep_pic = res.data.data[key].sales_rep.sales_rep_profile_pic_url;

                // stage_obj
                // sales_rep
              }

              // var profileImg = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((rep_pic!=="") && (rep_pic!==null) )? rep_pic : 'default-avatar.png');
              var stageIcon = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((stage_icon!=="") && (stage_icon!==null) )? stage_icon : 'default-avatar.png');
              this.setState(prevState => ({
                ...prevState,
                opportunityList,       
                defaultopprtunitySelectText: "Select Opportunity",
                defaulttaskSelectText: "Select Task",
                taskList : [],
                stageList
              }), ()=>{
                if (params.preset=='true') {
                  this.setState(
                    prevState => ({
                      ...prevState,
                    defaultopprtunitySelectText: SetOppoDetails.opp_name,   
                    defaulttaskSelectText: SetTask.task_title,               
                    eventInfo: 
                      {
                        ...prevState.eventInfo,
                        opportunity_id : SetOppoDetails.id,
                        title : 'Coaching for '+SetOppoDetails.opp_name,
                      }
                  }), ()=>{
                    this.getTasksByOpportunity();
                  }); 
                }

              });
                console.log(res);
            } 
        })
        .catch((error) => {
            console.log(error)
        })
  }

  getTasksByOpportunity = async () =>{
    // /opportunity/task-by-task-status
    const {eventInfo, stageList, SetTask, SetOppoDetails, params} = this.state;
    const OppInfo = {
      "opportunity_id": eventInfo.opportunity_id,
      "task_status": "0"
    }        
    await axios.post(`/opportunity/task-by-task-status`, OppInfo, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
        .then(res => {
          this.setState(prevState => ({
            ...prevState,       
            defaulttaskSelectText: "Select Task",
          }), ()=>{
            if (res.data.code === 200) {
              var taskList = [];
              var taskArrayonlyid = [];
              for ( const [key,value] of Object.entries(res.data.data)) {
                var taskArray = {};
                taskArray['id'] = key;
                taskArray['name'] = res.data.data[key].task_title ;
                taskArray['value'] = res.data.data[key].id;
                taskList.push(taskArray);
                taskArrayonlyid.push(res.data.data[key].id);
              }
              console.log('Stage Lists : ', stageList);
              console.log('Stage Lists : ', eventInfo.opportunity_id);
              this.setState(prevState => ({
                ...prevState,
                taskList,
                stage_name:stageList[eventInfo.opportunity_id].stage_name+' Stage',
                stage_icon:stageList[eventInfo.opportunity_id].stage_icon,
                eventInfo: 
                  {
                    ...prevState.eventInfo,
                    task_id: '',
                    stage_id : stageList[eventInfo.opportunity_id].stage_id,
                  }
              }), ()=>{
                if (params.preset=='true') {
                  if (taskArrayonlyid.includes(SetTask.id)){
                    this.setState(
                      prevState => ({
                        ...prevState,
                        defaulttaskSelectText: SetTask.task_title,
                        eventInfo: 
                        {
                          ...prevState.eventInfo,
                          stage_id : SetTask.stage_id,
                          task_id : SetTask.id,
                        }
                    }));
                  } else {
                    this.setState(
                      prevState => ({
                        ...prevState,
                        defaulttaskSelectText: "Select Task",
                        eventInfo: 
                        {
                          ...prevState.eventInfo,
                          stage_id : SetTask.stage_id,
                          task_id : "",
                        }
                    }));
                  }

                }
              });
            } 

          });
        })
        .catch((error) => {
            console.log(error)
        })
  }  

  setEventDetailShow = (newValue) => {
    this.setState({ eventDetailShow: newValue });
  }
  showSessionDetail = () => {

    const { onChange,session_event_detail, user_role_id } = this.state;
    if(session_event_detail){
     var stage_pic =  process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((session_event_detail.stage_icon_url!=="") && (session_event_detail.stage_icon_url!==null) )? session_event_detail.stage_icon_url : 'default_sales_stage.png');  
     var sales_rep_pic =  process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((session_event_detail.sales_rep_profile_pic_url!=="") && (session_event_detail.sales_rep_profile_pic_url!==null) )? session_event_detail.sales_rep_profile_pic_url : 'default-avatar.png');  
     var sales_manager_name,sales_manager_profile_pic;
     if(user_role_id==3){
       sales_manager_name =  localStorage.getItem('full_name');
       sales_manager_profile_pic =  localStorage.getItem('profilePic');
     }else{
      sales_manager_name =  session_event_detail.sales_manager_name;
      var sales_man_pic =  process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((session_event_detail.sales_manager_profile_pic_url!=="") && (session_event_detail.sales_manager_profile_pic_url!==null) )? session_event_detail.sales_manager_profile_pic_url : 'default-avatar.png');  

      sales_manager_profile_pic =  sales_man_pic;
     }

    return (<>  <form>


      <div className="modal-body">
        <div className="form-group">
          <label className="mb-0">Event Title</label>
          <div className="feild-text py-2 f-600 f2015">
          {session_event_detail.event_title}
          </div>
        </div>
        <div className="form-group">
          <label className="mb-0">Opportunity</label>
          <div className="feild-text py-2 f-600 f2015">
          {session_event_detail.title}
          </div>
        </div>
        <div className="form-group">
          <label className="mb-0">Task</label>
          <div className="feild-text py-2 f-600 f2015">
          {session_event_detail.task}
          </div>
        </div>
        <div className="form-group">
          <ul className="shedule-details f-500 list-unstyled">
            <li className="pb-2 pt-1 mb-2 mt-1">
              {/* <i className="ri-lightbulb-line color_blue mr-3"></i> */}
                <img src={stage_pic} className='color_blue mr-2' alt="" width="25px" height="25px"/>
                <span className="d-inline-block" style={{marginLeft: "3px"}}>
                {session_event_detail.stage} Stage
                </span>
            </li>
            
            <li className="pb-2 pt-1 mb-2 mt-1">
              <i className="ri-calendar-event-line color_blue mr-3 "></i> 
              {/* Thursday, 1 December 2021 */}
              {moment(session_event_detail.start).format("dddd, DD MMMM yyyy")}
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <i className="ri-time-line color_blue mr-3"></i>
              {moment(session_event_detail.start).format("hh:mma")}  -  {moment(session_event_detail.end).format("hh:mma")}
              {/* <TimePicker
                        onChange={onChange}
                        value={"10:00"}  disableClock={true}
                      /> <TimePicker
                      onChange={onChange} disableClock={true}
                      value={"12:00"}
                    /> */}
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1 d-flex align-items-center">
              <i className="ri-live-line color_blue mr-3 "></i>
              <div style={{wordBreak: 'break-all'}}>
               {session_event_detail.meeting_link!="" ?    user_role_id==3 ? <a className="color_blue underline"  href={session_event_detail.meeting_link} target="_blank"> 
                 {session_event_detail.meeting_link}</a>:<a className="color_blue underline"  style={{cursor:"default"}}> 
                 {session_event_detail.meeting_link}</a>:"Meeting link will generate shortly."}
                 </div>
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <div className="main-profile d-flex  align-items-center">
                <div className="img-wrap" >
                  <img src={sales_manager_profile_pic} alt="" />
                </div>
                <div className="ml-1">
                  <div className="name f-500 mb-1 ml-1" style={{paddingLeft :"2px"}}>
                    {sales_manager_name}
                  </div>
                </div>
              </div>
            </li>
            <li className="pb-2 pt-1 mb-2 mt-1">
              <div className="main-profile d-flex  align-items-center">
                <div className="img-wrap">

                  <img src={sales_rep_pic} alt="" />
                </div>
                <div className="ml-1">
                  <div className="name f-500 mb-1 ml-1" style={{paddingLeft :"2px"}}>
                     {session_event_detail.sales_rep_name}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="form-group">
          <label className="mb-0">Description</label>
          <div className="feild-text py-3 word-break-all f2015">
              {session_event_detail.description} 
          </div>
        </div>
        {/* <div className="form-group">
            <label className="mb-0">Task</label>
            <textarea type="text" className="form-control f-600 f2015" value=" Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt." placeholder="Task"></textarea>
          </div> */}
      </div>
      {user_role_id==3?
      <div className="modal-footer justify-content-center">
      <div class="color_blue">
        <i class="iconmoon icon-edit2 vertical-bottom"></i>
        <a class="color_blue underline " onClick={() => this.editSessionShow()}> Edit Session</a>
        </div>
          {/* <button type="button" className="btn dark-btn mx-2" onClick={() => this.editSessionShow()}>Edit Session</button> */}
        </div>
        :null}
    </form>
    </>


    );
      }

  };
  
  setRescheduleSessionShow = (newValue) => {
    this.setState({ rescheduleSessionShow: newValue,rescheduled_start_time:"", rescheduled_end_time:"" });
  }
  showRescheduleSession = () => {
    const {nylasData,Message, MessageType, rescheduled_start_time,rescheduled_end_time, viewModel, eventDetailShow, createSessionShow, rescheduleSessionShow, value, onChange ,session_event_detail} = this.state;
    if(session_event_detail){
      var stage_pic =  process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((session_event_detail.stage_icon_url!=="") && (session_event_detail.stage_icon_url!==null) )? session_event_detail.stage_icon_url : 'default_sales_stage.png');  
      var sales_rep_pic =  process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((session_event_detail.sales_rep_profile_pic_url!=="") && (session_event_detail.sales_rep_profile_pic_url!==null) )? session_event_detail.sales_rep_profile_pic_url : 'default-avatar.png');  
      var sales_manager_name =  localStorage.getItem('full_name');
      var sales_manager_profile_pic =  localStorage.getItem('profilePic');
      var start ;
      var end;
      var res_start_date;
      var res_start_time;
      if(rescheduled_start_time==""){
       res_start_date = new Date(session_event_detail.start);

      //  res_start_date = moment(session_event_detail.start).format('YYYY-MM-DD HH:mm');
       res_start_time = moment(session_event_detail.start).format('HH:mm');  
      start = session_event_detail.start;
      this.setState({rescheduled_start_time:session_event_detail.start});
     
      }else{
      
         res_start_date = new Date(rescheduled_start_time);
        //  res_start_date = moment(rescheduled_start_time).format('YYYY-MM-DD');

         res_start_time = moment(rescheduled_start_time).format('HH:mm');        
        start = rescheduled_start_time;
        
      }
      if(rescheduled_end_time==""){
        var res_end_time = moment(session_event_detail.end).format('HH:mm');
        end = session_event_detail.end;
        
      this.setState({rescheduled_end_time:session_event_detail.end});
      }else{
        var res_end_time = moment(rescheduled_end_time).format('HH:mm');
        end = rescheduled_end_time;
      }
    
     
    return (<>
      <form>
      {((nylasData!=="start")) ? (
                    (nylasData && nylasData.access_token && nylasData.access_token!=="" && nylasData.success && nylasData.success==="true") ? (
                        <>
                        </>
                    ) : (
                        <>
                        <div class="d-flex align-items-center mb-3 bg_light_gray p-3"  style={{margin: '-20px'}}>
                          <small class="ri-alert-fill mr-2 ri-3x color_blue"></small>
                          <div class="mb-0">The Core Ai calendar is not connected to your calendar. Events cannot be scheduled. To connect your calendar to Core Ai, go to your&nbsp;
                          <Link to={`./myprofile`} class="color_blue underline">Profile Page</Link>.
                          </div>
                        </div>                                                        
                        </>
                    )

                ) :null} 

        <div className="modal-body">
        {((Message) && (Message !== null)) ?
          (
            <div className={`alert alert-${MessageType}`}>
              {Message}
            </div>
          ) : (``)
        }    
          <div className="form-group">
            <label className="mb-0">Event Title</label>
            <div className="feild-text py-2 f-600 f2015">
            {session_event_detail.event_title}
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Opportunity</label>
            <div className="feild-text py-2 f-600 f2015">
            {session_event_detail.title}
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Task</label>
            <div className="feild-text py-2 f-600 f2015">
            {session_event_detail.task}
            </div>
          </div>
          <div className="form-group">
            <ul className="shedule-details f-500 list-unstyled">
              <li className="pb-2 pt-1 mb-2 mt-1">
              <img src={stage_pic} className='color_blue mr-2' alt="" width="25px" height="25px"/>
                <span className="d-inline-block" style={{marginLeft: "3px"}}>
                {session_event_detail.stage} Stage
                </span>
              </li>
              <li className="pb-2 pt-1 mb-2 mt-1 d-flex align-items-center">
                <i className="ri-calendar-event-line color_blue mr-3 "></i>
                <DatePicker
                  dateFormat="d MMMM yyyy"
                  className="form-control f-600 f2015 with-icon pt-0"
                  selected= {res_start_date}
                  onChange={(date) => this.setState({ rescheduled_startDate: date }, ()=>{
                    var st_time = moment(start).format('HH:mm');
                    var en_time = moment(end).format('HH:mm');
                        this.setState({
                          
                          rescheduled_start_time : moment(date).format('YYYY-MM-DD')+'T'+st_time+':01',
                          rescheduled_end_time : moment(date).format('YYYY-MM-DD')+'T'+en_time+':00',
                        });
                        
                  })}
                  maxDate={moment().add(365, 'days').toDate()}
                  minDate={moment().toDate()}
                />
              </li>
              <li className="pb-2 pt-1 mb-2 mt-1 ">
                <i className="ri-time-line color_blue mr-3"></i>
                <TimePicker className="f-500 f2015"
                  format={'hh:mm a'}
                  onChange={this.changeRescheduledStartTime}
                  value= {res_start_time} disableClock={true}
                /> 
                <TimePicker className="f-500 f2015"
                  format={'hh:mm a'}
                  onChange={this.changeRescheduledEndTime} disableClock={true}
                  value= {res_end_time}
                />
              </li>
              <li className="pb-2 pt-1 mb-2 mt-1 d-flex align-items-center">
              <i className="ri-live-line color_blue mr-3 "></i>
              <div style={{wordBreak: 'break-all'}}>
               {session_event_detail.meeting_link!="" ? <a className="color_blue underline"  href={session_event_detail.meeting_link} target="_blank"> 
                 {session_event_detail.meeting_link}</a>:"Meeting link will generate shortly."}
                 </div>
            </li>
              <li className="pb-2 pt-1 mb-2 mt-1">
              <div className="main-profile d-flex  align-items-center">
                <div className="img-wrap" >
                  <img src={sales_manager_profile_pic} alt="" />
                </div>
                <div className="ml-1">
                  <div className="name f-500 mb-1 ml-1" style={{paddingLeft :"2px"}}>
                    {sales_manager_name}
                  </div>
                </div>
              </div>
              </li>
              <li className="pb-2 pt-1 mb-2 mt-1">
              <div className="main-profile d-flex  align-items-center">
                <div className="img-wrap">

                  <img src={sales_rep_pic} alt="" />
                </div>
                <div className="ml-1">
                  <div className="name f-500 mb-1 ml-1" style={{paddingLeft :"2px"}}>
                     {session_event_detail.sales_rep_name}
                  </div>
                </div>
              </div>
              </li>              
            </ul>
          </div>
          <div className="form-group">
            <label className="mb-0">Description</label>
            <div className="feild-text word-break-all f2015">
              <textarea type="text" className="form-control f-600 f2015" placeholder="Add Description"  id='description' onChange={this.changeRescheduledFunction} defaultValue={session_event_detail.description}></textarea>
            </div>
          </div>

          {session_event_detail.status=="RESCHEDULED"?
        <div className="form-group">
          <label className="mb-0">Reschedule Reason</label>
          <div class="rescheduled_note">
          {(session_event_detail.reschedule_reason && session_event_detail.reschedule_reason.length>0) ? (
          session_event_detail.reschedule_reason.map((reschedule_note) => {return(
             <div className="form-group">           
                <div className="feild-text py-3 word-break-all f2015 color_dark ">{reschedule_note.note_body}</div>           
        </div>);
          }))
          :null}
          </div>
          </div>
        :null}
       

        </div>
        <div className="modal-footer justify-content-center">
        {(nylasData!=="start") ? (
                                                    (nylasData && nylasData.access_token && nylasData.access_token!=="" && nylasData.success && nylasData.success==="true" ) ? (
                                                        <>
        <button type="button" className="btn dark-btn mx-2" onClick={() => this.rescheduleEvent(session_event_detail.id)}>Reschedule</button>
                                                        </>
                                                    ) : (
                                                        <>
        <button type="button" className="btn light-btn mx-2" disabled >Reschedule</button>
                                                        </>
                                                    )

                                                ) :null}  
          {/* <button type="button" className="btn dark-btn mx-2 px-3" onClick={() => this.rescheduleEvent(session_event_detail.id)}>Reschedule</button> */}
          <button type="button" className="btn light-btn f-400 cancel-btn mx-2" data-dismiss="modal" onClick={() => this.setRescheduleSessionShow(false)}>Cancel</button>
        </div>
      </form>
    </>


    );}
  }
  setCreateSessionShow = (newValue) => {
    this.setState(prevState => ({
      ...prevState,
      createSessionShow: newValue,
      MessageOutSide : '',
      MessageTypeOutSide : '',
      Message : '',
      MessageType : '',
      stage_name : '',
      stage_icon :"",
      buttonHideA: false,
    }), ()=>{ 
      if (newValue===false){
        this.setState(prevState => ({
          ...prevState,
          params:[]  
        }), ()=>{
          localStorage.removeItem("Task");        
          localStorage.removeItem("OppoDetails"); 
          window.history.replaceState(null, "CoreAi", "./scheduler");
          setTimeout(() => { this.componentDidMount();   this.setState({
            Message : '',
            MessageType : '',
            description : '',
          })}, 1000);
        })
      }
     
    });
    
  }

  showCreateSession = () => {
    const {  buttonHideA, Message, MessageType, nylasData, first_name, last_name, profileImg, rep_name, rep_pic, stage_name, stage_icon } = this.state;
    var debug_date = this.state.startDate;
    // debug_date.setHours(debug_date.getHours()+5);
    debug_date.toLocaleString('en-US', { timeZone: 'America/New_York' })
    let start_date = debug_date;
   
   
    return (<>
      <form>

                {((nylasData!=="start")) ? (
                    (nylasData && nylasData.access_token && nylasData.access_token!=="" && nylasData.success && nylasData.success==="true") ? (
                        <>
                        </>
                    ) : (
                        <>
                        <div class="d-flex align-items-center mb-3 bg_light_gray p-3"  style={{margin: '-20px'}}>
                          <small class="ri-alert-fill mr-2 ri-3x color_blue"></small>
                          <div class="mb-0">The Core Ai calendar is not connected to your calendar. Events cannot be scheduled. To connect your calendar to Core Ai, go to your&nbsp;
                          <Link to={`./myprofile`} class="color_blue underline">Profile Page</Link>.
                          </div>
                        </div>                                                        
                        </>
                    )

                ) :null}   
        <div className="modal-body">
        {((Message) && (Message !== null)) ?
          (
            <div className={`alert alert-${MessageType}`}>
              {Message}
            </div>
          ) : (``)
        }             
          <div className="form-group">
            <label className="mb-0">Event Title</label>
            <div className="feild-text f-600 f2015">
              <input type="text" className="form-control f-600 f2015" defaultValue=""
                placeholder="Event Title" id='title' value={this.state.eventInfo.title} onChange={this.changeFunction} disabled/>
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Select Opportunity</label>
            <div className="feild-text f-600 f2015">
              <CustomSelect
                className="form-control f-600 text-left"
                defaultText={this.state.defaultopprtunitySelectText}
                optionsList={this.state.opportunityList}
                name="opp_id" dataUid={`opp_id`}
                onSelect={this.changeSelectonEvent}

              />
            </div>
          </div>


          <div className="form-group">
            <label className="mb-0">Select Task</label>
            <div className="feild-text f-600 f2015">
              <CustomSelect
                className="form-control f-600 text-left"
                defaultText={this.state.defaulttaskSelectText}
                name="task_id" dataUid={`task_id`}
                optionsList={this.state.taskList}
                onSelect={this.changeSelectonEvent}
              />
                 
            </div>
          </div>
          <div className="form-group">
            <ul className="shedule-details list-unstyled">
              <li className="pb-2 pt-1 mb-2 mt-1">
                <div className="main-profile d-flex  align-items-center">
                  {(stage_icon && stage_icon!='') ? (
                  <div className="img-wrap mr-1"> 
                    <img src={stage_icon} alt="" />
                  </div>
                  ) : null}
                  <div className="ml-1">
                    <div className="name f-500 ml-1">
                      {stage_name}
                    </div>
                  </div>
                </div>
              </li>

              {/* <li className="pb-2 pt-1 mb-2 mt-1">
                  <div className="img-wrap"> 
                    <img src={stage_icon} alt="" />
                  </div>
                <i className="ri-lightbulb-line color_blue mr-3"></i> {stage_name}
              </li> */}
              <li className="pb-2 pt-1 mb-2 mt-1 d-flex align-items-center">
                <i className="ri-calendar-event-line color_blue mr-3 "></i>
                {/* Thursday, 1 December 2021 */}
                {/* <DatePicker
                                dateFormat="MM-dd-yyyy"
                                className="form-control f-600 f2015"
                                selected={this.state.startDate}
                                onChange={(date) => this.setState({ startDate: date })}
                                minDate={moment().toDate()}
                            /> */}
                <DatePicker
                  dateFormat="d MMMM yyyy"
                  className="form-control f-600 f2015 with-icon pt-0"
                  selected={start_date}
                  onChange={(date) => this.setState({ startDate: date }, ()=>{
                    this.setState(prevState => ({
                      ...prevState,
                      eventInfo: 
                        {
                          ...prevState.eventInfo,
                          start_time : moment(date).format('YYYY-MM-DD')+'T'+this.state.startTime+':01',
                          end_time : moment(date).format('YYYY-MM-DD')+'T'+this.state.endTime+':00',
                        }
                    }));
                  })}
                  maxDate={moment().add(365, 'days').toDate()}
                  minDate={moment().toDate()}
                />
              </li>
              <li className="pb-2 pt-1 mb-2 mt-1 ">
                <i className="ri-time-line color_blue mr-3"></i>
                <TimePicker className="f-500 f2015"
                  format={'hh:mm a'}
                  onChange={this.changeStartTime}
                  value={this.state.startTime} disableClock={true}
                /> 
                <TimePicker className="f-500 f2015"
                  format={'hh:mm a'}
                  onChange={this.changeEndTime} disableClock={true}
                  value={this.state.endTime}
                />
              </li>
              <li className="pb-2 pt-1 mb-2 mt-1 d-flex align-items-center">
                <i className="ri-live-line color_blue mr-3 "></i>                     

                    {/* <Link className="color_blue underline"  >Generate Meeting Link</Link> */}
                    <div className="name f-500">
                    Zoom meeting link will be generated once the event is created.
                    </div>
              </li>
              <li className="pb-2 pt-1 mb-2 mt-1">
                <div className="main-profile d-flex  align-items-center">
                  <div className="img-wrap">
                    <img src={profileImg} alt="" />
                  </div>
                  <div className="ml-1">
                    <div className="name f-500 mb-1 ml-1" style={{paddingLeft :"2px"}}>
                      {first_name} {last_name}
                    </div>
                  </div>
                </div>
              </li>
              <li className="pb-2 pt-1 mb-2 mt-1">
                <div className="main-profile d-flex  align-items-center">
                  <div className="img-wrap"> 
                    <img src={rep_pic} alt="" />
                  </div>
                  <div className="ml-2">
                    <div className="name f-500 mb-1" style={{paddingLeft :"2px"}}>
                      {rep_name}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="form-group">
            <label className="mb-0">Description</label>
            <div className="feild-text word-break-all f2015">
              <textarea type="text" className="form-control f-600 f2015" placeholder="Add Description"  id='description' onChange={this.changeFunction}>{this.state.eventInfo.description}</textarea>
            </div>
          </div>
          {/* <div className="form-group">
            <label className="mb-0">Task</label>
            <textarea type="text" className="form-control f-600 f2015" value=" Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt." placeholder="Task"></textarea>
          </div> */}
        </div>
        <div className="modal-footer justify-content-center">
        {/* <button type="button" className="btn dark-btn mx-2" onClick={() => this.newEvent()}>Save</button> */}
        {(nylasData!=="start") ? (
                                                    (nylasData && nylasData.access_token && nylasData.access_token!=="" && nylasData.success && nylasData.success==="true" && (buttonHideA!==true)) ? (
                                                        <>
        <button type="button" className="btn dark-btn mx-2" onClick={() => this.saveEvent()}>Save</button>
                                                        </>
                                                    ) : (
                                                        <>
        <button type="button" className="btn light-btn mx-2" disabled onClick={() => this.saveEvent()}>Save</button>
                                                        </>
                                                    )

                                                ) :null}          
        {/* <button type="button" className="btn dark-btn mx-2" onClick={() => this.saveEvent()}>Save</button> */}

          <button type="button" className="btn light-btn  cancel-btn mx-2" data-dismiss="modal" onClick={() => this.setCreateSessionShow(false)}>Cancel</button>
        </div>
      </form>
    </>


    );

  };
    



  changeStartTime = (value) =>{
    this.setState({ startTime: value }, ()=>{
      this.setState(prevState => ({
        ...prevState,
        eventInfo: 
          {
            ...prevState.eventInfo,
            start_time : moment(this.state.startDate).format('YYYY-MM-DD')+'T'+this.state.startTime+':01',
          }
      }));
    })
  }
  changeEndTime = (value) =>{
    this.setState({ endTime: value }, ()=>{
      this.setState(prevState => ({
        ...prevState,
        eventInfo: 
          {
            ...prevState.eventInfo,
            end_time : moment(this.state.startDate).format('YYYY-MM-DD')+'T'+this.state.endTime+':00',
          }
      }));
    })
  }  

  changeRescheduledStartTime = (value) =>{  
    var start_time = moment(this.state.rescheduled_start_time).format('YYYY-MM-DD')+'T'+value+':01';
    
    this.setState({rescheduled_start_time:start_time});       
  }
  changeRescheduledEndTime = (value) =>{
    var end_time =  moment(this.state.rescheduled_end_time).format('YYYY-MM-DD')+'T'+value+':00';
    
    this.setState({rescheduled_end_time:end_time});         
  }  

  changeSelectonEvent = (ele, Uid) =>{
    const {opportunityList} = this.state;
    if (Uid=='task_id'){
      this.setState(prevState => ({
        ...prevState,
        eventInfo: 
          {
            ...prevState.eventInfo,
            task_id : ele,
          }
      }), ()=>{

      }); 
    }
    else if (Uid=='opp_id'){
      var eventTitle = 'Coaching for '+opportunityList[ele].name;
      this.setState(prevState => ({
        ...prevState,
        eventInfo: 
          {
            ...prevState.eventInfo,
            title : eventTitle,
            opportunity_id : ele,
            task_id : '',
          },
      }), ()=>{
        this.getTasksByOpportunity();
      }); 
    }    
  }
  changeRescheduledFunction = (e) => {
    if(e.target.value.trim().length == 0){
      e.target.value = "";
    }
      var s = e.target.value;
      var keyid = e.target.id;
  
     if (keyid === "description") {
        if (e.target.value.length>500){
          this.setState({
            Message : "Event description should be max 500 characters.",
            MessageType : "danger",
            buttonHideA : true,
          })
        } else {
          this.setState({
            Message : null,
            MessageType : null,
            buttonHideA : false,
          })
        }
  
        this.setState({rescheduled_description:s}); 
      }
  }

  changeFunction = (e) => {
    if(e.target.value.trim().length == 0){
      e.target.value = "";
    }
      var s = e.target.value;
      var keyid = e.target.id;
  
      if (keyid === "title") {

        this.setState(prevState => ({
          ...prevState,
          eventInfo: 
            {
              ...prevState.eventInfo,
              title : s,
            }
        }));      
      } else if (keyid === "description") {
        if (e.target.value.length>500){
          this.setState({
            Message : "Event description should be max 500 characters.",
            MessageType : "danger",
            buttonHideA : true,
          })
        } else {
          this.setState({
            Message : null,
            MessageType : null,
            buttonHideA : false,
          })
        }
  
        this.setState(prevState => ({
          ...prevState,
          eventInfo: 
            {
              ...prevState.eventInfo,
              description : s,
            }
        })); 
      }
      
  }
  
  saveEvent = async () => {
    
    utilityObj.loaderView("show");

    this.setState(prevState => ({
      ...prevState,
      eventInfo: 
      {
        ...prevState.eventInfo,
        start_timestamp : moment(this.state.eventInfo.start_time).format("X"),
        end_timestamp : moment(this.state.eventInfo.end_time).format("X"),
        // start_timestamp : Date.parse(this.state.eventInfo.start_time)/1000,
        // end_timestamp : Date.parse(this.state.eventInfo.end_time)/1000,
        
      }
    }), async ()=>{
      const {eventInfo} = this.state;
      // start_timestamp
     
      var testStartTime = moment(eventInfo.start_time).format('YYYY-MM-DD HH:mm:ss');
      var testCurrentTime = moment().format('YYYY-MM-DD HH:mm:ss');
      var startTimeStamp = moment(testStartTime).format("X");
      // Date.parse(testStartTime);
      var currentTimeStamp = moment(testCurrentTime).format("X"); 
      // Date.parse(testCurrentTime);
      if (currentTimeStamp>=startTimeStamp){
        this.setState({
          Message : 'Time should be a future value.',
          MessageType : 'danger', 
        });
        setTimeout(() => { 
          this.setState({ 
            Message : '',
            MessageType : ''
            })               
         }, 3000);     
         utilityObj.loaderView("hide");
        return;
      }
      if (!eventInfo.opportunity_id || eventInfo.opportunity_id=="" ){
        this.setState({
          Message : 'Please select opportunity for schedule session.',
          MessageType : 'danger', 
        });
        setTimeout(() => { 
          this.setState({ 
            Message : '',
            MessageType : ''
            })               
         }, 3000);     
         utilityObj.loaderView("hide");
        return;      
      }
      if (!eventInfo.stage_id || eventInfo.stage_id=="" ){
        this.setState({
          Message : 'Please select opportunity for schedule session.',
          MessageType : 'danger', 
        });
        setTimeout(() => { 
          this.setState({ 
            Message : '',
            MessageType : ''
            })               
         }, 3000);     
         utilityObj.loaderView("hide");
        return;      
      }
  
      if (!eventInfo.task_id || eventInfo.task=="" ){
        this.setState({
          Message : 'Please select task for schedule session.',
          MessageType : 'danger', 
        });
        setTimeout(() => { 
          this.setState({ 
            Message : '',
            MessageType : ''
            })               
         }, 3000);     
         utilityObj.loaderView("hide");
        return;      
      }
      
      var teststartDate = moment(eventInfo.start_time).format("X");
      var testendDate = moment(eventInfo.end_time).format("X");
      var diff = (teststartDate - testendDate);
      if (diff>0){
        this.setState({
          Message : 'Start time should be less than end time.',
          MessageType : 'danger', 
        })
        setTimeout(() => { 
          this.setState({ 
            Message : '',
            MessageType : ''
            })               
         }, 3000); 
         utilityObj.loaderView("hide");
  
        return;
      }
      await axios.post(`/calendar/create-session-event`, eventInfo, {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
          }
      })
          .then(res => {
            
              if (res.data.code === 200) {
                  this.setState({responseData : res.data.data,
                    Message : 'Session scheduled successfully.',
                    MessageType : 'success', 
                    stage_name : '',
                    stage_icon :"",            
                  });
                  setTimeout(() => { 
                    this.setState({ 
                      Message : '',
                      MessageType : '',
                      });
                      this.setCreateSessionShow(false); 
                      this.componentDidMount();
                   }, 3000); 
                   utilityObj.loaderView("hide");
  
              } else {
                  this.setState({responseData : res.data.data,
                    Message : 'Error in schedule session.',
                    MessageType : 'danger', 
                  });
                  setTimeout(() => { 
                    this.setState({ 
                      Message : '',
                      MessageType : ''
                      })               
                   }, 3000);           
                   utilityObj.loaderView("hide");
              }
          })
          .catch((error) => {
              console.log(error)
              utilityObj.loaderView("hide");
  
          })


    });      
  }

  render() {
    
    const { user_role, nylasData, user_role_id,viewModel, eventDetailShow, createSessionShow, rescheduleSessionShow, scheduler_wraper , MessageOutSide, MessageTypeOutSide} = this.state;
    // viewModel.setResources(this.state.resources);
    // viewModel.setEvents(this.state.events);
    if(user_role_id==3 || user_role_id==2){
    viewModel.config.resourceName = this.state.resource_name;
    }
    let leftCustomHeader = (
        <div> 
            {/* <Link className="text-dark mt-0  d-block  d-flex align-items-center" to={'/calendar'} ><i className="iconmoon icon-chevron-left"></i><span className="d-none d-sm-inline-block"> Go Back </span></Link> */}
        </div>
    );
    let rightCustomHeader = (
      <div>

        <Link className="text-dark mt-0  d-block   d-flex align-items-center month__btn" to={'/calendar'} ><span className="" > Month </span></Link>


      </div>
    );
    return (
      <div>
        <Modal classname="calendar-modal" className="calendar-wrap"
          title="Session Details"
          contentClasses=""
          size="m"
          onClose={() => this.setEventDetailShow(false)}
          show={eventDetailShow}>
          {this.showSessionDetail()}
        </Modal>

        <Modal classname="calendar-modal" className="calendar-wrap"
          title="Schedule Session"
          contentClasses=""
          size="m"
          onClose={() => this.setCreateSessionShow(false)}
          show={createSessionShow}>
          {this.showCreateSession()}
        </Modal>

        <Modal classname="calendar-modal" className="calendar-wrap"
          title="Reschedule Session"
          contentClasses=""
          size="m"
          onClose={() => this.setRescheduleSessionShow(false)}
          show={rescheduleSessionShow}>
          {this.showRescheduleSession()}
        </Modal>

        <main className="main-contant opportunity-management-main">
        {((user_role==4 || user_role==3) && (nylasData!=="start")) ? (
                                                    (nylasData && nylasData.access_token && nylasData.access_token!=="" && nylasData.success && nylasData.success==="true") ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <>
                                                        {/* <div className="divconmoon icon-warning mb-3"></div> */}
                                                        <div className="d-flex align-items-center mb-4" style={{maxWidth:'950px'}}>
                                                          <i class="ri-alert-fill mr-2 ri-3x color_blue"></i>                                                        
                                                          <p className="color_black mb-0">The Core Ai calendar is not connected to your calendar. Events will not be automatically updated from Core Ai to your calendar. To connect your calendar to Core Ai, go to your&nbsp; 
                                                          <Link to={`./myprofile`} className="color_blue underline">Profile Page</Link>.
                                                          </p>                                                        
                                                        </div>
                                                        </>
                                                    )

                                                ) :null}  
                                                          
          {((MessageOutSide) && (MessageOutSide !== null)) ?
          (
            <div className={`alert alert-${MessageTypeOutSide}`}>
              {MessageOutSide}
            </div>
          ) : (``)
        }             
          <div className="contents">

            <div className={`resource-wrap ${scheduler_wraper}`} >
              {/* <div className="resource-wrap company-admin-scheduler"> */}
{user_role==3 ?
              <Scheduler schedulerData={viewModel}
                prevClick={this.prevClick}
                nextClick={this.nextClick}
                onSelectDate={this.onSelectDate}
                onViewChange={this.onViewChange}
                eventItemClick={this.eventClicked}
                viewEventClick={this.ops1}
                //  viewEventText="Ops 1"
                //  viewEvent2Text="Ops 2"
                viewEvent2Click={this.ops2}
                updateEventStart={this.updateEventStart}
                updateEventEnd={this.updateEventEnd}
                moveEvent={this.rescheduleSession}
                newEvent={this.createSession}
                // onScrollLeft={this.onScrollLeft}
                // onScrollRight={this.onScrollRight}
                // onScrollTop={this.onScrollTop}
                // onScrollBottom={this.onScrollBottom}
                toggleExpandFunc={this.toggleExpandFunc}
                slotClickedFunc={this.slotClickedFunc}
                 leftCustomHeader={leftCustomHeader}
                rightCustomHeader={rightCustomHeader}
                eventItemTemplateResolver={this.eventItemTemplateResolver}
                eventItemPopoverTemplateResolver = {this.eventItemPopoverTemplateResolver}
                DATE_FORMAT='YYYY-MM'
              />

  :
                <Scheduler schedulerData={viewModel}
                prevClick={this.prevClick}
                nextClick={this.nextClick}
                onSelectDate={this.onSelectDate}
                onViewChange={this.onViewChange}
                eventItemClick={this.eventClicked}
                viewEventClick={this.ops1}
                //  viewEventText="Ops 1"
                //  viewEvent2Text="Ops 2"
                viewEvent2Click={this.ops2}
                updateEventStart={this.updateEventStart}
                updateEventEnd={this.updateEventEnd}
                moveEvent={this.rescheduleSession}
                // newEvent={this.createSession}
                // onScrollLeft={this.onScrollLeft}
                // onScrollRight={this.onScrollRight}
                // onScrollTop={this.onScrollTop}
                // onScrollBottom={this.onScrollBottom}
                toggleExpandFunc={this.toggleExpandFunc}
                slotClickedFunc={this.slotClickedFunc}
                leftCustomHeader={leftCustomHeader}
                rightCustomHeader={rightCustomHeader}
                eventItemTemplateResolver={this.eventItemTemplateResolver}
                eventItemPopoverTemplateResolver = {this.eventItemPopoverTemplateResolver}
                DATE_FORMAT='YYYY-MM'
              />
  
  }            
            </div>
            <div className="modal calendar-wrap fade  show">
              <div className="modal-dialog show">
                <div className="modal-content">
                  <div className="modal-header p-0 border-0">
                    <h5 className="modal-title p-3  text-center w-100 text-white" >Session Details</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>           </div>
    )
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.state.events);
    schedulerData.setResources(this.state.resources);
 
    
    // schedulerData.set
    this.setState({
      viewModel: schedulerData
    })
  }

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.state.events);
    schedulerData.setResources(this.state.resources);
    this.setState({
      viewModel: schedulerData
    })
  }

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(this.state.events);
    schedulerData.setResources(this.state.resources);
    this.setState({
      viewModel: schedulerData
    })
  }

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.events);
    schedulerData.setResources(this.state.resources);
    this.setState({
      viewModel: schedulerData
    })
  }

  eventClicked = (schedulerData, event,slotName) => {
   
    var session_event_detail = [];
    var user_role_id = localStorage.getItem('adminsrToken');
    this.setState({session_event_detail:event}, ()=>{
      if(user_role_id==3){
        if(event.status=="RESCHEDULED"){
          this.setRescheduleSessionShow(true);
        }else{
          this.setEventDetailShow(true);
        }
      }else if(user_role_id==2){
        this.setEventDetailShow(true);
      }
    });
    // alert(`You just clicked an event: {id: ${event.id}, title: ${event.}}`);
    // thissetEventDetailShow

  };

  ops1 = (schedulerData, event) => {
    alert(`You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`);
  };

  ops2 = (schedulerData, event) => {
    alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`);
  };


  loadDefaultPresetPopup = () => {
   const {SetTask, SetOppoDetails} = this.state;
  //  YYYY-MM-DD HH:mm:ss
  var profileImg = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((SetOppoDetails.sales_rep_profile_pic_url!=="") && (SetOppoDetails.sales_rep_profile_pic_url!==null) )? SetOppoDetails.sales_rep_profile_pic_url : 'default-avatar.png');

  this.setState(prevState => ({
    ...prevState,
    startDate : new Date(moment().format('YYYY-MM-DD')),
    startTime: moment().add(1, 'hours').format('HH:00'), 
    endTime: moment().add(1, 'hours').format('HH:59'),
    sales_rep_id : SetOppoDetails.sales_rep,
    rep_name : SetOppoDetails.sales_rep_first_name+' '+SetOppoDetails.sales_rep_last_name,
    rep_pic : profileImg
  }), ()=>{
    var start_time = moment(moment(this.state.startDate).format('YYYY-MM-DD')+'T'+this.state.startTime+':01').format('YYYY-MM-DDTHH:mm:ss');
    var end_time = moment(start_time).add(59, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
    this.setState(prevState => ({
      ...prevState,
      defaulttaskSelectText: SetTask.task_title,
      eventInfo: 
        {
          ...prevState.eventInfo,
          start_time,
          end_time,
          sales_rep_id : SetOppoDetails.sales_rep,
        }
    }), ()=>{
      this.getOpportunitiesBySalesRep();
       
      setTimeout(() => { this.setCreateSessionShow(true); }, 2000);
    }); 
  })
  }

  createSession = (schedulerData, slotId, slotName, start, end, type, item) => {
    
    var debug_data = new Date(start.replace(/-/g, "/"));
    console.log("start_check", start)
    var event_start_date =  debug_data.getFullYear() + "-" + (debug_data.getMonth()+1)+ "-" + debug_data.getDate()  ;
   
    var view_type = schedulerData.viewType;
    var rep = slotName.split(":@:");
    var testStartTime = moment(moment(start).format('YYYY-MM-DD')+'T'+moment(start).format('HH:mm')+':00').format('YYYY-MM-DDTHH:mm:ss');
    var testCurrentTime = moment().format('YYYY-MM-DDTHH:mm:ss');
    var startTimeStamp = moment(testStartTime).format("X");
 
    var currentTimeStamp =  moment(testCurrentTime).format("X");


    var selected_day = 0;
    if(view_type==1){
        if(moment().format('YYYY-MM-DD') == moment(start).format('YYYY-MM-DD')){
            selected_day = 1;
        }
    }

    if (currentTimeStamp>=startTimeStamp && selected_day==0){
      this.setState({
        MessageOutSide : 'Calendar events cannot be scheduled in the past.',
        MessageTypeOutSide : 'danger'
      });
      setTimeout(() => { 
        this.setState({ 
          MessageOutSide : '',
          MessageTypeOutSide : ''
          })               
       }, 3000);      
      return;
    }

    var event_start_time = moment(start).format('HH:mm');
   
    var checkDayTime = moment(end).subtract(1, 'minutes').format('HH:mm');
    if (checkDayTime=="23:58"){
      checkDayTime = moment(end).format('HH:mm');
    }


    if(view_type==1){      
      event_start_time =  moment().add(1, 'hours').format('HH:00');
      checkDayTime =   moment().add(1, 'hours').format('HH:59');
    }

    this.setState(prevState => ({
      ...prevState,
      startDate : new Date(event_start_date.replace(/-/g, "/")),
      
      // startDate : new Date(start),
      startTime: event_start_time, 
      endTime: checkDayTime,
      sales_rep_id : slotId,
      rep_name : rep[0],
      rep_pic : rep[1]
    }), ()=>{    
      var start_time = moment(moment(this.state.startDate).format('YYYY-MM-DD')+'T'+this.state.startTime+':01').format('YYYY-MM-DDTHH:mm:ss');
      var end_time = moment(moment(this.state.startDate).format('YYYY-MM-DD')+'T'+this.state.endTime+':00').format('YYYY-MM-DDTHH:mm:ss');
      // alert(start_time);
      this.setState(prevState => ({
        ...prevState,
        eventInfo: 
          {
            ...prevState.eventInfo,
            start_time,
            end_time,
            sales_rep_id : slotId
          }
      }), ()=>{
        this.getOpportunitiesBySalesRep();
        setTimeout(() => { this.setCreateSessionShow(true); }, 1000);
      }); 

    })

    // this.setState({ newschedulerData: schedulerData, newslotId: slotId, newslotName: slotName, newstart: start, newend: end, newtype: type, newitem: item })

  }
  newEvent = () => {
  
    // if(window.confirm(`Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`)){
    console.log(this.state.newschedulerData)
    var schedulerData = this.state.newschedulerData;
    var item = this.state.newitem;
    var start = this.state.newstart;
    var end = this.state.newend;
    var slotId = this.state.newslotId;
    let newFreshId = 0;
    schedulerData.events.forEach((item) => {
      if (item.id >= newFreshId)
        newFreshId = item.id + 1;
    });
    let events = schedulerData.events;
    let opportunity_id = newFreshId + 100;
    let newEvent = {
      id: newFreshId,
      title: 'opportunity ' + opportunity_id,
      start: this.state.newstart,
      end: this.state.newend,
      resourceId: slotId,
      bgColor: '#003665',
      extendedProps: {
        icon: 'ri-check-line'
      }, movable: false,
    };
    events.push(newEvent);
    events[0].id = 0;
    schedulerData.setEvents(events);
    // schedulerData.addEvent(newEvent);
    this.setState({
      viewModel: schedulerData
    })
    this.setCreateSessionShow(false);
    // }

  }
  //   newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
  //     if(window.confirm(`Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`)){

  //         let newFreshId = 0;
  //         schedulerData.events.forEach((item) => {
  //             if(item.id >= newFreshId)
  //                 newFreshId = item.id + 1;
  //         });

  //         let newEvent = {
  //             id: newFreshId,
  //             title: 'New event you just created',
  //             start: start,
  //             end: end,
  //             resourceId: slotId,
  //             bgColor: 'purple'
  //         }
  //         schedulerData.addEvent(newEvent);
  //         this.setState({
  //             viewModel: schedulerData
  //         })
  //     }
  // }

  updateEventStart = (schedulerData, event, newStart) => {
    // if(confirm(`Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`)) {
    //     schedulerData.updateEventStart(event, newStart);
    // }
    this.setState({
      viewModel: schedulerData
    })
  }

  updateEventEnd = (schedulerData, event, newEnd) => {
    // if(confirm(`Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`)) {
    //     schedulerData.updateEventEnd(event, newEnd);
    // }
    this.setState({
      viewModel: schedulerData
    })
  }
  // rescheduleEvent = ()=>{
  //   this.setRescheduleSessionShow(true);

  // }
  moveEvent = () => {
    var schedulerData = this.state.newschedulerData;
    var start = this.state.newstart;
    var end = this.state.newend;
    var slotId = this.state.newslotId;
    var event = this.state.newevent;
    var slotName = this.state.newslotName;
   
    event.bgColor = "#003665";
    event.extendedProps = { icon: "ri-check-line" }
    event.movable = false

    // if(window.confirm(`Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`)) {
    schedulerData.moveEvent(event, slotId, slotName, start, end);
    this.setState({
      viewModel: schedulerData
    })
    this.setRescheduleSessionShow(false);

    // }
  }

  rescheduleSession = (schedulerData, event, slotId, slotName, start, end) => {
    this.setRescheduleSessionShow(true);
    this.setState({ newschedulerData: schedulerData, newslotId: slotId, newslotName: slotName, newstart: start, newend: end, newevent: event })

  }

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.state.events);
      this.setState({
        viewModel: schedulerData
      });

      schedulerContent.scrollLeft = maxScrollLeft - 10;
      
    }
  }

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
   
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.state.events);
      this.setState({
        viewModel: schedulerData
      });

      schedulerContent.scrollLeft = 10;
  
    }
  }

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    
  }

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
   
  }

  toggleExpandFunc = (schedulerData, slotId) => {
    
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  }
  slotClickedFunc = (schedulerData, slot) => {

    // alert(`You just clicked a ${schedulerData.isEventPerspective ? 'task':'resource'}.{id: ${slot.slotId}, name: ${slot.slotName}}`);
  }

  eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {
    let session_start_time = moment(event.start).format("hh:mm a");
    let borderWidth = isStart ? '4' : '0';
    let borderColor = 'rgba(0,139,236,1)', backgroundColor = event.bgColor;
    let titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
    let remix_icon;
    if (event.status == "COMPLETED") {
      remix_icon = "ri-check-double-line";
    } else if (event.status == "CANCELED") {
      remix_icon = "ri-close-line";
    } else if (event.status == "RESCHEDULED") {
      remix_icon = "ri-refresh-line";
    } else if (event.status == "SCHEDULED") {
      remix_icon = "ri-check-line";
    } else if (event.status == "INVITESENT") {
      remix_icon = "ri-question-line";
      mustAddCssClass = "invitesent__class " + mustAddCssClass
    }

    if (!!event.type) {
      backgroundColor = event.bgColor;
    }
    console.log('Basic Cell Data : ',  schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth);
    let divStyle = { backgroundColor: backgroundColor, height: mustBeHeight };
    let dayview_session_height = mustBeHeight + 15;
    if (!agendaMaxEventWidth)
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };

    return <div key={event.id} className={mustAddCssClass}  style={divStyle}>
      {schedulerData.viewType == 1 ? <>
        <span className="f-600">{session_start_time}</span><br />
        <small class={remix_icon}></small> <span style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>{titleText}</span></>
        : <> <small class={remix_icon}></small> <span style={{ marginLeft: '4px', lineHeight: `${dayview_session_height}px` }}>{titleText}</span></>
      }

    </div>;
  }

  eventItemPopoverTemplateResolver = (schedulerData, event, title, start, end, statusColor) => {
    let remix_icon;
   
    var status = "";
    if (event.status == "COMPLETED") {
      remix_icon = "ri-check-double-line";
      status = "Completed";
    } else if (event.status == "CANCELED") {
      remix_icon = "ri-close-line";      
      status = "Cancelled";
    } else if (event.status == "RESCHEDULED") {
      remix_icon = "ri-refresh-line";      
      status = "Rescheduled";
    } else if (event.status == "SCHEDULED") {
      remix_icon = "ri-check-line";      
      status = "Scheduled";
    } else if (event.status == "INVITESENT") {
      remix_icon = "ri-question-line";      
      status = "Invite Sent";
      event.bgColor = "#003366"
    }
    return (
      <React.Fragment>    
        <div className='event_pophover'>
          <span className='top_after'></span>
          <h5 className='mb-2 pb-1 feild-text py-2 word-break-all f-600 f2015'>{title}</h5>
          <i class={remix_icon} style={{color:event.bgColor}}></i> <span className='f-14' style={{color:event.bgColor}}> {status}</span>
          <div style={{lineHeight:"1"}}><i class="ri-time-line color_blue"></i><span className='f-500'> {start.format("hh:mma")} - {end.format("hh:mma")}</span> </div>
        </div>
      </React.Fragment>
   
  );
}

}

export default withDragDropContext(SchedulerCommon)
