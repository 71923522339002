import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faFilter } from '@fortawesome/free-solid-svg-icons';

import profile2x from "../../images/default-avatar.png";
import { Link } from "react-router-dom";
import CustomSelect from "./elements/customSelect";
import axios from '../apiConnection/axios';

import TableContextMenu from "./elements/tableContextMenu";
import Modal from "./modals/modal";
import $ from 'jquery';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Utility from "../../components/utility";
const utilityObj = new Utility;
export default class userManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTeamManager: true,
      showTeamOption: true,
      showDivisionOption: true,
      first_name: "",
      last_name: "",
      email: "",
      role_id: "",
      team_id: "",
      division_id: "",
      phone: "",
      errors: {
        first_name: '',
        last_name: ''
      },
      user_status: ["Active", "Inactive", "Locked", "Invite Sent", "Invite Expired"],
      //user_status: [],
      user_role: [],
      sales_division: [],
      organization_structure: [],
      users: {},
      users_status_count: {},
      users_role_count: {},
      user_detail: {},
      user_org_detail: {},
      edit_user_detail: {},
      addUserShow: false,
      profileShow: false,
      deactivateUsershow: false,
      activateUsershow: false,
      inviteSendUsershow: false,
      selectedUserName: "",
      division_manager: "",
      sales_manager: "",
      stored_division_manager: "",
      stored_sales_manager: "",
      defaultdivisionSelectText: "",
      divisionList: [],
      defaultyearSelectText: "2021",
      yearList: [
        { id: 1, name: "2021" },
        { id: 2, name: "2022" },
        { id: 3, name: "2023" },
        { id: 4, name: "2024" },
        { id: 5, name: "2025" },
      ],
      defaultuserSelectText: "",
      userList: [
        { id: 1, name: "Vince Simon" },
        { id: 2, name: "Joseph Gates" },
        { id: 3, name: "Clark Winson" },
      ],
      defaultteamSelectText: "",
      teamList: [],
      defaultroleSelectText: "Select Role",
      roleList: [
        // { id: 2, name: "Division Manager", value: 2 },
        // { id: 3, name: "Sales Manager", value: 3 },
        { id: 4, name: "Sales Rep", value: 4 },
      ],
      orgName: "",
      refresh_filter: "",
      team_filters: []
      // division_disable:"disabled",
      // team_disable:"disabled"

    };
    this.getUsers = this.getUsers.bind(this);
    this.getUsersCount = this.getUsersCount.bind(this);
    this.getOrgStructure = this.getOrgStructure.bind(this);
    this.setProfileShow = this.setProfileShow.bind(this);
    this.setDeactivateUserShow = this.setDeactivateUserShow.bind(this);
    this.setActivateUserShow = this.setActivateUserShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.getAllDivisionsTeams = this.getAllDivisionsTeams.bind(this);
    this.userProfile = this.userProfile.bind(this);
    this.resetFilters = this.resetFilters.bind(this);


  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.checkUserManagmentRedirection();
    this.getUsers();
    this.getOrgStructure();
    this.getAllDivisionsTeams();
    this.getUsersCount();
    this.getOrganizationData();
  }
  getOrganizationData = async () => {
    await axios.get(`organizations/get-organization`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({ orgName: res.data.data[0].org_name })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  checkUserManagmentRedirection = async () => {
    await axios.get(`users/check-users-management-redirection`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 201) {
          window.location.href = "./initiate-user-management";
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  setDeactivateUser = async () => {
    await axios.post(`users/update-status`, { "user_id": this.state.selectedUserId, "status": "0" }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      console.log(res);
      if (res.data.code === 200) {
        setTimeout(() => { this.getUsers(); this.setState({ "deactivateUsershow": false }); }, 3000);
        this.setState({ Message: res.data.message, MessageType: 'success', });
        // this.setState({"organization_structure":res.data.data});
        //    console.log("get org structure api integration in react",this.state.organization_structure);
      }
      else {
        this.setState({
          Message: "Server Error.",
          MessageType: 'danger',
          fileextractDone: 0,
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })

  }
  setActivateUser = async () => {
    await axios.post(`users/update-status`, { "user_id": this.state.selectedUserId, "status": "1" }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      console.log(res);
      if (res.data.code === 200) {
        setTimeout(() => { this.getUsers(); this.setState({ "activateUsershow": false }); }, 3000);
        this.setState({ Message: res.data.message, MessageType: 'success', });
        // this.setState({"organization_structure":res.data.data});
        //    console.log("get org structure api integration in react",this.state.organization_structure);
      }
      else {
        this.setState({
          Message: "Server Error.",
          MessageType: 'danger',
          fileextractDone: 0,
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })

  }

  getOrgStructure = async () => {
    await axios.get(`organizations/get-organization-structure`, {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      console.log(res);
      if (res.data.code === 200) {
        this.setState({ "organization_structure": res.data.data });
        var organization_structure = res.data.data;
        console.log('My Data org', organization_structure[0])
        var divisions_list = [];
        if (organization_structure[0]) {
          let divisions = organization_structure[0].child;
          for (var i = 0; i < divisions.length; i++) {
            var obj = {};
            obj.id = divisions[i].id;
            obj.name = divisions[i].node_name;
            obj.value = divisions[i].id;
            if (divisions[i].user.length > 0) {
              obj.user_name = divisions[i].user[0].first_name + " " + divisions[i].user[0].last_name;
            } else {
              obj.user_name = "";
            }
            if (i == 0) {
              // this.setState({ defaultdivisionSelectText: obj.name });
              this.setState({ defaultdivisionSelectText: "Select Division" });
              //  this.setState({ division_id: divisions[i].id });
              this.setState({ division_id: "" });
              var division_manager = "";
              if (divisions[i].user.length > 0) {
                division_manager = divisions[i].user[0].first_name + " " + divisions[i].user[0].last_name;
                // this.setState({ division_manager: division_manager });
                this.setState({ division_manager: "" });

                this.setState({ stored_division_manager: division_manager });

                // if(organization_structure[0].user_detail[0].role_id == 5){
                // this.setState({ stored_division_manager: "" });
                // }
                // console.log(this.setState({ stored_sales_manager: sales_manager }))

              }
              var team_data = divisions[i].child;
              var team_list = [];
              for (var k = 0; k < team_data.length; k++) {
                var obj1 = {};
                obj1.id = team_data[k].id
                obj1.name = team_data[k].node_name;
                obj1.value = team_data[k].id;
                if (team_data[i].user.length > 0) {
                  obj1.user_name = team_data[i].user[0].first_name + " " + team_data[i].user[0].last_name;
                } else {
                  obj1.user_name = "";
                }
                if (k == 0) {
                  // this.setState({ defaultteamSelectText: obj1.name });
                  // this.setState({ team_id: obj1.id });
                  this.setState({ defaultteamSelectText: "Select Team" });
                  this.setState({ team_id: "" });
                  var sales_manager = "";
                  if (team_data[i].user[0]) {
                    sales_manager = team_data[i].user[0].first_name + " " + team_data[i].user[0].last_name;
                    this.setState({ sales_manager: "" });
                    //  this.setState({ sales_manager: sales_manager });
                    this.setState({ stored_sales_manager: sales_manager });
                    console.log(this.setState({ stored_sales_manager: sales_manager }))
                    // if(organization_structure[0].user_detail[0].role_id == 5){
                    //   this.setState({ stored_division_manager: "" });
                    //   }
                  }
                }
                team_list.push(obj1);
              }
              // this.setState({ teamList: team_list });
              this.setState({ teamList: [] });

            }
            divisions_list.push(obj);
          }
          this.setState({ divisionList: divisions_list });


          console.log("ORG ^ division list", this.state.divisionList);
        }
        console.log("get org structure api integration in react", this.state.organization_structure);
      }
      else {
        this.setState({
          Message: "Server Error.",
          MessageType: 'danger',
          fileextractDone: 0,
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })


  }


  getAllDivisionsTeams = async () => {
    await axios.get(`/organizations/get-all-divisions-teams`, {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      if (res.data.code === 200) {
        this.setState({ "all_division_teams": res.data.data });
      }
      else {
        this.setState({
          Message: "Server Error.",
          MessageType: 'danger',
          fileextractDone: 0,
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })
  }

  getUsersCount = async () => {
    await axios.get(`/users/get-user-status-roles-count`, {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      console.log(res);
      if (res.data.code === 200) {
        this.setState({ users_status_count: res.data.data[0].status });
        this.setState({ users_role_count: res.data.data[1].roles });

      }
      else {
        this.setState({
          Message: "Server Error.",
          MessageType: 'danger',
          fileextractDone: 0,
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })
  }

  getUsers = async () => {
    await axios.post(`/users/userslist`, { "auth_user_id": 1, "user_status_filter": this.state.user_status, "user_role_filter": this.state.user_role, "team_filter": this.state.team_filters }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      console.log(res);
      if (res.data.code === 200) {
        this.setState({ users: res.data.data });
        console.log("get users api integration in react", this.state.users);
      }
      else {
        this.setState({
          Message: "Server Error.",
          MessageType: 'danger',
          fileextractDone: 0,
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })


  }

  componentWillUnmount() {
    $("body").removeClass("open-filter");
  }

  setInviteSendUserShow = (newValue) => {
    this.setState({ inviteSendUsershow: newValue });
  }

  sentInvite = async (status, row) => {
    const sentInviteinfo = {
      email: row.email,
      redirect_url: process.env.REACT_APP_PASSWORD_CREATE_LINK,
    }
    console.log(sentInviteinfo);
    await axios.post(`/users/send-welcome`, sentInviteinfo, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        if (res.data.code === 200) {
          this.getUsers();
          this.setInviteSendUserShow(true)
        } else {

        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  sendResetPasswordLink = async (email) => {
    utilityObj.loaderView("show");
    const sentResetPAsswordinfo = {
      email: email,
      redirect_url: process.env.REACT_APP_PASSWORD_CREATE_LINK,
    }
    console.log(sentResetPAsswordinfo);
    await axios.post(`/users/send-reset-password-link`, sentResetPAsswordinfo, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        if (res.data.code === 200) {
          utilityObj.loaderView("hide");
          this.setState({
            Message: "Password reset link was sent to user.",
            MessageType: 'success',
          });


        } else {

        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  inviteSendUser = () => {
    return (<>
      <div className="text-center my-3">
        <i className="iconmoon icon-check check-successfully"></i>
        <p className="mb-0 mt-4">
          Invite resent successfully. </p>
        <div className="justify-content-center mt-4">
          <button type="button" className="btn dark-btn mx-2" onClick={() => this.setInviteSendUserShow(false)}>Ok</button>
        </div>
      </div>
    </>);
  }

  setAddUserShow = (newValue) => {
    this.setState({ addUserShow: newValue });
    if (newValue == false) {
      this.setState({ user_name: "" });
      this.state.last_name = "";
      this.state.first_name = "";
      this.state.phone = "";
      this.state.email = "";
      this.state.sales_manager = "";
      this.state.division_manager = "";

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    var error_message = "";
    var is_validation_pass = true;
    let postBodyData = {
      "first_name": this.state.first_name,
      "last_name": this.state.last_name,
      "email": this.state.email,
      "phone": this.state.phone,
      "role_id": this.state.role_id,
      "division_id": this.state.division_id,
      "team_id": this.state.team_id
      // "role_id":3,
      //"division_id":2,
      //"team_id":3
    }
    if (postBodyData.first_name === "") {
      is_validation_pass = false;
      error_message = error_message + " First Name is required.";
    }
    if (postBodyData.last_name === "") {
      is_validation_pass = false;
      error_message = error_message + " Last Name is required.";
    }
    if (postBodyData.role_id === 4 || postBodyData.role_id === 3) {
      if (postBodyData.division_id === "" || postBodyData.team_id === "") {
        is_validation_pass = false;
        error_message = error_message + " Division and Team is required.";
      }
    }
    if (postBodyData.role_id === 2) {
      if (postBodyData.division_id === "") {
        is_validation_pass = false;
        error_message = error_message + "Division is required.";
      }
    }
    if (postBodyData.email === "") {
      is_validation_pass = false;
      error_message = error_message + " Email is required.";
    } else {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(postBodyData.email)) {
        is_validation_pass = false;
        error_message = error_message + " Invalid Email";
      }
    }
    if (postBodyData.phone === "") {
      is_validation_pass = false;
      error_message = error_message + " Phone is required.";
    } else {
      var pattern = new RegExp(/^[0-9]{9,14}$/g);
      if (!pattern.test(postBodyData.phone)) {
        is_validation_pass = false;
        error_message = error_message + " Invalid Phone.";
      }
    }
    if (postBodyData.role_id == "") {
      is_validation_pass = false;
      error_message = error_message + " Select Role.";
    } if (postBodyData.division_id == "") {
      is_validation_pass = false;
      error_message = error_message + " Select Division.";
    } if (postBodyData.team_id == "") {
      is_validation_pass = false;
      error_message = error_message + " Select Team.";
    }

    if (is_validation_pass) {
      console.log("postBodyData", postBodyData);
      await axios.post(`users/add-manual`, postBodyData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        }
      }).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState({
            Message: "User created successfully. Invitation sent.",
            MessageType: 'success',
          });

          this.getUsers();
          setTimeout(
            () => {
              this.setState({ "addUserShow": false });
              this.setState({
                showTeamOption: true,
                showDivisionOption: true,
                showTeamManager: true,
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                user_name: '',
                role_id: 4,
                division_id: '',
                team_id: '',
                Message: "",
                MessageType: null
              });
            },
            2000);

        }
        else {
          this.setState({
            Message: res.data.message,
            MessageType: 'danger',
          });
        }
      })
        .catch((error) => {
          console.log(error)
        })
    } else {
      this.setState({
        Message: error_message,
        MessageType: 'danger',
      });
    }
  }

  handleEditSubmit = async () => {

    if (this.state.user_detail.role_id == 5) {
      var roalId = this.state.role_id
    }
    else {
      var roalId = this.state.user_detail.role_id
    }

    let postBodyData = {
      "first_name": this.state.user_detail.first_name,
      "last_name": this.state.user_detail.last_name,
      "email": this.state.user_detail.email,
      "phone": this.state.user_detail.mobile,
      "role_id": roalId,
      "division_id": this.state.division_id,
      "team_id": this.state.team_id,
      "edit_user_id": this.state.selectedUserId
    }
    console.log("postBodyData", postBodyData);
    await axios.post(`users/add-manual`, postBodyData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }).then(res => {
      console.log(res);
      if (res.data.code === 200) {
        this.getUsers();
        this.setProfileShow(false);
        setTimeout(() => {
          this.setEditProfileShow(false);
        }, 3000);
        this.setState({
          Message: res.data.message,
          MessageType: 'success',
          fileextractDone: 0,
        });
      }



      else {
        this.setState({
          Message: res.data.message,
          MessageType: 'danger',
          fileextractDone: 0,
        });
      }
    })
      .catch((error) => {
        console.log(error)
      })
  }
  handleChange(event) {
    if (event.target.value.trim().length == 0) {
      event.target.value = "";
    }
    console.log(event.target);
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
    switch (name) {
      case 'first_name':

        var pattern = new RegExp(/^[0-9a-zA-Z\s]*$/i);
        if (!pattern.test(value)) {
          this.setState({
            Message: "First name should be characters only.",
            MessageType: 'danger',
          });
        }
        else if (value.length > 50){
          this.setState({
            Message: "First name should be max 50 characters.",
            MessageType: 'danger',
          });
        }
         else {
          this.setState({
            Message: "",
            MessageType: '',
          });
        }
        break;

      case 'last_name':
        var pattern = new RegExp(/^[0-9a-zA-Z\s]*$/i);
        if (!pattern.test(value)) {
          this.setState({
            Message: "Last name should be characters only.",
            MessageType: 'danger',
          });
        } 
        else if (value.length > 50){
          this.setState({
            Message: "Last name should be max 50 characters.",
            MessageType: 'danger',
          });
        }
        else {
          this.setState({
            Message: "",
            MessageType: '',
          });

        }
        break;



      case 'email':
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(value)) {
          this.setState({
            Message: "Invalid Email",
            MessageType: 'danger',
          });
        } else {
          this.setState({
            Message: "",
            MessageType: '',
          });
          var user_name = value.split('@')[0];
          console.log("USERNAME", user_name);
          this.setState({ user_name: user_name });
        }
        break;

      case 'phone':

        var pattern = new RegExp(/^[0-9]{11,14}$/g);
        if (!pattern.test(value)) {
          this.setState({
            Message: "Phone number cannot be less than 11 digits and cannot exceed 14 digits.",
            MessageType: 'danger',
          });
        } else if ((value.length >= 15) || (value.length < 11)) {
          this.setState({
            Message: "Phone number cannot be less than 11 digits and cannot exceed 14 digits.",
            MessageType: 'danger',
          });

        } else {
          this.setState({
            Message: "",
            MessageType: '',
          });
        }
        break;

      default:
        break;
    }
    console.log("FORM DATA", this.state);
  }

  editHandleChange(event) {
    console.log(event.target);
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ edit_user_detail: { [name]: value } });
    switch (name) {
      case 'email':
        var user_name = value.split('@')[0];
        this.setState({ user_name: user_name });
        break;


      default:
        break;
    }
    console.log("FORM DATA", this.state);
  }

  handleRoleClick = (ele) => {
    this.setState({ role_id: ele });

    if (ele == 4) {
      this.setState({ showTeamOption: true, showDivisionOption: true, showTeamManager: true });
    } else if (ele == 3) {
      this.setState({ showTeamOption: true, showDivisionOption: true, showTeamManager: false });
      this.setState({ team_id: "", defaultteamSelectText: "Select Team", sales_manager: "" });
    } else if (ele == 2) {
      this.setState({ showTeamOption: false, showDivisionOption: false, showTeamManager: false });
      this.setState({ team_id: "", defaultteamSelectText: "Select Team", sales_manager: "" });
    }
  };

  handleDivisionClick = async (ele) => {
    var div_list = this.state.divisionList;
    console.log("DIVISION LIST:", div_list);
    console.log("DIVISION LIST:", ele);
    this.setState({ division_id: ele });
    for (var j = 0; j < div_list.length; j++) {
      if (ele === div_list[j].id) {
        this.setState({ default_edit_divisionSelectText: div_list[j].name, division_manager: div_list[j].user_name });
      }
    }
    this.setState({ default_edit_teamSelectText: "Select Team" });
    this.setState({ sales_manager: "-- " });

    var org_structure_data = this.state.organization_structure;
    for (var i = 0; i < org_structure_data.length; i++) {
      if (org_structure_data[0]) {
        let divisions = org_structure_data[0].child;

        for (var i = 0; i < divisions.length; i++) {
          if (divisions[i].id == ele) {
            var team_data = divisions[i].child;
            var team_list = [];
            console.log("@TEAM DATA", team_data);

            for (var k = 0; k < team_data.length; k++) {

              var obj1 = {};
              obj1.id = team_data[k].id
              obj1.name = team_data[k].node_name;
              obj1.value = team_data[k].id;
              // if (team_data[k].user.length > 0) {
              //   obj1.user_name = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
              //   if (k == 0) {
              //     this.setState({ defaultteamSelectText: obj1.name });
              //     this.setState({ defaultteamSelectText: "Select Team" });
              //     var sales_manager = "";
              //     if (team_data[k].user[0]) {
              //       sales_manager = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
              //       this.setState({ sales_manager: sales_manager });
              //       this.setState({ sales_manager: "" });
              //     }
              //   }
              // }
              team_list.push(obj1);
            }
            this.setState({ teamList: team_list });

          }

        }

      }
    }


  }
  handleTeamClick = (ele) => {
    this.setState({ team_id: ele });
    this.setState({ sales_manager: "TEAM MANAGER " + ele });


    var org_structure_data = this.state.organization_structure;
    for (var i = 0; i < org_structure_data.length; i++) {
      if (org_structure_data[0]) {
        let divisions = org_structure_data[0].child;

        console.log("Division ADAT", divisions);
        for (var j = 0; j < divisions.length; j++) {
          var team_data = divisions[j].child;
          console.log("TEAM ADAT", team_data);

          for (var k = 0; k < team_data.length; k++) {
            console.log("team _data ele", team_data[k]); console.log("elee", ele);
            this.setState({ default_edit_teamSelectText: team_data[k].node_name });
            if (team_data[k].id === ele) {
              var sales_manager = "";
              if (team_data[k].user[0]) {
                sales_manager = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                //set sales manager 
                this.setState({ sales_manager: sales_manager });
              } else {
                this.setState({ sales_manager: "" });
              }
            }
          }
        }

      }
    }
  }

  addUser = () => {
    const { orgName, showTeamOption, showDivisionOption, showTeamManager, first_name, last_name, email, phone, user_name, role_id, division_id, division_manager, team_id, sales_manager, Message, MessageType } = this.state;
    return (<>
      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          {((Message) && (Message !== null)) ?
            (
              <div className={`alert alert-${MessageType}`}>
                {Message}
              </div>
            ) : (``)
          }
          <div className="row">
            <div className="col-lg-6">
              <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Personal
                Details</span></div>
              <div className="clearfix px-2">
                <div className="profile-pic float-sm-right">
                  <div className="img-wrap">
                    <img className="profile-pic-img" id="editprofile" src={profile2x} alt="profile" />
                  </div>
                  <div className="p-image">
                    <i className="fa fa-camera upload-button" id="editprofilebtn"></i>
                    <input className="file-upload" id="editprofilefile" type="file" accept="image/*" />
                  </div>
                </div>
                <div className="profile-info float-sm-left">
                  <div className="row mt-4 mt-sm-0">
                    <div className="col form-group">
                      <label htmlFor="first_name" className="mb-0">First Name</label>
                      <input type="text" className="form-control f-600 f2015" name="first_name" value={first_name} defaultValue="" id="first_name" onChange={this.handleChange}
                        placeholder="First Name" />
                      {/* <span className='error'>kjlkld</span>  */}
                    </div>
                    <div className="col form-group">
                      <label htmlFor="last_name" className="mb-0">Last Name</label>
                      <input type="text" className="form-control f-600 f2015" name="last_name" value={last_name} defaultValue="" id="last_name" onChange={this.handleChange} placeholder="Last Name" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Email ID</label>
                    <input type="email" name="email" id="email" onChange={this.handleChange} className="form-control f-600 f2015" value={email} defaultValue=""
                      placeholder="Email" />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Mobile Number</label>
                    <input type="text" className="form-control f-600 f2015" name="phone" id="phone" onChange={this.handleChange} value={phone} defaultValue=""
                      placeholder="1-252-266-3259" />
                  </div>
                </div>
              </div>
              <div className="form-head  f2015 my-3 clearfix" ><span
                className="bg-white d-inline-block f-600 pr-3">Login Details</span></div>
              <div className="form-group px-2">
                <label className="mb-0">User Name</label>
                <input type="text" className="form-control f-600 f2015" id="user_name" disabled="disabled" value={user_name} defaultValue="" placeholder="User Name" />
              </div>
              {/* <div className="form-group clearfix px-2">
                  <a className="color_blue py-3 underline f2015" href="javascript:;">Send Reset Password Link</a>
                </div> */}

            </div>
            <div className="col-lg-6">
              <div className="form-head mb-3 f2015  clearfix"><span
                className="bg-white d-inline-block f-600 pr-3">Organization Details</span></div>
              <div className="form-group px-2">
                <label className="mb-0">Organization Name</label>
                <div className="feild-text py-3 f-600 f2015">
                  {orgName}
                </div>
              </div>
              <div className="form-group px-2">
                <label className="mb-0">User Role</label>
                <CustomSelect
                  name="role_id"
                  className="form-control f-600 text-left"
                  defaultText={this.state.defaultroleSelectText}
                  optionsList={this.state.roleList}
                  onSelect={this.handleRoleClick}

                />

              </div>
              <div className="form-group px-2">
                <label className="mb-0">Sales Division</label>
                <CustomSelect name="division_id"
                  className="form-control f-600 text-left"
                  defaultText={this.state.defaultdivisionSelectText}
                  optionsList={this.state.divisionList}
                  onSelect={this.handleDivisionClick}
                  disabled="disabled"
                />
              </div>
              {(showDivisionOption) ? (
                <div className="form-group px-2">
                  <label className="mb-0">Division Manager</label>
                  <input type="text" disabled="disabled" className="form-control f-600 W-100 f2015" value={division_manager} />
                </div>
              ) : null}
              {showTeamOption ? <>
                <div className="form-group px-2">
                  <label className="mb-0">Sales Team</label>
                  <CustomSelect name="team_id"
                    className="form-control f-600 text-left"
                    defaultText={this.state.defaultteamSelectText}
                    optionsList={this.state.teamList}
                    onSelect={this.handleTeamClick}
                  />
                </div>
              </> : null}
              {(showTeamManager) ? <>
                <div className="form-group px-2">
                  <label className="mb-0">Sales Manager</label>
                  <input type="text" disabled="disabled" className="form-control f-600 W-100 f2015" value={sales_manager} />
                </div></> : null}
            </div>
          </div>
        </div>
        <div className="modal-footer border-0 justify-content-center">
          {/* <button type="button" className="btn dark-btn mx-2" onClick={() => this.setPreviewProfileShow(false)}>Save</button> */}
          <button type="submit" className="btn dark-btn mx-2">Save</button>

          <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.cancelPreviewProfileShow(false)}>Cancel</button>
        </div>
      </form>

    </>);
  }


  setProfileShow = async (newValue, selectedUserName, selectedUserId) => {
    if (selectedUserId != undefined) {
      this.setState({ selectedUserId: selectedUserId });

      await axios.post(`users/get-profile-org-structure`, { "user_id": selectedUserId }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        }
      }).then(res => {
        console.log(res);
        if (res.data.code === 200) {

          console.log("user_detail@", res.data.data[1].organization_details[0]);
          this.setState({ user_detail: res.data.data[0].user_detail[0] });
          this.setState({ user_org_detail: res.data.data[1].organization_details[0] });
          var org_structure_id = res.data.data[0].org_structure_id;
          var organization_structure = this.state.organization_structure;
          if (organization_structure[0]) {
            let divisions = organization_structure[0].child;
            var divisions_list = [];
            for (var i = 0; i < divisions.length; i++) {
              var team_data = divisions[i].child;
              var team_list = [];
              for (var k = 0; k < team_data.length; k++) {
                if (team_data[k].id == org_structure_id) {
                  var division_id = divisions[i].id;
                  var division_name = divisions[i].node_name;
                  var division_manager = divisions[i].user[0].first_name + " " + divisions[i].user[0].last_name;
                  var team_id = team_data[k].id
                  var team_name = team_data[k].node_name;
                  var team_manager = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                  this.setState({ defaultdivisionSelectText: division_name });
                  this.setState({ defaultteamSelectText: team_name });
                  this.setState({ division_manager: division_manager });
                  this.setState({ sales_manager: team_manager });
                }
              }
            }
          }
        }
        else {
          this.setState({
            Message: res.data.message,
            MessageType: 'danger',
            fileextractDone: 0,
          });
        }
      })
        .catch((error) => {
          console.log(error)
        })
    }
    this.setState({ profileShow: newValue });

    this.setState({
      Message: "",
      MessageType: '',
    });
  }

  userProfile = () => {
    const { user_detail, user_org_detail, orgName, Message, MessageType } = this.state;
    console.log("USER DETAIL : ", user_detail);
    console.log("USER user_org_detail : ", user_org_detail);
    var division_name = "";
    var division_manager = "";
    var sales_team = "";
    var sales_manager_name = "";
    if (user_org_detail) {
      if (user_org_detail.hasOwnProperty("division")) {
        division_name = user_org_detail.division.node_name;
      }
      if (user_org_detail.hasOwnProperty("division_manager")) {
        if (user_org_detail.division_manager.length > 0) {
          division_manager = user_org_detail.division_manager[0].first_name + " " + user_org_detail.division_manager[0].last_name;
        }
      }

      if (user_org_detail.hasOwnProperty("team")) {
        sales_team = user_org_detail.team.node_name;
        if (user_org_detail.team.user.length > 0) {
          sales_manager_name = user_org_detail.team.user[0].first_name + " " + user_org_detail.team.user[0].last_name;
        }
      }
    }
    return (<>
      {((Message) && (Message !== null)) ?
        (
          <div className={`alert alert-${MessageType}`}>
            {Message}
          </div>
        ) : (``)
      }

      <form>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Personal
              Details</span></div>

            <div className="clearfix px-2">
              <div className="profile-pic float-sm-right ">
                <div className="img-wrap mr-0 mx-auto">
                  <img className="profile-pic-img" src={profile2x} alt="profile" />
                </div>
              </div>
              <div className="profile-info float-sm-left">
                <div className="form-group">
                  <label className="mb-0">Full Name</label>

                  <div className="feild-text py-3 f-600 f2015">
                    {user_detail ? user_detail.first_name + " " + user_detail.last_name : ""}
                  </div>

                </div>
                <div className="form-group">
                  <label className="mb-0">Email ID</label>
                  <div className="feild-text py-3 f-600 f2015">

                    {user_detail ? user_detail.email : ""}
                  </div>
                </div>
                <div className="form-group">
                  <label className="mb-0">Mobile Number</label>
                  <div className="feild-text py-3 f-600 f2015">
                    {user_detail ? user_detail.mobile : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-head my-3 f2015 clearfix"><span
              className="bg-white d-inline-block f-600 pr-3">Login Details</span></div>
            <div className="form-group px-2">
              <label className="mb-0">User Name</label>
              <div className="feild-text py-3 f-600 f2015">
                {user_detail ? user_detail.username : ""}
              </div>
            </div>
            <div className="form-group clearfix px-2">

              {user_detail.status != 0 ? <a className="color_blue py-3 underline f2015" onClick={() => this.sendResetPasswordLink(user_detail.email)}>Send Reset Password Link</a> : ""}


            </div>

          </div>
          <div className="col-lg-6 position-relative">
            <div className="form-head mb-3 f2015  clearfix"><span
              className="bg-white d-inline-block f-600 pr-3">Organization Details</span></div>
            <div className="form-group px-2">
              <label className="mb-0">Organization Name</label>
              <div className="feild-text py-3 f-600 f2015">
                {orgName}
              </div>
            </div>
            <div className="form-group px-2">
              <label className="mb-0">User Role</label>
              <div className="feild-text py-3 f-600 f2015">
                {division_name ? user_detail ? user_detail.role_id == 2 ? "Company Admin" : user_detail.role_id == 3 ? "Sales Manager" : user_detail.role_id == 4 ? "Sales Rep" : "" : "" : user_detail.role_id == 5 ? "Not Assigned" : "CEO"}
              </div>
            </div>
            {division_name ?
              <div className="form-group px-2">
                <label className="mb-0">Sales Division</label>
                <div className="feild-text py-3 f-600 f2015">
                  {division_name}
                </div>
              </div> : null}
            {division_manager && user_detail.role_id != 2 ?
              <div className="form-group px-2">
                <label className="mb-0">Division Manager</label>
                <div className="feild-text py-3 f-600 f2015">
                  {division_manager != "" ? division_manager : "--"}
                </div>
              </div>
              : <></>}
            {sales_team && user_detail.role_id != 2 ?
              <div className="form-group px-2">
                <label className="mb-0">Sales Team</label>
                <div className="feild-text py-3 f-600 f2015">
                  {sales_team}
                </div>
              </div> : null}
            {user_detail.role_id == 4 ?
              <div className="form-group px-2">
                <label className="mb-0">Sales Manager</label>
                <div className="feild-text py-3 f-600 f2015">
                  {sales_manager_name != "" ? sales_manager_name : "--"}
                </div>
              </div> : <></>}



            {user_detail.role_id == 4 || user_detail.role_id == 5 ?
              <div className="d-block color_blue text-right f2015 px-2 pb-2 rightBottomBnt">
                <small className="iconmoon icon-edit2 f2015 editicon"></small>&nbsp;
                <a className="color_blue underline " id="closeProfile" onClick={() => this.setEditProfileShow(true, user_detail.id)}>Edit
                  Details   </a>
              </div> :
              ``}


          </div>
        </div>
      </form>
    </>

    );
  }

  setEditProfileShow = async (newValue, selectedUserId) => {
    console.log("@EDIT USER ID:", selectedUserId);
    this.setState({ edit_user_id: selectedUserId }, async () => {
      this.setState({
        Message: "",
        MessageType: '',
      });
      await axios.post(`users/get-profile-org-structure`, { "user_id": selectedUserId }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        }
      }).then(res => {
        console.log(res);
        if (res.data.code === 200) {

          this.setState({ "edit_user_detail": res.data.data[0] });
          var user_details = res.data.data[0].user_detail[0];

          this.setState({ first_name: user_details.first_name });
          this.setState({ last_name: user_details.last_name });
          this.setState({ email: user_details.email });
          this.setState({ mobile: user_details.mobile });
          this.setState({ user_name: user_details.username });
          this.setState({ user_org_detail: {} });
          if (user_details.role_id == 5) {
            console.log("HEY NOT ASSIGNEDP");
            this.setState({ default_edit_teamSelectText: "Select Team", default_edit_divisionSelectText: "Select Division", sales_manager: "--", division_manager: "--" });
          } else {
            console.log("HEY SALES REP");
            var user_org_detail = res.data.data[1].organization_details;
            this.setState({ user_org_detail: res.data.data[1].organization_details });
            // console.log("ORG DETAIL:",res.data.data[1].organization_details);
            // console.log("defaultteamSelectText:",user_org_detail[0].team.node_name);
            // console.log("defaultdivisionSelectText:",user_org_detail[0].division.node_name);
            // console.log("sales_manager:",user_org_detail[0].team.user[0].first_name+" "+user_org_detail[0].team.user[0].last_name);
            // console.log("division_manager:",user_org_detail[0].division_manager[0].first_name+" "+user_org_detail[0].division_manager[0].last_name);
            this.setState({ default_edit_teamSelectText: user_org_detail[0].team.node_name });
            this.setState({ default_edit_divisionSelectText: user_org_detail[0].division.node_name })
            if (user_org_detail[0].team.user.length > 0) {
              this.setState({ sales_manager: user_org_detail[0].team.user[0].first_name + " " + user_org_detail[0].team.user[0].last_name });
            } else {
              this.setState({ sales_manager: "--" });
            }
            if (user_org_detail[0].division_manager.length > 0) {
              this.setState({ division_manager: user_org_detail[0].division_manager[0].first_name + " " + user_org_detail[0].division_manager[0].last_name });
            } else {
              this.setState({ division_manager: "--" });
            }
          }

          var organization_structure = this.state.organization_structure;

          console.log("user_detail", user_details); console.log("org_detail", organization_structure[0]);
          // if ((user_details.role_id!==5) && (organization_structure[0])) {
          if (organization_structure[0]) {
            var org_structure_id = res.data.data[1].organization_details[0].org_structure_id;
            let divisions = organization_structure[0].child;
            var divisions_list = [];
            for (var i = 0; i < divisions.length; i++) {
              var team_data = divisions[i].child;
              var team_list = [];

              for (var k = 0; k < team_data.length; k++) {
                var team_object = {};
                if (team_data[k].id == org_structure_id) {
                  var division_id = divisions[i].id;
                  var division_name = divisions[i].node_name;
                  // var division_manager = divisions[i].user[0].first_name + " " + divisions[i].user[0].last_name;
                  var team_id = team_data[k].id
                  var team_name = team_data[k].node_name;
                  // var team_manager = team_data[k].user[0].first_name + " " + team_data[k].user[0].last_name;
                  // this.setState({ defaultdivisionSelectText: division_name });
                  // this.setState({ defaultteamSelectText: team_name });
                  // this.setState({ division_manager: division_manager });
                  // this.setState({ sales_manager: team_manager });
                  team_object.id = team_id;
                  team_object.name = team_name;
                  team_object.value = team_id;
                  team_list.push(team_object);
                }

              }

              console.log("@teamlist : ", team_list);
              this.setState({ teamList: team_list });
            }
          }
          else {
            this.setState({ division_name: "Select Division" });
            this.setState({ sales_team: "Select Team" });
            this.setState({ stored_division_manager: "" });
            this.setState({ stored_sales_manager: "" });
          }

          // setTimeout(
          //   () => {
          //     alert("500 delayed");
          //     this.setState({ editprofileShow: newValue });
          //   },
          //   50);
          this.setState({ editprofileShow: newValue });

        } else {
          this.setState({
            Message: res.data.message,
            MessageType: 'danger',
            fileextractDone: 0,
          });
          this.setState({ editprofileShow: newValue });
        }
      })
        .catch((error) => {
          console.log(error)
          this.setState({ editprofileShow: newValue });

        })

    });

    if (newValue === true) {
      this.setProfileShow(false);
    } else {
      // this.setProfileShow(true);
    }

  }
  setPreviewProfileShow = (newValue) => {
    this.handleSubmit();
    this.setState({ editprofileShow: newValue });
    this.setState({ addprofileShow: false });
    this.setState({ addUserShow: false });
    if (newValue === true) {
      this.setProfileShow(false);
    } else {
      this.setProfileShow(true);
    }
  }
  cancelPreviewProfileShow = (newValue) => {
    this.setState({ addprofileShow: false });
    this.setState({ addUserShow: false });
    if (newValue === true) {
      this.setProfileShow(false);
    } else {
      this.setProfileShow(false);
      if (newValue == false) {
        this.setState({ user_name: "" });
        this.state.last_name = "";
        this.state.first_name = "";
        this.state.phone = "";
        this.state.email = "";
        this.state.division_manager = "";
        this.state.sales_manager = "";
      }
    }
  }
  // edituserProfile = () => {
  //   const { showTeamOption, showDivisionOption, showTeamManager, orgName, first_name, last_name, email, mobile, user_name, defaultdivisionSelectText, defaultteamSelectText, division_manager, sales_manager, Message, MessageType } = this.state;
  //   return (
  //     <form>
  //       <div className="modal-body">
  //         {((Message) && (Message !== null)) ?
  //           (
  //             <div className={`alert alert-${MessageType}`}>
  //               {Message}
  //             </div>
  //           ) : (``)
  //         }
  //         <div className="row">
  //           <div className="col-lg-6">
  //             <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Personal
  //               Details</span></div>
  //             <div className="clearfix px-2">
  //               <div className="profile-pic float-sm-right">
  //                 <div className="img-wrap">
  //                   <img className="profile-pic-img" id="editprofile" src={profile2x} alt="profile" />
  //                 </div>
  //                 <div className="p-image">
  //                   <i className="fa fa-camera upload-button" id="editprofilebtn"></i>
  //                   <input className="file-upload" id="editprofilefile" type="file" accept="image/*" />
  //                 </div>
  //               </div>
  //               <div className="profile-info float-sm-left">
  //                 <div className="row mt-4 mt-sm-0">
  //                   <div className="col form-group">
  //                     <label htmlFor="" className="mb-0">First Name</label>
  //                     <input type="text" className="form-control f-600 f2015" disabled="disabled" name="first_name" value={first_name} onChange={this.handleChange}
  //                       placeholder="First Name" />
  //                   </div>
  //                   <div className="col form-group">
  //                     <label htmlFor="" className="mb-0">Last Name</label>
  //                     <input type="text" className="form-control f-600 f2015" disabled="disabled" name="last_name" value={last_name} onChange={this.handleChange}
  //                       placeholder="Last Name" />
  //                   </div>
  //                 </div>
  //                 <div className="form-group">
  //                   <label className="mb-0">Email ID</label>
  //                   <input type="email" className="form-control f-600 f2015" disabled="disabled" name="email" value={email} onChange={this.handleChange}
  //                     placeholder="Email" />
  //                 </div>
  //                 <div className="form-group">
  //                   <label className="mb-0">Mobile Number</label>
  //                   <input type="text" className="form-control f-600 f2015" disabled="disabled" name="mobile" value={mobile} onChange={this.handleChange}
  //                     placeholder="+12522663259" />
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="form-head  f2015 my-3 clearfix" ><span
  //               className="bg-white d-inline-block f-600 pr-3">Login Details</span></div>
  //             <div className="form-group px-2">
  //               <label className="mb-0">User Name</label>
  //               <input type="text" className="form-control f-600 f2015" disabled="disabled" name="username" value={user_name} onChange={this.handleChange} placeholder="User Name" />
  //             </div>
  //             <div className="form-group clearfix px-2">
  //               <a className="color_blue py-3 underline f2015" onClick={() => this.setResetLinkSendShow(true)} >Send Reset Password Link</a>
  //             </div>

  //           </div>
  //           <div className="col-lg-6">
  //             <div className="form-head mb-3 f2015  clearfix"><span
  //               className="bg-white d-inline-block f-600 pr-3">Organization Details</span></div>
  //             <div className="form-group px-2">
  //               <label className="mb-0">Organization Name</label>
  //               <div className="feild-text py-3 f-600 f2015">
  //                 {orgName}
  //               </div>
  //             </div>
  //             <div className="form-group px-2">
  //               <label className="mb-0">User Role</label>
  //               <CustomSelect
  //                 className="form-control f-600 text-left"
  //                 disabled="disabled"
  //                 defaultText={this.state.defaultroleSelectText}
  //                 optionsList={this.state.roleList}
  //                 onSelect={this.handleRoleClick}
  //               />

  //             </div>

  //             <div className="form-group px-2">
  //               <label className="mb-0">Sales Division</label>
  //               <CustomSelect
  //                 className="form-control f-600 text-left"
  //                 defaultText={defaultdivisionSelectText}
  //                 optionsList={this.state.divisionList}
  //                 onSelect={this.handleDivisionClick}


  //               />
  //             </div>

  //             {(showDivisionOption) ? (<>
  //               <div className="form-group px-2">
  //                 <label className="mb-0">Division Manager</label>
  //                 <div className="feild-text py-3 f-600 f2015">
  //                   {division_manager}
  //                 </div>
  //               </div>
  //             </>) : null}

  //             {showTeamOption ? <>
  //               <div className="form-group px-2">
  //                 <label className="mb-0">Sales Team</label>
  //                 <CustomSelect
  //                   className="form-control f-600 text-left"
  //                   defaultText={this.state.defaultteamSelectText}
  //                   optionsList={this.state.teamList}
  //                   onSelect={this.handleTeamClick}

  //                 />
  //               </div>
  //             </> : null}

  //             {(showTeamManager) ? <>
  //               <div className="form-group px-2">
  //                 <label className="mb-0">Sales Manager</label>
  //                 <div className="feild-text py-3 f-600 f2015">
  //                   {sales_manager}
  //                 </div>
  //               </div>
  //             </> : null}
  //           </div>
  //         </div>
  //         <div className="modal-footer border-0 justify-content-center pt-0">
  //           <button type="button" className="btn dark-btn mx-2" onClick={() => this.handleEditSubmit()}>Save</button>
  //           <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setEditProfileShow(false)}>Cancel</button>
  //         </div>
  //       </div>
  //     </form>

  //   );
  // }
  edituserProfile = () => {
    const { user_org_detail, user_detail, division_name, sales_team, profileImg, orgName, showTeamOption, showDivisionOption, showTeamManager, first_name, last_name, email, phone, user_name, role_id, division_id, division_manager, team_id, sales_manager, Message, MessageType, default_edit_divisionSelectText, default_edit_teamSelectText, stored_division_manager, stored_sales_manager } = this.state;

    // var division_name = this.state.defaultdivisionSelectText;
    var division_manager_name;
    // var sales_team = this.state.defaultteamSelectText;
    var sales_manager_name;

    if (default_edit_teamSelectText === "Select Team") {
      sales_manager_name = "--";
    } else {
      sales_manager_name = this.state.sales_manager;
    }
    if (default_edit_divisionSelectText === "Select Division") {
      division_manager_name = "--";
    } else {
      var division_manager_name = this.state.division_manager;
    }
    // if (user_org_detail) {
    //   if (user_org_detail.hasOwnProperty("division") && user_org_detail.division!="") {
    //     division_name = user_org_detail.division.node_name;
    //   }
    //   if (user_org_detail.hasOwnProperty("division_manager") && user_org_detail.division_manager.length>0) {
    //     division_manager_name = user_org_detail.division_manager[0].first_name + " " + user_org_detail.division_manager[0].last_name;
    //   }

    //   if (user_org_detail.hasOwnProperty("team") && user_org_detail.team.user.length>0) {
    //     sales_team = user_org_detail.team.node_name;
    //     sales_manager_name = user_org_detail.team.user[0].first_name + " " + user_org_detail.team.user[0].last_name;
    //   }
    // }
    console.log("$DEBUG USER DETAIL:", user_org_detail);
    console.log("$sales_team:", default_edit_teamSelectText);
    console.log("$division_name:", default_edit_divisionSelectText);
    return (
      <form>
        {((Message) && (Message !== null)) ?
          (
            <div className={`alert alert-${MessageType}`}>
              {Message}
            </div>
          ) : (``)
        }
        <div className="row">
          <div className="col-lg-6">
            <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Personal
              Details</span></div>
            <div className="clearfix px-2">
              <div className="profile-pic float-sm-right">
                <div className="img-wrap">
                  <img className="profile-pic-img" id="editprofile" src={profile2x} alt="profile" />
                </div>

              </div>
              {/* <div className="profile-info float-sm-left">
                <div className="row mt-4 mt-sm-0">
                  <div className="col form-group">
                    <label htmlFor="" className="mb-0">First Name</label>
                    <input type="number" className="form-control f-600 f2015" defaultValue="Winton"
                      placeholder="First Name" />
                    
                  </div>
                  <div className="col form-group">
                    <label htmlFor="" className="mb-0">Last Name</label>
                    <input type="number" className="form-control f-600 f2015" defaultValue="Sandoval"
                      placeholder="Last Name" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="mb-0">Email ID</label>
                  <input type="email" className="form-control f-600 f2015" defaultValue="winton.sandoval@abbott.com"
                    placeholder="Email" />
                </div>
                <div className="form-group">
                  <label className="mb-0">Mobile Number</label>
                  <input type="text" className="form-control f-600 f2015" defaultValue="12522663259"
                    placeholder="+12522663259" />
                </div>
              </div> */}
              <div className="profile-info float-sm-left">
                <div className="form-group">
                  <label className="mb-0">Full Name</label>

                  <div className="feild-text py-3 f-600 f2015">
                    {user_detail ? user_detail.first_name + " " + user_detail.last_name : ""}
                  </div>

                </div>
                <div className="form-group">
                  <label className="mb-0">Email ID</label>
                  <div className="feild-text py-3 f-600 f2015">

                    {user_detail ? user_detail.email : ""}
                  </div>
                </div>
                <div className="form-group">
                  <label className="mb-0">Mobile Number</label>
                  <div className="feild-text py-3 f-600 f2015">
                    {user_detail ? user_detail.mobile : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-head  f2015 my-3 clearfix" ><span
              className="bg-white d-inline-block f-600 pr-3">Login Details</span></div>
            {/* <div className="form-group px-2">
              <label className="mb-0">User Name</label>
              <input type="text" className="form-control f-600 f2015" defaultValue="winton.sandoval" placeholder="User Name" />
            </div>
            <div className="form-group clearfix px-2">
              <a className="color_blue py-3 underline f2015" onClick={() => this.setResetLinkSendShow(true)} >Send Reset Password Link</a>
            </div> */}

            <div className="form-group px-2">
              <label className="mb-0">User Name</label>
              <div className="feild-text py-3 f-600 f2015">
                {user_detail ? user_detail.username : ""}
              </div>
            </div>
            <div className="form-group clearfix px-2">

              {user_detail.status != 0 ? <a className="color_blue py-3 underline f2015" onClick={() => this.sendResetPasswordLink(user_detail.email)}>Send Reset Password Link</a> : ""}
            </div>

          </div>
          <div className="col-lg-6 position-relative">
            <div className="form-head mb-3 f2015  clearfix"><span
              className="bg-white d-inline-block f-600 pr-3">Organization Details</span></div>
            <div className="form-group px-2">
              <label className="mb-0">Organization Name</label>
              <div className="feild-text py-3 f-600 f2015">
                {orgName}
              </div>
            </div>
            <div className="form-group px-2">
              <label className="mb-0">User Role</label>

              <div className="feild-text py-3 f-600 f2015">
                {user_detail.role_id == 2 ? "Company Admin" : user_detail.role_id == 3 ? "Sales Manager" : user_detail.role_id == 4 ? "Sales Rep" :

                  <CustomSelect
                    className="form-control f-600 text-left"
                    defaultText={this.state.defaultroleSelectText}
                    optionsList={this.state.roleList}
                    onSelect={this.handleRoleClick}
                  />

                }
              </div>


            </div>
            {/* <div className="form-group px-2">
              <label className="mb-0">Sales Division</label>
              <CustomSelect
                className="form-control f-600 text-left"
                defaultText={this.state.defaultdivisionSelectText}
                optionsList={this.state.divisionList}
              />
            </div>
            <div className="form-group px-2">
              <label className="mb-0">Division Manager</label>
              <div className="feild-text py-3 f-600 f2015">
                Ned Badman
              </div>
            </div>
            <div className="form-group px-2">
              <label className="mb-0">Sales Team</label>
              <CustomSelect
                className="form-control f-600 text-left"
                defaultText={this.state.defaultteamSelectText}
                optionsList={this.state.teamList}
              />
            </div>
            <div className="form-group px-2">
              <label className="mb-0">Sales Manager</label>
              <div className="feild-text py-3 f-600 f2015">
                John Smith
              </div>
            </div> */}
            <div className="form-group px-2">
              <label className="mb-0">Sales Division<span className="color_red"> *</span></label>
              <CustomSelect
                className="form-control f-600 text-left"
                defaultText={default_edit_divisionSelectText}
                optionsList={this.state.divisionList}
                onSelect={this.handleDivisionClick}
              />
            </div>

            {(showDivisionOption) ? (<>
              <div className="form-group px-2">
                <label className="mb-0">Division Manager</label>
                <div className="feild-text py-3 f-600 f2015">
                  {division_manager_name == "" ? stored_division_manager : division_manager_name}

                </div>
              </div>
            </>) : null}

            {showTeamOption ? <>
              <div className="form-group px-2">
                <label className="mb-0">Sales Team<span className="color_red"> *</span></label>
                <CustomSelect
                  className="form-control f-600 text-left"
                  defaultText={default_edit_teamSelectText}
                  optionsList={this.state.teamList}
                  onSelect={this.handleTeamClick}

                />
              </div>
            </> : null}

            {(showTeamManager) ? <>
              <div className="form-group px-2">
                <label className="mb-0">Sales Manager</label>
                <div className="feild-text py-3 f-600 f2015">
                  {sales_manager_name == "" ? stored_sales_manager : sales_manager_name}
                </div>
              </div>
            </> : null}
          </div>
        </div>
        <div className="modal-footer border-0 justify-content-center pt-0">
          <button type="button" className="btn dark-btn mx-2" onClick={() => this.handleEditSubmit()}>Save</button>
          <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setEditProfileShow(false)}>Cancel</button>
        </div>
      </form>
    );
  }

  setDeactivateUserShow = (newValue, selectedUserName, selectedUserId) => {
    this.setState({ deactivateUsershow: newValue, selectedUserName, selectedUserId });
    this.setState({ Message: '', MessageType: '', })
  }

  userDeactivate = () => {
    const { Message, MessageType, } = this.state

    return (<>
      {((Message) && (Message !== null)) ?
        (
          <div className={`alert alert-${MessageType}`}>
            {Message}
          </div>
        ) : (``)
      }

      <p className="m-3 text-center">
        Are you sure you want to deactivate <span className="d-sm-block f-600"> {this.state.selectedUserName}? </span> </p>
      <p></p>

      <div className="modal-footer border-0 justify-content-center">
        <button type="button" className="btn dark-btn mx-2" onClick={() => this.setDeactivateUser(false, this.state.selectedUserName)}>Yes</button>
        <button type="button" className="btn light-btn mx-2" onClick={() => this.setDeactivateUserShow(false)} >No</button>
      </div>
    </>);
  }
  setActivateUserShow = (newValue, selectedUserName, selectedUserId) => {
    this.setState({ activateUsershow: newValue, selectedUserName, selectedUserId });
    this.setState({ Message: '', MessageType: '', })

  }
  userActivate = () => {
    const { Message, MessageType, } = this.state

    return (<>
      {((Message) && (Message !== null)) ?
        (
          <div className={`alert alert-${MessageType}`}>
            {Message}
          </div>
        ) : (``)
      }

      <p className="m-3 text-center">
        Are you sure you want to activate <span className="d-sm-block f-600"> {this.state.selectedUserName}? </span> </p>
      <p></p>

      <div className="modal-footer border-0 justify-content-center">
        <button type="button" className="btn dark-btn mx-2" onClick={() => this.setActivateUser(false, this.state.selectedUserId)}>Yes</button>
        <button type="button" className="btn light-btn mx-2" onClick={() => this.setActivateUserShow(false)} >No</button>
      </div>
    </>);
  }
  checkAll = (e, option) => {
    if (e.target.checked === true) {

      this.setState({ user_status: [] });
      this.setState({ user_status: ["Active", "Inactive", "Locked", "Invite Sent", "Invite Expired"] });

      $('.userscheck > input[type=checkbox]').prop("checked", true)
    } else {
      this.setState({ user_status: [] });
      $('.userscheck > input[type=checkbox]').prop("checked", false)
    }
  }



  resetFilters = (e) => {
    this.setState({ user_status: ["Active", "Inactive", "Locked", "Invite Sent", "Invite Expired"], user_role: [], team_filters: [], refresh_filter: " " }, () => {
      this.getUsers();
    });

    $(".userscheck-all").prop("checked", true);
    $(".btn-block").attr("aria-expanded", false);
    $(".collapse").removeClass("show");
    $('.division-list button').addClass('collapsed');
    $(".uncheckOne").prop("checked", false);


  }
  checkAllOthers = (event, option) => {
    if (event.target.checked === true) {
      this.setState({ user_status: [...this.state.user_status, option] });
    } else {
      var status_avl = this.state.user_status;
      var status_filters = status_avl.filter(item => item !== option)
      var status_filters = status_filters.filter(item => item !== "All")
      this.setState({ user_status: status_filters });
    }
    var allchecked = true;

    $('.userscheck').each(function () {

      if ($(this).find(":checkbox").prop("checked") === false) {
        allchecked = false;
      }
    });

    if (allchecked === false) {
      $('.userscheck-all > input[type=checkbox]').prop("checked", false)
    } else {
      $('.userscheck-all > input[type=checkbox]').prop("checked", true)
    }
  }


  clickUserRole = (e, option) => {
    if ('#role' + $(e.target.id + '>input[type=checkbox]').is(":checked")) {
      $('#role' + e.target.id + '>input[type=checkbox]').prop("checked", false)
    } else {
      $('#role' + e.target.id + '>input[type=checkbox]').prop("checked", true)
    }
  }
  checkUserRole = (e, option) => {
    if (e.target.checked === true) {
      this.setState({ user_role: [...this.state.user_role, option] });
    } else {
      var roles_total = this.state.user_role;
      var roles_filters = roles_total.filter(item => item !== option)
      this.setState({ user_role: roles_filters });
    }
  }

  checkOrgTeam = (event, option) => {
    if (event.target.checked === true) {
      var teamids = this.state.team_filters;
      teamids.push(option);

      this.setState({ team_filters: teamids });
    } else {
      var team_val = this.state.team_filters;
      var team_filters = team_val.filter(item => item !== option)
      this.setState({ team_filters: team_filters });
    }
  }


  checkOrgDivision = (event, option, teamsdata) => {
    console.log(teamsdata);
    console.log(option);
    var mainarray = this.state.sales_division;
    $(document).on('click', '.btn-link', function () {

      $(this).parent('.division-list-wrap').find('input[type=checkbox]').prop("checked", true);
    })


    if (event.target.classList.contains("collapsed")) {
      this.setState({ sales_division: [...this.state.team_filters, option] });
      var teamids = this.state.team_filters;
      teamids.push(option);

      // if(teamids.indexOf(option) == -1){
      //   teamids.push(option);
      // }
      (teamsdata).map((team, index) => {
        // if(teamids.indexOf(team.id) == -1){
        teamids.push(team.id);
        // }
      })
      console.log('Team Payload : ', teamids)
      this.setState({ team_filters: teamids }, () => {
        console.log(this.state.team_filters);
      });
    } else {
      var team_val = this.state.team_filters;
      var team_filters = team_val.filter(item => item !== option)
      this.setState({ team_filters: team_filters }, () => {
        var team_filters2 = team_filters;
        (teamsdata).map((team, index) => {
          team_filters2 = team_filters2.filter(item => item !== team.id)
        })
        this.setState({ team_filters: team_filters2 });
      });

    }
    setTimeout(() => { console.log(this.state.sales_division); }, 1000);



  }
  statusformatter = (status, row, cell) => {
    if (status == "Active") {
      return (
        <span className="status status-active">{status}</span>
      );
    } else if (status == "Inactive") {
      return (
        <span className="status status-inactive">{status}</span>
      );
    } else if (status == "Invite Sent") {
      return (
        <span className="status status-success">{status}</span>
      );
    } else if (status == "Invite Expired") {
      return (
        <span className="status status-expired"> {status} <small
          className="iconmoon icon-resend ml-1 color_blue" onClick={() => this.sentInvite(status, row)}></small></span>

      );
    } else if (status == "Locked") {
      return (
        <span className="status status-inactive">{status}</span>
      );
    }
  }
  actionformatter = (cell, row, rowIndex, formatExtraData) => {
    console.log("datatable row:", row.id);
    var row_id = row.id;
    return (
      <TableContextMenu setProfileShow={this.setProfileShow} rowIndex={row_id} formatExtraData={formatExtraData} setDeactivateUserShow={this.setDeactivateUserShow} setActivateUserShow={this.setActivateUserShow} />

    );
  }

  render() {
    const { profileShow, editprofileShow, deactivateUsershow, activateUsershow, addUserShow, inviteSendUsershow, organization_structure, users_status_count, users_role_count } = this.state

    $(document).ready(function () {
      $(".cancel-btn,.close").click(function () {
        $("body").removeClass("modal-opens");
      });
      $("#filterbtn").click(function () {
        $("body").addClass("open-filter");
      });
      $("#closeFilter").click(function () {
        $("body").removeClass("open-filter");
      });
    });
    const { SearchBar } = Search;

    function profileimageformatter(image) {
      console.log("USER PROFILE IMAGE", image);
      var user_image = process.env.REACT_APP_STATIC_FILES_PATH + '/' + ((image) ? image : 'default-avatar.png');
      return (
        <span className="img-wrap user-profile bg-transparent"><img src={user_image} alt="profile" /></span>
      );
    }






    const defaultSorted = [{
      dataField: 'fullname',
      order: 'desc'
    }];
    const pagination = paginationFactory({
      hideSizePerPage: true,

    });
    var users = [];

    var users_pagination_data = [];

    if (this.state.users.length > 0) {

      users = this.state.users;
      if (users.length > 0) {
        for (var counter = 0; counter < users.length; counter++) {
          var user_id = users[counter].id;
          users_pagination_data[user_id] = users[counter];
        }
      }
    }
    // console.log("DEBUG USERS",users);console.log("DEBUG USERS1",users_pagination_data);return false;
    const columns = [{
      dataField: 'profileimage',
      text: '',
      formatter: profileimageformatter,
      sort: false
    }, {
      dataField: 'fullname',
      text: 'Full Name',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<span> &nbsp;  </span>);
        else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
        else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
        return null;
      }
    }
      , {
      dataField: 'email',
      text: 'Email ID',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<span> &nbsp;  </span>);
        else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
        else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
        return null;
      }
    }, {
      dataField: 'userrole',
      text: 'User Role',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<span> &nbsp;  </span>);
        else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
        else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
        return null;
      }
    }, {
      dataField: 'status',
      text: 'Status',
      formatter: this.statusformatter,
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<span> &nbsp;  </span>);
        else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
        else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
        return null;
      }
    }, {
      dataField: 'lastactivity',
      text: 'Last Activity',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<span> &nbsp; </span>);
        else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
        else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
        return null;
      }
    }, {
      dataField: 'action',
      text: ' ',
      formatter: this.actionformatter,
      formatExtraData: {
        // user_data: users
        user_data: users_pagination_data
      },
      sort: false
    }];
    console.log("SET USERS", users,organization_structure[0]?.child);
    return (

      <>
        <Modal
          title="Add User"
          contentClasses="viewProfile"
          size="xl"
          onClose={() => this.setAddUserShow(false)}
          show={addUserShow}>
          {this.addUser()}
        </Modal>

        <Modal
          title="User Profile"
          contentClasses="viewProfile"
          size="xl"
          onClose={() => this.setProfileShow(false)}
          show={profileShow}>
          {this.userProfile()}
        </Modal>

        <Modal
          title="Edit User Profile"
          contentClasses="editProfile"
          size="xl"
          onClose={() => this.setEditProfileShow(false)}
          show={editprofileShow}>
          {this.edituserProfile()}
        </Modal>

        <Modal
          title=""
          contentClasses="py-3 py-lg-5"
          size="md"
          onClose={() => this.setDeactivateUserShow(false)}
          show={deactivateUsershow}>
          {this.userDeactivate()}
        </Modal>

        <Modal
          title=""
          contentClasses="py-3 py-lg-5"
          size="md"
          onClose={() => this.setActivateUserShow(false)}
          show={activateUsershow}>
          {this.userActivate()}
        </Modal>

        <Modal
          title=""
          contentClasses="py-3 py-lg-5"
          size="md"
          onClose={() => this.setInviteSendUserShow(false)}
          show={inviteSendUsershow}>
          {this.inviteSendUser()}
        </Modal>

        <main className="main-contant">
          <div className="contents">


            <ToolkitProvider
              keyField="id"
              data={users}
              columns={columns}
              search
            >
              {
                props => (
                  <div>
                    <div className="d-flex flex-wrap justify-content-between mb-3">

                      <h3 className="title-head mt-0 mb-2 ">User Management</h3>

                      <div className="right-content right-search-content flex-wrap  d-flex  justify-content-between align-items-center">

                        <div className="search-bar ml-2 mr-3">
                          <SearchBar {...props.searchProps} />

                        </div>
                        <div className="actions Add_user_action">
                          <div className="dropdown">

                            <button type="button" className="btn dark-btn py-0 px-4 mr-2" id="dropdownMenuButton1" data-toggle="dropdown">+ Add User</button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <div className="d-flex text-center">
                                <a className="dropdown-item" onClick={() => this.setAddUserShow(true)}><i class="d-block ri-keyboard-box-line"></i> <small>Manually</small>  </a>
                                {/* <a className="dropdown-item" to={`./inactive-sales-stages`}></a> */}

                                <Link className="dropdown-item" to={`./initiate-user-management`}> <i class="d-block ri-upload-2-fill"></i> <small>Upload CSV</small></Link>

                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="filter-wrap mx-2" id="filterbtn">
                          <FontAwesomeIcon icon={faFilter} />
                          &nbsp;Filter
                        </div>
                      </div>
                    </div>

                    <BootstrapTable
                      {...props.baseProps}
                      pagination={pagination} />
                  </div>
                )
              }
            </ToolkitProvider>

          </div>
        </main>


        <div className="filter-container">
          <div className="d-flex filter-head p-4  justify-content-between align-items-center">
            <div className="filter-title f-600 f2015">
              Filters  {this.state.refresh_filter}
            </div>
            <small onClick={this.resetFilters} className="color_blue underline">Clear Filters</small>
          </div>
          <div className="accordion" id="filteraccordion">
            <div className="filter-list py-3">
              <button className="btn btn-link btn-block text-left px-4" type="button" data-toggle="collapse"
                data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                USER STATUS <i className="fa fa-chevron-down float-right"></i>
              </button>
              <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#filteraccordion">
                <div className="card-body p-0">
                  <ul className="list-unstyled m-0">
                    <li className="py-2 pr-3" >
                      <label className="customCheck text-check">
                        <input type="checkbox" className="userscheck-all" onChange={(e) => this.checkAll(e, "All")} defaultChecked={true} />
                        <span className="checkmark"  >All Users <text className="float-right">({users_status_count.all})</text></span>
                      </label>
                    </li>
                    <li className="py-2 pr-3">
                      <label className="customCheck userscheck">
                        <input type="checkbox" className="userscheck-all" onChange={(e) => this.checkAllOthers(e, "Active")} defaultChecked={true} />
                        <span className="checkmark">Active Users <text className="float-right">({users_status_count.active})</text></span>
                      </label>
                    </li>
                    <li className="py-2 pr-3">
                      <label className="customCheck userscheck">
                        <input type="checkbox" className="userscheck-all" onChange={(e) => this.checkAllOthers(e, "Inactive")} defaultChecked={true} />
                        <span className="checkmark">Inactive Users <text className="float-right">({users_status_count.inactive})</text></span>
                      </label>
                    </li>
                    <li className="py-2 pr-3">
                      <label className="customCheck userscheck">
                        <input type="checkbox" className="userscheck-all" onChange={(e) => this.checkAllOthers(e, "Invite Sent")} defaultChecked={true} />
                        <span className="checkmark">Invite Sent <text className="float-right">({users_status_count.invite_sent})</text></span>
                      </label>
                    </li>
                    <li className="py-2 pr-3">
                      <label className="customCheck userscheck">
                        <input type="checkbox" className="userscheck-all" onChange={(e) => this.checkAllOthers(e, "Invite Expired")} defaultChecked={true} />
                        <span className="checkmark">Invite Expired <text className="float-right">({users_status_count.invite_expire})</text></span>
                      </label>
                    </li>
                    <li className="py-2 pr-3">
                      <label className="customCheck userscheck">
                        <input type="checkbox" className="userscheck-all" onChange={(e) => this.checkAllOthers(e, "Locked")} defaultChecked={true} />
                        <span className="checkmark">Locked <text className="float-right">({users_status_count.lock})</text></span>
                      </label>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="filter-list py-3">
              <button className="btn btn-link btn-block text-left px-4 collapsed" type="button" data-toggle="collapse"
                data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                USER ROLE <i className="fa fa-chevron-down float-right"></i>
              </button>
              <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div className="card-body p-0">
                  <ul className="list-unstyled m-0">
                    <li className="py-2 pr-3">
                      <label className="customCheck text-check" id="role2" onClick={(e) => this.clickUserRole(e, 2)}>
                        <input type="checkbox" className="uncheckOne" onChange={(e) => this.checkUserRole(e, 2)} />
                        <span className="checkmark">Division Manager <text className="float-right">({users_role_count.company_admin})</text></span>
                      </label>
                    </li>
                    <li className="py-2 pr-3">
                      <label className="customCheck" id="role3" onClick={(e) => this.clickUserRole(e, 3)}>
                        <input type="checkbox" className="uncheckOne" onChange={(e) => this.checkUserRole(e, 3)} />
                        <span className="checkmark">Sales Manager <text className="float-right">({users_role_count.sales_maager})</text></span>
                      </label>
                    </li>
                    <li className="py-2 pr-3">
                      <label className="customCheck" id="role4" onClick={(e) => this.clickUserRole(e, 4)}>
                        <input type="checkbox" className="uncheckOne" onChange={(e) => this.checkUserRole(e, 4)} />
                        <span className="checkmark">Sales Reps <text className="float-right">({users_role_count.sales_rep})</text></span>
                      </label>
                    </li>
                    <li className="py-2 pr-3">
                      <label className="customCheck" id="role5" onClick={(e) => this.clickUserRole(e, 5)}>
                        <input type="checkbox" className="uncheckOne" onChange={(e) => this.checkUserRole(e, 5)} />
                        <span className="checkmark">Not Assigned <text className="float-right">({users_role_count.not_assigned})</text></span>
                      </label>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="filter-list division-list py-3">
              <button className="btn btn-link btn-block text-left px-4 collapsed" type="button" data-toggle="collapse"
                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                SALES DIVISION <i className="fa fa-chevron-down float-right"></i>
              </button>
              <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                <div className="card-body p-0">
                  <div className="accordion" id="salesaccordion">

                    {organization_structure[0] && Object.keys(organization_structure[0].child).length > 0 && organization_structure[0] != undefined ?

                      <div>
                        {(organization_structure[0].child).map((levels, index) => {
                          {
                            return <div className="filter-list division-list-wrap py-3 border-0">
                              <button className="btn btn-link btn-block text-left px-4 collapsed" type="button"
                                onClick={(e) => this.checkOrgDivision(e, levels.id, levels.child)} data-toggle="collapse" data-target={`#collapseOnesales${index}`} aria-expanded="false" aria-controls="collapseOnesales" >


                                {levels.node_name}

                                <i className="fa fa-chevron-down float-right"
                                >  </i> <span className="float-right"> ({levels.members_count}) &nbsp;</span>
                              </button>
                              <div id={`collapseOnesales${index}`} className="collapse" aria-labelledby="headingOnesales"
                                data-parent={`salesaccordion${index}`}>
                                <div className="card-body p-0">

                                  {levels.child ?

                                    <ul className="list-unstyled m-0">
                                      {(levels.child).map((orgteam, index1) => {
                                        {
                                          return <>
                                            <li className="py-2 pr-3">
                                              <label className="customCheck text-check">
                                                <input type="checkbox" className="uncheckOne" data-parent={levels.id} onChange={(e) => this.checkOrgTeam(e, orgteam.id)} />
                                                <span className="checkmark">{orgteam.node_name} <text
                                                  className="float-right">({orgteam.members_count})</text></span>
                                              </label>
                                            </li>
                                          </>
                                        }
                                      })}
                                    </ul> : null}

                                </div>
                              </div>
                            </div>
                          }
                        })
                        }
                      </div> : null}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="btn-groups mt-4 text-right">
            <button type="button" className="btn light-btn cancel-btn mx-2 py-0 px-4" id="closeFilter" data-dismiss="modal">Close</button>
            <button type="button" onClick={(e) => this.getUsers(e)} className="btn dark-btn mx-2 py-0 px-4">Apply</button>
          </div>

        </div>
      </>
    )
  }
}
