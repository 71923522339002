import React, { Component } from 'react'
import profile1 from "../../images/profile1.png";
import profile3 from "../../images/profile3.png";
import profile2 from "../../images/profile2.png"
import profile2x from "../../images/default-avatar.png";
import csv from "../../images/csv-file.png"
import Modal from "./modals/modal";
import axios from '../apiConnection/axios';
import $, { data } from 'jquery';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faEllipsisV, faChevronLeft, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { CSVReader } from 'react-papaparse';
import Utility from "../../components/utility";
const utilityObj = new Utility;
export default class InitiateOrganizationStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addDivisionShow: false,
      divisions: ['Headings', 'Org Head', 'division-2'],
      selectedFile: "",
      csvToJson: "",
      csvErrors: "",
      submitJson: [],
      redirect_url: "http://localhost:3001/createpassword",
      org_data: [],
      Message: "",
      MessageType: "",
      fileextractDone: 0,
      errorCells: "",
      errorfields: [],
      ReportFile: "",
      orgName: "",
      haserrors: true
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    var data_division = parseInt(e.target.getAttribute('data-division_id'));
    var haserrors = false;

    this.setState(prevState => ({
      ...prevState,
      submitJson: {
        ...prevState.submitJson,
        [0]: {
          ...prevState.submitJson[0],
          "data": ['Head Position', 'Sales Division Name', 'Sales Team Name', 'First Name', 'Last Name', 'Email', 'Mobile'],
        }
      }
    }))

    if (data_division === 1) {

      var indexval = "";
      if (e.target.name === "Sales Division Name") {
        indexval = 0;
      }
      if (e.target.name === "First Name") {
        indexval = 3;
      }
      if (e.target.name === "Last Name") {
        indexval = 4;
      }
      if (e.target.name === "Email") {
        indexval = 5;
      }
      if (e.target.name === "Mobile") {
        indexval = 6;
      }

      var orgdata = "";
      if (this.state.submitJson[data_division] !== undefined) {
        orgdata = this.state.submitJson[data_division].data;
        orgdata[indexval] = e.target.value;
      } else {
        orgdata = ['CEO', '', '', '', '', '', '']
        orgdata[indexval] = e.target.value;
      }


      this.setState(prevState => ({
        ...prevState,
        submitJson: {
          ...prevState.submitJson,
          [data_division]: {
            ...prevState.submitJson[data_division],
            "data": orgdata,
          }
        }

      }), () => {
        var errorfields = this.state.errorfields;
        var submitJson = this.state.submitJson;
        var row = data_division;
        var col = indexval;
        if ((submitJson[data_division].data[indexval] != undefined) && (submitJson[data_division].data[indexval] != "")) {

          // if (indexval === 0 || indexval === 3 || indexval === 4) { //Role, First & Last Name
          if (indexval === 0) { //Role, First & Last Name
            if (submitJson[data_division].data[indexval].trim().length > 30) {

              errorfields[row + '_' + col] = 1;

            } else {
              errorfields[row + '_' + col] = 0;
            }
            //   if (!(/^[a-zA-Z\s]*$/.test(submitJson[data_division].data[indexval]))) {
            //     errorfields[row+'_'+col] = 1;
            //   } else {
            //     errorfields[row+'_'+col] = 0;
            //   }
          }
          if (indexval === 1) { //Division & Team Name
            if (submitJson[data_division].data[indexval] !== "") {
              errorfields[row + '_' + col] = 1;
            } else {
              errorfields[row + '_' + col] = 0;
            }
            // if (!(/^[a-z\d\-_\s]+$/i.test(submitJson[data_division].data[indexval]))) {
            //   errorfields[row+'_'+col] = 1;
            // } else {
            //   errorfields[row+'_'+col] = 0;
            // }
          }
          if (indexval === 2) {
            if (submitJson[data_division].data[indexval] !== "") {
              errorfields[row + '_' + col] = 1;
            } else {
              errorfields[row + '_' + col] = 0;
            }
            // if (!(/^[a-z\d\-_\s]+$/i.test(submitJson[data_division].data[indexval]))) {
            //   errorfields[row+'_'+col] = 1;
            // } else {
            //   errorfields[row+'_'+col] = 0;
            // }
          }
          if (indexval === 6) { //mobile
            if (!(/^[0-9]{11,14}$/g.test(submitJson[data_division].data[indexval]))) {
              errorfields[row + '_' + col] = 1;
            } else {
              errorfields[row + '_' + col] = 0;
            }
          }
          if (indexval === 5) { //email
            let lastAtPos = submitJson[data_division].data[indexval].lastIndexOf('@');
            let lastDotPos = submitJson[data_division].data[indexval].lastIndexOf('.');
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(submitJson[data_division].data[indexval])) {
              errorfields[row + '_' + col] = 1;
            } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && submitJson[data_division].data[indexval].indexOf('@@') === -1 && lastDotPos > 2 && (submitJson[data_division].data[indexval].length - lastDotPos) > 2)) {
              errorfields[row + '_' + col] = 1;
            } else {
              errorfields[row + '_' + col] = 0;
            }
          }
          this.setState(prevState => ({
            ...prevState,
            errorfields
          }), () => {

          })
        }
      })

    } else {

      var indexval = "";
      if (e.target.name === "Sales Division Name") {
        indexval = 1;
      }
      if (e.target.name === "First Name") {
        indexval = 3;
      }
      if (e.target.name === "Last Name") {
        indexval = 4;
      }
      if (e.target.name === "Email") {
        indexval = 5;
      }
      if (e.target.name === "Mobile") {
        indexval = 6;
      }

      var orgdata = "";
      if (this.state.submitJson[data_division] !== undefined) {
        orgdata = this.state.submitJson[data_division].data;
        orgdata[indexval] = e.target.value;
      } else {
        orgdata = ['Division Manager', '', '', '', '', '', '']
        orgdata[indexval] = e.target.value;
      }


      this.setState(prevState => ({
        ...prevState,
        submitJson: {
          ...prevState.submitJson,
          [data_division]: {
            ...prevState.submitJson[data_division],
            "data": orgdata,
          }
        }
      }), () => {
        var errorfields = this.state.errorfields;
        var submitJson = this.state.submitJson;
        var row = data_division;
        var col = indexval;
        if ((submitJson[data_division].data[indexval] != undefined) && (submitJson[data_division].data[indexval] != "")) {
          // if (indexval === 0 || indexval === 3 || indexval === 4) { //Role, First & Last Name
          //   if (!(/^[a-zA-Z\s]*$/.test(submitJson[data_division].data[indexval]))) {
          //     errorfields[row+'_'+col] = 1;
          //   } else {
          //     errorfields[row+'_'+col] = 0;
          //   }

          // }
          if (indexval === 1) { //Division & Team Name
            // if (!(/^[a-z\d\-_\s]+$/i.test(submitJson[data_division].data[indexval]))) {
            //   errorfields[row+'_'+col] = 1;
            // } else {
            //   errorfields[row+'_'+col] = 0;
            // }
            if (submitJson[data_division].data[indexval].trim().length > 30) {

              errorfields[row + '_' + col] = 1;

            } else {
              errorfields[row + '_' + col] = 0;
            }

          }
          if ((indexval === 2) && submitJson[data_division].data[indexval] != "") {
            if (!(/^[a-z\d\-_\s]+$/i.test(submitJson[data_division].data[indexval]))) {
              errorfields[row + '_' + col] = 1;
            } else {
              errorfields[row + '_' + col] = 0;
            }
          }
          if (indexval === 6) { //mobile
            if (!(/^[0-9]{11,14}$/g.test(submitJson[data_division].data[indexval]))) {
              errorfields[row + '_' + col] = 1;
            } else {
              errorfields[row + '_' + col] = 0;
            }
          }
          if (indexval === 5) { //email
            let lastAtPos = submitJson[data_division].data[indexval].lastIndexOf('@');
            let lastDotPos = submitJson[data_division].data[indexval].lastIndexOf('.');
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(submitJson[data_division].data[indexval])) {
              console.log("IF@@@#");
              errorfields[row + '_' + col] = 1;
            } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && submitJson[data_division].data[indexval].indexOf('@@') === -1 && lastDotPos > 2 && (submitJson[data_division].data[indexval].length - lastDotPos) > 2)) {
              console.log("ELSEIF@@@###");
              errorfields[row + '_' + col] = 1;
            } else {
              console.log("ELSE@@@###");
              errorfields[row + '_' + col] = 0;
            }
          }
          this.setState(prevState => ({
            ...prevState,
            errorfields
          }), () => {

          })
        }
      })
    }
    var errorfields = this.state.errorfields;
    console.log(errorfields);

    var org_structure = [this.state.submitJson];
    var obj = org_structure[0];
    const propertyValues = Object.values(obj);
    for (let index = 0; index < propertyValues.length; index++) {
      if (index === 0) {
        if ((propertyValues[index].data[0] === "") || (propertyValues[index].data[0] === undefined)
          || (propertyValues[index].data[1] === "") || (propertyValues[index].data[1] === undefined)
          || (propertyValues[index].data[2] === "") || (propertyValues[index].data[2] === undefined)
          || (propertyValues[index].data[3] === "") || (propertyValues[index].data[3] === undefined)
          || (propertyValues[index].data[4] === "") || (propertyValues[index].data[4] === undefined)
          || (propertyValues[index].data[5] === "") || (propertyValues[index].data[5] === undefined)
          || (propertyValues[index].data[6] === "") || (propertyValues[index].data[6] === undefined)) {
          haserrors = true;
          break;
        }
      }
      if (index === 1) {
        if ((propertyValues[index].data[0] === "") || (propertyValues[index].data[0] === undefined)
          || (propertyValues[index].data[3] === "") || (propertyValues[index].data[3] === undefined)
          || (propertyValues[index].data[4] === "") || (propertyValues[index].data[4] === undefined)
          || (propertyValues[index].data[5] === "") || (propertyValues[index].data[5] === undefined)
          || (propertyValues[index].data[6] === "") || (propertyValues[index].data[6] === undefined)) {
          haserrors = true;
          break;
        }
      }
      if (index >= 2) {
        if ((propertyValues[index].data[0] === "") || (propertyValues[index].data[0] === undefined)
          || (propertyValues[index].data[1] === "") || (propertyValues[index].data[1] === undefined)
          || (propertyValues[index].data[3] === "") || (propertyValues[index].data[3] === undefined)
          || (propertyValues[index].data[4] === "") || (propertyValues[index].data[4] === undefined)
          || (propertyValues[index].data[5] === "") || (propertyValues[index].data[5] === undefined)
          || (propertyValues[index].data[6] === "") || (propertyValues[index].data[6] === undefined)) {
          haserrors = true;
          break;
        }
      }

    }


    for (const [key, value] of Object.entries(errorfields)) {
      if (value === 1) {
        haserrors = true;
      }
    }



    this.setState({ haserrors });
    // this.setState({submitJson:{[e.target.name]: e.target.value}});  
    // this.setState({ [e.target.name] : e.target.value });
  }
  handleMannualUpload = () => {
    console.log(this.state.submitJson);
    this.organizationUpload(2);

  }
  getOrganizations = async () => {
    await axios.get(`organizations/get-organization-structure`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        if (res.data.data.length > 0) {
          window.location.href = "./organization-structure";
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  organizationUpload = async (type) => {
    var organizationdata = {};
    if (type === 1) {
      organizationdata = {
        redirect_url: process.env.REACT_APP_PASSWORD_CREATE_LINK,
        org_data: this.state.org_data,
      }
    } else if (type === 2) {
      var org_structure = [this.state.submitJson];
      var obj = org_structure[0];
      const propertyValues = Object.values(obj);
      organizationdata = {
        redirect_url: process.env.REACT_APP_PASSWORD_CREATE_LINK,
        org_data: propertyValues,
      }
    }
    if ((type === 1) || (type === 2)) {
      utilityObj.loaderView("show");
      await axios.post(`/users/create-organisation-structure`, organizationdata, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        }
      })
        .then(res => {
          console.log(res);
          if (res.data.code === 200) {
            utilityObj.loaderView("hide");
            this.setState({
              ReportFile: res.data.data.csv_file_url,
              users_count: res.data.data.users_count,
              Message: "CSV Uploaded successfully.",
              MessageType: 'success',
              fileextractDone: 0,
            });
            this.setAddDivisionShow(false);
            localStorage.setItem('getOrganizationData', res.data.data.url);
            // window.location.href = "./success-organization-structure";
          }
          else {
            utilityObj.loaderView("hide");
            this.setState({
              Message: "CSV Upload Failed.",
              MessageType: 'danger',
              fileextractDone: 0,
            });
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getOrganizations();
    this.getOrganizationData();
  }
  setAddDivisionShow = (newValue) => {
    this.setState({ addDivisionShow: newValue });
  }

  appendDivision() {
    var newDivision = `division-${this.state.divisions.length}`;
    this.setState(prevState => ({ divisions: prevState.divisions.concat([newDivision]) }));
  }
  removeDivision = (deleteval) => {

    var index_to_remove = deleteval.replace("division-", "");
    console.log("Remove Division Index : ", index_to_remove);
    console.log("Divisions : ", this.state.divisions);
    this.setState({
      divisions: this.state.divisions.filter((divisions) => {
        return divisions !== deleteval
      })
    });

    var error_fields = this.state.errorfields;
    for (var i = 1; i < 7; i++) {
      delete error_fields[index_to_remove + "_" + i];
    }
    var divisionsToSubmit = this.state.submitJson;
    delete divisionsToSubmit[index_to_remove];
    this.setState({
      submitJson: divisionsToSubmit
    });

    this.setState({
      error_fields: error_fields
    });

    var haserrors = false;

    for (const [key, value] of Object.entries(error_fields)) {
      if (value === 1) {
        haserrors = true;
      }
    }

    this.setState({ haserrors });

    console.log("divisions after deletion", this.state.divisions);

  }
  loaderView(action = "") {
    if (document.getElementById("customeloader") != null) {
      if (action == "show") {
        document.getElementById("customeloader").style.visibility = "visible";
      }

      if (action == "hide") {
        document.getElementById("customeloader").style.visibility = "hidden";
      }
    }

  }
  addDivision = () => {
    const { errorfields } = this.state;
    console.log("errors", errorfields);
    return (<>
      <div className="form-head mb-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Organization
        Head</span></div>
      <form>
        <div className="form-group">
          <label className="mb-0">Organization Head Position <span className="mandatory color_red">*</span></label>
          <input type="text" className="form-control f-600 f2015"
            placeholder="Organization Head Position" data-division_id={1} onChange={this.onChange} onKeyUp={this.onChange} name={`Sales Division Name`} />
          <b className="color_red">{(errorfields['1_0']) ? (`Organization head position cannot exceed 30 characters`) : (``)}</b>
        </div>
        <div className="clearfix">
          <div className="profile-pic float-sm-left">
            <div className="img-wrap">
              <img className="profile-pic-img" id="orgHead" src={profile2x} alt="profile" />
            </div>
            {/* <div className="p-image">
              <i className="fa fa-camera upload-button" id="orgHeadbtn"></i>
              <input className="file-upload" id="orgHeadfile" type="file" accept="image/*" />
            </div> */}
          </div>
          <div className="profile-info float-sm-left">
            <div className="row mt-4 mt-sm-0">
              <div className="col">
                <div className="form-group">
                  <label className="mb-0">Head First Name <span className="mandatory color_red">*</span></label>
                  <input type="text" className="form-control f-600 f2015"
                    placeholder=" Head First Name" data-division_id={1} onChange={this.onChange} onKeyUp={this.onChange} name={`First Name`} />
                  <b className="color_red">{(errorfields['1_3']) ? (`Invalid Format`) : (``)}</b>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="mb-0">Head Last Name <span className="mandatory color_red">*</span></label>
                  <input type="text" className="form-control f-600 f2015"
                    placeholder="Head Last Name" data-division_id={1} onChange={this.onChange} onKeyUp={this.onChange} name={`Last Name`} />
                  <b className="color_red">{(errorfields['1_4']) ? (`Invalid Format`) : (``)}</b>
                </div>
              </div>

            </div>

            <div className="form-group">
              <label className="mb-0">Organization Head Email <span className="mandatory color_red">*</span></label>
              <input type="email" className="form-control f-600 f2015"
                placeholder="Email" data-division_id={1} onChange={this.onChange} onKeyUp={this.onChange} name={`Email`} />
              <b className="color_red">{(errorfields['1_5']) ? (`Invalid Format`) : (``)}</b>
            </div>
            <div className="form-group">
              <label htmlFor="" className="mb-0">Mobile Number <span className="mandatory color_red">*</span></label>
              <input type="number" data-division_id={1} onChange={this.onChange} onKeyUp={this.onChange} className="form-control f-600 f2015" defaultValue=""
                placeholder="With country code" name={`Mobile`} />
              <b className="color_red">{(errorfields['1_6']) ? (`Invalid Format`) : (``)}</b>

            </div>
          </div>
        </div>
        <div className="form-head my-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Sales Division
          Details</span> <small className="float-right bg-white d-inline-block pl-3 link" onClick={() => this.appendDivision()}> + Add</small></div>
        <hr />
        {this.state.divisions.map((input, index) =>
        (<>
          <div className="clearfix" id={input} key={index}>
            <div className="form-group">
              <label className="mb-0 w-100">Sales Division Name <span className="float-right bg-white d-inline-block pl-3 link color_red" onClick={() => this.removeDivision(input)}> - Clear</span></label>
              <input type="text" className="form-control f-600 f2015"
                placeholder="Sales Division Name" data-division_id={index} onChange={this.onChange} onKeyUp={this.onChange} name={`Sales Division Name`} />
              <b className="color_red">{(errorfields[index + '_1']) ? (`Division name cannot exceed 30 characters`) : (``)}</b>
            </div>
            <div className="clearfix">
              <div className="profile-pic float-sm-left">
                <div className="img-wrap">
                  <img className="profile-pic-img" id="salesDivision" src={profile2x} alt="profile" />
                </div>
                {/* <div className="p-image">
                  <i className="fa fa-camera upload-button" id="salesDivisionbtn"></i>
                  <input className="file-upload" id="salesDivisionfile" type="file" accept="image/*" />
                </div> */}
              </div>
              <div className="profile-info float-sm-left">
                <div className="row mt-4 mt-sm-0">
                  <div className="col">
                    <div className="form-group">
                      <label className="mb-0 w-100">First Name </label>
                      <input type="text" className="form-control f-600 f2015"
                        placeholder="First Name" data-division_id={index} onChange={this.onChange} onKeyUp={this.onChange} name={`First Name`} />
                      <b className="color_red">{(errorfields[index + '_3']) ? (`Invalid Format`) : (``)}</b>
                    </div>
                  </div>
                  <div className="col">

                    <div className="form-group">
                      <label className="mb-0 w-100">Last Name </label>
                      <input type="text" className="form-control f-600 f2015"
                        placeholder="Last Name" data-division_id={index} onChange={this.onChange} onKeyUp={this.onChange} name={`Last Name`} />
                      <b className="color_red">{(errorfields[index + '_4']) ? (`Invalid Format`) : (``)}</b>
                    </div>
                  </div>

                </div>

                <div className="form-group">
                  <label className="mb-0">Division Manager Email</label>
                  <input type="email" className="form-control f-600 f2015" placeholder="Email" data-division_id={index} name={`Email`} onChange={this.onChange} onKeyUp={this.onChange} />
                  <b className="color_red">{(errorfields[index + '_5']) ? (`Invalid Format`) : (``)}</b>
                </div>
                <div className="form-group">
                  <label htmlFor="" className="mb-0">Mobile Number</label>
                  <input type="number" className="form-control f-600 f2015" data-division_id={index} defaultValue="" name={`Mobile`} onChange={this.onChange} onKeyUp={this.onChange}
                    placeholder="Mobile Number" />
                  <b className="color_red">{(errorfields[index + '_6']) ? (`Invalid Format`) : (``)}</b>
                </div>
              </div>
            </div>
          </div><hr />
        </>)
        ).slice(3, this.state.divisions.length)}


      </form>
      <div className="modal-footer justify-content-center">
        {(this.state.haserrors === false) ? (
          <button type="button" className="btn dark-btn mx-2" onClick={this.handleMannualUpload}>Save</button>
        ) : (``)}
        <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setAddDivisionShow(false)}>Cancel</button>
      </div>
    </>);
  }





  handleOnDrop = (data) => {
    this.setState({ csvToJson: data });
    console.log(this.state.csvToJson);
    var fullerror_message = "";
    var cell = "";
    var row = 0;
    var col = 0;
    {
      var file_data = this.state.csvToJson;
      if (file_data.length === 0) {
        console.log("DATALENGTH", 0);
        fullerror_message = " <br/> CSV file is empty. Nothing to import.  <br/>";
        // return false;
      }
      else if (file_data.length === 1) {
        console.log("DATALENGTH", 1);
        fullerror_message = " <br/> CSV file is empty. Nothing to import. <br/>";
        // return false;
      } else {

        if (file_data.length === 2) {
          if (file_data[0].data.length <= 1 || file_data[1].data.length <= 1)
            fullerror_message = " <br/> CSV file is empty. Nothing to import. <br/>";
        }

        if (file_data[0].data[0] != "Head Position" || file_data[0].data[1] != "Sales Division Name" || file_data[0].data[2] != "Sales Team Name" || file_data[0].data[3] != "First Name" || file_data[0].data[4] != "Last Name" || file_data[0].data[5] != "Email" || file_data[0].data[6] != "Mobile" || fullerror_message != "") {
          fullerror_message = " <br/> The column header is incorrect. <br/>";
        }

        else {
          var division_list_data = [];
          this.state.csvToJson.map((value, index, array2) => {
            row = index + 1;
            if (index == 0) {
              for (var i = 0; i <= 6; i++) {
                col = this.numToSSColumn(i + 1);
                if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i]))) {
                  cell = 'Cell ' + col + '' + row;
                  fullerror_message = fullerror_message + ' ' + cell;
                }
              }
              this.setState(prevState => ({
                ...prevState,
                org_data: [
                  ...prevState.org_data,
                  {
                    "data": array2[index].data,
                  }
                ]
              })
              );
            } else if (index == 1) {
              for (var i = 0; i <= 6; i++) {
                col = this.numToSSColumn(i + 1);
                if (i === 0) {
                  if (array2[index].data[i].trim() == "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " Head position missing. <br>";
                  }
                }
                if (i === 3) {
                  if (array2[index].data[i].trim() == "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " First name missing. <br>";
                  }
                }
                if (i === 4) {
                  if (array2[index].data[i].trim() == "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " Last name missing. <br>";
                  }
                }
                if (i === 0 || i === 3 || i === 4) {
                  if (!(/^[a-zA-Z\s]*$/.test(array2[index].data[i].trim()))) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Only letters are allowed) <br>";
                  }
                }
                if (i === 1 || i === 2) {
                  if (array2[index].data[i].trim() !== '') {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Division Name/ Team Name is not valid) <br>";
                  }
                }

                if (i === 6) { //mobile
                  if (!(/^[0-9]{11,14}$/g.test(array2[index].data[i].trim()))) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Invalid phone)\n <br>";
                  }

                  if (array2[index].data[i].length < 10) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Phone number cannot be less than 10 digits)\n <br>";
                  }

                }
                if (i === 5) { //email
                  let lastAtPos = array2[index].data[i].lastIndexOf('@');
                  let lastDotPos = array2[index].data[i].lastIndexOf('.');
                  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                  if (!pattern.test(array2[index].data[i])) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Invalid Email)\n <br>";
                  } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && array2[index].data[i].indexOf('@@') === -1 && lastDotPos > 2 && (array2[index].data[i].length - lastDotPos) > 2)) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Invalid Email)\n <br>";
                  }
                }
              }
              this.setState(prevState => ({
                ...prevState,
                org_data: [
                  ...prevState.org_data,
                  {
                    "data": array2[index].data,
                  }
                ]
              })
              );
            } else if (index === data.length - 1) {
              if ((array2[index].data[0] === "Division Manager") || (array2[index].data[0] === "Sales Manager")) {
                if (array2[index].data[0] === "Division Manager") {
                  if (division_list_data.indexOf(array2[index].data[1]) === -1) {
                    division_list_data.push(array2[index].data[1]);
                    console.log("DIVISION LIST:", division_list_data);
                  }
                }


                for (var i = 0; i <= 6; i++) {
                  col = this.numToSSColumn(i + 1);
                  if (i === 0) {
                    if (array2[index].data[i].trim() == "") {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " Head position missing. <br>";
                    }
                  }
                  if (i === 3) {
                    if (array2[index].data[i].trim() == "") {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " First name missing. <br>";
                    }
                  }
                  if (i === 4) {
                    if (array2[index].data[i].trim() == "") {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " Last name missing. <br>";
                    }
                  }

                  if (i === 0 || i === 3 || i === 4) { //Role, First & Last Name
                    if (!(/^[a-zA-Z\s]*$/.test(array2[index].data[i].trim()))) {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Only letters are allowed) <br>";
                    }
                  }
                  if (i === 1) { //Division & Team Name
                    if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i].trim()))) {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Division name is not valid) <br>";
                    }
                  }
                  if (i === 2) {
                    cell = 'Cell ' + col + '' + row;
                    if (array2[index].data[0] === "Sales Manager") {
                      if (array2[index].data[i].trim() === "") {
                        fullerror_message = fullerror_message + ' ' + cell + " (Sales team name is required for sales manager.) <br>";
                      } else if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i]))) {
                        fullerror_message = fullerror_message + ' ' + cell + " (Sales team name is not valid) <br>";
                      } else {
                        if (division_list_data.indexOf(array2[index].data[1]) === -1) {
                          col = this.numToSSColumn(1 + 1);
                          cell = 'Cell ' + col + '' + row;
                          fullerror_message = fullerror_message + ' ' + cell + " (Sales Division Name doesn't exist) <br>";
                        }
                      }
                    }
                  }
                  if (i === 6) { //mobile
                    if (!(/^[0-9]{11,14}$/g.test(array2[index].data[i]))) {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' \n' + cell + " (Invalid phone)\n <br>";
                    }
                  }
                  if (i === 5) { //email
                    let lastAtPos = array2[index].data[i].lastIndexOf('@');
                    let lastDotPos = array2[index].data[i].lastIndexOf('.');
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (!pattern.test(array2[index].data[i])) {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' \n' + cell + " (Invalid Email)\n <br>";
                    } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && array2[index].data[i].indexOf('@@') === -1 && lastDotPos > 2 && (array2[index].data[i].length - lastDotPos) > 2)) {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' \n' + cell + " (Invalid Email)\n <br>";
                    }
                  }
                }
                this.setState(prevState => ({
                  ...prevState,
                  org_data: [
                    ...prevState.org_data,
                    {
                      "data": array2[index].data,
                    }
                  ]
                })
                );

              }


            } else {


              if (array2[index].data[0] === "Division Manager") {
                if (division_list_data.indexOf(array2[index].data[1]) === -1) {
                  division_list_data.push(array2[index].data[1]);
                  console.log("DIVISION LIST:", division_list_data);
                }
              }


              for (var i = 0; i <= 6; i++) {
                col = this.numToSSColumn(i + 1);
                if (i === 0) {
                  if (array2[index].data[i].trim() == "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " Head position missing. <br>";
                  }
                }
                if (i === 3) {
                  if (array2[index].data[i].trim() == "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " First name missing. <br>";
                  }
                }
                if (i === 4) {
                  if (array2[index].data[i].trim() == "") {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " Last name missing. <br>";
                  }
                }

                if (i === 0 || i === 3 || i === 4) { //Role, First & Last Name
                  if (!(/^[a-zA-Z\s]*$/.test(array2[index].data[i].trim()))) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Only letters are allowed) <br>";
                  }
                }
                if (i === 1) { //Division & Team Name
                  if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i].trim()))) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Division name is not valid) <br>";
                  }
                }
                if (i === 2) {
                  cell = 'Cell ' + col + '' + row;
                  if (array2[index].data[0] === "Sales Manager") {
                    if (array2[index].data[i].trim() === "") {
                      fullerror_message = fullerror_message + ' ' + cell + " (Sales team name is required for sales manager.) <br>";
                    } else if (!(/^[a-z\d\-_\s]+$/i.test(array2[index].data[i]))) {
                      fullerror_message = fullerror_message + ' ' + cell + " (Sales team name is not valid) <br>";
                    } else {
                      if (division_list_data.indexOf(array2[index].data[1]) === -1) {
                        col = this.numToSSColumn(1 + 1);
                        cell = 'Cell ' + col + '' + row;
                        fullerror_message = fullerror_message + ' ' + cell + " (Sales Division Name doesn't exist) <br>";
                      }
                    }
                  }
                }
                if (i === 6) { //mobile
                  if (!(/^[0-9]{11,14}$/g.test(array2[index].data[i]))) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' \n' + cell + " (Invalid phone)\n <br>";
                  }
                }
                if (i === 5) { //email
                  let lastAtPos = array2[index].data[i].lastIndexOf('@');
                  let lastDotPos = array2[index].data[i].lastIndexOf('.');
                  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                  if (!pattern.test(array2[index].data[i])) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' \n' + cell + " (Invalid Email)\n <br>";
                  } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && array2[index].data[i].indexOf('@@') === -1 && lastDotPos > 2 && (array2[index].data[i].length - lastDotPos) > 2)) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' \n' + cell + " (Invalid Email)\n <br>";
                  }
                }
              }
              this.setState(prevState => ({
                ...prevState,
                org_data: [
                  ...prevState.org_data,
                  {
                    "data": array2[index].data,
                  }
                ]
              })
              );
            }
          }).slice(0, 1)

        }
      }

    }

    if (fullerror_message == "") {
      this.setState({ fileextractDone: 1 });
    } else {
      this.setState({
        fileextractDone: 0,
        errorCells: fullerror_message,
        MessageType: "danger",
        Message: "file have errors."
      });
    }
    console.log(this.state.org_data);
  };
  numToSSColumn = (num) => {
    var s = '', t;

    while (num > 0) {
      t = (num - 1) % 26;
      s = String.fromCharCode(65 + t) + s;
      num = (num - t) / 26 | 0;
    }
    return s || undefined;
  }
  handleOnError = (err, file, inputElem, reason) => {
    this.setState({ csvErrors: err });
    console.log(this.state.csvErrors);
  };

  handleOnRemoveFile = (data) => {
    this.setState({ csvToJson: "" });
    console.log(this.state.csvToJson);
  };


  getOrganizationData = async () => {
    await axios.get(`organizations/get-organization`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.setState({ orgName: res.data.data[0].org_name })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  render() {
    const { orgName, addDivisionShow, MessageType, Message, fileextractDone, errorCells, ReportFile, users_count } = this.state

    return (<>
      <Modal
        title="Organization Structure"
        contentClasses=""
        size="md"
        onClose={() => this.setAddDivisionShow(false)}
        show={addDivisionShow}>
        {this.addDivision()}
      </Modal>

      <main className="main-contant">
        <div className="contents">
          <h3 className="title-head mt-0 mb-4 mb-lg-5">{orgName}</h3>
          <div className="upload-files mx-auto f2015" >
            <div className="text-center">
              <h5 className="top-fix-head">To create the Organization Setup: </h5>
              <div className="text-area text-left py-4 mx-auto f2015">
                1. Download CSV template. <br />
                2. Populate the template with data. <br />
                3. Drag and drop the CSV file.<br />
                4. Click Upload.
              </div>
              <img src={csv} alt="csv-file" />
              <div className="color_blue py-4 underline">
                <a href="../org_structure_template.csv " className="color_blue" target="_blank">Click here to download CSV template</a>
              </div>
              <div className="color_blue py-4 underline">
                <a href="../org_structure_template.pdf " className="color_blue" target="_blank" download>Click here to download CSV instructions</a>
              </div>
            </div>

            {((Message) && (Message !== null) && (MessageType === "success")) ?
              (
                <div className="upload-successfully mt-2">
                  <div className="drop-zone-wrap p-2">
                    <div className="drop-zone f2015 f-500">
                      <span className="drop-zone__prompt mt-3">
                        <i className="iconmoon icon-check check-successfully"></i>

                        <div className="color_blue mt-3">The organization setup was completed. <br />{users_count} users created.  Click here to download and review the setup report.</div><br />
                        <a href={process.env.REACT_APP_STATIC_FILES_PATH_CSV + `/` + ReportFile} className="color_blue" target="_blank"><i className="ri-download-2-line color_blue"></i>
                          &nbsp; <u>Download Report File</u></a>
                      </span>
                    </div>
                  </div>
                  <div className="text-center py-4">
                    <Link to={`./organization-structure`} type="btn" className="btn dark-btn">View Organization Setup</Link>
                  </div>
                </div>
              ) : (``)
            }

            {((Message) && (Message !== null) && (MessageType === "danger")) ?
              (
                <div className="errors-div">
                  <div className="drop-zone-wrap p-2">
                    <div className="drop-zone f2015 f-500">
                      <span className="drop-zone__prompt mt-3">
                        <i className="iconmoon icon-warning"></i>
                        <div className="color_blue mt-3">The following errors have been found in the uploaded CSV file:<span className="d-sm-block"></span><span dangerouslySetInnerHTML={{ __html: errorCells }}></span><br /><span>Please correct the errors in the CSV file, then click Restart Process and upload the corrected file.</span></div>
                      </span>
                    </div>
                    <div className="text-center py-4">
                      <a href="./initiate-organization-structure" className="color_blue underline f-2015"><i className="fa fa-refresh"></i> Restart Process</a>
                    </div>
                  </div>
                </div>
              ) : (``)
            }

            {((fileextractDone === 0) && ((!Message) || (Message === null) || (MessageType === ""))) ?
              (

                <CSVReader
                  onDrop={this.handleOnDrop}
                  onError={this.handleOnError}
                  addRemoveButton
                  onRemoveFile={this.handleOnRemoveFile}
                  Accept='text/csv, .csv'
                  style={{
                    dropArea: {
                      borderColor: 'gray',
                      borderRadius: 20,
                      maxHeight: 200
                    },
                    dropAreaActive: {
                      borderColor: '#003366',
                    },
                    dropFile: {
                      width: 240,
                      height: 120,
                      background: 'transparent',
                    },
                  }}
                >
                  <div className="drop-zone-wrap p-2" >
                    <div className="drop-zone f2015 f-500">
                      <span className="drop-zone__prompt mt-3">
                        Drag and drop CSV file or
                        <span className="color_blue underline"> Browse here</span>
                      </span>
                    </div>
                  </div>
                </CSVReader>
              ) : (``)}

            {(fileextractDone === 1) ? (
              <div className="upload-successfully mt-2">
                <div className="drop-zone-wrap p-2">
                  <div className="drop-zone f2015 f-500">
                    <span className="drop-zone__prompt mt-3">
                      <i className="iconmoon icon-check check-successfully"></i>
                      <div className="color_blue mt-3">The CSV file was extracted successfully. Click Upload to complete the process.</div>
                    </span>
                  </div>
                </div>
              </div>
            ) : (``)}

            <div className="text-center py-4">
              {(fileextractDone === 1) ? (
                <button onClick={() => this.organizationUpload(1)} type="btn" className="btn dark-btn">Upload</button>
              ) : (``)}
              {((fileextractDone === 0) && (ReportFile === "")) ? (<>
                <div className="or f2015 mt-4 mt-xl-5"><span> or </span></div>
                <div className="color_blue py-4 py-xl-5 underline" onClick={() => this.setAddDivisionShow(true)}>
                  Click here to create the organization setup manually</div>
              </>
              ) : (``)}
            </div>
          </div>
        </div>
      </main>


    </>
    )
  }
}