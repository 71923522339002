import React, { Component } from 'react'
import userprofilecover from "../../../images/user-profile-cover.png";
import profile2x from "../../../images/default-avatar.png";
import Modal from "../modals/modal";
import axios from '../../apiConnection/axios';
import ImageEditor from '../../imageCropper/imageEditor';

export default class myProfile extends Component {
    constructor(props) {

        super(props);
        this.state = {
            showEdit: false,
            otpUserShow: false,
            file: null,
            base64URL: "",
            cropImageShow: false,
            profileImg: profile2x,
            user_id: "",
            username:"",
            first_name: "",
            last_name: "",
            mobile_no: "",
            email_id: "",
            twofa_method : "",
            twofa_enable: "",
            otp:"",
            MessageType2fa : "",
            Message2fa:"",
            Message:"",
            MessageType:"",
            MessageProfilePic:"",
            MessageProfilePicType:"",
            digit1 : "",
            digit2 : "",
            digit3 : "",
            digit4 : "",
            digit5 : "",
            confirmMessage:"",
            time: {}, seconds: 0,
            MessageEmailError : null,
            MessageEmailErrorType : "",
            MessageMobileError : null,
            MessageMobileErrorType : ""

        }
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.startTimerTemp = this.startTimerTemp.bind(this);
        this.countDown = this.countDown.bind(this);        
        this.handleClick = this.handleClick.bind(this);
        this.refreshAlertMessages = this.refreshAlertMessages.bind(this);
        this.closeEditor = this.closeEditor.bind(this);
    }
    componentDidMount(){
     this.getProfile(); 
     let timeLeftVar = this.secondsToTime(this.state.seconds);
     this.setState({ time: timeLeftVar });       
    }
    startTimer = () => {
        if (this.state.seconds===0){
            this.setState({seconds:30}, ()=> {
                this.timer = setInterval(this.countDown, 1000);
                this.sendotp();
            });
        }
      }
      startTimerTemp = () => {
        if (this.state.seconds===0){
            this.setState({seconds:30}, ()=> {
                this.timer = setInterval(this.countDown, 1000);
                this.sendotptemp();
            });
        }
      }    
      countDown() {
        // Remove one second, set state so a re-render happens.
        if (this.state.seconds>0){
            let seconds = this.state.seconds - 1;
            this.setState({
              time: this.secondsToTime(seconds),
              seconds: seconds,
            });
            
            // Check if we're at zero.
            if (seconds == 0) { 
              clearInterval(this.timer);
            }
        }
      }    
    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));
    
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
    
        let obj = {
          "h": hours,
          "m": minutes,
          "s": seconds
        };
        return obj;
      }

    getProfile = async () => {
        if (localStorage.getItem('userid')) {
            var user_id = localStorage.getItem('userid')
            const profileinfo = {
                'user_id': user_id,
            }        
            await axios.post(`/users/get-profile`, profileinfo, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                }
            })
                .then(res => {
                    if (res.data.code === 200) {
                        this.setState(prevState=>({...prevState,
                            user_id: res.data.data.id,
                            first_name: res.data.data.first_name,
                            last_name: res.data.data.last_name,
                            mobile_no: res.data.data.mobile,
                            existing_mobile_no : res.data.data.mobile,
                            email_id: res.data.data.email,
                            existing_email_id: res.data.data.email,
                            username : res.data.data.username,
                            twofa_enable: res.data.data.twofa_enable,
                            twofa_method: (res.data.data.twofa_enable===0) ? 2 : res.data.data.twofa_method,
                            existing_twofa_enable: res.data.data.twofa_enable,
                            existing_twofa_method: (res.data.data.twofa_enable===0) ? 2 : res.data.data.twofa_method,
                            profile_pic_url:res.data.data.profile_pic_url,
                            profileImg: process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((res.data.data.profile_pic_url!=="") && (res.data.data.profile_pic_url!==null) )? res.data.data.profile_pic_url : 'default-avatar.png'),
                            
                        }));
                        var profileImg = process.env.REACT_APP_STATIC_FILES_PATH+'/'+ (((res.data.data.profile_pic_url!=="") && (res.data.data.profile_pic_url!==null) )? res.data.data.profile_pic_url : 'default-avatar.png');

                        localStorage.setItem('profilePic',profileImg);

                        this.formatPhoneNumber(res.data.data.mobile);
                    } 
                })
                .catch((error) => {
                    console.log(error)
                })

        }
    }

    updateProfilePic = async () => {
        if (localStorage.getItem('userid')) {
            var user_id = localStorage.getItem('userid');
        }

    const data = new FormData() 
    data.append('file', this.state.uploadFile, {'user_id' : user_id})
    await axios.post(`/users/update-profile-pic`, 
        data,{
            headers: {
                'user_id': user_id,
                'Accept': 'multipart/form-data',
                'Content-Type': 'multipart/form-data;charset=UTF-8',
            }
        },
        )
        .then(res => { // then print response status
            console.log(res);
            this.getProfile();
            this.setState({
                MessageProfilePic: "Your profile picture was updated successfully. ",
                MessageProfilePicType: 'success'
            });
            
            setTimeout(() => window.location.reload(), 3000);
            setTimeout(() => this.refreshAlertMessages(), 5000);                    

     })
     .catch(err=>{
        console.log(err)
     })
    }

    updateProfileStep2 = async (auth) => {
        const {existing_twofa_method, existing_twofa_enable, email_id, existing_email_id, mobile_no, existing_mobile_no} = this.state;
        console.log(this.state);
        console.log(auth);
        // return;
        if (auth===1){
            console.log('from inside function 1.');
            if (((this.state.digit1).length===1) && ((this.state.digit2).length===1) && ((this.state.digit3).length===1) && ((this.state.digit4).length===1) && ((this.state.digit5).length===1)){
                var otpValue =  this.state.digit1 + this.state.digit2 + this.state.digit3 + this.state.digit4 + this.state.digit5
                console.log('from inside function 2.');
                console.log(otpValue);
        
                var message ="";
                if (existing_email_id!==email_id){
                    message += "Your email was updated successfully. <br/>"
                }
                if (existing_mobile_no!==mobile_no){
                    message += "Your phone number was updated successfully. <br/>"
                }
                
                
                var user_id = this.state.user_id;
                const profileinfo = {
                    'user_id': user_id,
                    'username':this.state.username,
                    'first_name': this.state.first_name,
                    'last_name': this.state.last_name,
                    'otp': otpValue,
                    'mobile_no': this.state.mobile_no,
                    'email_id': this.state.email_id,
                    'profile_image': this.state.profileImg,
                }
                await axios.post(`/users/update-profile`, profileinfo, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                    }
                })
                    .then(res => {
                        console.log(res);
                        if (res.data.code === 200) {
                            this.setEdit(false)
                            this.setState({
                                Message: message,
                                MessageType: 'success'
                            });
                            setTimeout(() => this.refreshAlertMessages(), 5000);                    
                            this.setUpdateConfirmShow(false)                    
                            this.setTempOtpUserShow(false)                    
                        } else if (res.data.code === 201) {
                            this.setState({
                                Message : 'The code you entered is incorrect.',
                                MessageType: 'danger'
                            });
                            setTimeout(() => this.refreshAlertMessages(), 5000);                    
                        }
        
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                } else {
                    this.setState({
                        Message : 'The code you entered is incorrect.',
                        MessageType: 'success'
                    });
                    setTimeout(() => this.refreshAlertMessages(), 5000);                    
                }
        
        } else {
            var message ="";
            if (existing_email_id!==email_id){
                message += "Your email was updated successfully. <br/>"
            }
            if (existing_mobile_no!==mobile_no){
                message += "Your phone number was updated successfully. <br/>"
            }
            
            
            var user_id = this.state.user_id;
            const profileinfo = {
                'user_id': user_id,
                'username':this.state.username,
                'first_name': this.state.first_name,
                'last_name': this.state.last_name,
                'otp': "",
                'mobile_no': this.state.mobile_no,
                'email_id': this.state.email_id,
                'profile_image': this.state.profileImg,
            }
            await axios.post(`/users/update-profile`, profileinfo, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                }
            })
                .then(res => {
                    console.log(res);
                    if (res.data.code === 200) {
                        this.setEdit(false)
                        this.setState({
                            Message: message,
                            MessageType: 'success'
                        });
                        setTimeout(() => this.refreshAlertMessages(), 5000);                    
                        this.setUpdateConfirmShow(false) ;                   
                    } else if (res.data.code === 201) {
                        this.setState({
                            Message : 'Internel error in updating.',
                            MessageType: 'danger'
                        });
                        setTimeout(() => this.refreshAlertMessages(), 5000);                    
                    }
    
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }    

    updateProfile = async () => {
        const {existing_twofa_method, existing_twofa_enable, email_id, existing_email_id, mobile_no, existing_mobile_no} = this.state;
        if (localStorage.getItem('userid')) {
            var user_id = localStorage.getItem('userid')
        }
        var pattern = new RegExp(/^[0-9]{10,13}$/);

        if (((this.state.mobile_no).length<10)  || ((this.state.mobile_no).length>=14) ){
            this.setState({
                Message: 'Invalid Mobile Number.',
                MessageType: "danger"
            });
            return;
        } else if (!pattern.test(this.state.mobile_no)){
            this.setState({
                Message: 'Invalid Mobile Number.',
                MessageType: "danger"
            });
            return;            
        } else if (((this.state.first_name).length<2)  || ((this.state.mobile_no).length>=40) ){
            this.setState({
                Message: 'Invalid first name.',
                MessageType: "danger"
            });
            return;
          
        } else if (((this.state.last_name).length>=40) ){
            this.setState({
                Message: 'Invalid last name.',
                MessageType: "danger"
            });
            return;          
        } else if (((this.state.email_id).length<5)  || ((this.state.email_id).length>50) ){
            this.setState({
                Message: 'Invalid Email address.',
                MessageType: "danger"
            });
            return;          
        } else if ((typeof this.state.email_id !== "undefined")){
            let lastAtPos = this.state.email_id.lastIndexOf('@');
            let lastDotPos = this.state.email_id.lastIndexOf('.');
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.email_id)) {
              this.setState({
                  Message: 'Invalid Email address.',
                  MessageType: "danger"
              });
              return;
          } else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email_id.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email_id.length - lastDotPos) > 2)) {
              this.setState({
                  Message: 'Invalid Email address.',
                  MessageType: "danger"
              });
              return;
          }
        }  


        var messageshow = "";
        if (existing_twofa_enable===1){
            if (existing_twofa_method===1){
                if (existing_email_id!==email_id){
                    messageshow = "Changing your email address will cause your user name to change to the email ID.<br/>Changing your email will affect your 2FA authentication.<br/>Do you want to change your email address"
                    this.setUpdateConfirmShow(true, messageshow, 1);
                    //need to add email verification.
                    return;            
                }
            } else if (existing_twofa_method===0){
               if ((existing_mobile_no===mobile_no) && (existing_email_id!==email_id)){
                   messageshow = "Changing your email address will cause your user name to change to the email ID.<br/>Do you want to change your email address"
                   this.setUpdateConfirmShow(true, messageshow);
                   return;            
                }
               if ((existing_mobile_no!==mobile_no) && (existing_email_id===email_id)){
                    messageshow = "Changing your phone number will affect your 2FA authentication.<br/>Do you want to change your phone number"
                    this.setUpdateConfirmShow(true, messageshow, 1);
                    //need to add mobile verification.
                    return;            
                }
               if ((existing_mobile_no!==mobile_no) && (existing_email_id!==email_id)){
                    messageshow = "Changing your email address will cause your user name to change to the email ID,<br/>Changing your phone number will affect your 2FA authentication.<br/>Do you want to change your phone number"
                    this.setUpdateConfirmShow(true, messageshow, 1);
                    //need to add mobile verification.
                    return;            
                }
             }

        } else {
            if (existing_email_id!==email_id){
                messageshow = "Changing your email address will cause your user name to change to the email ID.<br/>Do you want to change your email address"
                this.setUpdateConfirmShow(true, messageshow);
                return;            
            }    
        }
        

        var message ="";
        if (existing_email_id!==email_id){
            message += "Your email was updated successfully. <br/>"
        }
        if (existing_mobile_no!==mobile_no){
            message += "Your phone number was updated successfully. <br/>"
        }
        if (message===""){
            message += "Profile updated successfully. <br/>";
        }
 
        
        const profileinfo = {
            'user_id': user_id,
            'username':this.state.username,
            'first_name': this.state.first_name,
            'last_name': this.state.last_name,
            'mobile_no': this.state.mobile_no,
            'email_id': this.state.email_id,
            'profile_image': this.state.profileImg,
        }
        await axios.post(`/users/update-profile`, profileinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res);
                if (res.data.code === 200) {
                    this.setEdit(false)
                    this.setState({
                        Message: message,
                        MessageType: 'success',
                    });
                    setTimeout(() => this.refreshAlertMessages(), 5000);                    
                    this.setUpdateConfirmShow(false);                    
                } else if (res.data.code === 201) {
                    this.setState({
                        Message: "Error in update.",
                        MessageType: 'danger'
                    });
                    setTimeout(() => this.refreshAlertMessages(), 5000);                    
                    this.getProfile();   
                    this.setUpdateConfirmShow(false);                    
                }

            })
            .catch((error) => {
                console.log(error)
            })

    }
    changeFunction = (e) => {
        if (e.target.id === 'first_name') {
            this.setState({ first_name: e.target.value });
        } else if (e.target.id === 'last_name') {
            this.setState({ last_name: e.target.value });
        }
        else if (e.target.id === 'mobile_no') {
            this.formatPhoneNumber(e.target.value);
            this.setState({ mobile_no: e.target.value }, async ()=>{

                const {email_id, existing_email_id, mobile_no, existing_mobile_no} = this.state;
                if (mobile_no!==existing_mobile_no){
                    if (localStorage.getItem('userid')) {
                        var user_id = localStorage.getItem('userid')
                    }
                    const profileinfo = {
                        'user_id': user_id,
                        'mobile_no': this.state.mobile_no,
                    }
                    await axios.post(`/users/check-mobile-exist`, profileinfo, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8',
                        }
                    })
                        .then(res => {
                            console.log(res);
                            if (res.data.code === 200) {
                                this.setState({
                                    MessageMobileError: null,
                                    MessageMobileErrorType: null,
                                });
                            } else if (res.data.code === 201) {
                                this.setState({
                                    MessageMobileError: res.data.message,
                                    MessageMobileErrorType: null,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })

                }
        
            });
        }
        else if (e.target.id === 'email_id') {
            var username = 	e.target.value.substring(0, e.target.value.indexOf("@"));
            this.setState({ email_id: e.target.value, username: username}, async ()=>{
                const {email_id, existing_email_id, mobile_no, existing_mobile_no} = this.state;
                if (email_id!==existing_email_id){
                    if (localStorage.getItem('userid')) {
                        var user_id = localStorage.getItem('userid')
                    }
                    const profileinfo = {
                        'user_id': user_id,
                        'email_id': this.state.email_id,
                    }
                    await axios.post(`/users/check-email-exist`, profileinfo, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8',
                        }
                    })
                        .then(res => {
                            console.log(res);
                            if (res.data.code === 200) {
                                this.setState({
                                    MessageEmailError: null,
                                    MessageEmailErrorType: null,
                                });
                            } else if (res.data.code === 201) {
                                this.setState({
                                    MessageEmailError: res.data.message,
                                    MessageEmailErrorType: null,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })

                }

            });
        }
        console.log(this.state.email_id);
    }
    handleClick(e) {
        if (this.state.fileworking!==true){
            this.setState({fileworking : true});
            this.inputFile.click();
        } else {
            this.setState({fileworking : false});
        }
    }
    getImgFromCrop = async (valu) => {
        this.setState({ profileImg: valu })
        console.log(valu)
        await fetch(valu)
        .then(res => res.blob())
        .then(blob => {
            const file = new File([blob], "testfile",{ type: "image/png" })
            const url = URL.createObjectURL(file);
            this.setState({ profileImg: url , uploadFile:file})
            console.log(url);
            this.updateProfilePic();
            this.setState({
                base64URL: "",
                file:null

            })
            this.setcropImage(false);
        })
    }
    closeEditor = () => {
        this.setState({ profileImg: "" , uploadFile:null})
        this.setState({ base64URL: "", file:null })
        this.setcropImage(false);        
        this.getProfile();
        this.componentDidMount();
    }
    setcropImage = (newValue) => {
        this.setState({ cropImageShow: newValue });
    }
    cropImage = () => {
        return (<>
            <ImageEditor base64URL={this.state.base64URL} closeEditor={this.closeEditor} getImgFromCrop={this.getImgFromCrop} />
        </>);
    }

    getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("Called", reader);
                baseURL = reader.result;
                console.log(baseURL);
                resolve(baseURL);
            };
            console.log(fileInfo);
        });
    };

    handleFileInputChange = e => {
        console.log(e.target.files[0]);
        let { file } = this.state;

        file = e.target.files[0];

        this.getBase64(file)
            .then(result => {
                file["base64"] = result;
                console.log("File Is", file);
                this.setState({
                    base64URL: result,
                    file
                });
            })
            .catch(err => {
                console.log(err);
            });

        this.setState({
            file: e.target.files[0]
        });
        if (e.target.files[0]!= undefined){
            this.setcropImage(true);
        }

    };

    enable2fa = () => {
        if (this.state.twofa_method!==2){
            this.setState({Message2fa: "", MessageType2fa:null});

            if (this.state.twofa_enable===0){
                this.setState({twofa_enable: 1}, () => {

                    this.setOtpUserShow(true);
                    this.sendotp();

                });
            } else if (this.state.twofa_enable===1){
                this.setState({ twofa_enable: 0, twofa_method : 2 }, () => {
                    this.update2fa();
                  }); 
                //diable 2fa
            }
        } else {
            this.setState({Message2fa: "Please select Email or Mobile.", MessageType2fa:"danger"});
            setTimeout(() => this.refreshAlertMessages(), 5000);                    

        }

    }


    sendotptemp = async () => {
        var method = this.state.twofa_method; // 1 for email and 0 for mobile.
        var user_id = this.state.user_id; // get user_id
        var mobile = this.state.mobile_no;
        var email = this.state.email_id
        var redirect_url = "";
        const profileinfo = {
            'userid': user_id,
            'method': method,
            'email' : email,
            'mobile' : mobile,
        }
        console.log(profileinfo);
        await axios.post(`/users/send-otp-temp`, profileinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res);
                if (res.data.code === 200) {
                    console.log('OTP Sent.')
                } 
            })
            .catch((error) => {
                console.log(error)
            })
    }

    sendotp = async () => {
        if (this.state.twofa_method===1){
            var emailormobile = this.state.email_id;
        } else if (this.state.twofa_method===0) {
            var emailormobile = this.state.mobile_no;
        }
        var method = this.state.twofa_method; // 1 for email and 0 for mobile.
        var redirect_url = "";
        const profileinfo = {
            'emailormobile': emailormobile,
            'method': method,
            'redirect_url': redirect_url,
        }
        console.log(profileinfo);
        await axios.post(`/users/send-otp`, profileinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res);
                if (res.data.code === 200) {
                    console.log('OTP Sent.')
                } 
            })
            .catch((error) => {
                console.log(error)
            })
    }

    update2fa = async () => {
        if (this.state.twofa_enable===1){
            if (((this.state.digit1).length===1) && ((this.state.digit2).length===1) && ((this.state.digit3).length===1) && ((this.state.digit4).length===1) && ((this.state.digit5).length===1)){
                var otpValue =  this.state.digit1 + this.state.digit2 + this.state.digit3 + this.state.digit4 + this.state.digit5
    
            if (this.state.twofa_method===1){
                var emailormobile = this.state.email_id;
            } else if (this.state.twofa_method===0) {
                var emailormobile = this.state.mobile_no;
            }
            var method = this.state.twofa_method; // 1 for email and 0 for mobile.
            var redirect_url = "";
            var otp = this.state.otp;
            const profileinfo = {
                'emailormobile': emailormobile,
                'method': method,
                'redirect_url': redirect_url,
                'otp': otpValue,
                'enable':1
            }
            console.log(profileinfo);
            await axios.post(`/users/update2fa`, profileinfo, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                }
            })
                .then(res => {
                    console.log(res);
                    if (res.data.code === 200) {
                        console.log('Verified.')
                        this.setState({
                            Message2fa : "2FA has been activated.",
                            MessageType2fa : "success"
                        })
                        setTimeout(() => this.refreshAlertMessages(), 5000);                                            
                        this.setOtpUserShow(false);
                        this.getProfile();   
                        this.showConfirmation(false);        

                    } else {
                        this.setState({
                            Message2fa : 'The code you entered is incorrect.',
                            MessageType2fa : "danger"
                        })            
                        setTimeout(() => this.refreshAlertMessages(), 5000);                                            
                    } 
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({
                        Message2fa : 'The code you entered is incorrect.',
                        MessageType2fa : "danger"
                    })
                    setTimeout(() => this.refreshAlertMessages(), 5000);                                        
            })
        } else {
            this.setState({
                Message2fa : 'The code you entered is incorrect.',
                MessageType2fa : "danger"
            })
            setTimeout(() => this.refreshAlertMessages(), 5000);                                
        }

    } else {

        var method = 2; // 1 for email and 0 for mobile.
        var redirect_url = "";
        var otp = "0";
        const profileinfo = {
            'emailormobile': this.state.email_id,
            'method': method,
            'redirect_url': redirect_url,
            'otp': otpValue,
            'enable':0
        }
        console.log(profileinfo);
        await axios.post(`/users/update2fa`, profileinfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res);
                if (res.data.code === 200) {
                    this.setState({
                        Message2fa : "2FA has been deactivated. Your account security is affected.",
                        MessageType2fa : "danger"
                    })
                    setTimeout(() => this.refreshAlertMessages(), 5000);                    
                    this.getProfile();
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    Message2fa : 'Internal error in update.',
                    MessageType2fa : "danger"
                })
                setTimeout(() => this.refreshAlertMessages(), 5000);                    
               
        })
    }        


}

    refreshAlertMessages = () => {
        console.log('i called.');
        this.setState({
            Message2fa : "",
            MessageType2fa : null,
            Message : "",
            MessageType : null,
            MessageProfilePic:"",
            MessageProfilePicType:null,
        })
    }
    handleCheckbox = (method) => (e) => {
        e.preventDefault();

        if (this.state.showEdit===false){
            var existing = this.state.twofa_method;
            if (existing!==method){
                this.setState({twofa_enable: 0,
                twofa_method:method
                }, ()=> {
                    this.showConfirmation(true);
                });
            }
        }
      } 

    showConfirmation = (newValue) => {
        this.setState({ showConfirmation: newValue ,
            confirmMessage : "Changing the 2FA method will enforce the account authentication via the new method. Do you want to continue with the update"
        });
        if (newValue===false) {
            this.getProfile();   
            this.setState({confirmMessage:""});
        } else {
            this.setState({
                Message2fa:null,
                MessageType2fa:"",    
            })
        }
        
    }
    
    
    updateConfirmForMethod = () => {
        var {confirmMessage} = this.state;
      return (<>
        <div className="my-3">
            {(confirmMessage!=="") ? (
                <span className="m-0 text-center" dangerouslySetInnerHTML={{__html: `<p>${confirmMessage}?</p>`}}></span>
            ) : (``)} 
          <div className="modal-footer border-0 justify-content-center">
            <button type="button" className="btn dark-btn mx-2" onClick={() => this.enable2fa()}>Yes</button>
            <button type="button" className="btn light-btn mx-2" onClick={() => this.showConfirmation(false)} >No</button>
          </div>
  
        </div>
      </>);
    }
        
    
    handleChangeUP1 = (e) => {
        const { maxLength, value, name } = e.target;
        if (parseInt(e.target.value)>9){
            e.target.value = e.target.value.substring(1, 1) ;
            e.preventDefault();
            return;
        }
    }

    handleChange1 = (e) => {
        const { maxLength, value, name } = e.target;

        const [fieldName, fieldIndex] = name.split("-");
        if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode === 8) || (e.keyCode ===8 )) { 
            // 0-9 only
        } else {
            e.preventDefault();
            return;
        }

        let fieldIntIndex = parseInt(fieldIndex, 10);
        if (fieldIntIndex === 5) {
            if ((e.keyCode ===8 ) && (value.length===0)){
                const prevfield = document.querySelector(
                    `input[name=digittemp-${fieldIntIndex -1 }]`
                  );
                  if (prevfield !== null) {
                    prevfield.focus();
                    return;
                  }                
            }
            if (e.keyCode === 8) {
                const currentfield = document.querySelector(
                    `input[name=digittemp-${fieldIntIndex}]`
                  );
                  if (currentfield !== null) {
                    currentfield.focus();
                    return;
                  }
            }
            if (value.length >= maxLength) {
                e.preventDefault();
                return;
            }

        }
        if (e.keyCode === 8) {
            if (fieldIntIndex >= 1) {

                // Get the next input field using it's name
                const prevfield = document.querySelector(
                    `input[name=digittemp-${fieldIntIndex - 1}]`
                  );
            
                  // If found, focus the prev field
                  if (prevfield !== null) {
                      prevfield.value="";
                    prevfield.focus();
                  }
                }
        }
      
        // Check if no of char in field == maxlength
        if (value.length === maxLength) {
      
          // It should not be last input field
          if (fieldIntIndex < 5) {
      
            // Get the next input field using it's name
            const nextfield = document.querySelector(
              `input[name=digittemp-${fieldIntIndex + 1}]`
            );
      
            // If found, focus the next field
            if (nextfield !== null) {
              nextfield.focus();
            }
          }
        } 
      }; 

      
    changeFunctionOTPtemp = (e) => {
        var s = e.target.value;
        var s1 = s.substring(0, 1);
            if (e.target.id === 'digittemp-1') {
                this.setState({ digit1: e.target.value });
            } else if (e.target.id === 'digittemp-2') {
                this.setState({ digit2: e.target.value });
            } else if (e.target.id === 'digittemp-3') {
                this.setState({ digit3: e.target.value });
            } else if (e.target.id === 'digittemp-4') {
                this.setState({ digit4: e.target.value });
            } else if (e.target.id === 'digittemp-5') {
                this.setState({ digit5: e.target.value });
            }
    }
   

    handleChangeUP = (e) => {
        const { maxLength, value, name } = e.target;
        if (parseInt(e.target.value)>9){
            e.target.value = e.target.value.substring(1, 1) ;
            e.preventDefault();
            return;
        }
    }

    handleChange = (e) => {
        const { maxLength, value, name } = e.target;

        const [fieldName, fieldIndex] = name.split("-");
        if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode === 8) || (e.keyCode ===8 )) { 
            // 0-9 only
        } else {
            e.preventDefault();
            return;
        }

        let fieldIntIndex = parseInt(fieldIndex, 10);
        if (fieldIntIndex === 5) {
            if ((e.keyCode ===8 ) && (value.length===0)){
                const prevfield = document.querySelector(
                    `input[name=digit-${fieldIntIndex -1 }]`
                  );
                  if (prevfield !== null) {
                    prevfield.focus();
                    return;
                  }                
            }
            if (e.keyCode === 8) {
                const currentfield = document.querySelector(
                    `input[name=digit-${fieldIntIndex}]`
                  );
                  if (currentfield !== null) {
                    currentfield.focus();
                    return;
                  }
            }
            if (value.length >= maxLength) {
                e.preventDefault();
                return;
            }

        }
        if (e.keyCode === 8) {
            if (fieldIntIndex >= 1) {

                // Get the next input field using it's name
                const prevfield = document.querySelector(
                    `input[name=digit-${fieldIntIndex - 1}]`
                  );
            
                  // If found, focus the prev field
                  if (prevfield !== null) {
                      prevfield.value="";
                    prevfield.focus();
                  }
                }
        }
      
        // Check if no of char in field == maxlength
        if (value.length === maxLength) {
      
          // It should not be last input field
          if (fieldIntIndex < 5) {
      
            // Get the next input field using it's name
            const nextfield = document.querySelector(
              `input[name=digit-${fieldIntIndex + 1}]`
            );
      
            // If found, focus the next field
            if (nextfield !== null) {
              nextfield.focus();
            }
          }
        } 
      }; 


    changeFunctionOTP = (e) => {
        var s = e.target.value;
        var s1 = s.substring(0, 1);
            if (e.target.id === 'digit-1') {
                this.setState({ digit1: e.target.value });
            } else if (e.target.id === 'digit-2') {
                this.setState({ digit2: e.target.value });
            } else if (e.target.id === 'digit-3') {
                this.setState({ digit3: e.target.value });
            } else if (e.target.id === 'digit-4') {
                this.setState({ digit4: e.target.value });
            } else if (e.target.id === 'digit-5') {
                this.setState({ digit5: e.target.value });
            }
    }
   
    setEdit = (newValue) => {
        this.setState({ showEdit: newValue ,
            Message:null,
            MessageType:""
        });
        if (newValue===false) {
            this.getProfile();   
        }
    }

    setOtpUserShow = (newValue) => {
        if (newValue===true){
            this.setState({ otpUserShow: newValue });
            this.setState({ showEdit: false ,
                Message2fa:null,
                MessageType2fa:""
            });
        } else {
            this.setState({ otpUserShow: newValue });
            this.showConfirmation(false);        
        }
    }
    otpUser = () => {
        return (<>

            <div className="close-popup text-right" onClick={() => this.setOtpUserShow(false)}><i className="ri-close-line color_blue ri-2x"></i></div>
            <div className="form-wrap py-lg-5 my-lg-4 my-3 py-4  d-flex align-items-center justify-content-center">
                <div className="w-100">
                    <h3 className="text-center mt-0 mb-3">
                        Authenticate Your Account
                    </h3>
                    <p className="text-center mb-lg-5 mb-4 color_light_gray">
                        Please confirm your account by entering the authorization code sent to <span
                            className="f-600 color-dark"> 
                            {(this.state.twofa_method===1) ? (this.state.email_id) : ('+'+this.state.mobile_no)}
                            </span>
                    </p>
                    {((this.state.Message2fa) && (this.state.Message2fa !== null)) ?
                                                            (
                                                                <div data-dismiss="alert" aria-label="Close"  className={`alert alert-${this.state.MessageType2fa}`}>
                                                                    {this.state.Message2fa}
                                                                </div>
                                                            ) : (``)
                                                        }                    
                    <form className="digit-group">
                        <div className="form-group mb-lg-5">
                            <div className="otp-input d-flex justify-content-between">
                            <input className="form-control f-600 f2015" type="number" id="digit-1" onChange={this.changeFunctionOTP}
                                                name="digit-1" data-next="digit-2" min="1" max="9"  onKeyDown={this.handleChange} onKeyUp={this.handleChangeUP} ref={(ip1) => this.myInp1 = ip1}  maxLength="1" />
                                            <input className="form-control f-600 f2015" type="number" id="digit-2" onChange={this.changeFunctionOTP}
                                                name="digit-2" data-next="digit-3" data-previous="digit-1" min="0" max="9" onKeyDown={this.handleChange} onKeyUp={this.handleChangeUP} ref={(ip2) => this.myInp2 = ip2} maxLength="1"/>
                                            <input className="form-control f-600 f2015" type="number" id="digit-3" onChange={this.changeFunctionOTP}
                                                name="digit-3" data-next="digit-4" data-previous="digit-2" min="0" max="9" onKeyDown={this.handleChange} onKeyUp={this.handleChangeUP} ref={(ip3) => this.myInp3 = ip3} maxLength="1"/>
                                            <input className="form-control f-600 f2015" type="number" id="digit-4" onChange={this.changeFunctionOTP}
                                                name="digit-4" data-next="digit-5" data-previous="digit-3" min="0" max="9" onKeyDown={this.handleChange} onKeyUp={this.handleChangeUP} ref={(ip4) => this.myInp4 = ip4} maxLength="1"/>
                                            <input className="form-control f-600 f2015" type="number" id="digit-5" onChange={this.changeFunctionOTP}
                                                name="digit-5" data-next="digit-6" data-previous="digit-4" min="0" max="9" onKeyDown={this.handleChange} onKeyUp={this.handleChangeUP} ref={(ip5) => this.myInp5 = ip5} maxLength="1"/>
                                
                            </div>
                        </div>
                        {/* <div>
                                <a onClick={this.startTimer}>Start</a>
                                m: {this.state.time.m} s: {this.state.time.s}
                            </div>                           */}
                        <button type="button" className="btn dark-btn  w-100" onClick={() => this.update2fa()}>Confirm</button>
                        <div className="text-center mt-3">
                            <div className="color_blue">
                                {(this.state.time.s===0) ? (<>
                                    <a className="underline color_blue" onClick={() => this.startTimer()} > Resend OTP</a>
                                    </>                                    
                                ) : (
                                    <>
                                    {/* Please wait {this.state.time.s} sec('s) for
                                    <i className="ri-restart-line  vertical-bottom color_light_gray"></i> */}
                                    <i className="color_light_gray" > Resend OTP</i> in {this.state.time.s} seconds.
                                    </>

                                )}
                            </div>                        
                        </div>
                    </form>
                </div>
            </div>
        </>);
    }


    setTempOtpUserShow = (newValue) => {
        if (newValue===true){
            this.setState({ otpTempUserShow: newValue });
            this.setState({
                Message:null,
                MessageType:""
            });
            this.sendotptemp();
        } else {
            this.setState({ otpTempUserShow: newValue, });
            this.setEdit(false);
        }
    }
    tempotpUser = () => {
        return (<>

            <div className="close-popup text-right" onClick={() => this.setTempOtpUserShow(false)}><i className="ri-close-line color_blue ri-2x"></i></div>
            <div className="form-wrap py-lg-5 my-lg-4 my-3 py-4  d-flex align-items-center justify-content-center">
                <div className="w-100">
                    <h3 className="text-center mt-0 mb-3">
                        Authenticate Your Account
                    </h3>
                    <p className="text-center mb-lg-5 mb-4 color_light_gray">
                        Please confirm your account by entering the authorization code sent to <span
                            className="f-600 color-dark"> 
                            {(this.state.twofa_method===1) ? (this.state.email_id) : ('+'+this.state.mobile_no)}
                            </span>
                    </p>
                    {((this.state.Message) && (this.state.Message !== null)) ?
                                                            (
                                                                <div data-dismiss="alert" aria-label="Close"  className={`alert alert-${this.state.MessageType}`}>
                                                                    {this.state.Message}
                                                                </div>
                                                            ) : (``)
                                                        }                    
                    <form className="digit-group">
                        <div className="form-group mb-lg-5">
                            <div className="otp-input d-flex justify-content-between">
                            <input className="form-control f-600 f2015" type="number" id="digittemp-1" onChange={this.changeFunctionOTPtemp}
                                                name="digittemp-1" data-next="digittemp-2" min="1" max="9"  onKeyDown={this.handleChange1} onKeyUp={this.handleChangeUP1} ref={(ip11) => this.myInp11 = ip11}  maxLength="1" />
                                            <input className="form-control f-600 f2015" type="number" id="digittemp-2" onChange={this.changeFunctionOTPtemp}
                                                name="digittemp-2" data-next="digittemp-3" data-previous="digittemp-1" min="0" max="9" onKeyDown={this.handleChange1} onKeyUp={this.handleChangeUP1} ref={(ip12) => this.myInp12 = ip12} maxLength="1"/>
                                            <input className="form-control f-600 f2015" type="number" id="digittemp-3" onChange={this.changeFunctionOTPtemp}
                                                name="digittemp-3" data-next="digittemp-4" data-previous="digittemp-2" min="0" max="9" onKeyDown={this.handleChange1} onKeyUp={this.handleChangeUP1} ref={(ip13) => this.myInp13 = ip13} maxLength="1"/>
                                            <input className="form-control f-600 f2015" type="number" id="digittemp-4" onChange={this.changeFunctionOTPtemp}
                                                name="digittemp-4" data-next="digittemp-5" data-previous="digittemp-3" min="0" max="9" onKeyDown={this.handleChange1} onKeyUp={this.handleChangeUP1} ref={(ip14) => this.myInp14 = ip14} maxLength="1"/>
                                            <input className="form-control f-600 f2015" type="number" id="digittemp-5" onChange={this.changeFunctionOTPtemp}
                                                name="digittemp-5" data-next="digittemp-6" data-previous="digittemp-4" min="0" max="9" onKeyDown={this.handleChange1} onKeyUp={this.handleChangeUP1} ref={(ip15) => this.myInp15 = ip15} maxLength="1"/>
                                
                            </div>
                        </div>
                        {/* <div>
                                <a onClick={this.startTimer}>Start</a>
                                m: {this.state.time.m} s: {this.state.time.s}
                            </div>                           */}
                        <button type="button" className="btn dark-btn  w-100" onClick={() => this.updateProfileStep2(1)}>Confirm</button>
                        <div className="text-center mt-3">
                            <div className="color_blue">
                                {(this.state.time.s===0) ? (<>
                                    <a className="underline color_blue" onClick={() => this.startTimerTemp()} > Resend OTP</a>
                                    </>                                    
                                ) : (
                                    <>
                                        <i className="color_light_gray" > Resend OTP</i> in {this.state.time.s} seconds.                                    
                                    </>
                                )}
                            </div>                        
                        </div>
                    </form>
                </div>
            </div>
        </>);
    }
    

    formatPhoneNumber = (str) => {
    if (str!== null && str!=="" && str.length>=10) {
        var newArray = str.match
                (/^([0-9]{1,3})?(\d{3})(\d{3})(\d{4})$/);
  
        // Checking the international code
        var intlCountryCode = (newArray[1] ? '+'+newArray[1] : '');
  
        // Resolving the above array we get
        // the international number
        var internationalNumber = intlCountryCode +
               '-'+ newArray[2] + '-' + newArray[3]
                + '-' + newArray[4];
  
            this.setState({formatedMobileNo: internationalNumber})
      
        return null
    } else {
        return null
    }
      };    



      setUpdateConfirmShow = (newValue, confirmMessage, sendotp) => {
        if (newValue === true) {
          this.setState({ updateConfirmshow: newValue, confirmMessage, });
        } else {
          this.setState({
            updateConfirmshow: newValue, confirmMessage:""
          });
        }
        if (sendotp===1){
            this.setState({sentTempOTP: sendotp});            
        } else {
            this.setState({sentTempOTP: 0});            
        }
      }
    
      updateConfirm = () => {
          var {confirmMessage, sentTempOTP} = this.state;
        return (<>
          <div className="my-3">
              {(confirmMessage!=="") ? (
                  <span className="m-0 text-center" dangerouslySetInnerHTML={{__html: `<p>${confirmMessage}?</p>`}}></span>
              ) : (``)} 
            <div className="modal-footer border-0 justify-content-center">
                {(sentTempOTP===1) ? (
                  <button type="button" className="btn dark-btn mx-2" onClick={() => this.setTempOtpUserShow(true)}>Yes</button>
              ) : (
                <button type="button" className="btn dark-btn mx-2" onClick={() => this.updateProfileStep2()}>Yes</button>
                )}
              <button type="button" className="btn light-btn mx-2" onClick={() => this.setUpdateConfirmShow(false)} >No</button>
            </div>
    
          </div>
        </>);
      }
      
      
    render() {
        

        const { showEdit, otpTempUserShow,showConfirmation, updateConfirmshow, otpUserShow, cropImageShow, profileImg, MessageProfilePic, MessageProfilePicType, Message, MessageType,  Message2fa, MessageType2fa, first_name, last_name, email_id, username, mobile_no, profile_pic_url, twofa_enable, twofa_method, formatedMobileNo,
        MessageEmailError, MessageMobileError } = this.state;
        return (
            
            <>
                <Modal
                    title=""
                    contentClasses="py-3 py-lg-5"
                    size="md"
                    onClose={() => this.showConfirmation(false)}
                    show={showConfirmation}>
                    {this.updateConfirmForMethod()}
                </Modal>            
                <Modal
                    title=""
                    contentClasses="py-3 py-lg-5"
                    size="md"
                    onClose={() => this.setUpdateConfirmShow(false)}
                    show={updateConfirmshow}>
                    {this.updateConfirm()}
                </Modal>
                <Modal
                    title=""
                    contentClasses=""
                    size="md"
                    onClose={() => this.setcropImage(false)}
                    show={cropImageShow}>
                    {this.cropImage()}
                </Modal>


                <Modal
                    title=""
                    contentClasses=""
                    size="md"
                    onClose={() => this.setOtpUserShow(false)}
                    show={otpUserShow}>
                    {this.otpUser()}
                </Modal>


                <Modal
                    title=""
                    contentClasses=""
                    size="md"
                    onClose={() => this.setTempOtpUserShow(false)}
                    show={otpTempUserShow}>
                    {this.tempotpUser()}
                </Modal>
                <main className="main-contant">
                    <div className="content">
                        <div className="user-profile-wrap mt-0 pb-5">
                            <div className="container-c px-2">
                                <div className="cover-banner">
                                    <img src={userprofilecover} alt="" />
                                </div>
                                <form>
                                  
                                    <div className="user-info-wrap mb-4">
                                        <div className="profile-img-wrap profile-pic ">
                                            <img id="editprofile" src={profileImg} alt="profile" />

                                            {/* <img src={profile2x} alt=""/> */}
                                            <div className="p-image" onClick={this.handleClick}>
                                                <i className="fa fa-camera upload-button" id="editprofilebtn" ></i>
                                                <input className="file-upload" id="editprofilefile" ref={(ref) => this.inputFile = ref} onChange={(e)=> this.handleFileInputChange(e)} type="file" accept="image/*" />
                                            </div>
                                        </div>
                                        <div className="profile-info py-3 w-100 text-center">
                                        {((MessageProfilePic) && (MessageProfilePic !== null)) ?
                                                            (
                                                                <div data-dismiss="alert" aria-label="Close"  className={`alert alert-${MessageProfilePicType}`}  dangerouslySetInnerHTML={{__html: MessageProfilePic}}/>
                                                            ) : (``)
                                                        }                                               

                                            <div className="name f-500">
                                                {first_name} {last_name}
                                            </div>
                                            <div className="designation">
                                                System Admin
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="profile-info-card px-lg-4 px-3 py-3">

                                                {(showEdit) ? (
                                                    <div id={`form-unlocked`} className={`pb-3`}>

                                                        {((Message) && (Message !== null)) ?
                                                            (
                                                                <div data-dismiss="alert" aria-label="Close"  className={`alert alert-${MessageType}`}  dangerouslySetInnerHTML={{__html: Message}}/>
                                                            ) : (``)
                                                        }
                                                        <div className="row mb-lg-4">
                                                            <div className="col-6">
                                                                <label htmlFor="" className="mb-0">First Name</label>
                                                                <input type="text" id='first_name' className="form-control f-600 f2015" defaultValue={first_name}
                                                                    placeholder="First Name" onChange={this.changeFunction} />

                                                            </div>
                                                            <div className="col-6">
                                                                <label htmlFor="" className="mb-0">Last Name</label>
                                                                <input type="text" id='last_name' className="form-control f-600 f2015" defaultValue={last_name}
                                                                    placeholder="Last Name" onChange={this.changeFunction} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-lg-4">
                                                            <label htmlFor="" className="mb-0">Mobile</label>
                                                            <input type="number" id='mobile_no' className="form-control f-600 f2015" defaultValue={mobile_no}
                                                                placeholder="Mobile" onChange={this.changeFunction} />
                                                             <p className="color_red">{((MessageMobileError) && (MessageMobileError !== null)) ?(MessageMobileError) : (``) }</p>

                                                        </div>
                                                        <div className="form-group mb-lg-4">
                                                            <label htmlFor="" className="mb-0">Email</label>
                                                            <input type="email" id='email_id'  className="form-control f-600 f2015" defaultValue={email_id}
                                                                placeholder="Email" onChange={this.changeFunction}  autocomplete={`no`} />
                                                             <p className="color_red">{((MessageEmailError) && (MessageEmailError !== null)) ?(MessageEmailError) : (``) }</p>
                                                        </div>
                                                        <div className="form-group mb-lg-4">
                                                            <label className="mb-0">User Name</label>
                                                            <div className="feild-text py-3 f-600 f2015 small-font">
                                                                {username}
                                                            </div>
                                                        </div>
                                                        <div className="justify-content-center">
                                                            {((MessageMobileError===null) && (MessageEmailError===null)) ? (
                                                                <button type="button" className="btn dark-btn mx-2" onClick={() => this.updateProfile()} >Save</button>
                                                            ) : (
                                                                <button type="button" className="btn dark-btn mx-2 disabled" >Save</button>
                                                            )} 
                                                            <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setEdit(false)}>Cancel</button>
                                                        </div>

                                                    </div>
                                                ) : (
                                                    <div id={`form-locked`}>
                                                        {((Message) && (Message !== null)) ?
                                                            (
                                                                <div data-dismiss="alert" aria-label="Close"  className={`alert alert-${MessageType}`}  dangerouslySetInnerHTML={{__html: Message}}/>
                                                            ) : (``)
                                                        }                                               
                                                        <div className="form-group mb-lg-4">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <label className="mb-0">Mobile Number</label>
                                                                <div className="color_blue">
                                                                    <i className="iconmoon icon-edit2 vertical-bottom"></i>
                                                                    <a className="color_blue underline " onClick={() => this.setEdit(true)} > Edit
                                                                        Details</a>
                                                                </div>
                                                            </div>
                                                            <div className="feild-text py-3 f-600">
                                                                {formatedMobileNo}
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-lg-4">
                                                            <label className="mb-0">Email</label>
                                                            <div className="feild-text py-3 f-600">
                                                                {email_id}
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-lg-4">
                                                            <label className="mb-0">Username</label>
                                                            <div className="feild-text py-3 f-600">
                                                                {username}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="profile-info-card px-lg-4 px-3 py-3">
                                                <div className="form-group mb-lg-5">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                        <div className="w-75">
                                                            <div className="feild-text f-600">
                                                                2 Factor Authentication
                                                            </div>
                                                            <div className="color_light_gray f-12">Activating 2FA will require entering a one time password which will be sent to your email or phone.</div>
                                                        </div>
                                                        <label className="switch d-block m-0">
                                                            <input type="checkbox" disabled={this.state.showEdit===true} checked={this.state.twofa_enable===1} onClick={this.enable2fa}/>
                                                            <span className="slider"></span>
                                                        </label>
                                                    </div>
                                                    {((Message2fa) && (Message2fa !== null)) ?
                                                            (
                                                                <div data-dismiss="alert" aria-label="Close"  className={`alert alert-${MessageType2fa}`}>
                                                                    {Message2fa}
                                                                </div>
                                                            ) : (``)
                                                        }

                                                </div>
                                                <div className="form-group mb-lg-4">
                                                    <label className="customRadio text-check" id="email_Select" onClick={this.handleCheckbox(1)}>
                                                        Use Email : <span className="f-600 color-dark"> “{email_id}” </span> for Authentication
                                                        <input type="Radio" name="user" checked={twofa_method === 1} onClick={this.handleCheckbox(1)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="form-group mb-lg-4">
                                                    <label className="customRadio text-check" id="mobile_Select" onClick={this.handleCheckbox(0)} >
                                                        Use Mobile Number : <span className="f-600 color-dark white-space-pre">  “{formatedMobileNo}”</span>  for Authentication
                                                        <input type="Radio" name="user" checked={twofa_method === 0} onClick={this.handleCheckbox(0)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}
