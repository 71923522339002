import React, { Component } from 'react'
import profile1 from "../../images/profile1.png";
import profile3 from "../../images/profile3.png";
import profile2 from "../../images/profile2.png"
import csv from "../../images/csv-file.png"
import Modal from "./modals/modal";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVReader } from 'react-papaparse';
import axios from '../apiConnection/axios';

import { faEllipsisH, faEllipsisV, faChevronLeft, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import {
  Link
} from "react-router-dom";
import Utility from "../../components/utility";
const utilityObj = new Utility;
export default class InitiateSalesProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: "",
      csvToJson: "",
      csvErrors: "",
      submitJson: [],
      redirect_url: "http://localhost:3001/createpassword",
      sales_process_data: [],
      Message: "",
      MessageType: "",
      fileextractDone: 0,
      errorCells: "",
      errorfields: [],
      ReportFile: "",
      haserrors: true
    }

  }
  validateDate = (testDate) => {
    // MM/DD/YYY format regex
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    if (!(date_regex.test(testDate))) {
      return false;
    } else {
      return true;
    }
  }
  handleOnLOad = (data) => {
    console.log("ON FILE LOAD DATA", data);
  }
  handleOnDrop = (data) => {
    this.setState({ csvToJson: data });
    console.log("PARSED CSV DATA", this.state.csvToJson);
    var csv_header = ["Stage Order", "Sales Stage Name", "Stage Description", "Task Order", "Task", "Required (Y/N)", "Start Date (mm/dd/yyyy)"];

    var fullerror_message = "";
    var cell = "";
    var row = 0;
    var col = 0;
    var file_data = this.state.csvToJson;
    if (file_data.length === 0) {
      console.log("DATALENGTH", 0);
      fullerror_message = " <br/> CSV file is empty. Nothing to import.  <br/>";
    }
    else if (file_data.length === 1) {
      console.log("DATALENGTH", 1);
      fullerror_message = " <br/> CSV file is empty. Nothing to import. <br/>";
    } else {
      if (file_data.length === 2) {
        console.log("DATALENGTH", 2);
        console.log("DATALENGTH0", file_data[0].data.length);
        console.log("DATALENGTH1", file_data[1].data.length);
        if (file_data[0].data.length <= 1 || file_data[1].data.length <= 1)
          fullerror_message = " <br/> CSV file is empty. Nothing to import. <br/>";
      }
      if (file_data[0].data[0] != "Stage Order" || file_data[0].data[1] != "Sales Stage Name" || file_data[0].data[2] != "Stage Description" || file_data[0].data[3] != "Task Order" || file_data[0].data[4] != "Task" || file_data[0].data[5] != "Required (Y/N)" || file_data[0].data[6] != "Start Date (mm/dd/yyyy)") {
        fullerror_message = " <br/> File is not valid. Please download sample file to process. <br/>";
      }

      else {
        var process_order = [];
        console.log("JSON DATA", this.state.csvToJson);
        var csv_data_length = this.state.csvToJson;
        var stage_ordering = [];
        var task_ordering = [];
        var task_ordering = [];
        var stage_names = [];
        this.state.csvToJson.map((value, index, array2) => {
          console.log("INDEX", index);
          console.log("INDEX VALUE", array2[index].data);

          row = index + 1;
          if (index == 0) {
            console.log("header", value);
            if (array2[index].data.length === 7) {
              for (var i = 0; i <= 6; i++) {
                col = this.numToSSColumn(i + 1);
                if (array2[index].data[i] != csv_header[i]) {
                  cell = 'Cell ' + col + '' + row;
                  fullerror_message = fullerror_message + ' ' + cell + " (CSV file header is incorrect)" + " <br/>";
                }
              }
            }
            this.setState(prevState => ({
              ...prevState,
              sales_process_data: [
                ...prevState.sales_process_data,
                {
                  "data": array2[index].data,
                }
              ]
            })
            );

          }

          else {
            if (array2[index].data.length === 7) {

              //Validations for stages

              if (array2[index].data[0]) {

                if (array2[index].data[1]) {
                  if (array2[index].data[1].length > 255) {
                    col = this.numToSSColumn(1 + 1);
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Stage Name cannot be longer than 255 characters.)" + " <br/>";
                  }
                  if (array2[index].data[2] == "") {
                    col = this.numToSSColumn(2 + 1);
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Data is missing.)" + " <br/>";
                  } else if (array2[index].data[2].length > 255) {
                    col = this.numToSSColumn(2 + 1);
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (Stage Description cannot be longer than 255 characters.)" + " <br/>";
                  }
                  //Ordering number in use:  “The order number is in use for another stage.”
                  col = this.numToSSColumn(0 + 1);
                  if (stage_ordering.indexOf(array2[index].data[0]) !== -1) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (The order number is in use for another stage.)" + " <br/>";
                  }

                  // Duplicate stage: “The Sales Stage already exists.”
                  stage_ordering.push(array2[index].data[0]);
                  col = this.numToSSColumn(1 + 1);
                  if (stage_names.indexOf(array2[index].data[1]) !== -1) {
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (The Sales Stage already exist.)" + " <br/>";
                  }
                  stage_names.push(array2[index].data[1]);
                } else {
                  col = this.numToSSColumn(1 + 1);
                  cell = 'Cell ' + col + '' + row;
                  fullerror_message = fullerror_message + ' ' + cell + " (Data is missing.)" + " <br/>";

                }
              }
              //Validations for tasks
              else {
                // var order_id  = array2[index].data[3];
                // task_ordering.push(["order_id"order_id]);

                if (array2[index].data[3] == "") {
                  col = this.numToSSColumn(3 + 1);
                  cell = 'Cell ' + col + '' + row;
                  fullerror_message = fullerror_message + ' ' + cell + " (Data is missing.)" + " <br/>";
                }
                if (array2[index].data[4] == "") {
                  col = this.numToSSColumn(4 + 1);
                  cell = 'Cell ' + col + '' + row;
                  fullerror_message = fullerror_message + ' ' + cell + " (Data is missing.)" + " <br/>";
                } else if (array2[index].data[4].length > 255) {
                  col = this.numToSSColumn(4 + 1);
                  cell = 'Cell ' + col + '' + row;
                  fullerror_message = fullerror_message + ' ' + cell + " (Task cannot be longer than 255 characters.)" + " <br/>";
                }
                if (array2[index].data[6] == "") {
                  col = this.numToSSColumn(6 + 1);
                  cell = 'Cell ' + col + '' + row;
                  fullerror_message = fullerror_message + ' ' + cell + " (Data is missing.)" + " <br/>";
                } else {
                  var is_valid = this.validateDate(array2[index].data[6]);
                  if (!is_valid) {
                    col = this.numToSSColumn(6 + 1);
                    cell = 'Cell ' + col + '' + row;
                    fullerror_message = fullerror_message + ' ' + cell + " (The Start Date is not in the mm/dd/yyyy.)" + " <br/>";
                  }
                }

              }

              for (var i = 0; i <= 6; i++) {
                col = this.numToSSColumn(i + 1);



                //Required (Y/N) validation (Required Column data different from Y or N: “Please specify either Y or N.”)
                if (i == 5) {
                  if (array2[index].data[0] === "") {
                    if (array2[index].data[5] == "Y" || array2[index].data[5] == "N") {
                    } else {
                      cell = 'Cell ' + col + '' + row;
                      fullerror_message = fullerror_message + ' ' + cell + " (Please specify either Y or N.)" + " <br/>";

                    }
                  }
                }
              }

            }
            this.setState(prevState => ({
              ...prevState,
              sales_process_data: [
                ...prevState.sales_process_data,
                {
                  "data": array2[index].data,
                }
              ]
            })
            );

          }
          console.log("CSV ROW MESSAGE", fullerror_message);
        }).slice(0, 1)
      }
    }
    console.log("ORDERING", stage_ordering);
    console.log("STAGE NAMES", stage_names);
    console.log("TASK ORDERING", task_ordering);
    console.log("fullerror_message", fullerror_message);
    if (fullerror_message == "") {
      this.setState({ fileextractDone: 1 });
    } else {
      this.setState({
        fileextractDone: 0,
        errorCells: fullerror_message,
        MessageType: "danger",
        Message: "file have errors."
      });
    }
    console.log("Final Sales Process Data", this.state.sales_process_data);
  };
  numToSSColumn = (num) => {
    var s = '', t;

    while (num > 0) {
      t = (num - 1) % 26;
      s = String.fromCharCode(65 + t) + s;
      num = (num - t) / 26 | 0;
    }
    return s || undefined;
  }

  salesProcessUpload = async () => {
    const salesProcessData = {
      data: this.state.sales_process_data,
    }
    utilityObj.loaderView("show");
    await axios.post(`/sales-process/create-sales-process-by-csv`, salesProcessData, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res);
        if (res.data.code === 200) {
          utilityObj.loaderView("hide");
          this.setState({
            ReportFile: res.data.data.csv_file_url,
            Message: "CSV Uploaded successfully.",
            MessageType: 'success',
            fileextractDone: 0,
          });
          console.log("response data", res.data.data);
          //   localStorage.setItem('getAllusersUrl', res.data.data.url);
          //   localStorage.setItem('getAllusersData', JSON.stringify(res.data.data));
        }
        else {
          utilityObj.loaderView("hide");
          this.setState({
            Message: "CSV Upload Failed.",
            MessageType: 'danger',
            fileextractDone: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  fileUpload = (event) => {
    console.log("FILE UPLOAD CHANGE", event.target);
  }


  render() {
    const { MessageType, Message, fileextractDone, errorCells, ReportFile, sales_process_data } = this.state;

    return (<>
      <main className="main-contant">
        <div className="contents">
          <h3 className="title-head m-0">Sales Process</h3>
          <div className="upload-files mx-auto f2015">
            {((fileextractDone === 0) && ((!Message) || (Message === null) || (MessageType === ""))) ?
              (
                <div className="text-center">
                  <h5 className="top-fix-head salesProcess">To create the Sales Process: </h5>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="text-area text-left py-3 mx-auto f2015">
                      1. Download CSV template. <br />
                      2. Populate the template with data. <br />
                      3. Drag and drop the CSV file.<br />
                      4. Click Upload.
                    </div>
                  </div>
                  <img src={csv} alt="csv-file" />
                  <div className="color_blue py-4 underline">
                    <a href="/sales_process_template.csv" className="color_blue" target="_blank">Click here to download CSV template</a>
                  </div>
                  <div className="color_blue py-4 underline">
                    <a href="/sales_process_template.pdf" className="color_blue" target="_blank" download>Click here to download CSV instructions</a>
                  </div>
                </div>
              ) : ('')}
            {((Message) && (Message !== null) && (MessageType === "danger")) ?
              (
                <div className="errors-div">
                  <div className="drop-zone-wrap p-2">
                    <div className="drop-zone f2015 f-500">
                      <span className="drop-zone__prompt mt-3">
                        <i className="iconmoon icon-warning"></i>
                        <div className="color_blue mt-3">The following errors have been found in the uploaded CSV file:<span className="d-sm-block"></span><span dangerouslySetInnerHTML={{ __html: errorCells }}></span><br /><span>Please correct the errors in the CSV file, then click Restart Process and upload the corrected file.</span></div>
                      </span>
                    </div>
                    <div className="text-center py-4">
                      <a href="./initiate-sales-process" className="color_blue underline f-2015"><i className="fa fa-refresh"></i> Restart Process</a>
                    </div>
                  </div>
                </div>
              ) : (``)
            }

            {((Message) && (Message !== null) && (MessageType === "success")) ?
              (
                <div className="upload-successfully mt-100">
                  <div className="drop-zone-wrap p-2">
                    <div className="drop-zone f2015 f-500">
                      <span className="drop-zone__prompt mt-3">
                        <i className="iconmoon icon-check check-successfully"></i>
                        <div className="color_blue mt-3">Sales process was uploaded successfully.</div><br />
                        <a href={process.env.REACT_APP_STATIC_FILES_PATH_CSV_SALES_PROCESS + `/` + ReportFile} className="color_blue" target="_blank"><i className="ri-download-2-line color_blue"></i>
                          &nbsp; <u>Download Report File</u></a>
                      </span>
                    </div>
                  </div>
                  <div className="text-center py-4">
                    <Link to={`./sales-process`} type="btn" className="btn dark-btn">View Sales Process</Link>
                  </div>
                </div>
              ) : (``)
            }
            {((fileextractDone === 0) && ((!Message) || (Message === null) || (MessageType === ""))) ?
              (
                <CSVReader
                  onDrop={this.handleOnDrop}
                  // onFileLoad = {this.handleOnDrop}
                  onError={this.handleOnError}
                  addRemoveButton
                  onRemoveFile={this.handleOnRemoveFile}
                  accept="text/csv, .csv"
                  config={{ delimiter: "," }}
                  style={{
                    dropArea: {
                      borderColor: 'gray',
                      borderRadius: 20,
                      maxHeight: 200
                    },
                    dropAreaActive: {
                      borderColor: '#003366',
                    },
                    dropFile: {
                      width: 240,
                      height: 120,
                      background: 'transparent',
                    },
                  }}
                >
                  <div className="drop-zone-wrap">
                    <div className="drop-zone f2015 f-500">
                      <span className="drop-zone__prompt mt-3">
                        Drag and drop CSV file or
                        <span className="color_blue underline"> Browse here</span>
                      </span>
                    </div>
                  </div>
                </CSVReader>
              ) : (``)}

            {(fileextractDone === 1) ? (<>
              <div className=" mt-2">
                <div className="drop-zone-wrap p-2">
                  <div className="drop-zone f2015 f-500">
                    <span className="drop-zone__prompt mt-3">
                      <div className="mt-3"> Please confirm the stages to be created:</div>
                      {sales_process_data ?
                        <ul className="list-unstyled my-4 mx-auto text-left SalesProcess">
                          {(sales_process_data).map((sales_stage, index) => {
                            {
                              return <>
                                {index != 0 ?
                                  sales_stage.data[0] ?
                                    <li className="x-0">
                                      {sales_stage.data[0]}. {sales_stage.data[1]}
                                    </li>
                                    : ""
                                  : ""}

                              </>
                            }
                          })}
                        </ul> : null}
                    </span>
                  </div>
                </div>
              </div>

            </>) : (``)}


            <div className="text-center py-4">
              {(fileextractDone === 1) ? (<>
                <button onClick={() => this.salesProcessUpload()} type="btn" className="btn dark-btn">Confirm</button>
                <div className="text-center py-4">
                  <a href="./initiate-sales-process" className="color_blue underline f-2015"><i className="fa fa-refresh"></i> Restart Process</a>
                </div></>
              ) : (``)}
            </div>

          </div>
        </div>
      </main>


    </>
    )
  }
}
