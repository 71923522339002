import React, { Component } from 'react'
import profile1 from "../../images/profile1.png";
import profile3 from "../../images/profile3.png";
import profile2 from "../../images/profile2.png"
import img1 from "../../images/user-img1.png";
import img2 from "../../images/user-img2.png";
import img3 from "../../images/user-img3.png"
import img4 from "../../images/user-img4.png";
import img5 from "../../images/user-img5.png"
import img6 from "../../images/user-img6.png";
import csv from "../../images/csv-file.png"
import Modal from "./modals/modal";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faEllipsisV, faChevronLeft, faChevronDown, faChevronUp, faFilter } from '@fortawesome/free-solid-svg-icons';
import {
  Link
} from "react-router-dom";
import TableContextMenu from "./elements/tableContextMenu";
import axios from '../apiConnection/axios';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Utility from "../../components/utility";
const utilityObj = new Utility;

export default class ConfirmUserManagement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profileImg: process.env.REACT_APP_STATIC_FILES_PATH + '/' + 'default-avatar.png',
      editUserShow: false,
      removeUserShow: false,
      first_name: "",
      last_name: "",
      mobile: "",
      email: "",
      allUsers: [],
      userData: {},
      buttonshow: false,
      user_already_exists: "",
      MessageType : "",
      Message : "",
    };
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // Retrieve the object from storage
    var retrievedObject = localStorage.getItem('getAllusersData');
    console.log('retrievedObject: ', JSON.parse(retrievedObject));
    var allUsers = JSON.parse(retrievedObject)

    for (let i = 0; i < allUsers.length; i++) {
      const full_name = allUsers[i].first_name + " " + allUsers[i].last_name;
      console.log(full_name);
      allUsers[i].full_name = full_name;
    }
    var buttonshow = true;
    for (let i = 0; i < allUsers.length; i++) {
      // skip headers
      var dataObj = allUsers[i];
      console.log(dataObj['user_already_exists']);
        if ((dataObj['user_already_exists']==="mobile") || (dataObj['user_already_exists']==="email") || (dataObj['user_already_exists']==="yes")|| (dataObj['division_team_not_exists']==="division") || (dataObj['division_team_not_exists']==="team")){
        buttonshow = false
      }
    }
    this.setState({ buttonshow });
    // for (let i = 0; i < allUsers.length; i++) {
    //   var dataObj = allUsers[i];
    //   if ((dataObj['user_already_exists']=="no")){
    //     var user_already_exists = allUsers[i].user_already_exists
    //     var division_team_not_exists = allUsers[i].division_team_not_exists
    //     if(user_already_exists=="no"){ 
    //       // alert(user_already_exists)
    //       this.setState({user_already_exists: division_team_not_exists});
    //       console.log("on load user_already_exists------------",this.state.user_already_exists)
    //     }
    //   }
    // };
    this.setState({ allUsers }, () => {
      console.log(this.state.allUsers)
    })
  }

  setRemoveUserShow = (newValue, row) => {
    if (newValue === false) {
      this.setState({ removeUserShow: newValue, Message:"", MessageType:"" });
    }
    else {
      this.setState({ removeUserShow: newValue });
      this.setState({ userData: row });
      console.log(this.state.userData)
    }
  }
  deleteUser = () => {
    var { id } = this.state.userData
    let data = this.state.allUsers;
    this.setState({
      allUsers: data.filter((d, i) => data[i].id !== id)
    }, () => {
      localStorage.setItem('getAllusersData', JSON.stringify(this.state.allUsers));
      this.setState({ 
        Message : 'User removed successfully.',
        MessageType : 'success'
        })           
      setTimeout(() => { 
        this.setState({ 
          Message : '',
          MessageType : ''
          })           
          this.setRemoveUserShow(false)
          this.userUpload();
        }, 3000);  
    })
  }


  finalUpload = async () => {
    const usersdata = {
      data: this.state.userData,
    }
    await axios.post(`/organizations/check-exists-users-csv`, usersdata, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState({
            Message: "CSV Uploaded successfully.",
            MessageType: 'success',
            fileextractDone: 0,
          });
          localStorage.setItem('getAllusersUrl', res.data.data.url);
          localStorage.setItem('getAllusersData', JSON.stringify(res.data.data));
          setTimeout(() => this.componentDidMount(), 2000);

          var buttonshow = true;

          // for (let i = 0; i < res.data.data.length; i++) {
          //   var dataObj = res.data.data[i];
          //   if ((dataObj['user_already_exists']=="no")){
          //     var user_already_exists = res.data.data[i].user_already_exists
          //     var division_team_not_exists = res.data.data[i].division_team_not_exists
          //     if(user_already_exists=="no"){
          //       this.setState({division_team_not_exists:user_already_exists});
          //       alert(this.state.division_team_not_exists)
          //       alert("finalUpload user_already_exists------------",this.state.division_team_not_exists)
          //     }
          //   } 

          // };
          this.setState({ buttonshow });
        }
        else {
          this.setState({
            Message: "CSV Upload Failed.",
            MessageType: 'danger',
            fileextractDone: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  userUpload = async () => {
    const dataload = this.state.allUsers;
    utilityObj.loaderView("show");

    if (dataload.length <= 0) {
      window.location.href = "./initiate-user-management";
      return false;
    }
    var data = [];
    var fullpayload = {};
    var rv = {};
    var data2 = [
      "Head Position",
      "Sales Division Name",
      "Sales Team Name",
      "First Name",
      "Last Name",
      "Email",
      "Mobile",
    ]
    rv['data'] = data2
    data.push(rv)
    for (let i = 0; i < dataload.length; i++) {
      var dataObj = dataload[i];
      var rv = {};
      var data2 = [
        dataObj["head_position"],
        dataObj["sales_division_name"],
        dataObj["sales_team_name"],
        dataObj["first_name"],
        dataObj["last_name"],
        dataObj["email"],
        dataObj["mobile"],
      ]
      rv['data'] = data2
      data.push(rv)

    }
    fullpayload['data'] = data;
    console.log(fullpayload);
    await axios.post(`/organizations/check-exists-users-csv`, fullpayload, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res);
        if (res.data.code === 200) {
          localStorage.setItem('getAllusersUrl', res.data.data.url);
          localStorage.setItem('getAllusersData', JSON.stringify(res.data.data));
          console.log('getAllusersData',res.data.data);
          this.setState({ buttonshow: true }, () => {
            for (let i = 0; i < res.data.data.length; i++) {
              // skip headers
              var dataObj = res.data.data[i];
                
                if ((dataObj['user_already_exists']==="mobile") || (dataObj['user_already_exists']==="email") || (dataObj['user_already_exists']==="yes") ||  (dataObj['division_team_not_exists']=="division") || (dataObj['division_team_not_exists']=="team")){
                this.setState({ buttonshow: false });
              }
            }

          })

          setTimeout(() => this.componentDidMount(), 2000);
          utilityObj.loaderView("hide");

        }
      })
      .catch((error) => {
        console.log(error)
      })


  }

  updateUser = async () => {
    var userData = this.state.userData;
    var allUsers = this.state.allUsers;
    const { email, mobile, first_name, last_name } = this.state;
    userData.first_name = first_name;
    userData.last_name = last_name;
    userData.mobile = mobile;
    userData.email = email;
    userData.full_name = first_name + " " + last_name;
    Array.prototype.insert = function (index, item) {
      this.splice(index, 0, item);
    };
    console.log(userData)
    // console.log(newData)
    var { id } = this.state.userData
    let data = this.state.allUsers;
    data = data.filter((d, i) => data[i].id !== id);
    data.insert(id, userData);
    // data.push(userData);

    console.log(data)
    this.setState({
      allUsers: data
    }, () => {
      localStorage.setItem('getAllusersData', JSON.stringify(this.state.allUsers));
      console.log(this.state.allUsers)
      this.userUpload();
    })
    console.log(id);
    this.setState({ 
      Message : 'User updated successfully.',
      MessageType : 'success'
      })               

    setTimeout(() => { 
      this.setState({ 
        Message : '',
        MessageType : ''
        })           
        this.setEditUserShow(false)
     }, 3000);  
  }
  confirmUser = async () => {

    console.log(this.state.allUsers)
    console.log(this.state.allUsers.length)
    var sendtoServer = this.state.allUsers;
    const newarrayaaa = [];
    const obj = [
      "Head Position",
      "Sales Division Name",
      "Sales Team Name",
      "First Name",
      "Last Name",
      "Email",
      "Mobile",
    ];
    var data = Object.assign([], obj); // {0:"a", 1:"b", 2:"c"}
    newarrayaaa.push({ data });
    Object.keys(sendtoServer).map((key, index) => {
      const obj = [
        sendtoServer[index].head_position,
        sendtoServer[index].sales_division_name,
        sendtoServer[index].sales_team_name,
        sendtoServer[index].first_name,
        sendtoServer[index].last_name,
        sendtoServer[index].email,
        sendtoServer[index].mobile,

      ];
      var data = Object.assign([], obj); // {0:"a", 1:"b", 2:"c"}
      newarrayaaa.push({ data });
    });
    console.log(newarrayaaa)

    const usersdata = {
      data: newarrayaaa,
    }


    await axios.post(`/organizations/check-exists-users-csv`, usersdata, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res);
        for (let i = 0; i < res.data.data.length; i++) {
          const element = res.data.data[i];
          if (element.user_already_exists === "no") {
            axios.post(`/organizations/create-structure-acllocation-csv`, usersdata, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
              }
            })
              .then(res => {
                console.log(res);
                if (res.data.code === 200) {
                  localStorage.setItem('getAlluserCount', res.data.data.length);
                  window.location.href = "/system-admin/success-user-management";
                }
                this.setState({
                  Message: res.data.message,
                  MessageType: 'danger',
                })
              })
              .catch((error) => {
                console.log(error)
              })
          }
          else {
            this.setState({
              Message: "Please remove All exists users",
              MessageType: 'danger',
            });
          }
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  removeUser = () => {
    const { first_name, last_name } = this.state.userData;
    const { Message, MessageType } = this.state;
    return (<>
      <div className="text-center m-4">
      {((Message) && (Message !== null)) ?
          (
            <div className={`alert alert-${MessageType}`}>
              {Message}
            </div>
          ) : (``)
        }
        <p className="m-0">
          Are you sure you want to remove  <span className="f-600">{first_name} {last_name} </span> ?</p>
        <div className="modal-footer border-0 justify-content-center">
          <button type="button" className="btn dark-btn mx-2" onClick={(e) => this.deleteUser(e)}>Yes</button>
          <button type="button" className="btn light-btn mx-2" onClick={() => this.setRemoveUserShow(false)}>No</button>
        </div>
      </div>
    </>);
  }

  setEditUserShow = (newValue, row) => {

    if (newValue === false) {
      this.setState({ editUserShow: newValue, Message : "", MessageType :"" });
    }
    else {
      const { first_name, last_name, email, mobile } = row;
      this.setState({ editUserShow: newValue, userData: row }, () => {
        console.log(this.state.userData)
        console.log(row)
        this.setState({
          first_name, last_name, email, mobile,
        })
      });
    }
  }

  formatPhoneNumber = (str) => {
    if (str !== null && str !== "" && str.length >= 10) {
      var newArray = str.match
        (/^([0-9]{1,3})?(\d{3})(\d{3})(\d{4})$/);
      // Checking the international code
      var intlCountryCode = (newArray[1] ? '+' + newArray[1] : '');

      // Resolving the above array we get
      // the international number
      var internationalNumber = intlCountryCode +
        '-' + newArray[2] + '-' + newArray[3]
        + '-' + newArray[4];
      this.setState({ formatedMobileNo: internationalNumber })

      return null
    } else {
      return null
    }
  };
  changeFunction = (e) => {
    if (e.target.id === 'first_name') {
      this.setState({ first_name: e.target.value });
    } else if (e.target.id === 'last_name') {
      this.setState({ last_name: e.target.value });
    }
    else if (e.target.id === 'mobile_no') {
      this.setState({ mobile: e.target.value });
      this.formatPhoneNumber(e.target.value);
    }
    else if (e.target.id === 'email_id') {
      var email = e.target.value.substring(0, e.target.value.indexOf("@"));
      this.setState({ email: e.target.value });
    }
  }
  editUser = (cell, row) => {
    const { first_name, last_name, email, mobile } = this.state.userData;
    const {Message, MessageType } = this.state;

    return (<>
      <form>

        {((Message) && (Message !== null)) ?
          (
            <div className={`alert alert-${MessageType}`}>
              {Message}
            </div>
          ) : (``)
        }
        <div className="row form-group">
          <div className="col-6">
            <label htmlFor="" className="mb-0">First Name</label>
            <input type="text" id='first_name' className="form-control f-600 f2015" defaultValue={first_name}
              placeholder="First Name" onChange={this.changeFunction} />
          </div>
          <div className="col-6">
            <label htmlFor="" className="mb-0">Last Name</label>
            <input type="text" id='last_name' className="form-control f-600 f2015" defaultValue={last_name}
              placeholder="Last Name" onChange={this.changeFunction} />
          </div>
        </div>
        <div className="form-group">
          <label className="mb-0">Email</label>
          <input type="email" id='email_id' className="form-control f-600 f2015" defaultValue={email}
            placeholder="Email" onChange={this.changeFunction} />
        </div>
        <div className="form-group">
          <label className="mb-0">Mobile Number</label>
          <input type="text" id="mobile_no" className="form-control f-600 f2015" defaultValue={mobile}
            placeholder="Mobile Number" onChange={this.changeFunction} />

        </div>
      </form>
      <div className="modal-footer justify-content-center">
        <button type="button" className="btn dark-btn mx-2" onClick={() => this.updateUser()}>Save</button>
        <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={() => this.setEditUserShow(false)}>Cancel</button>
      </div>
    </>);
  }
  // actionformatter = () => {
  // const  {index}  = this.state
  // for (let index = 0; index < this.state.allUsers.length; index++) {
  //   console.log(this.state.allUsers[index].id)
  // }
  actionformatter = (cell, row) => {
    return (
      <div className="actions">
        <div className="dropdown">
          <button className="btn dropdown-toggle" type="button"
            id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i className="iconmoon icon-ellipse-v"></i>
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
            <a className="dropdown-item" onClick={() => this.setEditUserShow(true, row)}>
              <i className="iconmoon icon-edit1"></i> Edit
            </a>
            <a className="dropdown-item" {...row.user_already_exists === "yes"} onClick={() => this.setRemoveUserShow(true, row)}>
              <i className="iconmoon icon-deactivate"></i> Remove </a>
          </div>
        </div>
      </div>
    );
  }


  render() {
    const { editUserShow, removeUserShow, profileImg, allUsers, buttonshow } = this.state
    const { SearchBar } = Search;
    function profileimageformatter(image) {
      return (
        <span className="img-wrap user-profile bg-transparent"><img src={profileImg} alt="profile" /></span>
      );
    }



    function statusformatter(status) {

      if (status == "yes") {
        return (
          <div className="actions already-exists">
            <div className="dropdown">
              <button className="btn dropdown-toggle" type="button"
                id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false"> <i className="iconmooon icon-info color_red"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                <a href="javascript:;" className="dropdown-item text-center color_red">User already exists.</a>
              </div>
            </div>
          </div>
        );
      }
      else if (status == "mobile") {
        return (
          <div className="actions already-exists">
            <div className="dropdown">
              <button className="btn dropdown-toggle" type="button"
                id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false"> <i className="iconmooon icon-info color_red"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                <a href="javascript:;" className="dropdown-item text-center color_red">Duplicate user in CSV.</a>
              </div>
            </div>
          </div>
        );
      }
      else if (status == "email") {
        return (
          <div className="actions already-exists">
            <div className="dropdown">
              <button className="btn dropdown-toggle" type="button"
                id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false"> <i className="iconmooon icon-info color_red"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                <a href="javascript:;" className="dropdown-item text-center color_red">Duplicate user in CSV.</a>
              </div>
            </div>
          </div>
        );
      }
      else if (status == "division") {
        return (
          <div className="actions already-exists">
            <div className="dropdown">
              <button className="btn dropdown-toggle" type="button"
                id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false"> <i className="iconmooon icon-info color_red"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                <a href="javascript:;" className="dropdown-item text-center color_red">Division already exists.</a>
              </div>
            </div>
          </div>
        );
      }
      else if (status == "team") {
        return (
          <div className="actions already-exists">
            <div className="dropdown">
              <button className="btn dropdown-toggle" type="button"
                id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false"> <i className="iconmooon icon-info color_red"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                <a href="javascript:;" className="dropdown-item text-center color_red">Team already exists.</a>
              </div>
            </div>
          </div>
        );
      }
      else {
        return (
          <span></span>
        );
      }

    }

    function divisitonteamformatter(status) {


      if (status == "division") {
        return (
          <div className="actions already-exists">
            <div className="dropdown">
              <button className="btn dropdown-toggle" type="button"
                id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false"> <i className="iconmooon icon-info color_red"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                <a href="javascript:;" className="dropdown-item text-center color_red">Division does not exist.</a>
              </div>
            </div>
          </div>
        );
      }
      else if (status == "team") {
        return (
          <div className="actions already-exists">
            <div className="dropdown">
              <button className="btn dropdown-toggle" type="button"
                id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false"> <i className="iconmooon icon-info color_red"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                <a href="javascript:;" className="dropdown-item text-center color_red">Team does not exist.</a>
              </div>
            </div>
          </div>
        );
      }
      else {
        return (
          <span></span>
        );
      }

    }




    const columns = [{
      dataField: 'profileimage',
      text: '',
      formatter: profileimageformatter,
      sort: false
    }, {
      dataField: 'full_name',
      text: 'Full Name',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<span> &nbsp;  </span>);
        else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
        else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
        return null;
      }
    }, {
      dataField: 'email',
      text: 'Email ID',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<span> &nbsp;  </span>);
        else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
        else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
        return null;
      }
    }, {
      dataField: 'mobile',
      text: 'Mobile Number',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<span> &nbsp;  </span>);
        else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
        else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
        return null;
      }
    }, {
      dataField: 'user_already_exists',
      text: '',
      formatter: statusformatter,
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<span> &nbsp;  </span>);
        else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
        else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
        return null;
      }
    },
    {
      dataField: 'division_team_not_exists',
      text: '',
      formatter: divisitonteamformatter,
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<span> &nbsp;  </span>);
        else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
        else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
        return null;
      }
    },

    {
      dataField: 'action',
      text: ' ',
      formatter: this.actionformatter,
      sort: false
    }];


    const defaultSorted = [{
      dataField: 'full_name',
      order: 'desc'
    }];
    const pagination = paginationFactory({
      hideSizePerPage: true,

    });
    var Users = [];

    if (this.state.allUsers.length > 0) {
      Users = this.state.allUsers;
    }
    return (<>
      <Modal
        title="Edit User"
        contentClasses=""
        size="md"
        onClose={() => this.setEditUserShow(false)}
        show={editUserShow}>
        {this.editUser()}
      </Modal>

      <Modal
        title=""
        contentClasses=""
        size="md"
        onClose={() => this.setRemoveUserShow(false)}
        show={removeUserShow}>
        {this.removeUser()}
      </Modal>
      <main className="main-contant">
        <div className="contents">
          <div className="d-flex justify-content-between mb-4 align-items-center">
            <h3 className="title-head m-0 ">User Management</h3>
          </div>
          <div className="steps-container d-flex justify-content-center">
            <div className="steps-list-wrap d-flex color_blue justify-content-between py-3 align-items-center">
              <div className="steps-list text-center f2015 px-4">
                <div className="steps-count steps-completed">
                  1
                </div>
                <div className="steps-text mt-2">
                  Upload
                </div>
              </div>
              <div className="steps-list text-center f2015 px-4">
                <div className="steps-count steps-completed">
                  2
                </div>
                <div className="steps-text mt-2">
                  Confirmation
                </div>
              </div>
              <div className="steps-list text-center f2015 px-4">
                <div className="steps-count">
                  3
                </div>
                <div className="steps-text mt-2">
                  Success
                </div>
              </div>
            </div>
          </div>
          <ToolkitProvider
            keyField="id"
            data={Users}
            columns={columns}
            search
          >
            {
              props => (
                <div className="user-management-table">
                  <BootstrapTable
                    {...props.baseProps}
                    pagination={pagination} />
                  <div className="text-center text-md-left">
                    {(buttonshow === true) ? (
                      <Link type="button" onClick={() => this.confirmUser(true)} className="btn dark-btn px-5">Confirm</Link>
                    ) : (
                      <Link type="button" disabled={true} className="btn light-btn px-5 disabled" title="fix errors to confirm.">Confirm</Link>
                    )}
                  </div>
                </div>
              )
            }
          </ToolkitProvider>

        </div>
      </main>

    </>
    )
  }
}
