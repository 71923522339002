import React, { Component } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import {
  Link
} from "react-router-dom";

export default class SuccessSalesProcess extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (<>
      <main className="main-contant">
        <div className="contents">
          <h3 className="title-head m-0">Sales Process</h3>
          <div className="upload-files mx-auto f2015">
            <div className="text-center">
              <h5>To create new Process: </h5>
              <div className="d-flex justify-content-center align-items-center">
                <div className="text-area text-left py-3 mx-auto f2015">
                  1. Download CSV template. <br />
                  2. Populate the template with data. <br />
                  3. Drag and drop the CSV file.<br />
                  4. Click Upload.
                </div>
              </div>
            </div>


            <div className="upload-successfully mt-2">
              <div className="drop-zone-wrap p-2">
                <div className="drop-zone f2015 f-500">
                  <span className="drop-zone__prompt mt-3">
                    <i className="iconmoon icon-check check-successfully"></i>
                    <div className="color_blue mt-3">The Sales Process uploaded successfully.</div>
                  </span>
                </div>
              </div>
              <div className="text-center py-4">
                <Link to={`./sales-process`}><button type="btn" className="btn dark-btn">View Sales Process</button></Link>
              </div>
            </div>

          </div>
        </div>
      </main>


    </>
    )
  }
}
