import React, { Component } from 'react'
import $ from 'jquery';
import './sidebar.css';
import img_3 from "../../../images/3.svg";
import icn_dashboard from "../../../images/dashboard-icon.png";
import icn_calendar from "../../../images/calendar-icon.png";
import icn_opportunity from "../../../images/opportunity-icon.png";
import icn_coaching from "../../../images/coaching-icon.png";
import icn_logout from "../../../images/log-out.png";
import file_chart_line from "../../../images/file-chart-line.png";

import {
	Link
  } from "react-router-dom"; 

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationclass : "",
    };
    this.changeActive = this.changeActive.bind(this);
  }  
  componentDidMount(){
    //destructuring pathname from location
    const { pathname } = window.location;
  
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    if (splitLocation[2]==='dashboard') { this.setState({locationclass:'dashboard'}) }
    else if (splitLocation[2]==='calendar') { this.setState({locationclass:'calendar'}) }
    else if ((splitLocation[2]==='opportunities') || (splitLocation[2]==='task-details')) { this.setState({locationclass:'opportunities'}) }
    else if ((splitLocation[2]==='coaching') || (splitLocation[2]==='coaching-detail')) { this.setState({locationclass:'coaching'}) }
    else if (splitLocation[2]==='reports') { this.setState({locationclass:'reports'}) }
    else {this.setState({locationclass:''})}
    setTimeout(() => { this.changeActive(this.state.locationclass);}, 500);

  }


  componentWillReceiveProps(nextProps){
    const { pathname } = window.location;
  
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    if (splitLocation[2]==='dashboard') { this.setState({locationclass:'dashboard'}) }
    else if (splitLocation[2]==='calendar') { this.setState({locationclass:'calendar'}) }
    else if ((splitLocation[2]==='opportunities') || (splitLocation[2]==='task-details')) { this.setState({locationclass:'opportunities'}) }
    else if ((splitLocation[2]==='coaching') || (splitLocation[2]==='coaching-detail')) { this.setState({locationclass:'coaching'}) }
    else if (splitLocation[2]==='reports') { this.setState({locationclass:'reports'}) }
    else {this.setState({locationclass:''})}
    setTimeout(() => { this.changeActive(this.state.locationclass);}, 500);
  }

  changeActive = (link) => {
     this.setState({locationclass:link});
  }

    render() {
      const { locationclass } = this.state;
      const role_permissions = JSON.parse(localStorage.getItem('permissions'));
      console.log("SALES REP ROLE PERMISSIONS:",role_permissions);
      console.log("SALES REP LEFT SIDEBAR locationclass:", locationclass);

      
      var pathname = window.location.pathname;
      console.log("@PATHNAME: ",pathname);
      return (
      <>     
      <aside className="opportunity-management-aside">
      <div className="container position-relative">
        <div className="sidebar">
          <span className="f2015 f-600 text-center toggler"><i className="fa fa-arrow-left"></i></span>
          <ul className="list-unstyled">

          {role_permissions.length>0 && role_permissions.map((permissions,index) => {
            var img_path = permissions.icon_path;
            switch(permissions.module_key){
              case "dashboard":
                img_path = icn_dashboard;
              break;
              case "organization_setup":
                img_path = icn_dashboard;
              break;
              case "user_management":
                img_path = icn_dashboard;
              break;
              case "sales_process":
                img_path = icn_dashboard;
              break;
              case "calendar":
                img_path = icn_calendar;
              break;
              case "opportunities":
                img_path = icn_opportunity;
              break;
              case "coaching":
                img_path = icn_coaching;
              break;
              case "reports":
                img_path = file_chart_line;
              break;

            }
            
            if(permissions.access==="1"){
            return(<>
            <li>
           <Link  onClick={()=> this.changeActive(permissions.module_key)}  to={permissions.menu_url} className={((locationclass === permissions.module_key)|| (permissions.module_key=="calendar" && locationclass=="" && pathname.indexOf("scheduler")!=-1) )? "active f2015" : "f2015"}>
           <img src={img_path} alt={permissions.module_key} className="mr-1" width="24"/>     
           <span className="menu-text">{permissions.module_name} </span>  
           </Link>           
            </li></>)
            }
           })
          
                            
          }

{/* <li>
            <Link onClick={()=> this.changeActive(1)} to={'./dashboard'} className={locationclass === 1 ? "active f2015" : "f2015"}>
              <img src={icn_dashboard} alt="dashboard-icon" className="mr-1" width="24"/>   <span className="menu-text"> Dashboard</span>
            </Link>                
            </li>
            <li>
            <Link onClick={()=> this.changeActive(2)} to={'./calendar'} className={locationclass === 2 ? "active f2015" : "f2015"}>
              <img src={icn_calendar} alt="calendar-icon" className="mr-1" width="24"/>   <span className="menu-text"> Calendar</span>
            </Link>                
            </li>
            <li>
            <Link onClick={()=> this.changeActive(3)} to={'./opportunities'} className={locationclass === 3 ? "active f2015" : "f2015"}>
              <img src={icn_opportunity} alt="opportunity-icon" className="mr-1" width="24"/>   <span className="menu-text"> Opportunities</span>
            </Link>
            </li>
            <li>
            <Link onClick={()=> this.changeActive(4)} to={'./coaching'} className={locationclass === 4 ? "active f2015" : "f2015"}>
              <img src={icn_coaching} alt="coaching-icon" className="mr-1" width="24"/>   <span className="menu-text"> Coaching</span>
            </Link>
            </li>
            <li>
            <Link onClick={()=> this.changeActive(5)} to={'./reports'} className={locationclass === 5 ? "active f2015" : "f2015"}>
              <img src={file_chart_line} alt="coaching-icon" className="mr-1" width="24"/>   <span className="menu-text"> Reports</span>
            </Link>
            </li> */}
          </ul>
          <ul className="list-unstyled mb-0 bottom-list">
            <li className="border-bottom">
              <a href="javascript:;" className="f2015 versions"><small>V<span>ersion</span> 1.0.1.2</small></a>
            </li>
            <li>
            <Link to={`/logout`} className="f2015"><img src={icn_logout} alt="dashboard-icon" className="mr-1" width="24"/>  <span className="menu-text"> Log Out</span></Link>
            </li>
          </ul>
        </div>
      </div>
    </aside>

            </>
        )
    }
}
